import { Component, EventEmitter, Input, NgZone, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AlertComponent } from 'src/app/components/bonus-ui/base/alert/alert.component';
import { DiscountGroupService } from 'src/app/components/discount/discount-group.service';
import { FormsService } from 'src/app/components/forms/forms.service';
import { field, value } from 'src/app/components/forms/manage-forms/formField-global.model';
import Swal from 'sweetalert2';
import { ApiClientService } from '../../services/api-client.service';
import { CommonService } from '../../services/common.service';
export interface Country {
  capital: string;
  country_code: string;
  country_name: string;
  currency: string;
  flag: string;
  id: number;
  phone_code: string;
  symbol: string;
  selected: any;
  fieldModelsExceptlasthree: any;
}
@Component({
  selector: 'app-configure-discount-rule',
  templateUrl: './configure-discount-rule.component.html',
  styleUrls: ['./configure-discount-rule.component.scss']
})
export class ConfigureDiscountRuleComponent implements OnInit, OnChanges {

  @Input() discountType: string = ''; // decorate the property with @Input()
  @Input() discountGroupRuleID: string = ''; // decorate the property with @Input()
  @Input() discountGroupID: string = ''; // decorate the property with @Input()
  @Input() form_price: string = ''; // decorate the property with @Input()
  @Input() Break: boolean; // decorate the property with @Input()
  @Input() ruleData: object = {}; // decorate the property with @Input()
  @Input() discountFields: any; // decorate the property with @Input()
  @Input() discountTypeID: any; // decorate the property with @Input(
  @Input() preConfiguredSelected = null; // decorate the property with @Input(
    @Input() maxpreconfig:any
    @Input() basicInfo:boolean
  // @Input() isPreconfigCheck: boolean = false; // decorate the property with @Input(
    @Input() isPreconfigCheck: boolean // decorate the property with @Input(
  @Input() formOwnRuleData: any[] = []; // decorate the property with @Input(
    
    @Input() now: any; // decorate the property with @Input(
      @Input() MaxDisArr: any; // decorate the property with @Input(
  @Output() RuleShow = new EventEmitter();
  @Output() RehitFormApi = new EventEmitter();
  

      // MaxDisArr

  validate = false;
  hidesave:boolean = false;
  formid: string = '';
  countriesList: Country[] = [];

  groupid: string = '';

  ruleEdit = false;
  Condition_Show:any =[]
  constraint_name_id:any=''
  conditionRows = [{
    "basic_info_field":'',
    "field_type": "",
    'group_type':"",
    'likertquestion':"",

    "constraint": "Is",
    "constraint_id": 1,
    "value": "",
    "showValueBox": true,
    'FieldConstraint':[],
    'conditiontype':'text',
    'valforboxes':{},
    'AddressType':[],
    'FileEmpt':false,

    "groupFields":[],
    "multipleboxes":[],

    'multiplecheckbox':[],
    'multiplevalue':[],
    'rating':{
      "accept":'',
      "value":'',
      "size":'',
      "description":'',
      "arrsize":[]
    },
    'LikertQuestions':{
      "Questions":[],
      "values":[]
    }
  }];

  basicInfoFields1: any[] = ["First Name", "Last Name", "Email", "Phone Code", "Phone Number", "Date of Birth", "Gender", "Nationality"];
  discountGroupminprice:any
  basicInfoFields: any[] = [
    
    {
      'label': 'First Name',
      'slug': 'first_name',
    },
    {
      'label': 'Last Name',
      'slug': 'last_name',
    },
    {
      'label': 'Email',
      'slug': 'email',
    },
  
    {
      'label': 'Phone Number',
      'slug': 'phone_number',
    },
    {
      'label': 'Date of Birth',
      'slug': 'dob',
    },
    {
      'label': 'Nationality',
      'slug': 'nationality_country',
    }
  ];
  basicInfoFieldsfornormal: any[] = [
    {
      'label': 'Basic Info',
      'name': 'Basic Info',
      
    },
    {
      'label': 'First Name',
      'name': 'first_name',
      
    },
    {
      'label': 'Last Name',
      'name': 'last_name',
    },
    {
      'label': 'Email',
      'name': 'email',
    },
  
    {
      'label': 'Phone Number',
      'name': 'phone_number',
    },
    {
      'label': 'Date of Birth',
      'name': 'dob',
    },
    {
      'label': 'Nationality',
      'name': 'nationality_country',
    }
  ];
  
  thenApply = ['percent', 'amount'];
  ownmaxx:any
  ifConditionOperator: string = 'any';
  thenApplyAction: string = 'amount';


  ruleConditionList: any[] = [];

  proofRequired: boolean = false;

  thenApplyValue: string = '';
  page_arr_fields:any[] = [];
  ruleName: string = '';
  newarr:any=[]
  // ruleConditionList:any[] = [];

  discountRuleForm = [
    {
      "rule_name": "",
      "if_condition": {
        "allow": "any",
        "conditions": [{
          "basic_info_field":'',
          "field_type": "",
          "field_type2": "",

          'group_type':"",
          'group_type2':"",
          'likertquestion':"",
          "constraint": "Is",
          "constraint_id": 1,
          "value": "",
          "showValueBox": true,
          'FieldConstraint':[],
         'conditiontype':'text',
         'FileEmpt':false,
      'valforboxes':{},
      "valforradio":{},
      'AddressType':[],
      "groupFields":[],
      'multiplecheckbox':[],
      'multiplevalue':[],
      "multipleboxes":[],
      'rating':{
        "accept":'',
        "value":'',
        "size":'',
        "description":'',
        "arrsize":[]
      },
      'LikertQuestions':{
        "Questions":[],
        "values":[]
      },
      "Nationality":false,
      "PhoneCode":false
         
        }]
      },
      "then_apply": {
        "type": "percent",
        "value": ""
      },
      "proof": "no",
      "discount_proof_title": "",
      "discount_proof_description": "",
      "discount_proof_files_allowed": [],
      "discount_proof_number_of_files": {
        "min": null,
        "max": null
      },
      "discount_proof_file_size": {
        "min": null,
        "max": null
      }
    }
  ]
  getprice:any
  apihit:boolean=true
  deleteFieldUpdate: boolean = false;

    constructor(
      private formService: FormsService,
      private discountGroupService: DiscountGroupService,
      private router: Router,
      private activatedRoute: ActivatedRoute,
      private ngZone: NgZone,
      private common:CommonService,
    private apiClientService: ApiClientService

      ) { }
    maxvalidationapply:boolean=true
    page_Num_arr:any[]=[]
    d2arr:any[]=[]

    ngOnChanges(changes: SimpleChanges) {
      // this.updateFormFields();
    }
  
    ngOnInit(): void {
      // alert('hit')
      console.log('hit11111111111111111111111111111111111',this.discountFields)
      console.log(this.form_price,"FPRMSPRICE")
      this.fetchCountriesList()
      this.handleDiscountRuleConditions();
      this.updateFormFields();
      console.log(this.d2arr,'dddddffffffffffff')
      console.log(this.discountGroupID,'diddddddd')
      // console.log(this.d2arr,'ffffdddddd',this.Break);
   this.Condition_Show=true;
      
     
      if(this.discountGroupRuleID !=''){
        this.maxvalidationapply=false
      }
     
  this.common.getprice().subscribe(res => {
  this.getprice=res
  })
      this.activatedRoute.params.subscribe((params: any) => {
        // 
        this.formid = params.formid;

        if(params['formid']) {
          this.formid = params.formid;
        }

        if(params['groupID']) {
          this.groupid = params['groupID'];
        }
      });

      if(this.groupid === '') {
        this.discountGroupService.getNewGroupID().subscribe(result => {
          this.groupid = result;
        })
      }
    // alert(this.Break)


    
    // console.log(this.discountRuleForm[0].if_condition.conditions[0].field_type,"ddddddddddddddddddd");
    
    if(this.formOwnRuleData.length > 0) {
      this.discountRuleForm = this.formOwnRuleData;
    }
    
    this.formService.getDiscountRuleConditionsList();
    
    this.groupRuleEditData();
    
    this.formService.getUpdatedDiscountRules().subscribe(result => {
      if(result['updateRule'] && !result['deleteRules']) {
        this.deleteFieldUpdate = true;
        this.discountRuleForm = result['data'];
        this.saveRule();
      }
    });
    console.log(this.discountRuleForm,"DDDD")

    }

    updateFormFields() {
    // alert('1')
    console.log("HITTTTTTTTTTTTTTTTTTTTTTTTT!!!!!")
      if(this.discountFields!=undefined){
        this.d2arr = [...this.discountFields]

        
        if(!this.Break){
        // alert('HIT')
        this.d2arr.unshift({'label':'Form-Fields','name':'Form-Fields'})
        // if(this.basicInfo) {
          this.d2arr =[...this.d2arr,...this.basicInfoFieldsfornormal]
        // }
        console.log(this.d2arr,'r=r=r=r=r=r=')


      }else{
        // alert('XXCCC')
        this.d2arr.unshift([{'label':'Form-Fields','name':'Form-Fields'}])
        this.d2arr.push(this.basicInfoFieldsfornormal)
        
        this.page_Num_arr 
        if(Array.isArray(this.d2arr) ==true){
          // console.log(this.di)
          this.d2arr.forEach((x,i)=>{
              console.log(x[0],'yyyyy');
              if(x[0].type =='break'){
                this.page_Num_arr.push(x[0].pageTitle)
              }
              
          })
        }
        
      }
      console.log(this.discountRuleForm,"UUUUU")
      // if(!this.Break){
      //   // if(this.d2arr !== undefined && this.d2arr.length > 0) {
      //   //   this.discountRuleForm[0].if_condition.conditions[0].field_type = this.d2arr[0].name;
      //   // }
      // }
      //   else{
      //    this.page_Num_arr 
      //    if(Array.isArray(this.d2arr) ==true){
      // // console.log(this.di)
      //      this.d2arr.forEach((x,i)=>{
      //          console.log(x[0],'yyyyy');
      //          if(x[0].type =='break'){
      //            this.page_Num_arr.push(x[0].pageTitle)
      //          }
            
      //      })
      //    }
      //     // this.d2arr.forEach((x,i)=>{
      //     //     if(x[0].type =='break'){
      //     //       this.page_Num_arr.push(x[0].pageTitle)
      //     //     }
           
      //     // })
      //   }
      }
    }

    AddID(id,ind,ind2){
      this.discountRuleForm[ind].if_condition.conditions[ind2].value= id.toString()
      console.log(this.discountRuleForm[ind].if_condition.conditions[ind2].value,"asdasdas",id.toString())
      console.log(this.discountRuleForm[ind].if_condition.conditions,"qwq-==",id.toString())

    }
    PageSelect(e:any){
      this.d2arr.forEach((x,i)=>{
       if(x[0].pageTitle == e.target.value){
       this.page_arr_fields=x
       }
      })
    }
    MaxDiscountLimit(event: any,index) {
    if(this.discountRuleForm[index].then_apply.type=='amount'){
      if (parseInt( event.target.value)>parseInt(this.getprice)) {
         Swal.fire({
          position: 'center',
          icon: 'error',
         title:'Discount Price should not be greater than form price',
          padding: '2em',
        });
        this.discountRuleForm[index].then_apply.value=''
        // this.discountRuleForm[index]
      }

    }else{
      if (parseInt( event.target.value)>100) {
        // return false;
        Swal.fire({
          position: 'center',
          icon: 'error',
         title:'Discount Percentage should not be greater than 100',
          padding: '2em',
        });
        this.discountRuleForm[index].then_apply.value=''
        // this.discountRuleForm[index]
      }
    }
    
        // return true;
   
   
  }
  MaxDiscountLimitgroupLink(event: any,index) {

    if(this.discountRuleForm[index].then_apply.type=='amount'){
      if (parseInt( event.target.value)<parseInt(this.discountGroupminprice)) {
         Swal.fire({
          position: 'center',
          icon: 'error',
         title:'Discount Price should not be less its use in some form already which leads to conflict',
          padding: '2em',
        });
        this.discountRuleForm[index].then_apply.value=this.discountGroupminprice
        // this.discountRuleForm[index]
      }

    }else{
      if (parseInt( event.target.value)>100) {
        // return false;
        Swal.fire({
          position: 'center',
          icon: 'error',
         title:'Discount Percentage should not be greater than 100',
          padding: '2em',
        });
        this.discountRuleForm[index].then_apply.value=''
        // this.discountRuleForm[index]
      }
    }
    
        // return true;
   
   
  }
  handleDiscountRuleConditions() {
    if(this.discountGroupID==''){

    this.formService.getDiscountRuleConditionsData().subscribe((result: any) => {
    // alert('hit')
      console.log(result,'rrr')
      if(result['status']) {
    // alert('hit2')

        this.ruleConditionList = result['data'];
        this.discountRuleForm[0].if_condition.conditions[0].FieldConstraint=result['data'];
        this.conditionRows[0].constraint = this.ruleConditionList[0].name;
        this.conditionRows[0].constraint_id = this.ruleConditionList[0].id;
      }
    })
  }else{
    console.log('INNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNN++++++++++++++++++++++')
    this.apiClientService.get('get_rules_conditions').subscribe((result: any) => {
      this.ruleConditionList = result['data'];
      this.conditionRows[0].constraint = this.ruleConditionList[0].name;
        this.conditionRows[0].constraint_id = this.ruleConditionList[0].id;
      // alert('data')
      // console.log(data);
    }, error => {
    });
  }
  }

  // onUserInfoFieldSelect(infoField: string, index: number) {
  // }
//Field Select Change
fetchCountriesList() {
  return this.formService.getCountriesList().subscribe((result: any) => {
    this.countriesList = result["data"];
    this.countriesList.forEach((count,i)=>{
      count.country_code=count.country_code.toLowerCase()
              })
  });
}
onUserInfoFieldSelect(val,ind,ind2){
  if(!val.includes('group')){
    this.discountRuleForm[ind].if_condition.conditions[ind2].field_type=val
    this.discountRuleForm[ind].if_condition.conditions[ind2].group_type=''


  }else{
    this.discountRuleForm[ind].if_condition.conditions[ind2].group_type=val

  }
  console.log(val,"THIS IS VAL ")
  let vals =[]
  vals =this.basicInfoFieldsfornormal.filter((fi,ki)=>{
    console.log(val,'====',fi.name)
    return val ==fi.name
  })
  console.log(vals,'valsssss')
  if(vals.length ==0){
    this.discountRuleForm[ind].if_condition.conditions[ind2].basic_info_field='false'

  }else{

    this.discountRuleForm[ind].if_condition.conditions[ind2].basic_info_field='true'
  }

  console.log(this.discountRuleForm[ind].if_condition.conditions)
  // if-else for conditions
  if(val.includes('file')){
     this.discountRuleForm[ind].if_condition.conditions[ind2].FieldConstraint= this.ruleConditionList.filter((field) =>{ return field.name =='Is Empty'||field.name =='Is Not Empty'})
     this.discountRuleForm[ind].if_condition.conditions[ind2].FileEmpt=true
    //  val.preventDefault();
    //  alert('file')
    //// console.log('FileEmpt',this.discountRuleForm)
    }else if (val.includes('rating')){
     this.discountRuleForm[ind].if_condition.conditions[ind2].FieldConstraint= this.ruleConditionList.filter((field) =>{ return field.name =='Is'||field.name =='Is Not'||field.name =='Is Empty'||field.name =='Is Not Empty'})

    }
      else if (val.includes('range')){
        this.discountRuleForm[ind].if_condition.conditions[ind2].FieldConstraint= this.ruleConditionList.filter((field) =>{ return field.name =='Is'||field.name =='Is Not'||field.name =='Is Empty'||field.name =='Is Not Empty'})

      }
     
    else{
      // alert('hit')
      this.discountRuleForm[ind].if_condition.conditions[ind2].FieldConstraint=this.ruleConditionList
      
    }
  // if-else for conditions values

 if(val.includes('date') && !val.includes('datetime')){
  this.discountRuleForm[ind].if_condition.conditions[ind2].valforboxes={}
  this.discountRuleForm[ind].if_condition.conditions[ind2].AddressType=[]
  this.discountRuleForm[ind].if_condition.conditions[ind2].multiplecheckbox=[]
  this.discountRuleForm[ind].if_condition.conditions[ind2].rating.accept=''
  this.discountRuleForm[ind].if_condition.conditions[ind2].rating.value=''
  this.discountRuleForm[ind].if_condition.conditions[ind2].rating.size=''
  this.discountRuleForm[ind].if_condition.conditions[ind2].rating.description=''
  this.discountRuleForm[ind].if_condition.conditions[ind2].groupFields=[]
  this.discountRuleForm[ind].if_condition.conditions[ind2].LikertQuestions.Questions=[]
  this.discountRuleForm[ind].if_condition.conditions[ind2].LikertQuestions.values=[]
  this.discountRuleForm[ind].if_condition.conditions[ind2].valforradio={}


  this.discountRuleForm[ind].if_condition.conditions[ind2].conditiontype='date'
  this.discountRuleForm[ind].if_condition.conditions[ind2].FieldConstraint= this.ruleConditionList.filter((field) =>{ return field.name =='Is'||field.name =='Is Not'||field.name =='Is Empty'||field.name =='Is Not Empty' || field.name =='Less Than <' || field.name =='Greater Than >'  })


 }
 else if(val.includes('datetime')){
  this.discountRuleForm[ind].if_condition.conditions[ind2].valforboxes={}
  this.discountRuleForm[ind].if_condition.conditions[ind2].AddressType=[]
  this.discountRuleForm[ind].if_condition.conditions[ind2].multiplecheckbox=[]
  this.discountRuleForm[ind].if_condition.conditions[ind2].rating.accept=''
  this.discountRuleForm[ind].if_condition.conditions[ind2].rating.value=''
  this.discountRuleForm[ind].if_condition.conditions[ind2].rating.size=''
  this.discountRuleForm[ind].if_condition.conditions[ind2].rating.description=''
  this.discountRuleForm[ind].if_condition.conditions[ind2].groupFields=[]
  this.discountRuleForm[ind].if_condition.conditions[ind2].LikertQuestions.Questions=[]
  this.discountRuleForm[ind].if_condition.conditions[ind2].LikertQuestions.values=[]
  this.discountRuleForm[ind].if_condition.conditions[ind2].valforradio={}

  this.discountRuleForm[ind].if_condition.conditions[ind2].conditiontype='datetime-local'
  this.discountRuleForm[ind].if_condition.conditions[ind2].FieldConstraint= this.ruleConditionList.filter((field) =>{ return field.name =='Is'||field.name =='Is Not'||field.name =='Is Empty'||field.name =='Is Not Empty' || field.name =='Less Than <' || field.name =='Greater Than >'  })

 }
 else if(val.includes('time')){
  this.discountRuleForm[ind].if_condition.conditions[ind2].valforboxes={}
  this.discountRuleForm[ind].if_condition.conditions[ind2].AddressType=[]
  this.discountRuleForm[ind].if_condition.conditions[ind2].multiplecheckbox=[]
  this.discountRuleForm[ind].if_condition.conditions[ind2].rating.accept=''
  this.discountRuleForm[ind].if_condition.conditions[ind2].rating.value=''
  this.discountRuleForm[ind].if_condition.conditions[ind2].rating.size=''
  this.discountRuleForm[ind].if_condition.conditions[ind2].rating.description=''
  this.discountRuleForm[ind].if_condition.conditions[ind2].groupFields=[]
  this.discountRuleForm[ind].if_condition.conditions[ind2].LikertQuestions.Questions=[]
  this.discountRuleForm[ind].if_condition.conditions[ind2].LikertQuestions.values=[]
  this.discountRuleForm[ind].if_condition.conditions[ind2].valforradio={}


  this.discountRuleForm[ind].if_condition.conditions[ind2].conditiontype='time'
  this.discountRuleForm[ind].if_condition.conditions[ind2].FieldConstraint= this.ruleConditionList.filter((field) =>{ return field.name =='Is'||field.name =='Is Not'||field.name =='Is Empty'||field.name =='Is Not Empty'})

 }
 else if(val.includes('checkbox')){

  // newone.forEach((item)=>{

    this.discountRuleForm[ind].if_condition.conditions[ind2].AddressType=[]
    this.discountRuleForm[ind].if_condition.conditions[ind2].multiplecheckbox=[]
    this.discountRuleForm[ind].if_condition.conditions[ind2].rating.accept=''
    this.discountRuleForm[ind].if_condition.conditions[ind2].rating.value=''
    this.discountRuleForm[ind].if_condition.conditions[ind2].rating.size=''
    this.discountRuleForm[ind].if_condition.conditions[ind2].rating.description=''
    this.discountRuleForm[ind].if_condition.conditions[ind2].groupFields=[]
  this.discountRuleForm[ind].if_condition.conditions[ind2].valforradio={}

    this.discountRuleForm[ind].if_condition.conditions[ind2].LikertQuestions.Questions=[]
    this.discountRuleForm[ind].if_condition.conditions[ind2].LikertQuestions.values=[]
  this.discountRuleForm[ind].if_condition.conditions[ind2].FieldConstraint= this.ruleConditionList.filter((field) =>{ return field.name =='Is'||field.name =='Is Not'||field.name =='Is Empty'||field.name =='Is Not Empty'})

    // this.discountRuleForm[ind].if_condition.conditions[ind2].FieldConstraint=this.ruleConditionList

if(!this.Break){
let newone= this.d2arr.find((item)=>{
  return item.name==val
      })
      this.discountRuleForm[ind].if_condition.conditions[ind2].valforboxes=newone
}else{
// console.log('val',val)
let newone =[]
this.d2arr.forEach((fields)=>{
fields.filter((item)=>{
// console.log(item,'item')
if(item.name==val){
 return newone.push(item)
}

})          
})

// this.discountRuleForm[ind].if_condition.conditions[ind2].valforboxes=newone
 //only for one time loop
 newone.forEach((item)=>{
  this.discountRuleForm[ind].if_condition.conditions[ind2].valforboxes=item

})

}

 }
 else if (val.includes('rating')){

    this.discountRuleForm[ind].if_condition.conditions[ind2].groupFields=[]
    this.discountRuleForm[ind].if_condition.conditions[ind2].valforboxes={}
    this.discountRuleForm[ind].if_condition.conditions[ind2].AddressType=[]
    this.discountRuleForm[ind].if_condition.conditions[ind2].multiplecheckbox=[]
  this.discountRuleForm[ind].if_condition.conditions[ind2].valforradio={}
 
    this.discountRuleForm[ind].if_condition.conditions[ind2].groupFields=[]
    this.discountRuleForm[ind].if_condition.conditions[ind2].LikertQuestions.Questions=[]
    this.discountRuleForm[ind].if_condition.conditions[ind2].LikertQuestions.values=[]
if(!this.Break){
let newone= this.d2arr.find((item)=>{
  return item.name==val
      })
      this.discountRuleForm[ind].if_condition.conditions[ind2].rating.accept=newone.accept
      this.discountRuleForm[ind].if_condition.conditions[ind2].rating.value=newone.value
      this.discountRuleForm[ind].if_condition.conditions[ind2].rating.size=newone.size
      this.discountRuleForm[ind].if_condition.conditions[ind2].rating.description=newone.description
}else{
let newone =[]

this.d2arr.forEach((fields)=>{
  fields.filter((item)=>{
  // console.log(item,'item')
  if(item.name==val){
    return newone.push(item)
   }
   
  })    
  this.discountRuleForm[ind].if_condition.conditions[ind2].rating.accept=newone[0].accept
  this.discountRuleForm[ind].if_condition.conditions[ind2].rating.value=newone[0].value
  this.discountRuleForm[ind].if_condition.conditions[ind2].rating.size=newone[0].size
  this.discountRuleForm[ind].if_condition.conditions[ind2].rating.description=newone[0].description
  })
}

  
        
 }
 else if(val.includes('radio')){



  this.discountRuleForm[ind].if_condition.conditions[ind2].AddressType=[]
  this.discountRuleForm[ind].if_condition.conditions[ind2].valforboxes={}

  this.discountRuleForm[ind].if_condition.conditions[ind2].multiplecheckbox=[]
  this.discountRuleForm[ind].if_condition.conditions[ind2].rating.accept=''
  this.discountRuleForm[ind].if_condition.conditions[ind2].rating.value=''
  this.discountRuleForm[ind].if_condition.conditions[ind2].rating.size=''
  this.discountRuleForm[ind].if_condition.conditions[ind2].rating.description=''
  this.discountRuleForm[ind].if_condition.conditions[ind2].groupFields=[]
  this.discountRuleForm[ind].if_condition.conditions[ind2].LikertQuestions.Questions=[]
  this.discountRuleForm[ind].if_condition.conditions[ind2].LikertQuestions.values=[]
  // this.discountRuleForm[ind].if_condition.conditions[ind2].FieldConstraint=this.ruleConditionList
  this.discountRuleForm[ind].if_condition.conditions[ind2].FieldConstraint= this.ruleConditionList.filter((field) =>{ return field.name =='Is'||field.name =='Is Not'||field.name =='Is Empty'||field.name =='Is Not Empty'})

console.log(this.discountRuleForm,'ffff11)')
if(!this.Break){
let newone= this.d2arr.find((item)=>{
  return item.name==val
      })
      this.discountRuleForm[ind].if_condition.conditions[ind2].valforradio=newone
}else{
let newone =[]

this.d2arr.forEach((fields)=>{
  fields.filter((item)=>{
  // console.log(item,'item')
  if(item.name==val){
    return newone.push(item)
   }
   
  })    
// console.log(newone,'newone')     
// console.log(this.discountRuleForm,'ffdd') 
  //only for one time loop
  newone.forEach((item)=>{
    this.discountRuleForm[ind].if_condition.conditions[ind2].valforradio=item

  })
  })
  
}

 }
 else if(val.includes('autocomplete')){
// console.log(this.d2arr,'ggyyy')

  this.discountRuleForm[ind].if_condition.conditions[ind2].valforboxes={}
  this.discountRuleForm[ind].if_condition.conditions[ind2].AddressType=[]

  this.discountRuleForm[ind].if_condition.conditions[ind2].rating.accept=''
  this.discountRuleForm[ind].if_condition.conditions[ind2].rating.value=''
  this.discountRuleForm[ind].if_condition.conditions[ind2].rating.size=''
  this.discountRuleForm[ind].if_condition.conditions[ind2].rating.description=''
  this.discountRuleForm[ind].if_condition.conditions[ind2].groupFields=[]
  this.discountRuleForm[ind].if_condition.conditions[ind2].LikertQuestions.Questions=[]
  this.discountRuleForm[ind].if_condition.conditions[ind2].LikertQuestions.values=[]
  this.discountRuleForm[ind].if_condition.conditions[ind2].valforradio={}

  this.discountRuleForm[ind].if_condition.conditions[ind2].FieldConstraint= this.ruleConditionList.filter((field) =>{ return field.name =='Is'||field.name =='Is Not'||field.name =='Is Empty'||field.name =='Is Not Empty'})

console.log(this.discountRuleForm,'dddff');
// this.discountRuleForm[ind].if_condition.conditions[ind2].FieldConstraint=this.ruleConditionList

console.log(this.discountRuleForm,'ffff11)')
if(!this.Break){
let newone= this.d2arr.find((item)=>{
  return item.name==val
      })
  this.discountRuleForm[ind].if_condition.conditions[ind2].multiplecheckbox=newone.values

    // console.log(newone,'newwwaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa')
}else{
let newone =[]

this.d2arr.forEach((fields)=>{
  fields.filter((item)=>{
  // console.log(item,'item')
  if(item.name==val){
    return newone.push(item)
   }
   
  })    
// console.log(newone,'newone')     
// console.log(this.discountRuleForm,'ffdd') 
  this.discountRuleForm[ind].if_condition.conditions[ind2].multiplecheckbox=newone[0].values
// console.log(this.discountRuleForm,'fff')
  //  newone.forEach((item)=>{
  //   this.discountRuleForm[ind].if_condition.conditions[ind2].valforboxes=item

  // })
  })

}


 }
 else if(val.includes('address-group')){
  // alert('address-group')
// console.log(this.d2arr,'ggyyy')
  
        this.discountRuleForm[ind].if_condition.conditions[ind2].valforboxes={}
        
        this.discountRuleForm[ind].if_condition.conditions[ind2].multiplecheckbox=[]
        this.discountRuleForm[ind].if_condition.conditions[ind2].rating.accept=''
        this.discountRuleForm[ind].if_condition.conditions[ind2].rating.value=''
        this.discountRuleForm[ind].if_condition.conditions[ind2].rating.size=''
        this.discountRuleForm[ind].if_condition.conditions[ind2].rating.description=''
        this.discountRuleForm[ind].if_condition.conditions[ind2].groupFields=[]
        this.discountRuleForm[ind].if_condition.conditions[ind2].LikertQuestions.Questions=[]
        this.discountRuleForm[ind].if_condition.conditions[ind2].LikertQuestions.values=[]
  this.discountRuleForm[ind].if_condition.conditions[ind2].valforradio={}

        if(!this.Break){
          let newone= this.d2arr.find((item)=>{
            return item.name==val
                })
                
                this.discountRuleForm[ind].if_condition.conditions[ind2].AddressType=newone.addressGroup
        }
        else{
        // console.log('val',val)
          let newone =[]
        this.d2arr.forEach((fields)=>{
      fields.filter((item)=>{
      // console.log(item,'item')
         if(item.name==val){
           return newone.push(item)
         }
         
        })          
      })
    // console.log(newone,'fieldsnewonebreak')
      this.discountRuleForm[ind].if_condition.conditions[ind2].AddressType=newone[0].addressGroup

          
        }

// console.log(this.discountRuleForm,"ffff")
 }
 else if(val.includes('group')){
  // alert('group')
// console.log(this.d2arr,'ggyyy')

        this.discountRuleForm[ind].if_condition.conditions[ind2].valforboxes={}
        this.discountRuleForm[ind].if_condition.conditions[ind2].conditiontype='text'
        this.discountRuleForm[ind].if_condition.conditions[ind2].AddressType=[]
        this.discountRuleForm[ind].if_condition.conditions[ind2].multiplecheckbox=[]
        this.discountRuleForm[ind].if_condition.conditions[ind2].rating.accept=''
        this.discountRuleForm[ind].if_condition.conditions[ind2].rating.value=''
        this.discountRuleForm[ind].if_condition.conditions[ind2].rating.size=''
        this.discountRuleForm[ind].if_condition.conditions[ind2].rating.description=''
  this.discountRuleForm[ind].if_condition.conditions[ind2].valforradio={}


        this.discountRuleForm[ind].if_condition.conditions[ind2].LikertQuestions.Questions=[]
        this.discountRuleForm[ind].if_condition.conditions[ind2].LikertQuestions.values=[]
if(!this.Break){
let newone= this.d2arr.find((item)=>{
  return item.name==val
      })
      this.discountRuleForm[ind].if_condition.conditions[ind2].groupFields=newone.groupFields
}else{
let newone =[]
console.log(this.d2arr,'fffyy')
this.d2arr.forEach((fields)=>{
  console.log(fields,'fieldsss');
  
  fields.forEach((item)=>{
console.log(item,'item')
  if(item.type=='group'){
    return newone.push(item)
   }
   
  })    
console.log(newone,'newonefff')     
console.log(this.discountRuleForm,'ffdd') 
// console.log(this.discountRuleForm,'aftergroupClick')
})
this.discountRuleForm[ind].if_condition.conditions[ind2].groupFields=newone[0].groupFields
}


console.log(this.discountRuleForm,"ffff")
 }
 else if (val.includes('likert')){
 console.log("likertinnn")
        this.discountRuleForm[ind].if_condition.conditions[ind2].valforboxes={}
        this.discountRuleForm[ind].if_condition.conditions[ind2].conditiontype='text'
        this.discountRuleForm[ind].if_condition.conditions[ind2].AddressType=[]
        this.discountRuleForm[ind].if_condition.conditions[ind2].multiplecheckbox=[]
        this.discountRuleForm[ind].if_condition.conditions[ind2].rating.accept=''
        this.discountRuleForm[ind].if_condition.conditions[ind2].rating.value=''
        this.discountRuleForm[ind].if_condition.conditions[ind2].rating.size=''
        this.discountRuleForm[ind].if_condition.conditions[ind2].rating.description=''
        this.discountRuleForm[ind].if_condition.conditions[ind2].groupFields=[]
  this.discountRuleForm[ind].if_condition.conditions[ind2].valforradio={}

   

        if(!this.Break){
          let newone= this.d2arr.find((item)=>{
            return item.name==val
                })
              // console.log(newone,'likertnewone')    
    this.discountRuleForm[ind].if_condition.conditions[ind2].LikertQuestions.Questions=newone.QuestionArr
    this.discountRuleForm[ind].if_condition.conditions[ind2].LikertQuestions.values=newone.QuestionArr[0].values
// console.log(this.discountRuleForm,"ffddfdfdfdfd====");

        }else{
           let newone =[]

this.d2arr.forEach((fields)=>{
  fields.filter((item)=>{
  // console.log(item,'item')
  if(item.name==val){
    return newone.push(item)
   }
   
  })    
// console.log(newone,'newoneliikppp')   
})
this.discountRuleForm[ind].if_condition.conditions[ind2].LikertQuestions.Questions=newone[0].QuestionArr
this.discountRuleForm[ind].if_condition.conditions[ind2].LikertQuestions.values=newone[0].QuestionArr[0].values
        }

       }
       else if (val.includes('nationality')){
        this.discountRuleForm[ind].if_condition.conditions[ind2].valforboxes={}
        this.discountRuleForm[ind].if_condition.conditions[ind2].conditiontype='text'
        this.discountRuleForm[ind].if_condition.conditions[ind2].AddressType=[]
        this.discountRuleForm[ind].if_condition.conditions[ind2].multiplecheckbox=[]
        this.discountRuleForm[ind].if_condition.conditions[ind2].rating.accept=''
        this.discountRuleForm[ind].if_condition.conditions[ind2].rating.value=''
        this.discountRuleForm[ind].if_condition.conditions[ind2].rating.size=''
        this.discountRuleForm[ind].if_condition.conditions[ind2].rating.description=''
        this.discountRuleForm[ind].if_condition.conditions[ind2].groupFields=[]
        this.discountRuleForm[ind].if_condition.conditions[ind2].LikertQuestions.Questions=[]
        this.discountRuleForm[ind].if_condition.conditions[ind2].LikertQuestions.values=[]
        this.discountRuleForm[ind].if_condition.conditions[ind2].Nationality=true
        this.discountRuleForm[ind].if_condition.conditions[ind2].PhoneCode=false
        this.discountRuleForm[ind].if_condition.conditions[ind2].value=""
  this.discountRuleForm[ind].if_condition.conditions[ind2].valforradio={}


        this.discountRuleForm[ind].if_condition.conditions[ind2].FieldConstraint= this.ruleConditionList.filter((field) =>{ return field.name =='Is'||field.name =='Is Not'||field.name =='Is Empty'||field.name =='Is Not Empty' })

       }
       else if (val.includes('phone_code')){
        this.discountRuleForm[ind].if_condition.conditions[ind2].valforboxes={}
        this.discountRuleForm[ind].if_condition.conditions[ind2].conditiontype='text'
        this.discountRuleForm[ind].if_condition.conditions[ind2].AddressType=[]
        this.discountRuleForm[ind].if_condition.conditions[ind2].multiplecheckbox=[]
        this.discountRuleForm[ind].if_condition.conditions[ind2].rating.accept=''
        this.discountRuleForm[ind].if_condition.conditions[ind2].rating.value=''
        this.discountRuleForm[ind].if_condition.conditions[ind2].rating.size=''
        this.discountRuleForm[ind].if_condition.conditions[ind2].rating.description=''
        this.discountRuleForm[ind].if_condition.conditions[ind2].groupFields=[]
        this.discountRuleForm[ind].if_condition.conditions[ind2].LikertQuestions.Questions=[]
        this.discountRuleForm[ind].if_condition.conditions[ind2].LikertQuestions.values=[]
        this.discountRuleForm[ind].if_condition.conditions[ind2].Nationality=false
        this.discountRuleForm[ind].if_condition.conditions[ind2].PhoneCode=true
        this.discountRuleForm[ind].if_condition.conditions[ind2].value=""
  this.discountRuleForm[ind].if_condition.conditions[ind2].valforradio={}


        this.discountRuleForm[ind].if_condition.conditions[ind2].FieldConstraint= this.ruleConditionList.filter((field) =>{ return field.name =='Is'||field.name =='Is Not'||field.name =='Is Empty'||field.name =='Is Not Empty' })

       }
       else if (val.includes('phone_number')){
        this.discountRuleForm[ind].if_condition.conditions[ind2].valforboxes={}
        this.discountRuleForm[ind].if_condition.conditions[ind2].conditiontype='text'
        this.discountRuleForm[ind].if_condition.conditions[ind2].AddressType=[]
        this.discountRuleForm[ind].if_condition.conditions[ind2].multiplecheckbox=[]
        this.discountRuleForm[ind].if_condition.conditions[ind2].rating.accept=''
        this.discountRuleForm[ind].if_condition.conditions[ind2].rating.value=''
        this.discountRuleForm[ind].if_condition.conditions[ind2].rating.size=''
        this.discountRuleForm[ind].if_condition.conditions[ind2].rating.description=''
        this.discountRuleForm[ind].if_condition.conditions[ind2].groupFields=[]
        this.discountRuleForm[ind].if_condition.conditions[ind2].LikertQuestions.Questions=[]
        this.discountRuleForm[ind].if_condition.conditions[ind2].LikertQuestions.values=[]
        this.discountRuleForm[ind].if_condition.conditions[ind2].Nationality=false
        this.discountRuleForm[ind].if_condition.conditions[ind2].PhoneCode=false
        this.discountRuleForm[ind].if_condition.conditions[ind2].value=""
        this.discountRuleForm[ind].if_condition.conditions[ind2].valforradio={}

        this.discountRuleForm[ind].if_condition.conditions[ind2].FieldConstraint= this.ruleConditionList.filter((field) =>{ return field.name =='Is'||field.name =='Is Not'||field.name =='Is Empty'||field.name =='Is Not Empty' })

       }
       else if (val.includes('first_name')){
        this.discountRuleForm[ind].if_condition.conditions[ind2].valforboxes={}
        this.discountRuleForm[ind].if_condition.conditions[ind2].conditiontype='text'
        this.discountRuleForm[ind].if_condition.conditions[ind2].AddressType=[]
        this.discountRuleForm[ind].if_condition.conditions[ind2].multiplecheckbox=[]
        this.discountRuleForm[ind].if_condition.conditions[ind2].rating.accept=''
        this.discountRuleForm[ind].if_condition.conditions[ind2].rating.value=''
        this.discountRuleForm[ind].if_condition.conditions[ind2].rating.size=''
        this.discountRuleForm[ind].if_condition.conditions[ind2].rating.description=''
        this.discountRuleForm[ind].if_condition.conditions[ind2].groupFields=[]
        this.discountRuleForm[ind].if_condition.conditions[ind2].LikertQuestions.Questions=[]
        this.discountRuleForm[ind].if_condition.conditions[ind2].LikertQuestions.values=[]
        this.discountRuleForm[ind].if_condition.conditions[ind2].Nationality=false
        this.discountRuleForm[ind].if_condition.conditions[ind2].PhoneCode=false
        this.discountRuleForm[ind].if_condition.conditions[ind2].value=""
  this.discountRuleForm[ind].if_condition.conditions[ind2].valforradio={}


        this.discountRuleForm[ind].if_condition.conditions[ind2].FieldConstraint= this.ruleConditionList.filter((field) =>{ return field.name =='Is'||field.name =='Is Not'||field.name =='Is Empty'||field.name =='Is Not Empty' })

       }
       else if (val.includes('last_name')){
        this.discountRuleForm[ind].if_condition.conditions[ind2].valforboxes={}
        this.discountRuleForm[ind].if_condition.conditions[ind2].conditiontype='text'
        this.discountRuleForm[ind].if_condition.conditions[ind2].AddressType=[]
        this.discountRuleForm[ind].if_condition.conditions[ind2].multiplecheckbox=[]
        this.discountRuleForm[ind].if_condition.conditions[ind2].rating.accept=''
        this.discountRuleForm[ind].if_condition.conditions[ind2].rating.value=''
        this.discountRuleForm[ind].if_condition.conditions[ind2].rating.size=''
        this.discountRuleForm[ind].if_condition.conditions[ind2].rating.description=''
        this.discountRuleForm[ind].if_condition.conditions[ind2].groupFields=[]
        this.discountRuleForm[ind].if_condition.conditions[ind2].LikertQuestions.Questions=[]
        this.discountRuleForm[ind].if_condition.conditions[ind2].LikertQuestions.values=[]
        this.discountRuleForm[ind].if_condition.conditions[ind2].Nationality=false
        this.discountRuleForm[ind].if_condition.conditions[ind2].PhoneCode=false
        this.discountRuleForm[ind].if_condition.conditions[ind2].value=""
        this.discountRuleForm[ind].if_condition.conditions[ind2].valforradio={}

        this.discountRuleForm[ind].if_condition.conditions[ind2].FieldConstraint= this.ruleConditionList.filter((field) =>{ return field.name =='Is'||field.name =='Is Not'||field.name =='Is Empty'||field.name =='Is Not Empty' })

       }
       else if (val.includes('email')){
        this.discountRuleForm[ind].if_condition.conditions[ind2].valforboxes={}
        this.discountRuleForm[ind].if_condition.conditions[ind2].conditiontype='text'
        this.discountRuleForm[ind].if_condition.conditions[ind2].AddressType=[]
        this.discountRuleForm[ind].if_condition.conditions[ind2].multiplecheckbox=[]
        this.discountRuleForm[ind].if_condition.conditions[ind2].rating.accept=''
        this.discountRuleForm[ind].if_condition.conditions[ind2].rating.value=''
        this.discountRuleForm[ind].if_condition.conditions[ind2].rating.size=''
        this.discountRuleForm[ind].if_condition.conditions[ind2].rating.description=''
        this.discountRuleForm[ind].if_condition.conditions[ind2].groupFields=[]
        this.discountRuleForm[ind].if_condition.conditions[ind2].LikertQuestions.Questions=[]
        this.discountRuleForm[ind].if_condition.conditions[ind2].LikertQuestions.values=[]
        this.discountRuleForm[ind].if_condition.conditions[ind2].Nationality=false
        this.discountRuleForm[ind].if_condition.conditions[ind2].PhoneCode=false
        this.discountRuleForm[ind].if_condition.conditions[ind2].value=""
        this.discountRuleForm[ind].if_condition.conditions[ind2].valforradio={}

        this.discountRuleForm[ind].if_condition.conditions[ind2].FieldConstraint= this.ruleConditionList.filter((field) =>{ return field.name =='Is'||field.name =='Is Not'||field.name =='Is Empty'||field.name =='Is Not Empty' })

       }
       else if (val.includes('dob')){
        this.discountRuleForm[ind].if_condition.conditions[ind2].valforboxes={}
        this.discountRuleForm[ind].if_condition.conditions[ind2].conditiontype='text'
        this.discountRuleForm[ind].if_condition.conditions[ind2].AddressType=[]
        this.discountRuleForm[ind].if_condition.conditions[ind2].multiplecheckbox=[]
        this.discountRuleForm[ind].if_condition.conditions[ind2].rating.accept=''
        this.discountRuleForm[ind].if_condition.conditions[ind2].rating.value=''
        this.discountRuleForm[ind].if_condition.conditions[ind2].rating.size=''
        this.discountRuleForm[ind].if_condition.conditions[ind2].rating.description=''
        this.discountRuleForm[ind].if_condition.conditions[ind2].groupFields=[]
        this.discountRuleForm[ind].if_condition.conditions[ind2].LikertQuestions.Questions=[]
        this.discountRuleForm[ind].if_condition.conditions[ind2].LikertQuestions.values=[]
        this.discountRuleForm[ind].if_condition.conditions[ind2].Nationality=false
        this.discountRuleForm[ind].if_condition.conditions[ind2].PhoneCode=false
        this.discountRuleForm[ind].if_condition.conditions[ind2].value=""
  this.discountRuleForm[ind].if_condition.conditions[ind2].valforradio={}

        this.discountRuleForm[ind].if_condition.conditions[ind2].FieldConstraint= this.ruleConditionList.filter((field) =>{ return field.name =='Is'||field.name =='Is Not'||field.name =='Is Empty'||field.name =='Is Not Empty' })

  this.discountRuleForm[ind].if_condition.conditions[ind2].FieldConstraint= this.ruleConditionList.filter((field) =>{ return field.name =='Is'||field.name =='Is Not'||field.name =='Is Empty'||field.name =='Is Not Empty' || field.name =='Less Than <' || field.name =='Greater Than >'  })

       }
 else{  
      // alert('hit')

  this.discountRuleForm[ind].if_condition.conditions[ind2].valforboxes={}
  this.discountRuleForm[ind].if_condition.conditions[ind2].conditiontype='text'
  this.discountRuleForm[ind].if_condition.conditions[ind2].AddressType=[]
  this.discountRuleForm[ind].if_condition.conditions[ind2].multiplecheckbox=[]
  this.discountRuleForm[ind].if_condition.conditions[ind2].rating.accept=''
  this.discountRuleForm[ind].if_condition.conditions[ind2].rating.value=''
  this.discountRuleForm[ind].if_condition.conditions[ind2].rating.size=''
  this.discountRuleForm[ind].if_condition.conditions[ind2].rating.description=''
  this.discountRuleForm[ind].if_condition.conditions[ind2].groupFields=[]
  this.discountRuleForm[ind].if_condition.conditions[ind2].LikertQuestions.Questions=[]
  this.discountRuleForm[ind].if_condition.conditions[ind2].LikertQuestions.values=[]
  this.discountRuleForm[ind].if_condition.conditions[ind2].Nationality=false
  this.discountRuleForm[ind].if_condition.conditions[ind2].valforradio={}

        this.discountRuleForm[ind].if_condition.conditions[ind2].PhoneCode=false
  this.discountRuleForm[ind].if_condition.conditions[ind2].FieldConstraint= this.ruleConditionList.filter((field) =>{ return field.name !='Less Than <' && field.name !='Greater Than >'  })
  console.log( this.discountRuleForm[ind].if_condition.conditions[ind2].FieldConstraint,"uiuiuiuiu");
  

 }
 

  this.Condition_Show=true

  
}
// Field Select Change For Groupfields
onUserInfoFieldSelect3(val,ind,ind2){
// console.log(val,'val');
  
this.discountRuleForm[ind].if_condition.conditions[ind2].field_type=val

  if(val.includes('file')){
    this.discountRuleForm[ind].if_condition.conditions[ind2].valforradio={}

    this.discountRuleForm[ind].if_condition.conditions[ind2].FieldConstraint= this.ruleConditionList.filter((field) =>{ return field.name =='Is Empty'||field.name =='Is Not Empty'})
    this.discountRuleForm[ind].if_condition.conditions[ind2].FileEmpt=true

   }else if (val.includes('rating')){
    this.discountRuleForm[ind].if_condition.conditions[ind2].FieldConstraint= this.ruleConditionList.filter((field) =>{ return field.name =='Is'||field.name =='Is Not'||field.name =='Is Empty'||field.name =='Is Not Empty'})

   }
   else{
     this.discountRuleForm[ind].if_condition.conditions[ind2].FieldConstraint=this.ruleConditionList
   }
   if(val.includes('date') && !val.includes('datetime')){
    this.discountRuleForm[ind].if_condition.conditions[ind2].valforboxes={}
    this.discountRuleForm[ind].if_condition.conditions[ind2].valforradio={}
   
    this.discountRuleForm[ind].if_condition.conditions[ind2].rating.accept=''
    this.discountRuleForm[ind].if_condition.conditions[ind2].rating.value=''
    this.discountRuleForm[ind].if_condition.conditions[ind2].rating.size=''
    this.discountRuleForm[ind].if_condition.conditions[ind2].rating.description=''
    this.discountRuleForm[ind].if_condition.conditions[ind2].LikertQuestions.Questions=[]
    this.discountRuleForm[ind].if_condition.conditions[ind2].LikertQuestions.values=[]
    this.discountRuleForm[ind].if_condition.conditions[ind2].AddressType=[]
    this.discountRuleForm[ind].if_condition.conditions[ind2].multiplecheckbox=[]

    
    this.discountRuleForm[ind].if_condition.conditions[ind2].rating.description=''
    this.discountRuleForm[ind].if_condition.conditions[ind2].conditiontype='date'

   }else if(val.includes('datetime')){
     // alert('datetime')
     this.discountRuleForm[ind].if_condition.conditions[ind2].valforradio={}

     this.discountRuleForm[ind].if_condition.conditions[ind2].valforboxes={}
     this.discountRuleForm[ind].if_condition.conditions[ind2].AddressType=[]
     this.discountRuleForm[ind].if_condition.conditions[ind2].multiplecheckbox=[]
     this.discountRuleForm[ind].if_condition.conditions[ind2].rating.accept=''
     this.discountRuleForm[ind].if_condition.conditions[ind2].rating.value=''
     this.discountRuleForm[ind].if_condition.conditions[ind2].rating.size=''
     this.discountRuleForm[ind].if_condition.conditions[ind2].rating.description=''
     this.discountRuleForm[ind].if_condition.conditions[ind2].conditiontype='datetime-local'
   // console.log(this.discountRuleForm,'ffffffyfyfyfyfyfyfyf========')

   }else if(val.includes('time')){
    // alert('time')
    this.discountRuleForm[ind].if_condition.conditions[ind2].valforboxes={}
    this.discountRuleForm[ind].if_condition.conditions[ind2].valforradio={}
   
    this.discountRuleForm[ind].if_condition.conditions[ind2].rating.accept=''
    this.discountRuleForm[ind].if_condition.conditions[ind2].rating.value=''
    this.discountRuleForm[ind].if_condition.conditions[ind2].rating.size=''
    this.discountRuleForm[ind].if_condition.conditions[ind2].rating.description=''
    this.discountRuleForm[ind].if_condition.conditions[ind2].LikertQuestions.Questions=[]
    this.discountRuleForm[ind].if_condition.conditions[ind2].LikertQuestions.values=[]
    this.discountRuleForm[ind].if_condition.conditions[ind2].AddressType=[]
    this.discountRuleForm[ind].if_condition.conditions[ind2].multiplecheckbox=[]
    this.discountRuleForm[ind].if_condition.conditions[ind2].conditiontype='time'

   }
   else if(val.includes('checkbox')){


    this.discountRuleForm[ind].if_condition.conditions[ind2].valforradio={}
     
      this.discountRuleForm[ind].if_condition.conditions[ind2].rating.accept=''
      this.discountRuleForm[ind].if_condition.conditions[ind2].rating.value=''
      this.discountRuleForm[ind].if_condition.conditions[ind2].rating.size=''
      this.discountRuleForm[ind].if_condition.conditions[ind2].rating.description=''
      this.discountRuleForm[ind].if_condition.conditions[ind2].LikertQuestions.Questions=[]
      this.discountRuleForm[ind].if_condition.conditions[ind2].LikertQuestions.values=[]
      this.discountRuleForm[ind].if_condition.conditions[ind2].AddressType=[]
      this.discountRuleForm[ind].if_condition.conditions[ind2].multiplecheckbox=[]

    // })

    if(!this.Break){
      let fieldarr= this.d2arr.find((item)=>{
        return item.type=='group'
            })
          // console.log(this.d2arr,'ff===')
           let newone =fieldarr.groupFields.find((item)=>{
              return item.name==val
                  })
          // console.log(newone,'newww')
             //only for one time loop

            this.discountRuleForm[ind].if_condition.conditions[ind2].valforboxes=newone
  
    }else{
      
      // let grpone ={}
      let newone=[]
      this.d2arr.forEach((fields)=>{
        fields.forEach((item)=>{
        // console.log(item,'item')
        if (item.type=='group')
        item.groupFields.forEach((item)=>{
          if (item.name ==val){

            return newone.push(item)
          }
  
              })
         
        }) 
      })
    // console.log(newone,"newoneinnnnnnn")
      // for one value in loop
      newone.forEach((item)=>{
        this.discountRuleForm[ind].if_condition.conditions[ind2].valforboxes=item
    
      })
      // this.discountRuleForm[ind].if_condition.conditions[ind2].valforboxes=newone[0].values
   // console.log(this.discountRuleForm,'fieldform')
    
  
  
    }
// this.discountRuleForm[ind].if_condition.conditions[ind2].FieldConstraint=this.ruleConditionList

// console.log(this.discountRuleForm,'ffff11)')

   }
   else if (val.includes('rating')){
    // alert('rating')
    let fieldarr= this.d2arr.find((item)=>{
      return item.type=='group'
          })
          let newone =fieldarr.groupFields.find((item)=>{
            return item.name==val
                })
                this.discountRuleForm[ind].if_condition.conditions[ind2].valforboxes={}
                this.discountRuleForm[ind].if_condition.conditions[ind2].valforradio={}
                this.discountRuleForm[ind].if_condition.conditions[ind2].LikertQuestions.Questions=[]
                this.discountRuleForm[ind].if_condition.conditions[ind2].LikertQuestions.values=[]
                this.discountRuleForm[ind].if_condition.conditions[ind2].AddressType=[]
                this.discountRuleForm[ind].if_condition.conditions[ind2].multiplecheckbox=[]
    this.discountRuleForm[ind].if_condition.conditions[ind2].rating.accept=newone.accept
    this.discountRuleForm[ind].if_condition.conditions[ind2].rating.value=newone.value
    this.discountRuleForm[ind].if_condition.conditions[ind2].rating.size=newone.size
    this.discountRuleForm[ind].if_condition.conditions[ind2].rating.description=newone.description

  // console.log(this.discountRuleForm,'fgfgfgfg');
    
          
   }
   else if(val.includes('radio')){
    
    // this.discountRuleForm[ind].if_condition.conditions[ind2].group_type=val
    this.discountRuleForm[ind].if_condition.conditions[ind2].valforboxes={}
 
          this.discountRuleForm[ind].if_condition.conditions[ind2].rating.accept=''
          this.discountRuleForm[ind].if_condition.conditions[ind2].rating.value=''
          this.discountRuleForm[ind].if_condition.conditions[ind2].rating.size=''
          this.discountRuleForm[ind].if_condition.conditions[ind2].rating.description=''
          this.discountRuleForm[ind].if_condition.conditions[ind2].LikertQuestions.Questions=[]
          this.discountRuleForm[ind].if_condition.conditions[ind2].LikertQuestions.values=[]
          this.discountRuleForm[ind].if_condition.conditions[ind2].AddressType=[]
          this.discountRuleForm[ind].if_condition.conditions[ind2].multiplecheckbox=[]

    if(!this.Break){
      let fieldarr= this.d2arr.find((item)=>{
        return item.type=='group'
            })
          // console.log(this.d2arr,'ff===')
           let newone =fieldarr.groupFields.find((item)=>{
              return item.name==val
                  })
          // console.log(newone,'newww')
            this.discountRuleForm[ind].if_condition.conditions[ind2].valforradio=newone
  
    }else{
  
      // let grpone ={}
    // console.log(val,'val')
      let newone=[]
      this.d2arr.forEach((fields)=>{
        fields.filter((item)=>{
      // console.log(item,'item')
        if (item.type=='group')
        item.groupFields.forEach((item)=>{
          if(item.name == val){

             newone.push(item)
          }
  
              })
         
        }) 
      })
    // console.log(newone,"newone")
        // for one value in loop
        newone.forEach((item)=>{
          this.discountRuleForm[ind].if_condition.conditions[ind2].valforradio=item
      
        })
      // this.discountRuleForm[ind].if_condition.conditions[ind2].valforboxes=newone[0].values
    // console.log(this.discountRuleForm,'fffooorrmmmmm')
  
      // console.log(newone,'newone');
      
      let fieldarr= this.d2arr.find((item)=>{
        return item.type=='group'
            })
    // console.log(fieldarr,'fieldarr')     
  
  
    }
//     this.discountRuleForm[ind].if_condition.conditions[ind2].FieldConstraint=this.ruleConditionList

console.log(this.discountRuleForm,'ffff1111111111111111111111111111111111111)')
   }

   else if(val.includes('autocomplete')){
    this.discountRuleForm[ind].if_condition.conditions[ind2].valforradio={}

  // console.log(this.d2arr,'ggyyy',val)

    this.discountRuleForm[ind].if_condition.conditions[ind2].valforboxes={}
   
    this.discountRuleForm[ind].if_condition.conditions[ind2].rating.accept=''
    this.discountRuleForm[ind].if_condition.conditions[ind2].rating.value=''
    this.discountRuleForm[ind].if_condition.conditions[ind2].rating.size=''
    this.discountRuleForm[ind].if_condition.conditions[ind2].rating.description=''
    this.discountRuleForm[ind].if_condition.conditions[ind2].LikertQuestions.Questions=[]
    this.discountRuleForm[ind].if_condition.conditions[ind2].LikertQuestions.values=[]
    this.discountRuleForm[ind].if_condition.conditions[ind2].AddressType=[]
console.log(this.discountRuleForm,'dddff');
if(!this.Break){
  let fieldarr= this.d2arr.find((item)=>{
    return item.type=='group'
        })
      // console.log(this.d2arr,'ff===')
       let newone =fieldarr.groupFields.find((item)=>{
          return item.name==val
              })
      // console.log(newone,'newww')
    this.discountRuleForm[ind].if_condition.conditions[ind2].multiplecheckbox=newone.values

}else{
  
  // let grpone ={}
  let newone=[]
  this.d2arr.forEach((fields)=>{
    fields.filter((item)=>{
  // console.log(item,'item')
    if (item.type=='group')
    item.groupFields.forEach((items)=>{
      if(items.name ==val){

        return newone.push(items)
      }

          })
     
    }) 
  })
// console.log(newone,"newone")
    // for one value in loop
    // newone.forEach((item)=>{
    //   this.discountRuleForm[ind].if_condition.conditions[ind2].multiplecheckbox=item
  
    // })
  this.discountRuleForm[ind].if_condition.conditions[ind2].multiplecheckbox=newone[0].values
 



}
this.discountRuleForm[ind].if_condition.conditions[ind2].FieldConstraint=this.ruleConditionList

console.log(this.discountRuleForm,'ffff11)')
   }
   else if(val.includes('address-group')){
    // alert('address-group')
  // console.log(this.d2arr,'ggyyy')
  this.discountRuleForm[ind].if_condition.conditions[ind2].valforradio={}
   
          this.discountRuleForm[ind].if_condition.conditions[ind2].valforboxes={}
    this.discountRuleForm[ind].if_condition.conditions[ind2].multiplecheckbox=[]
    this.discountRuleForm[ind].if_condition.conditions[ind2].rating.accept=''
    this.discountRuleForm[ind].if_condition.conditions[ind2].rating.value=''
    this.discountRuleForm[ind].if_condition.conditions[ind2].rating.size=''
    this.discountRuleForm[ind].if_condition.conditions[ind2].rating.description=''
    this.discountRuleForm[ind].if_condition.conditions[ind2].LikertQuestions.Questions=[]
    this.discountRuleForm[ind].if_condition.conditions[ind2].LikertQuestions.values=[]

if(!this.Break){
  let fieldarr= this.d2arr.find((item)=>{
    return item.type=='group'
        })
        let newone = fieldarr.groupFields.find((item)=>{
          return item.name==val
              })
      // console.log(newone,'newwww')
    this.discountRuleForm[ind].if_condition.conditions[ind2].AddressType=newone.addressGroup

}else{
// console.log('val',val)
  let newone2 =[]
  
this.d2arr.forEach((fields)=>{
fields.forEach((item)=>{
// console.log(item,'item')
 if(item.type=='group'){
// console.log(item,'particular item')
   return newone2.push(item)
 }
})    
})
let newone = []
newone2[0].groupFields.forEach((field)=>{
if(field.name ==val){
  return newone.push(field) 

}
}) 
console.log(newone,'fieldsnewonebreak')
this.discountRuleForm[ind].if_condition.conditions[ind2].AddressType=newone[0].addressGroup

console.log(newone2,'222f2222222ieldsnewoneb22222reak')

}
console.log(this.discountRuleForm,"ffff")
   }
   else if(val.includes('likert')){
    // alert('likert')
console.log(this.discountRuleForm,'fffff9999');
console.log(val,'c=val')
this.discountRuleForm[ind].if_condition.conditions[ind2].valforradio={}
    
                this.discountRuleForm[ind].if_condition.conditions[ind2].valforboxes={}
                this.discountRuleForm[ind].if_condition.conditions[ind2].multiplecheckbox=[]
                this.discountRuleForm[ind].if_condition.conditions[ind2].rating.accept=''
                this.discountRuleForm[ind].if_condition.conditions[ind2].rating.value=''
                this.discountRuleForm[ind].if_condition.conditions[ind2].rating.size=''
                this.discountRuleForm[ind].if_condition.conditions[ind2].rating.description=''
                this.discountRuleForm[ind].if_condition.conditions[ind2].LikertQuestions.Questions=[]
                this.discountRuleForm[ind].if_condition.conditions[ind2].LikertQuestions.values=[]
                this.discountRuleForm[ind].if_condition.conditions[ind2].AddressType=[]
               
           if(!this.Break){
            let newone2= this.d2arr.find((item)=>{
              return item.type=='group'
                  })
                // console.log(newone2,'newoneggg222')
                  let newone = newone2.groupFields.find((item)=>{
                    return item.name ==val
                  })
                // console.log(newone,'newone');
                        this.discountRuleForm[ind].if_condition.conditions[ind2].LikertQuestions.Questions=newone.QuestionArr
                        this.discountRuleForm[ind].if_condition.conditions[ind2].LikertQuestions.values=newone.QuestionArr[0].values
                       
                        // console.log(this.discountRuleForm,'f=f=f=f=f=f==fff=')
                          
                        
                      }  
                      else{
                        let newone =[]
                  // console.log(val,'val')
                        this.d2arr.forEach((fields)=>{
                          fields.filter((item)=>{
                          // console.log(item,'item')
                          if(item.type=='group'){
                            return newone.push(item)
                           }
                           
                          })    
                        // console.log(newone,'newoneliikppp')   
                        })
                   let newone2 =     newone[0].groupFields.find((field)=>{
return field.type =='likert'
                        })
                      // console.log(newone2.QuestionArr,'22')
                        // newone2.QuestionArr.forEach((question)=>{

                        // })
                        this.discountRuleForm[ind].if_condition.conditions[ind2].LikertQuestions.Questions=newone2.QuestionArr
                        this.discountRuleForm[ind].if_condition.conditions[ind2].LikertQuestions.values=newone2.QuestionArr[0].values
                    // console.log(this.discountRuleForm,"ffddfdfdfdfd====");
                        
                      }  
            
   }
 

   else{
  // console.log('hit exchange')
  this.discountRuleForm[ind].if_condition.conditions[ind2].valforradio={}

    this.discountRuleForm[ind].if_condition.conditions[ind2].valforboxes={}
    this.discountRuleForm[ind].if_condition.conditions[ind2].conditiontype='text'
    this.discountRuleForm[ind].if_condition.conditions[ind2].AddressType=[]
    this.discountRuleForm[ind].if_condition.conditions[ind2].multiplecheckbox=[]
    this.discountRuleForm[ind].if_condition.conditions[ind2].rating.accept=''
    this.discountRuleForm[ind].if_condition.conditions[ind2].rating.value=''
    this.discountRuleForm[ind].if_condition.conditions[ind2].rating.size=''
    this.discountRuleForm[ind].if_condition.conditions[ind2].rating.description=''
    // this.discountRuleForm[ind].if_condition.conditions[ind2].groupFields=[]
  // console.log(this.discountRuleForm,"dfff")

   }
   

  // console.log(this.discountRuleForm,'222111');
}
//constraint data
Constraintdata(e,ind,ind2){
    this.ruleConditionList.forEach((id:any)=>{
      if (id.id == parseInt(e.target.value)){
        this.constraint_name_id = id.name
      }
    })
  this.discountRuleForm[ind].if_condition.conditions[ind2].constraint_id=parseInt(e.target.value)
  this.discountRuleForm[ind].if_condition.conditions[ind2].constraint=this.constraint_name_id
  
// console.log(typeof e.target.value,'e')
console.log(this.discountRuleForm,'fff')
}
//likert on click for fields
onlikertoptions(val,ind,ind2){
// console.log(this.d2arr,'bbfieldssss  ')
if(!this.Break){
  let fieldarr= this.d2arr.find((item)=>{
    return item.type=='likert'
        })
      // console.log(fieldarr,'rrr')
      // console.log(val,"valwues")
        let newone =fieldarr.QuestionArr.find((item)=>{
          return item.QuestionName==val
              })
// console.log(newone,'likert')
this.discountRuleForm[ind].if_condition.conditions[ind2].LikertQuestions.values=newone.values
console.log(this.discountRuleForm,'fiedlikert')

}
else{    
// console.log(this.d2arr,'fieldssss  ')
  let newone2 =[]

  this.d2arr.forEach((fields)=>{
    fields.forEach((item)=>{
    // console.log(item,'item')
    if(item.type=='likert'){
      return newone2.push(item)
     }
     
    })    
  
   
  })
// console.log(newone2,'newoneoo22')     

let newone =[]
// console.log(val,'valllllll')   
  newone2[0].QuestionArr.find((item)=>{
     if(item.QuestionName ==val){
    return newone.push(item)
     }
  // console.log(item,'item',item.QuestionName == val)
  })
// console.log(newone,'newoneoo')     

  this.discountRuleForm[ind].if_condition.conditions[ind2].LikertQuestions.values=newone[0].values



// //         
// console.log(this.FieldForm,'fiedlikert')
}


}
//likert on Click for fields in group-fields
onlikertoptionsGroup(val,ind,ind2){
// console.log(this.d2arr,'bbfieldssss  ')
if(!this.Break){
  let fieldarr= this.d2arr.find((item)=>{
    return item.type=='group'
        })
      // console.log(fieldarr,'rrr')
      // console.log(val,"valwues")
        let newone =[]
        let newone2 =fieldarr.groupFields.forEach((item)=>{
          item.QuestionArr.find((ite)=>{
            if (ite.QuestionName ==val){
              newone.push(ite)
            }
          })
          // return item.QuestionName==val
              })
// console.log(newone,'likert')
this.discountRuleForm[ind].if_condition.conditions[ind2].LikertQuestions.values=newone[0].values
// console.log(this.FieldForm,'fiedlikert')

}
else{    
// console.log(this.d2arr,'fieldssss  ')
  let newone2 =[]

  this.d2arr.forEach((fields)=>{
    fields.forEach((item)=>{
     if(item.type=='group'){
      item.groupFields.find((ite=>{
if(ite.type=='likert'){
// newone2.push(ite);
console.log(ite,'ite')
// ite.groupFields.forEach((field)=>{
  ite.QuestionArr.find((ite)=>{
    if (ite.QuestionName ==val){
      newone2.push(ite)
    }
  })
// })
}
      }))
     }
          })
  
   
  })
// console.log(newone2,'newoneoo22')     

// let newone =[]
// // console.log(val,'valllllll')   
//    newone =newone2.groupFields.forEach((item)=>{
//     item.QuestionArr.find((ite)=>{
//       if (ite.QuestionName ==val){
//         newone.push(ite)
//       }
//     })
  //   // return item.QuestionName==val
  //       })
  // newone2[0].QuestionArr.find((item)=>{
  //    if(item.QuestionName ==val){
  //   return newone.push(item)
  //    }
  // // console.log(item,'item',item.QuestionName == val)
  // })
  // console.log(newone,'newoneoo')     

  this.discountRuleForm[ind].if_condition.conditions[ind2].LikertQuestions.values=newone2[0].values



// //         
// console.log(this.FieldForm,'fiedlikert')
}


}
PreConfigFields(e:any,ind,ind2){
  console.log(e.target.value,"ewrwe")
  let val = e.target.value
  if (val.includes('nationality')){
    this.discountRuleForm[ind].if_condition.conditions[ind2].valforboxes={}
    this.discountRuleForm[ind].if_condition.conditions[ind2].conditiontype='text'
    this.discountRuleForm[ind].if_condition.conditions[ind2].AddressType=[]
    this.discountRuleForm[ind].if_condition.conditions[ind2].multiplecheckbox=[]
    this.discountRuleForm[ind].if_condition.conditions[ind2].rating.accept=''
    this.discountRuleForm[ind].if_condition.conditions[ind2].rating.value=''
    this.discountRuleForm[ind].if_condition.conditions[ind2].rating.size=''
    this.discountRuleForm[ind].if_condition.conditions[ind2].rating.description=''
    this.discountRuleForm[ind].if_condition.conditions[ind2].groupFields=[]
    this.discountRuleForm[ind].if_condition.conditions[ind2].LikertQuestions.Questions=[]
    this.discountRuleForm[ind].if_condition.conditions[ind2].LikertQuestions.values=[]
    this.discountRuleForm[ind].if_condition.conditions[ind2].Nationality=true
    this.discountRuleForm[ind].if_condition.conditions[ind2].PhoneCode=false
    this.discountRuleForm[ind].if_condition.conditions[ind2].FieldConstraint= this.ruleConditionList.filter((field) =>{ return field.name =='Is'||field.name =='Is Not'||field.name =='Is Empty'||field.name =='Is Not Empty' })


   }
   else if (val.includes('phone_code')){
    this.discountRuleForm[ind].if_condition.conditions[ind2].valforboxes={}
    this.discountRuleForm[ind].if_condition.conditions[ind2].conditiontype='text'
    this.discountRuleForm[ind].if_condition.conditions[ind2].AddressType=[]
    this.discountRuleForm[ind].if_condition.conditions[ind2].multiplecheckbox=[]
    this.discountRuleForm[ind].if_condition.conditions[ind2].rating.accept=''
    this.discountRuleForm[ind].if_condition.conditions[ind2].rating.value=''
    this.discountRuleForm[ind].if_condition.conditions[ind2].rating.size=''
    this.discountRuleForm[ind].if_condition.conditions[ind2].rating.description=''
    this.discountRuleForm[ind].if_condition.conditions[ind2].groupFields=[]
    this.discountRuleForm[ind].if_condition.conditions[ind2].LikertQuestions.Questions=[]
    this.discountRuleForm[ind].if_condition.conditions[ind2].LikertQuestions.values=[]
    this.discountRuleForm[ind].if_condition.conditions[ind2].Nationality=false
    this.discountRuleForm[ind].if_condition.conditions[ind2].PhoneCode=true
    this.discountRuleForm[ind].if_condition.conditions[ind2].FieldConstraint= this.ruleConditionList.filter((field) =>{ return field.name =='Is'||field.name =='Is Not'||field.name =='Is Empty'||field.name =='Is Not Empty' })

   }
   else if (val.includes('phone_number')){
    this.discountRuleForm[ind].if_condition.conditions[ind2].valforboxes={}
    this.discountRuleForm[ind].if_condition.conditions[ind2].conditiontype='text'
    this.discountRuleForm[ind].if_condition.conditions[ind2].AddressType=[]
    this.discountRuleForm[ind].if_condition.conditions[ind2].multiplecheckbox=[]
    this.discountRuleForm[ind].if_condition.conditions[ind2].rating.accept=''
    this.discountRuleForm[ind].if_condition.conditions[ind2].rating.value=''
    this.discountRuleForm[ind].if_condition.conditions[ind2].rating.size=''
    this.discountRuleForm[ind].if_condition.conditions[ind2].rating.description=''
    this.discountRuleForm[ind].if_condition.conditions[ind2].groupFields=[]
    this.discountRuleForm[ind].if_condition.conditions[ind2].LikertQuestions.Questions=[]
    this.discountRuleForm[ind].if_condition.conditions[ind2].LikertQuestions.values=[]
    this.discountRuleForm[ind].if_condition.conditions[ind2].Nationality=false
    this.discountRuleForm[ind].if_condition.conditions[ind2].PhoneCode=false
    this.discountRuleForm[ind].if_condition.conditions[ind2].value=""

    this.discountRuleForm[ind].if_condition.conditions[ind2].FieldConstraint= this.ruleConditionList.filter((field) =>{ return field.name =='Is'||field.name =='Is Not'||field.name =='Is Empty'||field.name =='Is Not Empty' })

   }
   else if (val.includes('first_name')){
    this.discountRuleForm[ind].if_condition.conditions[ind2].valforboxes={}
    this.discountRuleForm[ind].if_condition.conditions[ind2].conditiontype='text'
    this.discountRuleForm[ind].if_condition.conditions[ind2].AddressType=[]
    this.discountRuleForm[ind].if_condition.conditions[ind2].multiplecheckbox=[]
    this.discountRuleForm[ind].if_condition.conditions[ind2].rating.accept=''
    this.discountRuleForm[ind].if_condition.conditions[ind2].rating.value=''
    this.discountRuleForm[ind].if_condition.conditions[ind2].rating.size=''
    this.discountRuleForm[ind].if_condition.conditions[ind2].rating.description=''
    this.discountRuleForm[ind].if_condition.conditions[ind2].groupFields=[]
    this.discountRuleForm[ind].if_condition.conditions[ind2].LikertQuestions.Questions=[]
    this.discountRuleForm[ind].if_condition.conditions[ind2].LikertQuestions.values=[]
    this.discountRuleForm[ind].if_condition.conditions[ind2].Nationality=false
    this.discountRuleForm[ind].if_condition.conditions[ind2].PhoneCode=false
    this.discountRuleForm[ind].if_condition.conditions[ind2].value=""

    this.discountRuleForm[ind].if_condition.conditions[ind2].FieldConstraint= this.ruleConditionList.filter((field) =>{ return field.name =='Is'||field.name =='Is Not'||field.name =='Is Empty'||field.name =='Is Not Empty' })

   }
   else if (val.includes('last_name')){
    this.discountRuleForm[ind].if_condition.conditions[ind2].valforboxes={}
    this.discountRuleForm[ind].if_condition.conditions[ind2].conditiontype='text'
    this.discountRuleForm[ind].if_condition.conditions[ind2].AddressType=[]
    this.discountRuleForm[ind].if_condition.conditions[ind2].multiplecheckbox=[]
    this.discountRuleForm[ind].if_condition.conditions[ind2].rating.accept=''
    this.discountRuleForm[ind].if_condition.conditions[ind2].rating.value=''
    this.discountRuleForm[ind].if_condition.conditions[ind2].rating.size=''
    this.discountRuleForm[ind].if_condition.conditions[ind2].rating.description=''
    this.discountRuleForm[ind].if_condition.conditions[ind2].groupFields=[]
    this.discountRuleForm[ind].if_condition.conditions[ind2].LikertQuestions.Questions=[]
    this.discountRuleForm[ind].if_condition.conditions[ind2].LikertQuestions.values=[]
    this.discountRuleForm[ind].if_condition.conditions[ind2].Nationality=false
    this.discountRuleForm[ind].if_condition.conditions[ind2].PhoneCode=false
    this.discountRuleForm[ind].if_condition.conditions[ind2].value=""

    this.discountRuleForm[ind].if_condition.conditions[ind2].FieldConstraint= this.ruleConditionList.filter((field) =>{ return field.name =='Is'||field.name =='Is Not'||field.name =='Is Empty'||field.name =='Is Not Empty' })

   }
   else if (val.includes('email')){
    this.discountRuleForm[ind].if_condition.conditions[ind2].valforboxes={}
    this.discountRuleForm[ind].if_condition.conditions[ind2].conditiontype='text'
    this.discountRuleForm[ind].if_condition.conditions[ind2].AddressType=[]
    this.discountRuleForm[ind].if_condition.conditions[ind2].multiplecheckbox=[]
    this.discountRuleForm[ind].if_condition.conditions[ind2].rating.accept=''
    this.discountRuleForm[ind].if_condition.conditions[ind2].rating.value=''
    this.discountRuleForm[ind].if_condition.conditions[ind2].rating.size=''
    this.discountRuleForm[ind].if_condition.conditions[ind2].rating.description=''
    this.discountRuleForm[ind].if_condition.conditions[ind2].groupFields=[]
    this.discountRuleForm[ind].if_condition.conditions[ind2].LikertQuestions.Questions=[]
    this.discountRuleForm[ind].if_condition.conditions[ind2].LikertQuestions.values=[]
    this.discountRuleForm[ind].if_condition.conditions[ind2].Nationality=false
    this.discountRuleForm[ind].if_condition.conditions[ind2].PhoneCode=false
    this.discountRuleForm[ind].if_condition.conditions[ind2].value=""

    this.discountRuleForm[ind].if_condition.conditions[ind2].FieldConstraint= this.ruleConditionList.filter((field) =>{ return field.name =='Is'||field.name =='Is Not'||field.name =='Is Empty'||field.name =='Is Not Empty' })

   }
   else if (val.includes('dob')){
    this.discountRuleForm[ind].if_condition.conditions[ind2].valforboxes={}
    this.discountRuleForm[ind].if_condition.conditions[ind2].conditiontype='text'
    this.discountRuleForm[ind].if_condition.conditions[ind2].AddressType=[]
    this.discountRuleForm[ind].if_condition.conditions[ind2].multiplecheckbox=[]
    this.discountRuleForm[ind].if_condition.conditions[ind2].rating.accept=''
    this.discountRuleForm[ind].if_condition.conditions[ind2].rating.value=''
    this.discountRuleForm[ind].if_condition.conditions[ind2].rating.size=''
    this.discountRuleForm[ind].if_condition.conditions[ind2].rating.description=''
    this.discountRuleForm[ind].if_condition.conditions[ind2].groupFields=[]
    this.discountRuleForm[ind].if_condition.conditions[ind2].LikertQuestions.Questions=[]
    this.discountRuleForm[ind].if_condition.conditions[ind2].LikertQuestions.values=[]
    this.discountRuleForm[ind].if_condition.conditions[ind2].Nationality=false
    this.discountRuleForm[ind].if_condition.conditions[ind2].PhoneCode=false
    this.discountRuleForm[ind].if_condition.conditions[ind2].value=""
    this.discountRuleForm[ind].if_condition.conditions[ind2].FieldConstraint= this.ruleConditionList.filter((field) =>{ return field.name =='Is'||field.name =='Is Not'||field.name =='Is Empty'||field.name =='Is Not Empty' })

this.discountRuleForm[ind].if_condition.conditions[ind2].FieldConstraint= this.ruleConditionList.filter((field) =>{ return field.name =='Is'||field.name =='Is Not'||field.name =='Is Empty'||field.name =='Is Not Empty' || field.name =='Less Than <' || field.name =='Greater Than >'  })

   }

}
  onRuleConditionSelect(conditionID: any, index:number, ruleindex: number) {
    console.log(conditionID,"ccccc")
    let conditionType = this.ruleConditionList.find(condition => condition.id === parseInt(conditionID));
    this.discountRuleForm[ruleindex].if_condition.conditions[index].constraint = conditionType.name;
    this.discountRuleForm[ruleindex].if_condition.conditions[index].constraint_id = conditionType.id;
    if(conditionType.name === 'Is Empty' || conditionType.name === 'Is Not Empty') {
      this.discountRuleForm[ruleindex].if_condition.conditions[index].showValueBox = false;
    } else {
      this.discountRuleForm[ruleindex].if_condition.conditions[index].showValueBox = true;
    }
  }

  addNewRow(ruleindex) {
    console.log(this.discountRuleForm,"2222DDDADDDD")

    if(this.d2arr !== undefined && this.d2arr.length > 0) {
      this.discountRuleForm[ruleindex].if_condition.conditions.push({
        "basic_info_field":'',
       "field_type": "",
       'group_type':"",
       "field_type2": "",
       'group_type2':"",


       'likertquestion':"",
       "constraint": this.ruleConditionList[0].name,
        "constraint_id": this.ruleConditionList[0].id,
       "value": "",
       "showValueBox": true,
       'FieldConstraint':[],
      'conditiontype':'text',
      'FileEmpt':false,
   'valforboxes':{},
   "valforradio":{},

   'AddressType':[],
   "groupFields":[],
   'multiplecheckbox':[],
   'multiplevalue':[],
   "multipleboxes":[],
   'rating':{
     "accept":'',
     "value":'',
     "size":'',
     "description":'',
     "arrsize":[]
   },
   'LikertQuestions':{
     "Questions":[],
     "values":[]
   }
        ,
      "Nationality":false,
      "PhoneCode":false


      });
    } else {
      this.discountRuleForm[ruleindex].if_condition.conditions.push({
       
        "basic_info_field":'',
        // "field_type": this.basicInfoFields[0].slug,
        "field_type": "",
        "field_type2": "",
        'group_type2':"",

        'group_type':"",
        'likertquestion':"",
        "constraint": this.ruleConditionList[0].name,
         "constraint_id": this.ruleConditionList[0].id,
        "value": "",
        "showValueBox": true,
        'FieldConstraint':[],
       'conditiontype':'text',
       'FileEmpt':false,
    'valforboxes':{},
    "valforradio":{},

    'AddressType':[],
    "groupFields":[],
    'multiplecheckbox':[],
    'multiplevalue':[],
    "multipleboxes":[],
    'rating':{
      "accept":'',
      "value":'',
      "size":'',
      "description":'',
      "arrsize":[]
    },
    'LikertQuestions':{
      "Questions":[],
      "values":[]
    },
    "Nationality":false,
    "PhoneCode":false


      })
      console.log(this.discountRuleForm,"DDDADDDD")
    }
    console.log(this.discountRuleForm,"AFTERRRRR")


  }

  removeRow(discountRuleFormIndex: number, index: number) { 
    this.discountRuleForm[discountRuleFormIndex].if_condition.conditions.splice(index, 1);
  }

  discountProofRequire(proof: string, index: number) {
    this.discountRuleForm[index].proof = proof;
  }

  groupRuleEditData() {
  // console.log(this.ruleData,'rules+++++==========');
    if(this.ruleData !=undefined){
      this.discountGroupminprice=this.ruleData['form_min_price']

    }
    if(this.ruleData && Object.keys(this.ruleData).length > 0) {
      this.ruleEdit = true;
// alert(true)
      this.discountRuleForm[0].rule_name = this.ruleData['rule_name'];
      this.discountRuleForm[0].if_condition = this.ruleData['if_condition'];
      this.discountRuleForm[0].then_apply = this.ruleData['then_apply'];
      this.discountRuleForm[0].proof = this.ruleData['proof_required'];

      if(this.discountRuleForm[0].proof === 'yes') {
        this.discountRuleForm[0].discount_proof_number_of_files = this.ruleData['discount_proof_number_of_files'];
        this.discountRuleForm[0].discount_proof_file_size = this.ruleData['discount_proof_file_size'];
        this.discountRuleForm[0].discount_proof_files_allowed = this.ruleData['discount_proof_files_allowed'];
        this.discountRuleForm[0].discount_proof_title = this.ruleData['discount_proof_title'];
        this.discountRuleForm[0].discount_proof_description = this.ruleData['discount_proof_description'];
      }
    }
    
  }

  addNewRule() {
    // this.conditionRows.push(this.emptyConditionRow);
    // this.conditionRows['FieldConstraint']=this.ruleConditionList,

    this.discountRuleForm.push({
        "rule_name": "",
        "if_condition": {
          "allow": "any",
          "conditions": [{
            "basic_info_field":'',
            "field_type": "",
          "field_type2": "",
          'group_type2':"",

            'group_type':"",
            'likertquestion':"",
            "constraint": "Is",
            "constraint_id": 1,
            "value": "",
            "showValueBox": true,
            'FieldConstraint':[],
           'conditiontype':'text',
           'FileEmpt':false,
        'valforboxes':{},
      "valforradio":{},

        'AddressType':[],
        "groupFields":[],
        'multiplecheckbox':[],
        'multiplevalue':[],
        "multipleboxes":[],
        'rating':{
          "accept":'',
          "value":'',
          "size":'',
          "description":'',
          "arrsize":[]
        },
        'LikertQuestions':{
          "Questions":[],
          "values":[]
        }
  ,
  "Nationality":false,
  "PhoneCode":false


           
          }]
        },
        "then_apply": {
          "type": "percent",
          "value": ""
        },
        "proof": "no",
        "discount_proof_title": "",
        "discount_proof_description": "",
        "discount_proof_files_allowed": [],
        "discount_proof_number_of_files": {
          "min": null,
          "max": null
        },
        "discount_proof_file_size": {
          "min": null,
          "max": null
        }
    });
    // this.discountRuleForm.push({
    //     "rule_name": "",
    //     "if_condition": {
    //       "allow": "any",
    //       "conditions": this.conditionRows
    //     },
    //     "then_apply": {
    //       "type": "amount",
    //       "value": ""
    //     },
    //     "proof": "no",
    //     "discount_proof_title": "",
    //     "discount_proof_description": "",
    //     "discount_proof_files_allowed": [],
    //     "discount_proof_number_of_files": {
    //       "min": null,
    //       "max": null
    //     },
    //     "discount_proof_file_size": {
    //       "min": null,
    //       "max": null
    //     }
    // });
  }
  destroy$: Subject<boolean> = new Subject<boolean>();
  result:any
  removeRule(index,id) {
  // console.log(index,'piddd');
  Swal.fire({
    title: "Are you sure you want to delete this rule ?",
    // text:"sas",
    icon: "warning",
    showCancelButton: true,
    confirmButtonColor: "#3085d6",
    cancelButtonColor: "#d33",
    confirmButtonText:"Yes" ,
  }).then((result)=>{
    if(result.value){
          if(index ==0){
            // alert('0')
          this.RuleShow.emit(true)
          this.hidesave=true
            
          }
        // console.log(this.discountRuleForm,'aaaaaaaaaaaaaaaaaaaaa');
          
          this.discountRuleForm.splice(index, 1);
      console.log(this.discountRuleForm,'aaaaaaaaaaaaaaaaaaaaa');
          // alert(id)
          // let id={}
          // id = id +''
          if(id !=undefined) {
            this.formService
            .DeleteDiscountRules(id)
            .pipe(takeUntil(this.destroy$))
            .subscribe((result) => {
              this.result = result;

              
          // this.RehitFormApi.emit(true)
          this.common.RehitFormApi(true)

              // this.formService.getDiscountRuleConditionsList();
              Swal.fire({
                title: "",
                text: result['message'],
                icon: "success",
                timer: 2000,
                showConfirmButton: false,
                showClass: {
                  popup: "animate__animated animate__fadeInDown",
                },
                hideClass: {
                  popup: "animate__animated animate__fadeOutUp",
                },
              });
              // alert('hit')
              // this.groupRuleEditData();
        
              // this.handleDiscountRuleConditions();
        
              // this.FinArr = this.result.data;
            });
      
          }

    }
  })
  }

  allowedFiles(fileType: any, index: number) {
    // console.log(this.discountRuleForm[index].discount_proof_files_allowed,'ssssssssssss')
    if(fileType.target.checked) {
      this.discountRuleForm[index].discount_proof_files_allowed.push(fileType.target.value);
    } else {
      this.discountRuleForm[index].discount_proof_files_allowed.splice(this.discountRuleForm[index].discount_proof_files_allowed.indexOf(fileType.target.value), 1);
    }
  }

  saveRule() {
    
    let errors = [];
console.log(this.discountRuleForm,'discountRuleForm')
    this.discountRuleForm.map((obj: any, i: number) => {
    // console.log(obj,'obj');
      
      if(obj.rule_name === '' || obj.then_apply.value === ''|| obj.then_apply.value ==null) {
        errors.push({'index': i, 'object': obj});
      }

      obj.if_condition.conditions.map((consObj: any, ci: number) => {
        console.log(obj,'obj')
        if(obj.if_condition.conditions[0].constraint_id !=3 &&obj.if_condition.conditions[0].constraint_id !=4){
          if(consObj.showValueBox && (consObj.value === '' || consObj.value.length == 0)) {
  
            errors.push({'condition_index': ci, 'object': consObj});
          }

        }
      });

      if(obj.proof === 'yes' && (obj.discount_proof_title === '' || obj.discount_proof_description === '' || obj.discount_proof_files_allowed.length === 0 || obj.discount_proof_number_of_files.min === null || obj.discount_proof_number_of_files.max === null || obj.discount_proof_file_size.min === null || obj.discount_proof_file_size.max === null)) {
        errors.push({'proof_index': i, 'object': obj});
      }
    });
// console.log(this.discountRuleForm.length,'ssssss')
    let newarrone=[]
// if(this.discountRuleForm.length>1){
  this.discountRuleForm.forEach((rule, i) => {
  // console.log(rule,'rule');
    
    rule.if_condition.conditions.map((condition) => {
    // console.log(i,'index');
      if(rule.if_condition.conditions.length>1){

      }
      newarrone.push({type:condition.field_type,val:condition.value,const:condition.constraint,allow:rule.if_condition.allow,conditionsLength:rule.if_condition.conditions.length,index:i})
  
         
   
    })
  })
// console.log(newarrone,'rrrrrrrrrrrrrrrrrrrrrrrrr');
// }
let keys = ['type', 'val', 'const','allow','conditionsLength'],
map = newarrone.reduce((m, o) => {
    const key = keys.map(k => JSON.stringify(o[k])).join('|'),

    [count = 0, array = []] = m.get(key) || [];
    return m.set(key, [count + 1, [...array, o]]);
}, new Map),
duplicates = Array
    .from(map.values(), ([count, array]) => count === 1 ? []: array)
    .reduce((acc, val) => acc.concat(val), []);

console.log(duplicates,duplicates.length);

console.log(errors,'error')
    if(errors.length > 0 ) {
      this.validate = true;
    } 
    else {
      if(duplicates.length>0){
// alert('dup')
Swal.fire({
  position: 'center',
  icon: 'error',
 title:'Rules with same conditions cannot be added.',
  padding: '2em',
});
      }
      // else{
      //   alert('hit')
      // }
      else{
        errors = [];
        this.validate = false;
        let formData = new FormData();
console.log(this.discountRuleForm,'ssssssddddddddddd',this.discountRuleForm)
  let maxxarr=[]
  let maxarr2=[]
  this.discountRuleForm.forEach((dis:any,y:any)=>{
    if(dis.then_apply.type =='amount'){

      maxxarr.push(dis.then_apply.value)
      maxarr2.push({type:'amount',value:dis.then_apply.value})
      // if(y==0){
      //   formData.append('max_discount_cal_type','amount')
        
      // }
    }
    else{
      let x = parseInt(this.form_price)*(parseInt(dis.then_apply.value)/100)
      maxxarr.push(x)
      maxarr2.push({type:'percent',value:x})

      // if(y==0){
      //   formData.append('max_discount_cal_type','percent')

      // }

    }

  })
console.log(maxarr2,"MAXarr2")
console.log(maxxarr,'maxarrrrrrrrr')


this.ownmaxx=Math.max(...maxxarr)
maxarr2.forEach((x,i)=>{
  if(x.value  == this.ownmaxx){
    formData.append('max_discount_cal_type',x.type)
  }
})
console.log(this.ownmaxx,'oooooooooooooooo');

  // console.log(this.maxpreconfig,'xxxxxxxxxxxxx333333333333');
  // if(this.maxpreconfig ==undefined){
  //   this.maxpreconfig= 0

  // }
  // if(this.ownmaxx ==undefined){
  //   this.ownmaxx=0
  // }
  // console.log( this.ownmaxx,'>own=====pre<', this.maxpreconfig)
  // // if(this.ownmaxx>this.maxpreconfig){
  //   formData.append('max_discount_amount',this.ownmaxx)
  //   formData.append('max_discount_type','own')
  if(this.discountGroupID==''&&this.d2arr && this.d2arr.length >0){
    // if(this.discountGroupID!=''){
      // if(this.discountType)
    if(parseInt(this.MaxDisArr.max_discount_amount) > this.ownmaxx && this.MaxDisArr.max_discount_amount!=undefined){
      // alert('pppp')
          formData.append('max_discount_amount',this.MaxDisArr.max_discount_amount)
        formData.append('max_discount_type',this.MaxDisArr.max_discount_type)
        }
        else{
          formData.append('max_discount_amount',this.ownmaxx)
          formData.append('max_discount_type','own')
  
        }

  }
  // }
  // else{
  //   formData.append('max_discount_amount',this.maxpreconfig)
  //   formData.append('max_discount_type','preconfigured')
  // }
        // formData.append('discount_group_rules', this.ruleData && this.ruleEdit ? JSON.stringify(this.discountRuleForm[0]) : JSON.stringify(this.discountRuleForm));
        formData.append('discount_group_rules', this.ruleData && this.ruleEdit ? JSON.stringify(this.discountRuleForm[0]) : JSON.stringify(this.discountRuleForm));
        console.log(this.d2arr,"DDDD@@@@@@@@@@@")
        if(this.ruleEdit && (this.d2arr === undefined || this.d2arr.length ==0)) {
          formData.append('discount_group_id', this.ruleData ? this.ruleData['discount_group_id'] : this.groupid);
          this.discountGroupService.updateDiscountGroupRule(formData, this.ruleData['id']).subscribe((result: any) => {
            if(result['success']) {
              Swal.fire({
                position: 'center',
                icon: 'success',
                html: result['message'],
                padding: '2em',
              }).then(() => {
                this.ruleEdit = false;
                this.discountGroupService.setGroupName('');
                this.deleteFieldUpdate = false;
                this.ngZone.run(() => {
                  this.router.navigate(['/settings/discount-groups']);
                });
              });
            }
          }, error => {
            Swal.fire({
              position: 'center',
              icon: 'error',
              html: error.error['message'],
              padding: '2em',
            });
          });
        }
  console.log(this.discountRuleForm,'ruleeeeeeeeeeeeeeeeeeeeeeeeee',this.ruleEdit,this.d2arr)
        
        if(!this.ruleEdit && (this.d2arr === undefined || this.d2arr.length === 0)) {
          console.log("HITTTTTTTTTTTTTTTT")

          formData.append('discount_group_id', this.ruleData ? this.ruleData['discount_group_id'] : this.groupid);
          this.discountGroupService.saveDiscountGroupRule(formData).subscribe((result: any) => {
          
            if(result['success']) {
              Swal.fire({
                position: 'center',
                icon: 'success',
                html: result['message'],
                padding: '2em',
              });
              this.ngZone.run(() => {
                this.router.navigate(['/settings/discount-groups']);
                this.discountRuleForm = [];
                this.addNewRule();
              })
            
            }
          }, error => {
            Swal.fire({
              position: 'center',
              icon: 'error',
              html: error.error['message'],
              padding: '2em',
            });
          });
        }
  
        if(!this.ruleEdit && this.d2arr && this.d2arr.length > 0) {
          // this.discountGroupService.setMaxDiscount(true)

          // console.log(this.isPreconfigCheck,"assssssssssssp");
            // formData.append('pre_configured_discount', this.preConfiguredSelected);
        // console.log(this.preConfiguredSelected,'sssss')
          
          // if(this.isPreconfigCheck) {
            // formData.append('pre_configured_discount', this.preConfiguredSelected);
          // }
          formData.append('form',this.formid);
          // formData.append('is_preconfigured',this.isPreconfigCheck ? 'yes' : 'no');
          formData.append('has_own_rules', 'yes');
  
          this.discountGroupService.saveFormDiscountRule(formData).subscribe((result: any) => {

          // alert('hit')
            // this.discountGroupService.setMaxDiscount({max_discount_type:'own',max_discount_amount:this.ownmaxx})
            this.discountGroupService.setMaxDiscount(true)
            result['data']['data']['own_rules'].forEach((rule,i)=>{
              this.discountRuleForm.forEach((val:any,i:any)=>{
                val['id'] =rule.id
                        })
            })
            console.log(this.discountRuleForm,'dddd')
            // console.log(this.idtopass,'idtopass')
            this.RehitFormApi.emit(true)
        
            if(result['success']) {
              Swal.fire({
                position: 'center',
                icon: 'success',
                html: this.deleteFieldUpdate ? '<h3>Discount rule updated!</h3>' : result['message'],
                padding: '2em',
                allowOutsideClick: false,
                allowEscapeKey: false,
              });
              this.deleteFieldUpdate = false;
            } else {
              Swal.fire({
                position: 'center',
                icon: 'error',
                html: result['message'],
                padding: '2em',
              });
            }
          }, error => {
            Swal.fire({
              position: 'center',
              icon: 'error',
              html: error.error['message'],
              padding: '2em',
            });
          });
        }
      }
    

    }
  }

  test(conditionItem, item) {
    if(item.type == 'group') {
      conditionItem.group_type = item.name;
    }
    if(item.type != 'group') {
      conditionItem.field_type = item.name;
    } 

    console.log('FINAL CONDITION ITEM', conditionItem);
  }

}
