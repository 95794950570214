import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-if-value',
  templateUrl: './if-value.component.html',
  styleUrls: ['./if-value.component.scss']
})
export class IfValueComponent implements OnInit {
@Input() FieldForm: any
@Input() Fields: any
@Input() Break: any
@Input() Condition_Show: any
  constructor() { }
  conditions:any=[]
  ngOnInit(): void {
  // console.log(this.Fields,'Fields')
    // this.conditions=this.FieldForm.conditions
  }
//   onUserInfoFieldSelect(val,ind,ind2){
//   // console.log('val',val,'ind===',ind,'ind2==',ind2) 
//     // if-else for conditions
//     if(val.includes('file')){
//        this.FieldForm[ind].if_condition.conditions[ind2].FieldConstraint= this.FieldConditionList.filter((field) =>{ return field.name =='Is Empty'||field.name =='Is Not Empty'})
//        this.FieldForm[ind].if_condition.conditions[ind2].FileEmpt=true
//       //  val.preventDefault();
//       //  alert('file')
//       //// console.log('FileEmpt',this.FieldForm)
//  // console.log(this.FieldForm,'ffffuuffee')
//       }else if (val.includes('rating')){
//        this.FieldForm[ind].if_condition.conditions[ind2].FieldConstraint= this.FieldConditionList.filter((field) =>{ return field.name =='Is'||field.name =='Is Not'||field.name =='Is Empty'||field.name =='Is Not Empty'})

//       }
//         else if (val.includes('range')){
//           this.FieldForm[ind].if_condition.conditions[ind2].FieldConstraint= this.FieldConditionList.filter((field) =>{ return field.name =='Is'||field.name =='Is Not'||field.name =='Is Empty'||field.name =='Is Not Empty'})
  
//         }
       
//       else{
//         // alert('hit')
//         this.FieldForm[ind].if_condition.conditions[ind2].FieldConstraint=this.FieldConditionList
//       // console.log(this.FieldForm,'fielddorm');
        
//       }
//     // if-else for conditions values

//    if(val.includes('date') && !val.includes('datetime')){
//     this.FieldForm[ind].if_condition.conditions[ind2].valforboxes={}
//     this.FieldForm[ind].if_condition.conditions[ind2].AddressType=[]
//     this.FieldForm[ind].if_condition.conditions[ind2].multiplecheckbox=[]
//     this.FieldForm[ind].if_condition.conditions[ind2].rating.accept=''
//     this.FieldForm[ind].if_condition.conditions[ind2].rating.value=''
//     this.FieldForm[ind].if_condition.conditions[ind2].rating.size=''
//     this.FieldForm[ind].if_condition.conditions[ind2].rating.description=''
//     this.FieldForm[ind].if_condition.conditions[ind2].groupFields=[]
//     this.FieldForm[ind].if_condition.conditions[ind2].LikertQuestions.Questions=[]
//     this.FieldForm[ind].if_condition.conditions[ind2].LikertQuestions.values=[]

//     this.FieldForm[ind].if_condition.conditions[ind2].conditiontype='date'
//     this.FieldForm[ind].if_condition.conditions[ind2].FieldConstraint= this.FieldConditionList.filter((field) =>{ return field.name =='Is'||field.name =='Is Not'||field.name =='Is Empty'||field.name =='Is Not Empty'})
//   // console.log(this.FieldForm,"ffff")

//    }else if(val.includes('datetime')){
//     this.FieldForm[ind].if_condition.conditions[ind2].valforboxes={}
//     this.FieldForm[ind].if_condition.conditions[ind2].AddressType=[]
//     this.FieldForm[ind].if_condition.conditions[ind2].multiplecheckbox=[]
//     this.FieldForm[ind].if_condition.conditions[ind2].rating.accept=''
//     this.FieldForm[ind].if_condition.conditions[ind2].rating.value=''
//     this.FieldForm[ind].if_condition.conditions[ind2].rating.size=''
//     this.FieldForm[ind].if_condition.conditions[ind2].rating.description=''
//     this.FieldForm[ind].if_condition.conditions[ind2].groupFields=[]
//     this.FieldForm[ind].if_condition.conditions[ind2].LikertQuestions.Questions=[]
//     this.FieldForm[ind].if_condition.conditions[ind2].LikertQuestions.values=[]

//     this.FieldForm[ind].if_condition.conditions[ind2].conditiontype='datetime-local'
//     this.FieldForm[ind].if_condition.conditions[ind2].FieldConstraint= this.FieldConditionList.filter((field) =>{ return field.name =='Is'||field.name =='Is Not'||field.name =='Is Empty'||field.name =='Is Not Empty'})

//    }else if(val.includes('time')){
//     this.FieldForm[ind].if_condition.conditions[ind2].valforboxes={}
//     this.FieldForm[ind].if_condition.conditions[ind2].AddressType=[]
//     this.FieldForm[ind].if_condition.conditions[ind2].multiplecheckbox=[]
//     this.FieldForm[ind].if_condition.conditions[ind2].rating.accept=''
//     this.FieldForm[ind].if_condition.conditions[ind2].rating.value=''
//     this.FieldForm[ind].if_condition.conditions[ind2].rating.size=''
//     this.FieldForm[ind].if_condition.conditions[ind2].rating.description=''
//     this.FieldForm[ind].if_condition.conditions[ind2].groupFields=[]
//     this.FieldForm[ind].if_condition.conditions[ind2].LikertQuestions.Questions=[]
//     this.FieldForm[ind].if_condition.conditions[ind2].LikertQuestions.values=[]

//     this.FieldForm[ind].if_condition.conditions[ind2].conditiontype='time'
//     this.FieldForm[ind].if_condition.conditions[ind2].FieldConstraint= this.FieldConditionList.filter((field) =>{ return field.name =='Is'||field.name =='Is Not'||field.name =='Is Empty'||field.name =='Is Not Empty'})

//    }
//    else if(val.includes('checkbox')){

//   // console.log(this.Fields,'ggyyy22')
  
//   // console.log(this.FieldForm,'bbbfields');
//     // newone.forEach((item)=>{

//       this.FieldForm[ind].if_condition.conditions[ind2].AddressType=[]
//       this.FieldForm[ind].if_condition.conditions[ind2].multiplecheckbox=[]
//       this.FieldForm[ind].if_condition.conditions[ind2].rating.accept=''
//       this.FieldForm[ind].if_condition.conditions[ind2].rating.value=''
//       this.FieldForm[ind].if_condition.conditions[ind2].rating.size=''
//       this.FieldForm[ind].if_condition.conditions[ind2].rating.description=''
//       this.FieldForm[ind].if_condition.conditions[ind2].groupFields=[]
//       this.FieldForm[ind].if_condition.conditions[ind2].LikertQuestions.Questions=[]
//       this.FieldForm[ind].if_condition.conditions[ind2].LikertQuestions.values=[]
//       // this.FieldForm[ind].if_condition.conditions[ind2].FieldConstraint=this.FieldConditionList

// console.log(this.FieldForm,'ffff11)')
// if(!this.Break){
//   let newone= this.Fields.find((item)=>{
//     return item.name==val
//         })
//         this.FieldForm[ind].if_condition.conditions[ind2].valforboxes=newone
// }else{
//   // console.log('val',val)
//   let newone =[]
// this.Fields.forEach((fields)=>{
// fields.filter((item)=>{
// // console.log(item,'item')
//  if(item.name==val){
//    return newone.push(item)
//  }
 
// })          
// })
// console.log(newone,'fieldsnewonebreak')
// // this.FieldForm[ind].if_condition.conditions[ind2].valforboxes=newone
//    //only for one time loop
//    newone.forEach((item)=>{
//     this.FieldForm[ind].if_condition.conditions[ind2].valforboxes=item

//   })
// console.log(this.FieldForm[ind].if_condition.conditions[ind2].valforboxes.hasOwnProperty('values'),'fasdasdaieldin decedsiones')

// }

//    }
//    else if (val.includes('rating')){
  
//       this.FieldForm[ind].if_condition.conditions[ind2].groupFields=[]
//       this.FieldForm[ind].if_condition.conditions[ind2].valforboxes={}
//       this.FieldForm[ind].if_condition.conditions[ind2].AddressType=[]
//       this.FieldForm[ind].if_condition.conditions[ind2].multiplecheckbox=[]
   
//       this.FieldForm[ind].if_condition.conditions[ind2].groupFields=[]
//       this.FieldForm[ind].if_condition.conditions[ind2].LikertQuestions.Questions=[]
//       this.FieldForm[ind].if_condition.conditions[ind2].LikertQuestions.values=[]
// if(!this.Break){
//   let newone= this.Fields.find((item)=>{
//     return item.name==val
//         })
//       // console.log(newone,'newone');
//         this.FieldForm[ind].if_condition.conditions[ind2].rating.accept=newone.accept
//         this.FieldForm[ind].if_condition.conditions[ind2].rating.value=newone.value
//         this.FieldForm[ind].if_condition.conditions[ind2].rating.size=newone.size
//         this.FieldForm[ind].if_condition.conditions[ind2].rating.description=newone.description
// }else{
//   let newone =[]

//   this.Fields.forEach((fields)=>{
//     fields.filter((item)=>{
//     // console.log(item,'item')
//     if(item.name==val){
//       return newone.push(item)
//      }
     
//     })    
//   // console.log(newone,'newone')     
//   // console.log(this.FieldForm,'ffdd') 
//     this.FieldForm[ind].if_condition.conditions[ind2].rating.accept=newone[0].accept
//     this.FieldForm[ind].if_condition.conditions[ind2].rating.value=newone[0].value
//     this.FieldForm[ind].if_condition.conditions[ind2].rating.size=newone[0].size
//     this.FieldForm[ind].if_condition.conditions[ind2].rating.description=newone[0].description
//     })
// }




//   // console.log(this.FieldForm,'fgfgfgfg');
    
          
//    }
//    else if(val.includes('radio')){
 
  

//     this.FieldForm[ind].if_condition.conditions[ind2].AddressType=[]
//     this.FieldForm[ind].if_condition.conditions[ind2].multiplecheckbox=[]
//     this.FieldForm[ind].if_condition.conditions[ind2].rating.accept=''
//     this.FieldForm[ind].if_condition.conditions[ind2].rating.value=''
//     this.FieldForm[ind].if_condition.conditions[ind2].rating.size=''
//     this.FieldForm[ind].if_condition.conditions[ind2].rating.description=''
//     this.FieldForm[ind].if_condition.conditions[ind2].groupFields=[]
//     this.FieldForm[ind].if_condition.conditions[ind2].LikertQuestions.Questions=[]
//     this.FieldForm[ind].if_condition.conditions[ind2].LikertQuestions.values=[]
//     // this.FieldForm[ind].if_condition.conditions[ind2].FieldConstraint=this.FieldConditionList

// console.log(this.FieldForm,'ffff11)')
// if(!this.Break){
//   let newone= this.Fields.find((item)=>{
//     return item.name==val
//         })
//         this.FieldForm[ind].if_condition.conditions[ind2].valforboxes=newone
// }else{
//   let newone =[]

//   this.Fields.forEach((fields)=>{
//     fields.filter((item)=>{
//     // console.log(item,'item')
//     if(item.name==val){
//       return newone.push(item)
//      }
     
//     })    
//   // console.log(newone,'newone')     
//   // console.log(this.FieldForm,'ffdd') 
//     //only for one time loop
//     newone.forEach((item)=>{
//       this.FieldForm[ind].if_condition.conditions[ind2].valforboxes=item

//     })
//     })
// }

//    }
//    else if(val.includes('autocomplete')){
//   // console.log(this.Fields,'ggyyy')
  
//     this.FieldForm[ind].if_condition.conditions[ind2].valforboxes={}
//     this.FieldForm[ind].if_condition.conditions[ind2].AddressType=[]
 
//     this.FieldForm[ind].if_condition.conditions[ind2].rating.accept=''
//     this.FieldForm[ind].if_condition.conditions[ind2].rating.value=''
//     this.FieldForm[ind].if_condition.conditions[ind2].rating.size=''
//     this.FieldForm[ind].if_condition.conditions[ind2].rating.description=''
//     this.FieldForm[ind].if_condition.conditions[ind2].groupFields=[]
//     this.FieldForm[ind].if_condition.conditions[ind2].LikertQuestions.Questions=[]
//     this.FieldForm[ind].if_condition.conditions[ind2].LikertQuestions.values=[]

// console.log(this.FieldForm,'dddff');
// // this.FieldForm[ind].if_condition.conditions[ind2].FieldConstraint=this.FieldConditionList

// console.log(this.FieldForm,'ffff11)')
// if(!this.Break){
//   let newone= this.Fields.find((item)=>{
//     return item.name==val
//         })
//     this.FieldForm[ind].if_condition.conditions[ind2].multiplecheckbox=newone.values

//       // console.log(newone,'newwwaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa')
// }else{
//   let newone =[]

//   this.Fields.forEach((fields)=>{
//     fields.filter((item)=>{
//     // console.log(item,'item')
//     if(item.name==val){
//       return newone.push(item)
//      }
     
//     })    
//   // console.log(newone,'newone')     
//   // console.log(this.FieldForm,'ffdd') 
//     this.FieldForm[ind].if_condition.conditions[ind2].multiplecheckbox=newone[0].values
//   // console.log(this.FieldForm,'fff')
//     //  newone.forEach((item)=>{
//     //   this.FieldForm[ind].if_condition.conditions[ind2].valforboxes=item

//     // })
//     })

// }


//    }
//    else if(val.includes('address-group')){
//     // alert('address-group')
//   // console.log(this.Fields,'ggyyy')
    
//           this.FieldForm[ind].if_condition.conditions[ind2].valforboxes={}
          
//           this.FieldForm[ind].if_condition.conditions[ind2].multiplecheckbox=[]
//           this.FieldForm[ind].if_condition.conditions[ind2].rating.accept=''
//           this.FieldForm[ind].if_condition.conditions[ind2].rating.value=''
//           this.FieldForm[ind].if_condition.conditions[ind2].rating.size=''
//           this.FieldForm[ind].if_condition.conditions[ind2].rating.description=''
//           this.FieldForm[ind].if_condition.conditions[ind2].groupFields=[]
//           this.FieldForm[ind].if_condition.conditions[ind2].LikertQuestions.Questions=[]
//           this.FieldForm[ind].if_condition.conditions[ind2].LikertQuestions.values=[]
//           if(!this.Break){
//             let newone= this.Fields.find((item)=>{
//               return item.name==val
//                   })
                  
//                   this.FieldForm[ind].if_condition.conditions[ind2].AddressType=newone.addressGroup
//           }
//           else{
//           // console.log('val',val)
//             let newone =[]
//           this.Fields.forEach((fields)=>{
//         fields.filter((item)=>{
//         // console.log(item,'item')
//            if(item.name==val){
//              return newone.push(item)
//            }
           
//           })          
//         })
//       // console.log(newone,'fieldsnewonebreak')
//         this.FieldForm[ind].if_condition.conditions[ind2].AddressType=newone[0].addressGroup

            
//           }

// console.log(this.FieldForm,"ffff")
//    }
//    else if(val.includes('group')){
//     // alert('group')
//   // console.log(this.Fields,'ggyyy')
  
//           this.FieldForm[ind].if_condition.conditions[ind2].valforboxes={}
//           this.FieldForm[ind].if_condition.conditions[ind2].conditiontype='text'
//           this.FieldForm[ind].if_condition.conditions[ind2].AddressType=[]
//           this.FieldForm[ind].if_condition.conditions[ind2].multiplecheckbox=[]
//           this.FieldForm[ind].if_condition.conditions[ind2].rating.accept=''
//           this.FieldForm[ind].if_condition.conditions[ind2].rating.value=''
//           this.FieldForm[ind].if_condition.conditions[ind2].rating.size=''
//           this.FieldForm[ind].if_condition.conditions[ind2].rating.description=''

//           this.FieldForm[ind].if_condition.conditions[ind2].LikertQuestions.Questions=[]
//           this.FieldForm[ind].if_condition.conditions[ind2].LikertQuestions.values=[]
// if(!this.Break){
//   let newone= this.Fields.find((item)=>{
//     return item.name==val
//         })
//         this.FieldForm[ind].if_condition.conditions[ind2].groupFields=newone.groupFields
// }else{
//   let newone =[]
// console.log(this.Fields,'fffyy')
//   this.Fields.forEach((fields)=>{
//     fields.forEach((item)=>{
//   // console.log(item,'item')
//     if(item.type=='group'){
//       return newone.push(item)
//      }
     
//     })    
//   // console.log(newone,'newonefff')     
//   // console.log(this.FieldForm,'ffdd') 
//     this.FieldForm[ind].if_condition.conditions[ind2].groupFields=newone[0].groupFields
//   // console.log(this.FieldForm,'aftergroupClick')
//   })
// }


// console.log(this.FieldForm,"ffff")
//    }
//    else if (val.includes('likert')){
//  // console.log("likertinnn")
//           this.FieldForm[ind].if_condition.conditions[ind2].valforboxes={}
//           this.FieldForm[ind].if_condition.conditions[ind2].conditiontype='text'
//           this.FieldForm[ind].if_condition.conditions[ind2].AddressType=[]
//           this.FieldForm[ind].if_condition.conditions[ind2].multiplecheckbox=[]
//           this.FieldForm[ind].if_condition.conditions[ind2].rating.accept=''
//           this.FieldForm[ind].if_condition.conditions[ind2].rating.value=''
//           this.FieldForm[ind].if_condition.conditions[ind2].rating.size=''
//           this.FieldForm[ind].if_condition.conditions[ind2].rating.description=''
//           this.FieldForm[ind].if_condition.conditions[ind2].groupFields=[]
     

//           if(!this.Break){
//             let newone= this.Fields.find((item)=>{
//               return item.name==val
//                   })
//                 // console.log(newone,'likertnewone')    
//       this.FieldForm[ind].if_condition.conditions[ind2].LikertQuestions.Questions=newone.QuestionArr
//       this.FieldForm[ind].if_condition.conditions[ind2].LikertQuestions.values=newone.QuestionArr[0].values
//   // console.log(this.FieldForm,"ffddfdfdfdfd====");

//           }else{
//              let newone =[]

//   this.Fields.forEach((fields)=>{
//     fields.filter((item)=>{
//     // console.log(item,'item')
//     if(item.name==val){
//       return newone.push(item)
//      }
     
//     })    
//   // console.log(newone,'newoneliikppp')   
//   })
//   this.FieldForm[ind].if_condition.conditions[ind2].LikertQuestions.Questions=newone[0].QuestionArr
//   this.FieldForm[ind].if_condition.conditions[ind2].LikertQuestions.values=newone[0].QuestionArr[0].values
//           }
 
//          }
//    else{  
//     this.FieldForm[ind].if_condition.conditions[ind2].valforboxes={}
//     this.FieldForm[ind].if_condition.conditions[ind2].conditiontype='text'
//     this.FieldForm[ind].if_condition.conditions[ind2].AddressType=[]
//     this.FieldForm[ind].if_condition.conditions[ind2].multiplecheckbox=[]
//     this.FieldForm[ind].if_condition.conditions[ind2].rating.accept=''
//     this.FieldForm[ind].if_condition.conditions[ind2].rating.value=''
//     this.FieldForm[ind].if_condition.conditions[ind2].rating.size=''
//     this.FieldForm[ind].if_condition.conditions[ind2].rating.description=''
//     this.FieldForm[ind].if_condition.conditions[ind2].groupFields=[]
//     this.FieldForm[ind].if_condition.conditions[ind2].LikertQuestions.Questions=[]
//     this.FieldForm[ind].if_condition.conditions[ind2].LikertQuestions.values=[]

//    }
   

//     this.Condition_Show=true
//   // console.log(this.Condition_Show,'111');
    
//   }
}
