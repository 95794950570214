import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from 'src/app/shared/services/common.service';
import Swal from 'sweetalert2';
import { ApiClientService } from '../../shared/services/api-client.service';

@Injectable({
  providedIn: 'root'
})
export class UserProfileService {

  constructor(
    public toster: ToastrService,
    private apiClientService: ApiClientService,
    private cookieService: CookieService,
    private commnService: CommonService
  ) { }

  fetchProfile() {
    return this.apiClientService.get('profile?type=dashboard');
  }

  updateProfile(data: any,value) {
      return this.apiClientService.post('update_profile?type=dashboard', data).subscribe(result => {
        if(result && result['success']==true) {
          // this.toster.success(result['message']);
          Swal.fire({
            position: 'center',
            icon: 'success',
            html: result['message'],
            showConfirmButton: false,
            timer: 1500,
            padding: '2em',
            showClass: {
              popup: 'animate__animated animate__fadeInDown'
            },
            hideClass: {
              popup: 'animate__animated animate__fadeOutUp'
            }
          });
        this.commnService.setprofilehit('true')
        this.commnService.setprofilename(data)
        setTimeout(() => {

          window.location.href="/user-profile/edit-profile"

        },2000)

          // if(this.cookieService.get('user_name')){
            // this.cookieService.delete('user_name');
          // }
          // this.cookieService.set('user_name', value.first_name + value.last_name );
        }
      },error =>{
        Swal.fire({
          position: 'center',
          icon: 'error',
          html: error.error['message'],
          showConfirmButton: true,
          padding: '2em',
        });
      })
  }
}
