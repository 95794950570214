import { AppModule } from './app/app.module';
import { enableProdMode } from '@angular/core';
import { environment } from './environments/environment';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

if (environment.production) {
  enableProdMode();  if (window) {
//  window.console.log = function () { };
    window.console.warn = function () { };
    // window.console.error = function () { };
  }
} else {
  if (window) {

    // window.console.log = function () { };
    window.console.warn = function () { };
    // window.console.error = function () { };



  }
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
