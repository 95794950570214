<div class="row">
  <div class="col-sm-12">
    <form
      class="needs-validation"
      novalidate
      [ngClass]="{ 'was-validated': validate }"
    >
      <!-- <div class="card"> -->
      <!-- {{discountRuleForm | json}} -->
      <!-- <div class="card-body px-4 py-2"> -->
      <ng-container
        *ngFor="let ruleitem of discountRuleForm; let ruleindex = index;let last = last"
      >
        <div class="rulesDup">
          <input
            type="text"
            class="form-control"
            placeholder="Rule Name"
            required=""
            [(ngModel)]="ruleitem.rule_name"
            [ngModelOptions]="{ standalone: true }"
          />
          <div class="fieldRuleConditions">
            <div class="ruleCont" style="margin: 33px 0 0 0">
              <div class="contDivInner">
                <span class="newRuleIfTxt">If</span>
                <div class="if-wrapper ifContainer fieldRuleIfCont">
                  <div class="form-group">
                    <select
                      class="custom-select form-control mr-3"
                      [(ngModel)]="ruleitem.if_condition.allow"
                      required=""
                      [ngModelOptions]="{ standalone: true }"
                    >
                      <option
                        value="any"
                        [selected]="ruleitem.if_condition.allow === 'any'"
                      >
                        Any
                      </option>
                      <option
                        value="all"
                        [selected]="ruleitem.if_condition.allow === 'all'"
                      >
                        All
                      </option>
                    </select>
                    <label for="">of the following conditions are met</label>
                  </div>
                  <!-- //migrated if condition -->
                  <ng-container class="fieldRow my-2 py-2" *ngIf="d2arr && d2arr.length >0;else baseInfoTemplate">
                  <ng-container *ngFor="let conditionItem of ruleitem.if_condition.conditions;let conindex = index">
                    <!-- {{conditionItem.field_type}}{{Condition_Show}} -->
                      <div class="col-sm-12">
                        <div class="d-flex align-items-center py-1">
                          <div class="form-group mr-3 mb-0">
                            <div class="user-info-dropdown flLeft commonBdr">
                              <ng-container *ngIf="d2arr && d2arr.length > 0 && !Break">

                                <!-- {{d2arr | json}} -->
                                <!-- {{d2arr |json}} -->
                                <!-- {{conditionItem.type}} -->
                                <!-- <select class="custom-select form-control" [(ngModel)]="conditionItem.field_type" required="" (change)="onUserInfoFieldSelect($event.target.value,ruleindex,conindex)" [ngModelOptions]="{ standalone: true }"> -->
                                <!-- <h1>11</h1> -->
                                <!-- {{conditionItem.field_type2}} -->
                                <!-- asdas -->
                                <!-- {{conditionItem.field_type}} -->
                                  <select class="custom-select form-control" 
                                [(ngModel)]="conditionItem.field_type2"
                                 required="" (change)="onUserInfoFieldSelect($event.target.value,ruleindex,conindex)"
                                 
                                 [ngModelOptions]="{ standalone: true }">
                                  
                                  <option value ="">Select Fields</option>
                                  
                                  <ng-container *ngFor="let item of d2arr; index as i">
                                    <!-- {{item.type}} -->
                                 <!-- {{conditionItem.field_type2}} {{ item.name}}  {{ conditionItem.field_type2 }} -->
                                <option [value]="item.name" *ngIf="item.type !='paragraph' && item.type !='likert'" [disabled]="item.name =='Basic Info' || item.name =='Form-Fields'" [selected]="">{{ item.label }}</option>
                                  </ng-container>
                                </select>
                              </ng-container>
                              <!-- <ng-container *ngIf="Fields && Fields.length > 0 && Break" > -->
                                <ng-container *ngIf="d2arr && d2arr.length > 0 && Break" >
                                <!-- <h1>Break</h1> -->
                                <select
                                class="custom-select form-control"
                                [(ngModel)]="conditionItem.field_type2"
                                required=""
                                (change)="onUserInfoFieldSelect($event.target.value,ruleindex,conindex)" 
                                [ngModelOptions]="{ standalone: true }"
                              >
                              <option value ="">Select Fields</option>


                                <ng-container
                                *ngFor="
                                  let item of d2arr;
                                  index as i
                                "
                              >
                              <ng-container *ngFor="let ite of item;index as j">
                                <ng-container *ngIf="i==0 ">
                                  <option
                                  value=""
                                  disabled="true"
                                  
                                >
                                  {{ ite.name }}
                                </option>
                              </ng-container>
                               <ng-container *ngIf="j ==0 && i!=0 && i !=d2arr.length-1">
                                <option
                                value=""
                                disabled="true"
                                
                              >
                                {{ ite.pageTitle }}
                              </option>
                            </ng-container>
                            <ng-container *ngIf="j ==0 && i ==d2arr.length-1">
                              <option
                              value=""
                              disabled="true"
                              
                            >
                              {{ ite.name }}
                            </option>
                          </ng-container>
                            <ng-container *ngIf="j >0" >
                              <option
                              [value]="ite.name"
                              [selected]="j == 0"
                              *ngIf="ite.type !='paragraph' && ite.type !='likert'"
                            >
                              {{ ite.label }}
                            </option>
                          </ng-container>
                              </ng-container>
                                
                              </ng-container>
                            </select>
                              </ng-container>
                          
                           
                            </div>
                            <div class="clearBoth"></div>
                          </div>
                          <div
                          class="form-group mr-3 mb-0"
                          *ngIf="conditionItem.AddressType.length>0"
                        >
                          <div class="rule-condition-list">
                            <!-- <h1>abcd</h1> -->
                            <select
                              class="custom-select form-control"
                              
                              required=""
                            >
                              <ng-container
                                *ngFor="
                                  let item of conditionItem.AddressType;
                                  index as i
                                "
                              >
                                <option
                                  [value]="item.id"
                                  [selected]="
                                    ruleEdit
                                      ? item.id ==
                                        conditionItem.constraint_id
                                      : i == 0
                                  "
                                >
                                  {{ item.label }}
                                </option>
                              </ng-container>
                            </select>
                          </div>
                        </div>
                        <div
                        class="form-group mr-3 mb-0"
                        *ngIf="conditionItem.groupFields && conditionItem.groupFields.length>0 && !Break" 
                      >
                      <!-- <h1>11</h1>
{{conditionItem | json}} -->
                        <div class="rule-condition-list">
                          <select
                          [(ngModel)]="conditionItem.group_type2"
                          [ngModelOptions]="{standalone: true}"
                            class="custom-select form-control"
                            (change)="onUserInfoFieldSelect3($event.target.value,ruleindex,conindex)"
                            required=""
                          >
                            <ng-container
                              *ngFor="
                                let item of conditionItem.groupFields;
                                index as i
                              "
                            >
                              <option
                                [value]="item.name"
                                *ngIf="item.type !='paragraph' && item.type !='likert'"
                              >
                                {{ item.label }}
                              </option>
                            </ng-container>
                          </select>
                        </div>
                      </div>
<!-- break with group fields -->
<div
class="form-group mr-3 mb-0"
*ngIf="conditionItem.groupFields && conditionItem.groupFields.length>0 && Break" 
>
<!-- <h1>11</h1> -->

<div class="rule-condition-list">
  <select
  [(ngModel)]="conditionItem.group_type2"
  [ngModelOptions]="{standalone: true}"
    class="custom-select form-control"
    (change)="onUserInfoFieldSelect3($event.target.value,ruleindex,conindex)"
    required=""
  >
    <ng-container
      *ngFor="
        let item of conditionItem.groupFields;
        index as i
      "
    >
      <option
        [value]="item.name"
        *ngIf="item.type !='paragraph' && item.type != 'likert'"
      >
        {{ item.label }}
      </option>
    </ng-container>
  </select>
</div>
</div>
<!-- //likert -->
                      <div
                      class="form-group mr-3 mb-0"
                      *ngIf="conditionItem.LikertQuestions.Questions.length>0"
                    >
                    <!-- <h1>11</h1> -->
                    <!-- {{conditionItem.likertquestion}} -->
                      <div class="rule-condition-list"  *ngIf="conditionItem.groupFields.length==0">
                        <select
                        [(ngModel)]="conditionItem.likertquestion"
                        [ngModelOptions]="{ standalone: true }"
                          class="custom-select form-control"
                          (change)="onlikertoptions($event.target.value,ruleindex,conindex)"
                          required=""
                        >
                        <option value ="">Select Question</option>

                          <ng-container
                            *ngFor="
                              let item of conditionItem.LikertQuestions.Questions;
                              index as i
                            "
                          >
                            <option
                              [value]="item.QuestionName"
                            
                            >
                              {{ item.Question }}
                            </option>
                          </ng-container>
                        </select>
                      </div>

<!-- for Group -->
<div class="rule-condition-list"  *ngIf="conditionItem.groupFields.length>0">
  <!-- <h1>group</h1> -->
  <select
  [(ngModel)]="conditionItem.likertquestion"
  [ngModelOptions]="{ standalone: true }"
    class="custom-select form-control"
    (change)="onlikertoptionsGroup($event.target.value,ruleindex,conindex)"
    required=""
  >
  <option value ="">Select Question</option>

    <ng-container
      *ngFor="
        let item of conditionItem.LikertQuestions.Questions;
        index as i
      "
    >
      <option
        [value]="item.QuestionName"
      
      >
        {{ item.Question }}
      </option>
    </ng-container>
  </select>
</div>
                    </div>
                 <!-- // Constraintdata -->
                 <!-- {{conditionItem.field_type}}{{Condition_Show}} -->
                      <div
                            class="form-group mr-3 mb-0"
                            *ngIf="conditionItem.field_type !== '' && Condition_Show;"
                          >
                          <!-- {{conditionItem.constraint_id}} -->

                            <div class="rule-condition-list">
                              <select
                                class="custom-select form-control"
                                (change)="Constraintdata($event,ruleindex,conindex)"
                                required=""
                              >
                                <ng-container
                                  *ngFor="
                                    let item of conditionItem.FieldConstraint;
                                    index as i
                                  "
                                >

                                  <!-- <option
                                    [value]="item.id"
                                    [selected]="
                                      ruleEdit
                                        ? item.id ==
                                          conditionItem.constraint_id
                                        : i == 0
                                    "
                                  > -->
                                  <option
                                  [value]="item.id"
                                  [selected]="
                                      item.id ==
                                        conditionItem.constraint_id
                                    
                                  "
                                >

                                {{ item.name }}
                                  </option>
                                </ng-container>
                              </select>
                            </div>
                          </div>
                          <!-- norml conditions value -->
                          <div
                            class="form-group mr-2 mb-0"   
         
         *ngIf=" conditionItem.showValueBox && conditionItem.multiplecheckbox.length ==0 && conditionItem.rating.accept =='' && conditionItem.LikertQuestions.values.length ==0 && conditionItem.constraint_id !='3' && conditionItem.constraint_id !='4' "
                          >
                          <!-- <h1>bb</h1> -->
                            <div class="condition-value-input" *ngIf=" !conditionItem.FileEmpt &&!conditionItem.valforboxes.hasOwnProperty('values')&&!conditionItem.valforradio.hasOwnProperty('values') && !conditionItem.Nationality &&!conditionItem.PhoneCode">
                              <!-- <div class="condition-value-input" *ngIf="conditionItem.valforboxes.length ==0"> -->
                               <!-- <h1>412</h1> -->
                              
                              <input
                                [type]="conditionItem.conditiontype"
                                class="form-control"
                                placeholder="Conditions Value"
                                [(ngModel)]="conditionItem.value"
                                [ngModelOptions]="{ standalone: true }"
                                required=""
                              />
                           
                            </div>
                            <div class="condition-value-input" *ngIf="conditionItem.valforradio.hasOwnProperty('values') ">
                              <!-- <div class="condition-value-input" *ngIf="conditionItem.valforboxes.length ==0"> -->
                               <!-- <h1>412</h1> -->
                             <select [(ngModel)]="conditionItem.value" 
                             [ngModelOptions]="{ standalone: true }"
                             class="form-control"


                             >
                              <option
                                    *ngFor="let value of conditionItem.valforradio.values" [value]="value.value">
                                    {{value.label}}
                                  </option>
                             </select> 
                              <!-- <input
                                [type]="conditionItem.conditiontype"
                                class="form-control"
                                placeholder="Conditions Value"
                                [(ngModel)]="conditionItem.value"
                                [ngModelOptions]="{ standalone: true }"
                                required=""
                              /> -->
                           
                            </div>
                            <div class="condition-value-input" *ngIf="conditionItem.Nationality&& !conditionItem.PhoneCode">
                              <!-- <div class="condition-value-input" *ngIf="conditionItem.valforboxes.length ==0"> -->
                              <!-- <h1>41</h1> -->
                              
                              <ng-select style="width: 220px;" [items]="countriesList" bindLabel="country_name" bindValue="id" class="d-flex align-items-center form-control" placeholder="Conditions Value"
                              [(ngModel)]="conditionItem.value"
                              [ngModelOptions]="{ standalone: true }">
                                <ng-template ng-label-tmp let-item="item"><img [src]="'/assets/images/country_flags/'+item.country_code+'.svg'" height="20" width="25">{{item.country_name}}</ng-template>
                                <ng-template ng-option-tmp let-item="item" let-index="index">
                                    <!-- <div >{{item.flag}} {{item.country_name}}</div> -->
                                    <div (click)="AddID(item.id,ruleindex,conindex)"><img [src]="'/assets/images/country_flags/'+item.country_code+'.svg'" height="20" width="25"> <b>{{item.country_name}}</b> </div>

                                </ng-template>
                            </ng-select>
                           
                            </div>
                            <div class="condition-value-input" *ngIf="!conditionItem.Nationality&& conditionItem.PhoneCode">
                              <!-- <div class="condition-value-input" *ngIf="conditionItem.valforboxes.length ==0"> -->
                              <!-- <h1>41</h1> -->
                              
                              <ng-select style="width: 220px;" [items]="countriesList" bindLabel="country_name" bindValue="phone_code" class="d-flex align-items-center form-control"placeholder="Conditions Value"
                              [(ngModel)]="conditionItem.value"
                              [ngModelOptions]="{ standalone: true }">
                                <ng-template ng-label-tmp let-item="item"> {{ item.flag }} {{ item.phone_code }}
                                  {{ item.country_name }}</ng-template>
                                <ng-template ng-option-tmp let-item="item" let-index="index">
                                    <div > {{ item.flag }} {{ item.phone_code }}
                                      {{ item.country_name }}</div>
                                </ng-template>
                            </ng-select>
                           
                            </div>
                            <!-- {{conditionItem.valforboxes.hasOwnProperty('values')}} && {{ conditionItem.constraint_id}} -->
                            <div class="rule-condition-list" *ngIf="conditionItem.valforboxes.hasOwnProperty('values') && conditionItem.constraint_id !='5' && conditionItem.constraint_id !='6' && conditionItem.constraint_id !='7' && conditionItem.constraint_id !='8' && conditionItem.constraint_id !='3' && conditionItem.constraint_id !='4'">
                              <!-- <div class="rule-condition-list" *ngIf="conditionItem.valforboxes.length > 0 && conditionItem.constraint_id !='5' && conditionItem.constraint_id !='6' && conditionItem.constraint_id !='7' && conditionItem.constraint_id !='8' && conditionItem.constraint_id !='3' && conditionItem.constraint_id !='4'"> -->
                            <!-- <h1>11</h1> -->
                              <ng-select
                              [items]="conditionItem.valforboxes.values"
                              bindLabel="label"
                              [bindValue]="value"
                              class="js-example-basic-multiple ng-select-control"
                              [(ngModel)]="conditionItem.value"
                              placeholder="Select Options"
                              [multiple]="true"
                              [ngModelOptions]="{ standalone: true }"
                            required=""

                            ></ng-select>
                        <!-- {{conditionItem.value | json}} -->

                            </div>
                            <ng-container  *ngIf="conditionItem.valforboxes.hasOwnProperty('values') && (conditionItem.constraint_id =='5' || conditionItem.constraint_id =='6' || conditionItem.constraint_id =='7' || conditionItem.constraint_id =='8') && conditionItem.constraint_id !='3' && conditionItem.constraint_id !='4'">
                              <!-- <div class="condition-value-input" *ngIf="!conditionItem.valforboxes.hasOwnProperty('values')"> -->
                                <div class="condition-value-input" >
                            <!-- <h1>21</h1> -->

                                <input
                                  type="text"
                                  class="form-control"
                                  placeholder="Conditions Value"
                                  [(ngModel)]="conditionItem.value"
                                  [ngModelOptions]="{ standalone: true }"
                                  required=""
                                />
                             
                              </div>
                          
                          </ng-container> 
                          </div>
                          <!-- multiselect constion value with contain/notontain/startswith/endswith -->
                          <ng-container  *ngIf="conditionItem.multiplecheckbox.length>0 && conditionItem.constraint_id !='5' && conditionItem.constraint_id !='6' && conditionItem.constraint_id !='7' && conditionItem.constraint_id !='8' && conditionItem.constraint_id !='3' && conditionItem.constraint_id !='4'">
                            <!-- {{conditionItem.multiplecheckbox |json}} -->
                            <!-- <h1>51</h1> -->
                            <ng-select
                            [items]="conditionItem.multiplecheckbox"
                            bindLabel="label"
                            [bindValue]="value"
                            class="js-example-basic-multiple ng-select-control"
                            [(ngModel)]="conditionItem.value"
                            placeholder="Select Options"
                            [multiple]="true"
                            [ngModelOptions]="{ standalone: true }"
                            required=""
                          ></ng-select>
                        <!-- {{conditionItem.value | json}} -->
                            </ng-container> 
                          <!-- multiselect constion value with  NO contain/notontain/startswith/endswith -->
                          <ng-container  *ngIf="conditionItem.multiplecheckbox.length>0 && (conditionItem.constraint_id =='5' || conditionItem.constraint_id =='6' || conditionItem.constraint_id =='7' || conditionItem.constraint_id =='8')">
                            <!-- {{conditionItem.multiplecheckbox |json}} -->
                            <!-- <div class="condition-value-input" *ngIf="!conditionItem.valforboxes.hasOwnProperty('values')"> -->
                              <div class="condition-value-input" >
                                <!-- <h1>31</h1> -->
                              <input
                                type="text"
                                class="form-control"
                                placeholder="Conditions Value"
                                [(ngModel)]="conditionItem.value"
                                required=""
                                [ngModelOptions]="{ standalone: true }"
                              />
                           
                            </div>
                        
                        </ng-container> 
                          <!-- //rating Fields -->
                           <ng-container *ngIf="conditionItem.rating.accept == 'star'">
                            <ngb-rating [(rate)]="conditionItem.rating.value" [max]="conditionItem.rating.size" class="rating-size starShape" ></ngb-rating>
                            <small class="blockquote-footer" *ngIf="conditionItem.rating.description && conditionItem.rating.description !== ''"><cite>{{  conditionItem.rating.description }}</cite></small>
                            <!-- <select
                            class="custom-select form-control"
                            
                            required=""
                          >
                            <ng-container
                             
                              *ngFor="let dummy of ' '.repeat(conditionItem.rating.size).split(''), let x = index"
                                
                              
                            >

                              <option
                                [value]="x"
                               
                              >
                               {{x}}
                              </option>
                            </ng-container>
                          </select> -->
                          </ng-container>
      
                           <ng-container *ngIf="conditionItem.rating.accept == 'heart'">
                            <ng-template #t let-fill="fill">
                              <span class="star" [class.full]="fill === 100">
                                <span class="half" [style.width.%]="fill">&hearts;</span>&hearts;
                              </span>
                            </ng-template>
                            
                            <ngb-rating class="heartShape" [(rate)]="conditionItem.rating.value" [starTemplate]="t" [max]="conditionItem.rating.size"></ngb-rating>
                            <small class="blockquote-footer" *ngIf="conditionItem.rating.description && conditionItem.rating.description !== ''"><cite>{{ conditionItem.rating.description }}</cite></small>
  
                          </ng-container>
                          <ng-container *ngIf="conditionItem.rating.accept == 'circle'">
                            <ng-template #c let-fill="fill">
                              <span class="star" [class.fullCircle]="fill === 100">
                                <span class="halfCircle" [style.width.%]="fill">&#11044;</span>&#11044;
                              </span>
                            </ng-template>
      
                            <ngb-rating class="circleShape" [(rate)]="conditionItem.rating.value" [starTemplate]="c" [max]="conditionItem.rating.size"></ngb-rating>
                            <small class="blockquote-footer" *ngIf="conditionItem.rating.description && conditionItem.rating.description !== ''"><cite>{{ conditionItem.rating.description }}</cite></small>
  
                          </ng-container>
                          <ng-container *ngIf="conditionItem.rating.accept == 'flag'">
                            <ng-template #f let-fill="fill">
                              <span class="star" [class.flagfull]="fill === 100">
                                <span class="flaghalf" [style.width.%]="fill"><i class="fa fa-flag"></i></span><i class="fa fa-flag-o"></i>
                              </span>
                            </ng-template>
      
                            <ngb-rating class="flagShape" [(rate)]="conditionItem.rating.value" [starTemplate]="f" [max]="conditionItem.rating.size"></ngb-rating>
                            <small class="blockquote-footer" *ngIf="conditionItem.rating.description && conditionItem.rating.description !== ''"><cite>{{ conditionItem.rating.description }}</cite></small>
  
                          </ng-container>
                          <ng-container *ngIf="conditionItem.rating.accept == 'emoji'">
                            <div class="emojiRatings">
                                <span (click)="conditionItem.rating.value = 1" [class.active]="conditionItem.rating.value === 1" class="emoji"><span>😟</span></span>
                                <span (click)="conditionItem.rating.value = 2" [class.active]="conditionItem.rating.value === 2" class="emoji"><span>😐</span></span>
                                <span (click)="conditionItem.rating.value = 3" [class.active]="conditionItem.rating.value === 3" class="emoji"><span>😀</span></span>
                                <span (click)="conditionItem.rating.value = 4" [class.active]="conditionItem.rating.value === 4" class="emoji"><span>🤩</span></span>
                                <span (click)="conditionItem.rating.value = 5" [class.active]="conditionItem.rating.value === 5" class="emoji"><span>😍</span></span>
                            </div>
                            <small class="blockquote-footer" *ngIf="conditionItem.rating.description && conditionItem.rating.description !== ''"><cite>{{ conditionItem.rating.description }}</cite></small>
  
                          </ng-container>  
                        <!-- </div> -->
                           <!-- //likert question -->
                    <div
                    class="form-group mr-3 mb-0"
                    *ngIf="conditionItem.LikertQuestions.values.length>0"
                  >
                  <!-- <h1>21</h1> -->
                    <div class="rule-condition-list">
                      <select
                      [(ngModel)]="conditionItem.value"
                      [ngModelOptions]="{ standalone: true }"
                        class="custom-select form-control"
                        (change)="onUserInfoFieldSelect3($event.target.value,ruleindex,conindex)"
                        required=""
                      >
                      
                      <option value ="">Select Value</option>
                     
                        <ng-container
                          *ngFor="
                            let item of conditionItem.LikertQuestions.values;
                            index as i
                          "
                        >
                          <option
                            [value]="item.value"
                          
                          >
                            {{ item.label }}
                          </option>
                        </ng-container>
                      </select>
                    </div>
                  </div>
                          <div class="mr-3 mb-0">
                            <button
                              type="button"
                              class="customAddminus clone"
                              (click)="addNewRow(ruleindex,conindex)"
                            >
                              <i class="icon-plus"></i>
                            </button>
                            <button
                            *ngIf="ruleitem.if_condition.conditions.length>1"
                              type="button"
                              class="customAddminus delete"
                              (click)="removeRow(ruleindex, conindex)"
                            >
                              <i class="icon-minus"></i>
                            </button>
                          </div>
                        </div>
                      </div>
                    <!-- </div> -->
                  </ng-container>
                </ng-container>
                  <!-- //old one below -->
                  <ng-template #baseInfoTemplate>

                <ng-container
                    *ngFor="
                      let conditionItem of ruleitem.if_condition.conditions;
                      let conindex = index
                    "
                  >
                  <!-- {{ruleitem.if_condition.conditions|json}} -->
                  <!-- <h1>old structure</h1> -->
                  <!-- {{conindex}} -->
                    <div class="fieldRow my-2 py-2">
                      <div class="col-sm-12">
                        <div class="d-flex align-items-center py-1">
                          <div class="form-group mr-3 mb-0">
                            <div class="user-info-dropdown flLeft commonBdr">
                            
                               <!-- <h1>pre</h1> -->
                                <select class="custom-select form-control" (change)="PreConfigFields($event,conindex, ruleindex)"[(ngModel)]="conditionItem.field_type" required="" [ngModelOptions]="{ standalone: true }">

                              <option Value ="">Select Fields</option>
                                  <ng-container *ngFor="let item of basicInfoFields; let bi = index">
                                    <option [value]="item.slug" [selected]="conditionItem.field_type == item.slug">
                                      {{ item.label }}
                                    </option>
                                  </ng-container>
                                </select>
                          </div>
                        </div>
                        <div class="form-group mr-3 mb-0"
                                >
               
                                <div class="rule-condition-list" *ngIf="conditionItem.field_type!=''">
                                  <select class="custom-select form-control" (change)="onRuleConditionSelect($event.target.value, conindex, ruleindex)" required="">
                                    <ng-container *ngFor="let item of ruleConditionList; index as i">
                                      <option [value]="item.id" [selected]="ruleEdit ? item.id == conditionItem.constraint_id : i == 0">{{ item.name }}</option>
                                    </ng-container>
                                  </select>
                                </div>
                              </div>
                              <!-- {{conditionItem |json}} -->
                              <div class="form-group mr-2 mb-0" *ngIf="conditionItem.showValueBox">
                                <!-- {{conditionItem |json}} -->
                                <div class="condition-value-input" *ngIf="!conditionItem.Nationality &&!conditionItem.PhoneCode">
                                  <!-- <p>asdasdas</p> -->
                                  <input type="text" class="form-control" placeholder="Conditions Value" [(ngModel)]="conditionItem.value" required="" [ngModelOptions]="{ standalone: true }"/>
                                </div>
                                <div class="condition-value-input" *ngIf="conditionItem.Nationality&& !conditionItem.PhoneCode">
                                  <!-- <div class="condition-value-input" *ngIf="conditionItem.valforboxes.length ==0"> -->
                                  <!-- <h1>41</h1> -->
                                  
                                  <ng-select style="width: 220px;" [items]="countriesList" bindLabel="country_name" bindValue="country_name" class="d-flex align-items-center form-control"placeholder="Conditions Value"
                                  [(ngModel)]="conditionItem.value"
                                  [ngModelOptions]="{ standalone: true }">
                                    <ng-template ng-label-tmp let-item="item"><img [src]="'/assets/images/country_flags/'+item.country_code+'.svg'" height="20" width="25"> {{item.country_name}}</ng-template>
                                    <ng-template ng-option-tmp let-item="item" let-index="index">
                                        <div ><img [src]="'/assets/images/country_flags/'+item.country_code+'.svg'" height="20" width="25"> {{item.country_name}}</div>
                                    </ng-template>
                                </ng-select>
                               
                                </div>
                                <div class="condition-value-input" *ngIf="!conditionItem.Nationality&& conditionItem.PhoneCode">
                                  <!-- <div class="condition-value-input" *ngIf="conditionItem.valforboxes.length ==0"> -->
                                  <!-- <h1>413</h1> -->
                                  
                                  <ng-select style="width: 220px;" [items]="countriesList" bindLabel="country_name" bindValue="phone_code" class="d-flex align-items-center form-control"placeholder="Conditions Value"
                                  [(ngModel)]="conditionItem.value"
                                  [ngModelOptions]="{ standalone: true }">
                                    <ng-template ng-label-tmp let-item="item"> {{ item.flag }} {{ item.phone_code }}
                                      {{ item.country_name }}</ng-template>
                                    <ng-template ng-option-tmp let-item="item" let-index="index">
                                        <div > {{ item.flag }} {{ item.phone_code }}
                                          {{ item.country_name }}</div>
                                    </ng-template>
                                </ng-select>
                               
                                </div>
                              </div>
                              <div class="mr-3 mb-0">
                                <button type="button" class="customAddminus clone" (click)="addNewRow(ruleindex)">
                                  <i class="icon-plus"></i>
                                </button>
                                <button *ngIf="conindex>0" type="button" class="customAddminus delete" (click)="removeRow(ruleindex, conindex)">
                                  <i class="icon-minus"></i>
                                </button>
                              </div>
                            </div>
                            <div class="clearBoth"></div>
                      </div>
                    </div>
                  </ng-container>
                </ng-template>

                </div>
                <span class="newRuleIfTxt newRuleThenTxt">Then</span>
              </div>
              <div class="actCondition">
                <span>Perform the following actions</span>
              </div>
              <div class="then-wrapper ruleActionWrapper">
                <div class="ruleAction">
                  <div class="fieldRow my-2 py-2 px-4 d-flex align-items-center">
                    <div class="form-group mr-3 mb-0">
                      <label for="">Discount Type</label>
                      <div class="rule-action-list">
                        <select
                          class="custom-select form-control text-capitalize"
                          [(ngModel)]="ruleitem.then_apply.type"
                          required=""
                          [ngModelOptions]="{ standalone: true }"
                        >
                          <option value="">Select Discount Type</option>
                          <ng-container *ngFor="let item of thenApply">
                            <option
                              [value]="item"
                              [selected]="item === ruleitem.then_apply.type"
                            >
                              {{ item }}
                            </option>
                          </ng-container>
                        </select>
                      </div>
                    </div>
                    <div class="form-group mr-3 mb-0">
                      <label for="">Discount Value</label>
                      <div
                        class="action-value-input"
                        *ngIf="maxvalidationapply"
                      >
                        <input
                          type="number"
                          class="form-control"
                          [(ngModel)]="ruleitem.then_apply.value"
                          required=""
                          [ngModelOptions]="{ standalone: true }"
                          (input)="MaxDiscountLimit($event, ruleindex)"
                        />
                    
                      </div>
                      <div
                        class="action-value-input"
                        *ngIf="!maxvalidationapply"
                      >
                      
                        <input
                          type="number"
                          class="form-control"
                          [(ngModel)]="ruleitem.then_apply.value"
                          required=""
                          [ngModelOptions]="{ standalone: true }"
                          (input)="MaxDiscountLimitgroupLink($event, ruleindex)"

                        />
                  
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="RuleNameCreate py-2 mx-4 mt-0">
            <label class="important">Required discount proof?</label>
            <div
              [ngClass]="
                ruleitem.proof === 'yes' ? '' : 'd-flex justify-content-between'
              "
            >
              <div class="m-checkbox-inline mt-3">
                <label
                  ><input
                    id="yes"
                    class="radio_animated"
                    type="radio"
                    placeholder="Yes"
                    [name]="'discountProof_' + ruleindex"
                    value="yes"
                    (change)="
                      discountProofRequire($event.target.value, ruleindex)
                    "
                    [checked]="ruleitem.proof == 'yes'"
                  />Yes</label
                >
                <label
                  ><input
                    id="no"
                    class="radio_animated"
                    type="radio"
                    placeholder="No"
                    [name]="'discountProof_' + ruleindex"
                    value="no"
                    (change)="
                      discountProofRequire($event.target.value, ruleindex)
                    "
                    [checked]="ruleitem.proof == 'no'"
                  />No</label
                >
              </div>
              <ng-container *ngIf="ruleitem.proof === 'yes'">
                <div class="my-4">
                  <div class="form-group">
                    <label for="">Discount Proof Title</label>
                    <input
                      class="form-control"
                      type="text"
                      placeholder="Discount Title"
                      required=""
                      [(ngModel)]="ruleitem.discount_proof_title"
                      [ngModelOptions]="{ standalone: true }"
                    />
                  </div>
                  <div class="form-group">
                    <label for="">Discount Proof Description</label>
                    <textarea
                      class="form-control"
                      rows="3"
                      placeholder="Discount Description"
                      required=""
                      [(ngModel)]="ruleitem.discount_proof_description"
                      [ngModelOptions]="{ standalone: true }"
                      >{{ ruleitem.discount_proof_description }}</textarea
                    >
                  </div>
                  <div class="form-group">
                    <div class="form-check m-checkbox-inline">
                      <label
                        [ngClass]="
                          validate &&
                          ruleitem.discount_proof_files_allowed.length === 0
                            ? 'text-danger'
                            : ''
                        "
                        >Allowed Files:</label
                      >
                      <label for="pdfFile" class="form-check-label">
                        <input
                          id="pdfFile"
                          class="checkbox_animated form-check-input"
                          type="checkbox"
                          placeholder=".pdf"
                          value="pdf"
                          (change)="allowedFiles($event, ruleindex)"
                          [checked]="
                            ruleitem.discount_proof_files_allowed != null
                              ? ruleitem.discount_proof_files_allowed.includes(
                                  'pdf'
                                )
                              : ''
                          "
                        />

                        .pdf
                      </label>
                      <label for="xlxsFile" class="form-check-label">
                        <input
                          id="xlxsFile"
                          class="checkbox_animated form-check-input"
                          type="checkbox"
                          placeholder=".xlxs"
                          value="xlsx"
                          (change)="allowedFiles($event, ruleindex)"
                          [checked]="
                            ruleitem.discount_proof_files_allowed != null
                              ? ruleitem.discount_proof_files_allowed.includes(
                                  'xlxs'
                                )
                              : ''
                          "
                        />
                        .xlxs
                      </label>
                      <label for="docFile" class="form-check-label">
                        <input
                          id="docFile"
                          class="checkbox_animated form-check-input"
                          type="checkbox"
                          placeholder=".doc"
                          value="doc"
                          (change)="allowedFiles($event, ruleindex)"
                          [checked]="
                            ruleitem.discount_proof_files_allowed != null
                              ? ruleitem.discount_proof_files_allowed.includes(
                                  'doc'
                                )
                              : ''
                          "
                        />
                        .doc
                      </label>
                      <label for="txtFile" class="form-check-label">
                        <input
                          id="txtFile"
                          class="checkbox_animated form-check-input"
                          type="checkbox"
                          placeholder=".png"
                          value="png"
                          (change)="allowedFiles($event, ruleindex)"
                          [checked]="
                            ruleitem.discount_proof_files_allowed != null
                              ? ruleitem.discount_proof_files_allowed.includes(
                                  'png'
                                )
                              : ''
                          "
                        />
                        .png
                      </label>
                      <label for="jpgFile" class="form-check-label">
                        <input
                          id="jpgFile"
                          class="checkbox_animated form-check-input"
                          type="checkbox"
                          placeholder=".jpg"
                          value="jpg"
                          (change)="allowedFiles($event, ruleindex)"
                          [checked]="
                            ruleitem.discount_proof_files_allowed != null
                              ? ruleitem.discount_proof_files_allowed.includes(
                                  'jpg'
                                )
                              : ''
                          "
                        />
                        .jpg
                      </label>
                      <label for="jpegFile" class="form-check-label">
                        <input
                          id="jpegFile"
                          class="checkbox_animated form-check-input"
                          type="checkbox"
                          placeholder=".jpeg"
                          value="jpeg"
                          (change)="allowedFiles($event, ruleindex)"
                          [checked]="
                            ruleitem.discount_proof_files_allowed != null
                              ? ruleitem.discount_proof_files_allowed.includes(
                                  'jpeg'
                                )
                              : ''
                          "
                        />
                        .jpeg
                      </label>
                    </div>
                    <span
                      *ngIf="
                        validate &&
                        ruleitem.discount_proof_files_allowed.length === 0
                      "
                      class="text-danger"
                      >Please select a file tipe</span
                    >
                  </div>
                  <div class="form-row form-group">
                    <div class="col-sm-12">
                      <label for="">Number of files to be uploaded</label>
                    </div>
                    <div class="col-md-6 col-lg-12 col-xl-6">
                      <input
                        type="number"
                        placeholder="Min"
                        class="form-control digits"
                        [(ngModel)]="
                          ruleitem.discount_proof_number_of_files.min
                        "
                        [ngModelOptions]="{ standalone: true }"
                        required=""
                      />
                    </div>
                    <div class="col-md-6 col-lg-12 col-xl-6">
                      <input
                        type="number"
                        placeholder="Max"
                        class="form-control digits"
                        [(ngModel)]="
                          ruleitem.discount_proof_number_of_files.max
                        "
                        [ngModelOptions]="{ standalone: true }"
                        required=""
                      />
                    </div>
                  </div>
                  <div class="form-row form-group">
                    <div class="col-sm-12">
                      <label for="">File size</label>
                    </div>
                    <div class="col-md-6 col-lg-12 col-xl-6">
                      <input
                        type="number"
                        placeholder="Minimum size in MB"
                        class="form-control digits"
                        [(ngModel)]="ruleitem.discount_proof_file_size.min"
                        [ngModelOptions]="{ standalone: true }"
                        required=""
                        step=".000001"
                      />
                    </div>
                    <div class="col-md-6 col-lg-12 col-xl-6">
                      <input
                        type="number"
                        placeholder="Maximum size in MB"
                        class="form-control digits"
                        [(ngModel)]="ruleitem.discount_proof_file_size.max"
                        [ngModelOptions]="{ standalone: true }"
                        required=""
                        step=".000001"

                      />
                      <!-- //aa -->
                    </div>
                  </div>
                </div>
              </ng-container>
              <div
                class="text-right"
                [ngClass]="ruleitem.proof === 'yes' ? '' : 'mb-3'"
                *ngIf="!ruleEdit"
              >
                <button
                
                  type="button"
                  class="btn btn-danger mr-2"
                  (click)="removeRule(ruleindex,ruleitem.id)"
                >
                  Delete Rule
                </button>
                <button
                *ngIf="last"
                  type="button"
                  class="btn btn-primary mr-2"
                  (click)="addNewRule()"
                >
                  Add Rule
                </button>
              </div>
            </div>
          </div>
        </div>
      </ng-container>
      <!-- </div> -->
      <div class="d-block mt-4" *ngIf="!hidesave">
        <button type="button" class="btn btn-primary mr-2" (click)="saveRule()">
          {{ ruleEdit ? "Update Rule" : "Save Rules" }}
        </button>
      </div>
      <!-- </div> -->
    </form>
  </div>
</div>
<!-- {{discountRuleForm |json}} -->
