import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { Subject } from 'rxjs';
import { CommonService } from 'src/app/shared/services/common.service';

@Component({
  selector: 'app-dynamic-tables',
  templateUrl: './dynamic-tables.component.html',
  styleUrls: ['./dynamic-tables.component.scss']
})
export class DynamicTablesComponent implements OnInit {
  // -------Pagination Array-------
  RowsArr = [
    { id: "0", value: 5 },
    { id: "1", value: 25 },
    { id: "2", value: 50 },
    { id: "3", value: 100 },
    { id: "4", value: 'All' },
  ];
  // -------------------
  searchVal: any = '' // search value variable
  FormSearchUpdate = new Subject<string>();  // Search update variable
  showheader: any = []   // collapse the heading
  showheader2: any = []   // collapse the heading 2
  // Static heading 
  Staticheading: any = ['ID', 'First Name', 'Last Name', 'Email', 'Type', 'Main User', 'Mobile No.', 'Category', 'Booking Date', 'Paid/Unpaid', 'Total Amount', 'Transactions', 'Discount Fee', 'Amount Paid', 'Transactions', 'Privacy', 'Age', 'Gender', 'Nationality']
  StaticheadingVote: any = ['ID', 'First Name', 'Last Name', 'Email', 'Type', 'Main User', 'Mobile No.', 'Booking Date', 'Privacy', 'Age', 'Gender', 'Nationality']
  // StaticheadingVote: any = ['First Name', 'Last Name', 'To', 'Subject']
  constructor(private cookie: CookieService, private router: Router, private common: CommonService) { }
  @Input() list: any[]
  @Input() pagefilters: any  // Page filters 
  @Input() listcount: any  // Page filters 
  @Input() type: any  // Page filters 
  // @Input() list:any[]
  ngOnInit(): void {
    // loop to make the array hide for all other headings except latest/first heading
    setTimeout(() => {
      if (this.type == 'standard_form') {

        this.list.forEach((li, i) => {
          this.showheader[i] = false
          li.form_data.attributes.forEach((attr) => {
            if (li.form_data.form_break) {
              attr.forEach((att, j) => {
                if (j === 0) {
                  // console.log(att,j,"INNN")
                  attr.splice(j, 1)
                }
              })

            }

          })
        })
      }
      else if (this.type == 'voting_form') {
        console.log(this.list, "LLLL")
        this.list.forEach((li, i) => {
          this.showheader[i] = false
          //  JSON.parse(li.booking_info.data)
          // console.log(li.booking_info.data.attributes,"AAAAA")
          // if(li.booking_info!=null){
          //   // JSON.parse(li.booking_info.data)
          //   li.booking_info.data.attributes.forEach((attr)=>{
          //    if(li.form_data.form_break){
          //      attr.forEach((att,j)=>{
          //      if(j===0){
          //        // console.log(att,j,"INNN")
          //        attr.splice(j,1)
          //      }
          //      })

          //    }

          //   })

          // }
        })
      }

    }, 2000)
  }
  // ---- collapse heading of tables-------------

  Collapse(index: any, type: any) {
    if (type == 'show') {
      this.showheader2[index] = true
      this.showheader[index] = true

    } else {
      this.showheader2[index] = false
      this.showheader[index] = false

    }
  }
  // ---- redirection to booking details page -------------
  redirect(id, action, useid, formType) {
    if (this.cookie.get('fzsd')) {
      this.cookie.delete('fzsd');
    }
    this.cookie.set('fzsd', action)
    if (this.cookie.get('useid')) {
      this.cookie.delete('useid');
    }
    this.cookie.set('useid', useid)
    if (this.cookie.get('bookid')) {
      this.cookie.delete('bookid');
    }
    this.cookie.set('bookid', id)
    
    if(formType == 'Standard form'){
      this.router.navigate(["form/manage-forms/booking-details/" + id]);
    }
    if(formType == 'Voting form') {
      this.router.navigate(["form/manage-forms/Voting-details/" + id]);
    }

  }
  pageshift(event: any) {
    this.pagefilters.page = event
    this.pagefilters.listcount = this.listcount

    console.log(this.pagefilters, "PAGEE")
    this.common.paginationhit(this.pagefilters)
  }
  rowsValidate(event: any) {
    // alert('hit')
    this.pagefilters.perpage = event.target.value
    console.log(event.target.value, "EEE")
    this.pagefilters.listcount = this.listcount
    console.log(this.pagefilters, "PAGEE")
    this.common.paginationhit(this.pagefilters)

    // alert("1")
    // if (event.target.value == 'All'){
    //   this.reson.perpage=''
    //   console.log(" ALL HIT")

    // }else{
    //   console.log(" NO ALL HIT")

    //   this.reson.perpage=event.target.value

    // }
    // this.reson.page=1
    // this.getFormSubmission(this.form_id,this.reson)


  }
}
