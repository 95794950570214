<div class="row" >
  <div class="col-sm-12">
    <!-- <div class="card-body px-4 py-2" >
      No field rules are configured for this form
    </div>
    <div class="card-footer px-4 py-2" >
      <button
        class="btn btn-primary mr-1"
        type="button"
        (click)="PageRuleDisplay(content2)"

      >
        Add Rule
      </button>
    </div> -->
    <form
      class="needs-validation"
      novalidate
      [ngClass]="{ 'was-validated': validate }"
    >
      <ng-container
        *ngFor="
          let ruleitem of PageForm;
          let ruleindex = index;
          let last = last
        "
      >
        <div class="rulesDup">
          <input
            type="text"
            class="form-control"
            placeholder="Rule Name"
            required=""
            [(ngModel)]="ruleitem.rule_name"
            [ngModelOptions]="{ standalone: true }"
          />
          <div class="fieldRuleConditions">
            <div class="ruleCont" style="margin: 33px 0 0 0">
              <div class="contDivInner">
                <span class="newRuleIfTxt">If</span>
                <div class="if-wrapper ifContainer fieldRuleIfCont">
                  <div class="form-group">
                    <select
                      class="custom-select form-control mr-3"
                      [(ngModel)]="ruleitem.if_condition.allow"
                      required=""
                      [ngModelOptions]="{ standalone: true }"
                    >
                      <option
                        value="any"
                        [selected]="ruleitem.if_condition.allow === 'any'"
                      >
                        Any
                      </option>
                      <option
                        value="all"
                        [selected]="ruleitem.if_condition.allow === 'all'"
                      >
                        All
                      </option>
                    </select>
                    <label for="">of the following conditions are met</label>
                  </div>
                  
                  <!-- {{Fields | json}} -->
                  <ng-container
                    *ngFor="
                      let conditionItem of ruleitem.if_condition.conditions;
                      let conindex = index
                    "
                  >
                  <h1>{{conditionItem.PageTitle}}</h1>
                    <div class="fieldRow my-2 py-2">
                      <div class="col-sm-12">
                        <div class="d-flex align-items-center py-1">
                          <div class="form-group mr-3 mb-0">
                            <div class="user-info-dropdown flLeft commonBdr">
                              <!-- fields without break -->
                            
                              <!-- fields with Break -->
                              <ng-container
                                *ngIf="Fields && Fields.length > 0 && Break"
                              >
                                <!-- <h1>Break</h1> -->
                                <!-- pagenumber -->
                                <!-- <select
                                  class="custom-select form-control"
                                  [(ngModel)]="conditionItem.page_number"
                                  required=""
                                  (change)="
                                    PageSelection(
                                      $event.target.value,
                                      ruleindex,
                                      conindex
                                    )
                                  "
                                  [ngModelOptions]="{ standalone: true }"
                                >


                                  <option value="">Select Pages</option>
                                  
                                  <ng-container
                                    *ngFor="
                                      let ite of conditionItem.pagenumberArr;index as j
                                   
                                    "
                                  > 
                                  <option [value]="ite.name">
                                    {{ ite.pageTitle }}
                                  </option>
                                  </ng-container>
                                </select> -->
                                <!-- page-fields -->
                                <ng-container *ngIf="ruleitem.if_condition.PageFields2.length>0">
                                  <select
                                    class="custom-select form-control"
                                    [(ngModel)]="conditionItem.field_type"
                                    required=""
                                    (change)="
                                    onUserInfoFieldSelect(
                                        $event.target.value,
                                        ruleindex,
                                        conindex
                                      )
                                    "
                                    [ngModelOptions]="{ standalone: true }"
                                  >
                                    <!-- (change)="onUserInfoFieldSelect($event.target.value,ruleindex,conindex)"  -->
  
                                    <!-- <option value ="">Select Fields</option> -->
                                    <option value="">Select fields</option>
  
                                    <ng-container
                                      *ngFor="
                                        let page of ruleitem.if_condition.PageFields2;
                                        index as i
                                      "
                                    >

                                    <!-- <ng-container
                                    *ngFor="
                                      let p of page;
                                      index as j
                                    "
                                  > -->
                                 <ng-container *ngIf="i !=0">

                                      <option [value]="page.name">
                                        {{page.label}}
                                      </option>
                                    </ng-container>
                                  <!-- </ng-container> -->
                                  </ng-container>
                                  </select>


                                </ng-container>
                              </ng-container>
                               <!-- //Address -->
                               <div
                            class="form-group mr-3 mb-0"
                            *ngIf="conditionItem.AddressType.length > 0"
                          >
                            <div class="rule-condition-list">
                              <!-- <h1>abcd</h1> -->
                              <select
                                class="custom-select form-control"
                                required=""
                              >
                                <ng-container
                                  *ngFor="
                                    let item of conditionItem.AddressType;
                                    index as i
                                  "
                                >
                                  <option
                                    [value]="item.id"
                                    [selected]="
                                      ruleEdit
                                        ? item.id == conditionItem.constraint_id
                                        : i == 0
                                    "
                                  >
                                    {{ item.label }}
                                  </option>
                                </ng-container>
                              </select>
                            </div>
                          </div>
                              <!-- <ng-template #baseInfoTemplate>
                                <select
                                  class="custom-select form-control"
                                  [(ngModel)]="conditionItem.field_type"
                                  (change)="
                                    onUserInfoFieldSelect(
                                      $event.target.value,
                                      i
                                    )
                                  "
                                  required=""
                                  [ngModelOptions]="{ standalone: true }"
                                >
                                  <ng-container
                                    *ngFor="
                                      let item of basicInfoFields;
                                      let bi = index
                                    "
                                  >
                                    <option
                                      [value]="item.slug"
                                      [selected]="
                                        conditionItem.field_type == item.slug
                                      "
                                    >
                                      {{ item.label }}
                                    </option>
                                  </ng-container>
                                </select>
                              </ng-template> -->
                            </div>
                            <div class="clearBoth"></div>
                          </div>
                          <!-- <div
                            class="form-group mr-3 mb-0"
                            *ngIf="conditionItem.field_type !== ''"
                          >
                            <div class="rule-condition-list">
                              <select
                                class="custom-select form-control"
                                (change)="
                                  onRuleConditionSelect(
                                    $event.target.value,
                                    conindex,
                                    ruleindex
                                  )
                                "
                                required=""
                              >
                                <ng-container
                                  *ngFor="
                                    let item of FieldConditionList;
                                    index as i
                                  "
                                >
                                  <option
                                    [value]="item.id"
                                    [selected]="
                                      ruleEdit
                                        ? item.id == conditionItem.constraint_id
                                        : i == 0
                                    "
                                  >
                                    {{ item.name }}
                                  </option>
                                </ng-container>
                              </select>
                            </div>
                          </div> -->
                            <!-- //likert -->
                            <div
                            class="form-group mr-3 mb-0"
                            *ngIf="
                              conditionItem.LikertQuestions.Questions.length > 0
                            "
                          >
                            <!-- <h1>11</h1> -->
                            <!-- {{conditionItem.likertquestion}} -->
                            <div
                              class="rule-condition-list"
                              *ngIf="conditionItem.groupFields.length == 0"
                            >
                              <select
                                [(ngModel)]="conditionItem.likertquestion"
                                [ngModelOptions]="{ standalone: true }"
                                class="custom-select form-control"
                                (change)="
                                  onlikertoptions(
                                    $event.target.value,
                                    ruleindex,
                                    conindex
                                  )
                                "
                                required=""
                              >
                                <option value="">Select Question</option>

                                <ng-container
                                  *ngFor="
                                    let item of conditionItem.LikertQuestions
                                      .Questions;
                                    index as i
                                  "
                                >
                                  <option [value]="item.QuestionName">
                                    {{ item.Question }}
                                  </option>
                                </ng-container>
                              </select>
                            </div>

                            <!-- for Group -->
                            <div
                              class="rule-condition-list"
                              *ngIf="conditionItem.groupFields.length > 0"
                            >
                              <!-- <h1>group</h1> -->
                              <select
                                [(ngModel)]="conditionItem.likertquestion"
                                [ngModelOptions]="{ standalone: true }"
                                class="custom-select form-control"
                                (change)="
                                  onlikertoptionsGroup(
                                    $event.target.value,
                                    ruleindex,
                                    conindex
                                  )
                                "
                                required=""
                              >
                              <!-- <h1>321</h1> -->
                                <option value="">Select Question</option>

                                <ng-container
                                  *ngFor="
                                    let item of conditionItem.LikertQuestions
                                      .Questions;
                                    index as i
                                  "
                                >
                                  <option [value]="item.QuestionName">
                                    {{ item.Question }}
                                  </option>
                                </ng-container>
                              </select>
                            </div>
                          </div>
                             <!-- break with group fields -->
                             <div
                             class="form-group mr-3 mb-0"
                             *ngIf="
                               conditionItem.groupFields &&
                               conditionItem.groupFields.length > 0 &&
                               Break
                             "
                           >
                             <!-- <h1>11</h1> -->
                             <!-- {{conditionItem.group_type}} -->
                             <div class="rule-condition-list">
                               <select
                                 [(ngModel)]="conditionItem.group_type"
                                 [ngModelOptions]="{ standalone: true }"
                                 class="custom-select form-control"
                                 (change)="
                                   onUserInfoFieldSelect3(
                                     $event.target.value,
                                     ruleindex,
                                     conindex
                                   )
                                 "
                                 required=""
                               >
                                 <ng-container
                                   *ngFor="
                                     let item of conditionItem.groupFields;
                                     index as i
                                   "
                                 >
                                   <option
                                     [value]="item.name"
                                     *ngIf="item.type != 'paragraph'"
                                   >
                                     {{ item.label }}
                                   </option>
                                 </ng-container>
                               </select>
                             </div>
                           </div>
                          <!-- //constraint -->
                          <div
                          class="form-group mr-3 mb-0"
                          *ngIf="
                            conditionItem.field_type !== '' && Condition_Show
                          "
                        >
                          <div class="rule-condition-list">
                            <select
                              class="custom-select form-control"
                              (change)="
                                Constraintdata($event, ruleindex, conindex)
                              "
                              required=""
                            >
                              <ng-container
                                *ngFor="
                                  let item of conditionItem.FieldConstraint;
                                  index as i
                                "
                              >
                                <option
                                  [value]="item.id"
                                  [selected]="
                                    ruleEdit
                                      ? item.id == conditionItem.constraint_id
                                      : i == 0
                                  "
                                >
                                  {{ item.name }}
                                </option>
                              </ng-container>
                            </select>
                          </div>
                        </div>
                          <!-- //likert question values -->
                          <div
                            class="form-group mr-3 mb-0"
                            *ngIf="
                              conditionItem.LikertQuestions.values.length > 0
                            "
                          >
                            <!-- <h1>21</h1> -->
                            <div class="rule-condition-list">
                              <!-- <h11>innnnnn</h11> -->
                              <select [(ngModel)]="conditionItem.value" [ngModelOptions]="{ standalone: true }" class="custom-select form-control"  required="">
                                <!-- (change)="onUserInfoFieldSelect3($event.target.value,ruleindex,conindex)" -->
                                <option value="">Select Value</option>
                                <ng-container *ngFor="let item of conditionItem.LikertQuestions.values;index as i">
                                  <option [value]="item.value">{{ item.label }}</option>
                                </ng-container>
                              </select>
                            </div>
                          </div>
                        <!-- //condition value for different type -->
                        <div
                        class="form-group mr-2 mb-0"
                        *ngIf="
                          conditionItem.showValueBox &&
                          conditionItem.multiplecheckbox.length == 0 &&
                          conditionItem.rating.accept == '' &&
                          conditionItem.LikertQuestions.values.length ==
                            0 &&
                          conditionItem.constraint_id != '3' &&
                          conditionItem.constraint_id != '4'
                        "
                      >
                        <!-- <h1>bb</h1> -->
                        <div
                          class="condition-value-input"
                          *ngIf="
                            !conditionItem.FileEmpt &&
                            !conditionItem.valforboxes.hasOwnProperty(
                              'values'
                            )
                          "
                        >
                          <!-- <div class="condition-value-input" *ngIf="conditionItem.valforboxes.length ==0"> -->
                          <!-- <h1>41</h1> -->

                          <input
                            [type]="conditionItem.conditiontype"
                            class="form-control"
                            placeholder="Conditions Value"
                            [(ngModel)]="conditionItem.value"
                            [ngModelOptions]="{ standalone: true }"
                            required=""
                          />
                        </div>
                        <!-- {{conditionItem.valforboxes.hasOwnProperty('values')}} && {{ conditionItem.constraint_id}} -->
                        <div
                          class="rule-condition-list"
                          *ngIf="
                            conditionItem.valforboxes.hasOwnProperty(
                              'values'
                            ) &&
                            conditionItem.constraint_id != '5' &&
                            conditionItem.constraint_id != '6' &&
                            conditionItem.constraint_id != '7' &&
                            conditionItem.constraint_id != '8' &&
                            conditionItem.constraint_id != '3' &&
                            conditionItem.constraint_id != '4'
                          "
                        >
                          <!-- <div class="rule-condition-list" *ngIf="conditionItem.valforboxes.length > 0 && conditionItem.constraint_id !='5' && conditionItem.constraint_id !='6' && conditionItem.constraint_id !='7' && conditionItem.constraint_id !='8' && conditionItem.constraint_id !='3' && conditionItem.constraint_id !='4'"> -->
                          <!-- <h1>11</h1> -->
                          <ng-select
                            [items]="conditionItem.valforboxes.values"
                            bindLabel="label"
                            [bindValue]="value"
                            class="js-example-basic-multiple ng-select-control"
                            [(ngModel)]="conditionItem.value"
                            placeholder="Select Options"
                            [multiple]="true"
                            [ngModelOptions]="{ standalone: true }"
                            required=""
                          ></ng-select>
                          <!-- {{conditionItem.value | json}} -->
                        </div>
                        <ng-container
                          *ngIf="
                            conditionItem.valforboxes.hasOwnProperty(
                              'values'
                            ) &&
                            (conditionItem.constraint_id == '5' ||
                              conditionItem.constraint_id == '6' ||
                              conditionItem.constraint_id == '7' ||
                              conditionItem.constraint_id == '8') &&
                            conditionItem.constraint_id != '3' &&
                            conditionItem.constraint_id != '4'
                          "
                        >
                          <!-- {{conditionItem.multiplecheckbox |json}} -->
                          <!-- <div class="condition-value-input" *ngIf="!conditionItem.valforboxes.hasOwnProperty('values')"> -->
                          <div class="condition-value-input">
                            <!-- <h1>21</h1> -->

                            <input
                              type="text"
                              class="form-control"
                              placeholder="Conditions Value"
                              [(ngModel)]="conditionItem.value"
                              [ngModelOptions]="{ standalone: true }"
                              required=""
                            />
                          </div>
                        </ng-container>
                      </div>
                          <!-- //condition value  -->
                          <!-- <div
                            class="form-group mr-2 mb-0"
                            *ngIf="conditionItem.showValueBox"
                          >
                            <div class="condition-value-input">
                              <input
                                type="text"
                                class="form-control"
                                placeholder="Conditions Value"
                                [(ngModel)]="conditionItem.value"
                                required=""
                                [ngModelOptions]="{ standalone: true }"
                              />
                            </div>
                          </div> -->
                          <div class="mr-3 mb-0">
                            <button
                              type="button"
                              class="customAddminus clone"
                              (click)="addNewRow(ruleindex,conindex)"
                            >
                              <i class="icon-plus"></i>
                            </button>
                            <button
                              type="button"
                              class="customAddminus delete"
                              (click)="removeRow(ruleindex, conindex)"
                            >
                              <i class="icon-minus"></i>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </ng-container>
                </div>
                <span class="newRuleIfTxt newRuleThenTxt">Then</span>
              </div>
              <div class="actCondition">
                <span>Perform the following actions</span>
              </div>
              <div class="then-wrapper ruleActionWrapper">
                <div class="ruleAction">
                  <div class="d-flex align-items-center">
                    <div class="form-group mr-3">
                      <label for="">Skip To</label>
                      <div class="rule-action-list">
                        <select
                          class="custom-select form-control"
                          [(ngModel)]="ruleitem.then_apply.type"
                          required=""
                          [ngModelOptions]="{ standalone: true }"
                        >
                        <!-- (change)="Gettype($event)" -->

                          <option value="">Select Pages</option>
                          <ng-container *ngFor="let item of ruleitem.then_apply.value;index as j">
                            <option
                              [value]="item.name"
                            >
                            <!-- [selected]="item === ruleitem.then_apply.type" -->

                              {{ item.pageTitle == null || item.pageTitle == '' ? 'Page3' : item.pageTitle }}
                            </option>
                          </ng-container>
                        </select>
                      </div>
                    </div>
                    
                   
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="float-right" *ngIf="!ruleEdit">
            <button
              type="button"
              class="btn btn-danger mr-2"
              (click)="removeRule(ruleindex, ruleitem.id)"
            >
              Delete Rule
            </button>
            <!-- <button
              *ngIf="last"
              type="button"
              class="btn btn-primary mr-2"
              (click)="addNewRule()"
            >
              Add Rule
            </button> -->
          </div>
        </div>
      </ng-container>
      <!-- <div class="d-block mt-4" *ngIf="!hidesave"> -->
      <div class="d-block mt-4">
        <!-- {{ruleEdit ? 'Update Rule' : 'Save Rules'}} -->
        <button type="button" class="btn btn-primary mr-2" (click)="savePageRule()">Save Rules</button>
      </div>
    </form>
  </div>
</div>



<ng-template #content2 let-modal let-d="dismiss">
  <div class="modal-header">
    <h5 class="modal-title" id="modal-basic-title">Bulk Email</h5>
    <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">&times;</button>
  </div>
  <div class="modal-body">
 <div *ngFor="let item of pagenumberArr">

 </div>
  </div>
  </ng-template>



