import { Injectable, OnInit, NgZone } from '@angular/core';
import { Router } from '@angular/router';
// import { auth } from 'firebase/app';
// import { AngularFireAuth } from '@angular/fire/auth';
// import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';
import { ToastrService } from 'ngx-toastr';
import { CookieService } from 'ngx-cookie-service';
import { ApiClientService } from '../api-client.service';
import { CommonService } from '../common.service';
// import * as firebase from 'firebase/app';

export interface User {
  uid: string;
  email: string;
  displayName: string;
  photoURL: string;
  emailVerified: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class AuthService implements OnInit {

  public userData: any;
  // public user: firebase.User;
  public showLoader: boolean = false;
  filter:any

  constructor(
    // public afs: AngularFirestore,
    // public afAuth: AngularFireAuth,
    public router: Router,
    public ngZone: NgZone,
    public toster: ToastrService,
    private apiClientService: ApiClientService,
    private commonService: CommonService,
    private cookieService: CookieService) {

    // this.afAuth.authState.subscribe(user => {
    //   if (user) {
    //     this.userData = user;
    //     cookieService.set('user', JSON.stringify(this.userData));
    //     localStorage.setItem('user', JSON.stringify(this.userData));
    //     JSON.parse(localStorage.getItem('user'));
    //   } else {
    //     localStorage.setItem('user', null);
    //     JSON.parse(localStorage.getItem('user'));
    //   }
    // });
  }

  ngOnInit(): void { }

  // sign in function
  SignIn(data:any){
    return this.apiClientService.post('hbku-aprs',data)
  }
  getEmailValidation(data:any){
    return this.apiClientService.post('email_validation',data)

  }
  // SignIn(email, password, rememberMe) {
  //   let data = {
  //     "email": email,
  //     "password": password,
  //     "remember_me": rememberMe
  //   }
  //   this.apiClientService.post('hbku-aprs', data).subscribe(result => {
  //    if(result['data']['user_role'] !='User'){
  //      if(result['data']['first_login'] =='0'){
  //     if(result && result['success']) {

  //       // let d = new Date();
  //       // d.setHours( d.getHours() + 12 );
  //       const dateNow = new Date();
  //       dateNow.setMinutes(dateNow.getMinutes() + result['data']['remember_time']);
  //       
  //       if(!this.cookieService.get('token_type') && !this.cookieService.get('access_token') && !this.cookieService.get('user_role') && !this.cookieService.get('user_name') && !this.cookieService.get('formval')) {
  //         this.cookieService.set('token_type', result['data']['token_type'],dateNow );
  //         this.cookieService.set('access_token', result['data']['access_token'], dateNow);
  //         this.cookieService.set('user_role', result['data']['user_role'],dateNow);
  //         this.cookieService.set('user_name', result['data']['full_name'], dateNow);
  //         this.cookieService.set('formval', result['data']['forms_exists'], dateNow);
  //         this.cookieService.set('user_role_id',result['data']['user_role_id'])
  //       }
  //       this.getPermissionAccess(result['data']['user_role_id'])
  //  // alert(result['data']['user_role_id'])
  //       // localStorage.setItem('user_permis', JSON.stringify(result['data']['user_permissions']));
  //       this.commonService.setUserPermissions(result['data']['user_permissions']);
  //       // this.router.navigateByUrl('/dashboard/hbku');
  //       // window.location.href = '/dashboard/hbku';
  //       // this.ngZone.run(() => {
  //       //   this.router.navigate(['/dashboard/hbku']);
  //       // });
  //     } 
  //    }else{
  //      this.toster.error('Login Permissions not granted')
  //    }
  //   }else{
    
  //   }
  //   },error =>{
  //     this.toster.error(error.error['message']);
  //   })
   
  // }

  confirmResetPassword(token) {
    this.apiClientService.get('password/reset/'+token).subscribe(result => {
      // this.router.navigate([''])
    },error =>{
      this.toster.error(error.error['message']);
      this.router.navigate([''])  
      // this.toster.error('The reset password link has been expired');
    })
  }

  createNewPassword(data) {
    this.apiClientService.post('password/create', data).subscribe(result => {
      this.router.navigate([''])
      this.toster.success('Your password has been changed successfully')

    },error=> {
      this.toster.error(error.error['message']);
      // this.router.navigate([''])
    }
    )
  }
  // firstAdminPass(data) {
  //   this.apiClientService.post('change-password', data).subscribe(result => {
  //     // this.router.navigate([''])
  //   },error=> {
  //     this.toster.error(error.error['message']);
  //     this.router.navigate([''])
  //   }
  //   )
  // }
  firstAdminPass(data:any){
    return this.apiClientService.post('change-password',data)
  }
  ForgotPass(data) {  
 
    return this.apiClientService.post('password/forgot/1',data)

  }

  getPermissionAccess(data){
    
    
    this.apiClientService.get('roles/'+data, data).subscribe(result => {
      if(result && result['success']) {
        
     this.commonService.setpermarr(result['data']['rolePermissions'])
     localStorage.setItem('user_per',JSON.stringify(result['data']['rolePermissions']))
     result['data']['rolePermissions'].map((x,i)=>{
       if(x.name =='see-form-list'){
      this.filter=  result['data']['rolePermissions'].filter((y,j)=>{
       return y.name !='access-own-form-list-only'
        })
       }
     })
     
        this.router.navigate(['/dashboard/hbku']);
        
        // this.router.navigateByUrl('/dashboard/hbku');
        // window.location.href = '/dashboard/hbku';
        // this.ngZone.run(() => {
        //   this.router.navigate(['/dashboard/hbku']);
        // });
      } 
    })

  }
  // main verification function
  // SendVerificationMail() {
  //   return this.afAuth.auth.currentUser.sendEmailVerification()
  //     .then(() => {
  //       this.router.navigate(['/dashboard/default']);
  //     })
  // }

  // // Sign in with Facebook
  // signInFacebok() {
  //   return this.AuthLogin(new auth.FacebookAuthProvider());
  // }

  // // Sign in with Twitter
  // signInTwitter() {
  //   return this.AuthLogin(new auth.TwitterAuthProvider());
  // }

  // // Sign in with Google
  // GoogleAuth() {
  //   return this.AuthLogin(new auth.GoogleAuthProvider());
  // }

  ForgotPassword(passwordResetEmail) {
    // return this.afAuth.auth.sendPasswordResetEmail(passwordResetEmail)
    //   .then(() => {
    //     window.alert('Password reset email sent, check your inbox.');
    //   }).catch((error) => {
    //     window.alert(error);
    //   });
  }

  // Authentication for Login
  AuthLogin(provider) {
    // return this.afAuth.auth.signInWithPopup(provider)
    //   .then((result) => {
    //     this.ngZone.run(() => {
    //       this.router.navigate(['/dashboard']);
    //     });
    //     this.SetUserData(result.user);
    //   }).catch((error) => {
    //     window.alert(error);
    //   });
  }

  // Set user
  // SetUserData(user) {
  //   const userRef: AngularFirestoreDocument<any> = this.afs.doc(`users/${user.uid}`);
  //   const userData: User = {
  //     email: user.email,
  //     displayName: user.displayName,
  //     uid: user.uid,
  //     photoURL: user.photoURL || 'assets/dashboeard/boy-2.png',
  //     emailVerified: user.emailVerified
  //   };
  //   userRef.delete().then(function () {})
  //         .catch(function (error) {});
  //   return userRef.set(userData, {
  //     merge: true
  //   });
  // }

  // Sign out
  // logout(){
  //   
  //   this.apicommon.logOut()
  //   .then((data) =>{
  //     this.result = data
  //         
  //         if (this.result.success && this.result.success == true) {
  //             this.deleteAllCookies()
  //             this.common.changeAccessToken('false')

  //             this.router.navigate([''])
  //         } else {
  //             this.deleteAllCookies()
  //         }
  //   }).catch((error) =>{
  //     
  //     )
  //     this.deleteAllCookies()
  //     this.router.navigate([''])
  //   })
  // }
  SignOut() {
    this.apiClientService.get('logout').subscribe(result =>{
      // this.cookieService.delete("cookie name","/","domain name",true,"None");

      // this.cookie.deleteAll();

      this.cookieService.deleteAll();
      localStorage.clear();
      // this.cookieService.deleteAll('/', 'xyz.net');     
      this.router.navigate(['/qdf-hbku']);
    // window.location.reload();

    },error =>
    {
      // alert()
      this.cookieService.deleteAll();
      localStorage.clear();
    // window.location.reload();

      this.router.navigate(['/qdf-hbku']);


    })
    this.cookieService.deleteAll('/');
    localStorage.clear();
    this.router.navigate(['/qdf-hbku']);
    // return this.afAuth.auth.signOut().then(() => {
    //   this.showLoader = false;
    //   localStorage.clear();
    //   this.cookieService.deleteAll('user', '/qdf-hbku');
    //   this.router.navigate(['/qdf-hbku']);
    // });
  }
clear(){
  this.cookieService.deleteAll();
  localStorage.clear();
  this.router.navigate(['/qdf-hbku']);
}
  get isLoggedIn(): boolean {
    const token = this.cookieService.get('access_token');
    const role = this.cookieService.get('user_role');
    return (token ) ? true : false;
    // return (token && role === 'Super-Admin') ? true : false;

  }

}