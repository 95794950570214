import { Component, OnInit, HostListener } from "@angular/core";
import { FormBuilder, FormGroup, NgForm, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { CookieService } from "ngx-cookie-service";
import { ToastrService } from "ngx-toastr";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { CommonService } from "src/app/shared/services/common.service";
import { FormsService } from "../forms.service";
import { MustMatch } from "../../../shared/validators/passwordMatch";
import { DatePipe } from "@angular/common";
import * as moment from "moment";
import { environment } from "src/environments/environment";
import { NavService } from "src/app/shared/services/nav.service";
import * as ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { AnimationStyleMetadata } from "@angular/animations";

const baseUrl = environment.apiEndpoint;
declare var require;

const Swal = require("sweetalert2");

@Component({
  selector: "app-booking-details",
  templateUrl: "./booking-details.component.html",
  styleUrls: ["./booking-details.component.scss"],
})
export class BookingDetailsComponent implements OnInit {
  public ClassicEditor = ClassicEditor;
  LoaderClass: boolean = false;
  formInfo: any = {};
  id: any;
  timeVal: any;
  NewFormName: any = "";
  backtoPageURL: any;
  displayoption: any;
  payment_status: any = "";
  destroy$: Subject<boolean> = new Subject<boolean>();
  model: any = {
    form_name: "Form name...",
    form_business_name: "Test",
    description: "Form Description...",
    form_type: "standard_form",
    theme: {
      bgColor: "ffffff",
      textColor: "555555",
      bannerImage: "",
    },
    attributes: [],
  };
  selectForm: any;
  ShowForm: boolean = false;
  showval: boolean = false;
  invoice_numbers: any = "";
  refund_payments: any = [];
  refundamt: any = "";
  bookingid: any;
  formName: any;
  formtype: any;
  fname: any;
  lname: any;
  fname2: any;
  lname2: any;
  user_dob2: any;
  model2: any = [];
  model3: any = [];
  totalamount: any;
  totalrefundamount: any;
  latestamount: any;
  certificate: string = "";
  files: any[] = [];
  transactions: any = [];
  fileval: any;
  formType: any;
  user_dob: any;
  imgarr: any;
  swal_title: any = "";
  html: any = "";
  gender: any = "";
  discountamount: any;
  bookingdate: any;
  nationality_country: any;
  phone_number: any;
  basicinfo: any;
  typeofcustomer: any;
  FormName: any;
  isPageBreak = false;
  Validations = [
    {
      name: "",
      message: "",
    },
  ];
  additional_payments: any[] = [];
  success = false;
  error = false;
  message: any;
  minDate: any;
  datetimeLocalEndTime: any;
  rule_type: any;
  maxDate: any;
  addref_err: any = "";

  allCountries: any;
  pipe = new DatePipe("en-US");
  pageval: any;
  listcount: any;
  result: any;
  encyid: any;
  user_id: any;
  EmailAutoArr: any;
  displayForm: any;
  firstFormGroup: FormGroup;
  secondFormGroup: FormGroup;
  thirdFormGroup: FormGroup;
  fourthFormGroup: FormGroup;
  AddAmountGroup: FormGroup;
  AddRefundAmountGroup: FormGroup;

  // maxDate: Date;
  Image_error_mess: any;
  pageBreakSettings: any;
  dropdownSettings: any = {};
  dropdownSettings2: any = {};
  discount_rule: any = {};
  proof_values: any = {};
  filesdiscount: any[] = [];
  pdf_error_dis: any = "";
  file_size: any;
  total_discount: any;
  final_max_percentage: any;
  form_price: any;
  final_price: any;
  proof_required: any;
  discount_proof_title: any;
  discount_proof_description: any;
  discount_proof_files_allowed: any = [];
  discount_proof_number_of_files: any = {};
  discount_proof_file_size: any = {};
  booking_id: any;
  removedarr: any = [];
  upimgdis: boolean = false;
  senddelete: boolean = false;
  AdditionalAmount: any;
  booking_form_price: any = "";
  cr_show: boolean = false;
  opt = "option-2";
  rule_name: any;
  mainTransaction: any = [];
  hidetabs: boolean = false;
  refundRequestAmount: any;
  constructor(
    private route: ActivatedRoute,
    private toster: ToastrService,
    private formService: FormsService,
    private cookie: CookieService,
    private router: Router,
    private common: CommonService,
    private _formBuilder: FormBuilder,
    private modalService: NgbModal,
    private navservice: NavService
  ) {
    this.id = this.route.snapshot.params.id; 
    if (this.route.snapshot.routeConfig.path.includes("Voting-details")) {
      this.hidetabs = true;
      // alert(this.hidetabs)
    }
    this.maxDate = new Date();
  }
  editcheck: any;
  pagecheck: any;
  img1: any = "";
  img2: any = "";
  externalLinkModal: any;
  user_permissions: any[] = [];
  add_show: boolean = false;
  edit_show: boolean = false;
  FormListing: any = [];
  NewFormID: any = "";
  val_edit: boolean = false;
  uploadError = "";
  // last_name_edit:boolean=false
  // dob_edit:boolean=false
  maxDob!: Date;
  maxDob2: any;
  @HostListener("window:popstate", ["$event"])
  onPopState(event) {
    // alert('back')
    this.FilterStore();
  }
  ngOnInit(): void {
    const today = new Date();
    this.maxDob = new Date(
      today.getFullYear() - 18,
      today.getMonth(),
      today.getDate()
    );
    this.getBookingSettingPageData();
    this.maxDob2 = moment(this.maxDob).format("YYYY-MM-DD");
    this.getrole();
    this.getAdditionalForm();
    this.getFormList();
    this.dropdownSettings = {
      singleSelection: false,
      idField: "value",
      textField: "value",
      selectAllText: "Select All",
      unSelectAllText: "UnSelect All",
      itemsShowLimit: 10,
      allowSearchFilter: true,
    };
    this.dropdownSettings2 = {
      singleSelection: true,
      idField: "value",
      textField: "value",
      selectAllText: "Select All",
      unSelectAllText: "UnSelect All",
      itemsShowLimit: 10,
      allowSearchFilter: true,
    };
    this.common.pagenum.subscribe((res: any) => {
      // console.log("res 1", res);
      this.pagecheck = res;
      if (res.type == "AutoGenerated") {
        this.getemails(res);
      }
    });
    this.common.pageper.subscribe((res: any) => {
      this.pagecheck = res;
      // console.log("res 2", res);
      if (res.perpage == "All" && res.type == "AutoGenerated") {
        res.perpage = "";
        //  res.page=''
        this.getemails(res);
      } else if (
        res.perpage != undefined &&
        res.page != undefined &&
        res.type == "AutoGenerated"
      ) {
        this.getemails(res);
      }
    });
    this.firstFormGroup = this._formBuilder.group({
      firstName: ["", Validators.required],
      lastName: ["", Validators.required],
    });
    this.secondFormGroup = this._formBuilder.group({
      email: ["", [Validators.required, Validators.email]],
      password: ["", Validators.required],
    });
    this.thirdFormGroup = this._formBuilder.group({
      birthdate: [null, Validators.required],
      hasPassport: ["", Validators.required],
    });
    this.fourthFormGroup = this._formBuilder.group({
      state: ["", Validators.required],
      city: ["", Validators.required],
    });
    this.AddAmountGroup = this._formBuilder.group({
      Addamount: ["", Validators.required],
      adddes: ["", Validators.required],
      Expirytime: [this.futureDate(), Validators.required], 
    });
    this.AddRefundAmountGroup = this._formBuilder.group({
      Addrefamount: ["", Validators.required],
      addrefdes: ["", Validators.required],
      addrefattachment: [],
    });
    this.editcheck = localStorage.getItem("edit_val");

    // this.formtype = this.formtype.charAt(0).toUpperCase() + this.formtype.slice(1).replaceAll('_', ' ')

    this.backtoPageURL =
      "/form/manage-forms/form-group/" + localStorage.getItem("MNG");

    if (this.id != "" && this.id != null) {
      this.getformdata();
    }
  }

  fileBrowseHandler(files: any) {
    this.uploadError = "";
    let file_type = files[0].type;

    if (file_type === "application/pdf") {
      this.uploadError = "";
      this.file_size = files[0].size / 1024 / 1024;

      if (parseFloat(this.file_size) > 10) {
        files = [];
        this.uploadError = "File size should be only 10 mb.";
      } else {
        this.prepareFilesList(files);
      }
    } else {
      this.uploadError = "Allowed PDF Format";
    }
  }

  prepareFilesList(files: Array<any>) {
    if (files.length > 1) {
      this.uploadError = "One file allowed to upload";
    } else {
      for (const item of files) {
        item.progress = 0;
        this.files.push(item);
      }
      this.uploadFilesSimulator(0);
    }
  }

  getFormList() {
    this.navservice.GetForms(this.cookie.get("fzsd")).subscribe((result) => {
      console.log(result, "FFFFFFFFFFFFFFFFFFFFFFFFFFFFFF");
      this.FormListing = result;
    });
  }
  getrole() {
    this.navservice.FetchPermissions().subscribe((result) => {
      this.user_permissions = result["data"]["user_permissions"];
      this.user_permissions.forEach((permission) => {
        if (permission.name == "create-refund") {
          this.cr_show = true;
        }
        if (permission.name == "create-additional-payment") {
          this.add_show = true;
        }
        if (permission.name == "edit-booking") {
          this.edit_show = true;
        }
      });
    });
  }
  setID(e: any) {
    console.log(e, "EEEE");
    if (e != undefined && e != null) {
      this.NewFormID = e.form_id;
      this.NewFormName = e.form_business_name;
    } else {
      this.NewFormID = "";
    }
    // this.selectForm=null
  }
  UpdateBooking() {
    let data = {
      booking_id: this.encyid,
      new_form_id: this.NewFormID,
      last_form_id: this.cookie.get("fzsd"),
      // updated_by_id:
    };
    if (this.NewFormID != "" && this.NewFormID != null) {
      Swal.fire({
        title: "Are you sure?",
        text: "you want to change  the form",
        icon: "info",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
        cancelButtonText: "No",
      }).then((result) => {
        // this.formservice.duplicateForm(id)
        console.log(result.value, "rrrr");

        // alert('12')
        if (result.value) {
          this.navservice.UpdateformsBookindID(data).subscribe(
            (result) => {
              this.result = result;
              console.log(result, "resss");
              this.formName = this.NewFormName;
              this.ShowForm = false;
              setTimeout(() => {
                this.getformdata();
              }, 1000);
              if (this.cookie.get("fzsd")) {
                this.cookie.delete("fzsd");
              }
              this.cookie.set("fzsd", this.NewFormID);
              this.selectForm = null;
              this.getFormList();
              Swal.fire({
                position: "center",
                type: "success",
                icon: "success",
                title: this.result.message,
                // html: emptyBuilder
                //   ? '<br><p role="alert" class="txt-secondary">You need to build the form !</p>'
                //   : "",
                showConfirmButton: false,
                timer: 2000,
                padding: "2em",
                showClass: {
                  popup: "animate__animated animate__fadeInDown",
                },
                hideClass: {
                  popup: "animate__animated animate__fadeOutUp",
                },
              });
            },
            (error) => {
              Swal.fire({
                position: "center",
                type: "error",
                icon: "error",
                title: error.error.message,
                // html: emptyBuilder
                //   ? '<br><p role="alert" class="txt-secondary">You need to build the form !</p>'
                //   : "",
                showConfirmButton: false,
                timer: 2000,
                padding: "2em",
                showClass: {
                  popup: "animate__animated animate__fadeInDown",
                },
                hideClass: {
                  popup: "animate__animated animate__fadeOutUp",
                },
              });
            }
          );
        }
      });
    }
  }
  ShowChangeForm(e) {
    console.log(e, "EEEEEEEEE");
    if (e.currentTarget.checked) {
      this.ShowForm = true;
    } else {
      this.ShowForm = false;
    }
  }
  getAdditionalForm() {
    this.externalLinkModal = this._formBuilder.group({
      amount: ["", Validators.required],
      secdescription2title: ["", Validators.required],
    });
  }
  download() {
    // console.log(this.proof_values,'pppppppp')
    let split = this.proof_values.split("/");
    let name = split[split.length - 1];
    // console.log(name,'name');

    let newurl = this.proof_values;

    let link = document.createElement("a");
    link.download = name;
    link.href = newurl;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
  download2(img: any) {
    // console.log(this.proof_values,'pppppppp')
    let split = img.split("/");
    let name = split[split.length - 1];
    // console.log(name,'name');

    let newurl = img;

    let link = document.createElement("a");
    link.download = name;
    link.href = newurl;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  removeimg1dep(index: any, parentind: any) {
    // console.log(this.displayForm[parentind].value);

    let first = this.displayForm[parentind].value.split(",")[index];
    this.displayForm[parentind].value = this.displayForm[
      parentind
    ].value.replace(`,${first}`, "");
    // console.log(this.displayForm[parentind],'dfff-====================', index);
    // this.imgarr.splice(index, 1);
  }
  removeimgdiscount(index: any, remove: any) {
    // console.log(remove,'ewew');
    this.senddelete = true;
    // console.log('removed',index,this.proof_values);
    this.removedarr.push(remove);
    // console.log(this.removedarr,'rrrrrrrrrrrrrrrrrrrrrrrrr');

    let first = this.proof_values.split(",")[index];
    if (index != 0) {
      this.proof_values = this.proof_values.replace(`,${first}`, "");
    } else {
      this.proof_values = this.proof_values.replace(`${first}`, "");
    }
    //   let first = this.displayForm[parentind].value.split(',')[index]
    //  this.displayForm[parentind].value = this.displayForm[parentind].value.replace(`,${first}`, "")
    // // console.log(this.displayForm[parentind],'dfff-====================', index);
    //   // this.imgarr.splice(index, 1);
  }
  removeimg1depbreak(index: any, parentind: any, superparent: any) {
    let first =
      this.displayForm[superparent][parentind].value.split(",")[index];
    // console.log(this.displayForm[superparent][parentind].value.split(',')[index],'ghghghghg');

    if (index == "0") {
      this.displayForm[superparent][parentind].value = this.displayForm[
        superparent
      ][parentind].value.replace(`${first}`, "");
    } else {
      this.displayForm[superparent][parentind].value = this.displayForm[
        superparent
      ][parentind].value.replace(`,${first}`, "");
    }
    // console.log(this.displayForm[superparent] [parentind],'dfff-====================', index);

    // console.log(total,'sssssss',p[i]);

    // let first = this.displayForm[parentind].value.split(',')[index]
    //  this.displayForm[parentind].value = this.displayForm[parentind].value.replace(`,${first}`, "")
    // console.log(this.displayForm[parentind],'dfff-====================', index);
    // this.imgarr.splice(index, 1);
  }

  async checkOnStepValidation(pageItem: any) {
    let errors = [];

    if (pageItem[0].beforeNextPage) {
      await pageItem.map((obj: any) => {
        if (obj.required && obj.value == "") {
          errors.push(obj.label);
        }
      });

      if (errors.length > 0) {
        this.message = "Please fill all required fields.";
        this.success = false;
        this.error = true;
        //
        // this.stepper.selected.completed = false;
        // this.stepper.selected.editable = true;
      }
      // else {
      //   this.stepper.selected.completed = true;
      //   this.stepper.next();
      // }
    } else {
      // this.stepper.selected.completed = true;
      // this.stepper.next();
    }
  }
  getemails(res) {
    let data = {
      // user_id: this.cookie.get("useid"),
      // booking_id: this.cookie.get("bookid"),
      user_id: this.user_id,
      booking_id: this.encyid,
      search: res.search,
      rows: res.perpage,
      col: res.columm,
      page: res.page,
    };
    this.common
      .getEmailsList(data)
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (result) => {
          this.result = result;
          this.EmailAutoArr = this.result.data;
          if (res.perpage != "" && res.page != "") {
            this.pageval = this.result.meta.pagination.current_page;
            this.listcount = this.result.meta.pagination.total;
          } else {
            this.pageval = 1;
            this.listcount = 0;
          }
        },
        (error) => {
          this.toster.error(error.error["message"]);
          //   if(error.error['message'] =='Unauthenticated.'){
          //     this.authService.clear();

          //   }
        }
      );
  }

  fetchTabs(e: any) {
    console.log(e.nextId, "eeeeeee");
    if (e.nextId == "tab-selectbyid4") {
      this.getemails(this.pagecheck);
      //
      //
    }
    // else if (e.nextId == "tab-selectbyid6") {
    // this.MiddleWareLogs()
    // }
  }
  MiddleWareLogs() {
    let data = {
      booking_id: this.encyid,
    };
    this.common
      .MiddleWareLogsFetch(data)
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (result) => {
          this.result = result;
        },
        (error) => {
          this.toster.error(error.error["message"]);
          //   if(error.error['message'] =='Unauthenticated.'){
          //     this.authService.clear();

          //   }
        }
      );
  }
  EditBasic(type: any) {
    console.log(type, "TYPEEEE");
    // if(type =='firstname'){
    this.val_edit = true;
  }
  CloseEdit(type) {
    this.val_edit = false;
    this.fname2 = this.fname;
    this.lname2 = this.lname;
    this.user_dob2 = this.user_dob;
  }
  BookDataChange(type) {
    let data = {};
    if (this.fname2 == "" || this.lname2 == "" || this.user_dob2 == "") {
      return;
    }
    data["booking_id"] = this.encyid;

    data["first_name"] = this.fname2;

    data["last_name"] = this.lname2;

    data["dob"] = this.user_dob2;

    console.log(data, "DATATATAT");
    this.common
      .BookFormDataChange(data)
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (result) => {
          this.val_edit = false;

          this.fname = this.fname2;
          this.lname = this.lname2;
          this.user_dob = this.user_dob2;
          Swal.fire({
            title: "",
            text: result["message"],
            icon: "success",
            timer: 2000,
            showConfirmButton: false,
            showClass: {
              popup: "animate__animated animate__fadeInDown",
            },
            hideClass: {
              popup: "animate__animated animate__fadeOutUp",
            },
          });
        },
        (error) => {
          Swal.fire({
            title: "",
            text: error.error.message,
            icon: "error",
            timer: 2000,
            showConfirmButton: false,
            showClass: {
              popup: "animate__animated animate__fadeInDown",
            },
            hideClass: {
              popup: "animate__animated animate__fadeOutUp",
            },
          });
        }
      );
  }
  openPopup(content) {
    this.modalService.open(content, { windowClass: "externalLinkModal" });
  }
  fileBrowseHandlerdiscount(files: any) {
    let file_type = files[0].type;
    let fileslpit = file_type.split("/").pop();

    let d = JSON.parse(this.discount_proof_files_allowed);
    let val = d.filter((x: any, i: any) => {
      return x == fileslpit;
    });

    if (val.length > 0) {
      this.pdf_error_dis = "";
      this.file_size = files[0].size / 1024 / 1024;
      console.log(
        parseFloat(this.file_size),
        "asdasd",
        this.discount_proof_file_size.min
      );

      if (parseFloat(this.file_size) > this.discount_proof_file_size.max) {
        files = [];
        this.pdf_error_dis =
          "File size should be only " +
          this.discount_proof_file_size.max +
          " mb or less";
      } else if (
        parseFloat(this.file_size) <= this.discount_proof_file_size.min
      ) {
        this.pdf_error_dis =
          "File size should be at least " +
          this.discount_proof_file_size.min +
          " mb or more";
      } else {
        this.prepareFilesListdiscount(files);
      }
    } else {
      this.pdf_error_dis = "Allowed Formats " + d.toString();
    }
  }
  prepareFilesListdiscount(files: Array<any>) {
    // console.log( this.discount_proof_number_of_files.max,'maxxxxxxx',this.filesdiscount.length);

    if (
      this.filesdiscount.length + 1 >
      this.discount_proof_number_of_files.max
    ) {
      this.pdf_error_dis =
        "Only " +
        this.discount_proof_number_of_files.max +
        " files are allowed to upload";
    }
    // else if(this.files.length 1 ){
    //   this.pdf_error = 'Please Upload Front & Back of your document';

    // }
    else {
      for (const item of files) {
        item.progress = 0;

        this.filesdiscount.push(item);
        this.upimgdis = true;
      }
    }

    this.uploadFilesSimulatordiscount(0);
  }
  uploadFilesSimulatordiscount(index: number) {
    setTimeout(() => {
      if (index === this.filesdiscount.length) {
        return;
      } else {
        const progressInterval = setInterval(() => {
          if (this.filesdiscount[index].progress === 100) {
            clearInterval(progressInterval);
            this.uploadFilesSimulatordiscount(index + 1);
          } else {
            this.filesdiscount[index].progress += 20;
          }
          // console.log(this.filesdiscount[index].progress);
        }, 20);
      }
    }, 400);
  }
  uploadFilesSimulator(index: number) {
    // setTimeout(() => {
    if (index === this.files.length) {
      return;
    } else {
      const progressInterval = setInterval(() => {
        if (this.files[index].progress === 100) {
          clearInterval(progressInterval);
          this.uploadFilesSimulator(index + 1);
        } else {
          this.files[index].progress += 20;
        }
        // console.log(this.files[index].progress,'pppppp');
      }, 50);
    }
    // }, 1000);
  }

  ToDiscount() {
    // console.log(this.filesdiscount.length,'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaa');
    // console.log(this.proof_values.split(',').length,'llllllllllllllllllllllllllllllllllll');

    if (
      this.filesdiscount.length + this.proof_values.split(",").length >=
      this.discount_proof_number_of_files.min
    ) {
      //  this.pageshift('apihit')
      // alert('success')
      let formdata = new FormData();

      this.filesdiscount.forEach((el: any, i: any) => {
        // console.log(el,'el');

        formdata.append("proof" + "[]", el);
        // el.files.forEach((file:File,index:any) => {

        // });
      });
      formdata.append("booking_id", this.encyid);
      // console.log(this.removedarr,'rrssssssswwwwwwweeeeeeeerrrrrrrrr');

      if (this.senddelete) {
        formdata.append("delete_proof", this.removedarr);
      }
      // formdata.append(''+'[]',el); this.removedarr

      this.common
        .DiscountFileProof(formdata)
        .pipe(takeUntil(this.destroy$))
        .subscribe(
          (result) => {
            this.result = result;
          },
          (error) => {
            this.toster.error(error.error["message"]);
            //   if(error.error['message'] =='Unauthenticated.'){
            //     this.authService.clear();

            //   }
          }
        );
    } else {
      this.pdf_error_dis =
        "Please Upload at least " +
        this.discount_proof_number_of_files.min +
        "Files";
    }
  }

  redirect() {
    // alert(this.user_id)
    this.router.navigate(["user/create-user/" + this.user_id]);
  }
  onFileSelected(event: any, item: any, i: any, breakcheck: any) {
    let files = event.target.files;
    files = Array.from(files);
    if (files.length > 0) {
      for (let index = 0; index < files.length; index++) {
        const file = files[index];

        let ft = file.type.split("/");
        if (
          file.type == "" ||
          (ft.length > 0 && !item.accept.includes(ft[1]))
        ) {
          if (breakcheck == "nobreak") {
            this.Validations[i].message = "File type should be " + item.accept;
          } else {
            this.Image_error_mess = "File type should be " + item.accept;
          }
          item.files = [];
          item.value = "";
          break;
        }

        if (file.size / 1024 / 1024 > item.size) {
          if (breakcheck == "break") {
            this.Image_error_mess =
              "File size should be less than " + item.size + " MB.";
          }
          item.files = [];
          item.value = "";
          break;
        }
        if (index + 1 == files.length) {
          if (
            item.multiple == false &&
            this.files.length > 0 &&
            breakcheck == "nobreak"
          ) {
            this.Validations[i].message = "Only Upload one file";
          } else if (
            breakcheck == "break" &&
            item.multiple == false &&
            this.files.length > 0
          ) {
            this.Image_error_mess = "Only Upload one file";
          } else if (
            breakcheck == "break" &&
            item.multiple == true &&
            this.files.length + 1 > item.max
          ) {
            this.Image_error_mess = "Only Upload" + item.max + "file";
          } else if (
            breakcheck == "nobreak" &&
            item.multiple == true &&
            this.files.length + 1 > item.max
          ) {
            this.Validations[i].message = "Only Upload" + item.max + "file";
          } else {
            for (const item of files) {
              item.progress = 0;
              this.files.push(item);
            }
            item.files = files;
            if (breakcheck == "nobreak") {
              this.Validations[i].message = "";
            }
          }
        }
      }
    } else {
      item.files = [];

      if (breakcheck == "nobreak") {
        this.Validations[i].message = "";
      }
    }
    this.uploadFilesSimulator(0);

    // console.log(item.value,"ffffffffffff");
  }
  deleteFile(index: number) {
    this.files.splice(index, 1);
  }
  deleteFilediscount(index: number) {
    // this.upimgdis=true
    this.filesdiscount.splice(index, 1);
  }

  //  openpop(type){
  //   if(type == 'add'){
  // this.swal_title='Additional Amount';
  //  this.html=`
  //  <input type="number" id="amount12" class="swal2-input" placeholder="Amount">
  // <input type="text" id="description12" class="swal2-input" placeholder="Description">
  // <`
  //   }else{
  //     this.swal_title='Refund Amount'
  //     this.html=`<input type="number" id="amount12" class="swal2-input" placeholder=" Refund Amount">
  // <input type="text" id="description12" class="swal2-input" placeholder="Description">`

  //   }

  //   // let new =''
  //   Swal.fire({
  //     title: this.swal_title,

  //     html: this.html,
  //     confirmButtonText: 'Add',
  //     focusConfirm: false,
  //     preConfirm: () => {
  //       const amount = Swal.getPopup().querySelector('#amount12').value
  //       const description =Swal.getPopup().querySelector('#description12').value
  //       if (!amount && !description) {
  //         Swal.showValidationMessage(`Please enter amount and desciption`)
  //       }
  //      else if (!amount) {
  //         Swal.showValidationMessage(`Please enter amount`)
  //       }
  //       else if (!description) {
  //         Swal.showValidationMessage(`Please enter desciption`)
  //       }
  //       if(type == 'refund'){{
  // if(parseInt(amount)> parseInt(this.totalamount)){
  //   Swal.showValidationMessage(`Refund Amount Should not be greater than Total Amount Paid`)
  //   return false
  // }
  // }
  //       }
  //       return { amount: amount, description: description }
  //     }
  //   }).then((result) => {
  //     if(type == 'add'){
  //       // Swal.fire('add')
  //       let data={
  //         booking_id:this.encyid,
  //         additional_amount:result.value.amount,
  //         reason:result.value.description
  //       }
  //       this.common.additionalamount(data).pipe(takeUntil(this.destroy$))
  //       .subscribe((result) =>{
  //         Swal.fire({
  //           title: '',
  //           text: result['message'],
  //           icon: 'success',
  //           timer: 2000,
  //           showConfirmButton: false,
  //           showClass: {
  //             popup: 'animate__animated animate__fadeInDown'
  //           },
  //           hideClass: {
  //             popup: 'animate__animated animate__fadeOutUp'
  //           }
  //         });
  //       })
  //         }else{
  //           // console.log(result.value.amount,  this.totalamount,)

  //             let data={
  //               booking_id:this.encyid,
  //               refund_amount:result.value.amount,
  //               reason:result.value.description
  //             }
  //             this.common.RefundAmount(data).pipe(takeUntil(this.destroy$))
  //             .subscribe((result) =>{
  //               Swal.fire({
  //                 title: '',
  //                 text: result['message'],
  //                 icon: 'success',
  //                 timer: 2000,
  //                 showConfirmButton: false,
  //                 showClass: {
  //                   popup: 'animate__animated animate__fadeInDown'
  //                 },
  //                 hideClass: {
  //                   popup: 'animate__animated animate__fadeOutUp'
  //                 }
  //               });
  //             })

  //         }

  //   })
  //  }
  hitIt() {
    // alert('hit2')
  }
  addAdditionalPayment() {
    if (this.AddAmountGroup.valid) {
      let data = {
        booking_id: this.encyid,
        additional_amount: this.AddAmountGroup.get("Addamount").value,
        reason: this.AddAmountGroup.get("adddes").value,
        link_expired_at: this.AddAmountGroup.get("Expirytime").value,
      };
      console.log(data, "data"); 

      // alert('success')
      this.LoaderClass = true;
      this.modalService.dismissAll();
      this.common
        .additionalamount(data)
        .pipe(takeUntil(this.destroy$))
        .subscribe(
          (result) => {
            this.LoaderClass = false;
            Swal.fire({
              title: "",
              text: result["message"],
              icon: "success",
              timer: 2000,
              showConfirmButton: false,
              showClass: {
                popup: "animate__animated animate__fadeInDown",
              },
              hideClass: {
                popup: "animate__animated animate__fadeOutUp",
              },
            });
            this.getformdata();
          },
          (error) => {
            console.log(error.error.message, "eeeeeeee");
            Swal.fire({
              position: "center",
              icon: "error",
              title: error.error.message,
              padding: "2em",
            });
            // this.withTitle(error);
            this.LoaderClass = false;
          }
        );
    } else {
      for (let i in this.AddAmountGroup.controls) {
        this.AddAmountGroup.controls[i].markAsTouched();
      }
    }
  }
  addRefundPayment() {
    if (this.AddRefundAmountGroup.valid && this.uploadError == "") {
      // let data = {
      //   booking_id: this.encyid,
      //   refund_amount: this.AddRefundAmountGroup.get('Addrefamount').value,
      //   reason: this.AddRefundAmountGroup.get('addrefdes').value,
      //   refund_attachment: this.files[0],
      // }

      let data = new FormData();
      data.append("booking_id", this.encyid);
      data.append(
        "refund_amount",
        this.AddRefundAmountGroup.get("Addrefamount").value
      );
      data.append("reason", this.AddRefundAmountGroup.get("addrefdes").value);
      data.append("refund_attachment", this.files[0]);

      let amount = this.totalamount.replace("QAR", "");
      let refundamount = this.totalrefundamount.replace("QAR", "");
      amount = amount.replace(/\,/g, "");
      refundamount = refundamount.replace(/\,/g, "");
      let requestamount = parseInt(
        this.AddRefundAmountGroup.get("Addrefamount").value
      );

      if (
        requestamount > 0 &&
        requestamount > parseInt(amount) - parseInt(refundamount)
      ) {
        Swal.fire({
          position: "center",
          icon: "error",
          title: "Refund Amount Should not be greater than Total Amount Paid",
          padding: "2em",
        });
        return false;
      } else if (requestamount <= 0) {
        Swal.fire({
          position: "center",
          icon: "error",
          title: "Refund Amount Should be greater than 0",
          padding: "2em",
        });
        return false;
      }

      this.LoaderClass = true;

      this.modalService.dismissAll();

      this.refundRequestAmount = requestamount;

      this.common
        .RefundAmount(data)
        .pipe(takeUntil(this.destroy$))
        .subscribe(
          (result) => {
            this.LoaderClass = false;
            this.totalrefundamount =
              this.totalrefundamount + this.refundRequestAmount;
            this.getformdata();
            this.AddRefundAmountGroup.reset();
            Swal.fire({
              title: "",
              text: result["message"],
              icon: "success",
              timer: 2000,
              showConfirmButton: false,
              showClass: {
                popup: "animate__animated animate__fadeInDown",
              },
              hideClass: {
                popup: "animate__animated animate__fadeOutUp",
              },
            });
          },
          (error) => {
            // this.withTitle(error);

            this.LoaderClass = false;
          }
        );
    } else {
      for (let i in this.AddRefundAmountGroup.controls) {
        this.AddRefundAmountGroup.controls[i].markAsTouched();
      }
    }
  }
  FilterStore() {
    this.common.executeFilter(true);
    if (this.cookie.get("backtype") == "vote") {
      this.router.navigate(["/booking/votes-list"]);
    } else {
      this.router.navigate(["/booking/all-bookings"]);
    }
  }
  SendEmail() {
    Swal.fire({
      title: "Are you sure?",
      text: "you want to send booking email again?",
      icon: "info",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes",
      cancelButtonText: "No",
    }).then((result) => {
      // this.formservice.duplicateForm(id)
      console.log(result.value, "rrrr");

      // alert('12')
      if (result.value) {
        let data = {
          booking_id: this.encyid,
        };
        this.formService
          .send_booking_email(data)
          .pipe(takeUntil(this.destroy$))
          .subscribe(
            (result) => {
              this.result = result;

              Swal.fire({
                position: "center",
                type: "success",
                icon: "success",
                title: this.result.message,
                // html: emptyBuilder
                //   ? '<br><p role="alert" class="txt-secondary">You need to build the form !</p>'
                //   : "",
                showConfirmButton: false,
                timer: 2000,
                padding: "2em",
                showClass: {
                  popup: "animate__animated animate__fadeInDown",
                },
                hideClass: {
                  popup: "animate__animated animate__fadeOutUp",
                },
              });
            },
            (error) => {
              Swal.fire({
                position: "center",
                type: "error",
                icon: "error",
                title: error.error.message,
                // html: emptyBuilder
                //   ? '<br><p role="alert" class="txt-secondary">You need to build the form !</p>'
                //   : "",
                showConfirmButton: false,
                timer: 2000,
                padding: "2em",
                showClass: {
                  popup: "animate__animated animate__fadeInDown",
                },
                hideClass: {
                  popup: "animate__animated animate__fadeOutUp",
                },
              });
            }
          );
      }
    });
  }
  getformdata(formDataId = null) {
    let data = {
      form_id: this.cookie.get("fzsd"),
    };
    this.formService
      .fetchBookingData(data, formDataId ? formDataId : this.id)
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (result) => {
          if (Object.keys(result["data"]).length > 0) {
            this.bookingid = result["data"]["submission_id"];
            this.encyid = result["data"]["id"];
            this.formName = result["data"]["form_name"];
            this.formInfo = result["data"]["form_info"];
            this.formtype = result["data"]["form_type"];
            this.fname = result["data"]["user_first_name"];
            this.lname = result["data"]["user_last_name"];
            this.user_dob = result["data"]["user_dob"];
            this.fname2 = result["data"]["user_first_name"];
            this.lname2 = result["data"]["user_last_name"];
            this.user_dob2 = result["data"]["user_dob"];
            // alert(result["data"]["latest_form_price"])
            // this.totalamount = result["data"]["total_amount"];
            this.totalamount = result["data"]["total_amount_paid"];
            this.totalrefundamount = result["data"]["total_refund_amount"];
            this.latestamount = result["data"]["total_amount_paid"];
            this.certificate = result["data"]["certificate"];
            this.discountamount = result["data"]["discount_fee"];
            this.bookingdate = result["data"]["booking_date"];
            this.typeofcustomer = result["data"]["user_type"];
            this.basicinfo = result["data"]["main"];
            this.user_id = result["data"]["user_id"];
            this.formType = result["data"]["form_type"];
            this.gender = result["data"]["gender"];
            this.nationality_country = result["data"]["nationality_country"];
            this.phone_number = result["data"]["phone_number"];
            if (result["data"]["qatar_image1"] != "") {
              this.img1 = result["data"]["qatar_image1"];
            }
            if (result["data"]["qatar_image2"] != "") {
              this.img2 = result["data"]["qatar_image2"];
            }
            if (result["data"]["passport_img1"] != "") {
              this.img1 = result["data"]["passport_img1"];
            }
            if (result["data"]["passport_img2"] != "") {
              this.img2 = result["data"]["passport_img2"];
            }
            this.booking_form_price = result["data"]["booking_form_price"];
            this.payment_status = result["data"]["payment_status"];
            this.invoice_numbers = result["data"]["invoice_numbers"];

            this.model = result["data"]["form_data"]
              ? JSON.parse(result["data"]["form_data"])
              : [];
            if (this.model.form_break) {
              this.showval = true;
              this.model3 = this.model.attributes;
              this.model3.forEach((x, i) => {
                x.forEach((y, j) => {
                  if (j == 0) {
                    x[j]["index"] = parseInt(i) + 1;
                  }
                });
              });
              console.log(this.model3, "=====", this.showval);
            } else {
              this.showval = false;
              this.model2 = this.model.attributes;
            }
            this.discount_rule = JSON.parse(result["data"]["discount_rule"]);

            this.rule_name = this.discount_rule.rule_name;
            // if percentefa>0 the percent else amount
            this.rule_type = result["data"]["discount_percentage"];
            // if(this.discount_rule.length > 0){
            if (this.discount_rule.proof_values != "") {
              this.proof_values = this.discount_rule.proof_values;
            }
            console.log(this.proof_values, "ppppppp");
            this.transactions = result["data"]["transactions"];
            this.transactions.forEach((trans, i) => {
              if (trans.additional_amount != null) {
                trans.additional_amount = trans.additional_amount
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
              }
              if (trans.refund_amount != null) {
                trans.refund_amount = trans.refund_amount
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
              }
              if (trans.transaction_amount != null) {
                trans.transaction_amount = trans.transaction_amount
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
              }
              if (trans.additional_amount != null) {
                trans.additional_amount = trans.additional_amount
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
              }
            });
            this.mainTransaction = this.transactions.filter((x, i) => {
              return x.transaction_amount != "";
            });
            // this.transactions = Object.keys(this.transactions).map(key => {
            //   return this.transactions[key];
            // })
            this.additional_payments = result["data"]["additional_payments"];
            this.refund_payments = result["data"]["refund_payments"];
            // console.log(this.transactions,'tttttttsadsadsa')

            // this.total_discount= this.result.data.total_discount,
            // this.final_max_percentage=this.result.data.final_max_percentage,
            // this.form_price= this.result.data.form_price,
            // this.final_price=this.result.data.final_price,
            (this.proof_required = this.discount_rule.proof_required),
              (this.discount_proof_title =
                this.discount_rule.discount_proof_title),
              (this.discount_proof_description =
                this.discount_rule.discount_proof_description),
              (this.discount_proof_files_allowed =
                this.discount_rule.discount_proof_files_allowed),
              (this.discount_proof_number_of_files =
                this.discount_rule.discount_proof_number_of_files),
              (this.discount_proof_file_size =
                this.discount_rule.discount_proof_file_size);
            // this.booking_id =this.result.data.booking_id
            if (this.model.form_break) {
              this.pageBreakSettings = this.model.attributes[0][0];
            }
            this.dummyStepForm();
          }
          this.result = data;
          this.FormName = result["data"]["form_name"];

          this.model = JSON.parse(result["data"]["form_data"]);
          this.isPageBreak = this.model.form_break;
          this.displayForm = this.model.attributes;
          // console.log("displayform", this.displayForm);
          this.displayForm.forEach((x) => {
            if (x.type == "autocomplete") {
              // console.log(x.value,"xxxxxx");

              this.displayoption = x.value;
              // console.log("display", this.displayoption);
            }
          });
          this.encyid = result["data"]["id"];
          // console

          // return;
          if (!this.isPageBreak) {
            this.Validations = [];
            // this.model.forEach((item:any,index:any) => {
            this.displayForm.forEach((item: any, index: any) => {
              let obj = {
                name: item.name,
                message: "",
              };
              this.Validations[index] = obj;

              switch (item.type) {
                case "email":
                  switch (item.domainsAllowed) {
                    case "0": // allow all domains
                      this.displayForm[index].regex = item.regex
                        ? item.regex
                        : "^([a-zA-Z0-9_.-]+)@([a-zA-Z0-9_.-]+).([a-zA-Z]{2,5})$";
                      // this.model[index].regex = item.regex ? item.regex : "^([a-zA-Z0-9_.-]+)@([a-zA-Z0-9_.-]+).([a-zA-Z]{2,5})$";
                      break;
                    case "1": // allow specific domains
                      let allowedDomains = item.allowedDomainsList.replace(
                        ", ",
                        "|"
                      );
                      this.displayForm[index].regex =
                        "^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@(" +
                        allowedDomains +
                        ")$";
                      // this.model[index].regex = "^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@("+allowedDomains+")$";
                      break;
                    case "2": // restrict specific domains
                      let restrictededDomains =
                        item.restrictedDomainsList.replace(", ", "|");
                      this.displayForm[index].regex =
                        "^([a-zA-Z0-9_.-]+)@(?!" +
                        restrictededDomains +
                        ")([a-zA-Z0-9_.-]+).([a-zA-Z]{2,5})$";
                      // this.model[index].regex = "^([a-zA-Z0-9_.-]+)@(?!"+restrictededDomains+")([a-zA-Z0-9_.-]+).([a-zA-Z]{2,5})$";
                      break;

                    default:
                      break;
                  }
                  break;

                case "radio":
                  if (item.sorting == "asc") {
                    item.values.sort(
                      (a: { label: string }, b: { label: any }) =>
                        a.label.localeCompare(b.label)
                    );
                  }
                  if (item.sorting == "desc") {
                    item.values.sort(
                      (a: { label: string }, b: { label: any }) =>
                        b.label.localeCompare(a.label)
                    );
                  }
                  if (item.allowOthers) {
                    if (item.otherFieldType == "text") {
                      this.displayForm[index].otherTextFieldValue = "";
                      // this.model[index].otherTextFieldValue = '';
                    }
                    if (item.otherFieldType == "checkbox") {
                      this.displayForm[index].otherCheckboxFieldValue = false;
                      // this.model[index].otherCheckboxFieldValue = false;
                    }
                    if (item.otherFieldType == "radio") {
                      this.displayForm[index].otherRadioFieldValue = false;
                      // this.model[index].otherRadioFieldValue = false;
                    }
                  }
                  break;
                case "autocomplete":
                  if (item.sorting == "asc") {
                    item.values.sort(
                      (a: { label: string }, b: { label: any }) =>
                        a.label.localeCompare(b.label)
                    );
                  }
                  if (item.sorting == "desc") {
                    item.values.sort(
                      (a: { label: string }, b: { label: any }) =>
                        b.label.localeCompare(a.label)
                    );
                  }
                  if (item.allowOthers) {
                    if (item.otherFieldType == "text") {
                      this.displayForm[index].otherTextFieldValue = "";
                      // this.model[index].otherTextFieldValue = '';
                    }
                    if (item.otherFieldType == "checkbox") {
                      this.displayForm[index].otherCheckboxFieldValue = false;
                      // this.model[index].otherCheckboxFieldValue = false;
                    }
                    if (item.otherFieldType == "radio") {
                      this.displayForm[index].otherRadioFieldValue = false;
                      // this.model[index].otherRadioFieldValue = false;
                    }
                  }
                  if (!item.multiple) {
                    let val = item.value.split(",");
                    item.value = val;
                    // console.log(this.displayForm,"dddddddddddddddd==============")
                  }
                  break;
                case "file":
                  // console.log();

                  this.displayForm[index].files = [];
                  // this.model[index].files = [];
                  // this.imgarr=item.value.split(',')
                  // console.log(this.imgarr,'ll');

                  break;
                case "address-group":
                  // console.log(item.addressGroup[5].value.toString(),'i================================================================');
                  let val = item.addressGroup[5].value.toString();
                  item.addressGroup[5].value = val;
                  item.addressGroup.map((obj: any) => {
                    if (obj.type == "autocomplete") {
                      this.allCountries = obj.multipleSelectedCountry;
                      return;
                    }
                  });
                  break;
                case "date":
                  // if(item.allowAutofillDate) {
                  // item.value = this.pipe.transform(Date.now(), 'yyyy-MM-dd');
                  item.value = moment(item.value).format("YYYY-MM-DD");

                  // }

                  if (item.futureDates && !item.pastDates) {
                    this.minDate = new Date();
                  }

                  if (item.pastDates && !item.futureDates) {
                    this.maxDate = new Date();
                  }

                  if (item.rangeDates) {
                    this.minDate = new Date(item.startFromDate);
                    this.maxDate = new Date(item.EndOnDate);
                  }
                  break;
                case "time":
                  item.timeFormat = parseInt(item.timeFormat);
                  item.minutesInterval = parseInt(item.minutesInterval);
                  break;
                case "datetime-local":
                  item.timeFormat = parseInt(item.timeFormat);
                  if (
                    item.rangeEndDateTime !== "" &&
                    item.rangeStartDateTime !== ""
                  ) {
                    let enddatetime: number[] = [];
                    let startdatetime: number[] = [];
                    let endDateAr = item.rangeEndDateTime.split("T");
                    let startDateAr = item.rangeStartDateTime.split("T");
                    for (let i = 0; i < endDateAr.length; i++) {
                      if (endDateAr[i].includes("-")) {
                        endDateAr[i].split("-").map((obj: any) => {
                          enddatetime.push(parseInt(obj));
                        });
                      }
                      if (endDateAr[i].includes(":")) {
                        endDateAr[i].split(":").map((obj: any) => {
                          enddatetime.push(parseInt(obj));
                        });
                      }
                    }

                    for (let i = 0; i < startDateAr.length; i++) {
                      if (startDateAr[i].includes("-")) {
                        startDateAr[i].split("-").map((obj: any) => {
                          startdatetime.push(parseInt(obj));
                        });
                      }
                      if (startDateAr[i].includes(":")) {
                        startDateAr[i].split(":").map((obj: any) => {
                          startdatetime.push(parseInt(obj));
                        });
                      }
                    }
                    this.datetimeLocalEndTime =
                      new Date(item.rangeEndDateTime).getTime() / 1000;
                    item.rangeEndDateTime = new Date(
                      enddatetime[0],
                      enddatetime[1] - 1,
                      enddatetime[2],
                      enddatetime[3],
                      enddatetime[4]
                    );
                    item.rangeStartDateTime = new Date(
                      startdatetime[0],
                      startdatetime[1] - 1,
                      startdatetime[2],
                      startdatetime[3],
                      startdatetime[4]
                    );
                  }
                  if (item.allowAutofillDateTime) {
                    item.value = new Date();
                  }
                  break;
                //                   case'address-group':

                // break;
                case "text":
                  if (
                    item.subtype == "decimal" &&
                    item.unitOfCount == "" &&
                    item.values.length > 1
                  )
                    item.unitOfCount = item.values[0].value;
                  break;
                default:
                  break;
              }

              // this.model[index].value = item.value ? item.value : "";
              this.displayForm[index].value = item.value ? item.value : "";
            });
          } else {
            let formArr = [];
            let breakObj: any[];
            formArr = this.displayForm;
            // console.log(this.displayForm,"dd");

            this.displayForm.forEach((items: any, indexes: any) => {
              // let obj = {
              //   name:item.name,
              //   message:""
              // }
              // console.log(items,'iteeeeeee');

              items.forEach((item, index) => {
                switch (item.type) {
                  case "email":
                    switch (item.domainsAllowed) {
                      case "0": // allow all domains
                        this.displayForm[index].regex = item.regex
                          ? item.regex
                          : "^([a-zA-Z0-9_.-]+)@([a-zA-Z0-9_.-]+).([a-zA-Z]{2,5})$";
                        // this.model[index].regex = item.regex ? item.regex : "^([a-zA-Z0-9_.-]+)@([a-zA-Z0-9_.-]+).([a-zA-Z]{2,5})$";
                        break;
                      case "1": // allow specific domains
                        let allowedDomains = item.allowedDomainsList.replace(
                          ", ",
                          "|"
                        );
                        this.displayForm[index].regex =
                          "^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@(" +
                          allowedDomains +
                          ")$";
                        // this.model[index].regex = "^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@("+allowedDomains+")$";
                        break;
                      case "2": // restrict specific domains
                        let restrictededDomains =
                          item.restrictedDomainsList.replace(", ", "|");
                        this.displayForm[index].regex =
                          "^([a-zA-Z0-9_.-]+)@(?!" +
                          restrictededDomains +
                          ")([a-zA-Z0-9_.-]+).([a-zA-Z]{2,5})$";
                        // this.model[index].regex = "^([a-zA-Z0-9_.-]+)@(?!"+restrictededDomains+")([a-zA-Z0-9_.-]+).([a-zA-Z]{2,5})$";
                        break;

                      default:
                        break;
                    }
                    break;

                  case "radio":
                    if (item.sorting == "asc") {
                      item.values.sort(
                        (a: { label: string }, b: { label: any }) =>
                          a.label.localeCompare(b.label)
                      );
                    }
                    if (item.sorting == "desc") {
                      item.values.sort(
                        (a: { label: string }, b: { label: any }) =>
                          b.label.localeCompare(a.label)
                      );
                    }
                    if (item.allowOthers) {
                      if (item.otherFieldType == "text") {
                        this.displayForm[index].otherTextFieldValue = "";
                        // this.model[index].otherTextFieldValue = '';
                      }
                      if (item.otherFieldType == "checkbox") {
                        this.displayForm[index].otherCheckboxFieldValue = false;
                        // this.model[index].otherCheckboxFieldValue = false;
                      }
                      if (item.otherFieldType == "radio") {
                        this.displayForm[index].otherRadioFieldValue = false;
                        // this.model[index].otherRadioFieldValue = false;
                      }
                    }
                    break;
                  case "autocomplete":
                    if (item.sorting == "asc") {
                      item.values.sort(
                        (a: { label: string }, b: { label: any }) =>
                          a.label.localeCompare(b.label)
                      );
                    }
                    if (item.sorting == "desc") {
                      item.values.sort(
                        (a: { label: string }, b: { label: any }) =>
                          b.label.localeCompare(a.label)
                      );
                    }
                    if (item.allowOthers) {
                      if (item.otherFieldType == "text") {
                        this.displayForm[index].otherTextFieldValue = "";
                        // this.model[index].otherTextFieldValue = '';
                      }
                      if (item.otherFieldType == "checkbox") {
                        this.displayForm[index].otherCheckboxFieldValue = false;
                        // this.model[index].otherCheckboxFieldValue = false;
                      }
                      if (item.otherFieldType == "radio") {
                        this.displayForm[index].otherRadioFieldValue = false;
                        // this.model[index].otherRadioFieldValue = false;
                      }
                    }
                    if (!items.multiple) {
                      // console.log( typeof item.value,"vvvv");
                      if (typeof item.value == "string") {
                        // alert('string')
                        let val = item.value.split(",");
                        item.value = val;
                      }
                      // console.log(this.displayForm,"dddddddddddddddd==============")
                    }
                    break;
                  // case 'file':
                  //   this.displayForm[index].files = [];
                  //   // this.model[index].files = [];
                  //   break;
                  case "address-group":
                  case "address-group":
                    let filval = items.filter((ite: any, k: any) => {
                      // console.log(ite.type,'sss');
                      return ite.type == "address-group";
                    });
                    // console.log(filval,"assss====");

                    // console.log(items,'i================================================================');
                    let val = filval[0].addressGroup[5].value.toString();
                    // console.log(val,'sss================================================================')
                    filval[0].addressGroup[5].value = val;

                    // filval.addressGroup.forEach((obj: any) => {
                    //   if(obj.type == 'autocomplete') {
                    //     this.allCountries = obj.multipleSelectedCountry;
                    //     return;
                    //   }
                    // })
                    break;
                  // item.addressGroup.map((obj: any) => {
                  //   if(obj.type == 'autocomplete') {
                  //     this.allCountries = obj.multipleSelectedCountry;
                  //     return;
                  //   }
                  // })
                  // break;
                  case "date":
                    // if(item.allowAutofillDate) {
                    // item.value = this.pipe.transform(Date.now(), 'yyyy-MM-dd');
                    item.value = moment(item.value).format("YYYY-MM-DD");

                    // }

                    if (item.futureDates && !item.pastDates) {
                      this.minDate = new Date();
                    }

                    if (item.pastDates && !item.futureDates) {
                      this.maxDate = new Date();
                    }

                    if (item.rangeDates) {
                      this.minDate = new Date(item.startFromDate);
                      this.maxDate = new Date(item.EndOnDate);
                    }
                    break;
                  case "time":
                    item.timeFormat = parseInt(item.timeFormat);
                    item.minutesInterval = parseInt(item.minutesInterval);
                    break;
                  case "datetime-local":
                    item.timeFormat = parseInt(item.timeFormat);
                    if (
                      item.rangeEndDateTime !== "" &&
                      item.rangeStartDateTime !== ""
                    ) {
                      let enddatetime: number[] = [];
                      let startdatetime: number[] = [];
                      let endDateAr = item.rangeEndDateTime.split("T");
                      let startDateAr = item.rangeStartDateTime.split("T");
                      for (let i = 0; i < endDateAr.length; i++) {
                        if (endDateAr[i].includes("-")) {
                          endDateAr[i].split("-").map((obj: any) => {
                            enddatetime.push(parseInt(obj));
                          });
                        }
                        if (endDateAr[i].includes(":")) {
                          endDateAr[i].split(":").map((obj: any) => {
                            enddatetime.push(parseInt(obj));
                          });
                        }
                      }

                      for (let i = 0; i < startDateAr.length; i++) {
                        if (startDateAr[i].includes("-")) {
                          startDateAr[i].split("-").map((obj: any) => {
                            startdatetime.push(parseInt(obj));
                          });
                        }
                        if (startDateAr[i].includes(":")) {
                          startDateAr[i].split(":").map((obj: any) => {
                            startdatetime.push(parseInt(obj));
                          });
                        }
                      }
                      this.datetimeLocalEndTime =
                        new Date(item.rangeEndDateTime).getTime() / 1000;
                      item.rangeEndDateTime = new Date(
                        enddatetime[0],
                        enddatetime[1] - 1,
                        enddatetime[2],
                        enddatetime[3],
                        enddatetime[4]
                      );
                      item.rangeStartDateTime = new Date(
                        startdatetime[0],
                        startdatetime[1] - 1,
                        startdatetime[2],
                        startdatetime[3],
                        startdatetime[4]
                      );
                    }
                    if (item.allowAutofillDateTime) {
                      item.value = new Date();
                    }
                    break;

                  case "text":
                    if (
                      item.subtype == "decimal" &&
                      item.unitOfCount == "" &&
                      item.values.length > 1
                    )
                      item.unitOfCount = item.values[0].value;
                    break;
                  default:
                    break;
                }

                // this.model[index].value = item.value ? item.value : "";
                // this.displayForm[index].value = item.value ? item.value : "";
              });
              // this.Validations[index]=obj;
            });
          }
        },
        (error) => {
          // this.withTitle(error);
        }
      );
  }
  RepushMiddleware(payment) {
    let data = {
      payment_trx_id: payment.transaction_id,
      booking_trx_id: payment.id,
      booking_id: payment.booking_id,
    };
    this.formService
      .repushmiddleware(data)
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (result) => {
          this.toster.success(result["message"]);
          this.getformdata();
        },
        (error) => {
          this.toster.error(error.error["message"]);
        }
      );
  }
  // getJson() {
  //   // this.common.changeDataSubject('login');
  //   let data = {
  //     form_id: this.cookie.get("fzsd"),
  //   };
  //   this.formService
  //     .fetchBookingData(data,this.id)
  //     .then((data) => {

  //     })
  //     .catch((error) => {
  //
  //     });
  // }
  onItemSelect(item: any) {}
  onSelectAll(items: any) {
    // console.log("onSelectAll", items);
  }
  dummyStepForm() {
    this.firstFormGroup = this._formBuilder.group({
      firstName: ["", Validators.required],
      lastName: ["", Validators.required],
      contactNumber: ["", Validators.required],
    });
    this.secondFormGroup = this._formBuilder.group(
      {
        email: ["", [Validators.required, Validators.email]],
        password: ["", Validators.required],
        cnfPassword: ["", Validators.required],
      },
      {
        validator: MustMatch("password", "cnfPassword"),
      }
    );
    this.thirdFormGroup = this._formBuilder.group({
      birthdate: ["", Validators.required],
      age: [""],
      hasPassport: ["", Validators.required],
    });
    this.fourthFormGroup = this._formBuilder.group({
      country: ["", Validators.required],
      state: ["", Validators.required],
      city: ["", Validators.required],
    });
  }
// new update code====>
  getBookingSettingPageData() {
    this.formService
      .getBookingSettingPageData()
      .pipe(takeUntil(this.destroy$))
      .subscribe((result) => {
        this.result = result;
        console.log(this.result, "time val");
        this.timeVal = this.result.data.add_pay_link_exp_time;
        this.initializeForm()   
      });
  }
  initializeForm() {
    this.AddAmountGroup = this._formBuilder.group({
      Addamount: ["", Validators.required],
      adddes: ["", Validators.required],
      Expirytime: [this.futureDate(), Validators.required],
    });
  } 
  futureDate() {
    const now = new Date();
    const istOffset = 5.5 * 60 * 60 * 1000;
    if (this.timeVal !== undefined) {
      const future = new Date(now.getTime() + this.timeVal * 60 * 60 * 1000);
      future.setTime(future.getTime() + istOffset);
      return future.toISOString().slice(0, 16);
    }
    return now.toISOString().slice(0, 16);
  }
  minExpiryDate() {
    const now = new Date();
    const oneHourLater = new Date(now.getTime() + 60 * 60 * 1000);  
    return oneHourLater.toISOString().slice(0, 16);
  }
  
// new update code====>
 
  checkCustomeValidations(model: any): true | false {
    for (let i = 0; i < model.length; i++) {
      let item = model[i];
      switch (item.type) {
        case "rating":
          if (item.required && (item.value == "" || item.value == null)) {
            this.Validations[i]["message"] = item.label + " is required.";
            this.message = "Please fill all required fields.";
            this.success = false;
            this.error = true;
            return false;
          }
          break;
        case "file":
          // this.model.map((el:any)=>{
          if (
            item.type == "file" &&
            item.multiple == true &&
            item.files.length > item.max
          ) {
            this.Validations[i]["message"] =
              "Maximum" + item.max + " files are allowed.";
            this.message = "File selection error!";
            this.success = false;
            this.error = true;
            return false;
          }
          // })
          break;
        default:
          break;
      }
    }
    return true;
  }
  submit(form: NgForm) {
    //

    var formNames = Object.keys(form.value);

    var currentTime = Math.floor(new Date().getTime() / 1000);
    if (!this.isPageBreak) {
      var formValid = form.valid ? true : false;
      if (!formValid) {
        formNames.map((obj: string) => {
          form.control.get(obj)?.markAsTouched();
        });

        // this.model.forEach((el:any,index:any)=> {
        this.displayForm.forEach((el: any, index: any) => {
          if (
            el.type == "radio" ||
            el.type == "checkbox" ||
            el.type == "rating"
          ) {
            if (el.value == "" && el.required) {
              this.Validations[index].message = el.label + " is required.";
            }
          } else {
            if (this.Validations[index]) this.Validations[index].message = "";
          }
        });
        // form.control.markAllAsTouched();
      } else {
        for (let index = 0; index < this.Validations.length; index++) {
          let item = this.Validations[index];
          if (item.message != "") {
            formValid = false;
            break;
          }
        }
        if (
          this.datetimeLocalEndTime &&
          currentTime > this.datetimeLocalEndTime
        ) {
          this.message = "Form is expired!";
          this.success = false;
          this.error = true;
          formValid = false;
          return;
        }

        // formValid = this.checkCustomeValidations(this.model);
        formValid = this.checkCustomeValidations(this.displayForm);

        if (formValid) {
          let formdata = new FormData();
          // let files = this.model.filter((el:any)=>{
          let files = this.displayForm.filter((el: any) => {
            return el.type == "file";
          });

          if (files.length > 0) {
            files.forEach((el: any) => {
              el.files.forEach((file: File, index: any) => {
                formdata.append(el.name + "_files[]", file);
              });
            });
          }
          this.displayForm.forEach((val, index) => {
            if (val.type == "file") {
              val["existing_value"] = val.value;
            }
          });
          // formdata.append('existing_value',this.imgarr)
          formdata.append("submission_id", this.encyid);

          // if (this.cookie.get('access_token')==''||this.cookie.get('access_token')==null||this.cookie.get('access_token')==undefined){
          // formdata.append("customer_type",'2');

          // }else{
          // formdata.append("customer_type",'1');

          // }

          this.model.form_break = this.isPageBreak;
          this.model.attributes = this.displayForm;
          formdata.append("form_data", JSON.stringify(this.model));
          // formdata.append("form_data",JSON.stringify(this.displayForm));
          // if(this.token == undefined){
          //   this.captchaMsg='Please tick the captcha'

          // }

          this.formService
            .submituserformedit(formdata)
            .pipe(takeUntil(this.destroy$))
            .subscribe((result) => {
              // this.result = data;
              // this.getformdata()
              this.message = this.result.message;
              // this.success = true;
              // this.error = false;
              // setTimeout(()=>{
              //   this.success = false;

              // },2000)
            });
        }
      }
    } else {
      let errors: any = [];
      if (
        this.displayForm[0][0].type == "break" &&
        this.displayForm[0][0].onSubmition
      ) {
        for (let i = 0; i < this.displayForm.length; i++) {
          this.displayForm[i].map((obj: any) => {
            if (
              obj.required &&
              formNames.includes(obj.name) &&
              obj.value == ""
            ) {
              errors.push(obj.label);
            }
          });
        }
      }

      if (errors.length > 0) {
        this.message = errors.join(", ") + " required field(s).";
        this.success = false;
        this.error = true;
      } else {
        let formdata = new FormData();

        // this.common.FormDataSubmit.subscribe((res: any) => {
        //   this.resval =res
        //   formdata.append("user_type",res.reguest);

        //   if(res.reguest=='2'){
        //     formdata.append("dependent_id",res.dep_user);
        //     formdata.append("user_id",res.user_id2);
        //   }else{
        //     formdata.append("user_id",res.user_id2);
        //   }
        // });

        formdata.append("submission_id", this.encyid);

        this.model.form_break = this.isPageBreak;
        this.model.attributes = this.displayForm;

        // formdata.append("form_data",JSON.stringify(this.displayForm));
        formdata.append("form_data", JSON.stringify(this.model));

        // if (this.cookie.get('access_token')==''||this.cookie.get('access_token')==null||this.cookie.get('access_token')==undefined){
        //   formdata.append("customer_type",'2');
        // }else{
        //   formdata.append("customer_type",'1');
        // }
        this.displayForm.forEach((val, index) => {
          val.forEach((x: any, j) => {
            if (x.type == "file") {
              x["existing_value"] = x.value;
            }
          });
        });

        // console.log(this.displayForm,'dd');

        this.formService
          .submituserformedit(formdata)
          .pipe(takeUntil(this.destroy$))
          .subscribe((result) => {
            // this.result = data;
            // this.getformdata()

            this.message = this.result.message;
            // this.success = true;
            // this.error = false;
            // setTimeout(()=>{
            //   this.success = false;

            // },2000)
          });
        // this.apiService.SubmitFormIO(formdata).then((data)=>{
        //
        //   this.result = data;
        //   this.message = this.result.message;
        //   this.success = true;
        //   this.error = false;
        //   setTimeout(()=>{
        //     this.success = false;
        //   },2000);
        //   form.resetForm();
        //   this.stepper.reset();
        // });
      }
    }
  }

  erpResponse() {
    console.log(this.encyid);
    this.formService.getErpData(this.encyid).subscribe(
      (result: any) => {
        console.log("ERP", result);
      },
      (error: any) => {
        console.error(error);
      }
    );
  }
}
