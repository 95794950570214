<div class="container-fluid">
  <div class="page-title">
    <div class="row">
      <div class="col-6">
        <h3>{{title}}</h3>
      </div>
      <div class="col-6">
        <ol class="breadcrumb">
          <li class="breadcrumb-item">
            <a [routerLink]="'/dashboard/hbku'">
              <app-feather-icons [icon]="'home'"></app-feather-icons>
            </a>
          </li>
          <li class="breadcrumb-item" *ngFor="let item of items">
              <a *ngIf="itemsLink != '' && itemsLink != null && itemsLink != undefined" [routerLink]="itemsLink">{{item}}</a>
              <span *ngIf="itemsLink == '' || itemsLink == null || itemsLink == undefined">{{item}}</span>
              <!-- {{item}} -->
          </li>
          <li class="breadcrumb-item active">{{active_item}}</li>
        </ol>
      </div>
    </div>
  </div>
</div>