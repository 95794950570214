<div class="row">
  <div class="col-sm-12">
    <form
      class="needs-validation"
      novalidate
      [ngClass]="{ 'was-validated': validate }"
    >
      <ng-container
        *ngFor="
          let ruleitem of FieldForm;
          let ruleindex = index;
          let last = last
        "
      >
        <div class="rulesDup">
          <!-- {{this.Fields |json}} -->
          <input
            type="text"
            class="form-control"
            placeholder="Rule Name"
            required=""
            [(ngModel)]="ruleitem.rule_name"
            [ngModelOptions]="{ standalone: true }"
          />
          <div class="fieldRuleConditions">
            <div class="ruleCont" style="margin: 33px 0 0 0">
              <div class="contDivInner">
                <span class="newRuleIfTxt">If</span>
                <div class="if-wrapper ifContainer fieldRuleIfCont">
                  <div class="form-group">
                    <select
                      class="custom-select form-control mr-3"
                      [(ngModel)]="ruleitem.if_condition.allow"
                      required=""
                      [ngModelOptions]="{ standalone: true }"
                    >
                      <option
                        value="any"
                        [selected]="ruleitem.if_condition.allow === 'any'"
                      >
                        Any
                      </option>
                      <option
                        value="all"
                        [selected]="ruleitem.if_condition.allow === 'all'"
                      >
                        All
                      </option>
                    </select>
                    <label for="">of the following conditions are met</label>
                  </div>
                  <!-- <ng-container *ngFor="let conditionItem of ruleitem.if_condition.conditions;let conindex = index"> -->

                  <!-- <app-if-value [FieldForm]="ruleitem" [Fields]="Fields" [Break]="Break" [Condition_Show]="Condition_Show" [FieldConditionList]="FieldConditionList"[ruleindex]='ruleindex'></app-if-value> -->
                  <!-- </ng-container> -->
                  <ng-container
                    *ngFor="
                      let conditionItem of ruleitem.if_condition.conditions;
                      let conindex = index
                    "
                  >
                    <div class="fieldRow my-2 py-2">
                      <div class="col-sm-12">
                        <div class="d-flex align-items-center py-1">
                          <div class="form-group mr-3 mb-0">
                            <div class="user-info-dropdown flLeft commonBdr">
                              <ng-container
                                *ngIf="Fields && Fields.length > 0 && !Break"
                              >
                                <!-- <h1>22</h1> -->

                                <select class="custom-select form-control" [(ngModel)]="conditionItem.field_type" required="" (change)="onUserInfoFieldSelect($event.target.value,ruleindex,conindex)" [ngModelOptions]="{ standalone: true }">
                                  <option value="">Select Fields</option>
                                  <ng-container *ngFor="let item of Fields; index as i">
                                    <option [value]="item.name" *ngIf="item.type != 'paragraph'">{{ item.label }}</option>
                                  </ng-container>
                                </select>
                              </ng-container>
                              <ng-container
                                *ngIf="Fields && Fields.length > 0 && Break"
                              >
                                <!-- <h1>Break</h1> -->
                                <select
                                  class="custom-select form-control"
                                  [(ngModel)]="conditionItem.field_type"
                                  required=""
                                  (change)="
                                    onUserInfoFieldSelect(
                                      $event.target.value,
                                      ruleindex,
                                      conindex
                                    )
                                  "
                                  [ngModelOptions]="{ standalone: true }"
                                >
                                  <option value="">Select Fields</option>

                                  <ng-container
                                    *ngFor="let item of Fields; index as i"
                                  >
                                    <ng-container
                                      *ngFor="let ite of item; index as j"
                                    >
                                      <ng-container *ngIf="j == 0">
                                        <option value="" disabled="true">
                                          {{ ite.pageTitle }}
                                        </option>
                                      </ng-container>
                                      <ng-container *ngIf="j > 0">
                                        <option
                                          [value]="ite.name"
                                          [selected]="j == 0"
                                          *ngIf="ite.type != 'paragraph'"
                                        >
                                          {{ ite.label }}
                                        </option>
                                      </ng-container>
                                    </ng-container>
                                  </ng-container>
                                </select>
                              </ng-container>

                              <!-- <ng-template #baseInfoTemplate>
                                <select class="custom-select form-control" [(ngModel)]="conditionItem.field_type" (change)="onUserInfoFieldSelect2($event.target.value,i)" required="" [ngModelOptions]="{ standalone: true }">
                                  <ng-container *ngFor="let item of basicInfoFields;let bi = index">
                                   
                                      <option [value]="item.slug" [selected]="conditionItem.field_type == item.slug">
                                      {{ item.label }}
                                    </option>
                                  </ng-container>
                                </select>
                              </ng-template> -->
                            </div>
                            <div class="clearBoth"></div>
                          </div>
                          <div
                            class="form-group mr-3 mb-0"
                            *ngIf="conditionItem.AddressType.length > 0"
                          >
                            <div class="rule-condition-list">
                              <!-- <h1>abcd</h1> -->
                              <select
                                class="custom-select form-control"
                                required=""
                              >
                                <ng-container
                                  *ngFor="
                                    let item of conditionItem.AddressType;
                                    index as i
                                  "
                                >
                                  <option
                                    [value]="item.id"
                                    [selected]="
                                      ruleEdit
                                        ? item.id == conditionItem.constraint_id
                                        : i == 0
                                    "
                                  >
                                    {{ item.label }}
                                  </option>
                                </ng-container>
                              </select>
                            </div>
                          </div>
                          <div
                            class="form-group mr-3 mb-0"
                            *ngIf="
                              conditionItem.groupFields &&
                              conditionItem.groupFields.length > 0 &&
                              !Break
                            "
                          >
                            <!-- <h1>11</h1> -->
                            <!-- {{conditionItem.group_type}} -->
                            <div class="rule-condition-list">
                              <select
                                [(ngModel)]="conditionItem.group_type"
                                [ngModelOptions]="{ standalone: true }"
                                class="custom-select form-control"
                                (change)="
                                  onUserInfoFieldSelect3(
                                    $event.target.value,
                                    ruleindex,
                                    conindex
                                  )
                                "
                                required=""
                              >
                                <ng-container
                                  *ngFor="
                                    let item of conditionItem.groupFields;
                                    index as i
                                  "
                                >
                                  <option
                                    [value]="item.name"
                                    *ngIf="item.type != 'paragraph'"
                                  >
                                    {{ item.label }}
                                  </option>
                                </ng-container>
                              </select>
                            </div>
                          </div>
                          <!-- break with group fields -->
                          <div
                            class="form-group mr-3 mb-0"
                            *ngIf="
                              conditionItem.groupFields &&
                              conditionItem.groupFields.length > 0 &&
                              Break
                            "
                          >
                            <!-- <h1>11</h1> -->
                            <!-- {{conditionItem.group_type}} -->
                            <div class="rule-condition-list">
                              <select
                                [(ngModel)]="conditionItem.group_type"
                                [ngModelOptions]="{ standalone: true }"
                                class="custom-select form-control"
                                (change)="
                                  onUserInfoFieldSelect3(
                                    $event.target.value,
                                    ruleindex,
                                    conindex
                                  )
                                "
                                required=""
                              >
                                <ng-container
                                  *ngFor="
                                    let item of conditionItem.groupFields;
                                    index as i
                                  "
                                >
                                  <option
                                    [value]="item.name"
                                    *ngIf="item.type != 'paragraph'"
                                  >
                                    {{ item.label }}
                                  </option>
                                </ng-container>
                              </select>
                            </div>
                          </div>
                          <!-- //likert -->
                          <div
                            class="form-group mr-3 mb-0"
                            *ngIf="
                              conditionItem.LikertQuestions.Questions.length > 0
                            "
                          >
                            <!-- <h1>11</h1> -->
                            <!-- {{conditionItem.likertquestion}} -->
                            <div
                              class="rule-condition-list"
                              *ngIf="conditionItem.groupFields.length == 0"
                            >
                              <select
                                [(ngModel)]="conditionItem.likertquestion"
                                [ngModelOptions]="{ standalone: true }"
                                class="custom-select form-control"
                                (change)="
                                  onlikertoptions(
                                    $event.target.value,
                                    ruleindex,
                                    conindex
                                  )
                                "
                                required=""
                              >
                                <option value="">Select Question</option>

                                <ng-container
                                  *ngFor="
                                    let item of conditionItem.LikertQuestions
                                      .Questions;
                                    index as i
                                  "
                                >
                                  <option [value]="item.QuestionName">
                                    {{ item.Question }}
                                  </option>
                                </ng-container>
                              </select>
                            </div>

                            <!-- for Group -->
                            <div
                              class="rule-condition-list"
                              *ngIf="conditionItem.groupFields.length > 0"
                            >
                              <!-- <h1>group</h1> -->
                              <select
                                [(ngModel)]="conditionItem.likertquestion"
                                [ngModelOptions]="{ standalone: true }"
                                class="custom-select form-control"
                                (change)="
                                  onlikertoptionsGroup(
                                    $event.target.value,
                                    ruleindex,
                                    conindex
                                  )
                                "
                                required=""
                              >
                                <option value="">Select Question</option>

                                <ng-container
                                  *ngFor="
                                    let item of conditionItem.LikertQuestions
                                      .Questions;
                                    index as i
                                  "
                                >
                                  <option [value]="item.QuestionName">
                                    {{ item.Question }}
                                  </option>
                                </ng-container>
                              </select>
                            </div>
                          </div>

                          <div
                            class="form-group mr-3 mb-0"
                            *ngIf="
                              conditionItem.field_type !== '' && Condition_Show
                            "
                          >
                            <div class="rule-condition-list">
                              <select
                                class="custom-select form-control"
                                (change)="
                                  Constraintdata($event, ruleindex, conindex)
                                "
                                required=""
                              >
                                <ng-container
                                  *ngFor="
                                    let item of conditionItem.FieldConstraint;
                                    index as i
                                  "
                                >
                                  <option
                                    [value]="item.id"
                                    [selected]="
                                      ruleEdit
                                        ? item.id == conditionItem.constraint_id
                                        : i == 0
                                    "
                                  >
                                    {{ item.name }}
                                  </option>
                                </ng-container>
                              </select>
                            </div>
                          </div>
                          <!-- norml conditions value -->
                          <!-- {{conditionItem.showValueBox |json}}{{conditionItem.multiplecheckbox |json}}{{conditionItem.rating.accept |json}}{{conditionItem.LikertQuestions.values |json}}{{conditionItem.constraint_id |json}} -->
                          <div
                            class="form-group mr-2 mb-0"
                            *ngIf="
                              conditionItem.showValueBox &&
                              conditionItem.multiplecheckbox.length == 0 &&
                              conditionItem.rating.accept == '' &&
                              conditionItem.LikertQuestions.values.length ==
                                0
                            "
                          >

                          <!-- &&
                              conditionItem.constraint_id != '3' &&
                              conditionItem.constraint_id != '4' -->

                            <!-- <h1>bb</h1> -->
                            <div
                              class="condition-value-input"
                              *ngIf="
                                !conditionItem.FileEmpt &&
                                !conditionItem.valforboxes.hasOwnProperty(
                                  'values'
                                )
                              "
                            >
                              <!-- <div class="condition-value-input" *ngIf="conditionItem.valforboxes.length ==0"> -->
                              <!-- <h1>41</h1> -->

                              <input
                                [type]="conditionItem.conditiontype"
                                class="form-control"
                                placeholder="Conditions Value"
                                [(ngModel)]="conditionItem.value"
                                [ngModelOptions]="{ standalone: true }"
                                required=""
                                [disabled]=" conditionItem.constraint_id == '3' || conditionItem.constraint_id == '4'"
                              />
                            </div>
                            <!-- {{conditionItem.valforboxes.hasOwnProperty('values')}} && {{ conditionItem.constraint_id}} -->
                            <div
                              class="rule-condition-list"
                              *ngIf="
                                conditionItem.valforboxes.hasOwnProperty(
                                  'values'
                                ) &&
                                conditionItem.constraint_id != '5' &&
                                conditionItem.constraint_id != '6' &&
                                conditionItem.constraint_id != '7' &&
                                conditionItem.constraint_id != '8' &&
                                conditionItem.constraint_id != '3' &&
                                conditionItem.constraint_id != '4'
                              "
                            >
                              <!-- <div class="rule-condition-list" *ngIf="conditionItem.valforboxes.length > 0 && conditionItem.constraint_id !='5' && conditionItem.constraint_id !='6' && conditionItem.constraint_id !='7' && conditionItem.constraint_id !='8' && conditionItem.constraint_id !='3' && conditionItem.constraint_id !='4'"> -->
                              <!-- <h1>11</h1> -->
                              <ng-select
                                [items]="conditionItem.valforboxes.values"
                                bindLabel="label"
                                [bindValue]="value"
                                class="js-example-basic-multiple ng-select-control"
                                [(ngModel)]="conditionItem.value"
                                placeholder="Select Options"
                                [multiple]="true"
                                [ngModelOptions]="{ standalone: true }"
                                required=""
                              ></ng-select>
                              <!-- {{conditionItem.value | json}} -->
                            </div>
                            <ng-container
                              *ngIf="
                                conditionItem.valforboxes.hasOwnProperty(
                                  'values'
                                ) &&
                                (conditionItem.constraint_id == '5' ||
                                  conditionItem.constraint_id == '6' ||
                                  conditionItem.constraint_id == '7' ||
                                  conditionItem.constraint_id == '8') &&
                                conditionItem.constraint_id != '3' &&
                                conditionItem.constraint_id != '4'
                              "
                            >
                              <!-- {{conditionItem.multiplecheckbox |json}} -->
                              <!-- <div class="condition-value-input" *ngIf="!conditionItem.valforboxes.hasOwnProperty('values')"> -->
                              <div class="condition-value-input">
                                <!-- <h1>21</h1> -->

                                <input
                                  type="text"
                                  class="form-control"
                                  placeholder="Conditions Value"
                                  [(ngModel)]="conditionItem.value"
                                  [ngModelOptions]="{ standalone: true }"
                                  required=""
                                />
                              </div>
                            </ng-container>
                          </div>
                          <!-- multiselect constion value with contain/notontain/startswith/endswith -->
                          <ng-container
                            *ngIf="
                              conditionItem.multiplecheckbox.length > 0 &&
                              conditionItem.constraint_id != '5' &&
                              conditionItem.constraint_id != '6' &&
                              conditionItem.constraint_id != '7' &&
                              conditionItem.constraint_id != '8' &&
                              conditionItem.constraint_id != '3' &&
                              conditionItem.constraint_id != '4'
                            "
                          >
                            <!-- {{conditionItem.multiplecheckbox |json}} -->
                            <!-- <h1>51</h1> -->
                            <ng-select
                              [items]="conditionItem.multiplecheckbox"
                              bindLabel="label"
                              [bindValue]="value"
                              class="js-example-basic-multiple ng-select-control"
                              [(ngModel)]="conditionItem.value"
                              placeholder="Select Options"
                              [multiple]="true"
                              [ngModelOptions]="{ standalone: true }"
                              required=""
                            ></ng-select>
                            <!-- {{conditionItem.value | json}} -->
                          </ng-container>
                          <!-- multiselect constion value with  NO contain/notontain/startswith/endswith -->
                          <ng-container
                            *ngIf="
                              conditionItem.multiplecheckbox.length > 0 &&
                              (conditionItem.constraint_id == '5' ||
                                conditionItem.constraint_id == '6' ||
                                conditionItem.constraint_id == '7' ||
                                conditionItem.constraint_id == '8')
                            "
                          >
                            <!-- {{conditionItem.multiplecheckbox |json}} -->
                            <!-- <div class="condition-value-input" *ngIf="!conditionItem.valforboxes.hasOwnProperty('values')"> -->
                            <div class="condition-value-input">
                              <h1>31</h1>
                              <input
                                type="text"
                                class="form-control"
                                placeholder="Conditions Value"
                                [(ngModel)]="conditionItem.value"
                                required=""
                                [ngModelOptions]="{ standalone: true }"
                              />
                            </div>
                          </ng-container>
                          <!-- //rating Fields -->
                          <ng-container
                            *ngIf="conditionItem.rating.accept == 'star'"
                          >
                            <ngb-rating
                              [(rate)]="conditionItem.rating.value"
                              [max]="conditionItem.rating.size"
                              class="rating-size starShape"
                            ></ngb-rating>
                            <small
                              class="blockquote-footer"
                              *ngIf="
                                conditionItem.rating.description &&
                                conditionItem.rating.description !== ''
                              "
                              ><cite>{{
                                conditionItem.rating.description
                              }}</cite></small
                            >
                            <!-- <select
                            class="custom-select form-control"
                            
                            required=""
                          >
                            <ng-container
                             
                              *ngFor="let dummy of ' '.repeat(conditionItem.rating.size).split(''), let x = index"
                                
                              
                            >

                              <option
                                [value]="x"
                               
                              >
                               {{x}}
                              </option>
                            </ng-container>
                          </select> -->
                          </ng-container>

                          <ng-container
                            *ngIf="conditionItem.rating.accept == 'heart'"
                          >
                            <ng-template #t let-fill="fill">
                              <span class="star" [class.full]="fill === 100">
                                <span class="half" [style.width.%]="fill"
                                  >&hearts;</span
                                >&hearts;
                              </span>
                            </ng-template>

                            <ngb-rating
                              class="heartShape"
                              [(rate)]="conditionItem.rating.value"
                              [starTemplate]="t"
                              [max]="conditionItem.rating.size"
                            ></ngb-rating>
                            <small
                              class="blockquote-footer"
                              *ngIf="
                                conditionItem.rating.description &&
                                conditionItem.rating.description !== ''
                              "
                              ><cite>{{
                                conditionItem.rating.description
                              }}</cite></small
                            >
                          </ng-container>
                          <ng-container
                            *ngIf="conditionItem.rating.accept == 'circle'"
                          >
                            <ng-template #c let-fill="fill">
                              <span
                                class="star"
                                [class.fullCircle]="fill === 100"
                              >
                                <span class="halfCircle" [style.width.%]="fill"
                                  >&#11044;</span
                                >&#11044;
                              </span>
                            </ng-template>

                            <ngb-rating
                              class="circleShape"
                              [(rate)]="conditionItem.rating.value"
                              [starTemplate]="c"
                              [max]="conditionItem.rating.size"
                            ></ngb-rating>
                            <small
                              class="blockquote-footer"
                              *ngIf="
                                conditionItem.rating.description &&
                                conditionItem.rating.description !== ''
                              "
                              ><cite>{{
                                conditionItem.rating.description
                              }}</cite></small
                            >
                          </ng-container>
                          <ng-container
                            *ngIf="conditionItem.rating.accept == 'flag'"
                          >
                            <ng-template #f let-fill="fill">
                              <span
                                class="star"
                                [class.flagfull]="fill === 100"
                              >
                                <span class="flaghalf" [style.width.%]="fill"
                                  ><i class="fa fa-flag"></i></span
                                ><i class="fa fa-flag-o"></i>
                              </span>
                            </ng-template>

                            <ngb-rating
                              class="flagShape"
                              [(rate)]="conditionItem.rating.value"
                              [starTemplate]="f"
                              [max]="conditionItem.rating.size"
                            ></ngb-rating>
                            <small
                              class="blockquote-footer"
                              *ngIf="
                                conditionItem.rating.description &&
                                conditionItem.rating.description !== ''
                              "
                              ><cite>{{
                                conditionItem.rating.description
                              }}</cite></small
                            >
                          </ng-container>
                          <ng-container
                            *ngIf="conditionItem.rating.accept == 'emoji'"
                          >
                            <div class="emojiRatings">
                              <span
                                (click)="conditionItem.rating.value = 1"
                                [class.active]="
                                  conditionItem.rating.value === 1
                                "
                                class="emoji"
                                ><span>😟</span></span
                              >
                              <span
                                (click)="conditionItem.rating.value = 2"
                                [class.active]="
                                  conditionItem.rating.value === 2
                                "
                                class="emoji"
                                ><span>😐</span></span
                              >
                              <span
                                (click)="conditionItem.rating.value = 3"
                                [class.active]="
                                  conditionItem.rating.value === 3
                                "
                                class="emoji"
                                ><span>😀</span></span
                              >
                              <span
                                (click)="conditionItem.rating.value = 4"
                                [class.active]="
                                  conditionItem.rating.value === 4
                                "
                                class="emoji"
                                ><span>🤩</span></span
                              >
                              <span
                                (click)="conditionItem.rating.value = 5"
                                [class.active]="
                                  conditionItem.rating.value === 5
                                "
                                class="emoji"
                                ><span>😍</span></span
                              >
                            </div>
                            <small
                              class="blockquote-footer"
                              *ngIf="
                                conditionItem.rating.description &&
                                conditionItem.rating.description !== ''
                              "
                              ><cite>{{
                                conditionItem.rating.description
                              }}</cite></small
                            >
                          </ng-container>
                          <!-- </div> -->
                          <!-- //likert question -->
                          <div
                            class="form-group mr-3 mb-0"
                            *ngIf="
                              conditionItem.LikertQuestions.values.length > 0
                            "
                          >
                            <!-- <h1>21</h1> -->
                            <div class="rule-condition-list">
                              <!-- <h11>innnnnn</h11> -->
                              <select [(ngModel)]="conditionItem.value" [ngModelOptions]="{ standalone: true }" class="custom-select form-control" (change)="onUserInfoFieldSelect3($event.target.value,ruleindex,conindex)" required="">
                                <option value="">Select Value</option>
                                <ng-container *ngFor="let item of conditionItem.LikertQuestions.values;index as i">
                                  <option [value]="item.value">{{ item.label }}</option>
                                </ng-container>
                              </select>
                            </div>
                          </div>
                          <div class="mr-3 mb-0">
                            <button type="button" class="customAddminus clone" (click)="addNewRow(ruleindex, conindex)"><i class="icon-plus"></i></button>
                            <button type="button" class="customAddminus delete" (click)="removeRow(ruleindex, conindex)"><i class="icon-minus"></i></button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </ng-container>
                </div>
                <span class="newRuleIfTxt newRuleThenTxt">Then</span>
              </div>
              <div class="actCondition">
                <span>Perform the following actions</span>
              </div>
              <div class="then-wrapper ruleActionWrapper">
                <div class="ruleAction">
                  <div class="d-flex align-items-center flex-wrap">
                    <!-- {{ruleitem.then_apply | json}} -->
                    <div class="d-flex flex-column w-100">
                      <ng-container
                        *ngFor="
                          let thenItem of ruleitem.then_apply;
                          let thenindex = index
                        "
                      >
                         
                            <!-- <div class="action-value-input">
                                                            <input type="text" class="form-control" [(ngModel)]="ruleitem.then_apply.value" required="" [ngModelOptions]="{standalone: true}">
                                                            
                                                        </div> -->
                          
                              <!-- <h1>22</h1> -->
                              <div class="form-group mr-3" *ngIf="thenindex == 0">
                                <!-- <label for="">Show/Hide</label> -->
                                <div class="rule-action-list">
                                  <!-- {{ruleitem.then.type[0].type|json}} -->
                                  <select
                                    class="custom-select form-control"
                                    [(ngModel)]="thenItem.type"
                                    [ngModelOptions]="{ standalone: true }"
                                    required=""
                                  >
                                    <option value="">
                                      Please Select
                                    </option>
                                    <ng-container
                                      *ngFor="let item of thenApply"
                                    >
                                      <option [value]="item">
                                        <!-- [selected]="item === ruleitem.show.type" -->

                                        {{ item }}
                                      </option>
                                    </ng-container>
                                  </select>
                                </div>
                              </div>
                              <div class="d-flex align-items-center mb-3">
                                <ng-container
                              *ngIf="Fields && Fields.length > 0 && !Break"
                            >
                            <div class="form-group mr-3 mb-0">
                              <!-- <label for="">Fields</label> -->
                              <select class="custom-select form-control" [(ngModel)]="thenItem.value" required="" (change)="onThenFieldSelect($event.target.value,ruleindex,thenindex)" [ngModelOptions]="{ standalone: true }">
                                <option value="">Select Fields</option>
                                <ng-container *ngFor="let item of thenItem.thenFinalValue; index as i">
                                  <option [value]="item.name" *ngIf="item.type != 'paragraph'">{{ item.label }}</option>
                                </ng-container>
                              </select>
                            </div>


                              <!-- groupfields without break-->
                              <div class="form-group mr-3 mb-0" *ngIf="thenItem.groupFields && thenItem.groupFields.length > 0 && !Break">
                              <!-- <h1>11</h1> -->
                              <!-- {{conditionItem.group_type}} -->
                                <select [(ngModel)]="thenItem.group_type" [ngModelOptions]="{ standalone: true }" class="custom-select form-control" (change)="onThenGroupFieldSelect($event.target.value,ruleindex,thenindex)" required="">
                                <!-- (change)="
                                onUserInfoFieldSelect3(
                                  $event.target.value,
                                  ruleindex,
                                  conindex
                                )
                              " -->
                                  <option [value]="">Select Fields</option>
                                  <ng-container *ngFor="let item of thenItem.groupFields;index as i">
                                    <option [value]="item.name" *ngIf="item.type != 'paragraph'">{{ item.label }}</option>
                                  </ng-container>
                                </select>
                              </div>

                            </ng-container>
                            <!-- // fields with Break -->
                            <ng-container *ngIf="Fields && Fields.length > 0 && Break">
                            <!-- <h1>Break</h1> -->
                            <div class="form-group mr-3 mb-0">
                              <select class="custom-select form-control" [(ngModel)]="thenItem.value" required="" (change)=" onThenFieldSelect($event.target.value,ruleindex,thenindex)" [ngModelOptions]="{ standalone: true }">
                                <option value="">Select Fields</option>
                                <ng-container *ngFor="let item of Fields; index as i">
                                  <ng-container *ngFor="let ite of item; index as j">
                                    <ng-container *ngIf="j == 0">
                                      <option value="" disabled="true">
                                        {{ ite.pageTitle }}
                                      </option>
                                    </ng-container>
                                    <ng-container *ngIf="j > 0">
                                      <option [value]="ite.name" [selected]="j == 0" *ngIf="ite.type != 'paragraph'">{{ ite.label }}</option>
                                    </ng-container>
                                  </ng-container>
                                </ng-container>
                              </select>
                            </div>
                            <!-- //Fiedls of group with break -->
                            <div class="form-group mr-3 mb-0" *ngIf="thenItem.groupFields && thenItem.groupFields.length > 0 && Break">
                            <!-- <h1>11</h1> -->
                            <!-- {{conditionItem.group_type}} -->
                              <select [(ngModel)]="thenItem.group_type" [ngModelOptions]="{ standalone: true }" class="custom-select form-control" (change)="onThenGroupFieldSelect($event.target.value,ruleindex,thenindex)" required="">
                                <option [value]="">Select Fields</option>
                                <ng-container *ngFor="let item of thenItem.groupFields; index as i">
                                    <option [value]="item.name" *ngIf="item.type != 'paragraph'">{{ item.label }}</option>
                                </ng-container>
                              </select>
                          </div>

                          </ng-container>
                          <!-- //address -->
                          <div class="form-group mr-3 mb-0" *ngIf="thenItem.AddressType.length > 0">
                            <ng-select
                              [items]="thenItem.AddressType"
                              bindLabel="label"
                              [bindValue]="name"
                              class="js-example-basic-multiple ng-select-control"
                              [(ngModel)]="thenItem.address_value"
                              placeholder="Select Address Group"
                              [multiple]="true"
                              [ngModelOptions]="{ standalone: true }"
                              required=""
                            ></ng-select>
                            <!-- (ngModelChange)="selectvalue($event,ruleindex)" -->
                          </div>
                              <!-- //likertquestion -->

                        <div class="d-flex align-items-center" *ngIf="thenItem.LikertQuestions.Questions.length > 0">
                        <!-- <h1>11</h1> -->
                        <!-- {{conditionItem.likertquestion}} -->
                        <div class="rule-condition-list d-flex align-items-center" *ngIf="thenItem.groupFields.length == 0">
                          <!-- //likert Questionsnames -->
                          <div class="form-group mr-3 mb-0">
                            <select
                              [(ngModel)]="thenItem.likertquestion"
                              [ngModelOptions]="{ standalone: true }"
                              class="custom-select form-control"
                              (change)="
                                onlikertThenoptions(
                                  $event.target.value,
                                  ruleindex,
                                  thenindex
                                )
                              "
                              required=""
                            >
                              <option value="">Select Question</option>
  
                              <ng-container
                                *ngFor="
                                  let item of thenItem.LikertQuestions
                                    .Questions;
                                  index as i
                                "
                              >
                                <option [value]="item.QuestionName">
                                  {{ item.Question }}
                                </option>
                              </ng-container>
                            </select>
                          </div>
                          <!-- likert question values -->
                          <div class="form-group mr-3 mb-0"
                            *ngIf="
                              thenItem.LikertQuestions.values.length > 0
                            "
                          >
                            <!-- <h1>21</h1> -->
                              <!-- <h11>innnnnn</h11> -->
                              <select
                                [(ngModel)]="thenItem.likertvalue"
                                [ngModelOptions]="{ standalone: true }"
                                class="custom-select form-control"
                                required=""
                              >
                                <!-- (change)="
                              onUserInfoFieldSelect3(
                                $event.target.value,
                                ruleindex,
                                conindex
                              )
                            " -->
                                <option value="">Select Value</option>

                                <ng-container
                                  *ngFor="
                                    let item of thenItem.LikertQuestions
                                      .values;
                                    index as i
                                  "
                                >
                                  <option [value]="item.value">
                                    {{ item.label }}
                                  </option>
                                </ng-container>
                              </select>
                          </div>
                        </div>

                        <!-- for Group -->
                        <div
                          class="rule-condition-list d-flex align-items-center"
                          *ngIf="thenItem.groupFields.length > 0"
                        >
                          <!-- <h1>group</h1> -->
                         <div class="form-group mr-3 mb-0">
                          <select
                          [(ngModel)]="thenItem.likertquestion"
                          [ngModelOptions]="{ standalone: true }"
                          class="custom-select form-control"
                          (change)="
                          onlikertThenoptionsGroup(
                              $event.target.value,
                              ruleindex,
                              thenindex
                            )
                          "
                          required=""
                        >
                          <option value="">Select Question</option>

                          <ng-container
                            *ngFor="
                              let item of thenItem.LikertQuestions
                                .Questions;
                              index as i
                            "
                          >
                            <option [value]="item.QuestionName">
                              {{ item.Question }}
                            </option>
                          </ng-container>
                        </select>
                         </div>
                          <!-- //likert ques values/ -->
                          <div class="form-group mr-3 mb-0" *ngIf="thenItem.LikertQuestions.values.length > 0">
                          <!-- <h1>21</h1> -->
                            <select [(ngModel)]="thenItem.likertvalue" [ngModelOptions]="{ standalone: true }" class="custom-select form-control" required="">
                              <!-- (change)="
                              onUserInfoFieldSelect3(
                              $event.target.value,
                              ruleindex,
                              conindex
                              )
                              " -->
                              <option value="">Select Value</option>
                              <ng-container *ngFor="let item of thenItem.LikertQuestions.values;index as i">
                                <option [value]="item.value">
                                  {{ item.label }}
                                </option>
                              </ng-container>
                            </select>
                        </div>
                        </div>
                          <!-- radio/select/decision bo fields -->
         
                      </div>
                          <!-- //radio/decision box/select -->
                          <div class="rule-condition-list d-flex align-items-center" *ngIf="thenItem.valforboxes.hasOwnProperty('values')">
                            <!-- <div class="rule-condition-list" *ngIf="conditionItem.valforboxes.length > 0 && conditionItem.constraint_id !='5' && conditionItem.constraint_id !='6' && conditionItem.constraint_id !='7' && conditionItem.constraint_id !='8' && conditionItem.constraint_id !='3' && conditionItem.constraint_id !='4'"> -->
                            <!-- <h1>11</h1> -->
                            <div class="form-group mr-3 mb-0">
                              <ng-select
                                [items]="thenItem.valforboxes.values"
                                bindLabel="label"
                                [bindValue]="value"
                                class="js-example-basic-multiple ng-select-control"
                                [(ngModel)]="thenItem.boxes_value"
                                placeholder="Select Options"
                                [multiple]="true"
                                [ngModelOptions]="{ standalone: true }"
                                required="">
                              </ng-select>
                            </div>
                            <!-- {{conditionItem.value | json}} -->
                          </div>
                          <div class="mr-3 mb-0">
                            <button type="button" class="customAddminus clone" (click)="addThenRow(ruleindex, thenindex)"><i class="icon-plus"></i></button>
                            <button type="button" class="customAddminus delete" (click)="removeThenRow(ruleindex, thenindex)"><i class="icon-minus"></i></button>
                          </div>
                        </div>
                      </ng-container>
                    </div>
                  </div>
                
                </div>
              </div>
            </div>
          </div>
    
          <div class="float-right" *ngIf="!ruleEdit">
            <button
              type="button"
              class="btn btn-danger mr-2"
              (click)="removeRule(ruleindex, ruleitem.id)"
            >
              Delete Rule
            </button>
            <button
              *ngIf="last"
              type="button"
              class="btn btn-primary mr-2"
              (click)="addNewRule()"
            >
              Add Rule
            </button>
          </div>
        </div>
      </ng-container>
      <div class="d-block mt-4" *ngIf="!hidesave">
        <!-- {{ruleEdit ? 'Update Rule' : 'Save Rules'}} -->
        <button
          type="button"
          class="btn btn-primary mr-2"
          (click)="saveFieldRule()"
        >
          Save Rules
        </button>
      </div>
      <!-- {{FieldForm |json}} -->
    </form>
  </div>
</div>
