<div class="row" >
    <div class="col-sm-12">
        <form class="needs-validation" novalidate [ngClass]="{ 'was-validated': validate }">
            <ng-container *ngFor="let pageRuleItem of allPageRules; let pageRuleIndex = index">
                <div class="ruleBordWrap">
                    <div class="rulesDup mt-2">
                        <ng-container *ngIf="pageRuleItem[pageNames[pageRuleIndex]] != undefined">
                            <input
                                type="text"
                                class="form-control"
                                placeholder="Rule Name"
                                required=""
                                [(ngModel)]="pageRuleItem[pageNames[pageRuleIndex]].rule_name"
                                [ngModelOptions]="{ standalone: true }"
                                (blur)="checkError($event, pageRuleItem[pageNames[pageRuleIndex]])"
                                />
                            <p *ngIf="pageRuleItem[pageNames[pageRuleIndex]].rule_name_error != undefined && pageRuleItem[pageNames[pageRuleIndex]].rule_name_error" class="text-danger">Rule name is required</p>
                            <div class="fieldRuleConditions">
                                <div class="ruleCont" style="margin: 33px 0 0 0">
                                    <div class="contDivInner">
                                        <span class="newRuleIfTxt">If</span>
                                        <div class="if-wrapper ifContainer fieldRuleIfCont">
                                            <div class="form-group">
                                                <select
                                                    class="custom-select form-control mr-3"
                                                    [(ngModel)]="pageRuleItem[pageNames[pageRuleIndex]].logical_oper"
                                                    required=""
                                                    [ngModelOptions]="{ standalone: true }"
                                                    >
                                                    <option
                                                        value="any"
                                                        [selected]="pageRuleItem[pageNames[pageRuleIndex]].logical_oper == 'any' || pageRuleItem[pageNames[pageRuleIndex]].logical_oper == ''"
                                                    >
                                                        Any
                                                    </option>
                                                    <option
                                                        value="all"
                                                        [selected]="pageRuleItem[pageNames[pageRuleIndex]].logical_oper == 'all'"
                                                    >
                                                        All
                                                    </option>
                                                </select>
                                                <label for="">of the following conditions are met</label>
                                            </div>
                                            <ng-container *ngIf="pageRuleItem[pageNames[pageRuleIndex]]?.conditions?.length > 0; else setNewConditions">
                                                <h3>{{ formBuilder?.attributes[pageRuleIndex][pageIndex]?.pageTitle == '' || formBuilder?.attributes[pageRuleIndex][pageIndex]?.pageTitle == null ? 'Page' + (pageRuleItem[pageNames[pageRuleIndex]]?.on_page_index + 1) : formBuilder?.attributes[pageRuleIndex][pageIndex]?.pageTitle }}</h3>
                                                <div class="fieldRow my-2 py-2">
                                                    <div class="col-sm-12">
                                                        <ng-container *ngFor="let conditionItem of pageRuleItem[pageNames[pageRuleIndex]]?.conditions; let ci = index">
    
                                                            <div class="d-flex align-items-center py-1">
                                                                <div class="form-group mr-3 mb-0">
                                                                  <div class="user-info-dropdown flLeft commonBdr">
                                                                        <select class="custom-select form-control" (change)="onFormFieldSelect($event.target.value, conditionItem, pageRuleIndex, ci)" [ngModelOptions]="{ standalone: true }" [(ngModel)]="conditionItem.field_name">
                                                                            <option value="">Select fields</option>
                                                                            <ng-container *ngFor="let fieldItem of formBuilder?.attributes[pageRuleItem[pageNames[pageRuleIndex]]?.on_page_index]">
                                                                                <option [selected]="conditionItem.field_name == fieldItem?.name" [value]="fieldItem?.name" *ngIf="fieldItem?.type != 'break'">{{ fieldItem?.label }}</option>
                                                                            </ng-container>
                                                                        </select>

                                                                        <ng-container *ngIf="conditionItem?.groupFields">
                                                                            <select class="custom-select form-control ml-3" (change)="onGroupFieldSelect($event.target.value, conditionItem, pageRuleIndex, ci)" [ngModelOptions]="{ standalone: true }" [(ngModel)]="conditionItem.group_field_name">
                                                                                <option value="">Select Fields</option>
                                                                                <ng-container *ngFor="let gitem of conditionItem?.groupFields">
                                                                                    <option [value]="gitem?.name">{{ gitem.label }}</option>
                                                                                </ng-container>
                                                                            </select>
                                                                        </ng-container>
                                                                  </div>
                                                                </div>

                                                                  <ng-container *ngIf="conditionItem?.operands?.length > 0">
                                                                    <div class="form-group mr-3 mb-0">
                                                                        <div class="rule-condition-list">
                                                                            <select class="custom-select form-control" (change)="onConditionSelect($event, pageRuleItem[pageNames[pageRuleIndex]]?.conditions, ci, conditionItem)" [(ngModel)]="conditionItem.comparison_oper" [ngModelOptions]="{ standalone: true }">
                                                                                <option value="">Select Condition</option>
                                                                                <ng-container *ngFor="let conditionOper of conditionItem?.operands">
                                                                                    <option [selected]="conditionItem.comparison_oper == conditionOper?.name" [value]="conditionOper?.id">{{ conditionOper?.name }}</option>
                                                                                </ng-container>
                                                                            </select>
                                                                        </div>
                                                                    </div>
                                                                  </ng-container>
                                                                <div class="form-group mr-2 mb-0" *ngIf="conditionItem.comparison_oper != 3 && conditionItem.comparison_oper != 4">
                                                                    <div class="condition-value-input">
                                                                        <!-- {{conditionItem | json}} -->
                                                                        <ng-container *ngIf="conditionItem?.field_type == 'group'; else normalFields">
                                                                            <ng-container *ngIf="conditionItem?.group_field_type == 'date' || conditionItem?.group_field_type == 'datetime-local' || conditionItem?.group_field_type == 'time'">
                                                                                <!-- DATE & TIME FAMILY -->
                                                                                <input
                                                                                [type]="conditionItem?.group_field_type
                                                                                "
                                                                                class="form-control"
                                                                                placeholder="Conditions Value"
                                                                                [ngModelOptions]="{ standalone: true }"
                                                                                required=""
                                                                                [(ngModel)]="conditionItem.value"
                                                                                (blur)="conditionCheck($event, conditionItem.comparison_oper, conditionItem)"
                                                                            />
                                                                            </ng-container>
                                                                            <ng-container *ngIf="conditionItem?.group_field_type == 'checkbox' || conditionItem?.group_field_type == 'autocomplete' || conditionItem?.group_field_type == 'radio'">
                                                                                <!-- CHECKBOX, RADIO AND SELECT DROPDOWN -->
                                                                                <ng-container *ngIf="conditionItem?.group_field_type == 'checkbox' || conditionItem?.group_field_type == 'autocomplete'">
                                                                                    <ng-select [items]="conditionItem?.options" class="js-example-basic-multiple" placeholder="Select" [(ngModel)]="conditionItem.value" [multiple]="true" [ngModelOptions]="{standalone: true}"></ng-select>
                                                                                </ng-container>
                                                                                <ng-container *ngIf="conditionItem?.group_field_type == 'radio'">
                                                                                    <ng-select [items]="conditionItem?.options" [(ngModel)]="conditionItem.value" bindLabel="label" bindValue="value" placeholder="Select Option" [ngModelOptions]="{standalone: true}"></ng-select>
                                                                                </ng-container>
                                                                            </ng-container>
                                                                            <ng-container *ngIf="conditionItem?.group_field_type != 'radio' && conditionItem?.group_field_type != 'checkbox' && conditionItem?.group_field_type != 'autocomplete' && conditionItem?.group_field_type != 'date' && conditionItem?.group_field_type != 'datetime-local' && conditionItem?.group_field_type != 'time' && conditionItem?.group_field_type != 'file'">
                                                                                <!-- NORMAL INPUT BOX FIELDS -->
                                                                                <input
                                                                                type="text"
                                                                                class="form-control"
                                                                                placeholder="Conditions Value"
                                                                                [ngModelOptions]="{ standalone: true }"
                                                                                required=""
                                                                                [(ngModel)]="conditionItem.value"
                                                                                (blur)="conditionCheck($event, conditionItem.comparison_oper, conditionItem)"
                                                                            />
                                                                            </ng-container>
                                                                        </ng-container>
                                                                        <ng-template #normalFields>
                                                                            <ng-container *ngIf="conditionItem?.field_type == 'date' || conditionItem?.field_type == 'datetime-local' || conditionItem?.field_type == 'time'">
                                                                                <!-- DATE & TIME FAMILY -->
                                                                                <input
                                                                                [type]="conditionItem?.field_type"
                                                                                class="form-control"
                                                                                placeholder="Conditions Value"
                                                                                [ngModelOptions]="{ standalone: true }"
                                                                                required=""
                                                                                [(ngModel)]="conditionItem.value"
                                                                                (blur)="conditionCheck($event, conditionItem.comparison_oper, conditionItem)"
                                                                            />
                                                                            </ng-container>
                                                                            <ng-container *ngIf="conditionItem?.field_type == 'checkbox' || conditionItem?.field_type == 'autocomplete' || conditionItem?.field_type == 'radio'">
                                                                                <!-- CHECKBOX, RADIO AND SELECT DROPDOWN -->
                                                                                <ng-container *ngIf="conditionItem?.field_type == 'checkbox' || conditionItem?.field_type == 'autocomplete'">
                                                                                    <ng-select [items]="conditionItem?.options" class="js-example-basic-multiple" placeholder="Select" [(ngModel)]="conditionItem.value" [multiple]="true" [ngModelOptions]="{standalone: true}"></ng-select>
                                                                                </ng-container>
                                                                                <ng-container *ngIf="conditionItem?.field_type == 'radio'">
                                                                                    <ng-select [items]="conditionItem?.options" [(ngModel)]="conditionItem.value" bindLabel="label" bindValue="value" placeholder="Select Option" [ngModelOptions]="{standalone: true}"></ng-select>
                                                                                </ng-container>
                                                                            </ng-container>
                                                                            <ng-container *ngIf="conditionItem?.field_type != 'radio' && conditionItem?.field_type != 'checkbox' && conditionItem?.field_type != 'autocomplete' && conditionItem?.field_type != 'date' && conditionItem?.field_type != 'datetime-local' && conditionItem?.field_type != 'time' && conditionItem?.field_type != 'file'">
                                                                                <!-- NORMAL INPUT BOX FIELDS -->
                                                                                <input
                                                                                type="text"
                                                                                class="form-control"
                                                                                placeholder="Conditions Value"
                                                                                [ngModelOptions]="{ standalone: true }"
                                                                                required=""
                                                                                [(ngModel)]="conditionItem.value"
                                                                                (blur)="conditionCheck($event, conditionItem.comparison_oper, conditionItem)"
                                                                            />
                                                                            </ng-container>
                                                                        </ng-template>
                                                                    </div>
                                                                </div>
                                                                
                                                                <div class="mr-3 mb-0">
                                                                    <button
                                                                      type="button"
                                                                      class="customAddminus clone"
                                                                      (click)="addNewRow(pageRuleIndex)"
                                                                    >
                                                                      <i class="icon-plus"></i>
                                                                    </button>
                                                                    <button
                                                                      type="button"
                                                                      class="customAddminus delete"
                                                                      (click)="removeRow(pageRuleItem[pageNames[pageRuleIndex]], ci)"
                                                                    >
                                                                      <i class="icon-minus"></i>
                                                                    </button>
                                                                </div>
                                                              </div>
                                                              <p *ngIf="conditionItem.condition_error != undefined && conditionItem.condition_error" class="text-danger">
                                                                Conditions is invalid
                                                            </p>
                                                        </ng-container>
                                                    </div>
                                                </div>
                                            </ng-container>
                                        </div>
                                        <span class="newRuleIfTxt newRuleThenTxt">Then</span>
                                    </div>
                                    <div class="actCondition">
                                        <span>Perform the following actions</span>
                                    </div>
                                    <div class="then-wrapper ruleActionWrapper">
                                        <div class="ruleAction">
                                            <div class="d-flex align-items-center">
                                                <div class="form-group mr-3 mb-0">
                                                    <label for="">Skip To</label>
                                                    <div class="rule-action-list">
                                                        <select class="custom-select form-control" [ngModelOptions]="{ standalone: true }" required="" (change)="skipPageSelect($event, pageRuleIndex, pageNames[pageRuleIndex])">
                                                            <option value="">Select Pages</option>
                                                            <ng-container *ngFor="let nextPage of formBuilder?.attributes let ni = index">
                                                                
                                                                <ng-container *ngIf="nextPage[0] != undefined && ni > pageRuleItem[pageNames[pageRuleIndex]]?.on_page_index">
                                                                    <option [selected]="(pageRuleItem[pageNames[pageRuleIndex]]?.to_page_link - 1) == ni" [value]="(ni + 1)">
                                                                        {{(nextPage[0]?.pageTitle == '' || nextPage[0]?.pageTitle == null) ? 'Page'+(ni + 1) : nextPage[0]?.pageTitle}}
                                                                    </option>
                                                                </ng-container>
                                                            </ng-container>
                                                        </select>
                                                        <p *ngIf="pageRuleItem[pageNames[pageRuleIndex]].to_page_link_error != undefined && pageRuleItem[pageNames[pageRuleIndex]].to_page_link_error" class="text-danger">Go to page is required</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </ng-container>
                    </div>
                    <button type="button" class="btn btn-danger" (click)="ruleDelete(pageRuleIndex)">Delete Rule</button>
                </div>
            </ng-container>
            <button type="button" class="btn btn-primary" (click)="saveRule()" *ngIf="allPageRules?.length > 0">Save Rule</button>
        </form>
    </div>
</div>
