import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { Subject } from 'rxjs';
import { FormsService } from 'src/app/components/forms/forms.service';
import Swal from 'sweetalert2';
import { takeUntil } from 'rxjs/operators';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CommonService } from '../../services/common.service';

@Component({
  selector: 'app-page-rule',
  templateUrl: './page-rule.component.html',
  styleUrls: ['./page-rule.component.scss']
})
export class PageRuleComponent implements OnInit, OnChanges {
  @Input() Fields: any; // decorate the property with @Input()
  @Input() form_id:any
@Input() pageRuleData:any[]=[]
@Input() pageRuleObj:any;
@Input() Break: boolean;
@Input() pagenumber:any
@Input() formPages:any
@Output() FieldPageRuleShow = new EventEmitter();

  constructor(private formService:FormsService,
    private modalService: NgbModal,
    private common:CommonService
    
    ) { }
  validate = false;
  Condition_Show:any =[]
  FieldConditionList:any=[]
  hidesave:boolean=false
  destroy$: Subject<boolean> = new Subject<boolean>();
  pagerulepopupshow: boolean;

  pageRules: any = {};

  result:any
  // PageForm = [
  //   {
  //     "rule_name": "",
  //     "if_condition": {
  //       "allow": "any",

  //       "conditions": [{
  //         "PageFields":[],
  //         "pagenumberArr":[],
  //         "page_number":"",
  //         "skippagenumberarray":[],
  //         "field_type": "",
  //         'group_type':"",
  //         'likertquestion':"",
  //         "constraint": "Is",
  //         "constraint_id": 1,
  //         "value": "",
  //         "showValueBox": true,
  //         'FieldConstraint':[],
  //        'conditiontype':'text',
  //        'FileEmpt':false,
  //     'valforboxes':{},
  //     'AddressType':[],
  //     "groupFields":[],
  //     'multiplecheckbox':[],
  //     'multiplevalue':[],
  //     "multipleboxes":[],
  //     'rating':{
  //       "accept":'',
  //       "value":'',
  //       "size":'',
  //       "description":'',
  //       "arrsize":[]
  //     },
  //     'LikertQuestions':{
  //       "Questions":[],
  //       "values":[]
  //     }

         
  //       }]
  //     },
  //     // "show":{
  //     //   "type": "Show",

  //     // },
  //     "then_apply": {
  //       "type": "",
  //       "value": [],
  //       "multinewarr2":[],
       

  //     },
    
      
  //   }
  // ]
  PageForm = [
    {
      "rule_name": "",
      "if_condition": {
        "allow": "any",
        "PageFields2":[],
        "skippagenumberarray2":[],
        // "PageTitle":"",
        "conditions": [{
          "PageTitle":"",
          "PageFields":[],
          "pagenumberArr":[],
          "page_number":"",
          "skippagenumberarray":[],
          "field_type": "",
          'group_type':"",
          'likertquestion':"",
          "constraint": "Is",
          "constraint_id": 1,
          "value": "",
          "showValueBox": true,
          'FieldConstraint':[],
         'conditiontype':'text',
         'FileEmpt':false,
      'valforboxes':{},
      'AddressType':[],
      "groupFields":[],
      'multiplecheckbox':[],
      'multiplevalue':[],
      "multipleboxes":[],
      'rating':{
        "accept":'',
        "value":'',
        "size":'',
        "description":'',
        "arrsize":[]
      },
      'LikertQuestions':{
        "Questions":[],
        "values":[]
      }

         
        }]
      },
      // "show":{
      //   "type": "Show",

      // },
      "then_apply": {
        "type": "",
        "value": [],
        "multinewarr2":[],
       

      },
    
      
    }
  ]
  conditionRows = [
    {
      "PageFields":[],
      "PageTitle":"",

      "pagenumberArr":[],
      "page_number":"",
      "skippagenumberarray":[],
      "field_type": "",
      'group_type':"",
      'likertquestion':"",
      "constraint": "Is",
      "constraint_id": 1,
      "value": "",
      "showValueBox": true,
      'FieldConstraint':[],
     'conditiontype':'text',
     'FileEmpt':false,
  'valforboxes':{},
  'AddressType':[],
  "groupFields":[],
  'multiplecheckbox':[],
  'multiplevalue':[],
  "multipleboxes":[],
  'rating':{
    "accept":'',
    "value":'',
    "size":'',
    "description":'',
    "arrsize":[]
  },
  'LikertQuestions':{
    "Questions":[],
    "values":[]
  }

     
    }
  ];
  formBuilder: any[] = []
  // pageselect:any=[]
  pagenumberArr=[]
  // newarrreferences:any=[]
  ngOnInit(): void {
    // alert('hit')
    this.common.getpagerule().subscribe((pageval) =>{
    // console.log(pageval,'pageval')
      if(pageval.length>0){
        pageval.forEach((page,i)=>{
          // console.log(i,'iii',i>1)
          if(i>=1){
            // console.log(this.PageForm,'ffffffffffffffff===========')
            this.addNewRule()
          }
          
        this.PageSelection2(page,i)
        })
         }
      }
    )
    // alert(this.pagerulepopupshow)
 console.log(this.pagenumber,'numberrrrrrrrrrrrrr')
console.log(this.pageRuleData,'ruleeeeeeeeeeeeeeeeeeeee')
    if(this.pageRuleData.length>0){

      this.PageForm=this.pageRuleData
     }
    this.handleDiscountRuleConditions()
  // console.log(this.Fields,'ff');
    this.Condition_Show=true

console.log(this.PageForm,'PageFormmmmm')

this.pagenumberArr.splice(-1)
console.log(this.pagenumberArr,'prrsadasrrr',)
// console.log(this.newarrreferences,'newarrreferences',)
this.PageForm[0].if_condition.conditions[0].pagenumberArr=this.pagenumberArr
  // console.log(this.PageForm,'prrrrr')

  // console.log('THE PAGE RULE OBJ UPDATE', this.pageRuleObj)
  }

  ngOnChanges(changes: SimpleChanges): void {
    console.log(this.pageRuleObj, 'CHANGE DETECT', changes)
    this.pageRules = {...this.pageRuleObj, ...this.pageRules}

    console.log('UPDATED RULE OBBBBBBBJJJJ', this.pageRules)
    this.formBuilder = this.formPages;
  }
 

  
    PageRuleDisplay(content){
      // this.PageRule=true
      // this.pagerulepopupshow=true
    // console.log('lllllllllllllllllllllllllllllllllllllllllllllllllllllllll')
      // this.common.setpagerule(true)
        this.openCreateEmailForm(content);
  
    }
    openCreateEmailForm(content2) {
      // alert('hit')
        // console.log(this.user_details, "On Open Details")
        this.modalService.open(content2, { centered: true });
      
        // modalRef.result.then((data) => {
          // this.BulkEmailForm.reset();
        // }, (reason) => {
          // this.BulkEmailForm.reset();
        // });
      
      }
  handleDiscountRuleConditions() {
    this.formService.getDiscountRuleConditionsData().subscribe((result: any) => {
      
      if(result['status']) {
        this.FieldConditionList = result['data'];
        this.PageForm[0].if_condition.conditions[0].FieldConstraint=result['data'];
        // this.parllel_fiel_value.push
        // this.parllel_fiel_value=result['data'].forEach(())
        // this.conditionRows[0].constraint = this.FieldConditionList[0].name;
        // this.conditionRows[0].constraint_id = this.FieldConditionList[0].id;
      }
    })
  }
  PageSelection2(val,ind){
    this.Fields.forEach((field) =>{
      field.forEach((field2) =>{

  if(field2.name ==val){
    this.PageForm[ind].if_condition.PageFields2=field
    this.PageForm[ind].if_condition.conditions[0].PageTitle=field[0].pageTitle
  }
  
      })
      })
      this.Fields.forEach((fields,i)=>{
        fields.forEach((field2,j)=>{
        if( j ==0){
          this.pagenumberArr.push(field2)
          this.PageForm[ind].if_condition.skippagenumberarray2.push(field2)
        }
        })
        
        })
      //skip to pages
      this.PageForm[ind].if_condition.skippagenumberarray2.forEach((pagenumber,i) =>{
        if(pagenumber.name ==val){
         this.PageForm[ind].then_apply.value= this.PageForm[ind].if_condition.skippagenumberarray2.filter((pagenumber2,j)=>{
          return j>i
         })
  
        }
      })
    // console.log(this.PageForm,'pageselect');
    // console.log(this.PageForm,'ppppppfff');
      
  }
  PageSelection(val,ind,ind2){
  // console.log(val,'val')
  // console.log(this.Fields,'Fields');
    
    this.Fields.forEach((field) =>{
    field.forEach((field2) =>{
      if(field2.type !='group'){
  this.PageForm[ind].if_condition.conditions[ind2].groupFields=[]

      }
if(field2.name ==val){
  this.PageForm[ind].if_condition.conditions[ind2].PageFields=field
}

    })
    })
  // console.log(this.pagenumberArr,'rrrpppp');
    
    this.PageForm[ind].if_condition.conditions[ind2].skippagenumberarray.forEach((pagenumber,i) =>{
      if(pagenumber.name ==val){
       this.PageForm[ind].then_apply.value= this.PageForm[ind].if_condition.conditions[ind2].skippagenumberarray.filter((pagenumber2,j)=>{
        return j>i
       })

      }
    })
  // console.log(this.PageForm,'pageselect');
    
  }
  removeRow(discountRuleFormIndex: number, index: number) { 
    this.PageForm[discountRuleFormIndex].if_condition.conditions.splice(index, 1);
  }
  addNewRow(ruleindex,index2) {
  // console.log(this.FieldConditionList,'fffffffffllllllll');
    
    if(this.Fields !== undefined && this.Fields.length > 0) {
      this.PageForm[ruleindex].if_condition.conditions.push({
        "PageFields":[],
        "PageTitle":"",
        "pagenumberArr":this.PageForm[ruleindex].if_condition.conditions[index2].pagenumberArr,
        "page_number":"",
        "skippagenumberarray":this.PageForm[ruleindex].if_condition.conditions[index2].skippagenumberarray,
        "field_type": "",
        'group_type':"",
        'likertquestion':"",
        "constraint": "Is",
        "constraint_id": 1,
        "value": "",
        "showValueBox": true,
        'FieldConstraint':[],
       'conditiontype':'text',
       'FileEmpt':false,
    'valforboxes':{},
    'AddressType':[],
    "groupFields":[],
    'multiplecheckbox':[],
    'multiplevalue':[],
    "multipleboxes":[],
    'rating':{
      "accept":'',
      "value":'',
      "size":'',
      "description":'',
      "arrsize":[]
    },
    'LikertQuestions':{
      "Questions":[],
      "values":[]
    }

       
      });
      // console.log(this.PageForm,'fffooo')
      // this.FieldConditionList.push(this.FieldConditionList)
      // console.log(this.FieldConditionList,'FFF')
    } 
   
  }
  addNewRule() {
    this.conditionRows['FieldConstraint']=this.FieldConditionList,
    this.PageForm.push({
        "rule_name": "",
        "if_condition": {
        "PageFields2":[],
        "skippagenumberarray2":[],

          "allow": "any",
          "conditions": this.conditionRows,


        },
        // "show":{
        //   "type": "Show",
  
        // },
        "then_apply": {
          "type": "",
          "value": [],
          "multinewarr2":[],
         
  
        },
        
      
        
    });
    // console.log(this.PageForm,'ffff99900000')
  }
  removeRule(index,id) {
  // console.log(index,'piddd',id);
    
    if(index ==0){
      this.hidesave=true
      
    }//for delete rules
    this.FieldPageRuleShow.emit(true)
    // console.log(this.PageForm,'aaaaaaaaaaaaaaaaaaaaa');
    
    this.PageForm.splice(index, 1);
this.common.setIndex(index)
    // let id={}
    
    if(id !=undefined) {
      this.formService
      .DeletePageRules(id)
      .pipe(takeUntil(this.destroy$))
      .subscribe((result) => {
        this.result = result;
    
      });

    }
  }
  onlikertoptionsGroup(val,ind,ind2){
  // console.log(this.Fields,'bbfieldssss  ')
  if(!this.Break){
    let fieldarr= this.Fields.find((item)=>{
      return item.type=='group'
          })
        // console.log(fieldarr,'rrr')
        // console.log(val,"valwues")
          let newone =[]
          let newone2 =fieldarr.groupFields.forEach((item)=>{
            item.QuestionArr.find((ite)=>{
              if (ite.QuestionName ==val){
                newone.push(ite)
              }
            })
            // return item.QuestionName==val
                })
  // console.log(newone,'likert')
this.PageForm[ind].if_condition.conditions[ind2].LikertQuestions.values=newone[0].values
// console.log(this.PageForm,'fiedlikert')

  }
  else{    
  // console.log(this.Fields,'fieldssss  ')
    let newone2 =[]

    this.Fields.forEach((fields)=>{
      fields.forEach((item)=>{
       if(item.type=='group'){
        item.groupFields.find((ite=>{
if(ite.type=='likert'){
  // newone2.push(ite);
// console.log(ite,'ite')
  // ite.groupFields.forEach((field)=>{
    ite.QuestionArr.find((ite)=>{
      if (ite.QuestionName ==val){
        newone2.push(ite)
      }
    })
  // })
}
        }))
       }
            })
    
     
    })
  // console.log(newone2,'newoneoo22')     

  // let newone =[]
  // // console.log(val,'valllllll')   
  //    newone =newone2.groupFields.forEach((item)=>{
  //     item.QuestionArr.find((ite)=>{
  //       if (ite.QuestionName ==val){
  //         newone.push(ite)
  //       }
  //     })
    //   // return item.QuestionName==val
    //       })
    // newone2[0].QuestionArr.find((item)=>{
    //    if(item.QuestionName ==val){
    //   return newone.push(item)
    //    }
    // // console.log(item,'item',item.QuestionName == val)
    // })
    // console.log(newone,'newoneoo')     

    this.PageForm[ind].if_condition.conditions[ind2].LikertQuestions.values=newone2[0].values



// //         
// console.log(this.PageForm,'fiedlikert')
  }


  }
  onUserInfoFieldSelect(val,ind,ind2){
  // console.log('val',val,'ind===',ind,'ind2==',ind2) 
    // if-else for conditions
    if(val.includes('file')){
       this.PageForm[ind].if_condition.conditions[ind2].FieldConstraint= this.FieldConditionList.filter((field) =>{ return field.name =='Is Empty'||field.name =='Is Not Empty'})
       this.PageForm[ind].if_condition.conditions[ind2].FileEmpt=true
      //  val.preventDefault();
      //// console.log('FileEmpt',this.PageForm)
 // console.log(this.PageForm,'ffffuuffee')
      }else if (val.includes('rating')){
       this.PageForm[ind].if_condition.conditions[ind2].FieldConstraint= this.FieldConditionList.filter((field) =>{ return field.name =='Is'||field.name =='Is Not'||field.name =='Is Empty'||field.name =='Is Not Empty'})

      }
        else if (val.includes('range')){
          this.PageForm[ind].if_condition.conditions[ind2].FieldConstraint= this.FieldConditionList.filter((field) =>{ return field.name =='Is'||field.name =='Is Not'||field.name =='Is Empty'||field.name =='Is Not Empty'})
  
        }
       
      else{
        this.PageForm[ind].if_condition.conditions[ind2].FieldConstraint=this.FieldConditionList
      // console.log(this.PageForm,'fielddorm');
        
      }
    // if-else for conditions values

   if(val.includes('date') && !val.includes('datetime')){
    this.PageForm[ind].if_condition.conditions[ind2].valforboxes={}
    this.PageForm[ind].if_condition.conditions[ind2].AddressType=[]
    this.PageForm[ind].if_condition.conditions[ind2].multiplecheckbox=[]
    this.PageForm[ind].if_condition.conditions[ind2].rating.accept=''
    this.PageForm[ind].if_condition.conditions[ind2].rating.value=''
    this.PageForm[ind].if_condition.conditions[ind2].rating.size=''
    this.PageForm[ind].if_condition.conditions[ind2].rating.description=''
    this.PageForm[ind].if_condition.conditions[ind2].groupFields=[]
    this.PageForm[ind].if_condition.conditions[ind2].LikertQuestions.Questions=[]
    this.PageForm[ind].if_condition.conditions[ind2].LikertQuestions.values=[]

    this.PageForm[ind].if_condition.conditions[ind2].conditiontype='date'
    this.PageForm[ind].if_condition.conditions[ind2].FieldConstraint= this.FieldConditionList.filter((field) =>{ return field.name =='Is'||field.name =='Is Not'||field.name =='Is Empty'||field.name =='Is Not Empty'})
  // console.log(this.PageForm,"ffff")

   }else if(val.includes('datetime')){
    this.PageForm[ind].if_condition.conditions[ind2].valforboxes={}
    this.PageForm[ind].if_condition.conditions[ind2].AddressType=[]
    this.PageForm[ind].if_condition.conditions[ind2].multiplecheckbox=[]
    this.PageForm[ind].if_condition.conditions[ind2].rating.accept=''
    this.PageForm[ind].if_condition.conditions[ind2].rating.value=''
    this.PageForm[ind].if_condition.conditions[ind2].rating.size=''
    this.PageForm[ind].if_condition.conditions[ind2].rating.description=''
    this.PageForm[ind].if_condition.conditions[ind2].groupFields=[]
    this.PageForm[ind].if_condition.conditions[ind2].LikertQuestions.Questions=[]
    this.PageForm[ind].if_condition.conditions[ind2].LikertQuestions.values=[]

    this.PageForm[ind].if_condition.conditions[ind2].conditiontype='datetime-local'
    this.PageForm[ind].if_condition.conditions[ind2].FieldConstraint= this.FieldConditionList.filter((field) =>{ return field.name =='Is'||field.name =='Is Not'||field.name =='Is Empty'||field.name =='Is Not Empty'})

   }else if(val.includes('time')){
    this.PageForm[ind].if_condition.conditions[ind2].valforboxes={}
    this.PageForm[ind].if_condition.conditions[ind2].AddressType=[]
    this.PageForm[ind].if_condition.conditions[ind2].multiplecheckbox=[]
    this.PageForm[ind].if_condition.conditions[ind2].rating.accept=''
    this.PageForm[ind].if_condition.conditions[ind2].rating.value=''
    this.PageForm[ind].if_condition.conditions[ind2].rating.size=''
    this.PageForm[ind].if_condition.conditions[ind2].rating.description=''
    this.PageForm[ind].if_condition.conditions[ind2].groupFields=[]
    this.PageForm[ind].if_condition.conditions[ind2].LikertQuestions.Questions=[]
    this.PageForm[ind].if_condition.conditions[ind2].LikertQuestions.values=[]

    this.PageForm[ind].if_condition.conditions[ind2].conditiontype='time'
    this.PageForm[ind].if_condition.conditions[ind2].FieldConstraint= this.FieldConditionList.filter((field) =>{ return field.name =='Is'||field.name =='Is Not'||field.name =='Is Empty'||field.name =='Is Not Empty'})

   }
   else if(val.includes('checkbox')){

  // console.log(this.Fields,'ggyyy22')
  
  // console.log(this.PageForm,'bbbfields');
    // newone.forEach((item)=>{

      this.PageForm[ind].if_condition.conditions[ind2].AddressType=[]
      this.PageForm[ind].if_condition.conditions[ind2].multiplecheckbox=[]
      this.PageForm[ind].if_condition.conditions[ind2].rating.accept=''
      this.PageForm[ind].if_condition.conditions[ind2].rating.value=''
      this.PageForm[ind].if_condition.conditions[ind2].rating.size=''
      this.PageForm[ind].if_condition.conditions[ind2].rating.description=''
      this.PageForm[ind].if_condition.conditions[ind2].groupFields=[]
      this.PageForm[ind].if_condition.conditions[ind2].LikertQuestions.Questions=[]
      this.PageForm[ind].if_condition.conditions[ind2].LikertQuestions.values=[]
      // this.PageForm[ind].if_condition.conditions[ind2].FieldConstraint=this.FieldConditionList

console.log(this.PageForm,'ffff11)')
if(!this.Break){
  let newone= this.Fields.find((item)=>{
    return item.name==val
        })
        this.PageForm[ind].if_condition.conditions[ind2].valforboxes=newone
}else{
  // console.log('val',val)
  let newone =[]
this.Fields.forEach((fields)=>{
fields.filter((item)=>{
// console.log(item,'item')
 if(item.name==val){
   return newone.push(item)
 }
 
})          
})
console.log(newone,'fieldsnewonebreak')
// this.PageForm[ind].if_condition.conditions[ind2].valforboxes=newone
   //only for one time loop
   newone.forEach((item)=>{
    this.PageForm[ind].if_condition.conditions[ind2].valforboxes=item

  })
console.log(this.PageForm[ind].if_condition.conditions[ind2].valforboxes.hasOwnProperty('values'),'fasdasdaieldin decedsiones')

}

   }
   else if (val.includes('rating')){
  
      this.PageForm[ind].if_condition.conditions[ind2].groupFields=[]
      this.PageForm[ind].if_condition.conditions[ind2].valforboxes={}
      this.PageForm[ind].if_condition.conditions[ind2].AddressType=[]
      this.PageForm[ind].if_condition.conditions[ind2].multiplecheckbox=[]
   
      this.PageForm[ind].if_condition.conditions[ind2].groupFields=[]
      this.PageForm[ind].if_condition.conditions[ind2].LikertQuestions.Questions=[]
      this.PageForm[ind].if_condition.conditions[ind2].LikertQuestions.values=[]
if(!this.Break){
  let newone= this.Fields.find((item)=>{
    return item.name==val
        })
      // console.log(newone,'newone');
        this.PageForm[ind].if_condition.conditions[ind2].rating.accept=newone.accept
        this.PageForm[ind].if_condition.conditions[ind2].rating.value=newone.value
        this.PageForm[ind].if_condition.conditions[ind2].rating.size=newone.size
        this.PageForm[ind].if_condition.conditions[ind2].rating.description=newone.description
}else{
  let newone =[]

  this.Fields.forEach((fields)=>{
    fields.filter((item)=>{
    // console.log(item,'item')
    if(item.name==val){
      return newone.push(item)
     }
     
    })    
  // console.log(newone,'newone')     
  // console.log(this.PageForm,'ffdd') 
    this.PageForm[ind].if_condition.conditions[ind2].rating.accept=newone[0].accept
    this.PageForm[ind].if_condition.conditions[ind2].rating.value=newone[0].value
    this.PageForm[ind].if_condition.conditions[ind2].rating.size=newone[0].size
    this.PageForm[ind].if_condition.conditions[ind2].rating.description=newone[0].description
    })
}




  // console.log(this.PageForm,'fgfgfgfg');
    
          
   }
   else if(val.includes('radio')){
 
  

    this.PageForm[ind].if_condition.conditions[ind2].AddressType=[]
    this.PageForm[ind].if_condition.conditions[ind2].multiplecheckbox=[]
    this.PageForm[ind].if_condition.conditions[ind2].rating.accept=''
    this.PageForm[ind].if_condition.conditions[ind2].rating.value=''
    this.PageForm[ind].if_condition.conditions[ind2].rating.size=''
    this.PageForm[ind].if_condition.conditions[ind2].rating.description=''
    this.PageForm[ind].if_condition.conditions[ind2].groupFields=[]
    this.PageForm[ind].if_condition.conditions[ind2].LikertQuestions.Questions=[]
    this.PageForm[ind].if_condition.conditions[ind2].LikertQuestions.values=[]
    // this.PageForm[ind].if_condition.conditions[ind2].FieldConstraint=this.FieldConditionList

console.log(this.PageForm,'ffff11)')
if(!this.Break){
  let newone= this.Fields.find((item)=>{
    return item.name==val
        })
        this.PageForm[ind].if_condition.conditions[ind2].valforboxes=newone
}else{
  let newone =[]

  this.Fields.forEach((fields)=>{
    fields.filter((item)=>{
    // console.log(item,'item')
    if(item.name==val){
      return newone.push(item)
     }
     
    })    
  // console.log(newone,'newone')     
  // console.log(this.PageForm,'ffdd') 
    //only for one time loop
    newone.forEach((item)=>{
      this.PageForm[ind].if_condition.conditions[ind2].valforboxes=item

    })
    })
}

   }
   else if(val.includes('autocomplete')){
  // console.log(this.Fields,'ggyyy')
  
    this.PageForm[ind].if_condition.conditions[ind2].valforboxes={}
    this.PageForm[ind].if_condition.conditions[ind2].AddressType=[]
 
    this.PageForm[ind].if_condition.conditions[ind2].rating.accept=''
    this.PageForm[ind].if_condition.conditions[ind2].rating.value=''
    this.PageForm[ind].if_condition.conditions[ind2].rating.size=''
    this.PageForm[ind].if_condition.conditions[ind2].rating.description=''
    this.PageForm[ind].if_condition.conditions[ind2].groupFields=[]
    this.PageForm[ind].if_condition.conditions[ind2].LikertQuestions.Questions=[]
    this.PageForm[ind].if_condition.conditions[ind2].LikertQuestions.values=[]

console.log(this.PageForm,'dddff');
// this.PageForm[ind].if_condition.conditions[ind2].FieldConstraint=this.FieldConditionList

console.log(this.PageForm,'ffff11)')
if(!this.Break){
  let newone= this.Fields.find((item)=>{
    return item.name==val
        })
        newone.forEach((item)=>{
          this.PageForm[ind].if_condition.conditions[ind2].valforboxes=item
    
        })
    // this.PageForm[ind].if_condition.conditions[ind2].valforboxes=newone.values

      // console.log(newone,'newwwaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa')
}else{
  let newone =[]

  this.Fields.forEach((fields)=>{
    fields.filter((item)=>{
    // console.log(item,'item')
    if(item.name==val){
      return newone.push(item)
     }
     
    })    
  // console.log(newone,'newone')     
  // console.log(this.PageForm,'ffdd') 
  // console.log(this.PageForm,'fff')
    // this.PageForm[ind].if_condition.conditions[ind2].multiplecheckbox=newone[0].values
     newone.forEach((item)=>{
      this.PageForm[ind].if_condition.conditions[ind2].valforboxes=item

    })
    })

}


   }
   else if(val.includes('address-group')){
  // console.log(this.Fields,'ggyyy')
    
          this.PageForm[ind].if_condition.conditions[ind2].valforboxes={}
          
          this.PageForm[ind].if_condition.conditions[ind2].multiplecheckbox=[]
          this.PageForm[ind].if_condition.conditions[ind2].rating.accept=''
          this.PageForm[ind].if_condition.conditions[ind2].rating.value=''
          this.PageForm[ind].if_condition.conditions[ind2].rating.size=''
          this.PageForm[ind].if_condition.conditions[ind2].rating.description=''
          this.PageForm[ind].if_condition.conditions[ind2].groupFields=[]
          this.PageForm[ind].if_condition.conditions[ind2].LikertQuestions.Questions=[]
          this.PageForm[ind].if_condition.conditions[ind2].LikertQuestions.values=[]
          if(!this.Break){
            let newone= this.Fields.find((item)=>{
              return item.name==val
                  })
                  
                  this.PageForm[ind].if_condition.conditions[ind2].AddressType=newone.addressGroup
          }
          else{
          // console.log('val',val)
            let newone =[]
          this.Fields.forEach((fields)=>{
        fields.filter((item)=>{
        // console.log(item,'item')
           if(item.name==val){
             return newone.push(item)
           }
           
          })          
        })
      // console.log(newone,'fieldsnewonebreak')
        this.PageForm[ind].if_condition.conditions[ind2].AddressType=newone[0].addressGroup

            
          }

console.log(this.PageForm,"ffff")
   }
   else if(val.includes('group')){
  // console.log(this.Fields,'ggyyy')
  
          this.PageForm[ind].if_condition.conditions[ind2].valforboxes={}
          this.PageForm[ind].if_condition.conditions[ind2].conditiontype='text'
          this.PageForm[ind].if_condition.conditions[ind2].AddressType=[]
          this.PageForm[ind].if_condition.conditions[ind2].multiplecheckbox=[]
          this.PageForm[ind].if_condition.conditions[ind2].rating.accept=''
          this.PageForm[ind].if_condition.conditions[ind2].rating.value=''
          this.PageForm[ind].if_condition.conditions[ind2].rating.size=''
          this.PageForm[ind].if_condition.conditions[ind2].rating.description=''

          this.PageForm[ind].if_condition.conditions[ind2].LikertQuestions.Questions=[]
          this.PageForm[ind].if_condition.conditions[ind2].LikertQuestions.values=[]
if(!this.Break){
  let newone= this.Fields.find((item)=>{
    return item.name==val
        })
        this.PageForm[ind].if_condition.conditions[ind2].groupFields=newone.groupFields
}else{
  let newone =[]
console.log(this.Fields,'fffyy')
this.Fields.forEach((field) =>{
  field.forEach((field2) =>{
if(field2.name ==val){
// this.PageForm[ind].if_condition.conditions[ind2].PageFields=field
field.forEach((item)=>{
  // console.log(item,'item')
    if(item.type=='group'){
      return newone.push(item)
     }
     
    }) 
}

  })
  })
  // this.Fields.forEach((fields)=>{
  // // console.log(fields,'fields');
    
  //   // fields.forEach((item)=>{
  //   // console.log(item,'item')
  //   // if(item.type=='group'){
  //   //   return newone.push(item)
  //   //  }
     
  //   // })    
  // console.log(newone,'newonefffggs')     
  // // console.log(this.PageForm,'ffdd',) 
    this.PageForm[ind].if_condition.conditions[ind2].groupFields=newone[0].groupFields
  // // console.log(this.PageForm,'aftergroupClick')
  // })
}


console.log(this.PageForm,"ffff")
   }
   else if (val.includes('likert')){
 // console.log("likertinnn")
          this.PageForm[ind].if_condition.conditions[ind2].valforboxes={}
          this.PageForm[ind].if_condition.conditions[ind2].conditiontype='text'
          this.PageForm[ind].if_condition.conditions[ind2].AddressType=[]
          this.PageForm[ind].if_condition.conditions[ind2].multiplecheckbox=[]
          this.PageForm[ind].if_condition.conditions[ind2].rating.accept=''
          this.PageForm[ind].if_condition.conditions[ind2].rating.value=''
          this.PageForm[ind].if_condition.conditions[ind2].rating.size=''
          this.PageForm[ind].if_condition.conditions[ind2].rating.description=''
          this.PageForm[ind].if_condition.conditions[ind2].groupFields=[]
     

          if(!this.Break){
            let newone= this.Fields.find((item)=>{
              return item.name==val
                  })
                // console.log(newone,'likertnewone')    
      this.PageForm[ind].if_condition.conditions[ind2].LikertQuestions.Questions=newone.QuestionArr
      this.PageForm[ind].if_condition.conditions[ind2].LikertQuestions.values=newone.QuestionArr[0].values
  // console.log(this.PageForm,"ffddfdfdfdfd====");

          }else{
             let newone =[]

  this.Fields.forEach((fields)=>{
    fields.filter((item)=>{
    // console.log(item,'item')
    if(item.name==val){
      return newone.push(item)
     }
     
    })    
  // console.log(newone,'newoneliikppp')   
  })
  this.PageForm[ind].if_condition.conditions[ind2].LikertQuestions.Questions=newone[0].QuestionArr
  this.PageForm[ind].if_condition.conditions[ind2].LikertQuestions.values=newone[0].QuestionArr[0].values
          }
 
         }
   else{  
    this.PageForm[ind].if_condition.conditions[ind2].valforboxes={}
    this.PageForm[ind].if_condition.conditions[ind2].conditiontype='text'
    this.PageForm[ind].if_condition.conditions[ind2].AddressType=[]
    this.PageForm[ind].if_condition.conditions[ind2].multiplecheckbox=[]
    this.PageForm[ind].if_condition.conditions[ind2].rating.accept=''
    this.PageForm[ind].if_condition.conditions[ind2].rating.value=''
    this.PageForm[ind].if_condition.conditions[ind2].rating.size=''
    this.PageForm[ind].if_condition.conditions[ind2].rating.description=''
    this.PageForm[ind].if_condition.conditions[ind2].groupFields=[]
    this.PageForm[ind].if_condition.conditions[ind2].LikertQuestions.Questions=[]
    this.PageForm[ind].if_condition.conditions[ind2].LikertQuestions.values=[]

   }
   

    this.Condition_Show=true
  // console.log(this.Condition_Show,'111');
    
  }
  onUserInfoFieldSelect3(val,ind,ind2){
  // console.log(val,'val');
    
    if(val.includes('file')){
      this.PageForm[ind].if_condition.conditions[ind2].FieldConstraint= this.FieldConditionList.filter((field) =>{ return field.name =='Is Empty'||field.name =='Is Not Empty'})
      this.PageForm[ind].if_condition.conditions[ind2].FileEmpt=true
  
     }else if (val.includes('rating')){
      this.PageForm[ind].if_condition.conditions[ind2].FieldConstraint= this.FieldConditionList.filter((field) =>{ return field.name =='Is'||field.name =='Is Not'||field.name =='Is Empty'||field.name =='Is Not Empty'})

     }
     else{
       this.PageForm[ind].if_condition.conditions[ind2].FieldConstraint=this.FieldConditionList
     }
     if(val.includes('date') && !val.includes('datetime')){
      this.PageForm[ind].if_condition.conditions[ind2].valforboxes={}
     
      this.PageForm[ind].if_condition.conditions[ind2].rating.accept=''
      this.PageForm[ind].if_condition.conditions[ind2].rating.value=''
      this.PageForm[ind].if_condition.conditions[ind2].rating.size=''
      this.PageForm[ind].if_condition.conditions[ind2].rating.description=''
      this.PageForm[ind].if_condition.conditions[ind2].LikertQuestions.Questions=[]
      this.PageForm[ind].if_condition.conditions[ind2].LikertQuestions.values=[]
      this.PageForm[ind].if_condition.conditions[ind2].AddressType=[]
      this.PageForm[ind].if_condition.conditions[ind2].multiplecheckbox=[]

      
      this.PageForm[ind].if_condition.conditions[ind2].rating.description=''
      this.PageForm[ind].if_condition.conditions[ind2].conditiontype='date'
  
     }else if(val.includes('datetime')){
       // alert('datetime')
       this.PageForm[ind].if_condition.conditions[ind2].valforboxes={}
       this.PageForm[ind].if_condition.conditions[ind2].AddressType=[]
       this.PageForm[ind].if_condition.conditions[ind2].multiplecheckbox=[]
       this.PageForm[ind].if_condition.conditions[ind2].rating.accept=''
       this.PageForm[ind].if_condition.conditions[ind2].rating.value=''
       this.PageForm[ind].if_condition.conditions[ind2].rating.size=''
       this.PageForm[ind].if_condition.conditions[ind2].rating.description=''
       this.PageForm[ind].if_condition.conditions[ind2].conditiontype='datetime-local'
     // console.log(this.PageForm,'ffffffyfyfyfyfyfyfyf========')
  
     }else if(val.includes('time')){
      // alert('time')
      this.PageForm[ind].if_condition.conditions[ind2].valforboxes={}
     
      this.PageForm[ind].if_condition.conditions[ind2].rating.accept=''
      this.PageForm[ind].if_condition.conditions[ind2].rating.value=''
      this.PageForm[ind].if_condition.conditions[ind2].rating.size=''
      this.PageForm[ind].if_condition.conditions[ind2].rating.description=''
      this.PageForm[ind].if_condition.conditions[ind2].LikertQuestions.Questions=[]
      this.PageForm[ind].if_condition.conditions[ind2].LikertQuestions.values=[]
      this.PageForm[ind].if_condition.conditions[ind2].AddressType=[]
      this.PageForm[ind].if_condition.conditions[ind2].multiplecheckbox=[]
      this.PageForm[ind].if_condition.conditions[ind2].conditiontype='time'
  
     }
     else if(val.includes('checkbox')){
  
  
       
        this.PageForm[ind].if_condition.conditions[ind2].rating.accept=''
        this.PageForm[ind].if_condition.conditions[ind2].rating.value=''
        this.PageForm[ind].if_condition.conditions[ind2].rating.size=''
        this.PageForm[ind].if_condition.conditions[ind2].rating.description=''
        this.PageForm[ind].if_condition.conditions[ind2].LikertQuestions.Questions=[]
        this.PageForm[ind].if_condition.conditions[ind2].LikertQuestions.values=[]
        this.PageForm[ind].if_condition.conditions[ind2].AddressType=[]
        this.PageForm[ind].if_condition.conditions[ind2].multiplecheckbox=[]
  
      // })
  
      if(!this.Break){
        let fieldarr= this.Fields.find((item)=>{
          return item.type=='group'
              })
            // console.log(this.Fields,'ff===')
             let newone =fieldarr.groupFields.find((item)=>{
                return item.name==val
                    })
            // console.log(newone,'newww')
               //only for one time loop

              this.PageForm[ind].if_condition.conditions[ind2].valforboxes=newone
    
      }else{
        
        // let grpone ={}
        let newone=[]
        this.Fields.forEach((fields)=>{
          fields.forEach((item)=>{
          // console.log(item,'item')
          if (item.type=='group')
          item.groupFields.forEach((item)=>{
            if (item.name ==val){

              return newone.push(item)
            }
    
                })
           
          }) 
        })
      // console.log(newone,"newoneinnnnnnn")
        // for one value in loop
        newone.forEach((item)=>{
          this.PageForm[ind].if_condition.conditions[ind2].valforboxes=item
      
        })
        // this.PageForm[ind].if_condition.conditions[ind2].valforboxes=newone[0].values
     // console.log(this.PageForm,'fieldform')
      
    
    
      }
  // this.PageForm[ind].if_condition.conditions[ind2].FieldConstraint=this.FieldConditionList

  // console.log(this.PageForm,'ffff11)')
  
     }
     else if (val.includes('rating')){
      // alert('rating')
      let fieldarr= this.Fields.find((item)=>{
        return item.type=='group'
            })
            let newone =fieldarr.groupFields.find((item)=>{
              return item.name==val
                  })
                  this.PageForm[ind].if_condition.conditions[ind2].valforboxes={}
                  this.PageForm[ind].if_condition.conditions[ind2].LikertQuestions.Questions=[]
                  this.PageForm[ind].if_condition.conditions[ind2].LikertQuestions.values=[]
                  this.PageForm[ind].if_condition.conditions[ind2].AddressType=[]
                  this.PageForm[ind].if_condition.conditions[ind2].multiplecheckbox=[]
      this.PageForm[ind].if_condition.conditions[ind2].rating.accept=newone.accept
      this.PageForm[ind].if_condition.conditions[ind2].rating.value=newone.value
      this.PageForm[ind].if_condition.conditions[ind2].rating.size=newone.size
      this.PageForm[ind].if_condition.conditions[ind2].rating.description=newone.description
  
    // console.log(this.PageForm,'fgfgfgfg');
      
            
     }
     else if(val.includes('radio')){
      
      // this.PageForm[ind].if_condition.conditions[ind2].group_type=val
   
            this.PageForm[ind].if_condition.conditions[ind2].rating.accept=''
            this.PageForm[ind].if_condition.conditions[ind2].rating.value=''
            this.PageForm[ind].if_condition.conditions[ind2].rating.size=''
            this.PageForm[ind].if_condition.conditions[ind2].rating.description=''
            this.PageForm[ind].if_condition.conditions[ind2].LikertQuestions.Questions=[]
            this.PageForm[ind].if_condition.conditions[ind2].LikertQuestions.values=[]
            this.PageForm[ind].if_condition.conditions[ind2].AddressType=[]
            this.PageForm[ind].if_condition.conditions[ind2].multiplecheckbox=[]

      if(!this.Break){
        let fieldarr= this.Fields.find((item)=>{
          return item.type=='group'
              })
            // console.log(this.Fields,'ff===')
             let newone =fieldarr.groupFields.find((item)=>{
                return item.name==val
                    })
            // console.log(newone,'newww')
              this.PageForm[ind].if_condition.conditions[ind2].valforboxes=newone
    
      }else{
    
        // let grpone ={}
      // console.log(val,'val')
        let newone=[]
        this.Fields.forEach((fields)=>{
          fields.filter((item)=>{
        // console.log(item,'item')
          if (item.type=='group')
          item.groupFields.forEach((item)=>{
            if(item.name == val){

               newone.push(item)
            }
    
                })
           
          }) 
        })
      // console.log(newone,"newone")
          // for one value in loop
          newone.forEach((item)=>{
            this.PageForm[ind].if_condition.conditions[ind2].valforboxes=item
        
          })
        // this.PageForm[ind].if_condition.conditions[ind2].valforboxes=newone[0].values
      // console.log(this.PageForm,'fffooorrmmmmm')
    
        // console.log(newone,'newone');
        
        let fieldarr= this.Fields.find((item)=>{
          return item.type=='group'
              })
      // console.log(fieldarr,'fieldarr')     
    
    
      }
  //     this.PageForm[ind].if_condition.conditions[ind2].FieldConstraint=this.FieldConditionList

// console.log(this.PageForm,'ffff1111111111111111111111111111111111111)')
     }

     else if(val.includes('autocomplete')){

    // console.log(this.Fields,'ggyyy',val)
  
      this.PageForm[ind].if_condition.conditions[ind2].valforboxes={}
     
      this.PageForm[ind].if_condition.conditions[ind2].rating.accept=''
      this.PageForm[ind].if_condition.conditions[ind2].rating.value=''
      this.PageForm[ind].if_condition.conditions[ind2].rating.size=''
      this.PageForm[ind].if_condition.conditions[ind2].rating.description=''
      this.PageForm[ind].if_condition.conditions[ind2].LikertQuestions.Questions=[]
      this.PageForm[ind].if_condition.conditions[ind2].LikertQuestions.values=[]
      this.PageForm[ind].if_condition.conditions[ind2].AddressType=[]
      this.PageForm[ind].if_condition.conditions[ind2].multiplecheckbox=[]
// console.log(this.PageForm,'dddff');
  if(!this.Break){
    let fieldarr= this.Fields.find((item)=>{
      return item.type=='group'
          })
        // console.log(this.Fields,'ff===')
         let newone =fieldarr.groupFields.find((item)=>{
            return item.name==val
                })
        // console.log(newone,'newww')
      this.PageForm[ind].if_condition.conditions[ind2].multiplecheckbox=newone.values

  }else{
    
    // let grpone ={}
    let newone=[]
    this.Fields.forEach((fields)=>{
      fields.filter((item)=>{
    // console.log(item,'item')
      if (item.type=='group')
      item.groupFields.forEach((items)=>{
        if(items.name ==val){

          return newone.push(items)
        }

            })
       
      }) 
    })
  // console.log(newone,"newone")
      // for one value in loop
      // newone.forEach((item)=>{
      //   this.PageForm[ind].if_condition.conditions[ind2].multiplecheckbox=item
    
      // })
      this.PageForm[ind].if_condition.conditions[ind2].valforboxes=newone

    // this.PageForm[ind].if_condition.conditions[ind2].multiplecheckbox=newone[0].values
   
  


  }
  this.PageForm[ind].if_condition.conditions[ind2].FieldConstraint=this.FieldConditionList

// console.log(this.PageForm,'ffff11)')
     }
     else if(val.includes('address-group')){
      // alert('address-group')
    // console.log(this.Fields,'ggyyy')
     
            this.PageForm[ind].if_condition.conditions[ind2].valforboxes={}
      this.PageForm[ind].if_condition.conditions[ind2].multiplecheckbox=[]
      this.PageForm[ind].if_condition.conditions[ind2].rating.accept=''
      this.PageForm[ind].if_condition.conditions[ind2].rating.value=''
      this.PageForm[ind].if_condition.conditions[ind2].rating.size=''
      this.PageForm[ind].if_condition.conditions[ind2].rating.description=''
      this.PageForm[ind].if_condition.conditions[ind2].LikertQuestions.Questions=[]
      this.PageForm[ind].if_condition.conditions[ind2].LikertQuestions.values=[]

  if(!this.Break){
    let fieldarr= this.Fields.find((item)=>{
      return item.type=='group'
          })
          let newone = fieldarr.groupFields.find((item)=>{
            return item.name==val
                })
        // console.log(newone,'newwww')
      this.PageForm[ind].if_condition.conditions[ind2].AddressType=newone.addressGroup

  }else{
  // console.log('val',val)
    let newone2 =[]
    
  this.Fields.forEach((fields)=>{
fields.forEach((item)=>{
  // console.log(item,'item')
   if(item.type=='group'){
  // console.log(item,'particular item')
     return newone2.push(item)
   }
  })    
})
let newone = []
newone2[0].groupFields.forEach((field)=>{
  if(field.name ==val){
    return newone.push(field) 

  }
}) 
console.log(newone,'fieldsnewonebreak')
this.PageForm[ind].if_condition.conditions[ind2].AddressType=newone[0].addressGroup

console.log(newone2,'222f2222222ieldsnewoneb22222reak')

  }
// console.log(this.PageForm,"ffff")
     }
     else if(val.includes('likert')){
      // alert('likert')
console.log(this.PageForm,'fffff9999');
console.log(val,'c=val')
      
                  this.PageForm[ind].if_condition.conditions[ind2].valforboxes={}
                  this.PageForm[ind].if_condition.conditions[ind2].multiplecheckbox=[]
                  this.PageForm[ind].if_condition.conditions[ind2].rating.accept=''
                  this.PageForm[ind].if_condition.conditions[ind2].rating.value=''
                  this.PageForm[ind].if_condition.conditions[ind2].rating.size=''
                  this.PageForm[ind].if_condition.conditions[ind2].rating.description=''
                  this.PageForm[ind].if_condition.conditions[ind2].LikertQuestions.Questions=[]
                  this.PageForm[ind].if_condition.conditions[ind2].LikertQuestions.values=[]
                  this.PageForm[ind].if_condition.conditions[ind2].AddressType=[]
                 
             if(!this.Break){
              let newone2= this.Fields.find((item)=>{
                return item.type=='group'
                    })
                  // console.log(newone2,'newoneggg222')
                    let newone = newone2.groupFields.find((item)=>{
                      return item.name ==val
                    })
                  // console.log(newone,'newone');
                          this.PageForm[ind].if_condition.conditions[ind2].LikertQuestions.Questions=newone.QuestionArr
                          this.PageForm[ind].if_condition.conditions[ind2].LikertQuestions.values=newone.QuestionArr[0].values
                         
                          // console.log(this.PageForm,'f=f=f=f=f=f==fff=')
                            
                          
                        }  
                        else{
                          let newone =[]
                    // console.log(val,'val')
                          this.Fields.forEach((fields)=>{
                            fields.filter((item)=>{
                            // console.log(item,'item')
                            if(item.type=='group'){
                              return newone.push(item)
                             }
                             
                            })    
                          // console.log(newone,'newoneliikppp')   
                          })
                     let newone2 =     newone[0].groupFields.find((field)=>{
return field.type =='likert'
                          })
                        // console.log(newone2.QuestionArr,'22')
                          // newone2.QuestionArr.forEach((question)=>{

                          // })
                          this.PageForm[ind].if_condition.conditions[ind2].LikertQuestions.Questions=newone2.QuestionArr
                          this.PageForm[ind].if_condition.conditions[ind2].LikertQuestions.values=newone2.QuestionArr[0].values
                      // console.log(this.PageForm,"ffddfdfdfdfd====");
                          
                        }  
              
     }
   
 
     else{
    // console.log('hit exchange')
      this.PageForm[ind].if_condition.conditions[ind2].valforboxes={}
      this.PageForm[ind].if_condition.conditions[ind2].conditiontype='text'
      this.PageForm[ind].if_condition.conditions[ind2].AddressType=[]
      this.PageForm[ind].if_condition.conditions[ind2].multiplecheckbox=[]
      this.PageForm[ind].if_condition.conditions[ind2].rating.accept=''
      this.PageForm[ind].if_condition.conditions[ind2].rating.value=''
      this.PageForm[ind].if_condition.conditions[ind2].rating.size=''
      this.PageForm[ind].if_condition.conditions[ind2].rating.description=''
      // this.PageForm[ind].if_condition.conditions[ind2].groupFields=[]
    // console.log(this.PageForm,"dfff")
  
     }
     
  
      // this.Condition_Show[ind2]=true
    // console.log(this.Condition_Show,'111');
    // console.log(this.PageForm,'222111');
  }
  onlikertoptions(val,ind,ind2){
  // console.log(this.Fields,'bbfieldssss  ')
  if(!this.Break){
    let fieldarr= this.Fields.find((item)=>{
      return item.type=='likert'
          })
        // console.log(fieldarr,'rrr')
        // console.log(val,"valwues")
          let newone =fieldarr.QuestionArr.find((item)=>{
            return item.QuestionName==val
                })
  // console.log(newone,'likert')
this.PageForm[ind].if_condition.conditions[ind2].LikertQuestions.values=newone.values
console.log(this.PageForm,'fiedlikert')

  }
  else{    
  // console.log(this.Fields,'fieldssss  ')
    let newone2 =[]

    this.Fields.forEach((fields)=>{
      fields.forEach((item)=>{
      // console.log(item,'item')
      if(item.type=='likert'){
        return newone2.push(item)
       }
       
      })    
    
     
    })
  // console.log(newone2,'newoneoo22')     

  let newone =[]
  // console.log(val,'valllllll')   
    newone2[0].QuestionArr.find((item)=>{
       if(item.QuestionName ==val){
      return newone.push(item)
       }
    // console.log(item,'item',item.QuestionName == val)
    })
  // console.log(newone,'newoneoo')     

    this.PageForm[ind].if_condition.conditions[ind2].LikertQuestions.values=newone[0].values



// //         
// console.log(this.PageForm,'fiedlikert')
  }


  }
  savePageRule(){
    let errors = [];
    this.PageForm.map((obj: any, i: number) => {
    // console.log(obj,'obj')

      if(obj.rule_name === '' || (obj.then_apply.value == '' || obj.then_apply.type =="")) {
        errors.push({'index': i, 'object': obj});
      }
// validations for plain Text
      obj.if_condition.conditions.map((consObj: any, ci: number) => {
        if(consObj.showValueBox && (consObj.value === '' || consObj.value.length == 0)) {
          errors.push({'condition_index': ci, 'object': consObj});
        }
      });

      // if(obj.proof === 'yes' && (obj.discount_proof_title === '' || obj.discount_proof_description === '' || obj.discount_proof_files_allowed.length === 0 || obj.discount_proof_number_of_files.min === null || obj.discount_proof_number_of_files.max === null || obj.discount_proof_file_size.min === null || obj.discount_proof_file_size.max === null)) {
      //   errors.push({'proof_index': i, 'object': obj});
      // }
    });
console.log(errors,'error');

    if(errors.length > 0 ) {
      this.validate = true;
    } 
    else {
      let formdata= new FormData();
      formdata.append('form',this.form_id)
      // let data = {
      //   form:this.form_id,
      //   field_rules:this.PageForm
      // }
     
//       this.PageForm.forEach((item,i)=>{
//         item.if_condition.conditions.forEach((condition)=>{
// JSON.stringify(condition)
// console.log(condition,'conditions')       
// })
//       })
console.log(this.PageForm,'ff')

// this.PageForm.forEach((item,i)=>{
// console.log(item,'item');

// })
for (var key in this.PageForm) {
  if (this.PageForm.hasOwnProperty(key)) {
    // console.log(key + " -> " + this.PageForm[key]);
  }
}
      // formdata.append('field_rules',JSON.stringify(this.PageForm,this.getCircularReplacer()))
      formdata.append('page_rules',JSON.stringify(this.PageForm))
      this.formService.CreatePageRules(formdata).subscribe((result: any) => {
          
        if(result['success']) {
          Swal.fire({
            position: 'center',
            icon: 'success',
            html: result['message'],
            padding: '2em',
          });
          // this.ngZone.run(() => {
          //   this.router.navigate(['/settings/discount-groups']);
          //   this.discountRuleForm = [];
          //   this.addNewRule();
          // })
        
        }
      }, error => {
        Swal.fire({
          position: 'center',
          icon: 'error',
          html: error.error['message'],
          padding: '2em',
        });
      });
    }
  }
}
