<!-- <p>returning-student works!</p> -->
<div class="card shadow-none mb-0">
  <div class="card-body p-4">
    <div class="row">
        <div class="col-sm-12">
          <form
            class="needs-validation"
            novalidate
            [ngClass]="{ 'was-validated': validate }"
          >

            <ng-container
              *ngFor="let ruleitem of discountRuleForm; let ruleindex = index"
            >
              <div class="rulesDup">
                <input
                  type="text"
                  class="form-control"
                  placeholder="Rule Name"
                  required=""
                  [(ngModel)]="ruleitem.rule_name"
                  [ngModelOptions]="{ standalone: true }"
                />
                <div class="fieldRuleConditions">
                  <div class="ruleCont" style="margin: 33px 0 0 0">
                    <div class="contDivInner">
                      <span class="newRuleIfTxt">If</span>
                      <div class="if-wrapper ifContainer fieldRuleIfCont">
                        <div class="form-group">
                          <select
                            class="custom-select form-control mr-3"
                            [(ngModel)]="ruleitem.if_condition.allow"
                            required=""
                            [ngModelOptions]="{ standalone: true }"
                          >
                            <option
                              value="any"
                              [selected]="ruleitem.if_condition.allow === 'any'"
                            >
                              Any
                            </option>
                            <option
                              value="all"
                              [selected]="ruleitem.if_condition.allow === 'all'"
                            >
                              All
                            </option>
                          </select>
                          <label for="">of the following conditions are met</label>
                        </div>
                        <ng-container
                          *ngFor="
                            let conditionItem of ruleitem.if_condition.conditions;
                            let conindex = index
                          "
                        >
                          <div class="fieldRow my-2 py-2">
                            <div class="col-sm-12">
                              <div class="d-flex align-items-center py-1">
                                    <ng-container *ngIf="discountFields && discountFields.length > 0; else baseInfoTemplate">
                                    
                                    </ng-container>
                                    <ng-template #baseInfoTemplate>
                                    
                                      <div class="form-group mr-3">
                                        <label class="col-form-label">Start Date</label>
                                        <input
                                          class="form-control digits"
                                          [min]="minDobstart"
                                          [max]="maxDob"
                                          [(ngModel)]="conditionItem.startDate"
                                          (change)="startChange($event)"
                                          [ngModelOptions]="{
                                            standalone: true
                                          }"
                                          ngDefaultControl
                                          id="validationCustom003"
                                          type="date"
                                          required=""
                                        />
                                      </div>
                              
                                      <div class="form-group mr-3">
                                        <label class="col-form-label">End Date</label>
                                        <input
                                          class="form-control digits"
                                          [(ngModel)]="conditionItem.endDate"
                                          [min]="minDob"
                                          (change)="EndChange($event)"
                                          [ngModelOptions]="{
                                            standalone: true
                                          }"
                                          ngDefaultControl
                                          id="validationCustom004"
                                          type="date"
                                          required=""

                                        />
                                      </div>
                                      <div class="form-group mr-3 mb-0 mt-3">
                                        <input
                                        placeholder="Number of seats"
                                          class="form-control digits"
                                          [(ngModel)]="conditionItem.num_of_seats"
                                          [ngModelOptions]="{
                                            standalone: true
                                          }"
                                          ngDefaultControl
                                          id="num001"
                                          type="number"
                                          required=""

                                        />
                                      </div>
                                    </ng-template>
                                  <div class="clearBoth"></div>
                              
                                <div
                                  class="form-group mr-2 mb-0"
                                  *ngIf="conditionItem.showValueBox"
                                >
                                  <!-- <div class="condition-value-input">
                                      <input
                                        type="text"
                                        class="form-control"
                                        placeholder="Conditions Value"
                                        [(ngModel)]="conditionItem.value"
                                        required=""
                                        [ngModelOptions]="{ standalone: true }"
                                      />
                                    </div> -->
                                </div>
                                <div class="mb-0 mt-3">
                                  <button
                                    type="button"
                                    class="customAddminus clone"
                                    (click)="addNewRow(ruleindex)"
                                  >
                                  <i class="icon-plus"></i>
                                  </button>
                                  <button
                                  *ngIf="ruleitem.if_condition.conditions.length>1"
                                    type="button"
                                    class="customAddminus delete"
                                    (click)="removeRow(ruleindex, conindex)"
                                  >
                                    <i class="icon-minus"></i>
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </ng-container>
                      </div>
                      <span class="newRuleIfTxt newRuleThenTxt">Then</span>
                    </div>
                    <div class="actCondition">
                      <span>Perform the following actions</span>
                    </div>
                    <div class="then-wrapper ruleActionWrapper">
                      <div class="ruleAction">
                        <div class="fieldRow my-2 py-2 px-4 d-flex align-items-center">
                          <div class="form-group mr-3 mb-0">
                            <label for="">Discount Type</label>
                            <div class="rule-action-list">
                              <select
                                class="custom-select form-control text-capitalize"
                                [(ngModel)]="ruleitem.then_apply.type"
                                required=""
                                [ngModelOptions]="{ standalone: true }"
                              >
                                <option value="">Select Discount Type</option>
                                <ng-container *ngFor="let item of thenApply">
                                  <option
                                    [value]="item"
                                    [selected]="item === ruleitem.then_apply.type"
                                  >
                                    {{ item }}
                                  </option>
                                </ng-container>
                              </select>
                            </div>
                          </div>
                          <div class="form-group mr-3 mb-0">
                            <label for="">Discount Value</label>
                            <div
                              class="action-value-input"
                            >
                              <input
                                type="number"
                                class="form-control"
                                [(ngModel)]="ruleitem.then_apply.value"
                                required=""
                                [ngModelOptions]="{ standalone: true }"
                                (input)="MaxDiscountLimit($event, ruleindex)"
                              />
                              <!-- oninput="if(this.value>100){ 
                                                                    this.value='' ;alert('asdsa')};"  -->
                            </div>
                          
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="form-group">
                      <label for="">Discount Title</label>
                      <input
                        class="form-control"
                        type="text"
                        placeholder="Discount Title"
                        required=""
                        [(ngModel)]="ruleitem.discount_proof_title"
                        [ngModelOptions]="{ standalone: true }"
                      />
                    </div>
                    <div class="form-group">
                      <label for="">Discount Description</label>
                      <textarea
                        class="form-control"
                        rows="3"
                        placeholder="Discount Description"
                        required=""
                        [(ngModel)]="ruleitem.discount_proof_description"
                        [ngModelOptions]="{ standalone: true }"
                        >{{ ruleitem.discount_proof_description }}</textarea
                      >
                    </div>
                  </div>
                </div>
          
              </div>
              <div
              class="text-right"
            
              >
              <!-- *ngIf="!ruleEdit" -->
              <button
              
                type="button"
                class="btn btn-danger mr-2"
                 (click)="removeRule(ruleindex,ruleitem.id)"
                >
                Delete Rule
              </button>
       
            </div>
            </ng-container>
            <!-- </div> -->
            <div class="d-block pb-4" *ngIf="!hidesave">
              <button type="button" class="btn btn-primary mr-2" (click)="saveRule()">
                <!-- {{ ruleEdit ? "Update Rule" : "Save Rules" }} -->
                Save Rules
              </button>
            </div>
            <!-- </div> -->
          </form>
        </div>
      </div>
  </div>
</div>