import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../services/firebase/auth.service';
import { CookieService } from 'ngx-cookie-service';
import { NavService } from '../services/nav.service';

@Injectable({
  providedIn: 'root'
})
export class AdminGuard implements CanActivate {
  
  constructor(public authService: AuthService,
    public router: Router, private cookieService: CookieService) {
    // alert('guard hit')

     }

  canActivate(next: ActivatedRouteSnapshot, 
      state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    // Guard for user is login or not
    // let user = JSON.parse(localStorage.getItem('user'));
    let token = this.cookieService.get('access_token');
    if (!token || token === null || token === undefined) {
      this.router.navigate(['/qdf-hbku']);
      return true
    }
    // else if (user) {
    //   if (!Object.keys(user).length) {
    //     this.router.navigate(['/qdf-hbku']);
    //     return true
    //   }
    // }
    return true
  }
}


