<!-- <p>returning-student works!</p> -->
<div class="card shadow-none mb-0">
  <div class="card-body p-4">
    <div class="row">
      <div class="col-sm-12">
        <form
          class="needs-validation"
          novalidate
          [ngClass]="{ 'was-validated': validate }"
        >
          <!-- <div class="card"> -->
          <!-- {{discountRuleForm | json}} -->
          <!-- <div class="card-body px-4 py-2"> -->
          <ng-container
            *ngFor="let ruleitem of discountRuleForm; let ruleindex = index"
          >
            <div class="rulesDup">
              <input
                type="text"
                class="form-control"
                placeholder="Rule Name"
                required=""
                [(ngModel)]="ruleitem.rule_name"
                [ngModelOptions]="{ standalone: true }"
              />
        
              <div class="fieldRuleConditions">
                <div class="ruleCont" style="margin: 33px 0 0 0">
                  <div class="contDivInner">
                    <span class="newRuleIfTxt">If</span>
                    <div class="if-wrapper ifContainer fieldRuleIfCont">
                      <div class="form-group">
                        <select
                          class="custom-select form-control mr-3"
                          [(ngModel)]="ruleitem.if_condition.allow"
                          required=""
                          [ngModelOptions]="{ standalone: true }"
                        >
                          <option
                            value="any"
                            [selected]="ruleitem.if_condition.allow === 'any'"
                          >
                            Any
                          </option>
                          <option
                            value="all"
                            [selected]="ruleitem.if_condition.allow === 'all'"
                          >
                            All
                          </option>
                        </select>
                        <label for="">of the following conditions are met</label>
                      </div>
                      <ng-container
                        *ngFor="
                          let conditionItem of ruleitem.if_condition.conditions;
                          let conindex = index
                        "
                      >
                        <div class="fieldRow my-2 py-2">
                          <div class="col-sm-12">
                            <div class="d-flex align-items-center py-1">
                                  <ng-container *ngIf="discountFields && discountFields.length > 0; else baseInfoTemplate">
                                  
                                  </ng-container>
                                  <ng-template #baseInfoTemplate>
                                    <div class="form-group mr-3">
                                      <label class="col-form-label">Start Date</label>
                                      <input
                                        class="form-control digits"
                                        [min]="minDobstart"
                                        [max]="maxDob"
                                        [(ngModel)]="conditionItem.startDate"
                                        (change)="startChange($event)"
                                        [ngModelOptions]="{
                                          standalone: true
                                        }"
                                        ngDefaultControl
                                        id="validationCustom003"
                                        type="date"
                                        required=""
                                      />
                                    </div>
                            
                                    <div class="form-group mr-3">
                                      <label class="col-form-label">End Date</label>
                                      <input
                                        class="form-control digits"
                                        [(ngModel)]="conditionItem.endDate"
                                        [min]="minDob"
                                        (change)="EndChange($event)"
                                        [ngModelOptions]="{
                                          standalone: true
                                        }"
                                        ngDefaultControl
                                        id="validationCustom004"
                                        type="date"
                                        required=""
                                      />
                                    </div>
                                    <div class="form-group mr-3 mb-0 mt-3">
                                        <!-- <label for="exampleFormControlSelect9">Example select</label> -->
                                            <select
                                            class="form-control digits -{{conindex}}"
                                            id="exampleFormControlSelect8"
                                            (change)="
                                                categoryChange($event.target.value,conindex)
                                            "
                                            required=""
                                            [(ngModel)]="conditionItem.category_id"
                                      [ngModelOptions]="{ standalone: true }"
    
                                            >
                                            <option value="">Categories</option>
                                            <ng-container
                                                *ngFor="let category of allCategories"
                                            >
                                                <option
                                                [value]="category.id"
                                                *ngIf="
                                                    category.sub_categories.length > 0
                                                "
                                                [selected]="
                                                    category.id ===
                                                    selectedCategoryOption
                                                "
                                                >
                                                {{ category.category_name }}
                                                </option>
                                            </ng-container>
                                            </select>
    
                                    </div>
                                    <!-- <ng-container *ngIf="showSubCategoryList.length > 0 && conditionItem.category_id !=''"> -->
                                      <!-- {{conditionItem.sub_cat_list|json}} -->
                                      <ng-container *ngIf="conditionItem.sub_cat_list.length>0 ">
                                        <!-- <label
                                                for="exampleFormControlSelect9"
                                                class="mt-3"
                                                >Choose Sub Category</label -->
                                        <!-- > -->
                                    <div class="form-group mr-3 mb-0 mt-3">
                                        <select
                                        class="form-control digits -{{conindex}}"
                                        id="exampleFormControlSelect9"
                                        [(ngModel)]="conditionItem.subcategory_id"
                                      [ngModelOptions]="{ standalone: true }"
                                      (change)="
                                      SubcategoryChange($event.target.value,conindex)
                                  "
                                        >
                                        <!-- required="" -->
                                        <!-- (change)=" subCategoryChange(
                                        $event.target.value ) " -->
                                        <option value="">Sub Categories</option>
                                        <!-- <ng-container
                                            *ngFor="
                                            let subCat of newarr;let subindup = index;
                                            "
                                        > -->
                                      
                                        <ng-container
                                        *ngFor="
                                        let subCategory of conditionItem.sub_cat_list ;let subind = index;  
                                        "
                                    >
                                <!-- <ng-container *ngIf="subindup ==conindex"> -->
                                    
                                    <!-- <div *ngIf="subCategory.parent_id ==conditionItem.category_id">  -->
                                      <option
                                      [value]="subCategory.id"
                                      [selected]="
                                      subCategory.id ===
                                      selectedSubCategoryOption
                                      "
                                      >
                                      {{ subCategory.category_name }}
                                  </option>
                                <!-- </ng-container> -->
    
                                    <!-- </div> -->
                                    </ng-container>
                                  <!-- </ng-container> -->
                                        </select>
                                    </div>
                                    </ng-container>
                                <!-- /////////////////////////////////////// -->

                                <ng-container *ngIf="conditionItem.sub__sub_cat_list.length>0 ">
                                  <!-- <label
                                          for="exampleFormControlSelect9"
                                          class="mt-3"
                                          >Choose Sub Category</label -->
                                  <!-- > -->
                              <div class="form-group mr-3 mb-0 mt-3">
                                  <select
                                  class="form-control digits -{{conindex}}"
                                  id="exampleFormControlSelect9"
                                  [(ngModel)]="conditionItem.sub_sub_category_id"
                                [ngModelOptions]="{ standalone: true }"
                             
                                  >
                                  (change)="
                                  SubcategoryChange($event.target.value,conindex)
                              "
                                  <!-- required="" -->
                                  <!-- (change)=" subCategoryChange(
                                  $event.target.value ) " -->
                                  <option value="">Sub Categories</option>
                                  <!-- <ng-container
                                      *ngFor="
                                      let subCat of newarr;let subindup = index;
                                      "
                                  > -->
                                
                                  <ng-container
                                  *ngFor="
                                  let subCategory of conditionItem.sub__sub_cat_list ;let subind = index;  
                                  "
                              >
                          <!-- <ng-container *ngIf="subindup ==conindex"> -->
                              
                              <!-- <div *ngIf="subCategory.parent_id ==conditionItem.category_id">  -->
                                <option
                                [value]="subCategory.id"
                                [selected]="
                                subCategory.id ===
                                selectedSubCategoryOption
                                "
                                >
                                {{ subCategory.category_name }}
                            </option>
                          <!-- </ng-container> -->

                              <!-- </div> -->
                              </ng-container>
                            <!-- </ng-container> -->
                                  </select>
                              </div>
                              </ng-container>
                              
                                  </ng-template>
                                <div class="clearBoth"></div>
                           
    
                              <div
                                class="form-group mr-2 mb-0"
                                *ngIf="conditionItem.showValueBox"
                              >
                                <!-- <div class="condition-value-input">
                                    <input
                                      type="text"
                                      class="form-control"
                                      placeholder="Conditions Value"
                                      [(ngModel)]="conditionItem.value"
                                      required=""
                                      [ngModelOptions]="{ standalone: true }"
                                    />
                                  </div> -->
                              </div>
                              <div class="mb-0 mt-3">
                                <button
                                  type="button"
                                  class="customAddminus clone"
                                  (click)="addNewRow(ruleindex)"
                                >
                                  <i class="icon-plus"></i>
                                </button>
                                <button
                                *ngIf="ruleitem.if_condition.conditions.length>1"
                                  type="button"
                                  class="customAddminus delete"
                                  (click)="removeRow(ruleindex, conindex)"
                                >
                                  <i class="icon-minus"></i>
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </ng-container>
                    </div>
                    <span class="newRuleIfTxt newRuleThenTxt">Then</span>
                  </div>
                  <div class="actCondition">
                    <span>Perform the following actions</span>
                  </div>
                  <div class="then-wrapper ruleActionWrapper">
                    <div class="ruleAction">
                      <div class="fieldRow my-2 py-2 px-4 d-flex align-items-center">
                        <div class="form-group mr-3 mb-0">
                          <label for="">Discount Type</label>
                          <div class="rule-action-list">
                            <select
                              class="custom-select form-control text-capitalize"
                              [(ngModel)]="ruleitem.then_apply.type"
                              required=""
                              [ngModelOptions]="{ standalone: true }"
                            >
                              <option value="">Select Discount Type</option>
                              <ng-container *ngFor="let item of thenApply">
                                <option
                                  [value]="item"
                                  [selected]="item === ruleitem.then_apply.type"
                                >
                                  {{ item }}
                                </option>
                              </ng-container>
                            </select>
                          </div>
                        </div>
                        <div class="form-group mr-3 mb-0">
                          <label for="">Discount Value</label>
                          <div
                            class="action-value-input"
                           
                          >
                            <input
                              type="number"
                              class="form-control"
                              [(ngModel)]="ruleitem.then_apply.value"
                              required=""
                              [ngModelOptions]="{ standalone: true }"
                              (input)="MaxDiscountLimit($event, ruleindex)"
                            />
                            <!-- oninput="if(this.value>100){ 
                                                                  this.value='' ;alert('asdsa')};"  -->
                          </div>
                          <!-- <div
                            class="action-value-input"
                            *ngIf="!maxvalidationapply"
                          >
                            <input
                              type="number"
                              class="form-control"
                              [(ngModel)]="ruleitem.then_apply.value"
                              required=""
                              [ngModelOptions]="{ standalone: true }"
                              (input)="MaxDiscountLimitgroupLink($event, ruleindex)"
                            />
                            
                          </div> -->
                        </div>
                      </div>
                    </div>
                  </div>
                    <div class="form-group">
                    <label for="">Discount Title</label>
                    <input
                      class="form-control"
                      type="text"
                      placeholder="Discount Title"
                      required=""
                      [(ngModel)]="ruleitem.discount_proof_title"
                      [ngModelOptions]="{ standalone: true }"
                    />
                  </div>
                  <div class="form-group">
                    <label for="">Discount Description</label>
                    <textarea
                      class="form-control"
                      rows="3"
                      placeholder="Discount Description"
                      required=""
                      [(ngModel)]="ruleitem.discount_proof_description"
                      [ngModelOptions]="{ standalone: true }"
                      >{{ ruleitem.discount_proof_description }}</textarea
                    >
                  </div>
                </div>
              </div>
         
            </div>
            <div
            class="text-right"
          
            >
            <!-- *ngIf="!ruleEdit" -->
            <button
            
              type="button"
              class="btn btn-danger mr-2"
               (click)="removeRule(ruleindex,ruleitem.id)"
              >
              Delete Rule
            </button>
     
          </div>
          </ng-container>
     
          <!-- </div> -->
          <div class="d-block pb-4"  *ngIf="!hidesave">
            <button type="button" class="btn btn-primary mr-2" (click)="saveRule()">
              <!-- {{ ruleEdit ? "Update Rule" : "Save Rules" }} -->
              Save Rules
            </button>
          </div>
          <!-- </div> -->
        </form>
      </div>
    </div>
  </div>
</div>