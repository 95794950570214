<!-- Page Header Start-->
<div class="page-header" [class.close_icon]="navServices.collapseSidebar" >
  <div class="header-wrapper row m-0" (click)="boolchange('false')">
    <app-search></app-search>
    <div class="header-logo-wrapper">
      <div class="logo-wrapper">
        <a routerLink='/'>
          <img class="img-fluid for-light" src="assets/images/logo/hbku-color.svg" alt="">
          <img class="img-fluid for-dark" src="assets/images/logo/logo_dark.png" alt="">
        </a>
      </div>
      <div class="toggle-sidebar" (click)="sidebarToggle()">
        <app-feather-icons [icon]="'align-center'" class="status_toggle middle"></app-feather-icons>
      </div>
    </div>
    <div class="left-header col horizontal-wrapper pl-0">
      <app-mega-menu></app-mega-menu>
    </div>
    <div class="nav-right col-sm-8 col-10 pull-right right-header p-0">
      <ul class="nav-menus">
        <li>
          <i class="icofont icofont-wall-clock f-16 mr-1"></i>{{ p }}
        </li>
        <!-- {{showdrop}} -->
        <div class="row">
          <div class="col">
            <div ngbDropdown class="d-inline-block">
              <button type="button" class="profilebtn btn btn-link text-dark text-decoration-none p-0 px-2" id="dropdownBasic1" ngbDropdownToggle>
                <i class="icon-user"></i> <span> Hello {{ name }}</span>
              </button>
              <div ngbDropdownMenu aria-labelledby="dropdownBasic1">

                <button ngbDropdownItem (click)="redirect()">Edit Profile</button>
                <button ngbDropdownItem (click)="logout()">Log Out</button>
              </div>
            </div>
          </div>
        </div>  
        <!-- <li class="profile-nav dropdown" *ngIf="showdrop =='false'" (click)="boolchange('false')">
        <li class="profile-nav "*ngIf="showdrop =='true'" (click)="boolchange('true')">

          <a href="javascript:void(0)" class="mouse-hover text-dark dropdown-toggle text-capitalize">Hello {{name}}</a>
  
              <app-my-account *ngIf="showdrop =='true'"></app-my-account>

        </li>
        <li class="profile-nav dropup" *ngIf="showdrop =='true'" (click)="boolchange('true')">
      
          <a href="javascript:void(0)" class="mouse-hover text-dark dropdown-toggle text-capitalize">Hello {{name}}</a>

              <app-my-account *ngIf="showdrop =='true'"></app-my-account>

        </li>
        <li class="maximize">
          <a class="text-dark" href="javascript:void(0)" (click)="toggleFullScreen()">
            <app-feather-icons [icon]="'maximize'"></app-feather-icons>
          </a>
        </li> -->
      </ul>
    </div>
  </div>
</div>
<!-- Page Header Ends -->