<!-- <p>dynamic-tables works!</p> -->

<div class="Vertical-scroll">
  <!-- {{list |json}} -->
  <!-- {{pagefilters |json}} -->
  <select id="perpage" #select (change)="rowsValidate($event)" [(ngModel)]="pagefilters.perpage"  [ngModelOptions]="{standalone: true}" class="custom-select btn-pill btn-air-primary m-0">
    <option *ngFor="let item of RowsArr" [value]="item.value">
      {{item.value}}
    </option>
  </select>
<ng-container *ngFor="let item of list| paginate: { itemsPerPage: pagefilters.perpage, currentPage: pagefilters.page, totalItems: pagefilters.listcount };index as k ">
  <!-- -------------ICONS FOR SHOW AND HIDE------------------- -->
  <button class="btn btn-light" *ngIf="k!=0 &&  !showheader[k]"  (click)="Collapse(k,'show')">
    <i class="fa" class="fa fa-eye" ></i>    
  </button>
  <button class="btn btn-light" *ngIf="k!=0 &&  showheader[k]" (click)="Collapse(k,'hide')">
    <i class="fa" class="fa fa-eye-slash"   ></i>
  </button>
  
<!-- -------------------------------------- -->
<table class="table table-striped table-bordered table-hover submissionTable">
    <thead *ngIf="k==0 || showheader2[k]">
      <!-- <tr> -->
        <!---------------------- // Static Headings as our requirement ------------------------>
       <ng-container *ngIf="type =='standard_form'">
         <ng-container *ngFor="let head of Staticheading">
         <th>{{head}}</th>
       </ng-container>
      </ng-container>
       <ng-container *ngIf="type =='voting_form'">
        <ng-container *ngFor="let head of StaticheadingVote">
        <th>{{head}}</th>
      </ng-container>
       </ng-container>
        <!---------------------- // END   Static Headings as our requirement ------------------------>
          
        <!---------------------- // Dynamic Headings as our requirement ------------------------>
        <ng-container *ngIf="type =='standard_form'">
          <ng-container *ngFor="let items of  item.form_data.attributes" >
      <!-- -------- PB HEad -->
      <ng-container *ngIf="item.form_data.form_break">

             <ng-container *ngFor="let ite of  items">    

      <th *ngIf="ite.type !='paragraph'&& ite.type!='group'&& ite.type!='break'" >{{ite.label}}</th>
      <!-- -------------group fields------------ -->
     <ng-container  *ngIf=" ite.type=='group'" >
<ng-container *ngFor="let group of ite.groupFields">
<th width="20%" *ngIf="group.type !='paragraph'" >{{group.label}}</th>

</ng-container>
      </ng-container>
      <!-- ------------------------ -->
  </ng-container>
</ng-container>
<!-- ---------NoPB head -->
<ng-container *ngIf="!item.form_data.form_break">


<th width="20%" *ngIf="items.type !='paragraph'&& items.type!='group'&& items.type!='break'" >{{items.label}}</th>
<!-- ------------group Fields------------- -->
<ng-container  *ngIf=" items.type=='group'" >
<ng-container *ngFor="let group of items.groupFields">
<th width="20%" *ngIf="group.type !='paragraph'" >{{group.label}}</th>

</ng-container>
</ng-container>
<!-- --------------------------- -->
</ng-container>
    </ng-container>

</ng-container>
<ng-container *ngIf="type =='voting_form'">
  <ng-container *ngFor="let items of  item.form_data.attributes" >
<!-- -------- PB HEad -->

<!-- ---------NoPB head -->
<!-- <ng-container *ngIf="!item.form_data.form_break"> -->


<th width="20%" *ngIf="items.type !='paragraph'&& items.type!='group'&& items.type!='break'" >{{items.label}}</th>
<!-- ------------group Fields------------- -->
<ng-container  *ngIf=" items.type=='group'" >
<ng-container *ngFor="let group of items.groupFields">
<th width="20%" *ngIf="group.type !='paragraph'" >{{group.label}}</th>

</ng-container>
</ng-container>
<!-- --------------------------- -->
<!-- </ng-container> -->
</ng-container>

</ng-container>

        <!---------------------- // END   Dynamic Headings as our requirement ------------------------>

    </thead>
 <tbody>
        <tr >
        <ng-container *ngIf="type =='standard_form'">

            <td class="mouse-over"><a class="subid mouse-over"style="
                cursor: pointer;" (click)="redirect(item.id,item.form_id,item.user_id, item.form_type)">#{{item.submission_id ==null? "0":item.submission_id}} </a> 
              </td>
             
              <td style="min-width: 130px;">{{item.user_first_name}}</td>
              <td style="min-width: 130px;">{{item.user_last_name}}</td>
              <td style="min-width: 250px;">{{item.user_email}}</td>
              <td style="min-width: 100px;">{{item.user_type}}</td>
              <td style="min-width: 100px;">{{item.main}}</td>
              <td style="min-width: 120px;">{{item.phone_number}}</td>
              <td style="min-width: 180px; white-space: normal;"><span *ngIf="item?.form_info?.category_name!=''">
                {{item?.form_info?.category_name}}/{{item?.form_info?.sub_category_name
                }}<span *ngIf="item?.form_info?.sub_sub_category_name!=''">/</span>{{item?.form_info?.sub_sub_category_name}}

              </span></td>
              <td style="min-width: 180px;">{{item.booking_date}}</td>
              <td style="min-width: 200px;">{{item.payment_status}}</td>
              <td style="min-width: 120px;">{{item.booking_form_price}}</td>
              <td style="min-width: 110px;">{{item.discount_fee}}</td>
              <td style="min-width: 115px;">{{item.total_amount}}</td>
              <td style="min-width: 120px;">{{item.total_transactions}}</td>
              <td style="min-width: 120px;">{{item.discount_fee=="QAR 0.00"? 'NO' :'YES'}}</td>
              <td style="min-width: 80px;">{{item.is_public == '0' ? 'Private' : 'Public' }}</td>
              <td style="min-width: 50px;">{{item.age}}</td>
              <td style="min-width: 80px;">{{item.gender.charAt(0).toUpperCase() + item.gender.slice(1)}}</td>
              <td style="min-width: 200px;">{{item.nationality_country}}</td>
              </ng-container>
              <ng-container *ngIf="type =='voting_form'">

                <td class="mouse-over"><a class="subid mouse-over"style="
                    cursor: pointer;" (click)="redirect(item.id,item.form_id,item.user_id)">#{{item.submission_id ==null? "0":item.submission_id}} </a> 
                  </td>
                 
                  <td style="min-width: 130px;">{{item.user_first_name}}</td>
                  <td style="min-width: 130px;">{{item.user_last_name}}</td>
                  <td style="min-width: 250px;">{{item.user_email}}</td>
                  <td style="min-width: 100px;">{{item.user_type}}</td>
                  <td style="min-width: 100px;">{{item.main}}</td>
                  <td style="min-width: 120px;">{{item.phone_number}}</td>
          
                  <td style="min-width: 180px;">{{item.booking_date}}</td>
               
                  <td style="min-width: 80px;">{{item.is_public == '0' ? 'Private' : 'Public' }}</td>
                  <td style="min-width: 50px;">{{item.age}}</td>
                  <td style="min-width: 80px;">{{item.gender.charAt(0).toUpperCase() + item.gender.slice(1)}}</td>
                  <td style="min-width: 200px;">{{item.nationality_country}}</td>
                  </ng-container>
              <!-- <ng-container *ngIf="type=='voting_form'">    
                  <td style="min-width: 130px;">{{item.first_name}}</td>
                  <td style="min-width: 130px;">{{item.last_name}}</td>
                  <td style="min-width: 130px;">{{item.to}}</td>
                  <td style="min-width: 150px;">{{item.subject}}</td>
              </ng-container> -->
                  <ng-container *ngIf="type =='standard_form'">
                    <ng-container *ngFor="let items of  item.form_data.attributes;index as j" >
                      <!-- --------PB FORM------------- -->
                      <ng-container *ngIf="item.form_data.form_break">
                        <ng-container *ngFor="let ite of items;index as lj">
                          <td [ngClass]="ite.type == 'date' || ite.type == 'datetime-local' ? 'datetime' : '' || ite.type == 'textarea' ? 'multiline' : '' || ite.type == 'email' ? 'email' : ''" 
                          *ngIf="ite.type!='group' &&ite.type !='break' && ite.type!='autocomplete'&& ite.type!='radio'&& ite.type!='checkbox' && ite.type!='file'&& ite.type!='paragraph' && ite.type!='address-group'&& ite.type!='likert'" style="min-width: 130px;">{{ite.value}}</td>
                          <ng-container *ngIf="ite.type=='radio'">
                            <ng-container *ngIf="ite.value !=null; else radiofamily">
                                <td [ngClass]="ite.type == 'date' || ite.type == 'datetime-local' ? 'datetime' : '' || ite.type == 'textarea' ? 'multiline' : '' || ite.type == 'email' ? 'email' : ''" *ngIf="ite.type == 'radio'">
                                <ng-container *ngIf="(ite.value =='other' || ite.value =='otheroption');else radioOptions">
                                  {{ ite.otherFieldOptionLabel }}
                                </ng-container>
                                <ng-template #radioOptions>
                                    <!-- <h6>{{ ite.label }} XX</h6> -->
                                    <ng-container *ngFor="let val of ite.values">
                                      <td [ngClass]="val.type == 'datetime-local' ? 'datetime' : '' || val.type == 'textarea' ? 'multiline' : '' || val.type == 'email' ? 'email' : ''" *ngIf="val.value == ite.value">{{val.label}}</td>
                                      <!-- <td *ngIf="ite.value ==null">KBCC</td> -->
                                    </ng-container>
                                  </ng-template>
                                  <ng-container *ngIf="ite.allowOthers && ite.otherTextFieldValue != '' && (ite.value =='other' || ite.value =='otheroption')">
                                    <h6 style="min-width: 130px; white-space: normal;">{{ ite?.otherFieldLabel }}</h6>
                                    <p style="min-width: 130px; white-space: normal;">{{ ite?.otherTextFieldValue }}</p>
                                  </ng-container>
                                </td>
                                </ng-container>
                                <ng-container *ngIf="ite.value==null">
                                    <td style="min-width: 130px;"></td>
                                </ng-container>
                            </ng-container>
            
                        <!-- ------------------ autocomplete and checkbox----------- -->
                        <ng-container *ngIf="ite.type=='autocomplete' || ite.type=='checkbox'">
                      <ng-container *ngIf="ite.value.length<=1">
                          <ng-container *ngIf="ite.value !=null;else radiofamily">
                      <ng-container *ngFor="let val of ite.values">
                        <td style="min-width: 110px; white-space: normal;" *ngIf="val.value == ite.value">{{val.label.slice(0,50)}}<ng-container *ngIf="val.label.length >50" >....</ng-container></td>
                        <!-- <td *ngIf="ite.value ==null">KBCC</td> -->
                      </ng-container>
                  </ng-container>
                  </ng-container>
                  <td style="min-width: 100px; white-space: normal;" *ngIf="ite.value.length>1">
                    <ng-container *ngFor="let val of ite.values">
                      <ng-container *ngFor="let ites of ite.value;let p=index">
                        <ng-container *ngIf="val.value ==ites">{{val.label}}
                        </ng-container>
                      <!-- <td *ngIf="ite.value ==null">KBCC</td> -->
                      </ng-container>
                    </ng-container>
                  </td>
                  <ng-container *ngIf="ite.value==null">
                      <td style="min-width: 80px;"></td>
                  </ng-container>
                      </ng-container>
                        <!-- -------------file------------ -->
                        <ng-container *ngIf="ite.type =='file'">
                          <ng-container *ngIf="ite.value!=null">
                            <ng-container *ngFor="let img of ite.values">
                              <td class="reviewFiledynamic"  >
                                <img alt="" [src]="img" />
                              </td>
                            </ng-container>
                        </ng-container>
                            <td style="min-width: 80px;" *ngIf="ite.value ==null"></td>  
                        </ng-container>
                        <!-- -------------address------------ -->
                        <ng-container *ngIf="ite.type =='address-group'">
                          <td style="min-width: 200px;">
                            <ng-container *ngFor="let addr of ite.addressGroup">
                              <span *ngIf="addr.value != ''"><b>{{ addr.label }}</b></span>
                              <p>{{ addr.value }}</p>
                            </ng-container>
                          </td>
            
                        </ng-container>
                        <!-- -------------likert------------ -->
                        <ng-container *ngIf="ite.type == 'likert'">
                          <ng-container *ngFor="let likertitem of ite.QuestionArr">
                            <ng-container *ngIf="likertitem.value != ''">
                              <td>
                              <b>{{ likertitem.Question }}</b>
                              <ng-container *ngFor="let ans of likertitem.values">
                                <p *ngIf="ans.value === likertitem.value">
                                  {{ ans.label }}
                                </p>
                              </ng-container>
                            </td>
                            </ng-container>
                          </ng-container>
                        </ng-container>
                        <!-- -------------group------------ -->
                        <ng-container *ngIf="ite.type=='group'">
                          <ng-container *ngFor="let group of ite.groupFields">
                            <td *ngIf="group.type!='autocomplete'&& group.type!='radio'&& group.type!='checkbox' && group.type!='file'&& group.type!='paragraph' && group.type!='address-group'&& group.type!='likert'">{{group.value}}</td>
                            <ng-container *ngIf="  group.type=='radio'">
                              <td *ngIf="group.value != null">
                                <!-- {{ group.label }} -->
                                <ng-container *ngIf="(group.value === 'other' || group.value === 'otheroption');else pbGroupRadioOptions">
                                  {{group.otherFieldOptionLabel}}
                                </ng-container>
                                <ng-template #pbGroupRadioOptions>
                                  <ng-container *ngIf="group.value !=null">
                                    <ng-container *ngFor="let val of group.values">
                                      <td *ngIf="val.value ==group.value">{{val.label}}</td>
                                    </ng-container>
                                  </ng-container>
                                </ng-template>
                                <ng-container *ngIf="group.allowOthers && group.otherTextFieldValue != '' && (group.value === 'other' || group.value === 'otheroption')">
                                  <h6 style="min-width: 130px; white-space: normal;">{{ group?.otherFieldLabel }}</h6>
                                  <p style="min-width: 130px; white-space: normal;">{{ group?.otherTextFieldValue }}</p>
                                </ng-container>
                              </td>
                              <td *ngIf="group.value==null"></td>
                            </ng-container>
                            <!-- ---------autocomplete and checkbox--------- -->
                            <ng-container *ngIf=" group.type=='autocomplete'||  group.type=='checkbox'">
                              <ng-container *ngIf=" group.value.length<=1">
                                <ng-container *ngIf="group.value !=null;else radiofamily">
                            <ng-container *ngFor="let val of group.values">
                              <td *ngIf="val.value ==group.value ">{{val.label.slice(0,50)}}<ng-container *ngIf="val.label.length >50" >....</ng-container> </td>
                              <!-- <td *ngIf="ite.value ==null">KBCC</td> -->
                            </ng-container>
                        </ng-container>
                        </ng-container>
                        <td *ngIf=" group.value.length>1">
                      <ng-container *ngFor="let val of group.values">
                        <ng-container *ngFor="let ites of group.value;let p=index">
                  
                        <ng-container *ngIf="val.value ==ites  ">{{val.label}} </ng-container>
                        <!-- <td *ngIf="ite.value ==null">KBCC</td> -->
                      </ng-container>
                    </ng-container>
                  </td>
                    <ng-container *ngIf="group.value==null">
                        <td style="min-width: 130px;"></td>
                    </ng-container>
                            </ng-container>
                              <ng-container *ngIf="group.type =='file'">
                                <ng-container *ngIf="group.value!=null">
                                <ng-container *ngFor="let img of group.values">
                                  <td class="reviewFiledynamic"  >
                                    <img alt="" [src]="img" />
                                  </td>
                                </ng-container>
                              </ng-container>
                              <td *ngIf="group.value==null"></td>
                              </ng-container>
                              <ng-container *ngIf="group.type =='address-group'">
                                <td style="min-width: 200px;">
                                  <ng-container *ngFor="let addr of group.addressGroup" >
                                    <span *ngIf="addr.value != ''"><b>{{ addr.label }}</b></span>
                                    <p>{{ addr.value }}</p>
                                  </ng-container>
                                </td>
                              </ng-container>
                              <ng-container *ngIf="group.type == 'likert'">
                                <ng-container *ngFor="let likertitem of group.QuestionArr">
                                  <ng-container *ngIf="likertitem.value != ''">
                                    <td>
                                      <b>{{ likertitem.Question }}</b>
                                      <ng-container *ngFor="let ans of likertitem.values">
                                        <p *ngIf="ans.value === likertitem.value">
                                          {{ ans.label }}
                                        </p>
                                      </ng-container>
                                    </td>
                                  </ng-container>
                                </ng-container>
                              </ng-container>
                          </ng-container>
                        </ng-container>
            
            </ng-container>
            </ng-container>
            <!-- -------------------No PB FORM -->
            <ng-container *ngIf="!item.form_data.form_break">
            <td [ngClass]="items.type == 'date' || items.type == 'datetime-local' ? 'datetime' : '' || items.type == 'text' ? 'singleLine' : '' || items.type == 'textarea' ? 'multiline' : '' || items.type == 'email' ? 'email' : ''" *ngIf="items.type!='group' && items.type !='break' && items.type!='autocomplete'&& items.type!='radio'&& items.type!='checkbox' && items.type!='file'&& items.type!='paragraph' && items.type!='address-group'&& items.type!='likert'">{{items.value}}</td>
              <ng-container *ngIf="items.type=='radio'">
                <td *ngIf="items.value!=null" style="min-width: 130px;">
                  <h6>{{ items.label }}</h6>
                  <ng-container *ngIf="(items.value === 'other' || items.value === 'otheroption');else singlePageRadioOptions">
                    {{items.otherFieldOptionLabel}}
                  </ng-container>
                  <ng-template #singlePageRadioOptions>
                    <ng-container  *ngIf="items.value !=null;else radiofamily">
                      <ng-container *ngIf="items.value!=null">
                        <ng-container *ngFor="let val of items.values">
                          <td *ngIf="val.value ==items.value ">{{val.label}}</td>
                        </ng-container>
                      </ng-container>
                    </ng-container>
                  </ng-template>
                  <ng-container *ngIf="items.allowOthers && items.otherTextFieldValue != '' && (items.value === 'other' || items.value === 'otheroption')">
                    <h6 style="min-width: 130px; white-space: normal;">{{items?.otherFieldLabel}}</h6>
                    <p style="min-width: 130px; white-space: normal;">{{ items?.otherTextFieldValue }}</p>
                  </ng-container>
                </td>
                <td *ngIf="items.value==null" style="min-width: 130px;"></td>
              </ng-container>
                    <ng-container *ngIf=" items.type=='autocomplete' || items.type=='checkbox'">
                      <ng-container *ngIf=" items.value.length<=1">
                        <ng-container *ngFor="let val of items.values">
                          <td  style="min-width: 110px; white-space: normal;" *ngIf="val.value ==items.value ">{{val.label.slice(0,50)}}<ng-container *ngIf="val.label.length >50" >....</ng-container></td>
                          <!-- <td *ngIf="ite.value ==null">KBCC</td> -->
                        </ng-container>
                      </ng-container>
                      <td [ngClass]="items.value.length>1 ? 'decisionBox' : ''" *ngIf="items.value.length>1">
                        <ng-container *ngFor="let val of items.values">
                          <ng-container *ngFor="let ites of items.value;let p=index">
                            <ng-container *ngIf="val.value ==ites">{{val.label}}</ng-container>
                          <!-- <td *ngIf="ite.value ==null">KBCC</td> -->
                          </ng-container>
                        </ng-container>
                      </td>
                      <ng-container *ngIf="items.value==null">
                        <td style="min-width: 130px;"></td>
                      </ng-container>
                    </ng-container>
                    <ng-container *ngIf="items.type =='file'">
                      <ng-container *ngIf="items.value !=null">
                        <ng-container *ngFor="let img of items.values">
                          <td class="reviewFiledynamic">
                            <img alt="" [src]="img" />
                          </td>
                        </ng-container>
                      </ng-container>
                      <td *ngIf="items.value==null" style="min-width: 130px;"></td>
                    </ng-container>
                    <ng-container *ngIf="items.type == 'address-group'">
                      <td>
                        <ng-container *ngFor="let addr of items.addressGroup" >
                          <span><b>{{ addr.label }}</b></span>
                          <p>{{ addr.value }}</p>
                        </ng-container>
                      </td>
                    </ng-container>
                    <ng-container *ngIf="items.type == 'likert'">
                      <ng-container *ngFor="let likertitem of items.QuestionArr">
                        <ng-container *ngIf="likertitem.value != ''">
                          <td>
                          <b>{{ likertitem.Question }}</b>
                          <ng-container *ngFor="let ans of likertitem.values">
                            <p *ngIf="ans.value === likertitem.value">
                              {{ ans.label }}
                            </p>
                          </ng-container>
                        </td>
                        </ng-container>
                      </ng-container>
                    </ng-container>
                    <ng-container *ngIf="items.type=='group'">
                      <ng-container *ngFor="let group of items.groupFields">
                        <td *ngIf="group.type!='autocomplete'&& group.type!='radio'&& group.type!='checkbox' && group.type!='file'&& group.type!='paragraph' && group.type!='address-group'&& group.type!='likert'">{{group.value}}</td>
                        <ng-container *ngIf="group.type=='radio'">
                          <td *ngIf="group.value!=null">
                            <!-- <h6>{{group.label}}</h6> -->
                            <ng-container *ngIf="(group.value === 'other' || group.value === 'otheroption');else groupRafdioOptions">
                              {{group.otherFieldOptionLabel}}
                            </ng-container>
                            <ng-template #groupRafdioOptions>
                              <ng-container  *ngIf="group.value !=null;else radiofamily">
                                <ng-container *ngIf="group.value!=null">
                                  <ng-container *ngFor="let val of group.values">
                                    <td *ngIf="val.value ==group.value ">{{val.label}}</td>
                                  </ng-container>
                                </ng-container>
                              </ng-container>
                            </ng-template>
                            <ng-container *ngIf="group.allowOthers && group.otherTextFieldValue != '' && (group.value === 'other' || group.value === 'otheroption')">
                              <h6 style="min-width: 130px; white-space: normal;">{{ group?.otherFieldLabel }}</h6>
                              <p style="min-width: 130px; white-space: normal;">{{ group?.otherTextFieldValue }}</p>
                            </ng-container>
                          </td>
                          <td *ngIf="group.value==null"></td>
                        </ng-container>
                      <ng-container *ngIf=" group.type=='autocomplete' || group.type=='checkbox'">
                        <ng-container *ngIf=" group.value.length<=1">
                          <ng-container *ngFor="let val of group.values">
                            <td style="min-width: 80px;" *ngIf="val.value ==group.value ">{{val.label.slice(0,50)}}<ng-container *ngIf="val.label.length >50" >....</ng-container></td>
                            <!-- <td *ngIf="ite.value ==null">KBCC</td> -->
                          </ng-container>
                        </ng-container>
                        <td *ngIf=" group.value.length>1">
                          <ng-container *ngFor="let val of group.values">
                            <ng-container *ngFor="let ites of group.value;let p=index">
                              <ng-container *ngIf="val.value ==ites  ">{{val.label}}</ng-container>
                            </ng-container>
                          </ng-container>
                        </td>
                        <ng-container *ngIf="group.value==null">
                            <td style="min-width: 130px;"></td>
                        </ng-container>
                      </ng-container>
                      <ng-container *ngIf="group.type =='file'">
                        <ng-container *ngIf="group.value !=null">
                          <ng-container *ngFor="let img of group.values">
                            <td class="reviewFiledynamic"><img alt="" [src]="img" /></td>
                          </ng-container>
                        </ng-container>
                        <td *ngIf="group.value ==null"></td>
                      </ng-container>
                      <ng-container *ngIf="group.type =='address-group'">
                        <td>
                          <ng-container *ngFor="let addr of group.addressGroup" >
                            <span><b>{{ addr.label }}</b></span>
                            <p>{{ addr.value }}</p>
                          </ng-container>
                        </td>
                      </ng-container>
                      <ng-container *ngIf="group.type == 'likert'">
                        <ng-container *ngFor="let likertitem of group.QuestionArr">
                          <ng-container *ngIf="likertitem.value != ''">
                            <td>
                            <b>{{ likertitem.Question }}</b>
                            <ng-container *ngFor="let ans of likertitem.values">
                              <p *ngIf="ans.value === likertitem.value">
                                {{ ans.label }}
                              </p>
                            </ng-container>
                          </td>
                          </ng-container>
                        </ng-container>
                      </ng-container>
                    </ng-container>
                  </ng-container>
              </ng-container>
            </ng-container>

                  </ng-container>
                  <ng-container *ngIf="type =='voting_form'">
                    <ng-container *ngFor="let items of  item.form_data.attributes;index as j" >
                          <!-- --------PB FORM------------- -->
                  
            <!-- -------------------No PB FORM -->
            
            <td *ngIf="items.type!='group' && items.type !='break' && items.type!='autocomplete'&& items.type!='radio'&& items.type!='checkbox' && items.type!='file'&& items.type!='paragraph' && items.type!='address-group'&& items.type!='likert'">{{items.value}} </td>
                    <ng-container *ngIf="items.type=='radio'">
                        <ng-container  *ngIf="items.value !=null;else radiofamily">
                          <ng-container *ngIf="items.value!=null">
                    <ng-container *ngFor="let val of items.values">
                      <td *ngIf="val.value ==items.value ">{{val.label}}</td>
                    </ng-container>
                  </ng-container>
                </ng-container>
                    <td *ngIf="items.value==null" style="min-width: 130px;"></td>
                    </ng-container>
                    <ng-container *ngIf=" items.type=='autocomplete' || items.type=='checkbox'">
                      <ng-container *ngIf=" items.value.length<=1">
                    <ng-container *ngFor="let val of items.values">
                      <td *ngIf="val.value ==items.value ">{{val.label.slice(0,50)}}<ng-container *ngIf="val.label.length >50" >....</ng-container></td>
                      <!-- <td *ngIf="ite.value ==null">KBCC</td> -->
                    </ng-container>
                </ng-container>
                <td *ngIf=" items.value.length>1">
              <ng-container *ngFor="let val of items.values">
                <ng-container *ngFor="let ites of items.value;let p=index">
            
                <ng-container *ngIf="val.value ==ites  ">{{val.label}} </ng-container>
                <!-- <td *ngIf="ite.value ==null">KBCC</td> -->
              </ng-container>
            </ng-container>
            </td>
                <ng-container *ngIf="items.value==null">
                    <td style="min-width: 130px;"></td>
                </ng-container>
                  </ng-container>
                    <ng-container *ngIf="items.type =='file'">
                      <ng-container *ngIf="items.value !=null">
            
                      <ng-container *ngFor="let img of items.values">
                        <td class="reviewFiledynamic"  >
                          <img alt="" [src]="img" />
            
                        </td>
                      </ng-container>
                    </ng-container>
                    <td *ngIf="items.value==null" style="min-width: 130px;"></td>
                    
                    </ng-container>
                    <ng-container *ngIf="items.type =='address-group'">
            <td>
            
                      <ng-container *ngFor="let addr of items.addressGroup" >
            <span><b>{{ addr.label }}</b></span>
            <p>{{ addr.value }}</p>
                      </ng-container>
            </td>
            
                    </ng-container>
                    <ng-container *ngIf="items.type == 'likert'">
                      <ng-container *ngFor="let likertitem of items.QuestionArr">
                        <ng-container *ngIf="likertitem.value != ''">
                          <td>
                          <b>{{ likertitem.Question }}</b>
                          <ng-container *ngFor="let ans of likertitem.values">
                            <p *ngIf="ans.value === likertitem.value">
                              {{ ans.label }}
                            </p>
                          </ng-container>
                        </td>
                        </ng-container>
                      </ng-container>
                    </ng-container>
                    <ng-container *ngIf="items.type=='group'">
                      <ng-container *ngFor="let group of items.groupFields">
                        <td *ngIf="group.type!='autocomplete'&& group.type!='radio'&& group.type!='checkbox' && group.type!='file'&& group.type!='paragraph' && group.type!='address-group'&& group.type!='likert'">{{group.value}}</td>
                        <ng-container *ngIf="group.type=='radio'">
                          <ng-container  *ngIf="group.value !=null;else radiofamily">
                            <ng-container *ngIf="group.value!=null">
                      <ng-container *ngFor="let val of group.values">
                        <td *ngIf="val.value ==group.value ">{{val.label}}</td>
                      </ng-container>
                    </ng-container>
                  </ng-container>
                      <td *ngIf="group.value==null"></td>
                      </ng-container>
              
                      <ng-container *ngIf=" group.type=='autocomplete' || group.type=='checkbox'">
                        <ng-container *ngIf=" group.value.length<=1">
                      <ng-container *ngFor="let val of group.values">
                        <td *ngIf="val.value ==group.value ">{{val.label.slice(0,50)}}<ng-container *ngIf="val.label.length >50" >....</ng-container> </td>
                        <!-- <td *ngIf="ite.value ==null">KBCC</td> -->
                      </ng-container>
                  </ng-container>
                  <td *ngIf=" group.value.length>1">
                <ng-container *ngFor="let val of group.values">
                  <ng-container *ngFor="let ites of group.value;let p=index">
              
                  <ng-container *ngIf="val.value ==ites  ">{{val.label}} </ng-container>
                  <!-- <td *ngIf="ite.value ==null">KBCC</td> -->
                </ng-container>
              </ng-container>
              </td>
                  <ng-container *ngIf="group.value==null">
                      <td style="min-width: 130px;"></td>
                  </ng-container>
                    </ng-container>
                          <ng-container *ngIf="group.type =='file'">
                            <ng-container *ngIf="group.value !=null">
                            <ng-container *ngFor="let img of group.values">
                              <td class="reviewFiledynamic"  >
                                <img alt="" [src]="img" />
            
                              </td>
                            </ng-container>
                          </ng-container>
                          <td *ngIf="group.value ==null"></td>
                          </ng-container>
                          <ng-container *ngIf="group.type =='address-group'">
                            <td>
                            
                                                    <ng-container *ngFor="let addr of group.addressGroup" >
                              <span><b>{{ addr.label }}</b></span>
                              <p>{{ addr.value }}</p>
                                                    </ng-container>
                            </td>
                            
                                                  </ng-container>
                                                  <ng-container *ngIf="group.type == 'likert'">
                                                    <ng-container *ngFor="let likertitem of group.QuestionArr">
                                                      <ng-container *ngIf="likertitem.value != ''">
                                                        <td>
                                                        <b>{{ likertitem.Question }}</b>
                                                        <ng-container *ngFor="let ans of likertitem.values">
                                                          <p *ngIf="ans.value === likertitem.value">
                                                            {{ ans.label }}
                                                          </p>
                                                        </ng-container>
                                                      </td>
                                                      </ng-container>
                                                    </ng-container>
                                                  </ng-container>
                      </ng-container>
                    </ng-container>
            
            </ng-container>

                  </ng-container>
<!-- </ng-container> -->

    </tr>
 </tbody>
</table>
</ng-container>
<div class="table_pagination pt-4">
  <div class="d-flex" *ngIf="pagefilters.perpage !=''">
    <pagination-controls 
        [directionLinks]="true" 
        previousLabel="" 
        nextLabel="" 
        (pageChange)="pageshift($event)" 
        >
    </pagination-controls>
  </div>
</div>
</div>