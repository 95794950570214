import { Component, ElementRef, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, Validators, FormGroup, FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CookieService } from 'ngx-cookie-service';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { CommonService } from 'src/app/shared/services/common.service';
import { AuthService } from '../../shared/services/firebase/auth.service';

const moment = require('moment-timezone');

declare var require
const Swal = require('sweetalert2')
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  @ViewChild("addBookmark", { static: false }) addBookmark: TemplateRef<any>;
  // @ViewChild("ResetPass", { static: false }) ResetPass: TemplateRef<any>;
  // @ViewChild("content",{static:true}) content:ElementRef;
  
  public show: boolean = false;
  public cshow: boolean = false;
  public rshow: boolean = false;
  passwordPattern = new RegExp(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]).{5,}$/);

  public loginForm: FormGroup;
  public errorMessage: any;
  rememberChecked: boolean=false;
  public resetForm: FormGroup;
  public ForgotForm: FormGroup;
  user_role_id:any
  user_per:any
  content:any
  emaildupmess:any=''
  LoaderClass:boolean=false
  echeck:any=''
  eforpass:any=''
  constructor(public authService: AuthService, private fb: FormBuilder, public toster: ToastrService, private modalService: NgbModal,private commonService: CommonService, private cookieService: CookieService,private router:Router) {
      this.loginForm = this.fb.group({
        email: ['', [Validators.required, 
          // Validators.email
        ]],
        password: ['', [Validators.required, Validators.pattern(this.passwordPattern)]],
        rememberMe: false,
        recaptcha: ["", [Validators.required]],

      });
      this.ForgotForm = this.fb.group({
        forgotemail: ['', [Validators.required, 
          // Validators.email
        ]],
       

      });
      this.resetForm=this.fb.group({
        password: new FormControl(null, [Validators.required, Validators.pattern(this.passwordPattern)]),
        cpassword: new FormControl(null,[Validators.required]), 
      },
      {
        validators: this.MustMatch('password', 'cpassword')
      }
      )
  }
  MustMatch (controlName:string,matchingControlName:string) {
    return(formGroup:FormGroup) => {
      const control = formGroup.controls[controlName];
      const matchingControl = formGroup.controls[matchingControlName];
      if(matchingControl.errors && !matchingControl.errors['MustMatch']){
        return
      }
      if(control.value !== matchingControl.value){
        matchingControl.setErrors({MustMatch:true});
      }
      else{
        matchingControl.setErrors(null);
      }
    }
  }
  ngOnInit() {
    // this.openwindowClass('content')
  }

  // openwindowClass(content) {
  //   this.modalService.open(content, { windowClass: 'resetPassModal' });
  // }

  showPassword() {
    this.show = !this.show;
  }

  showrPassword() {
    this.rshow = !this.rshow;
  }

  showCpassword() {
    this.cshow = !this.cshow;
  }

  //rem check
  remcheck(){
    this.rememberChecked=true 
}
destroy$: Subject<boolean> = new Subject<boolean>();

  // Simple Login
  withAjax(content2) {
      this.modalService.open(content2, { windowClass: 'externalLinkModal' });
   if(this.loginForm.get('email').invalid) {
    this.ForgotForm.get('forgotemail').setValue("")
   }
   else{
    this.ForgotForm.get('forgotemail').setValue(this.loginForm.get('email').value)

   }
  //   Swal.fire({
  //     title: 'Forgot Password?',
  //     input: 'email',
  //     value:'sdasda',
  //     inputPlaceholder: 'Email*',
  //     inputValue:this.loginForm.value['email'],
  //     showCancelButton: true,
  //     confirmButtonText: 'Submit',
  //     showLoaderOnConfirm: true,
  //     preConfirm: (email) => {
  //       let data={
  //         email:email
  //       }
  //       this.authService.ForgotPass(data)
        
  //       // return fetch(`//api.github.com/users/${login}`)
  //         // .then(response => {
  //         //   if (!response.ok) {
  //         //     throw new Error(response.statusText)
  //         //   }
  //         //   return response.json()
  //         // })
  //         // .catch(error => {
  //         //   Swal.showValidationMessage(
  //         //     `Request failed: ${error}`
  //         //   )
  //         // })
  //     },
  //     allowOutsideClick: () => !Swal.isLoading()
  //   })
  //   // .then((result) => {
  //   //   if (result.value) {
  //   //     Swal.fire({
  //   //       title: `${result.value.login}'s avatar`,
          
  //   //     })
  //   //   }
  //   // })
  }
  ValEmail(event:any){
    // if(this.firstFormGroup.get('email').valid){
    //   let data={
    //     email:this.firstFormGroup.get('email').value
    //   }
    let data ={
email:event.target.value
    }
    if(event.target.value !=''){
      this.authService.getEmailValidation(data).pipe(takeUntil(this.destroy$)).subscribe(result => {
  
         this.emaildupmess=''
     },error =>{
      this.emaildupmess=error.error.message

     } )

    }
      
    }
  login(content) {
    if(this.emaildupmess !=''){
      let data ={
        email:this.loginForm.value['email']
            }
      this.authService.getEmailValidation(data).pipe(takeUntil(this.destroy$)).subscribe(result => {
  
        this.emaildupmess=''
        this.loginapihit(content)
    },error =>{
     this.emaildupmess=error.error.message

    } )
      
    }
    else{
      this.loginapihit(content)

    }
  
  }
  loginapihit(content){
    if (this.loginForm.valid ){
      let data={
        "email": this.loginForm.value['email'],
            "password": this.loginForm.value['password'],
            "remember_me": this.rememberChecked,
            "timezone": moment.tz.guess()
      }
      // this.authService.SignIn(data);
      if(this.emaildupmess==''&& this.echeck ==''){
        this.authService.SignIn(data).pipe(takeUntil(this.destroy$)).subscribe(result => {
          if(result['data']['user_role'] !='User'){
        
           if(result && result['success']) {
     
             // let d = new Date();
             // d.setHours( d.getHours() + 12 );
             const dateNow = new Date();
             dateNow.setMinutes(dateNow.getMinutes() + result['data']['remember_time']);
             
             if(!this.cookieService.get('token_type') && !this.cookieService.get('access_token') && !this.cookieService.get('user_role') && !this.cookieService.get('user_name') && !this.cookieService.get('formval')) {
               this.cookieService.set('token_type', result['data']['token_type'],dateNow );
               this.cookieService.set('access_token', result['data']['access_token'], dateNow);
               this.cookieService.set('user_role', result['data']['user_role'],dateNow);
               this.cookieService.set('user_name', result['data']['full_name'], dateNow);
               this.cookieService.set('formval', result['data']['forms_exists'], dateNow);
               this.cookieService.set('user_role_id',result['data']['user_role_id'])
             }
             this.user_role_id=result['data']['user_role_id']
             this.user_per=result['data']['user_permissions']
         
             if(result['data']['first_login'] =='0'){
  
             this.permissiongrant()      
                    }      
                       else{
  // this.openwindowClass() 
            this.modalService.open(content, { windowClass: 'resetPassModal' });
  
                    }
            
           } 
          }else{
            this.toster.error('Kindly check that the email you entered is correct and try again')
          }
        //  }else{
        //   // this.modalService.open(this.content, { windowClass: 'resetPassModal' });
        //  }
         },error =>{
           this.toster.error(error.error['message']);
         })

      }
      // });
    }
    else {
      for (let i in this.loginForm.controls) {
        this.loginForm.controls[i].markAsTouched();
      }
    }
  }
  emailVal(e){
    if (/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(e.target.value)|| e.target.value =='') {

      this.echeck=""
      // this.cookie.set('email', this.registerForm.get('email').value)

    }
    else{
      this.echeck="Email must be a valid email address"

    }
  }
  emailForgotVal(e){
    if (/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(e.target.value)|| e.target.value =='') {

      this.eforpass=""
      // this.cookie.set('email', this.registerForm.get('email').value)

    }
    else{
      this.eforpass="Email must be a valid email address"

    }
  }
  permissiongrant(){
    this.authService.getPermissionAccess(this.user_role_id)
  this.commonService.setUserPermissions(this.user_per);
  }
  resetpass() {
    if (this.resetForm.valid){
      let data={
        'new_password': this.resetForm.get('password').value,
         'new_confirm_password':this.resetForm.get('cpassword').value,
         'token':  this.cookieService.get('access_token')
        }
    
      this.authService.firstAdminPass(data).pipe(takeUntil(this.destroy$)).subscribe(result => {
        this.permissiongrant()
        this.modalService.dismissAll()

      //  }
       },error =>{
         this.toster.error(error.error['message']);
       })
      // firstAdminPass(data) {
      //   this.apiClientService.post('change-password', data).subscribe(result => {
      //     // this.router.navigate([''])
      //   },error=> {
      //     this.toster.error(error.error['message']);
      //     this.router.navigate([''])
      //   }
      //   )
      // }
    }
    else {
      for (let i in this.resetForm.controls) {
        this.resetForm.controls[i].markAsTouched();
      }
    }
  }
  SubmitforgotEmail() {
    if (this.ForgotForm.valid){
      let data={
        'email': this.ForgotForm.get('forgotemail').value,
     
        }
        // this.authService.ForgotPass(data)
        if(this.eforpass==''){
              this.LoaderClass=true
              this.authService.ForgotPass(data).subscribe(result => {
      this.LoaderClass=false
  
                if(result && result['success']==true) {
                  this.toster.success(result['message'],"",{
                    enableHtml: true,
                });
                  this.router.navigate(['/']);
                  this.modalService.dismissAll();
                  // alert(result['message'])
                }
              },error =>{
      this.LoaderClass=false
  
                this.toster.error(error.error['message']);
              })
              
            }
      // firstAdminPass(data) {
      //   this.apiClientService.post('change-password', data).subscribe(result => {
      //     // this.router.navigate([''])
      //   },error=> {
      //     this.toster.error(error.error['message']);
      //     this.router.navigate([''])
      //   }
      //   )
      // }
    }
    else {
      for (let i in this.ForgotForm.controls) {
        this.ForgotForm.controls[i].markAsTouched();
      }
    }
  }
}
  // emailVal(event:any){
  //   
  //   // this.emailval =true;
  //   // 
  //   if(this.registerForm.controls.email.errors ==null){
  //     
  //     this.cookie.set('email', this.registerForm.get('email').value)

  //   }
  // }



// // Login With Google
// loginGoogle() {
//   this.authService.GoogleAuth();
// }

// // Login With Twitter
// loginTwitter(): void {
//   this.authService.signInTwitter();
// }

// // Login With Facebook
// loginFacebook() {
//   this.authService.signInFacebok();
// }