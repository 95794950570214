import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './auth/login/login.component';
import { ContentComponent } from "./shared/components/layout/content/content.component";
import { FullComponent } from "./shared/components/layout/full/full.component";
import { full } from "./shared/routes/full.routes";
import { content } from "./shared/routes/routes";

import { AdminGuard } from './shared/guard/admin.guard';
import { SecureInnerPagesGuard } from './shared/guard/SecureInnerPagesGuard.guard';
import { ResetpasswordComponent } from './auth/resetpassword/resetpassword.component';
// import { BookingComponent } from './components/apps/booking/booking.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'dashboard/hbku',
    pathMatch: 'full'
  },
  {
    path: 'qdf-hbku',
    component: LoginComponent,
    canActivate: [SecureInnerPagesGuard]
  },
  {
    path: 'resetpassword/:id',
    component: ResetpasswordComponent,
    canActivate: [SecureInnerPagesGuard]
  },
  {
    path: '',
    component: ContentComponent,
    canActivate: [AdminGuard],
    children: content
  },
  {
    path: '',
    component: FullComponent,
    canActivate: [AdminGuard],
    children: full
  },
  // {
  //   path: 'bookings',
  //   component:BookingComponent ,
  //   canActivate: [AdminGuard],
  //   children: full
  // },
  {
    path: '**',
    redirectTo: ''
  },

];

@NgModule({
  imports: [[RouterModule.forRoot(routes, {
    anchorScrolling: 'enabled',
    scrollPositionRestoration: 'enabled',
    relativeLinkResolution: 'legacy'
})],
],
  exports: [RouterModule]
})
export class AppRoutingModule { }
