<div class="customizer-links" [ngClass]="{'open': customizer != '' }" (click)="Customizer('')">
   <!-- <div class="nav flex-column nac-pills" id="c-pills-tab" role="tablist" aria-orientation="vertical"> -->
      <!--- Nav Item - Dashboard --->
   <!-- </div> -->
</div>
<div class="customizer-contain" [ngClass]="{'open' : customizer != '' }">
   <div class="tab-content" id="c-pills-tabContent">
      <div class="customizer-header">
         <i class="icofont-close icon-close" (click)="Customizer('')"></i>
         <h5>Customize Settings</h5>
         <!-- <p class="mb-0"><b>Field Type: </b><span class="ucfirst">{{ formFieldItem.type }}</span></p> -->
      </div>
      <span class="fieldname">{{fieldname}}</span>
      <div class="customizer-body custom-scrollbar">
        <ng-container *ngIf="formFieldItem.type == 'break';else singleFormFieldSettings">
          <div class="form-group m-t-15 custom-radio-ml ml-2">
            <label>Show page title in</label>
            <div class="radio radio-primary">
                <input id="showformtitleinNavigationBar" type="radio" name="showPageTitleIn" value="navigationbar" [checked]="formFieldItem?.showPageTitleIn == 'navigationbar'" (change)="updateFormFieldItem($event, formFieldItem)">
                <label for="showformtitleinNavigationBar">Navigation Bar</label>
              </div>
              <div class="radio radio-primary">
                <input id="showformtitleinPage" type="radio" name="showPageTitleIn" value="page" [checked]="formFieldItem?.showPageTitleIn == 'page'" (change)="updateFormFieldItem($event, formFieldItem)">
                <label for="showformtitleinPage">Page</label>
              </div>
              <div class="radio radio-primary">
                <input id="showformtitleinNone" type="radio" name="showPageTitleIn" value="none" [checked]="formFieldItem?.showPageTitleIn == 'none'" (change)="updateFormFieldItem($event, formFieldItem)">
                <label for="showformtitleinNone">None</label>
              </div>
          </div>
          <div class="form-group m-t-15 custom-radio-ml ml-2">
            <label>Navigation Options Display</label>
            <div class="radio radio-primary">
                <input id="navigationdisplaySteps" type="radio" name="navigationOptionDisplay" value="steps" [checked]="formFieldItem?.navigationOptionDisplay == 'steps'" (change)="updateFormFieldItem($event, formFieldItem)">
                <label for="navigationdisplaySteps">Steps</label>
              </div>
              <div class="radio radio-primary">
                <input id="navigationdisplayBar" type="radio" name="navigationOptionDisplay" value="bar" [checked]="formFieldItem?.navigationOptionDisplay == 'bar'" (change)="updateFormFieldItem($event, formFieldItem)">
                <label for="navigationdisplayBar">Navigation Bar</label>
              </div>
              <div class="radio radio-primary">
                <input id="navigationdisplayNone" type="radio" name="navigationOptionDisplay" value="none" [checked]="formFieldItem?.navigationOptionDisplay == 'none'" (change)="updateFormFieldItem($event, formFieldItem)">
                <label for="navigationdisplayNone">None</label>
              </div>
          </div>

          <div class="form-group">
            <div class="col-form-label"> Perform field validations</div>
            <div class="checkbox checkbox-primary">
              <input id="nextPage" type="checkbox" name="beforeNextPage" (change)="checkboxChange($event, formFieldItem, 'beforeNextPahe')" [checked]="formFieldItem.beforeNextPage" [(ngModel)]="formFieldItem.beforeNextPage">
              <label for="nextPage">Before proceeding to the next page</label>
            </div>
            <div class="checkbox checkbox-primary">
              <input id="finalSubmission" type="checkbox" name="onSubmition" (change)="checkboxChange($event, formFieldItem, 'onFinalSubmission')" [checked]="formFieldItem.onSubmition" [(ngModel)]="formFieldItem.onSubmition">
              <label for="finalSubmission">On final submission</label>
            </div>
          </div>

          <div class="form-group">
            <div class="checkbox checkbox-primary">
              <input id="pageNumber" type="checkbox" name="displayPageNumber" (change)="checkboxChange($event, formFieldItem, 'displayPageNumber')" [checked]="formFieldItem.displayPageNumber" [(ngModel)]="formFieldItem.displayPageNumber">
              <label for="pageNumber">Display page number?</label>
            </div>
          </div>
        </ng-container>
        <ng-template #singleFormFieldSettings>
              <div class="form-group media align-items-center" *ngIf="formFieldItem.type != 'button' && formFieldItem.type != 'address-group'&& formFieldItem.type != 'paragraph' && formFieldItem.type != 'group'">
                <label class="mb-0">Is field mandatory?</label>
                <div class="media-body text-right icon-state">
                   <label class="switch">
                      <input type="checkbox" name="required" [value]="formFieldItem?.required ? formFieldItem?.required : isFieldRequired" (change)="checkboxChange($event, formFieldItem, 'isRequired')" [checked]="formFieldItem?.required ? formFieldItem?.required : isFieldRequired"><span class="switch-state"></span>
                   </label>
                </div>
              </div>
              <div class="form-group">
                <label>Label</label>
                <input
                  class="form-control"
                  type="text"
                  name="label"
                  [(ngModel)]="formFieldItem.label"
                  (keyup)="updateFormFieldItem($event, formFieldItem)"
                />
              </div>
              <div class="required_error">{{required_label_err}}</div>
              <div class="form-group" *ngIf="formFieldItem.type != 'button' && formFieldItem.type != 'group'">
                <!-- <label>{{formFieldItem.type == 'paragraph' ? 'Content' : 'Instructions'}}</label> -->
                <label *ngIf="formFieldItem.type != 'paragraph' && formFieldItem.type != 'group'">Instructions</label>
                <input
                *ngIf="formFieldItem.type != 'paragraph' && formFieldItem.type != 'group'"
                  class="form-control"
                  type="text"
                  name="description"
                  [(ngModel)]="formFieldItem.description"
                  (keyup)="updateFormFieldItem($event, formFieldItem)"
                />
                <label *ngIf="formFieldItem.type == 'paragraph'" >Content</label>
                <ck-editor *ngIf="formFieldItem.type == 'paragraph'" name="description" [config]="config" [(ngModel)]="formFieldItem.description" (keyup)="updateFormFieldItem($event, formFieldItem)"></ck-editor>
              </div>
              <!-- <div class="form-group">
                <label>Name</label>
                <input class="form-control" type="text" [(ngModel)]="formFieldItem.name" />
              </div> -->
              <div class="form-group" *ngIf="formFieldItem.type != 'file' && formFieldItem.type != 'button' && formFieldItem.type != 'address-group' && (formFieldItem.type == 'time' || formFieldItem.type == 'datetime-local' || formFieldItem.type == 'date') && formFieldItem.type != 'paragraph' && formFieldItem.type != 'group'&& formFieldItem.type != 'likert'">
                <label>Initial Value</label>
                <div *ngIf="formFieldItem.type == 'date'">
                  <input class="form-control" name="value" type="date" (change)="updateFormFieldItem($event, formFieldItem)" [(ngModel)]="formFieldItem.value" />
                </div>
                <div *ngIf="formFieldItem.type == 'time'">
                  <input class="form-control" name="value" (change)="updateFormFieldItem($event, formFieldItem)" [(ngModel)]="formFieldItem.value" type="time">
                </div>
                <div *ngIf="formFieldItem.type == 'datetime-local'">
                  <input class="form-control" name="value" type="datetime-local" (change)="updateFormFieldItem($event, formFieldItem)" [(ngModel)]="formFieldItem.value">
                </div>
              </div>
              <div class="form-group" *ngIf="formFieldItem.type == 'date'">
                <div class="col-form-label">Allowed Days?</div>
                  <ng-select [items]="weekDays" class="js-example-basic-multiple" placeholder="Select days of week"
                    [(ngModel)]="formFieldItem.allowedDays" [multiple]="true" (change)="changeAllowedDays($event, formFieldItem)"></ng-select>
                  <!-- <ng-select [items]="weekDays" bindLabel="name" bindValue="id" class="js-example-basic-multiple" placeholder="Select days of week"
                    [(ngModel)]="formFieldItem.allowedDays" [multiple]="true" (change)="changeAllowedDays(formFieldItem, $event)"></ng-select> -->
              </div>
              <div class="form-group" *ngIf="formFieldItem.type == 'date'">
                <div class="col-form-label"> Allowed Dates</div>
                <div class="checkbox checkbox-primary">
                  <input id="allDates" type="checkbox" name="allDates" (change)="checkboxChange($event, formFieldItem, 'allDates')" [checked]="formFieldItem.allDates" [(ngModel)]="formFieldItem.allDates">
                  <label for="allDates">All Dates</label>
                </div>
                <div class="checkbox checkbox-primary">
                  <input id="pastDates" type="checkbox" name="pastDates" (change)="checkboxChange($event, formFieldItem)" [checked]="formFieldItem.pastDates" [(ngModel)]="formFieldItem.pastDates">
                  <label for="pastDates">Past Dates</label>
                </div>
                <div class="checkbox checkbox-primary">
                  <input id="futureDates" type="checkbox" name="futureDates" (change)="checkboxChange($event, formFieldItem, 'futureDates')" [checked]="formFieldItem.futureDates" [(ngModel)]="formFieldItem.futureDates">
                  <label for="futureDates">Future Dates</label>
                </div>
                <div>
                  <div class="checkbox checkbox-primary">
                    <input id="rangeDates" type="checkbox" name="rangeDates" (change)="checkboxChange($event, formFieldItem, 'autofillDate')" [checked]="formFieldItem.rangeDates" [(ngModel)]="formFieldItem.rangeDates">
                    <label for="rangeDates">Date of Response</label>
                  </div>
                  <div class="col" *ngIf="formFieldItem.rangeDates">
                    <label class="col-sm-3 col-form-label">Start from</label>
                    <div class="col-sm-9">
                      <input class="form-control" type="date" [value]="formFieldItem?.startFromDate"  [max]="formFieldItem?.EndOnDate" name="startFromDate" (change)="updateFormFieldItem($event, formFieldItem)">
                    </div>
                    <label class="col-sm-3 col-form-label">End On</label>
                    <div class="col-sm-9">
                      <input class="form-control" type="date" [value]="formFieldItem?.EndOnDate" [min]="formFieldItem?.startFromDate" name="EndOnDate" (change)="updateFormFieldItem($event, formFieldItem)">
                    </div>
                  </div>
                </div>
              </div>
              <div class="form-group" *ngIf="formFieldItem.type != 'file' && formFieldItem.type != 'button' && formFieldItem.type != 'time' && formFieldItem.type != 'datetime-local' && formFieldItem.type != 'date' && formFieldItem.type != 'paragraph' && formFieldItem.type != 'address-group' && formFieldItem.subtype != 'currency' && formFieldItem.type != 'checkbox'  && formFieldItem.type != 'radio'  && formFieldItem.type != 'autocomplete' && formFieldItem.type != 'group' && formFieldItem.type != 'likert'">
                <label>Initial Value</label>
                <input class="form-control" name="value" type="text" (keyup)="updateFormFieldItem($event, formFieldItem)" [(ngModel)]="formFieldItem.value" />
              </div>
              <div class="form-group" *ngIf="formFieldItem.type == 'text' && formFieldItem.subtype && formFieldItem.subtype == 'currency'">
                <label>Initial Currency Value</label>
                <input class="form-control" type="text" name="selectedCurrency" (keyup)="updateFormFieldItem($event, formFieldItem)" [(ngModel)]="formFieldItem.value" />
              </div>
              <div class="form-group" *ngIf="formFieldItem.type == 'number' && (formFieldItem?.subtype == 'decimal' || formFieldItem?.subtype == 'currency')">
                 <label>How many decimals can the field value be rounded to?</label>
                 <input type="number" name="decimalLength" class="form-control" min="1" max="5" (keyup)="updateFormFieldItem($event, formFieldItem)" [(ngModel)]="formFieldItem.decimalLength" />
              </div>
              <ng-container *ngIf="formFieldItem.type == 'rating'">
                <div class="form-group">
                  <label>Stars Count?</label>
                  <input type="number" name="size" class="form-control" min="2" max="10" (keyup)="updateFormFieldItem($event, formFieldItem)" [(ngModel)]="formFieldItem.size" />
                </div>
                <div class="form-group">
                  <label for="minuteinterval">Rating Type</label>
                  <select class="form-control digits" name="accept" [(ngModel)]="formFieldItem.accept" (change)="updateFormFieldItem($event, formFieldItem)">
                      <option *ngFor="let type of RatingType" [value]="type.value">{{type.text}}</option>
                  </select>
                </div>
              </ng-container>
              <div class="form-group m-t-15 custom-radio-ml ml-2" *ngIf="formFieldItem.type == 'time' || formFieldItem.type == 'datetime-local'">
                <label>Time Format</label>
                <div class="radio radio-primary">
                    <input id="format12" type="radio" name="timeFormat" value="12" [checked]="formFieldItem?.timeFormat == 12" (change)="updateFormFieldItem($event, formFieldItem)">
                    <label for="format12">12 Hours</label>
                  </div>
                  <div class="radio radio-primary">
                    <input id="format24" type="radio" name="timeFormat" value="24" [checked]="formFieldItem?.timeFormat == 24" (change)="updateFormFieldItem($event, formFieldItem)">
                    <label for="format24">24 Hours</label>
                  </div>
              </div>
              <div class="form-group" *ngIf="formFieldItem.type == 'time'">
                <label for="minuteinterval">Minutes Interval</label>
                <select class="form-control digits" name="minutesInterval" [(ngModel)]="formFieldItem.minutesInterval" (change)="updateFormFieldItem($event, formFieldItem)">
                    <option *ngFor="let interval of minuteIntervals" [value]="interval.value">{{interval.value}} {{interval.minuteText}}</option>
                </select>
              </div>
              <div class="form-group" *ngIf="formFieldItem.type != 'button' && formFieldItem.type != 'date' && formFieldItem.type != 'time' && formFieldItem.type != 'datetime-local' && formFieldItem.type != 'checkbox' && formFieldItem.type != 'radio' && formFieldItem.type != 'rating' && formFieldItem.type != 'paragraph' && formFieldItem.type != 'address-group'&& formFieldItem.type != 'file' && formFieldItem.label != 'Currency' && formFieldItem.type != 'range'   && formFieldItem.type != 'group' && formFieldItem.type != 'likert'">
                <label>Placeholder</label>
                <input
                  class="form-control"
                  type="text"
                  name="placeholder"
                  (keyup)="updateFormFieldItem($event, formFieldItem)"
                  [(ngModel)]="formFieldItem.placeholder"
                />
              </div>
              <ng-container *ngIf="formFieldItem.type == 'checkbox'">
                <div class="form-group">
                  <label>Checked All message</label>
                  <input
                    class="form-control"
                    type="text"
                    name="checkedAllMessage"
                    (keyup)="updateFormFieldItem($event, formFieldItem)"
                    [(ngModel)]="formFieldItem.checkedAllMessage"
                  />
                </div>
                <div class="form-group">
                  <label>Unchecked all message</label>
                  <input
                    class="form-control"
                    type="text"
                    name="unCheckedAllMessage"
                    (keyup)="updateFormFieldItem($event, formFieldItem)"
                    [(ngModel)]="formFieldItem.unCheckedAllMessage"
                  />
                </div>
              </ng-container>
              <ng-container *ngIf="formFieldItem.type == 'file'">
                <div class="form-group">
                   <label>File Size (In MB)</label>
                   <input
                      class="form-control"
                      type="number"
                      min="1"
                      max="20"
                      name="size"
                      (keyup)="updateFormFieldItem($event, formFieldItem)"
                      [(ngModel)]="formFieldItem.size"
                   />
                </div>
                <div class="form-group">
                   <label>Allowed File Extensions (comma separated)</label>
                   <input
                      class="form-control"
                      type="text"
                      name="accept"
                      (keyup)="updateFormFieldItem($event, formFieldItem)"
                      [(ngModel)]="formFieldItem.accept"
                   />
                </div>
              </ng-container>
              <div class="form-group media align-items-center" *ngIf="formFieldItem.type == 'file' || formFieldItem.type == 'autocomplete'">
                 <label class="mb-0">Allow Multiple {{formFieldItem.type == 'file' ? 'files upload' : 'select'}}?</label>
                 <div class="media-body text-right icon-state">
                    <label class="switch">
                       <input type="checkbox" [value]="formFieldItem?.multiple" (change)="checkboxChange($event, formFieldItem, 'allowMultiple')" name="multiple" [checked]="formFieldItem?.multiple"><span class="switch-state"></span>
                    </label>
                 </div>
              </div>
              <ng-container *ngIf="formFieldItem.type == 'radio' || formFieldItem.type == 'autocomplete' ||formFieldItem.type == 'checkbox'">
                <div class="row mb-2">
                  <div class="col">
                    <label class="d-block" for="chk-allowOthers">
                      <input class="checkbox_animated" id="chk-allowOthers" type="checkbox" (change)="frontEndDisplayPriorities($event, formFieldItem, 'allowOthers')" [checked]="formFieldItem?.allowOthers">Allow Others?
                    </label>
                  </div>
                </div>
                <ng-container *ngIf="formFieldItem.allowOthers">
                  <!-- <div class="form-group">
                    <label>Other field type</label>
                    <input type="text" name="otherFieldLabel" class="form-control" (change)="updateFormFieldItem($event, formFieldItem)" [value]="formFieldItem.otherFieldLabel ? formFieldItem.otherFieldLabel : 'Other'">
  
                    <select class="form-control" name="otherFieldType" (change)="updateFormFieldItem($event, formFieldItem)">
                      <option *ngFor="let item of otherFieldType" [value]="item.value">{{item.label}}</option>
                    </select>
                  </div> -->
                     <div class="form-group">
                    <label>Initial Value</label>
                    <input type="text" name="IntialVal" class="form-control" (change)="updateFormFieldItem($event, formFieldItem)" [value]="formFieldItem.IntialVal ? formFieldItem.IntialVal : ''">
  
                   
                  </div>
                  <div class="form-group">
                    <label>Other field label</label>
                    <input type="text" name="otherFieldLabel" class="form-control" (change)="updateFormFieldItem($event, formFieldItem)" [value]="formFieldItem.otherFieldLabel ? formFieldItem.otherFieldLabel : 'Other'">
                  </div>
                  <div class="form-group">
                    <label>Other option label</label>
                    <input type="text" name="otherFieldOptionLabel" class="form-control" (change)="updateFormFieldItem($event, formFieldItem)" [value]="formFieldItem.otherFieldOptionLabel">
                  </div>
                </ng-container>
              </ng-container>
              <div class="form-group" *ngIf="formFieldItem.type == 'radio' || formFieldItem.type == 'autocomplete'">
                <label>Sort Choices</label>
                <div class="btn-group btn-group-toggle w-100" ngbRadioGroup name="fieldSizeBasic1">
                   <ng-container *ngFor="let item of SortingOptions">
                     <label ngbButtonLabel [class]="formFieldItem.sorting == item.value ? 'btn btn-outline-primary active':'btn btn-outline-primary'">
                        <input ngbButton type="radio" [value]="item.value" name="sorting" [checked]="formFieldItem.sorting == item.value" (change)="updateFormFieldItem($event, formFieldItem)"> {{item.text}}
                      </label>
                   </ng-container>
                </div>
              </div>
              <div class="form-group" *ngIf="formFieldItem.type == 'radio' || formFieldItem.type == 'checkbox'">
                <label>Show data in</label>
                <div class="btn-group btn-group-toggle w-100" ngbRadioGroup name="fieldSizeBasic1">
                   <ng-container *ngFor="let item of ColumnLayouts">
                     <label ngbButtonLabel [class]="formFieldItem.columnLayout == item.value ? 'btn btn-outline-primary active':'btn btn-outline-primary'">
                        <input ngbButton type="radio" [value]="item.value" name="columnLayout" [checked]="formFieldItem.columnLayout == item.value" (change)="updateFormFieldItem($event, formFieldItem)"> {{item.text}}
                      </label>
                   </ng-container>
                </div>
              </div>
              <div class="form-group" *ngIf="formFieldItem.type != 'group'&&formFieldItem.type != 'likert'">
                 <label>Field Size</label>
                 <div class="btn-group btn-group-toggle w-100" ngbRadioGroup name="fieldSizeBasic">
                    <ng-container *ngFor="let item of fieldSizeItem">
                      <label ngbButtonLabel [class]="'btn btn-outline-primary fieldSize'">
                        <!-- formFieldItem.fieldSize === item.value ? 'btn btn-outline-primary active':'btn btn-outline-primary' -->
                        
                        <input ngbButton type="radio" [value]="item.value" name="fieldSize" [checked]="formFieldItem.fieldSize == item.value" (change)="updateFormFieldItem($event, formFieldItem, 'fieldSize')"> {{item.text}}
                       </label>
                    </ng-container>
                 </div>
              </div>
              <div class="form-group" *ngIf="formFieldItem.type == 'datetime-local' && formFieldItem?.rangeDateTime">
                <label class="col-sm-3 col-form-label">Start Date and time</label>
                <div class="col-sm-9">
                    <input class="form-control" type="datetime-local" [value]="formFieldItem?.rangeStartDateTime" name="rangeStartDateTime" [max]="formFieldItem?.rangeEndDateTime" (change)="updateFormFieldItem($event, formFieldItem)">
                </div>
                <label class="col-sm-3 col-form-label">End Date and time</label>
                <div class="col-sm-9">
                    <input class="form-control" name="rangeEndDateTime" type="datetime-local" [value]="formFieldItem?.rangeEndDateTime" (change)="updateFormFieldItem($event, formFieldItem)" [min]="formFieldItem?.rangeStartDateTime" [disabled]="formFieldItem?.rangeStartDateTime == null">
                </div>
              </div>
              <div *ngIf="formFieldItem.type == 'radio' || formFieldItem.type == 'checkbox' ||formFieldItem.type == 'autocomplete'">
                <div class="form-group">
                  <!-- <label>Sort</label> -->
                  <div class="btn-group btn-group-toggle w-100" ngbRadioGroup name="fieldSizeBasic">
                    <label [ngClass]="formFieldItem?.isRandom ? 'btn btn-outline-primary active' : 'btn btn-outline-primary'">
                      <input name="phoneConfirmation" id="isRandom" type="radio" (change)="checkboxChange($event, formFieldItem, 'randomChoice')" [checked]="formFieldItem?.isRandom"> Random Choice
                    </label>
                    <label [ngClass]="formFieldItem?.isRandomChoices ? 'btn btn-outline-primary active' : 'btn btn-outline-primary'">  
                      <input name="phoneConfirmation" id="isRandomChoices" type="radio" (change)="checkboxChange($event, formFieldItem, 'randomSubsetChoice')" [checked]="formFieldItem?.isRandomChoices"> Random Subset Choices
                    </label>
                  </div>
                </div>
                <div class="form-group" *ngIf="formFieldItem?.isRandomChoices">
                  <label>Choices</label>
                  <input class="form-control" type="number" name="choices" [(ngModel)]="formFieldItem.choices" (keyup)="updateFormFieldItem($event, formFieldItem)"/>
                </div>
             </div>
              <div *ngIf="formFieldItem.type == 'email'">
                <div class="form-group">
                  <label for="domainvalidation">Domain Validation</label>
                  <select class="form-control" name="domainsAllowed" [value]="formFieldItem.domainsAllowed" (change)="updateFormFieldItem($event, formFieldItem)">
                      <option *ngFor="let domain of allowedDomainOptions" [value]="domain.id">{{domain.name}}</option>
                  </select>
                </div>
    
                <div class="form-group" *ngIf="formFieldItem.domainsAllowed === '1'">
                  <label for="alloweddomains">Allowed Domains</label>
                  <div class="tagsbox form-control" (click)="focusOnAllDom()">
                    <ul class="domain-tags-list">
                      <li class="action_btn btn-primary" *ngFor="let item of formFieldItem.allowedDomainArr; let i = index;">{{item}} <span (click)="updateAllowedDomains(i, formFieldItem)"><i class="icofont-close icon-close"></i></span></li>
                      <input #allowdominput id="alloweddomains" type="text" [(ngModel)]="allowedDomainStr" (keyup)="updateDomainsListing($event, formFieldItem, 'allowed')">
                    </ul>
                  </div>
                  <div class="text-danger" *ngIf="availDomainErr">
                    Please enter a valid domain name
                  </div>
                </div>
                <div class="form-group" *ngIf="formFieldItem.domainsAllowed === '2'">
                  <label for="restricteddomains">Restricted Domains</label>
                  <div class="tagsbox form-control" (click)="focusOnResDom()">
                    <ul class="domain-tags-list">
                      <li class="action_btn btn-primary" *ngFor="let item of formFieldItem.RestrictedDomainArr">{{item}} <span (click)="updateRestrictedDomains(i, formFieldItem)"><i class="icofont-close icon-close"></i></span></li>
                      <input #resdominput id="restricteddomains" type="text" [(ngModel)]="restrictedDomainStr" (keyup)="updateDomainsListing($event, formFieldItem, 'restricted')">
                    </ul>
                  </div>
                  <div class="text-danger" *ngIf="availDomainErr">
                    Please enter a valid domain name
                  </div>
                </div>
              </div>
              <div class="form-group" *ngIf="formFieldItem.type == 'phone'">
                <div class="row">
                  <div class="col-12">
                    <label for="chk-countryCode">
                      <input class="checkbox_animated" id="chk-countryCode" type="checkbox" (change)="frontEndDisplayPriorities($event, formFieldItem, 'countryCode')" [checked]="formFieldItem?.countryCode"> Include country code?
                    </label>
                  </div>
                  <div class="col-12" *ngIf="allowCountryCode">
                    <div class="form-group">
                      <div class="col-form-label">Select Country</div>
                      <!-- <ng-select [items]="countriesList" bindLabel="country_name" placeholder="Select" class="js-example-placeholder-multiple" [(ngModel)]="formFieldItem.selectedCountryCode" (change)="onPhoneCountryChange($event, formFieldItem)">
                        <ng-template ng-option-tmp let-item="item" let-index="index">
                          <div >
<img [src]="'/assets/images/country_flags/'+item.country_code+'.svg'" height="20" width="25"><b>{{item.phone_code}}</b> </div>
                      </ng-template>
  
                      </ng-select> -->
                      <ng-select [items]="countriesList" bindLabel="country_name" placeholder="Select" class="js-example-placeholder-multiple" [(ngModel)]="formFieldItem.selectedCountryCode" (change)="onPhoneCountryChange($event, formFieldItem)">
                        <ng-template ng-label-tmp let-item="item">

                          <div ><img *ngIf=" item.country_code != undefined" [src]="'/assets/images/country_flags/'+item.country_code+'.svg'" height="20" width="25"><b>{{item.phone_code}}</b> </div>
                        </ng-template>
                        <ng-template ng-option-tmp let-item="item" let-index="index">
                          <div ><img  *ngIf=" item.country_code != undefined"[src]="'/assets/images/country_flags/'+item.country_code+'.svg'" height="20" width="25"><b>{{item.phone_code}}</b> </div>
                      </ng-template>
  
                      </ng-select>
                    </div>
                  </div>
                  <div class="col-12" *ngIf="formFieldItem?.countryCode">
                    <div class="form-group">
                      <div class="form-group m-t-15 custom-radio-ml ml-2">
                        <div class="radio radio-primary">
                           <input id="countryOption1" type="radio" name="countryOption" value="default_country" [checked]="formFieldItem?.defaultCountry" (change)="frontEndDisplayPriorities($event, formFieldItem, 'countryOptions')">
                           <label for="countryOption1">Use country as default?</label>
                         </div>
                         <!-- <div class="radio radio-primary">
                           <input id="countryOption2" type="radio" name="countryOption" value="obtain_ip_country" [checked]="formFieldItem?.obtainIPCountry" (change)="frontEndDisplayPriorities($event, formFieldItem, 'countryOptions')">
                           <label for="countryOption2">Obtain Country based on IP?</label>
                         </div> -->
                     </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="form-group" *ngIf="(formFieldItem.type == 'number' || formFieldItem.type == 'text') && (formFieldItem?.subtype == 'decimal' || formFieldItem?.subtype == 'currency')">
                <label> Thousand separator</label>
                <select class="form-control" name="primaryNumberSeparator" (change)="updateFormFieldItem($event, formFieldItem)">
                  <ng-container *ngFor="let separator of separatorsList; let si = index">
                    <option [value]="separator">{{(separatorsList?.length - 1) == si ? 'Empty Space' : separator}}</option>
                  </ng-container>
                </select>
              </div>
              <div class="form-group" *ngIf="(formFieldItem.type == 'number' || formFieldItem.type == 'text') && (formFieldItem?.subtype == 'decimal' || formFieldItem?.subtype == 'currency')">
                <label>Rounded To</label>
                <select class="form-control" name="RoundedOff" (change)="updateFormFieldItem($event, formFieldItem, 'decimalRoundOff')">
                   <option *ngFor="let rounder of RoundeOffList" [value]="rounder.roundVal">{{rounder.roundLabel}}</option>
                </select>
              </div>
              <div class="form-group" *ngIf="formFieldItem.type != 'checkbox' && formFieldItem.type != 'radio' && formFieldItem.type != 'autocomplete' && formFieldItem.type != 'date' && formFieldItem.type != 'datetime-local' && formFieldItem.type != 'time' && formFieldItem.type != 'button' && formFieldItem.type != 'rating' && formFieldItem.type != 'paragraph' && formFieldItem.type != 'address-group' && formFieldItem.type != 'group' && formFieldItem.type != 'likert'">
                 <label> Minimum and maximum {{ formFieldItem.type=='file' ? 'file' : '' }} limit</label>
                 <!-- <label>{{formFieldItem.label}} Minimum and maximum {{ formFieldItem.type=='file' ? 'file' : '' }} limit</label> -->
                 <div class="d-flex align-items-center min_maxWrap form-group flex-wrap">
                      <div class="bootstrap-touchspin mr-3">
                        <!-- <input class="touchspin-vertical form-control" name="min" min="0" type="number" [value]="formFieldItem.min" (input)="updateFormFieldItem($event, formFieldItem, 'min')" (keyup)="updateFormFieldItem($event, formFieldItem, 'min')" /> -->
                        <input class="touchspin-vertical form-control" id="minBox" name="min" type="number" [value]="formFieldItem?.min"   (keyup)="updateFormFieldItem($event, formFieldItem, 'min')"  />
                        <span class="mt-2 d-block w-100">Min</span>
                      </div>
                      <div class="bootstrap-touchspin">
                        <!-- <input class="touchspin-vertical form-control" name="max" min="0" type="number" [value]="formFieldItem.max" (input)="updateFormFieldItem($event, formFieldItem, 'max')" (keyup)="updateFormFieldItem($event, formFieldItem, 'max')" /> -->
                        <input class="touchspin-vertical form-control" id="maxBox" name="max" type="number" [value]="formFieldItem?.max"  (keyup)="updateFormFieldItem($event, formFieldItem, 'max')"   />
                        <span class="mt-2 d-block w-100">Max</span>
                      </div>
                      <div class="text-danger">{{minMaxError}}</div>
                 </div>
                 <div *ngIf="formFieldItem.type == 'textarea'" class="form-group">
                   <div class="row">
                      <div class="col">
                         <div class="form-group m-t-15 custom-radio-ml ml-2">
                            <div class="radio radio-primary">
                               <input id="radio1" type="radio" name="limitOption" value="word_limit" (change)="allowLimitOnCharacterWords($event, formFieldItem, 'textarea')">
                               <label for="radio1">Allow limit on words?</label>
                             </div>
                             <div class="radio radio-primary">
                               <input id="radio4" type="radio" name="limitOption" value="char_limit" checked="" (change)="allowLimitOnCharacterWords($event, formFieldItem, 'textarea')">
                               <label for="radio4">Allow limit on characters?</label>
                             </div>
                         </div>
                      </div>
                   </div>
                 </div>
                 <div *ngIf="formFieldItem.type == 'number' || formFieldItem?.subtype == 'decimal'" class="form-group">
                   <label>What is the unit of count?</label>
                   <div class="row">
                    <div [ngClass]="formFieldItem.type != 'number' ? 'col-5' : 'col-10'">
                      <div class="form-group">
                        <label>Label</label>
                      </div>
                    </div>
                    <div class="col-5" *ngIf="formFieldItem.type != 'number'">
                      <div class="form-group">
                        <label>Value</label>
                      </div>
                    </div>
                    <div class="col-2">
                      <div class="form-group">
                        <label>Action</label>
                      </div>
                    </div>
                  </div>
                  <div
                    *ngFor="let i of formFieldItem.values; let valueIndex = index"
                    class="row"
                  >
                    <div [ngClass]="formFieldItem.type != 'number' ? 'col-5' : 'col-10'">
                      <div class="form-group">
                        <input
                          class="form-control"
                          type="text"
                          [(ngModel)]="i.label"
                          (input)="unitChange($event, formFieldItem, valueIndex)"
                        />
                      </div>
                    </div>
                    <div class="col-5" *ngIf="formFieldItem.subtype == 'currency' && formFieldItem.currencyDisplayOption == 'symbol'">
                      <div class="form-group">
                        <input
                          class="form-control"
                          type="text"
                          [(ngModel)]="i.currencySymbol"
                          placeholder="Currency symbol ($)"
                        />
                      </div>
                    </div>
                    <div class="col-5" *ngIf="formFieldItem.type != 'number'">
                      <div class="form-group">
                        <input
                          class="form-control"
                          type="text"
                          [(ngModel)]="i.value"
                          disabled
                        />
                      </div>
                    </div>
                    <div class="col-2">
                      <div class="form-group">
                        <button type="button" class="btn-danger btn-sm mr-2" (click)="formFieldItem.values.splice(valueIndex, 1)">Remove</button>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-5">
                    </div>
                    <div class="col-5">
                    </div>
                    <div class="col-2">
                      <div class="form-group">
                        <button type="button" class="btn-success btn-sm" (click)="addValue(formFieldItem.values)">Add</button>
                      </div>
                    </div>
                  </div>
                   <!-- <div class="row">
                      <div class="col">
                         <div class="form-group m-t-15 custom-radio-ml ml-2">
                            <div class="radio radio-primary">
                               <input id="kmunit" type="radio" name="unitOfCount" value="km" [checked]="fieldItem?.unitOfCount === 'km'" (change)="updateFormFieldItem($event, formFieldItem)">
                               <label for="kmunit">KM</label>
                             </div>
                             <div class="radio radio-primary">
                               <input id="cmunit" type="radio" name="unitOfCount" value="cm" [checked]="fieldItem?.unitOfCount === 'cm'" (change)="updateFormFieldItem($event, formFieldItem)">
                               <label for="cmunit">CM</label>
                             </div>
                         </div>
                      </div>
                   </div> -->
                 </div>
              </div>
              <div *ngIf="formFieldItem.type == 'likert'">
                <div class="row">
                  <!-- <div class="col-5"> -->
                    <!-- <div class="form-group">
                      <label>Question</label>
                    </div>
                  </div> --> 
                  <!-- <button  *ngIf="formFieldItem.QuestionArr.length==0" type="button" class="btn btn-primary" (click)="addQuestion()">ADD Question</button> -->
                   <div class="col-sm-12 CustomizerDuplicateBox mb-3" *ngFor="let item of formFieldItem.QuestionArr; let Fieldindex = index">
                      <div class="form-group d-flex align-items-center justify-content-between bg-grey">
                        <label>Question</label>
                        <div>
                        <button type="submit" class="customAddminus clone" (click)="addQuestion()"><i class="icon-plus"></i></button>
                        <button type="submit" class="customAddminus delete" *ngIf="Fieldindex>0" (click)="RemoveQuestion(Fieldindex)"><i class="icon-minus"></i></button>
                        </div>
                      </div>
                      <div class="form-group">
                        <input type="text" class="form-control" placeholder="Question" id="{{Fieldindex}}" [(ngModel)]="item.Question"  >
                      </div>
                      <div dragula="Options" *ngFor="let i of item.values; let valueIndex = index">                  
                          <div class="row align-items-center cursor-drag form-group">
                            <div class="col-9">
                              <input class="form-control" type="text" [(ngModel)]="i.label" (input)="likertInput($event,item.values)" (blur)="valwithTimeStampLikert(item.values)" />
                            </div>
                            <div class="col-3">
                              <button type="button" class="customAddminus clone" (click)="addValueLikert(item.values,Fieldindex)"><i class="icon-plus"></i></button>
                              <button type="button" class="customAddminus delete" *ngIf="valueIndex>0" (click)="item.values.splice(valueIndex, 1)"><i class="icon-minus"></i></button>
                            </div>
                          </div>
                      </div>
                    </div>
                       <!-- <div dragula="Options" >
                       <div
                         *ngFor="let i of item.values; let valueIndex = index"
                         class="row"
                       >
                         <div class="col-5">
                           <div class="form-group">
                             <input
                               class="form-control"
                               type="text"
                               [(ngModel)]="i.label"
                               (input)="likertInput($event,item.values)"
                             />
                           </div>
                         </div>
                         <div class="col-2">
                           <div class="form-group">
                             <button type="button" class="btn-danger btn-sm mr-2" *ngIf="valueIndex>0" (click)="item.values.splice(valueIndex, 1)">Remove</button>
                           </div>
                         </div>
                       </div>
                     </div> -->
                     <!-- <div class="row">
                      
                       <div class="col-2">
                         <div class="form-group">
                           <button type="button" class="btn-success btn-sm" (click)="addValueLikert(item.values,Fieldindex)">Add</button>
                         </div>
                       </div>
                     </div> -->
                   </div>
                 
              </div>
              <div class="form-group">
                <div class="row">
                  <div class="col-12" *ngIf="formFieldItem.type == 'phone'">
                    <label for="chk-allownumbers">
                        <input class="checkbox_animated" id="chk-allownumbers" type="checkbox" (change)="frontEndDisplayPriorities($event, formFieldItem, 'allowNumbersOnly')" [checked]="formFieldItem?.allowNumbersOnly">Allow only numbers?
                    </label>
                  </div>
                  <div class="col-12" *ngIf="formFieldItem.type == 'phone'">
                    <label for="chk-phoneConfirm">
                        <input class="checkbox_animated" name="phoneConfirmation" id="chk-phoneConfirm" type="checkbox" (change)="checkboxChange($event, formFieldItem, 'confirmPhone')" [checked]="formFieldItem?.phoneConfirmation">Include a phone confirmation field?
                    </label>
                  </div>
                  <div class="col-12" *ngIf="formFieldItem.type == 'email'">
                    <label for="chk-phoneConfirm">
                        <input class="checkbox_animated" name="emailConfirmation" id="chk-phoneConfirm" type="checkbox" (change)="checkboxChange($event, formFieldItem, 'confirmEmail')" [checked]="formFieldItem?.emailConfirmation">Include an email address confirmation field?
                     </label>
                  </div>
                  <div class="col-12" *ngIf="formFieldItem.type == 'number' || formFieldItem?.subtype == 'decimal'">
                    <label for="chk-ani2">
                        <input class="checkbox_animated" name="digitsLimit" id="chk-ani2" type="checkbox" (change)="checkboxChange($event, formFieldItem, 'digitsLimit')" [checked]="formFieldItem?.digitsLimit">Allow limit on digits?
                     </label>
                  </div>
                  <div class="col-12" *ngIf="formFieldItem.type == 'number' || formFieldItem?.subtype == 'decimal' || formFieldItem?.subtype == 'currency'">
                    <label for="chk-ani2x">
                       <input class="checkbox_animated" name="negativeValuesAllowed" id="chk-ani2x" type="checkbox" (change)="checkboxChange($event, formFieldItem, 'allowNegative')" [checked]="formFieldItem?.negativeValuesAllowed">Allow negative values?
                   </label>
                  </div>
                  <div class="col-12" *ngIf="formFieldItem.type != 'button' && formFieldItem.type != 'group' && formFieldItem.type == 'date'">
                    <!-- <div class="col-12" *ngIf="formFieldItem.type != 'button' && formFieldItem.type != 'group' && formFieldItem.type == 'date' && allowAutofillDate"> -->
                    <label for="chk-ani">
                        <input class="checkbox_animated" name="allowAutofillDate" id="chk-ani" type="checkbox" (change)="checkboxChange($event, formFieldItem, 'autofillDate')" [checked]="formFieldItem?.allowAutofillDate"> Autofill date of filling form?
                    </label>
                  </div>
                  <div class="col-12" *ngIf="formFieldItem.type != 'button' && formFieldItem.type != 'group' && formFieldItem.type == 'time'">
                    <label for="autofilltime">
                        <input class="checkbox_animated" name="allowAutofillTime" id="autofilltime" type="checkbox" (change)="checkboxChange($event, formFieldItem, 'autofillTime')" [checked]="formFieldItem?.allowAutofillTime"> Autofill time of filling form?
                    </label>
                  </div>
                  <div class="col-12" *ngIf="formFieldItem.type != 'button' && formFieldItem.type != 'group' && formFieldItem.type == 'datetime-local'">
                    <label for="rangedatetime">
                      <input type="checkbox" class="checkbox_animated" id="rangedatetime" name="rangeDateTime" (change)="checkboxChange($event, formFieldItem, 'rangeDatetime')" [checked]="formFieldItem?.rangeDateTime">
                      Date & Time Range
                    </label>
                    <br />
                    <label for="autofilltime1" *ngIf="!formFieldItem?.rangeDateTime">
                        <input class="checkbox_animated" name="allowAutofillDateTime" id="autofilltime1" type="checkbox" (change)="checkboxChange($event, formFieldItem, 'autofillDatetime')" [checked]="formFieldItem?.allowAutofillDateTime"> Autofill date and time during filling form?
                    </label>
                  </div>
                  <div class="col-12" *ngIf="formFieldItem.type != 'button' && formFieldItem.type != 'group'  && formFieldItem.type != 'likert'">
                    <label for="chk-ani3">
                        <input class="checkbox_animated" name="isHidden" id="chk-ani3" type="checkbox" (change)="checkboxChange($event, formFieldItem, 'isHidden')" [checked]="formFieldItem?.isHidden">Should the field be hidden from end user?
                    </label>
                  </div>
                  <div class="col-12" *ngIf="formFieldItem.type != 'button' && formFieldItem.type != 'paragraph' && formFieldItem.type != 'group'  && formFieldItem.type != 'likert'">
                    <label for="chk-ani1">
                        <input class="checkbox_animated" name="isDisabled" id="chk-ani1" type="checkbox" (change)="checkboxChange($event, formFieldItem, 'isDisabled')" [checked]="formFieldItem?.isDisabled">Should the field be disabled so end user can't edit it?
                    </label>
                  </div>
                  <div class="col-12" *ngIf="(formFieldItem.type != 'paragraph' && formFieldItem.type != 'group' && formFieldItem.type != 'likert' && formFieldItem.type !='date' && formFieldItem.type !='time' && formFieldItem.type !='datetime-local'   && formFieldItem.type !='textarea' && formFieldItem.subtype !='currency' ) && ((formFieldItem.type === 'text' && (formFieldItem.subtype === 'text' || formFieldItem.subtype === 'decimal')) || formFieldItem.type === 'email' || formFieldItem.type === 'phone' || formFieldItem.type === 'url' || formFieldItem.type === 'textarea' || formFieldItem.type === 'number')">
                    <label for="chk-ani4">
                      <!-- && formFieldItem.type !='number' -->
                        <input class="checkbox_animated" name="isUnique" id="chk-ani4" type="checkbox" (change)="checkboxChange($event, formFieldItem, 'noDuplicates')" [checked]="formFieldItem?.isUnique">No duplicates?
                    </label>
                  </div>
                  <div class="col-12" *ngIf="formFieldItem.type == 'textarea'">
                    <label for="chk-ani5">
                        <input class="checkbox_animated" name="showCharWordCount" id="chk-ani5" type="checkbox" (change)="checkboxChange($event, formFieldItem, 'showCharWordCount')" [checked]="formFieldItem?.showCharWordCount">Show character/word count to the end user?
                    </label>
                  </div>
                </div>
              </div>
              <ng-container *ngIf="formFieldItem.type == 'email' || (formFieldItem.type == 'phone' && allowNumbersOnlyPhone)">
                <div class="form-group">
                  <label>Regex</label>
                  <input
                    class="form-control"
                    type="text"
                    [(ngModel)]="formFieldItem.regex"
                    [attr.disabled]="allowNumbersOnlyPhone"
                  />
                </div>
                <div class="form-group">
                  <label>Error text</label>
                  <input
                    class="form-control"
                    type="text"
                    [(ngModel)]="formFieldItem.errorText"
                  />
                </div>
              </ng-container>
    
              <div *ngIf="formFieldItem.type == 'checkbox'">
                <div class="row">
                  <div class="col-7">
                    <div class="form-group">
                      <label>Label</label>
                    </div>
                  </div>
                  <!-- <div class="col-4">
                    <div class="form-group">
                      <label>Value</label>
                    </div>
                  </div> -->
                  <div class="col-2">
                    <div class="form-group">
                      <label>Default</label>
                    </div>
                  </div>
                  <div class="col-3">
                    <div class="form-group">
                      <label>Action</label>
                    </div>
                  </div>
                </div>
                <div dragula="Options" [(dragulaModel)]="formFieldItem.values">
                  <div
                    *ngFor="let i of formFieldItem.values; let valueIndex = index"
                    class="row cursor-drag"
                  >
                    <div class="col-7">
                      <div class="form-group">
                        <input
                          class="form-control"
                          type="text"
                          [(ngModel)]="i.label"
                          [ngClass]="{'border-check': i.duperror}"
                           (input)=" dupcheckbox(formFieldItem.values,$event,valueIndex,'checkbox')" 
                             (blur)="valwithTimeStamp(formFieldItem.values)"
                           />
                      </div>
                      
                    </div>
                    <!-- <div class="col-4">
                      <div class="form-group">
                        <input
                          class="form-control"
                          type="text"
                          [(ngModel)]="i.value"
                          [disabled]="true"
                        />
                      </div>
                    </div> -->
                    <div class="col-2 align-self-center">
                      <div class="form-group">
                        <input class="checkbox_animated" type="checkbox" [(ngModel)]="i.checked" /><p></p>
                      </div>
                    </div>
                    <div class="col-3">
                      <div class="form-group">
                        <button type="button" class="btn-danger btn-sm mr-2" (click)="formFieldItem.values.splice(valueIndex, 1)">Remove</button>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-5">
                  </div>
                  <div class="col-5">
                  </div>
                  <div class="col-2">
                    <div class="form-group">
                      <button type="button" class="btn-success btn-sm" (click)="addValue(formFieldItem.values)">Add</button>
                    </div>
                  </div>
                </div>
                <div class="required_error">{{dup_mssg}}</div>
              </div>
              <div *ngIf="formFieldItem.type == 'radio' || formFieldItem.type == 'autocomplete' || formFieldItem.subtype == 'currency'">
                <ng-container *ngIf="formFieldItem.subtype == 'currency'">
                  <div class="form-group m-t-15 custom-radio-ml ml-2">
                    <label>Display Option</label>
                    <div class="radio radio-primary">
                        <input id="currencyText" type="radio" name="currencyDisplayOption" value="text" [checked]="formFieldItem?.currencyDisplayOption == 'text'" (change)="updateFormFieldItem($event, formFieldItem)">
                        <label for="currencyText">Text (USD)</label>
                      </div>
                      <div class="radio radio-primary">
                        <input id="currencySymbol" type="radio" name="currencyDisplayOption" value="symbol" [checked]="formFieldItem?.currencyDisplayOption == 'symbol'" (change)="updateFormFieldItem($event, formFieldItem)">
                        <label for="currencySymbol">Symbol ($)</label>
                      </div>
                  </div>
                </ng-container>
                <h6 *ngIf="formFieldItem.subtype == 'currency'">
                  Allowed Currencies
                </h6>
                <div class="row">
                  <div class="col-7">
                    <div class="form-group">
                      <label>Label</label>
                    </div>
                  </div>
                  <div class="col-5" *ngIf="formFieldItem.subtype == 'currency' && formFieldItem.currencyDisplayOption == 'symbol'">
                    <div class="form-group">
                      <label>Display {{formFieldItem.currencyDisplayOption}}</label>
                    </div>
                  </div>
                  <div class="offset-sm-2"></div>
                  <!-- <div class="col-5">
                    <div class="form-group">
                      <label>Value</label>
                    </div>
                  </div> -->
                  <div class="col-2">
                    <div class="form-group">
                      <label>Action</label>
                    </div>
                  </div>
                </div>
                <div dragula="Options" [(dragulaModel)]="formFieldItem.values">
                  <div
                    *ngFor="let i of formFieldItem.values; let valueIndex = index"
                    class="row cursor-drag"
                  >
                    <div class="col-7">
                      <div class="form-group">
                        <!-- <input
                          class="form-control"
                          type="text"
                          [(ngModel)]="i.label"
                        /> -->
                        <input
                        class="form-control"
                        type="text"
                        [(ngModel)]="i.label"
                        [ngClass]="{'border-check': i.duperror}"
                         (input)=" dupcheckbox(formFieldItem.values,$event,valueIndex,formFieldItem.type)" 
                           (blur)="valwithTimeStamp(formFieldItem.values)"
                         />
                         
                      </div>
                    </div>
                    <div class="col-5" *ngIf="formFieldItem.subtype == 'currency' && formFieldItem.currencyDisplayOption == 'symbol'">
                      <div class="form-group">
                        <input
                          class="form-control"
                          type="text"
                          [(ngModel)]="i.currencySymbol"
                          placeholder="Currency symbol ($)"
                        />
                      </div>
                    </div>
                    <div class="offset-sm-2"></div>
                    <!-- <div class="col-5">
                      <div class="form-group">
                        <input
                          class="form-control"
                          type="text"
                          [(ngModel)]="i.value"
                          [disabled]="true"
                        />
                      </div>
                    </div> -->
                    <div class="col-2 align-self-center">
                      <div class="form-group">
                        <button type="button" class="btn-danger btn-sm mr-2" (click)="formFieldItem.values.splice(valueIndex, 1)">Remove</button>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-5">
                  </div>
                  <div class="col-5">
                  </div>
                  <div class="col-2">
                    <div class="form-group">
                      <button type="button" class="btn-success btn-sm" (click)="addValue(formFieldItem.values)">Add</button>
                    </div>
                  </div>
                </div>
              </div>
              <div class="required_error" *ngIf="formFieldItem.type =='radio'">{{dup_mssg_radio}}</div>
              <div class="required_error" *ngIf="formFieldItem.type =='autocomplete'">{{dup_mssg_autocomplete}}</div>
        </ng-template>
      </div>
      <div class="svaeSettings-btn">
        <button type="button" class="btn btn-primary" (click)="saveSettings(formFieldItem)" [disabled]="minMaxError != ''">Save</button>
      </div>
    </div>
   </div>
