import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { CommonService } from 'src/app/shared/services/common.service';
import { AuthService } from '../../../../services/firebase/auth.service';

@Component({
  selector: 'app-my-account',
  templateUrl: './my-account.component.html',
  styleUrls: ['./my-account.component.scss']
})
export class MyAccountComponent implements OnInit {
data:any
  


  name: string = 'Super Admin';

  constructor(public authService: AuthService, private cookieService: CookieService,private router:Router) { }

  ngOnInit() {
    this.name = this.cookieService.get('user_name');
  }
  redirect(){
    this.router.navigate(['user-profile/edit-profile']);
  }
  logout() {
    this.authService.SignOut();
  }
}

