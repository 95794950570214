import { Injectable } from '@angular/core';
import { ApiClientService } from '../../shared/services/api-client.service';
import { Subject } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { RouteConfigLoadEnd, Router } from '@angular/router';
import Swal from 'sweetalert2';

@Injectable({
  providedIn: 'root'
})

export class AdminService {

  AdminList$ = new Subject();

  constructor(
    public toster: ToastrService,
    private apiClientService: ApiClientService,
    private router: Router
  ) { }

  // fetchAdminsList(rows,page,search) {
  //   if(search==undefined || search ==''||search==null){
  //     return this.apiClientService.get('admins?rows='+rows +'&page=' +page);

  //   }else{
  //     return this.apiClientService.get('admins?rows='+rows +'&page=' +page +'&search='+ search);

  //   }
  // }
  fetchAdminsList(rows, page, col, ord, type) {
    if (type == null) {
      type = ''
    }
    return this.apiClientService.get('admins?rows=' + rows + '&page=' + page + '&col=' + col + '&order=' + ord + '&role=' + type);
  }

  AdminForForms() {
    return this.apiClientService.get('admins');
  }
  AdminForFormsBuilder() {
    return this.apiClientService.get('form_admins');
  }

  fetchUsersList(rows, page, col, ord, nat, date, am, stat, typ, search) {
    return this.apiClientService.get('users?rows=' + rows + '&page=' + page + '&col=' + col + '&order=' + ord + "&nationality=" + nat + '&dob=' + date + '&amount_paid=' + am + '&status=' + stat + '&user_type=' + typ + '&search=' + search);
  }
  //  fetchUsersList(rows,page,col,ord) {
  //   return this.apiClientService.get('users?rows='+rows +'&page=' +page+'&col='+col +'&order='+ ord);
  // }
  fetchFormsList() {
    return this.apiClientService.get('forms');
  }
  // ChangeStatus(data){  
  //   return this.apiClientService.post('user/status',data)
  // }
  getRole() {
    return this.apiClientService.get('roles');
  }

  Edituserget(id) {
    return this.apiClientService.get('admin_detail/' + id.id);
  }

  CreateForm(data) {
    this.apiClientService.post('save_admin', data).subscribe(result => {
      if (result && result['success'] == true) {
        // this.toster.success(result['message']);
        this.router.navigate(['/admin/admin-list']);
        Swal.fire({
          position: 'center',
          icon: 'success',
          html: result['message'],
          showConfirmButton: true,
          padding: '2em',
        });
      }
    }, error => {
      // this.toster.error(error.error['message']);
      Swal.fire({
        position: 'center',
        icon: 'error',
        html: error.error['message'],
        padding: '2em',
      });
    })
  }
  UpdateAdmin(data) {

    this.apiClientService.post('update_admin/' + data.id, data).subscribe(result => {
      if (result && result['success'] == true) {
        // this.toster.success(result['message']);
        this.router.navigate(['/admin/admin-list']);
        Swal.fire({
          position: 'center',
          icon: 'success',
          html: result['message'],
          showConfirmButton: true,
          padding: '2em',
        });
      }
    }, error => {
      Swal.fire({
        position: 'center',
        icon: 'error',
        html: error.error['message'],
        padding: '2em',
      });
      // this.toster.error(error.error['message']);
    })
  }
  ChangeStatus(data) {

    this.apiClientService.post('user/status', data).subscribe(result => {
      if (result && result['success']) {

      } else {
        // this.toster.error('You have enter Wrong Email or Password.');
      }
    })

  }
  setAdminList(data) {

    // this.AdminList$.next(data);
  }

  getAdminList() {
    return this.AdminList$.asObservable();
  }

  searchadmiapi(keyword, type, pno, rows) {
    return this.apiClientService.get('admins?search=' + keyword + '&page=' + pno + '&rows=' + rows);
  }
  searchuserApi(keyword, type, pno, rows) {
    return this.apiClientService.get('users?search=' + keyword + '&page=' + pno + '&rows=' + rows);
  }
  searchApi2(keyword, pno, rows, category_name, subcategory_name, sub_sub_category_name,form_type) {
    return this.apiClientService.get('forms?search=' + keyword + '&page=' + pno + '&rows=' + rows + '&category_name=' + category_name + '&subcategory_name=' + subcategory_name + '&sub_sub_category_name=' + sub_sub_category_name +'&form_type=' +form_type);

  }
  searchApi2Votes(keyword, pno, rows, category_name, subcategory_name, sub_sub_category_name,form_type) {
    return this.apiClientService.get('voting_forms?search=' + keyword + '&page=' + pno + '&rows=' + rows  +'&form_type=' +form_type);

  }
  searchapicat(keyword, pno, rows) {
    return this.apiClientService.get('category_list?search=' + keyword + '&page=' + pno + '&level=1' + '&rows=' + rows);

  }
  serachforallfinance(keyword, pno, rows) {
    return this.apiClientService.get('pending_refunds?search=' + keyword + '&page=' + pno + '&rows=' + rows);
  }
  serachforreturnfinance(keyword, pno, rows) {
    return this.apiClientService.get('finance_category?search=' + keyword + '&page=' + pno + '&level=1' + '&rows=' + rows);
  }
  searchapisubcat(keyword, id, pno, rows) {
    return this.apiClientService.get('category_list?search=' + keyword + '&category_id=' + id + '&page=' + pno + '&level=2' + '&rows=' + rows);

  }
  searchapisubsubcat(keyword, id, pno, rows) {
    return this.apiClientService.get('category_list?search=' + keyword + '&category_id=' + id + '&page=' + pno + '&level=3' + '&rows=' + rows);

  }
  searchformsubmit(keyword, pno, rows, data) {
    return this.apiClientService.post('get_form_submission?search=' + keyword + '&page=' + pno + '&rows=' + rows, data);

  }
  searchforuserbook(keyword, pno, rows, id) {
    return this.apiClientService.get('get_user_submission/' + id + '?rows=' + rows + '&page=' + pno + '&search=' + keyword


    )

  }
  searchfordependent(keyword, pno, rows, id) {
    return this.apiClientService.post('dependent_list?rows=' + rows + '&page=' + pno + '&search=' + keyword, id)

  }
  searchforAllBook(keyword, pno, rows, id, type) {
    return this.apiClientService.get('all_bookings?rows=' + rows + '&page=' + pno + '&search=' + keyword + '&form_type=' + type);

  }
  searchforInvite(data, keyword, pno, rows, id) {
    return this.apiClientService.post('form_email_logs?rows=' + rows + '&page=' + pno + '&search=' + keyword, data)

  }
  searchforAutoEmails(data) {
    return this.apiClientService.post('booking_auto_emails', data)


  }
  postInviteUser(data) {
    return this.apiClientService.post('invite_users', data)


  }

  searchpages(keyword, pno, rows) {
    return this.apiClientService.get('static_pages?search=' + keyword + '&page=' + pno + '&rows=' + rows);

  }
  searchhomepageslider(keyword, pno, rows) {
    return this.apiClientService.get('slider_list?search=' + keyword + '&page=' + pno + '&rows=' + rows);

  }
  searchemail(keyword, pno, rows) {
    return this.apiClientService.get('email_templates?search=' + keyword + '&page=' + pno + '&rows=' + rows);

  }
  searchroles(keyword, pno, rows) {
    return this.apiClientService.get('roles?search=' + keyword + '&page=' + pno + '&rows=' + rows);

  }
  searchsubcat(keyword, pno, rows) {
    return this.apiClientService.get('roles?search=' + keyword + '&page=' + pno + '&rows=' + rows);

  }
  exportAdmins(data) {
    if (data.role == null) {
      data.role = ''
    }
    return this.apiClientService.get('export_admins?col=' + data.col + '&order=' + data.ord + '&search=' + data.search + '&role=' + data.role + '&rows=' + data.perpage + '&page=' + data.page);
    // return this.apiClientService.get('export_users?&page='+ pno +'&rows='+rows);

  }
  exportusers(data) {

    return this.apiClientService.get('export_users?col=' + data.col + '&order=' + data.ord + "&nationality=" + data.nat + '&dob=' + data.date + '&amount_paid=' + data.amount + '&status=' + data.status + '&user_type=' + data.user_type + '&search=' + data.search);
    // return this.apiClientService.get('export_users?&page='+ pno +'&rows='+rows);

  }
  exportbookusers(data: any) {
    return this.apiClientService.get('user_booking_export?col=' + data.col + '&order=' + "&user_id=" + data.user_id + "&search=" + data.search);

  }
  exportAllbookusers(data: any) {
    // return this.apiClientService.grr('user_booking_export');
    // return this.apiClientService.get('all_booking_export?rows=' + data.perpage + '&page=' + data.page + '&search=' + data.search + '&payment_type=' + data.payment_type + '&start_date=' + data.start_date + '&end_date=' + data.end_date +'&category_name='+data.category_name+'&subcategory_name='+data.subcategory_name+'&sub_sub_category_name='+data.sub_sub_category_name+"&discount_type="+data.discount_type);
    return this.apiClientService.get('all_booking_export?rows=&page=' + data.page + '&search=' + data.search + '&payment_type=' + data.payment_type + '&start_date=' + data.start_date + '&end_date=' + data.end_date +'&category_name='+data.category_name+'&subcategory_name='+data.subcategory_name+'&sub_sub_category_name='+data.sub_sub_category_name+"&discount_type="+data.discount_type);


  }
  exportAllVote(data: any) {
    // return this.apiClientService.grr('user_booking_export');
    // return this.apiClientService.get('all_booking_export?rows=' + data.perpage + '&page=' + data.page + '&search=' + data.search + '&payment_type=' + data.payment_type + '&start_date=' + data.start_date + '&end_date=' + data.end_date +'&category_name='+data.category_name+'&subcategory_name='+data.subcategory_name+'&sub_sub_category_name='+data.sub_sub_category_name+"&discount_type="+data.discount_type);
    return this.apiClientService.get('voting_booking_export?rows=&page=' + data.page + '&search=' + data.search  + '&start_date=' + data.start_date + '&end_date=' + data.end_date );


  }
  exportAllVoteList(rows,page,col,ord,category_name,subcategory_name,sub_sub_category_name,is_popular) {
    return this.apiClientService.get('voting_booking_export?rows='+rows +'&page=' +page+'&col='+col +"&order="+ord+'&category_name='+category_name+'&subcategory_name='+subcategory_name+'&sub_sub_category_name='+sub_sub_category_name+'&is_popular='+is_popular);
  }
  exportlist(data) {
    return this.apiClientService.post('booking_export', data);

  }
  deleteRoles(data) {
    // return this.apiClientService.delete('role/'+ data);
    this.apiClientService.delete('roles/' + data)
      .subscribe(result => {
        if (result && result['success'] == true) {
          // this.router.navigate(['/role/all-roles']);
          Swal.fire({
            position: 'center',
            icon: 'success',
            html: result['message'],
            showConfirmButton: true,
            padding: '2em',
          });

        }
        else {
          Swal.fire({
            position: 'center',
            icon: 'error',
            html: result['message'],
            padding: '2em',
          });
          // this.toster.error('You have enter Wrong Email or Password.');
        }
      }, (error) => {
        Swal.fire({
          position: 'center',
          icon: 'info',
          html: error.error.message,
          padding: '2em',
        });
      })
  }


  ProcessAPi(data: any) {
    return this.apiClientService.post('approve_refund', data)

  }
}
