import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/shared/services/firebase/auth.service';

@Component({
  selector: 'app-resetpassword',
  templateUrl: './resetpassword.component.html',
  styleUrls: ['./resetpassword.component.scss']
})
export class ResetpasswordComponent implements OnInit {
  public show: boolean = false;
  public cshow: boolean = false;
  passwordPattern = new RegExp(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]).{6,}$/);

  public resetForm: FormGroup;
  public errorMessage: any;
  token:any
  result:any


  constructor(public authService: AuthService, private fb: FormBuilder, private route: ActivatedRoute, private toastr:ToastrService, private router: Router) {
      this.resetForm = this.fb.group({
        password: new FormControl(null, [Validators.required, Validators.pattern(this.passwordPattern)]),
       cpassword: new FormControl(null,[Validators.required]), 
      },
      {
        validators: this.MustMatch('password', 'cpassword')
      })
      // this.verifyToken();

      
      this.token =this.route.snapshot.params['id']
  }

  MustMatch (controlName:string,matchingControlName:string) {
    return(formGroup:FormGroup) => {
      const control = formGroup.controls[controlName];
      const matchingControl = formGroup.controls[matchingControlName];
      if(matchingControl.errors && !matchingControl.errors['MustMatch']){
        return
      }
      if(control.value !== matchingControl.value){
        matchingControl.setErrors({MustMatch:true});
      }
      else{
        matchingControl.setErrors(null);
      }
    }
  }

  ngOnInit() {
    this.verifyToken();

  }

  verifyToken() {
    this.authService.confirmResetPassword(this.token);
  }

  showPassword() {
    this.show = !this.show;
  }

  showCpassword() {
    this.cshow = !this.cshow;
  }

  resetpass() {
    if (this.resetForm.valid){
      let data={
        'password': this.resetForm.get('password').value,
         'confirm_password':this.resetForm.get('cpassword').value,
         'token':this.token
        }
      this.authService.createNewPassword(data);
    }
    else {
      for (let i in this.resetForm.controls) {
        this.resetForm.controls[i].markAsTouched();
      }
    }
  }
}