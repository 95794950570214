import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormsService } from "src/app/components/forms/forms.service";
import Swal from "sweetalert2";
import { filter, takeUntil } from "rxjs/operators";
import { Subject } from "rxjs";
import { value } from "src/app/components/forms/manage-forms/formField-global.model";
import { AlertComponent } from "src/app/components/bonus-ui/base/alert/alert.component";
import { getWeekYearWithOptions } from "date-fns/fp";
import { it } from "date-fns/locale";

@Component({
  selector: "app-field-rule",
  templateUrl: "./field-rule.component.html",
  styleUrls: ["./field-rule.component.scss"],
})
export class FieldRuleComponent implements OnInit {
  @Input() Fields: any; // decorate the property with @Input()
  @Input() form_id: any;
  @Input() FieldRuleData: any[] = [];
  @Output() FieldRuleShow = new EventEmitter();
  @Input() Break: boolean;
  // FieldRuleData
  constructor(private formService: FormsService) {}
  Condition_Show: any = [];
  FieldForm = [
    {
      rule_name: "",
      maxIndexArr: [],
      maxthenIndexArr: [],
      if_condition: {
        allow: "any",
        conditions: [
          {
            field_type: "",
            group_type: "",
            likertquestion: "",
            constraint: "Is",
            constraint_id: 1,
            value: "",
            showValueBox: true,
            FieldConstraint: [],
            conditiontype: "text",
            FileEmpt: false,
            valforboxes: {},
            AddressType: [],
            groupFields: [],
            multiplecheckbox: [],
            multiplevalue: [],
            multipleboxes: [],
            rating: {
              accept: "",
              value: "",
              size: "",
              description: "",
              arrsize: [],
            },
            LikertQuestions: {
              Questions: [],
              values: [],
            },
            index:'',
            pagebreakindex:''
          },
        ],
      },
      // "show":{
      //   "type": "Show",

      // },
      then_apply: [
        {
          type: "Show",
          thenFinalValue: [],
          value: [],
          thenindex:"",
          multinewarr2: [],
          groupFields: [],
          group_type: "",
          valforboxes: {},
          AddressType: [],
          boxes_value: [],
          address_value: [],
          likertquestion: "",
          likertvalue: "",
          LikertQuestions: {
            Questions: [],
            values: [],
          },
        },
      ],
    },
  ];
  hidesave: boolean = false;

  thenApply = ["Hide", "Show"];
  FieldConditionList: any[] = [];
  conditionRows = [
    {
      field_type: "",
      group_type: "",
      likertquestion: "",

      constraint: "Is",
      constraint_id: 1,
      value: "",
      showValueBox: true,
      FieldConstraint: [],
      conditiontype: "text",
      valforboxes: {},
      AddressType: [],
      FileEmpt: false,

      groupFields: [],
      multipleboxes: [],

      multiplecheckbox: [],
      multiplevalue: [],
      rating: {
        accept: "",
        value: "",
        size: "",
        description: "",
        arrsize: [],
      },
      LikertQuestions: {
        Questions: [],
        values: [],
      },
      index:'',
      pagebreakindex:''


    },
  ];
  validate = false;
  // dropdownSettings2: any = {};
  destroy$: Subject<boolean> = new Subject<boolean>();
  result: any;
  showHideFields: any;
  multinewarr: any[] = [];
  dropdownSettings: any = {};
  dropdownList = [
    { label: 1, value: "Mumbai" },
    { label: 2, value: "Bangaluru" },
  ];
  page_Num_arr: any[] = [];

  ngOnInit(): void {
    // setTimeout(() => {

    this.dropdownSettings = {
      singleSelection: false,
      idField: "value",
      textField: "label",
      selectAllText: "Select All",
      unSelectAllText: "UnSelect All",
      itemsShowLimit: 3,
      allowSearchFilter: true,
    };

    // }, 2000);
    if (this.FieldRuleData.length > 0) {
      this.FieldForm = this.FieldRuleData;
    }
    // console.log(this.Fields,'FFF');
    this.Condition_Show = true;
    if (this.Break == false) {
      if (this.Fields !== undefined && this.Fields.length > 0) {
        // this.FieldForm[0].if_condition.conditions[0].field_type = this.Fields[0].name;
        this.showHideFields = this.Fields[0].name;
      }
    } else {
      this.page_Num_arr;
      this.Fields.forEach((x, i) => {
        // console.log(x[0],'yyyyy');
        if (x[0].type == "break") {
          this.page_Num_arr.push(x[0].pageTitle);
        }
      });
    }
    this.handleDiscountRuleConditions();
    // let newfields=[]
    // this.Fields.forEach((item,i)=>{
    // // console.log(item.type,'type');

    //  if( item.type !='"paragraph"'){
    //   newfields.push(item)
    //  }
    // })
    // // alert('stop')
    // console.log(newfields,"ssffffffffffffffffffffffffffffffffff=============================")
  }
  addNewRule() {
    (this.conditionRows["FieldConstraint"] = this.FieldConditionList),
      this.FieldForm.push({
        rule_name: "",
        maxIndexArr: [],
        maxthenIndexArr: [],

        if_condition: {
          allow: "any",
          conditions: this.conditionRows,
        },
        // "show":{
        //   "type": "Show",

        // },
        then_apply: [
          {
            type: "Show",
            value: [],
            thenFinalValue: [],
            thenindex:"",

            multinewarr2: [],
            groupFields: [],
            group_type: "",
            valforboxes: {},
            boxes_value: [],

            AddressType: [],
            address_value: [],
            likertquestion: "",
            likertvalue: "",
            LikertQuestions: {
              Questions: [],
              values: [],
            },
          },
        ],
      });
    // console.log(this.FieldForm,'ffff99900000')
  }
  removeRow(discountRuleFormIndex: number, index: number) {
    this.FieldForm[discountRuleFormIndex].if_condition.conditions.splice(
      index,
      1
      );
      console.log( this.FieldForm[discountRuleFormIndex].if_condition.conditions,'maxx');
    let new01=[]

      this.FieldForm[discountRuleFormIndex].if_condition.conditions.forEach((cond,j) => {
        console.log(cond.index,'index innnnnn')
    if(!new01.includes(cond.index)){
    
      new01.push(cond.index);
    }
        if (!this.FieldForm[discountRuleFormIndex].maxIndexArr.includes(cond.index)) {
    
              this.FieldForm[discountRuleFormIndex].maxIndexArr.push(cond.index);
           
        }
        })
        console.log(new01,'new01');
        console.log(     this.FieldForm[discountRuleFormIndex].maxIndexArr,'     this.FieldForm[discountRuleFormIndex].maxIndexArr');
        this.FieldForm[discountRuleFormIndex].maxIndexArr=this.FieldForm[discountRuleFormIndex].maxIndexArr.filter(element => new01.includes(element));

   
        //slice cut outs into newa arr
        Math.max(...this.FieldForm[discountRuleFormIndex].maxIndexArr)
        if(!!this.FieldForm[discountRuleFormIndex].maxIndexArr.includes(  Math.max(...this.FieldForm[discountRuleFormIndex].maxIndexArr))){
    this.FieldForm[discountRuleFormIndex].then_apply[0].thenFinalValue=[]
    
          this.FieldForm[discountRuleFormIndex].then_apply[0].thenFinalValue=this.Fields.slice(Math.max(...this.FieldForm[discountRuleFormIndex].maxIndexArr)+1)
        }
      // this.ifThenInterLinks(this.FieldForm[discountRuleFormIndex].if_condition.conditions[index].field_type,discountRuleFormIndex,index)
//  this.ifThenInterLinks(this.FieldForm[discountRuleFormIndex].if_condition.conditions[index].field_type,discountRuleFormIndex,index)
  }
  addNewRow(ruleindex, index2) {
    // console.log(this.FieldConditionList,'fffffffffllllllll');

    if (this.Fields !== undefined && this.Fields.length > 0) {
      this.FieldForm[ruleindex].if_condition.conditions.push({
        field_type: "",
        group_type: "",
        likertquestion: "",

        constraint: this.FieldConditionList[0].name,
        constraint_id: this.FieldConditionList[0].id,
        value: "",
        showValueBox: true,

        FieldConstraint: this.FieldConditionList,
        conditiontype: "text",
        valforboxes: {},
        AddressType: [],
        FileEmpt: false,

        groupFields: [],
        multipleboxes: [],
        multiplecheckbox: [],
        multiplevalue: [],
        rating: {
          accept: "",
          value: "",
          size: "",
          description: "",
          arrsize: [],
        },
        LikertQuestions: {
          Questions: [],
          values: [],
        },
        index:'',
        pagebreakindex:''


      });
      // console.log(this.FieldForm,'fffooo')
      // this.FieldConditionList.push(this.FieldConditionList)
      // console.log(this.FieldConditionList,'FFF')
    }
  }
    addThenRow(ruleindex, index2) {
    console.log(ruleindex,index2,index2+1,'nexton',this.FieldForm[ruleindex].then_apply[0].thenFinalValue,)
    if (this.Fields !== undefined && this.Fields.length > 0) {
      this.FieldForm[ruleindex].then_apply.push({
        type: "Show",
        value: [],
        thenFinalValue:  this.FieldForm[ruleindex].then_apply[0].thenFinalValue,
        
        multinewarr2: [],
        groupFields: [],
        group_type: "",
        valforboxes: {},
        boxes_value: [],
        thenindex:"",

        AddressType: [],
        address_value: [],
        likertquestion: "",
        likertvalue: "",
        LikertQuestions: {
          Questions: [],
          values: [],
        },
      });
      // console.log(this.FieldForm,'fffooo')
      // this.FieldConditionList.push(this.FieldConditionList)
      // console.log(this.FieldConditionList,'FFF')
    }
    // let globthen=this.FieldForm[ruleindex].then_apply[index2].thenFinalValue
    // console.log(this.FieldForm[ruleindex].then_apply,'pppppppppppppp')
    let index = ''
    this.FieldForm[ruleindex].then_apply.forEach((ite,i)=>{
      if(i !=index2){
        console.log(ite.thenFinalValue,'then++++++')
        ite.thenFinalValue=ite.thenFinalValue.filter((fil,p)=>{
          console.log(fil.name ,'val', this.FieldForm[ruleindex].then_apply[index2].value)
          return fil.name != this.FieldForm[ruleindex].then_apply[index2].value
        })
      }
      // if(i == index2){
      //    index=ite.thenindex
      //    console.log(index,'this is index')
      // }
      // console.log(i ,'iii',index2)
      // if(i != index2){
      //   console.log(i ,'i+i+i+i+i+i+i+i+i+i+i+i',ite ,parseInt(index) ,index)
      // ite.thenFinalValue= ite.thenFinalValue.slice(parseInt(index))
      //  console.log( ite.thenFinalValue,'after slice');
       
      // }
console.log(ite,'ite')
    })
    // console.log(this.FieldForm[ruleindex].then_apply[index2],'then_apply')

  }
  removeThenRow(discountRuleFormIndex: number, index: number) {
    this.FieldForm[discountRuleFormIndex].then_apply.splice(index, 1);
  }
  removeRule(index, id) {
    // console.log(index,'piddd',id);

    if (index == 0) {
      this.FieldRuleShow.emit(true);
      this.hidesave = true;
    }
    // console.log(this.FieldForm,'aaaaaaaaaaaaaaaaaaaaa');

    this.FieldForm.splice(index, 1);

    // let id={}

    if (id != undefined) {
      this.formService
        .DeleteFiedRules(id)
        .pipe(takeUntil(this.destroy$))
        .subscribe((result) => {
          this.result = result;
        });
    }
  }
  ifThenInterLinks(val,ind,ind2){
    // alert('hot')
    console.log(this.Fields,'FIelds in IF-THEN RELATION')
    if(!this.Break){
      this.Fields.forEach((fiel: any, j) => {
        if (fiel.name == val) {
          console.log(j, "index++++++++++++");
            this.FieldForm[ind].if_condition.conditions[ind2].index=j
          }
      });

    }else{
      console.log("BREAK")
      this.Fields.forEach((field: any, i) => {
        field.forEach((fiel,j)=>{
          if (fiel.name == val) {
            console.log(j, "index++++++++++++");
              // this.FieldForm[ind].if_condition.conditions[ind2].index=(parseInt(j)-1).toString();
              this.FieldForm[ind].if_condition.conditions[ind2].index=j
              this.FieldForm[ind].if_condition.conditions[ind2].pagebreakindex=i
            }

        })
      });
    }
    let new01=[]
    this.FieldForm[ind].if_condition.conditions.forEach((cond,j) => {
    console.log(cond.index,'index innnnnn')
if(!new01.includes(cond.index)){

  new01.push(cond.index);
}
    if (!this.FieldForm[ind].maxIndexArr.includes(cond.index)) {

          this.FieldForm[ind].maxIndexArr.push(cond.index);
       
    }
    })
    console.log(new01,'new01');
    
   
    this.FieldForm[ind].maxIndexArr=this.FieldForm[ind].maxIndexArr.filter(element => new01.includes(element));
console.log(this.FieldForm[ind].maxIndexArr,'maxiiieee')
   
    //slice cut outs into newa arr
    Math.max(...this.FieldForm[ind].maxIndexArr)
    console.log(
    Math.max(...this.FieldForm[ind].maxIndexArr),'maxvalue'
     )
    if(!!this.FieldForm[ind].maxIndexArr.includes(  Math.max(...this.FieldForm[ind].maxIndexArr))){
this.FieldForm[ind].then_apply[0].thenFinalValue=[]

      // this.FieldForm[ind].then_apply[0].thenFinalValue=this.Fields.slice(Math.max(...this.FieldForm[ind].maxIndexArr)+1)
      this.FieldForm[ind].then_apply.forEach((ite)=>{
        if(!this.Break){

          ite.thenFinalValue = this.Fields.slice(Math.max(...this.FieldForm[ind].maxIndexArr)+1)
        }else{
          console.log(this.Fields,'inPAGEBREAK FIEDS',this.Fields[this.FieldForm[ind].if_condition.conditions[ind2].pagebreakindex])
          this.FieldForm[ind].then_apply[ind2].thenFinalValue=this.Fields[this.FieldForm[ind].if_condition.conditions[ind2].pagebreakindex].slice((Math.max(...this.FieldForm[ind].maxIndexArr)+1))
          console.log(   this.FieldForm[ind].then_apply[ind2].thenFinalValue,"THENVALUEAFTERSLICE");
          
          // ite.thenFinalValue = this.Fields[this.FieldForm[ind].if_condition.conditions[ind2].pagebreakindex]
          // forEach((it,m)=>{
          //   console.log(it,'ittttttttttt')
          //   it.slice(Math.max(...this.FieldForm[ind].maxIndexArr))
          // console.log(it,'AFTERttttt')
          // })

        }
      })
    }
    console.log(this.FieldForm,'inifchange')
  }
  onUserInfoFieldSelect(val, ind, ind2) {
    console.log("val", val, "ind===", ind, "ind2==", ind2);
  

  
    this.ifThenInterLinks(val,ind,ind2)
     
    // this.FieldForm[ind].if_condition.conditions.forEach((field: any, j) => {
    //   if (j == this.FieldForm[ind].if_condition.conditions.length - 1) {
    //   }
    // });

    if (val.includes("file")) {
      this.FieldForm[ind].if_condition.conditions[ind2].FieldConstraint =
        this.FieldConditionList.filter((field) => {
          return field.name == "Is Empty" || field.name == "Is Not Empty";
        });
      this.FieldForm[ind].if_condition.conditions[ind2].FileEmpt = true;
      //  val.preventDefault();
      //  alert('file')
      //// console.log('FileEmpt',this.FieldForm)
      // console.log(this.FieldForm,'ffffuuffee')
    } else if (val.includes("rating")) {
      this.FieldForm[ind].if_condition.conditions[ind2].FieldConstraint =
        this.FieldConditionList.filter((field) => {
          return (
            field.name == "Is" ||
            field.name == "Is Not" ||
            field.name == "Is Empty" ||
            field.name == "Is Not Empty"
          );
        });
    } else if (val.includes("range")) {
      this.FieldForm[ind].if_condition.conditions[ind2].FieldConstraint =
        this.FieldConditionList.filter((field) => {
          return (
            field.name == "Is" ||
            field.name == "Is Not" ||
            field.name == "Is Empty" ||
            field.name == "Is Not Empty"
          );
        });
    } else {
      // alert('hit')
      this.FieldForm[ind].if_condition.conditions[ind2].FieldConstraint =
        this.FieldConditionList;
      // console.log(this.FieldForm,'fielddorm');
    }
    // if-else for conditions values

    if (val.includes("date") && !val.includes("datetime")) {
      this.FieldForm[ind].if_condition.conditions[ind2].valforboxes = {};
      this.FieldForm[ind].if_condition.conditions[ind2].AddressType = [];
      this.FieldForm[ind].if_condition.conditions[ind2].multiplecheckbox = [];
      this.FieldForm[ind].if_condition.conditions[ind2].rating.accept = "";
      this.FieldForm[ind].if_condition.conditions[ind2].rating.value = "";
      this.FieldForm[ind].if_condition.conditions[ind2].rating.size = "";
      this.FieldForm[ind].if_condition.conditions[ind2].rating.description = "";
      this.FieldForm[ind].if_condition.conditions[ind2].groupFields = [];
      this.FieldForm[ind].if_condition.conditions[
        ind2
      ].LikertQuestions.Questions = [];
      this.FieldForm[ind].if_condition.conditions[ind2].LikertQuestions.values =
        [];

      this.FieldForm[ind].if_condition.conditions[ind2].conditiontype = "date";
      this.FieldForm[ind].if_condition.conditions[ind2].FieldConstraint =
        this.FieldConditionList.filter((field) => {
          return (
            field.name == "Is" ||
            field.name == "Is Not" ||
            field.name == "Is Empty" ||
            field.name == "Is Not Empty"
          );
        });
      // console.log(this.FieldForm,"ffff")
    } else if (val.includes("datetime")) {
      this.FieldForm[ind].if_condition.conditions[ind2].valforboxes = {};
      this.FieldForm[ind].if_condition.conditions[ind2].AddressType = [];
      this.FieldForm[ind].if_condition.conditions[ind2].multiplecheckbox = [];
      this.FieldForm[ind].if_condition.conditions[ind2].rating.accept = "";
      this.FieldForm[ind].if_condition.conditions[ind2].rating.value = "";
      this.FieldForm[ind].if_condition.conditions[ind2].rating.size = "";
      this.FieldForm[ind].if_condition.conditions[ind2].rating.description = "";
      this.FieldForm[ind].if_condition.conditions[ind2].groupFields = [];
      this.FieldForm[ind].if_condition.conditions[
        ind2
      ].LikertQuestions.Questions = [];
      this.FieldForm[ind].if_condition.conditions[ind2].LikertQuestions.values =
        [];

      this.FieldForm[ind].if_condition.conditions[ind2].conditiontype =
        "datetime-local";
      this.FieldForm[ind].if_condition.conditions[ind2].FieldConstraint =
        this.FieldConditionList.filter((field) => {
          return (
            field.name == "Is" ||
            field.name == "Is Not" ||
            field.name == "Is Empty" ||
            field.name == "Is Not Empty"
          );
        });
    } else if (val.includes("time")) {
      this.FieldForm[ind].if_condition.conditions[ind2].valforboxes = {};
      this.FieldForm[ind].if_condition.conditions[ind2].AddressType = [];
      this.FieldForm[ind].if_condition.conditions[ind2].multiplecheckbox = [];
      this.FieldForm[ind].if_condition.conditions[ind2].rating.accept = "";
      this.FieldForm[ind].if_condition.conditions[ind2].rating.value = "";
      this.FieldForm[ind].if_condition.conditions[ind2].rating.size = "";
      this.FieldForm[ind].if_condition.conditions[ind2].rating.description = "";
      this.FieldForm[ind].if_condition.conditions[ind2].groupFields = [];
      this.FieldForm[ind].if_condition.conditions[
        ind2
      ].LikertQuestions.Questions = [];
      this.FieldForm[ind].if_condition.conditions[ind2].LikertQuestions.values =
        [];

      this.FieldForm[ind].if_condition.conditions[ind2].conditiontype = "time";
      this.FieldForm[ind].if_condition.conditions[ind2].FieldConstraint =
        this.FieldConditionList.filter((field) => {
          return (
            field.name == "Is" ||
            field.name == "Is Not" ||
            field.name == "Is Empty" ||
            field.name == "Is Not Empty"
          );
        });
    } else if (val.includes("checkbox")) {
      // console.log(this.Fields,'ggyyy22')

      // console.log(this.FieldForm,'bbbfields');
      // newone.forEach((item)=>{

      this.FieldForm[ind].if_condition.conditions[ind2].AddressType = [];
      this.FieldForm[ind].if_condition.conditions[ind2].multiplecheckbox = [];
      this.FieldForm[ind].if_condition.conditions[ind2].rating.accept = "";
      this.FieldForm[ind].if_condition.conditions[ind2].rating.value = "";
      this.FieldForm[ind].if_condition.conditions[ind2].rating.size = "";
      this.FieldForm[ind].if_condition.conditions[ind2].rating.description = "";
      this.FieldForm[ind].if_condition.conditions[ind2].groupFields = [];
      this.FieldForm[ind].if_condition.conditions[
        ind2
      ].LikertQuestions.Questions = [];
      this.FieldForm[ind].if_condition.conditions[ind2].LikertQuestions.values =
        [];
      // this.FieldForm[ind].if_condition.conditions[ind2].FieldConstraint=this.FieldConditionList

      console.log(this.FieldForm, "ffff11)");
      if (!this.Break) {
        let newone = this.Fields.find((item) => {
          return item.name == val;
        });
        this.FieldForm[ind].if_condition.conditions[ind2].valforboxes = newone;
      } else {
        // console.log('val',val)
        let newone = [];
        this.Fields.forEach((fields) => {
          fields.filter((item) => {
            // console.log(item,'item')
            if (item.name == val) {
              return newone.push(item);
            }
          });
        });
        console.log(newone, "fieldsnewonebreak");
        // this.FieldForm[ind].if_condition.conditions[ind2].valforboxes=newone
        //only for one time loop
        newone.forEach((item) => {
          this.FieldForm[ind].if_condition.conditions[ind2].valforboxes = item;
        });
        console.log(
          this.FieldForm[ind].if_condition.conditions[
            ind2
          ].valforboxes.hasOwnProperty("values"),
          "fasdasdaieldin decedsiones"
        );
      }
    } else if (val.includes("rating")) {
      this.FieldForm[ind].if_condition.conditions[ind2].groupFields = [];
      this.FieldForm[ind].if_condition.conditions[ind2].valforboxes = {};
      this.FieldForm[ind].if_condition.conditions[ind2].AddressType = [];
      this.FieldForm[ind].if_condition.conditions[ind2].multiplecheckbox = [];

      this.FieldForm[ind].if_condition.conditions[ind2].groupFields = [];
      this.FieldForm[ind].if_condition.conditions[
        ind2
      ].LikertQuestions.Questions = [];
      this.FieldForm[ind].if_condition.conditions[ind2].LikertQuestions.values =
        [];
      if (!this.Break) {
        let newone = this.Fields.find((item) => {
          return item.name == val;
        });
        // console.log(newone,'newone');
        this.FieldForm[ind].if_condition.conditions[ind2].rating.accept =
          newone.accept;
        this.FieldForm[ind].if_condition.conditions[ind2].rating.value =
          newone.value;
        this.FieldForm[ind].if_condition.conditions[ind2].rating.size =
          newone.size;
        this.FieldForm[ind].if_condition.conditions[ind2].rating.description =
          newone.description;
      } else {
        let newone = [];

        this.Fields.forEach((fields) => {
          fields.filter((item) => {
            // console.log(item,'item')
            if (item.name == val) {
              return newone.push(item);
            }
          });
          // console.log(newone,'newone')
          // console.log(this.FieldForm,'ffdd')
          this.FieldForm[ind].if_condition.conditions[ind2].rating.accept =
            newone[0].accept;
          this.FieldForm[ind].if_condition.conditions[ind2].rating.value =
            newone[0].value;
          this.FieldForm[ind].if_condition.conditions[ind2].rating.size =
            newone[0].size;
          this.FieldForm[ind].if_condition.conditions[ind2].rating.description =
            newone[0].description;
        });
      }

      // console.log(this.FieldForm,'fgfgfgfg');
    } else if (val.includes("radio")) {
      this.FieldForm[ind].if_condition.conditions[ind2].AddressType = [];
      this.FieldForm[ind].if_condition.conditions[ind2].multiplecheckbox = [];
      this.FieldForm[ind].if_condition.conditions[ind2].rating.accept = "";
      this.FieldForm[ind].if_condition.conditions[ind2].rating.value = "";
      this.FieldForm[ind].if_condition.conditions[ind2].rating.size = "";
      this.FieldForm[ind].if_condition.conditions[ind2].rating.description = "";
      this.FieldForm[ind].if_condition.conditions[ind2].groupFields = [];
      this.FieldForm[ind].if_condition.conditions[
        ind2
      ].LikertQuestions.Questions = [];
      this.FieldForm[ind].if_condition.conditions[ind2].LikertQuestions.values =
        [];
      // this.FieldForm[ind].if_condition.conditions[ind2].FieldConstraint=this.FieldConditionList

      console.log(this.FieldForm, "ffff11)");
      if (!this.Break) {
        let newone = this.Fields.find((item) => {
          return item.name == val;
        });
        this.FieldForm[ind].if_condition.conditions[ind2].valforboxes = newone;
      } else {
        let newone = [];

        this.Fields.forEach((fields) => {
          fields.filter((item) => {
            // console.log(item,'item')
            if (item.name == val) {
              return newone.push(item);
            }
          });
          // console.log(newone,'newone')
          // console.log(this.FieldForm,'ffdd')
          //only for one time loop
          newone.forEach((item) => {
            this.FieldForm[ind].if_condition.conditions[ind2].valforboxes =
              item;
          });
        });
      }
    } else if (val.includes("autocomplete")) {
      // console.log(this.Fields,'ggyyy')

      this.FieldForm[ind].if_condition.conditions[ind2].valforboxes = {};
      this.FieldForm[ind].if_condition.conditions[ind2].AddressType = [];

      this.FieldForm[ind].if_condition.conditions[ind2].rating.accept = "";
      this.FieldForm[ind].if_condition.conditions[ind2].rating.value = "";
      this.FieldForm[ind].if_condition.conditions[ind2].rating.size = "";
      this.FieldForm[ind].if_condition.conditions[ind2].rating.description = "";
      this.FieldForm[ind].if_condition.conditions[ind2].groupFields = [];
      this.FieldForm[ind].if_condition.conditions[
        ind2
      ].LikertQuestions.Questions = [];
      this.FieldForm[ind].if_condition.conditions[ind2].LikertQuestions.values =
        [];

      console.log(this.FieldForm, "dddff");
      // this.FieldForm[ind].if_condition.conditions[ind2].FieldConstraint=this.FieldConditionList

      console.log(this.FieldForm, "ffff11)");
      if (!this.Break) {
        let newone = this.Fields.find((item) => {
          return item.name == val;
        });
        this.FieldForm[ind].if_condition.conditions[ind2].multiplecheckbox =
          newone.values;

        // console.log(newone,'newwwaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa')
      } else {
        let newone = [];

        this.Fields.forEach((fields) => {
          fields.filter((item) => {
            // console.log(item,'item')
            if (item.name == val) {
              return newone.push(item);
            }
          });
          // console.log(newone,'newone')
          // console.log(this.FieldForm,'ffdd')
          this.FieldForm[ind].if_condition.conditions[ind2].multiplecheckbox =
            newone[0].values;
          // console.log(this.FieldForm,'fff')
          //  newone.forEach((item)=>{
          //   this.FieldForm[ind].if_condition.conditions[ind2].valforboxes=item

          // })
        });
      }
    } else if (val.includes("address-group")) {
      // alert('address-group')
      // console.log(this.Fields,'ggyyy')

      this.FieldForm[ind].if_condition.conditions[ind2].valforboxes = {};

      this.FieldForm[ind].if_condition.conditions[ind2].multiplecheckbox = [];
      this.FieldForm[ind].if_condition.conditions[ind2].rating.accept = "";
      this.FieldForm[ind].if_condition.conditions[ind2].rating.value = "";
      this.FieldForm[ind].if_condition.conditions[ind2].rating.size = "";
      this.FieldForm[ind].if_condition.conditions[ind2].rating.description = "";
      this.FieldForm[ind].if_condition.conditions[ind2].groupFields = [];
      this.FieldForm[ind].if_condition.conditions[
        ind2
      ].LikertQuestions.Questions = [];
      this.FieldForm[ind].if_condition.conditions[ind2].LikertQuestions.values =
        [];
      if (!this.Break) {
        let newone = this.Fields.find((item) => {
          return item.name == val;
        });

        this.FieldForm[ind].if_condition.conditions[ind2].AddressType =
          newone.addressGroup;
      } else {
        // console.log('val',val)
        let newone = [];
        this.Fields.forEach((fields) => {
          fields.filter((item) => {
            // console.log(item,'item')
            if (item.name == val) {
              return newone.push(item);
            }
          });
        });
        // console.log(newone,'fieldsnewonebreak')
        this.FieldForm[ind].if_condition.conditions[ind2].AddressType =
          newone[0].addressGroup;
      }

      console.log(this.FieldForm, "ffff");
    } else if (val.includes("group")) {
      // alert('group')
      // console.log(this.Fields,'ggyyy')

      this.FieldForm[ind].if_condition.conditions[ind2].valforboxes = {};
      this.FieldForm[ind].if_condition.conditions[ind2].conditiontype = "text";
      this.FieldForm[ind].if_condition.conditions[ind2].AddressType = [];
      this.FieldForm[ind].if_condition.conditions[ind2].multiplecheckbox = [];
      this.FieldForm[ind].if_condition.conditions[ind2].rating.accept = "";
      this.FieldForm[ind].if_condition.conditions[ind2].rating.value = "";
      this.FieldForm[ind].if_condition.conditions[ind2].rating.size = "";
      this.FieldForm[ind].if_condition.conditions[ind2].rating.description = "";

      this.FieldForm[ind].if_condition.conditions[
        ind2
      ].LikertQuestions.Questions = [];
      this.FieldForm[ind].if_condition.conditions[ind2].LikertQuestions.values =
        [];
      if (!this.Break) {
        let newone = this.Fields.find((item) => {
          return item.name == val;
        });
        this.FieldForm[ind].if_condition.conditions[ind2].groupFields =
          newone.groupFields;
      } else {
        let newone = [];
        console.log(this.Fields, "fffyy");
        this.Fields.forEach((fields) => {
          fields.forEach((item) => {
            // console.log(item,'item')
            if (item.type == "group") {
              return newone.push(item);
            }
          });
          // console.log(newone,'newonefff')
          // console.log(this.FieldForm,'ffdd')
          this.FieldForm[ind].if_condition.conditions[ind2].groupFields =
            newone[0].groupFields;
          // console.log(this.FieldForm,'aftergroupClick')
        });
      }

      console.log(this.FieldForm, "ffff");
    } else if (val.includes("likert")) {
      // console.log("likertinnn")
      this.FieldForm[ind].if_condition.conditions[ind2].valforboxes = {};
      this.FieldForm[ind].if_condition.conditions[ind2].conditiontype = "text";
      this.FieldForm[ind].if_condition.conditions[ind2].AddressType = [];
      this.FieldForm[ind].if_condition.conditions[ind2].multiplecheckbox = [];
      this.FieldForm[ind].if_condition.conditions[ind2].rating.accept = "";
      this.FieldForm[ind].if_condition.conditions[ind2].rating.value = "";
      this.FieldForm[ind].if_condition.conditions[ind2].rating.size = "";
      this.FieldForm[ind].if_condition.conditions[ind2].rating.description = "";
      this.FieldForm[ind].if_condition.conditions[ind2].groupFields = [];

      if (!this.Break) {
        let newone = this.Fields.find((item) => {
          return item.name == val;
        });
        // console.log(newone,'likertnewone')
        this.FieldForm[ind].if_condition.conditions[
          ind2
        ].LikertQuestions.Questions = newone.QuestionArr;
        this.FieldForm[ind].if_condition.conditions[
          ind2
        ].LikertQuestions.values = newone.QuestionArr[0].values;
        // console.log(this.FieldForm,"ffddfdfdfdfd====");
      } else {
        let newone = [];

        this.Fields.forEach((fields) => {
          fields.filter((item) => {
            // console.log(item,'item')
            if (item.name == val) {
              return newone.push(item);
            }
          });
          // console.log(newone,'newoneliikppp')
        });
        this.FieldForm[ind].if_condition.conditions[
          ind2
        ].LikertQuestions.Questions = newone[0].QuestionArr;
        this.FieldForm[ind].if_condition.conditions[
          ind2
        ].LikertQuestions.values = newone[0].QuestionArr[0].values;
      }
    } else {
      this.FieldForm[ind].if_condition.conditions[ind2].valforboxes = {};
      this.FieldForm[ind].if_condition.conditions[ind2].conditiontype = "text";
      this.FieldForm[ind].if_condition.conditions[ind2].AddressType = [];
      this.FieldForm[ind].if_condition.conditions[ind2].multiplecheckbox = [];
      this.FieldForm[ind].if_condition.conditions[ind2].rating.accept = "";
      this.FieldForm[ind].if_condition.conditions[ind2].rating.value = "";
      this.FieldForm[ind].if_condition.conditions[ind2].rating.size = "";
      this.FieldForm[ind].if_condition.conditions[ind2].rating.description = "";
      this.FieldForm[ind].if_condition.conditions[ind2].groupFields = [];
      this.FieldForm[ind].if_condition.conditions[
        ind2
      ].LikertQuestions.Questions = [];
      this.FieldForm[ind].if_condition.conditions[ind2].LikertQuestions.values =
        [];
    }

    this.Condition_Show = true;
    // console.log(this.Condition_Show,'111');
  }
  onlikertoptions(val, ind, ind2) {
    // console.log(this.Fields,'bbfieldssss  ')
    if (!this.Break) {
      let fieldarr = this.Fields.find((item) => {
        return item.type == "likert";
      });
      // console.log(fieldarr,'rrr')
      // console.log(val,"valwues")
      let newone = fieldarr.QuestionArr.find((item) => {
        return item.QuestionName == val;
      });
      // console.log(newone,'likert')
      this.FieldForm[ind].if_condition.conditions[ind2].LikertQuestions.values =
        newone.values;
      console.log(this.FieldForm, "fiedlikert");
    } else {
      // console.log(this.Fields,'fieldssss  ')
      let newone2 = [];

      this.Fields.forEach((fields) => {
        fields.forEach((item) => {
          // console.log(item,'item')
          if (item.type == "likert") {
            return newone2.push(item);
          }
        });
      });
      // console.log(newone2,'newoneoo22')

      let newone = [];
      // console.log(val,'valllllll')
      newone2[0].QuestionArr.find((item) => {
        if (item.QuestionName == val) {
          return newone.push(item);
        }
        // console.log(item,'item',item.QuestionName == val)
      });
      // console.log(newone,'newoneoo')

      this.FieldForm[ind].if_condition.conditions[ind2].LikertQuestions.values =
        newone[0].values;

      // //
      // console.log(this.FieldForm,'fiedlikert')
    }
  }

  onlikertoptionsGroup(val, ind, ind2) {
    // console.log(this.Fields,'bbfieldssss  ')
    if (!this.Break) {
      let fieldarr = this.Fields.find((item) => {
        return item.type == "group";
      });
      // console.log(fieldarr,'rrr')
      // console.log(val,"valwues")
      let newone = [];
      let newone2 = fieldarr.groupFields.forEach((item) => {
        item.QuestionArr.find((ite) => {
          if (ite.QuestionName == val) {
            newone.push(ite);
          }
        });
        // return item.QuestionName==val
      });
      // console.log(newone,'likert')
      this.FieldForm[ind].if_condition.conditions[ind2].LikertQuestions.values =
        newone[0].values;
      // console.log(this.FieldForm,'fiedlikert')
    } else {
      // console.log(this.Fields,'fieldssss  ')
      let newone2 = [];

      this.Fields.forEach((fields) => {
        fields.forEach((item) => {
          if (item.type == "group") {
            item.groupFields.find((ite) => {
              if (ite.type == "likert") {
                // newone2.push(ite);
                // console.log(ite,'ite')
                // ite.groupFields.forEach((field)=>{
                ite.QuestionArr.find((ite) => {
                  if (ite.QuestionName == val) {
                    newone2.push(ite);
                  }
                });
                // })
              }
            });
          }
        });
      });
      // console.log(newone2,'newoneoo22')

      // let newone =[]
      // // console.log(val,'valllllll')
      //    newone =newone2.groupFields.forEach((item)=>{
      //     item.QuestionArr.find((ite)=>{
      //       if (ite.QuestionName ==val){
      //         newone.push(ite)
      //       }
      //     })
      //   // return item.QuestionName==val
      //       })
      // newone2[0].QuestionArr.find((item)=>{
      //    if(item.QuestionName ==val){
      //   return newone.push(item)
      //    }
      // // console.log(item,'item',item.QuestionName == val)
      // })
      // console.log(newone,'newoneoo')

      this.FieldForm[ind].if_condition.conditions[ind2].LikertQuestions.values =
        newone2[0].values;

      // //
      // console.log(this.FieldForm,'fiedlikert')
    }
  }

  onlikertThenoptions(val, ind, ind2) {
    // console.log(this.Fields,'bbfieldssss  ')
    if (!this.Break) {
      let fieldarr = this.Fields.find((item) => {
        return item.type == "likert";
      });
      // console.log(fieldarr,'rrr')
      // console.log(val,"valwues")
      let newone = fieldarr.QuestionArr.find((item) => {
        return item.QuestionName == val;
      });
      // console.log(newone,'likert')
      // console.log(this.FieldForm[ind].then_apply[ind2],'errr)')
      this.FieldForm[ind].then_apply[ind2].LikertQuestions.values =
        newone.values;
      console.log(this.FieldForm, "fiedlikert");
    } else {
      // console.log(this.Fields,'fieldssss  ')
      let newone2 = [];

      this.Fields.forEach((fields) => {
        fields.forEach((item) => {
          // console.log(item,'item')
          if (item.type == "likert") {
            return newone2.push(item);
          }
        });
      });
      // console.log(newone2,'newoneoo22')

      let newone = [];
      // console.log(val,'valllllll')
      newone2[0].QuestionArr.find((item) => {
        if (item.QuestionName == val) {
          return newone.push(item);
        }
        // console.log(item,'item',item.QuestionName == val)
      });
      // console.log(newone,'newoneoo')

      this.FieldForm[ind].then_apply[ind2].LikertQuestions.values =
        newone[0].values;

      // //
      // console.log(this.FieldForm,'fiedlikert')
    }
  }
  onlikertThenoptionsGroup(val, ind, ind2) {
    // console.log(this.Fields,'bbfieldsssslll  ')
    if (!this.Break) {
      let fieldarr = this.Fields.find((item) => {
        return item.type == "group";
      });
      // console.log(fieldarr,'rrr')
      // console.log(val,"valwues")
      let newone = [];
      let newone2 = fieldarr.groupFields.forEach((item) => {
        // console.log(item,'val')
        item.QuestionArr.forEach((ite) => {
          if (ite.QuestionName == val) {
            newone.push(ite);
          }
        });
        // return item.QuestionName==val
      });
      // console.log(newone,'likert')
      this.FieldForm[ind].then_apply[ind2].LikertQuestions.values =
        newone[0].values;
      // console.log(this.FieldForm,'fiedlikert')
    } else {
      // console.log(this.Fields,'fieldssss  ')
      let newone2 = [];

      this.Fields.forEach((fields) => {
        fields.forEach((item) => {
          if (item.type == "group") {
            item.groupFields.find((ite) => {
              if (ite.type == "likert") {
                // newone2.push(ite);
                // console.log(ite,'ite')
                // ite.groupFields.forEach((field)=>{
                ite.QuestionArr.find((ite) => {
                  if (ite.QuestionName == val) {
                    newone2.push(ite);
                  }
                });
                // })
              }
            });
          }
        });
      });
      // console.log(newone2,'newoneoo22')

      // let newone =[]
      // // console.log(val,'valllllll')
      //    newone =newone2.groupFields.forEach((item)=>{
      //     item.QuestionArr.find((ite)=>{
      //       if (ite.QuestionName ==val){
      //         newone.push(ite)
      //       }
      //     })
      //   // return item.QuestionName==val
      //       })
      // newone2[0].QuestionArr.find((item)=>{
      //    if(item.QuestionName ==val){
      //   return newone.push(item)
      //    }
      // // console.log(item,'item',item.QuestionName == val)
      // })
      // console.log(newone,'newoneoo')

      this.FieldForm[ind].if_condition.conditions[ind2].LikertQuestions.values =
        newone2[0].values;

      // //
      // console.log(this.FieldForm,'fiedlikert')
    }
  }
  onUserInfoFieldSelect3(val, ind, ind2) {
    // console.log(val,'val');

    if (val.includes("file")) {
      this.FieldForm[ind].if_condition.conditions[ind2].FieldConstraint =
        this.FieldConditionList.filter((field) => {
          return field.name == "Is Empty" || field.name == "Is Not Empty";
        });
      this.FieldForm[ind].if_condition.conditions[ind2].FileEmpt = true;
    } else if (val.includes("rating")) {
      this.FieldForm[ind].if_condition.conditions[ind2].FieldConstraint =
        this.FieldConditionList.filter((field) => {
          return (
            field.name == "Is" ||
            field.name == "Is Not" ||
            field.name == "Is Empty" ||
            field.name == "Is Not Empty"
          );
        });
    } else {
      this.FieldForm[ind].if_condition.conditions[ind2].FieldConstraint =
        this.FieldConditionList;
    }
    if (val.includes("date") && !val.includes("datetime")) {
      this.FieldForm[ind].if_condition.conditions[ind2].valforboxes = {};

      this.FieldForm[ind].if_condition.conditions[ind2].rating.accept = "";
      this.FieldForm[ind].if_condition.conditions[ind2].rating.value = "";
      this.FieldForm[ind].if_condition.conditions[ind2].rating.size = "";
      this.FieldForm[ind].if_condition.conditions[ind2].rating.description = "";
      this.FieldForm[ind].if_condition.conditions[
        ind2
      ].LikertQuestions.Questions = [];
      this.FieldForm[ind].if_condition.conditions[ind2].LikertQuestions.values =
        [];
      this.FieldForm[ind].if_condition.conditions[ind2].AddressType = [];
      this.FieldForm[ind].if_condition.conditions[ind2].multiplecheckbox = [];

      this.FieldForm[ind].if_condition.conditions[ind2].rating.description = "";
      this.FieldForm[ind].if_condition.conditions[ind2].conditiontype = "date";
    } else if (val.includes("datetime")) {
      // alert('datetime')
      this.FieldForm[ind].if_condition.conditions[ind2].valforboxes = {};
      this.FieldForm[ind].if_condition.conditions[ind2].AddressType = [];
      this.FieldForm[ind].if_condition.conditions[ind2].multiplecheckbox = [];
      this.FieldForm[ind].if_condition.conditions[ind2].rating.accept = "";
      this.FieldForm[ind].if_condition.conditions[ind2].rating.value = "";
      this.FieldForm[ind].if_condition.conditions[ind2].rating.size = "";
      this.FieldForm[ind].if_condition.conditions[ind2].rating.description = "";
      this.FieldForm[ind].if_condition.conditions[ind2].conditiontype =
        "datetime-local";
      // console.log(this.FieldForm,'ffffffyfyfyfyfyfyfyf========')
    } else if (val.includes("time")) {
      // alert('time')
      this.FieldForm[ind].if_condition.conditions[ind2].valforboxes = {};

      this.FieldForm[ind].if_condition.conditions[ind2].rating.accept = "";
      this.FieldForm[ind].if_condition.conditions[ind2].rating.value = "";
      this.FieldForm[ind].if_condition.conditions[ind2].rating.size = "";
      this.FieldForm[ind].if_condition.conditions[ind2].rating.description = "";
      this.FieldForm[ind].if_condition.conditions[
        ind2
      ].LikertQuestions.Questions = [];
      this.FieldForm[ind].if_condition.conditions[ind2].LikertQuestions.values =
        [];
      this.FieldForm[ind].if_condition.conditions[ind2].AddressType = [];
      this.FieldForm[ind].if_condition.conditions[ind2].multiplecheckbox = [];
      this.FieldForm[ind].if_condition.conditions[ind2].conditiontype = "time";
    } else if (val.includes("checkbox")) {
      this.FieldForm[ind].if_condition.conditions[ind2].rating.accept = "";
      this.FieldForm[ind].if_condition.conditions[ind2].rating.value = "";
      this.FieldForm[ind].if_condition.conditions[ind2].rating.size = "";
      this.FieldForm[ind].if_condition.conditions[ind2].rating.description = "";
      this.FieldForm[ind].if_condition.conditions[
        ind2
      ].LikertQuestions.Questions = [];
      this.FieldForm[ind].if_condition.conditions[ind2].LikertQuestions.values =
        [];
      this.FieldForm[ind].if_condition.conditions[ind2].AddressType = [];
      this.FieldForm[ind].if_condition.conditions[ind2].multiplecheckbox = [];

      // })

      if (!this.Break) {
        let fieldarr = this.Fields.find((item) => {
          return item.type == "group";
        });
        // console.log(this.Fields,'ff===')
        let newone = fieldarr.groupFields.find((item) => {
          return item.name == val;
        });
        // console.log(newone,'newww')
        //only for one time loop

        this.FieldForm[ind].if_condition.conditions[ind2].valforboxes = newone;
      } else {
        // let grpone ={}
        let newone = [];
        this.Fields.forEach((fields) => {
          fields.forEach((item) => {
            // console.log(item,'item')
            if (item.type == "group")
              item.groupFields.forEach((item) => {
                if (item.name == val) {
                  return newone.push(item);
                }
              });
          });
        });
        // console.log(newone,"newoneinnnnnnn")
        // for one value in loop
        newone.forEach((item) => {
          this.FieldForm[ind].if_condition.conditions[ind2].valforboxes = item;
        });
        // this.FieldForm[ind].if_condition.conditions[ind2].valforboxes=newone[0].values
        // console.log(this.FieldForm,'fieldform')
      }
      // this.FieldForm[ind].if_condition.conditions[ind2].FieldConstraint=this.FieldConditionList

      // console.log(this.FieldForm,'ffff11)')
    } else if (val.includes("rating")) {
      // alert('rating')
      let fieldarr = this.Fields.find((item) => {
        return item.type == "group";
      });
      let newone = fieldarr.groupFields.find((item) => {
        return item.name == val;
      });
      this.FieldForm[ind].if_condition.conditions[ind2].valforboxes = {};
      this.FieldForm[ind].if_condition.conditions[
        ind2
      ].LikertQuestions.Questions = [];
      this.FieldForm[ind].if_condition.conditions[ind2].LikertQuestions.values =
        [];
      this.FieldForm[ind].if_condition.conditions[ind2].AddressType = [];
      this.FieldForm[ind].if_condition.conditions[ind2].multiplecheckbox = [];
      this.FieldForm[ind].if_condition.conditions[ind2].rating.accept =
        newone.accept;
      this.FieldForm[ind].if_condition.conditions[ind2].rating.value =
        newone.value;
      this.FieldForm[ind].if_condition.conditions[ind2].rating.size =
        newone.size;
      this.FieldForm[ind].if_condition.conditions[ind2].rating.description =
        newone.description;

      // console.log(this.FieldForm,'fgfgfgfg');
    } else if (val.includes("radio")) {
      // this.FieldForm[ind].if_condition.conditions[ind2].group_type=val

      this.FieldForm[ind].if_condition.conditions[ind2].rating.accept = "";
      this.FieldForm[ind].if_condition.conditions[ind2].rating.value = "";
      this.FieldForm[ind].if_condition.conditions[ind2].rating.size = "";
      this.FieldForm[ind].if_condition.conditions[ind2].rating.description = "";
      this.FieldForm[ind].if_condition.conditions[
        ind2
      ].LikertQuestions.Questions = [];
      this.FieldForm[ind].if_condition.conditions[ind2].LikertQuestions.values =
        [];
      this.FieldForm[ind].if_condition.conditions[ind2].AddressType = [];
      this.FieldForm[ind].if_condition.conditions[ind2].multiplecheckbox = [];

      if (!this.Break) {
        let fieldarr = this.Fields.find((item) => {
          return item.type == "group";
        });
        // console.log(this.Fields,'ff===')
        let newone = fieldarr.groupFields.find((item) => {
          return item.name == val;
        });
        // console.log(newone,'newww')
        this.FieldForm[ind].if_condition.conditions[ind2].valforboxes = newone;
      } else {
        // let grpone ={}
        // console.log(val,'val')
        let newone = [];
        this.Fields.forEach((fields) => {
          fields.filter((item) => {
            // console.log(item,'item')
            if (item.type == "group")
              item.groupFields.forEach((item) => {
                if (item.name == val) {
                  newone.push(item);
                }
              });
          });
        });
        // console.log(newone,"newone")
        // for one value in loop
        newone.forEach((item) => {
          this.FieldForm[ind].if_condition.conditions[ind2].valforboxes = item;
        });
        // this.FieldForm[ind].if_condition.conditions[ind2].valforboxes=newone[0].values
        // console.log(this.FieldForm,'fffooorrmmmmm')

        // console.log(newone,'newone');

        let fieldarr = this.Fields.find((item) => {
          return item.type == "group";
        });
        // console.log(fieldarr,'fieldarr')
      }
      //     this.FieldForm[ind].if_condition.conditions[ind2].FieldConstraint=this.FieldConditionList

      // console.log(this.FieldForm,'ffff1111111111111111111111111111111111111)')
    } else if (val.includes("autocomplete")) {
      // console.log(this.Fields,'ggyyy',val)

      this.FieldForm[ind].if_condition.conditions[ind2].valforboxes = {};

      this.FieldForm[ind].if_condition.conditions[ind2].rating.accept = "";
      this.FieldForm[ind].if_condition.conditions[ind2].rating.value = "";
      this.FieldForm[ind].if_condition.conditions[ind2].rating.size = "";
      this.FieldForm[ind].if_condition.conditions[ind2].rating.description = "";
      this.FieldForm[ind].if_condition.conditions[
        ind2
      ].LikertQuestions.Questions = [];
      this.FieldForm[ind].if_condition.conditions[ind2].LikertQuestions.values =
        [];
      this.FieldForm[ind].if_condition.conditions[ind2].AddressType = [];
      // console.log(this.FieldForm,'dddff');
      if (!this.Break) {
        let fieldarr = this.Fields.find((item) => {
          return item.type == "group";
        });
        // console.log(this.Fields,'ff===')
        let newone = fieldarr.groupFields.find((item) => {
          return item.name == val;
        });
        // console.log(newone,'newww')
        this.FieldForm[ind].if_condition.conditions[ind2].multiplecheckbox =
          newone.values;
      } else {
        // let grpone ={}
        let newone = [];
        this.Fields.forEach((fields) => {
          fields.filter((item) => {
            // console.log(item,'item')
            if (item.type == "group")
              item.groupFields.forEach((items) => {
                if (items.name == val) {
                  return newone.push(items);
                }
              });
          });
        });
        // console.log(newone,"newone")
        // for one value in loop
        // newone.forEach((item)=>{
        //   this.FieldForm[ind].if_condition.conditions[ind2].multiplecheckbox=item

        // })
        this.FieldForm[ind].if_condition.conditions[ind2].multiplecheckbox =
          newone[0].values;
      }
      this.FieldForm[ind].if_condition.conditions[ind2].FieldConstraint =
        this.FieldConditionList;

      // console.log(this.FieldForm,'ffff11)')
    } else if (val.includes("address-group")) {
      // alert('address-group')
      // console.log(this.Fields,'ggyyy')

      this.FieldForm[ind].if_condition.conditions[ind2].valforboxes = {};
      this.FieldForm[ind].if_condition.conditions[ind2].multiplecheckbox = [];
      this.FieldForm[ind].if_condition.conditions[ind2].rating.accept = "";
      this.FieldForm[ind].if_condition.conditions[ind2].rating.value = "";
      this.FieldForm[ind].if_condition.conditions[ind2].rating.size = "";
      this.FieldForm[ind].if_condition.conditions[ind2].rating.description = "";
      this.FieldForm[ind].if_condition.conditions[
        ind2
      ].LikertQuestions.Questions = [];
      this.FieldForm[ind].if_condition.conditions[ind2].LikertQuestions.values =
        [];

      if (!this.Break) {
        let fieldarr = this.Fields.find((item) => {
          return item.type == "group";
        });
        let newone = fieldarr.groupFields.find((item) => {
          return item.name == val;
        });
        // console.log(newone,'newwww')
        this.FieldForm[ind].if_condition.conditions[ind2].AddressType =
          newone.addressGroup;
      } else {
        // console.log('val',val)
        let newone2 = [];

        this.Fields.forEach((fields) => {
          fields.forEach((item) => {
            // console.log(item,'item')
            if (item.type == "group") {
              // console.log(item,'particular item')
              return newone2.push(item);
            }
          });
        });
        let newone = [];
        newone2[0].groupFields.forEach((field) => {
          if (field.name == val) {
            return newone.push(field);
          }
        });
        console.log(newone, "fieldsnewonebreak");
        this.FieldForm[ind].if_condition.conditions[ind2].AddressType =
          newone[0].addressGroup;

        console.log(newone2, "222f2222222ieldsnewoneb22222reak");
      }
      // console.log(this.FieldForm,"ffff")
    } else if (val.includes("likert")) {
      // alert('likert')
      console.log(this.FieldForm, "fffff9999");
      console.log(val, "c=val");

      this.FieldForm[ind].if_condition.conditions[ind2].valforboxes = {};
      this.FieldForm[ind].if_condition.conditions[ind2].multiplecheckbox = [];
      this.FieldForm[ind].if_condition.conditions[ind2].rating.accept = "";
      this.FieldForm[ind].if_condition.conditions[ind2].rating.value = "";
      this.FieldForm[ind].if_condition.conditions[ind2].rating.size = "";
      this.FieldForm[ind].if_condition.conditions[ind2].rating.description = "";
      this.FieldForm[ind].if_condition.conditions[
        ind2
      ].LikertQuestions.Questions = [];
      this.FieldForm[ind].if_condition.conditions[ind2].LikertQuestions.values =
        [];
      this.FieldForm[ind].if_condition.conditions[ind2].AddressType = [];

      if (!this.Break) {
        let newone2 = this.Fields.find((item) => {
          return item.type == "group";
        });
        // console.log(newone2,'newoneggg222')
        let newone = newone2.groupFields.find((item) => {
          return item.name == val;
        });
        // console.log(newone,'newone');
        this.FieldForm[ind].if_condition.conditions[
          ind2
        ].LikertQuestions.Questions = newone.QuestionArr;
        this.FieldForm[ind].if_condition.conditions[
          ind2
        ].LikertQuestions.values = newone.QuestionArr[0].values;

        // console.log(this.FieldForm,'f=f=f=f=f=f==fff=')
      } else {
        let newone = [];
        // console.log(val,'val')
        this.Fields.forEach((fields) => {
          fields.filter((item) => {
            // console.log(item,'item')
            if (item.type == "group") {
              return newone.push(item);
            }
          });
          // console.log(newone,'newoneliikppp')
        });
        let newone2 = newone[0].groupFields.find((field) => {
          return field.type == "likert";
        });
        // console.log(newone2.QuestionArr,'22')
        // newone2.QuestionArr.forEach((question)=>{

        // })
        this.FieldForm[ind].if_condition.conditions[
          ind2
        ].LikertQuestions.Questions = newone2.QuestionArr;
        this.FieldForm[ind].if_condition.conditions[
          ind2
        ].LikertQuestions.values = newone2.QuestionArr[0].values;
        // console.log(this.FieldForm,"ffddfdfdfdfd====");
      }
    } else {
      // console.log('hit exchange')
      this.FieldForm[ind].if_condition.conditions[ind2].valforboxes = {};
      this.FieldForm[ind].if_condition.conditions[ind2].conditiontype = "text";
      this.FieldForm[ind].if_condition.conditions[ind2].AddressType = [];
      this.FieldForm[ind].if_condition.conditions[ind2].multiplecheckbox = [];
      this.FieldForm[ind].if_condition.conditions[ind2].rating.accept = "";
      this.FieldForm[ind].if_condition.conditions[ind2].rating.value = "";
      this.FieldForm[ind].if_condition.conditions[ind2].rating.size = "";
      this.FieldForm[ind].if_condition.conditions[ind2].rating.description = "";
      // this.FieldForm[ind].if_condition.conditions[ind2].groupFields=[]
      // console.log(this.FieldForm,"dfff")
    }

    // this.Condition_Show[ind2]=true
    // console.log(this.Condition_Show,'111');
    // console.log(this.FieldForm,'222111');
  }
  thenThenRealtion(val,ind,ind2){
    console.log(val,ind,ind2,"ON THEN SELECT")
    // this.globalthen = 
    // console.log(this.FieldForm[ind].then_apply[0],'0001010101010');
    
    // this.FieldForm[ind].then_apply[0].thenFinalValue.forEach((val,ind)=>{
      // console.log(val,'valllll')
    // })
    this.Fields.forEach((fiel: any, j) => {
      if (fiel.name == val) {
        console.log(j, "index++++++++++++");
          this.FieldForm[ind].then_apply[ind2].thenindex=j
        }
    });
    // console.log(this.FieldForm[ind].then_apply[0],'0001010101010');
   this.FieldForm[ind].then_apply.forEach((valq,i)=>{
if(i !=ind2){
  valq.thenFinalValue=valq.thenFinalValue.filter((fil,p)=>{
    console.log(fil.name ,'val',val)
    return fil.name != val
  })
  console.log(valq.thenFinalValue,'vvvvvvv')
}
   })
 
  // console.log(this.FieldForm[ind].then_apply[ind2],"fffffffffffffinCHnage")
    // console.log(this.FieldForm[ind],'thenapp;y')
    // let new01=[]

  //   this.FieldForm[ind].then_apply.forEach((cond,j) => {
  //     console.log(cond.thenindex,'index innnnnn')
  // if(!new01.includes(cond.thenindex)){
  
  //   new01.push(cond.thenindex);
  // }
  //     if (!this.FieldForm[ind].maxthenIndexArr.includes(cond.thenindex)) {
  
  //           this.FieldForm[ind].maxthenIndexArr.push(cond.thenindex);
         
  //     }
  //     })
  //     console.log(new01,'0110101')
  //     console.log(this.FieldForm[ind].maxthenIndexArr,'this.FieldForm[ind].maxthenIndexArr')
  //     this.FieldForm[ind].maxthenIndexArr=this.FieldForm[ind].maxthenIndexArr.filter(element => new01.includes(element));
  //     console.log(this.FieldForm[ind].maxthenIndexArr,'afterthis.FieldForm[ind].maxthenIndexArr')

   
  //     //slice cut outs into newa arr
  //     Math.max(...this.FieldForm[ind].maxthenIndexArr)
  //     console.log(Math.max(...this.FieldForm[ind].maxthenIndexArr),'maxxxx')
  //     if(!!this.FieldForm[ind].maxthenIndexArr.includes(  Math.max(...this.FieldForm[ind].maxthenIndexArr))){
  // // this.FieldForm[ind].then_apply[0].thenFinalValue=[]
  
  //       // this.FieldForm[ind].then_apply[0].thenFinalValue=this.Fields.slice(Math.max(...this.FieldForm[ind].maxIndexArr)+1)
  //       console.log(this.FieldForm[ind].then_apply,'apllyyyy',this.FieldForm[ind].then_apply.length)
  //       this.FieldForm[ind].then_apply.forEach((ite,k)=>{
  //         console.log(ind,'ind',k,'k')
  //         console.log(ite.thenFinalValue,'ite.thenvalue')
       

  //       })
      // }
  }
  onThenFieldSelect(val, ind, ind2) {
    this.selectvalue(val,ind,ind2)
    console.log(val,ind,ind2,"ONCHANGE THEN")


  this.thenThenRealtion(val,ind,ind2)


    // console.log(val,'val')
    if (val.includes("address-group")) {
      if (!this.Break) {
        let newone = this.Fields.find((item) => {
          return item.name == val;
        });
        // console.log(newone,'newoneadd',ind)
        // console.log(this.FieldForm[ind],"FieldForm");

        this.FieldForm[ind].then_apply[ind2].AddressType = newone.addressGroup;
        // console.log(this.FieldForm,'then after the full form')
      } else {
        // console.log('val',val)
        let newone = [];
        this.Fields.forEach((fields) => {
          fields.filter((item) => {
            // console.log(item,'item')
            if (item.name == val) {
              return newone.push(item);
            }
          });
        });
        // console.log(newone,'fieldsnewonebreak')
        this.FieldForm[ind].then_apply[ind2].AddressType =
          newone[0].addressGroup;

        // this.FieldForm[ind].then_apply.AddressType=newone[0].addressGroup
      }
      // this.FieldForm[ind].then_apply.AddressType
      // this.FieldForm[ind].if_condition.conditions[ind2].AddressType=newone.addressGroup
    } else if (val.includes("radio")) {
      // this.FieldForm[ind].if_condition.conditions[ind2].AddressType=[]
      // this.FieldForm[ind].if_condition.conditions[ind2].multiplecheckbox=[]
      // this.FieldForm[ind].if_condition.conditions[ind2].rating.accept=''
      // this.FieldForm[ind].if_condition.conditions[ind2].rating.value=''
      // this.FieldForm[ind].if_condition.conditions[ind2].rating.size=''
      // this.FieldForm[ind].if_condition.conditions[ind2].rating.description=''
      // this.FieldForm[ind].if_condition.conditions[ind2].groupFields=[]
      // this.FieldForm[ind].if_condition.conditions[ind2].LikertQuestions.Questions=[]
      // this.FieldForm[ind].if_condition.conditions[ind2].LikertQuestions.values=[]
      // this.FieldForm[ind].if_condition.conditions[ind2].FieldConstraint=this.FieldConditionList

      // console.log(this.FieldForm,'ffff11)')
      if (!this.Break) {
        let newone = this.Fields.find((item) => {
          return item.name == val;
        });
        // console.log(newone,'newoneinthenradio');

        this.FieldForm[ind].then_apply[ind2].valforboxes = newone;
        // console.log(this.FieldForm,'fieldfoem in raiod then');
      } else {
        let newone = [];

        this.Fields.forEach((fields) => {
          fields.filter((item) => {
            // console.log(item,'item')
            if (item.name == val) {
              return newone.push(item);
            }
          });
          // console.log(newone,'newone')
          // console.log(this.FieldForm,'ffdd')
          //only for one time loop
          newone.forEach((item) => {
            this.FieldForm[ind].then_apply[ind2].valforboxes = item;
          });
        });
      }
    } else if (val.includes("checkbox")) {
      // console.log(this.Fields,'ggyyy22')

      // console.log(this.FieldForm,'bbbfields');
      // newone.forEach((item)=>{

      // this.FieldForm[ind].if_condition.conditions[ind2].AddressType=[]
      // this.FieldForm[ind].if_condition.conditions[ind2].multiplecheckbox=[]
      // this.FieldForm[ind].if_condition.conditions[ind2].rating.accept=''
      // this.FieldForm[ind].if_condition.conditions[ind2].rating.value=''
      // this.FieldForm[ind].if_condition.conditions[ind2].rating.size=''
      // this.FieldForm[ind].if_condition.conditions[ind2].rating.description=''
      // this.FieldForm[ind].if_condition.conditions[ind2].groupFields=[]
      // this.FieldForm[ind].if_condition.conditions[ind2].LikertQuestions.Questions=[]
      // this.FieldForm[ind].if_condition.conditions[ind2].LikertQuestions.values=[]
      // this.FieldForm[ind].if_condition.conditions[ind2].FieldConstraint=this.FieldConditionList

      // console.log(this.FieldForm,'ffff11)')
      if (!this.Break) {
        let newone = this.Fields.find((item) => {
          return item.name == val;
        });
        // console.log(newone,'newoneinthenradio');

        this.FieldForm[ind].then_apply[ind2].valforboxes = newone;
        // console.log(this.FieldForm,'fieldfoem in raiod then');
      } else {
        // console.log('val',val)
        let newone = [];
        this.Fields.forEach((fields) => {
          fields.filter((item) => {
            // console.log(item,'item')
            if (item.name == val) {
              return newone.push(item);
            }
          });
        });
        // console.log(newone,'fieldsnewonebreak',this.FieldForm[ind].then_apply)
        // this.FieldForm[ind].if_condition.conditions[ind2].valforboxes=newone
        //only for one time loop
        newone.forEach((item) => {
          // console.log(item,'===', )
          this.FieldForm[ind].then_apply[ind2].valforboxes = item;
        });
        // console.log(this.FieldForm[ind].then_apply[ind2].valforboxes.hasOwnProperty('values'),'fasdasdaieldin decedsiones')
      }
    } else if (val.includes("likert")) {
      // console.log("likertinnn")

      if (!this.Break) {
        let newone = this.Fields.find((item) => {
          return item.name == val;
        });
        // console.log(newone,'likertnewone')
        this.FieldForm[ind].then_apply[ind2].LikertQuestions.Questions =
          newone.QuestionArr;
        this.FieldForm[ind].then_apply[ind2].LikertQuestions.values =
          newone.QuestionArr[0].values;

        //  this.FieldForm[ind].if_condition.conditions[ind2].LikertQuestions.Questions=newone.QuestionArr
        //  this.FieldForm[ind].if_condition.conditions[ind2].LikertQuestions.values=newone.QuestionArr[0].values
        // console.log(this.FieldForm,"ffddfdfdfdfd====");
      } else {
        let newone = [];

        this.Fields.forEach((fields) => {
          fields.filter((item) => {
            // console.log(item,'item')
            if (item.name == val) {
              return newone.push(item);
            }
          });
          // console.log(newone,'newoneliikppp')
        });
        this.FieldForm[ind].then_apply[ind2].LikertQuestions.Questions =
          newone[0].QuestionArr;
        this.FieldForm[ind].then_apply[ind2].LikertQuestions.values =
          newone[0].QuestionArr[0].values;
      }
    } else if (val.includes("autocomplete")) {
      // console.log(this.Fields,'ggyyy')

      // this.FieldForm[ind].if_condition.conditions[ind2].valforboxes={}
      // this.FieldForm[ind].if_condition.conditions[ind2].AddressType=[]

      // this.FieldForm[ind].if_condition.conditions[ind2].rating.accept=''
      // this.FieldForm[ind].if_condition.conditions[ind2].rating.value=''
      // this.FieldForm[ind].if_condition.conditions[ind2].rating.size=''
      // this.FieldForm[ind].if_condition.conditions[ind2].rating.description=''
      // this.FieldForm[ind].if_condition.conditions[ind2].groupFields=[]
      // this.FieldForm[ind].if_condition.conditions[ind2].LikertQuestions.Questions=[]
      // this.FieldForm[ind].if_condition.conditions[ind2].LikertQuestions.values=[]

      // console.log(this.FieldForm,'dddff');
      // this.FieldForm[ind].if_condition.conditions[ind2].FieldConstraint=this.FieldConditionList

      // console.log(this.FieldForm,'ffff11)')
      if (!this.Break) {
        let newone = this.Fields.find((item) => {
          return item.name == val;
        });
        // console.log(newone,'newoneinthenradio');

        this.FieldForm[ind].then_apply[ind2].valforboxes = newone;
        // console.log(this.FieldForm,'fieldfoem in raiod then');
      } else {
        let newone = [];

        this.Fields.forEach((fields) => {
          fields.filter((item) => {
            // console.log(item,'item')
            if (item.name == val) {
              return newone.push(item);
            }
          });
          // console.log(newone,'newone')
          // console.log(this.FieldForm,'ffdd')
          newone.forEach((item) => {
            this.FieldForm[ind].then_apply[ind2].valforboxes = item;
          });

          // console.log(this.FieldForm,'fff')
          //  newone.forEach((item)=>{
          //   this.FieldForm[ind].if_condition.conditions[ind2].valforboxes=item

          // })
        });
      }
    } else if (val.includes("group")) {
      // alert('group')
      // console.log(this.Fields,'ggyyy')

      // this.FieldForm[ind].if_condition.conditions[ind2].valforboxes={}
      // this.FieldForm[ind].if_condition.conditions[ind2].conditiontype='text'
      // this.FieldForm[ind].if_condition.conditions[ind2].AddressType=[]
      // this.FieldForm[ind].if_condition.conditions[ind2].multiplecheckbox=[]
      // this.FieldForm[ind].if_condition.conditions[ind2].rating.accept=''
      // this.FieldForm[ind].if_condition.conditions[ind2].rating.value=''
      // this.FieldForm[ind].if_condition.conditions[ind2].rating.size=''
      // this.FieldForm[ind].if_condition.conditions[ind2].rating.description=''

      // this.FieldForm[ind].if_condition.conditions[ind2].LikertQuestions.Questions=[]
      // this.FieldForm[ind].if_condition.conditions[ind2].LikertQuestions.values=[]
      if (!this.Break) {
        let newone = this.Fields.find((item) => {
          return item.name == val;
        });
        // console.log(newone,'newonegroupthen')
        this.FieldForm[ind].then_apply[ind2].groupFields = newone.groupFields;
        // console.log(this.FieldForm,'fieldformingroupthen');
      } else {
        let newone = [];
        // console.log(this.Fields,'fffyy')
        this.Fields.forEach((fields) => {
          fields.forEach((item) => {
            // console.log(item,'item')
            if (item.type == "group") {
              return newone.push(item);
            }
          });
          // console.log(newone,'newonefff')
          // console.log(this.FieldForm,'ffdd')
          // console.log(this.FieldForm,'aftergroupClick')
        });
        this.FieldForm[ind].then_apply[ind2].groupFields =
          newone[0].groupFields;
      }

      // console.log(this.FieldForm,"ffff")
    }
  }
  onThenGroupFieldSelect(val, ind, ind2) {
    if (val.includes("address-group")) {
      // alert('address-group')
      // console.log(this.Fields,'ggyyy')

      //       this.FieldForm[ind].if_condition.conditions[ind2].valforboxes={}
      // this.FieldForm[ind].if_condition.conditions[ind2].multiplecheckbox=[]
      // this.FieldForm[ind].if_condition.conditions[ind2].rating.accept=''
      // this.FieldForm[ind].if_condition.conditions[ind2].rating.value=''
      // this.FieldForm[ind].if_condition.conditions[ind2].rating.size=''
      // this.FieldForm[ind].if_condition.conditions[ind2].rating.description=''
      // this.FieldForm[ind].if_condition.conditions[ind2].LikertQuestions.Questions=[]
      // this.FieldForm[ind].if_condition.conditions[ind2].LikertQuestions.values=[]

      if (!this.Break) {
        let fieldarr = this.Fields.find((item) => {
          return item.type == "group";
        });
        let newone = fieldarr.groupFields.find((item) => {
          return item.name == val;
        });
        // console.log(newone,'newwww')
        this.FieldForm[ind].then_apply[ind2].AddressType = newone.addressGroup;
        // console.log(this.FieldForm,'fielformadd');
      } else {
        // console.log('val',val)
        let newone2 = [];

        this.Fields.forEach((fields) => {
          fields.forEach((item) => {
            // console.log(item,'item')
            if (item.type == "group") {
              // console.log(item,'particular item')
              return newone2.push(item);
            }
          });
        });
        let newone = [];
        newone2[0].groupFields.forEach((field) => {
          if (field.name == val) {
            return newone.push(field);
          }
        });
        console.log(newone, "fieldsnewonebreak");
        this.FieldForm[ind].then_apply[ind2].AddressType =
          newone[0].addressGroup;

        console.log(newone2, "222f2222222ieldsnewoneb22222reak");
      }
      // console.log(this.FieldForm,"ffff")
    } else if (val.includes("radio")) {
      // this.FieldForm[ind].if_condition.conditions[ind2].group_type=val

      // this.FieldForm[ind].if_condition.conditions[ind2].rating.accept=''
      // this.FieldForm[ind].if_condition.conditions[ind2].rating.value=''
      // this.FieldForm[ind].if_condition.conditions[ind2].rating.size=''
      // this.FieldForm[ind].if_condition.conditions[ind2].rating.description=''
      // this.FieldForm[ind].if_condition.conditions[ind2].LikertQuestions.Questions=[]
      // this.FieldForm[ind].if_condition.conditions[ind2].LikertQuestions.values=[]
      // this.FieldForm[ind].if_condition.conditions[ind2].AddressType=[]
      // this.FieldForm[ind].if_condition.conditions[ind2].multiplecheckbox=[]

      if (!this.Break) {
        let fieldarr = this.Fields.find((item) => {
          return item.type == "group";
        });
        // console.log(this.Fields,'ff===')
        let newone = fieldarr.groupFields.find((item) => {
          return item.name == val;
        });
        // console.log(newone,'newww')
        this.FieldForm[ind].then_apply[ind2].valforboxes = newone;
      } else {
        // let grpone ={}
        // console.log(val,'val')
        let newone = [];
        this.Fields.forEach((fields) => {
          fields.filter((item) => {
            // console.log(item,'item')
            if (item.type == "group")
              item.groupFields.forEach((item) => {
                if (item.name == val) {
                  newone.push(item);
                }
              });
          });
        });
        // console.log(newone,"newone")
        // for one value in loop
        newone.forEach((item) => {
          this.FieldForm[ind].then_apply[ind2].valforboxes = item;
        });
        // this.FieldForm[ind].if_condition.conditions[ind2].valforboxes=newone[0].values
        // console.log(this.FieldForm,'fffooorrmmmmm')

        // console.log(newone,'newone');

        let fieldarr = this.Fields.find((item) => {
          return item.type == "group";
        });
        // console.log(fieldarr,'fieldarr')
      }
      //     this.FieldForm[ind].if_condition.conditions[ind2].FieldConstraint=this.FieldConditionList

      // console.log(this.FieldForm,'ffff1111111111111111111111111111111111111)')
    } else if (val.includes("checkbox")) {
      // this.FieldForm[ind].if_condition.conditions[ind2].rating.accept=''
      // this.FieldForm[ind].if_condition.conditions[ind2].rating.value=''
      // this.FieldForm[ind].if_condition.conditions[ind2].rating.size=''
      // this.FieldForm[ind].if_condition.conditions[ind2].rating.description=''
      // this.FieldForm[ind].if_condition.conditions[ind2].LikertQuestions.Questions=[]
      // this.FieldForm[ind].if_condition.conditions[ind2].LikertQuestions.values=[]
      // this.FieldForm[ind].if_condition.conditions[ind2].AddressType=[]
      // this.FieldForm[ind].if_condition.conditions[ind2].multiplecheckbox=[]

      // })

      if (!this.Break) {
        let fieldarr = this.Fields.find((item) => {
          return item.type == "group";
        });
        // console.log(this.Fields,'ff===')
        let newone = fieldarr.groupFields.find((item) => {
          return item.name == val;
        });
        // console.log(newone,'newww')
        //only for one time loop

        this.FieldForm[ind].then_apply[ind2].valforboxes = newone;
      } else {
        // let grpone ={}
        let newone = [];
        this.Fields.forEach((fields) => {
          fields.forEach((item) => {
            // console.log(item,'item')
            if (item.type == "group")
              item.groupFields.forEach((item) => {
                if (item.name == val) {
                  return newone.push(item);
                }
              });
          });
        });
        // console.log(newone,"newoneinnnnnnn")
        // for one value in loop
        newone.forEach((item) => {
          this.FieldForm[ind].then_apply[ind2].valforboxes = item;
        });
        // this.FieldForm[ind].if_condition.conditions[ind2].valforboxes=newone[0].values
        // console.log(this.FieldForm,'fieldform')
      }
      // this.FieldForm[ind].if_condition.conditions[ind2].FieldConstraint=this.FieldConditionList

      // console.log(this.FieldForm,'ffff11)')
    } else if (val.includes("autocomplete")) {
      // console.log(this.Fields,'ggyyy',val)

      // this.FieldForm[ind].if_condition.conditions[ind2].valforboxes={}

      // this.FieldForm[ind].if_condition.conditions[ind2].rating.accept=''
      // this.FieldForm[ind].if_condition.conditions[ind2].rating.value=''
      // this.FieldForm[ind].if_condition.conditions[ind2].rating.size=''
      // this.FieldForm[ind].if_condition.conditions[ind2].rating.description=''
      // this.FieldForm[ind].if_condition.conditions[ind2].LikertQuestions.Questions=[]
      // this.FieldForm[ind].if_condition.conditions[ind2].LikertQuestions.values=[]
      // this.FieldForm[ind].if_condition.conditions[ind2].AddressType=[]
      console.log(this.FieldForm, "dddff");
      if (!this.Break) {
        let fieldarr = this.Fields.find((item) => {
          return item.type == "group";
        });
        // console.log(this.Fields,'ff===')
        let newone = fieldarr.groupFields.find((item) => {
          return item.name == val;
        });
        // console.log(newone,'newww')
        this.FieldForm[ind].then_apply[ind2].valforboxes = newone;
      } else {
        // let grpone ={}
        let newone = [];
        this.Fields.forEach((fields) => {
          fields.filter((item) => {
            // console.log(item,'item')
            if (item.type == "group")
              item.groupFields.forEach((items) => {
                if (items.name == val) {
                  return newone.push(items);
                }
              });
          });
        });
        // console.log(newone,"newone")
        // for one value in loop
        // newone.forEach((item)=>{
        //   this.FieldForm[ind].if_condition.conditions[ind2].multiplecheckbox=item

        // })
        newone.forEach((item) => {
          this.FieldForm[ind].then_apply[ind2].valforboxes = item;
        });
      }
      // this.FieldForm[ind].if_condition.conditions[ind2].FieldConstraint=this.FieldConditionList

      console.log(this.FieldForm, "ffff11)");
    } else if (val.includes("likert")) {
      // alert('likert')
      console.log(this.FieldForm, "fffff9999");
      console.log(val, "c=val");

      // this.FieldForm[ind].if_condition.conditions[ind2].valforboxes={}
      // this.FieldForm[ind].if_condition.conditions[ind2].multiplecheckbox=[]
      // this.FieldForm[ind].if_condition.conditions[ind2].rating.accept=''
      // this.FieldForm[ind].if_condition.conditions[ind2].rating.value=''
      // this.FieldForm[ind].if_condition.conditions[ind2].rating.size=''
      // this.FieldForm[ind].if_condition.conditions[ind2].rating.description=''
      // this.FieldForm[ind].if_condition.conditions[ind2].LikertQuestions.Questions=[]
      // this.FieldForm[ind].if_condition.conditions[ind2].LikertQuestions.values=[]
      // this.FieldForm[ind].if_condition.conditions[ind2].AddressType=[]

      if (!this.Break) {
        let newone2 = this.Fields.find((item) => {
          return item.type == "group";
        });
        // console.log(newone2,'newoneggg222')
        let newone = newone2.groupFields.find((item) => {
          return item.name == val;
        });
        // console.log(newone,'newone');
        this.FieldForm[ind].then_apply[ind2].LikertQuestions.Questions =
          newone.QuestionArr;
        this.FieldForm[ind].then_apply[ind2].LikertQuestions.values =
          newone.QuestionArr[0].values;

        // console.log(this.FieldForm,'f=f=f=f=f=f==fff=')
      } else {
        let newone = [];
        // console.log(val,'val')
        this.Fields.forEach((fields) => {
          fields.filter((item) => {
            // console.log(item,'item')
            if (item.type == "group") {
              return newone.push(item);
            }
          });
          // console.log(newone,'newoneliikppp')
        });
        let newone2 = newone[0].groupFields.find((field) => {
          return field.type == "likert";
        });
        // console.log(newone2.QuestionArr,'22')
        // newone2.QuestionArr.forEach((question)=>{

        // })
        this.FieldForm[ind].then_apply[ind2].LikertQuestions.Questions =
          newone2.QuestionArr;
        this.FieldForm[ind].then_apply[ind2].LikertQuestions.values =
          newone2.QuestionArr[0].values;
        // console.log(this.FieldForm,"ffddfdfdfdfd====");
      }
    }
  }
  newarr: any = [];

  handleDiscountRuleConditions() {
    this.formService
      .getDiscountRuleConditionsData()
      .subscribe((result: any) => {
        if (result["status"]) {
          this.FieldConditionList = result["data"];
          this.FieldForm[0].if_condition.conditions[0].FieldConstraint =
            result["data"];
          // this.parllel_fiel_value.push
          // this.parllel_fiel_value=result['data'].forEach(())
          this.conditionRows[0].constraint = this.FieldConditionList[0].name;
          this.conditionRows[0].constraint_id = this.FieldConditionList[0].id;
        }
      });
  }
  selectvalue(val: any, ind, ind2) {
    // alert('hit')
    let e = [];
    this.Fields.forEach((fields) => {
      if (fields.name == val) {
        e.push(fields);
      }
    });

    // console.log(e,'iteeee');

    e.forEach((selectvalue) => {
      if (selectvalue.type == "group") {
        // console.log('hit',selectvalue);
        // this.FieldForm[ind].then_apply.groupFields=selectvalue.groupFields;
        // console.log(this.FieldForm,'fffff');
      }
    });
    let arr = [];
    e.forEach((x, i) => {
      // console.log(x.values,'xxxx');
      if (x.values != undefined) {
        // x.values.unshift(x.label)
        x.values.forEach((value, i) => {
          if (i == 0) {
            value["new"] = x.label;
          }
          value["checked"] = false;
          // console.log(value['label']=,'lll');
        });
        // console.log(x.values,'xxxxxssss');
        arr.push(x.values);
      }

      // console.log(arr,'rrrs');
    });
    if (arr.length > 0) {
      this.multinewarr = arr.filter((x, i) => {
        return x != undefined;
      });
      // this.multinewarr.pop()
      // console.log(this.multinewarr,'qqqqqqqqq================================');
    }
    // console.log(this.FieldForm,'incheckradioandselects');
  }
  getCheckbox() {
    // console.log(this.multinewarr,'mmm');
  }
  Constraintdata(e, ind, ind2) {
    this.FieldForm[ind].if_condition.conditions[ind2].constraint_id = parseInt(
      e.target.value
    );

    // console.log(typeof e.target.value,'e')
    // console.log(this.FieldForm,'fff')
  }
  getCircularReplacer = () => {
    const seen = new WeakSet();
    return (key, value) => {
      if (typeof value === "object" && value !== null) {
        // console.log(seen,'seen')
        if (seen.has(value)) {
          return;
        }
        seen.add(value);
      }
      return value;
    };
  };
  saveFieldRule() {
    let errors = [];
    this.FieldForm.map((obj: any, i: number) => {
      // console.log(obj,'obj')

      if (obj.rule_name === "" || obj.then_apply.value == "") {
        errors.push({ index: i, object: obj });
      }
      // validations for plain Text
      obj.if_condition.conditions.map((consObj: any, ci: number) => {
        if (
          consObj.showValueBox &&
          (consObj.value === "" || consObj.value.length == 0)
        ) {
          errors.push({ condition_index: ci, object: consObj });
        }
      });

      // if(obj.proof === 'yes' && (obj.discount_proof_title === '' || obj.discount_proof_description === '' || obj.discount_proof_files_allowed.length === 0 || obj.discount_proof_number_of_files.min === null || obj.discount_proof_number_of_files.max === null || obj.discount_proof_file_size.min === null || obj.discount_proof_file_size.max === null)) {
      //   errors.push({'proof_index': i, 'object': obj});
      // }
    });
    console.log(errors, "error");

    if (errors.length > 0) {
      this.validate = true;
    } else {
      let formdata = new FormData();
      formdata.append("form", this.form_id);
      // let data = {
      //   form:this.form_id,
      //   field_rules:this.FieldForm
      // }

      //       this.FieldForm.forEach((item,i)=>{
      //         item.if_condition.conditions.forEach((condition)=>{
      // JSON.stringify(condition)
      // console.log(condition,'conditions')
      // })
      //       })
      console.log(this.FieldForm, "ff");
      // alert('stopff')

      // this.FieldForm.forEach((item,i)=>{
      // console.log(item,'item');

      // })
      for (var key in this.FieldForm) {
        if (this.FieldForm.hasOwnProperty(key)) {
          // console.log(key + " -> " + this.FieldForm[key]);
        }
      }
      // formdata.append('field_rules',JSON.stringify(this.FieldForm,this.getCircularReplacer()))
      formdata.append("field_rules", JSON.stringify(this.FieldForm));
      this.formService.CreateFieldRules(formdata).subscribe(
        (result: any) => {
          if (result["success"]) {
            Swal.fire({
              position: "center",
              icon: "success",
              html: result["message"],
              padding: "2em",
            });
            // this.ngZone.run(() => {
            //   this.router.navigate(['/settings/discount-groups']);
            //   this.discountRuleForm = [];
            //   this.addNewRule();
            // })
          }
        },
        (error) => {
          Swal.fire({
            position: "center",
            icon: "error",
            html: error.error["message"],
            padding: "2em",
          });
        }
      );
    }
  }
}
