import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ApiClientService } from 'src/app/shared/services/api-client.service';

@Injectable({
  providedIn: 'root'
})
export class DiscountGroupService {

  destroy$: Subject<boolean> = new Subject<boolean>();

  newGroupId = new BehaviorSubject<any>('');
  groupName = new BehaviorSubject<any>('');

  discountGroupData = new BehaviorSubject<any>({});
maxDiscountPrice= new BehaviorSubject<any>(false);
  constructor(
    private apiClientService: ApiClientService
  ) { }

  createDiscountGroup(data: any) {
    return this.apiClientService.post('discount_group', data);
  }
setMaxDiscount(data: any) {    
this.maxDiscountPrice.next(data)
}
getMaxDiscount(){
  return this.maxDiscountPrice.asObservable();
}
  getDiscountGroupList() {
    return this.apiClientService.get('discount_group?rows=1&page=1');
  }
  getDiscountGroupList1(paginateData = null) {
    let endPoint = 'discount_group';
    if(paginateData !== null && Object.keys(paginateData).length > 0) {
      endPoint += '?rows=' + paginateData.itemsPerPage + '&page=' + paginateData.currentPage;
    }
    return this.apiClientService.get(endPoint).pipe(takeUntil(this.destroy$)).subscribe(result => {
      this.discountGroupData.next(result);
    }, error => {
      this.discountGroupData.next(error);
    });
  }
  getDiscountGroupList1forforms(paginateData = null) {
    let endPoint = 'form_discount_group';
    if(paginateData !== null && Object.keys(paginateData).length > 0) {
      endPoint += '?rows=' + paginateData.itemsPerPage + '&page=' + paginateData.currentPage;
    }
    return this.apiClientService.get(endPoint).pipe(takeUntil(this.destroy$)).subscribe(result => {
      this.discountGroupData.next(result);
    }, error => {
      this.discountGroupData.next(error);
    });
  }

  allDiscountGroupsData() {
    return this.discountGroupData.asObservable();
  }

  getDiscountGroupRulesList() {
    return this.apiClientService.get('discount_group_rule');
  }

  searchDiscountGroups(data: any) {
    return this.apiClientService.get('discount_group?search=' + data);
  }

  saveDiscountGroupRule(data: any) {
    return this.apiClientService.post('discount_group_rule', data);
  }

  getDiscountGroupDetails(groupRuleID: string) {
    return this.apiClientService.get('discount_group/' + groupRuleID + '/edit');
  }

  getDiscountGroupRuleDetails(groupRuleID: string) {
    return this.apiClientService.get('discount_group_rule/' + groupRuleID);
  }

  updateDiscountGroupName(data: any) { 
    return this.apiClientService.put('discount_group/' + data.group_id, data);
  }

  updateDiscountGroupRule(data: any, groupRuleID: string) {
    
    return this.apiClientService.post('discount_group_rule/' + groupRuleID, data);
  }

  setGroupName(data: any) {
    this.groupName.next(data);
  }

  getGroupName() {
    return this.groupName.asObservable();
  }

  setNewGroupID(data) {
    this.newGroupId.next(data);
  }

  getNewGroupID() {
    return this.newGroupId.asObservable();
  }

  saveFormDiscountRule(data: any) {
    return this.apiClientService.post('discount_rules', data);
  }
  UploadCSVFile(data: any) {
    return this.apiClientService.post('specific_discount_csv', data);

  }
  GetSampleCSV(){
    return this.apiClientService.get('specific_discount_sample');

  }
  DeleteDiscountGroupRule(data: any) {
    return this.apiClientService.delete('discount_group/'+data);
  }
 StatusDiscountGroupRule(data: any) {
    return this.apiClientService.post('status_discount_page',data);
  }
}
