export const environment = {
  production: true,
  // frontEndBaseUrl: 'https://dev.qf.digiturnal.com', 
  // frontEndBaseUrl: 'https://qf.digiturnal.com', 
  frontEndBaseUrl: 'https://register.tii.qa', 
  
  frontEndFormUrl: 'https://register.tii.qa/form-registration', //TII Procustion API url
  frontEndVoteFormUrl: 'https://register.tii.qa/voting-form', //TII Procustion
  apiEndpoint:'https://digitiiapi.tii.qa',//TII Procustion API url

  baseApiUrl:'https://digitiiapi.tii.qa/api',// TII Procustion API url
  // baseApiUrl:'https://dev.qfapi.digiturnal.com/api',//dev
//  baseApiUrl:'https://qfapi.digiturnal.com/api', //production

  recaptcha: {
    //  siteKey: '6LefSgkfAAAAAPxXo5FhgwnKGtRvmzsr9qE40RyK', // Kartavya local key
    //  siteKey: '6Ldc5TYfAAAAAMzGAq9WOOhY92VqAQV3QscaOGg3', // prod-key
      // siteKey:'6LdxGoEfAAAAAHCwNFlM0AVGlYvCb5QGQ6gcaZYF',      //dev key
    //  siteKey:'6LfbDvwfAAAAAFzCCQ5_NSYI7iLKeClAJJm33w_g' //shivam local

     siteKey: '6LfS4LcjAAAAAMCfMIyvrgAYbAYuo7bhtqoOo3ue'    // TII Procustion recaptcha key
 }
};
