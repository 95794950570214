<!-- <div class="container-fluid p-0">
  <div class="row">
    <div class="col-12">     
      <div class="login-card">
        <div>
          <div>
            <a class="logo"  routerLink='/'>
              <img class="img-fluid for-light" src="assets/images/logo/hbku-color-login.svg" alt="looginpage">
              <img class="img-fluid for-dark" src="assets/images/logo/logo_dark.png" alt="looginpage">
            </a>
          </div>
          <div class="login-main"> 
            <form class="theme-form">
              <h4>Sign in to account</h4>
              <p>Enter your email & password to login</p>
              <form [formGroup]="loginForm">
              <div class="form-group">
                <label class="col-form-label">Email Address</label>
                <input class="form-control" type="email" required="" formControlName="email" placeholder="app@yourmail.com">
                <div class="required_error" *ngIf="loginForm.controls.email.touched && loginForm.controls.email.errors?.required">Email is required.</div>
                <div  class="required_error" *ngIf="loginForm.controls.email.touched && loginForm.controls.email.errors?.email">Invalid Email Address.</div>
              </div>
              <div class="form-group">
                <label class="col-form-label">Password</label>
                <input class="form-control" [type]="show ? 'text' : 'password'" formControlName="password" name="login[password]" required="" placeholder="*********">
                <div  class="required_error" *ngIf="loginForm.controls.password.touched && loginForm.controls.password.errors?.required">Password is required.</div> 
                <div  class="required_error" *ngIf="loginForm.controls.password.touched && loginForm.controls.password.errors?.pattern"> Password must contain Minimum eight characters, at least one uppercase 
                  letter, one lowercase letter, one number and one special character</div>                 
                <div class="show-hide" (click)="showPassword()" *ngIf="!show"><span class="show"></span></div>
                <div class="show-hide" (click)="showPassword()" *ngIf="show"><span class="Hide"></span></div>
              </div>
              <div class="form-group mb-0">
                <div class="checkbox p-0">
                  <input id="checkbox1" (change)="remcheck()" type="checkbox" formControlName="rememberMe" [(ngModel)]="rememberChecked">
                  <label class="text-muted" for="checkbox1" >Keep me Logged In</label>
                </div>
                <a [routerLink]="'/authentication/forgot-password'" class="link">Forgot password?</a>
               
                <div class="form-group">
                    
                  <re-captcha  formControlName="recaptcha" id="recaptcha" name="recaptcha"
                   [class.is-invalid]="loginForm.controls['recaptcha'].invalid && (loginForm.controls['recaptcha'].dirty || loginForm.controls['recaptcha'].touched)"
                    >
                  </re-captcha>
                  
                  <div class="required_error" *ngIf="loginForm.controls.recaptcha.touched && loginForm.controls.recaptcha.errors?.required">Please Tick Captcha</div>
                </div>
                <button class="btn btn-primary btn-block" (click)="login()" type="submit">Sign in</button>
              </div>
            </form>
              <h6 class="text-muted mt-4 or" >Or Sign in with</h6>
              <div class="social mt-4">
                <div class="btn-showcase">
                  <a class="btn btn-light" href="https://www.linkedin.com/login" target="_blank">
                    <app-feather-icons [icon]="'linkedin'" class="txt-linkedin"></app-feather-icons> LinkedIn 
                  </a>
                  <a class="btn btn-light" href="https://twitter.com/login?lang=en" target="_blank">
                   <app-feather-icons [icon]="'twitter'" class="txt-twitter"></app-feather-icons>twitter
                  </a>
                  <a class="btn btn-light" href="https://www.facebook.com/" target="_blank">
                    <app-feather-icons [icon]="'facebook'" class="txt-fb"></app-feather-icons>facebook
                  </a>
                </div>
              </div>
              <p class="mt-4 mb-0">Don't have account?
                <a [routerLink]="'/authentication/register/simple'" class="ml-2">Create Account</a>
              </p>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div> -->



<div class="container-fluid">
  <div class="row">
    <div class="col-xl-7 order-1 d-xl-flex d-none align-items-center justify-content-center" [ngStyle]="{'background-image': 'url(assets/images/login/login_banner.jpeg)',
                                              'background-size': 'cover',
                                              'background-position': 'center center'}">
          <div>
            <img class="img-fluid" src="assets/images/logo/hbku_white.svg" alt="QF Dynamic Forms" style="width: 100px;">
          </div>
    </div>
    <div class="col-xl-5 p-0">
      <div class="login-card">
        <div>
          <!-- <div>
            <a class="logo text-left" routerLink='/'>
              <img class="img-fluid for-light" src="assets/images/logo/hbku_blue.svg" alt="looginpage"> -->
              <!-- <img class="img-fluid for-dark" src="assets/images/logo/logo_dark.png" alt="looginpage"> -->
              <!-- QF Dynamic
            </a>
          </div> -->
          <div class="login-main"> 
            <form class="theme-form needs-validation" [class.was-validated]="!loginForm.valid" novalidate="" [formGroup]="loginForm">
              <h4>Sign in to account</h4>
              <p>Enter your email address & password to login</p>
              <div class="form-group">
                <label class="col-form-label">Email Address</label>
                <input class="form-control text-dark" type="email" required="" (blur)="ValEmail($event)" (input)="emailVal($event)" formControlName="email" placeholder="app@yourmail.com">
                <div class="required_error" *ngIf="emaildupmess!=''">{{emaildupmess}}</div>
                <div class="required_error" *ngIf="loginForm.controls.email.touched && loginForm.controls.email.errors?.required">Email Address is required.</div>
                <!-- <div  class="required_error" *ngIf="loginForm.controls.email.touched && loginForm.controls.email.errors?.email">Invalid Email Address.</div> -->
                <div class="required_error">{{echeck}}</div>
              </div>
             
              <div class="form-group">
                <label class="col-form-label">Password</label>
                <input class="form-control  text-dark" [type]="show ? 'text' : 'password'" formControlName="password" name="login[password]" required="" placeholder="*********">
                <div  class="required_error" *ngIf="loginForm.controls.password.touched && loginForm.controls.password.errors?.required">Password is required.</div> 
                <div  class="required_error" *ngIf="loginForm.controls.password.touched && loginForm.controls.password.errors?.pattern"> Password must contain Minimum five characters, at least one uppercase 
                  letter, one lowercase letter, one number and one special character</div>     
              
                <div class="show-hide" (click)="showPassword()" >    <i class="fa"[ngClass]="{'fa-eye-slash': !show,'fa-eye': show}"></i>
                </div>
                <!-- <div class="show-hide" (click)="showPassword()" *ngIf="show"><span class="Hide"></span></div> -->
              </div>
              <div class="form-group mb-0">
                <div class="checkbox p-0">
                  <input id="checkbox1" (change)="remcheck()" type="checkbox" formControlName="rememberMe" [(ngModel)]="rememberChecked">
                  <label class="text-muted" for="checkbox1" >Remember me</label>
                </div>
                <div (click)="withAjax(content2)" class="link">Forgot password?</div>
               
                <div class="form-group recaptchaResponsive">
                  <re-captcha  formControlName="recaptcha" id="recaptcha" name="recaptcha" data-size="compact"
                   [class.is-invalid]="loginForm.controls['recaptcha'].invalid && (loginForm.controls['recaptcha'].dirty || loginForm.controls['recaptcha'].touched)"
                    >
                  </re-captcha>
                  
                  <div class="required_error" *ngIf="loginForm.controls.recaptcha.touched && loginForm.controls.recaptcha.errors?.required">Please Tick Captcha</div>
                </div>
                <button class="btn btn-primary btn-block" (click)="login(content)" type="submit">Log in</button>
              </div>
    
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>



<ng-template #content let-modal>
  <div class="modal-header">
    <h5 class="modal-title" id="exampleModalLabel">Reset Password</h5>
    <button class="close" type="button" data-dismiss="modal" aria-label="Close" data-original-title="" title=""
      (click)="modal.dismiss('Cross click')"><span aria-hidden="true">×</span></button>
  </div>
  <div class="modal-body">
    
    <form class="theme-form needs-validation" [class.was-validated]="!resetForm.valid" novalidate="" [formGroup]="resetForm">
      <div class="form-group">
        <label class="col-form-label">New Password</label>
        <input class="form-control  text-dark" [type]="rshow ? 'text' : 'password'" formControlName="password" name="login[password]" required="" placeholder="*********">
        <div  class="required_error" *ngIf="resetForm.controls.password.touched && resetForm.controls.password.errors?.required">Password is required.</div> 
        <div  class="required_error" *ngIf="resetForm.controls.password.touched && resetForm.controls.password.errors?.pattern"> Password must contain Minimum eight characters, at least one uppercase 
          letter, one lowercase letter, one number and one special character</div>                 
        <div class="show-hide" (click)="showrPassword()" *ngIf="!rshow"><span class="show"></span></div>
        <div class="show-hide" (click)="showrPassword()" *ngIf="rshow"><span class="Hide"></span></div>
      </div>
      <div class="form-group">
          <label class="col-form-label">Confirm Password</label>
          <input class="form-control  text-dark" [type]="cshow ? 'text' : 'password'" formControlName="cpassword" name="login[password]" required="" placeholder="*********">
          <div  class="required_error" *ngIf="resetForm.controls.cpassword.touched && resetForm.controls.cpassword.errors?.required">Confirm Password is required.</div> 
          <div class="required_error" *ngIf="resetForm.controls.cpassword.errors?.MustMatch">Passwords do not match</div>
          <div class="show-hide" (click)="showCpassword()" *ngIf="!cshow"><span class="show"></span></div>
          <div class="show-hide" (click)="showCpassword()" *ngIf="cshow"><span class="Hide"></span></div>
        </div>
      <div class="form-group mb-0">
        <button class="btn btn-primary btn-block" (click)="resetpass()" type="submit">Reset</button>
      </div>
    </form>
  </div>
</ng-template>

<!-- <button class="btn btn-danger btn-block" (click)="openwindowClass(content)" type="button">Open Modal</button> -->
<ng-template #content2 let-modal>
  <div class="modal-header">
    <h5 class="modal-title" id="exampleModalLabel">Forgot Password</h5>
    <button class="close" type="button" data-dismiss="modal" aria-label="Close" data-original-title="" title=""
      (click)="modal.dismiss('Cross click')"><span aria-hidden="true">×</span></button>
  </div>  
  <div class="modal-body">
   
    <form class="theme-form needs-validation" [class.was-validated]="!ForgotForm.valid" novalidate="" [formGroup]="ForgotForm">
      <div class="form-group">
        <label class="col-form-label">Email Address</label>
        <input class="form-control  text-dark" type="email" formControlName="forgotemail" name="login[password]" required="" placeholder="app@yourmail.com" (input)="emailForgotVal($event)">
        <div class="required_error" *ngIf="ForgotForm.controls.forgotemail.touched && ForgotForm.controls.forgotemail.errors?.required">Email Address is required.</div>
        <div class="required_error">{{eforpass}}</div>

        <!-- <div  class="required_error" *ngIf="ForgotForm.controls.forgotemail.touched && ForgotForm.controls.forgotemail.errors?.email">Invalid Email Address.</div> -->
        <!-- <div  class="required_error" *ngIf="resetForm.controls.password.touched && resetForm.controls.password.errors?.required">Password is required.</div>  -->
        <!-- <div  class="required_error" *ngIf="resetForm.controls.password.touched && resetForm.controls.password.errors?.pattern"> Password must contain Minimum eight characters, at least one uppercase  -->
          <!-- letter, one lowercase letter, one number and one special character</div>                  -->
      </div>
   
      <div class="form-group mb-0">
        <button class="btn btn-primary btn-block" (click)="SubmitforgotEmail()" type="submit">Submit</button>
      </div>
    </form>
  </div>
</ng-template>
<div class="customLoader" *ngIf="LoaderClass">
  <div class="col-md-3 loaderWrap">
      <div class="loader-box">
      <div class="loader-19"></div>
      </div>
  </div>
</div>