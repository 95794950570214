<div class="container-fluid">
    <div class="row">
      <div class="col-xl-7 order-1 d-flex align-items-center justify-content-center" [ngStyle]="{'background-image': 'url(assets/images/login/login_banner.jpeg)','background-size': 'cover','background-position': 'center center'}">
        <div>
            <img class="img-fluid" src="assets/images/logo/hbku_white.svg" alt="QF Dynamic Forms" style="width: 100px;">
        </div>
      </div>
      <div class="col-xl-5 p-0">
        <div class="login-card">
          <div>
            <div class="login-main"> 
              <form class="theme-form needs-validation" [class.was-validated]="!resetForm.valid" novalidate="" [formGroup]="resetForm">
                <h4>Reset Password</h4>
                <p>Enter your new password to get into your account</p>
               
                <div class="form-group">
                  <label class="col-form-label">New Password</label>
                  <input class="form-control  text-dark" [type]="show ? 'text' : 'password'" formControlName="password" name="login[password]" required="" placeholder="*********">
                  <div  class="required_error" *ngIf="resetForm.controls.password.touched && resetForm.controls.password.errors?.required">Password is required.</div> 
                  <div  class="required_error" *ngIf="resetForm.controls.password.touched && resetForm.controls.password.errors?.pattern"> Password must contain Minimum five characters, at least one uppercase 
                    letter, one lowercase letter, one number and one special character</div>                 
                  <div class="show-hide" (click)="showPassword()" *ngIf="!show"><span class="show"></span></div>
                  <div class="show-hide" (click)="showPassword()" *ngIf="show"><span class="Hide"></span></div>
                </div>
                <div class="form-group">
                    <label class="col-form-label">Confirm Password</label>
                    <input class="form-control  text-dark" [type]="cshow ? 'text' : 'password'" formControlName="cpassword" name="login[password]" required="" placeholder="*********">
                    <div  class="required_error" *ngIf="resetForm.controls.cpassword.touched && resetForm.controls.cpassword.errors?.required">Confirm Password is required.</div> 
                    <div class="required_error" *ngIf="resetForm.controls.cpassword.errors?.MustMatch">Passwords do not match</div>
                    <div class="show-hide" (click)="showCpassword()" *ngIf="!cshow"><span class="show"></span></div>
                    <div class="show-hide" (click)="showCpassword()" *ngIf="cshow"><span class="Hide"></span></div>
                  </div>
                <div class="form-group mb-0">
                  <button class="btn btn-primary btn-block" (click)="resetpass()" type="submit">Reset</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>