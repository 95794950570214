import { Component, OnInit, Inject, ElementRef, HostListener } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { NavService } from '../../services/nav.service';
import { LayoutService } from '../../services/layout.service';
import { CookieService } from 'ngx-cookie-service';
// import { CommonService } from '../../services/common.service';
import { CommonService } from 'src/app/shared/services/common.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { AuthService } from '../../services/firebase/auth.service';
import * as moment from 'moment';
import { ApiClientService } from '../../services/api-client.service';
import { UserProfileService } from 'src/app/components/user-profile/user-profile.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  // host: {
  //   '(document:click)': 'onClick($event)',
  // },
})
export class HeaderComponent implements OnInit {

  public elem: any;
  public dark: boolean = this.layout.config.settings.layout_version == 'dark-only' ? true : false;

  constructor(public layout: LayoutService,
    public navServices: NavService, private cookiService:CookieService,private common:CommonService,private auth:AuthService,private _eref: ElementRef,private profileService:UserProfileService,
    private router :Router,
    @Inject(DOCUMENT) private document: any
  ) {
  }
  data:any
  destroy$: Subject<boolean> = new Subject<boolean>();
  result:any
  newArr:any
  name:any
  e:any
  p:any
  date:any
  showdrop='false'
  intervalId:any
  // onClick(event) {
  //   console.log('event',event)
  //   if (!this._eref.nativeElement.contains(event.target)) // or some similar check
  //    this.showdrop='false'
  //  } 
  // @HostListener('document:mousedown', ['$event'])
  // onGlobalClick(event): void {
  //    if (!this._eref.nativeElement.contains(event.target)) {
  //       // clicked outside => close dropdown list
  //    this.showdrop = 'false'
  //    }
  // } 
  ngOnInit() {

    this.intervalId = setInterval(() => {
      this.date = new Date();
      // this.e = this.date.toLocaleString('en-US', { timeZone: 'Asia/Qatar' });
      this.p = moment(this.date).format('h:mm:ss A')
    }, 1000);

    
    this.name= this.cookiService.get('user_name')
     this.data = this.cookiService.get('user_role_id')

    this.elem = document.documentElement;
    // this.getlist()
this.fetchProfile()
// this.common.getprofilehit().subscribe((res)=>{
//   if(res !=''){
//     this.fetchProfile
//   }
// })
// this.common.getprofilename().subscribe((res=>{
//   console.log('HITTTTTTTTTTTTTTTTTTTTTTTT',res)
// this.name = res.data.first_name+res.data.last_name
// }))
  }
  fetchProfile() {
    // alert('1')
      this.profileService.fetchProfile().pipe(takeUntil(this.destroy$)).subscribe(result => {
          
          this.result=result;
          this.name=this.result.data.first_name + ' ' + this.result.data.last_name
          // this.result = this.result.data
          // this.user_fName = this.result.data.first_name
          // this.user_lName = this.result.data.last_name
          // this.address_val=this.result.data.address
          // this.user_role = this.result.data.roles
          // this.email_val=this.result.data.email
          // this.setUserEditForm()
          // if (this.result.status && this.result.status == 'error') {
          //   this.toastr.error(this.result.message)
          // }
          
          
          // this.user_gender = this.result.data.gender
        
        }, error => {
          // this.error(error);
          
          
            
        });
    }
    redirect(){
      this.router.navigate(['user-profile/edit-profile']);
    }
    logout() {
      this.auth.SignOut();
    }
  boolchange(type){
    if(type=='false'){
      this.showdrop= "true"

    }else if (type =='true'){
      this.showdrop= "false"

    }
    // if(this.showdrop='true'){
    // this.showdrop= "false"

    // }
  }
//  getlist(){
//   //  alert('stop')
//     // this.common.getPermissionAccess(6)

//     this.common.permarr.subscribe((res: any) => {
//       
//       this.newArr = res;
//      
//     if (this.newArr.length ==0){
//       this.auth.getPermissionAccess(this.cookiService.get('user_role_id'))
//     }

//     })

   
//   }
  sidebarToggle() {
    this.navServices.collapseSidebar = !this.navServices.collapseSidebar;
    this.navServices.megaMenu  = false;
    this.navServices.levelMenu = false;
  }

  layoutToggle() {
    this.dark = !this.dark;
    this.layout.config.settings.layout_version = this.dark ? 'dark-only' : 'light';
  }

  searchToggle() {
    this.navServices.search = true;
  }

  languageToggle() {
    this.navServices.language = !this.navServices.language;
  }

  toggleFullScreen() {
    this.showdrop='false'
    this.navServices.fullScreen = !this.navServices.fullScreen;
    if (this.navServices.fullScreen) {
      if (this.elem.requestFullscreen) {
        this.elem.requestFullscreen();
      } else if (this.elem.mozRequestFullScreen) {
        /* Firefox */
        this.elem.mozRequestFullScreen();
      } else if (this.elem.webkitRequestFullscreen) {
        /* Chrome, Safari and Opera */
        this.elem.webkitRequestFullscreen();
      } else if (this.elem.msRequestFullscreen) {
        /* IE/Edge */
        this.elem.msRequestFullscreen();
      }
    } else {
      if (!this.document.exitFullscreen) {
        this.document.exitFullscreen();
      } else if (this.document.mozCancelFullScreen) {
        /* Firefox */
        this.document.mozCancelFullScreen();
      } else if (this.document.webkitExitFullscreen) {
        /* Chrome, Safari and Opera */
        this.document.webkitExitFullscreen();
      } else if (this.document.msExitFullscreen) {
        /* IE/Edge */
        this.document.msExitFullscreen();
      }
    }
  }


}
