<div class="card shadow-none mb-0">
  <div class="card-body p-4">
    <div class="row">
      <div class="col-sm-12">
        <form
          class="needs-validation"
          novalidate
          [ngClass]="{ 'was-validated': validate }"
        >
          <!-- <div class="card"> -->
          <!-- {{discountRuleForm | json}} -->
          <!-- <div class="card-body px-4 py-2"> -->
          <ng-container
            *ngFor="let ruleitem of discountRuleForm; let ruleindex = index"
          >
            <div class="rulesDup">
              <input
                type="text"
                class="form-control"
                placeholder="Rule Name"
                required=""
                [(ngModel)]="ruleitem.rule_name"
                [ngModelOptions]="{ standalone: true }"
              />
              <div class="fieldRuleConditions">
                <div class="ruleCont" style="margin: 33px 0 0 0">
                  <div class="contDivInner">
                    <span class="newRuleIfTxt">If</span>
                    <div class="if-wrapper ifContainer fieldRuleIfCont">
                      <div class="form-group">
                        <select
                          class="custom-select form-control mr-3"
                          [(ngModel)]="ruleitem.if_condition.allow"
                          required=""
                          [ngModelOptions]="{ standalone: true }"
                        >
                          <option
                            value="any"
                            [selected]="ruleitem.if_condition.allow === 'any'"
                          >
                            Any
                          </option>
                          <!-- <option
                            value="all"
                            [selected]="ruleitem.if_condition.allow === 'all'"
                          >
                            All
                          </option> -->
                        </select>
                        <label for="">of the following conditions are met</label>
                      </div>
                      <ng-container
                        *ngFor="
                          let conditionItem of ruleitem.if_condition.conditions;
                          let conindex = index
                        "
                      >
                        <div class="fieldRow my-2 py-2">
                          <div class="col-sm-12">
                            <div class="d-flex align-items-center py-1">
                                  <ng-container *ngIf="discountFields && discountFields.length > 0; else baseInfoTemplate">
                                  
                                  </ng-container>
                                  <ng-template #baseInfoTemplate>
                                
                                    <ng-container >
                                        <div class="form-group mr-3 mb-0">
                                            <label
                                              for="allowedemails"
                                              *ngIf="showdomplace == true"
                                              >Allowed Emails</label
                                            >
                                            <div
                                              class="tagsbox form-control d-flex align-items-center"
                                              ngClass="{{email_err ? 'border-alert' : ''}}"
                                            >
                                              <ul class="domain-tags-list">
                                                <li
                                                  class="action_btn btn-primary"
                                                  *ngFor="
                                                    let item of allowedEmailInputData;
                                                    let i = index
                                                  "
                                                >
                                                  {{ item }}
                                                  <span
                                                    (click)="
                                                    updateAllowedEmails(
                                                        i,
                                                        formFieldItem
                                                      )
                                                    "
                                                    ><i
                                                      class="icofont-close icon-close"
                                                    ></i
                                                  ></span>
                                                </li>
                                                <input
                                                  #alloweminput
                                              class="form-control"
                                                  id="allowedemails"
                                                  type="text"
                                                  placeholder="Allow Emails"
                                                  [(ngModel)]="allowedEmailStr"
                                                  [ngModelOptions]="{
                                                    standalone: true
                                                  }"
                                                  (keyup)="
                                                    updateEmailListing(
                                                      $event,
                                                      formFieldItem,
                                                      'allowed',conindex
                                                    )
                                                  "
                                                  required
  
                                                />
                                              </ul>
                                            </div>
                                        <small class="text-secondary">- Once filled, press enter</small>

                                          </div>
                                
                                  
                                          <!-- {{allowedDomainStr}} -->
                                      <div class="form-group mr-3 mb-0">
                                          <label
                                            for="alloweddomains"
                                            *ngIf="showdomplace == true"
                                            >Allowed Domains</label
                                          >
                                          <div
                                            class="tagsbox form-control d-flex align-items-center"
                                            ngClass="{{domains_err  ? 'border-alert' : ''}}"

                                          >
                                            <ul class="domain-tags-list">
                                              <li
                                                class="action_btn btn-primary"
                                                *ngFor="
                                                  let item of allowedDomainsInputData;
                                                  let i = index
                                                "
                                              >
                                                {{ item }}
                                                <span
                                                  (click)="
                                                  updateAllowedDomains(
                                                      i,
                                                      formFieldItem
                                                    )
                                                  "
                                                  ><i
                                                    class="icofont-close icon-close"
                                                  ></i
                                                ></span>
                                              </li>
                                              <input
                                                #allowdominput
                                                id="alloweddomains"
                                                class="form-control"
                                                type="text"
                                                placeholder="Allow Domains"
                                                [(ngModel)]="allowedDomainStr"
                                                [ngModelOptions]="{
                                                  standalone: true
                                                }"
                                                (keyup)="
                                                updateDomainsListing(
                                                    $event,
                                                    formFieldItem,
                                                    'allowed',conindex
                                                  )
                                                "
                                                required
  
                                              />
                                            </ul>
                                          </div>
                                        <small class="text-secondary">- Once filled, press enter</small>

                                        </div>
                        <p class="required_error">{{domain_err}} </p>
                                        
                                  </ng-container>
                              
                              
                                  </ng-template>
                                <div class="clearBoth"></div>
                            
                              <div
                                class="form-group mr-2 mb-0"
                                *ngIf="conditionItem.showValueBox"
                              >
                                <!-- <div class="condition-value-input">
                                    <input
                                      type="text"
                                      class="form-control"
                                      placeholder="Conditions Value"
                                      [(ngModel)]="conditionItem.value"
                                      required=""
                                      [ngModelOptions]="{ standalone: true }"
                                    />
                                  </div> -->
                              </div>
                              <!-- <div class="mr-3 mb-0">
                                <button
                                  type="button"
                                  class="customAddminus clone"
                                  (click)="addNewRow(ruleindex)"
                                >
                                  <i class="icon-plus"></i>
                                </button>
                                <button
                                  type="button"
                                  class="customAddminus delete"
                                  (click)="removeRow(ruleindex, conindex)"
                                >
                                  <i class="icon-minus"></i>
                                </button>
                              </div> -->
                            </div>
                          </div>
                        </div>
                        <p class="required_error">{{email_error}} </p>
                      </ng-container>
                    </div>
                    <span class="newRuleIfTxt newRuleThenTxt">Then</span>
                  </div>
                  <div class="actCondition">
                    <span>Perform the following actions</span>
                  </div>
                  <div class="then-wrapper ruleActionWrapper">
                    <div class="ruleAction">
                      <div class="fieldRow my-2 py-2 px-4 d-flex align-items-center">
                        <div class="form-group mr-3 mb-0">
                          <label for="">Discount Type</label>
                          <div class="rule-action-list">
                            <select
                              class="custom-select form-control text-capitalize"
                              [(ngModel)]="ruleitem.then_apply.type"
                              required=""
                              [ngModelOptions]="{ standalone: true }"
                            >
                              <option value="">Select Discount Type</option>
                              <ng-container *ngFor="let item of thenApply">
                                <option
                                  [value]="item"
                                  [selected]="item === ruleitem.then_apply.type"
                                >
                                  {{ item }}
                                </option>
                              </ng-container>
                            </select>
                          </div>
                        </div>
                        <div class="form-group mr-3 mb-0">
                          <label for="">Discount Value</label>
                          <div
                            class="action-value-input"
                          
                          >
                            <input
                              type="number"
                              class="form-control"
                              [(ngModel)]="ruleitem.then_apply.value"
                              required=""
                              [ngModelOptions]="{ standalone: true }"
                              (input)="MaxDiscountLimit($event, ruleindex)"
                            />
                            <!-- oninput="if(this.value>100){ 
                                                                  this.value='' ;alert('asdsa')};"  -->
                          </div>
                        
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="form-group">
                    <label for="">Discount Title</label>
                    <input
                      class="form-control"
                      type="text"
                      placeholder="Discount Title"
                      required=""
                      [(ngModel)]="ruleitem.discount_proof_title"
                      [ngModelOptions]="{ standalone: true }"
                    />
                  </div>
                  <div class="form-group">
                    <label for="">Discount Description</label>
                    <textarea
                      class="form-control"
                      rows="3"
                      placeholder="Discount Description"
                      required=""
                      [(ngModel)]="ruleitem.discount_proof_description"
                      [ngModelOptions]="{ standalone: true }"
                      >{{ ruleitem.discount_proof_description }}</textarea
                    >
                  </div>
                </div>
              </div>
              <!-- <div class="RuleNameCreate py-2 mx-4 mt-0">
                  <label class="important">Required discount proof?</label>
                  <div
                    [ngClass]="
                      ruleitem.proof === 'yes' ? '' : 'd-flex justify-content-between'
                    "
                  >
                    <!-- <div class="m-checkbox-inline mt-3">
                      <label
                        ><input
                          id="yes"
                          class="radio_animated"
                          type="radio"
                          placeholder="Yes"
                          [name]="'discountProof_' + ruleindex"
                          value="yes"
                          (change)="
                            discountProofRequire($event.target.value, ruleindex)
                          "
                          [checked]="ruleitem.proof == 'yes'"
                        />Yes</label
                      >
                      <label
                        ><input
                          id="no"
                          class="radio_animated"
                          type="radio"
                          placeholder="No"
                          [name]="'discountProof_' + ruleindex"
                          value="no"
                          (change)="
                            discountProofRequire($event.target.value, ruleindex)
                          "
                          [checked]="ruleitem.proof == 'no'"
                        />No</label
                      >
                    </div> -->
              <!-- <ng-container *ngIf="ruleitem.proof === 'yes'">
                      <div class="my-4">
                        <div class="form-group">
                          <label for="">Discount Proof Title</label>
                          <input
                            class="form-control"
                            type="text"
                            placeholder="Discount Title"
                            required=""
                            [(ngModel)]="ruleitem.discount_proof_title"
                            [ngModelOptions]="{ standalone: true }"
                          />
                        </div>
                        <div class="form-group">
                          <label for="">Discount Proof Description</label>
                          <textarea
                            class="form-control"
                            rows="3"
                            placeholder="Discount Description"
                            required=""
                            [(ngModel)]="ruleitem.discount_proof_description"
                            [ngModelOptions]="{ standalone: true }"
                            >{{ ruleitem.discount_proof_description }}</textarea
                          >
                        </div>
                        <div class="form-group">
                          <div class="form-check m-checkbox-inline">
                            <label
                              [ngClass]="
                                validate &&
                                ruleitem.discount_proof_files_allowed.length === 0
                                  ? 'text-danger'
                                  : ''
                              "
                              >Allowed Files:</label
                            >
                            <label for="pdfFile" class="form-check-label">
                              <input
                                id="pdfFile"
                                class="checkbox_animated form-check-input"
                                type="checkbox"
                                placeholder=".pdf"
                                value="pdf"
                                (change)="allowedFiles($event, ruleindex)"
                                [checked]="
                                  ruleitem.discount_proof_files_allowed != null
                                    ? ruleitem.discount_proof_files_allowed.includes(
                                        'pdf'
                                      )
                                    : ''
                                "
                              />
      
                              .pdf
                            </label>
                            <label for="xlxsFile" class="form-check-label">
                              <input
                                id="xlxsFile"
                                class="checkbox_animated form-check-input"
                                type="checkbox"
                                placeholder=".xlxs"
                                value="xlsx"
                                (change)="allowedFiles($event, ruleindex)"
                                [checked]="
                                  ruleitem.discount_proof_files_allowed != null
                                    ? ruleitem.discount_proof_files_allowed.includes(
                                        'xlxs'
                                      )
                                    : ''
                                "
                              />
                              .xlxs
                            </label>
                            <label for="docFile" class="form-check-label">
                              <input
                                id="docFile"
                                class="checkbox_animated form-check-input"
                                type="checkbox"
                                placeholder=".doc"
                                value="doc"
                                (change)="allowedFiles($event, ruleindex)"
                                [checked]="
                                  ruleitem.discount_proof_files_allowed != null
                                    ? ruleitem.discount_proof_files_allowed.includes(
                                        'doc'
                                      )
                                    : ''
                                "
                              />
                              .doc
                            </label>
                            <label for="txtFile" class="form-check-label">
                              <input
                                id="txtFile"
                                class="checkbox_animated form-check-input"
                                type="checkbox"
                                placeholder=".png"
                                value="png"
                                (change)="allowedFiles($event, ruleindex)"
                                [checked]="
                                  ruleitem.discount_proof_files_allowed != null
                                    ? ruleitem.discount_proof_files_allowed.includes(
                                        'png'
                                      )
                                    : ''
                                "
                              />
                              .png
                            </label>
                          </div>
                          <span
                            *ngIf="
                              validate &&
                              ruleitem.discount_proof_files_allowed.length === 0
                            "
                            class="text-danger"
                            >Please select a file tipe</span
                          >
                        </div>
                        <div class="form-row form-group">
                          <div class="col-sm-12">
                            <label for="">Number of files to be uploaded</label>
                          </div>
                          <div class="col-md-6 col-lg-12 col-xl-6">
                            <input
                              type="number"
                              placeholder="Min"
                              class="form-control digits"
                              [(ngModel)]="
                                ruleitem.discount_proof_number_of_files.min
                              "
                              [ngModelOptions]="{ standalone: true }"
                              required=""
                            />
                          </div>
                          <div class="col-md-6 col-lg-12 col-xl-6">
                            <input
                              type="number"
                              placeholder="Max"
                              class="form-control digits"
                              [(ngModel)]="
                                ruleitem.discount_proof_number_of_files.max
                              "
                              [ngModelOptions]="{ standalone: true }"
                              required=""
                            />
                          </div>
                        </div>
                        <div class="form-row form-group">
                          <div class="col-sm-12">
                            <label for="">File size</label>
                          </div>
                          <div class="col-md-6 col-lg-12 col-xl-6">
                            <input
                              type="number"
                              placeholder="Minimum size in MB"
                              class="form-control digits"
                              [(ngModel)]="ruleitem.discount_proof_file_size.min"
                              [ngModelOptions]="{ standalone: true }"
                              required=""
                            />
                          </div>
                          <div class="col-md-6 col-lg-12 col-xl-6">
                            <input
                              type="number"
                              placeholder="Maximum size in MB"
                              class="form-control digits"
                              [(ngModel)]="ruleitem.discount_proof_file_size.max"
                              [ngModelOptions]="{ standalone: true }"
                              required=""
                            />
                          </div>
                        </div>
                      </div>
                    </ng-container> -->
              <!-- <div
                      class="text-right"
                      [ngClass]="ruleitem.proof === 'yes' ? '' : 'mb-3'"
                      *ngIf="!ruleEdit"
                    >
                      <button
                        type="button"
                        class="btn btn-danger mr-2"
                        (click)="removeRule(ruleindex,ruleitem.id)"
                      >
                        Delete Rule
                      </button>
                      <button
                        type="button"Al
                        class="btn btn-primary mr-2"
                        (click)="addNewRule()"
                      >
                        Add Rule
                      </button>
                    </div> -->
              <!-- </div>
                </div>  -->
            </div>
            <div
            class="text-right"
          
            >
            <!-- *ngIf="!ruleEdit" -->
            <button
            
              type="button"
              class="btn btn-danger mr-2"
               (click)="removeRule(ruleindex,ruleitem.id)"
              >
              Delete Rule
            </button>
     
          </div>
          </ng-container>
          <!-- </div> -->
          <div class="d-block pb-4" *ngIf="!hidesave">
            <button type="button" class="btn btn-primary mr-2" (click)="saveRule()">
              <!-- {{ ruleEdit ? "Update Rule" : "Save Rules" }} -->
              Save Rules
            </button>
          </div>
          <!-- </div> -->
        </form>
      </div>
    </div>
    <div class="row mt-4">
      <div class="col-sm-12">
        <a download="filename" target="_blank" href="https://dev.qfapi.digiturnal.com/uploaded/sample_specific_discount.csv">
          Click here to download Sample CSV
        </a>
        <div class="files-list">
          <div
            class="single-file"
            *ngFor="let file of files; let i = index"
          >
            <div class="d-flex justify-content-between">
              <h4 class="name">
                {{ file?.name }}
              </h4>
              <!-- <p class="size">
                {{ formatBytes(file?.size) }}
              </p> -->
            </div>
            <div class="info">
              <app-progress
                [progress]="file?.progress"
              ></app-progress>
              <div class="delete" (click)="deleteFile(i)">
                <i class="fa fa-times"></i>
              </div>
            </div>
          </div>
        </div>
        <div
        
          class="drag_dropContainer"
          appDnd
          (fileDropped)="onFileDropped($event)"
        >
          <input
            type="file"
            #fileDropRef
            id="fileDropRef"
            multiple
            name="upimage"
            (change)="
            fileBrowseHandler($any($event).target.files)
            "
          />
        
          <i class="fa fa-cloud-upload"></i>
        
          <h3>
            Drag and Drop your QID here
            <label for="fileDropRef">or click to browse</label>
          </h3>
          <!-- <div class="required_error">{{ pdf_error_dis }}</div> -->
        </div>
      </div>
    </div>
  </div>
</div>