// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    production: false,
    // frontEndBaseUrl: 'https://dev.qf.digiturnal.com', 
    // frontEndBaseUrl: 'https://qf.digiturnal.com', 
    frontEndBaseUrl: 'https://register.tii.qa', 

    apiEndpoint:'https://digitiiapi.tii.qa',//TII Procustion API url
    baseApiUrl:'https://digitiiapi.tii.qa/api',// TII Procustion API url
    frontEndFormUrl: 'https://register.tii.qa/form-registration', //TII Procustion API url
    frontEndVoteFormUrl: 'https://register.tii.qa/voting-form', //TII Procustion
    // apiEndpoint:'https://dev.qfapi.digiturnal.com',//dev
  // baseApiUrl:'https://dev.qfapi.digiturnal.com/api',//dev
  // frontEndFormUrl: 'https://dev.qf.digiturnal.com/form-registration', //dev
  // apiEndpoint:'https://qfapi.digiturnal.com',//prod
  // baseApiUrl:'https://qfapi.digiturnal.com/api',//prod
  // frontEndFormUrl: 'https://qf.digiturnal.com/form-registration', //prod
  recaptcha: {
      // siteKey: '6LefSgkfAAAAAPxXo5FhgwnKGtRvmzsr9qE40RyK', // local key
      //  siteKey: '6Ldc5TYfAAAAAMzGAq9WOOhY92VqAQV3QscaOGg3', // prod-key
      //  siteKey:'6LdxGoEfAAAAAHCwNFlM0AVGlYvCb5QGQ6gcaZYF'      //dev key
  //  siteKey:'6LfbDvwfAAAAAFzCCQ5_NSYI7iLKeClAJJm33w_g' //shivam local
   siteKey: '6LfS4LcjAAAAAMCfMIyvrgAYbAYuo7bhtqoOo3ue'    // TII Procustion recaptcha key
  }



  // },
};

