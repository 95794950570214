import { Injectable, OnDestroy } from '@angular/core';
import { Subject, BehaviorSubject, fromEvent } from 'rxjs';
import { takeUntil, debounceTime } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiClientService } from './api-client.service';

// Menu
export interface Menu {
	headTitle1?: string,
	headTitle2?: string,
	path?: string;
	title?: string;
	icon?: string;
	type?: string;
	badgeType?: string;
	badgeValue?: string;
	active?: boolean;
	bookmark?: boolean;
	children?: Menu[];
	show?: string;
}
export interface MenuItems {
	state?: string;
	name: string;
	type: string;
	icon: string;
	class: string;
	children?: MenuItems[];
	isOpen?: boolean;
	isFirst?: boolean;
}
@Injectable({
	providedIn: 'root'
})

export class NavService implements OnDestroy {

	private unsubscriber: Subject<any> = new Subject();
	public screenWidth: BehaviorSubject<number> = new BehaviorSubject(window.innerWidth);

	// Search Box
	public search: boolean = false;

	// Language
	public language: boolean = false;

	// Mega Menu
	public megaMenu: boolean = false;
	public levelMenu: boolean = false;
	public megaMenuColapse: boolean = window.innerWidth < 1199 ? true : false;

	// Collapse Sidebar
	public collapseSidebar: boolean = window.innerWidth < 991 ? true : false;

	// For Horizontal Layout Mobile
	public horizontal: boolean = window.innerWidth < 991 ? false : true;

	// Full screen
	public fullScreen: boolean = false;

	constructor(private router: Router, private apiClientService: ApiClientService,
		private route: ActivatedRoute
	) {
		// this.book_id=this.route.snapshot.routeConfig.path
		this.setScreenWidth(window.innerWidth);
		fromEvent(window, 'resize').pipe(
			debounceTime(1000),
			takeUntil(this.unsubscriber)
		).subscribe((evt: any) => {
			this.setScreenWidth(evt.target.innerWidth);
			if (evt.target.innerWidth < 991) {
				this.collapseSidebar = true;
				this.megaMenu = false;
				this.levelMenu = false;
			}
			if (evt.target.innerWidth < 1199) {
				this.megaMenuColapse = true;
			}
		});
		if (window.innerWidth < 991) { // Detect Route change sidebar close
			this.router.events.subscribe(event => {
				this.collapseSidebar = true;
				this.megaMenu = false;
				this.levelMenu = false;
			});
		}
	}
	permaArr: any[] = []
	FetchPermissions() {
		return this.apiClientService.get('get_logged_permissions');
	}
	GetForms(formid: any) {
		return this.apiClientService.get('all_forms?form_id=' + formid);
	}
	UpdateformsBookindID(data: any) {
		return this.apiClientService.post('update_booking_form', data);
	}
	//   FetchpermissionsData() {

	// 	  alert() 
	// 	  this.apiClientService.get('get_logged_permissions').subscribe(result => {
	// 		  //   this.
	// 		  this.permaArr=result['data']['user_permissions']
	// 	console.log(this.permaArr,'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa')
	// },error =>{

	// 	  // this.toster.error(error.error['message']);
	// 	})
	//   }
	//   ngOnInit:void() {
	// 	alert()
	// this.FetchpermissionsData()

	//   } 
	ngOnDestroy() {
		this.unsubscriber.next();
		this.unsubscriber.complete();
	}

	private setScreenWidth(width: number): void {
		this.screenWidth.next(width);
	}

	common: any = 'see-form-list'
	MENUITEMS: Menu[] = [
		// {
		// 	headTitle1: 'General', headTitle2: 'Dashboards & widgets.',
		// },
		{ title: 'Dashboard', icon: 'home', type: 'link', path: '/dashboard/hbku', show: 'access-admin-dashboard' },
		//  active: true,
		{ path: '/admin/admin-list', icon: 'user', title: 'Manage Admins', type: 'link', show: 'access-admin-list' },

		{ path: '/booking/all-bookings', icon: 'calendar', title: 'Bookings', type: 'link', show: 'access-booking-list' },

		{ path: '/booking/votes-list', icon: 'archive', title: 'Votes', type: 'link', show: 'access-voting-form-list' },
		{ path: '/form/manage-forms/voting-forms-list', icon: 'archive', title: 'Voting-Forms', type: 'link', show: 'access-voting-form-list' },


		{ path: '/admin/finance-refunds', icon: 'archive', title: 'Finance Refund', type: 'link', show: 'listing-pending-refunds' },
		//side bar no show this below page
		// { path: '/admin/finance-refunds-details', icon: 'archive', title: 'Finance Refund Details', type: 'link', show:'No show in sidebar' },
		// { path: '/booking/surveys-list', icon: 'user-check', title: 'Surveys', type: 'link', show:'access-admin-list' },

		{ path: '/user/user-listing', icon: 'users', title: 'Users', type: 'link', show: 'access-user-list' },

		{ path: '/role/all-roles', icon: 'shield-off', title: 'All Roles', type: 'link', show: 'see-role-list' },

		{ title: 'Forms', icon: 'file-text', type: 'link', path: '/form/manage-forms/list', show: 'see-form-list' },

		{ title: 'Forms', icon: 'file-text',  type: 'link' , path: '/form/manage-forms/list', show:'access-own-form-list-only'},

		{
			// title: 'Settings', icon: 'settings', type: 'sub', active: true, children: [
			title: 'Settings', icon: 'settings', type: 'sub', children: [
				// { path: '/categories/category-list', icon: 'aperture', title: 'Common Settings', type: 'link', show:'see-common-settings-list' },
				// { path: '/common-settings/list', icon: 'aperture', title: 'Common Settings', type: 'link', show:'see-category-list' },
				{ path: '/categories/category-list', icon: 'git-pull-request', title: 'Form Categories', type: 'link', show: 'see-category-list' },
				{ path: '/email-templates/template-list', icon: 'mail', title: 'Autogenerated Emails', type: 'link', show: 'see-email-template-list' },
				{ path: '/settings/discount-groups', icon: 'gift', title: 'Discount Groups', type: 'link', show: 'list-discount-groups' },
				{ path: '/smtp-page/smtp-page-list', icon: 'server', title: 'SMTP Details', type: 'link', show: 'see-smtp-details' },
				{ path: '/static-pages/pages-list', icon: 'file-text', title: 'Static Pages', type: 'link', show: 'access-page-list' },
				{ path: '/homecontent/homepage-slider', icon: 'sliders', title: 'HomePage Slider', type: 'link', show: 'list-homepage-slider' },
				// Admin Check Pending
				// { path: '/footer/footer-links', icon: 'link', title: 'Footer Links', type: 'link', show:'access-page-list' },
				{ path: '/footer/footer-links', icon: 'link', title: 'Footer Links', type: 'link', show: 'access-footer-links' },

				{ path: '/finance/finance-categories', icon: 'calendar', title: 'Finance Categories', type: 'link', show: 'access-finance-types' },
				{ path: '/booking-page/setting-booking-list', icon: 'calendar', title: 'Booking Setting', type: 'link', show: 'see-common-settings-list' }
			]
		},


	];
	// console.log();

	permval = new BehaviorSubject<any>(this.permaArr)
	items = new BehaviorSubject<Menu[]>(this.MENUITEMS);

	// megaItems = new BehaviorSubject<Menu[]>(this.MEGAMENUITEMS);
	// levelmenuitems = new BehaviorSubject<Menu[]>(this.LEVELMENUITEMS);

}
