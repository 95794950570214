import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Subject } from 'rxjs';
import { DiscountGroupService } from 'src/app/components/discount/discount-group.service';
import { FormsService } from 'src/app/components/forms/forms.service';
import Swal from 'sweetalert2';
import { CommonService } from '../../services/common.service';
import { takeUntil } from "rxjs/operators";

@Component({
  selector: 'app-early-bird',
  templateUrl: './early-bird.component.html',
  styleUrls: ['./early-bird.component.scss']
})
export class EarlyBirdComponent implements OnInit {
  discountRuleForm = [
    {
      "rule_name": "",
      "if_condition": {
        "allow": "any",
        "conditions": [{
          "email_extensions":"",
          "num_of_seats":"",
          "allowstr":'',
          "domain_err":"",
          "email_err":"",
          'alloEestr':"",
        "email2_extensions":""

       
        }]
      },
      "then_apply": {
        "type": "percent",
        "value": ""
      },
      "discount_proof_title": "",
      "discount_proof_description": "",
    
    }
  ]
  hidesave:boolean = false
  show:boolean = false
@Input() form_id:any
@Input() EarlyBirdArr: any[] = [];
@Output() EarlyRulesShow = new EventEmitter();
@Input() form_price: string = ''; // decorate the property with @Input()

destroy$: Subject<boolean> = new Subject<boolean>();
result:any
@ViewChild("allowdominput") allowdominput;
@Input() MaxDisArr: any; // decorate the property with @Input(
  idtopass:any=''
  constructor(
    private discountGroupService: DiscountGroupService,
    private common:CommonService,
    private formService: FormsService

  ) { }
  allowedDomainStr: string = "";
  allowedEmailStr: string = "";
  allowedDomainsInputData: any = [];
  validate = false;
  thenApply = ['percent', 'amount'];
  numberofseats:any
  newarr:any=[{
    domain_arr:[]

  }]
  newarremail:any=[{
    email_arr:[]
  }]
  getprice:any
  ngOnInit(): void {
    this.common.getprice().subscribe(res => {
      this.getprice=res
      })
    if(this.EarlyBirdArr.length > 0) {
      this.discountRuleForm = this.EarlyBirdArr;
    this.discountRuleForm.forEach((value, i) => {
    value.if_condition.conditions.forEach((condition,j) => {
// this.allowedDomainsInputData=condition.email_extensions
this.newarr[j].domain_arr = condition.email_extensions
this.newarremail[j].email_arr = condition.email2_extensions
// this.allowedDomainsInputData=condition.email_extensions
    })
    })
  // console.log(this.newarr,"Newarrrrrrrrrrr");
    
    }
  }
  updateEmailsListing(event, field, domainType,index) {
    
    const regularExpression = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    let newval =event.target.value
    newval= newval.charAt(0).toLowerCase() +  newval.slice(1);
    console.log(newval,'e+====')
  if (event.keyCode === 13 && event.key === "Enter" && event.target.value!='') {
    if (regularExpression.test(newval)) {
     
     switch (domainType) {
       case "allowed":
      // console.log(this.newarr[index].domain_arr);
        // console.log(  this.newarremail[index].email_arr,'newwwemail')
         this.newarremail[index].email_arr.push(event.target.value)
         console.log(this.newarremail,'rr')
         this.discountRuleForm[0].if_condition.conditions[index].email_err==''
        this.allowedEmailStr = " ";
        this.discountRuleForm[0].if_condition.conditions[index].alloEestr=""
        this.discountRuleForm[0].if_condition.conditions[index].email2_extensions=this.newarremail[index].email_arr
       
        
   }
 }
// if(event.target.value.includes('@')||event.target.value.includes(',')){
//   this.domain_err='Domain is InValid'

// }
else{
  this.discountRuleForm[0].if_condition.conditions[index].email_err='Email is InValid'


}


 }

    
  }
  updateDomainsListing(event, field, domainType,index) {
    // const regularExpression = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    let newval =event.target.value
    newval= newval.charAt(0).toLowerCase() +  newval.slice(1);
    console.log(newval,'e+====')
  if (event.keyCode === 13 && event.key === "Enter" && event.target.value!='') {
    // if (/^[a-zA-Z0-9][a-zA-Z0-9-]{1,61}[a-zA-Z0-9]\.[a-zA-Z]{2,}$/.test(newval)) {
      // if (/^([a-zA-Z0-9][a-zA-Z0-9-_]*\.)*[a-zA-Z0-9]*[a-zA-Z0-9-_]*[[a-zA-Z0-9]+$/.test(newval)) {
        if (/(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z0-9][a-z0-9-]{0,61}[a-z0-9]/.test(newval)) {

     
     switch (domainType) {
       case "allowed":
      // console.log(this.newarr[index].domain_arr);
      console.log(  this.newarr[index],'newdomains')
        
         this.newarr[index].domain_arr.push(event.target.value)
         this.discountRuleForm[0].if_condition.conditions[index].domain_err==''
        this.allowedDomainStr = " ";
        this.discountRuleForm[0].if_condition.conditions[index].allowstr=""
        this.discountRuleForm[0].if_condition.conditions[index].email_extensions=this.newarr[index].domain_arr
       
        
   }
 }
// if(event.target.value.includes('@')||event.target.value.includes(',')){
//   this.domain_err='Domain is InValid'

// }
else{
  console.log('error')
  this.discountRuleForm[0].if_condition.conditions[index].domain_err='Domain is InValid'


}


 }

    
  }

  updateAllowedDomains(pindex,cindex, field) {
    // this.allowedDomainsInputData.splice(index, 1);
    this.newarr[pindex].domain_arr.splice(cindex, 1);
  }
  updateEmailAllowedDomains(pindex,cindex, field) {
    // this.allowedDomainsInputData.splice(index, 1);
    this.newarremail[pindex].email_arr.splice(cindex, 1);
  }
  removeRow(discountRuleFormIndex: number, index: number) { 
    this.discountRuleForm[discountRuleFormIndex].if_condition.conditions.splice(index, 1);
  }
  addNewRow(ruleindex) {
    // if(this.discountFields !== undefined && this.discountFields.length > 0) {
      this.discountRuleForm[ruleindex].if_condition.conditions.push({
        "email_extensions":"",
        "num_of_seats":"",
        "allowstr":"",
        "domain_err":"",
        "email_err":"",
        'alloEestr':"",
        "email2_extensions":""


     
      });
      this.newarr.push({
          domain_arr:[],
         
      })
      this.newarremail.push({
        email_arr:[],
       
    })
    // console.log(this.newarr,'newarr');
      
    // // } else {
    //   this.discountRuleForm[ruleindex].if_condition.conditions.push({
    //     "field_type": this.basicInfoFields[0].slug,
    //     // "constraint": this.ruleConditionList[0].name,
    //     // "constraint_id": this.ruleConditionList[0].id,
    //     "value": "",
    //     "showValueBox": true
    //   // })
    // // }
  }
  MaxDiscountLimit(event: any,index) {

       
  // console.log( parseInt(event.target.value),'gggg', parseInt(this.getprice));
    if(this.discountRuleForm[index].then_apply.type=='amount'){
      if (parseInt( event.target.value)>parseInt(this.getprice)) {
         Swal.fire({
          position: 'center',
          icon: 'error',
         title:'Discount Price should not be greater than form price',
          padding: '2em',
        });
        this.discountRuleForm[index].then_apply.value=''
        // this.discountRuleForm[index]
      }

    }else{
      if (parseInt( event.target.value)>100) {
        // return false;
        Swal.fire({
          position: 'center',
          icon: 'error',
         title:'Discount Percentage should not be greater than 100',
          padding: '2em',
        });
        this.discountRuleForm[index].then_apply.value=''
        // this.discountRuleForm[index]
      }
    }
    
        // return true;
   
 // console.log(this.discountRuleForm[index].then_apply.value,"ddd");
   
   
  }
  removeRule(index,id) {
    // console.log(index,'piddd');
    Swal.fire({
      title: "Are you sure you want to delete this rule ?",
      // text:"sas",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText:"Yes" ,
    }).then((result)=>{
      if(result.value){
            if(index ==0){
              // alert('0')
            this.EarlyRulesShow.emit(true)
            this.hidesave=true
              
            }
          // console.log(this.discountRuleForm,'aaaaaaaaaaaaaaaaaaaaa');
            
            this.discountRuleForm.splice(index, 1);
        console.log(this.discountRuleForm,'aaaaaaaaaaaaaaaaaaaaa');
            // alert(id)
            // let id={}
            id = id+',early_bird'
            
            if(id !=undefined) {
              this.formService
              .DeleteDiscountRules(id)
              .pipe(takeUntil(this.destroy$))
              .subscribe((result) => {
                this.result = result;
                // this.formService.getDiscountRuleConditionsList();
                Swal.fire({
                  title: "",
                  text: result['message'],
                  icon: "success",
                  timer: 2000,
                  showConfirmButton: false,
                  showClass: {
                    popup: "animate__animated animate__fadeInDown",
                  },
                  hideClass: {
                    popup: "animate__animated animate__fadeOutUp",
                  },
                });
                // this.groupRuleEditData();
          
                // this.handleDiscountRuleConditions();
          
                // this.FinArr = this.result.data;
              });
        
            }
  
      }
    })
    }
  saveRule(){
    let errors = [];
    console.log(this.discountRuleForm,'ddd')
console.log(this.newarr[0].domain_arr,'lllllllllllll');
this.show=true
    this.discountRuleForm.map((obj: any, i: number) => {
      if(obj.rule_name === '' || obj.then_apply.value === '') {
        errors.push({'index': i, 'object': obj});
      }

      // obj.if_condition.conditions.map((consObj: any, ci: number) => {
      //   if(consObj.showValueBox && consObj.value === '') {
      //     errors.push({'condition_index': ci, 'object': consObj});
      //   }
      // });

      // if(obj.proof === 'yes' && (obj.discount_proof_title === '' || obj.discount_proof_description === '' || obj.discount_proof_files_allowed.length === 0 || obj.discount_proof_number_of_files.min === null || obj.discount_proof_number_of_files.max === null || obj.discount_proof_file_size.min === null || obj.discount_proof_file_size.max === null)) {
      //   errors.push({'proof_index': i, 'object': obj});
      // }
      console.log(obj,"obj")
      if(obj.discount_proof_title === '' || obj.discount_proof_description === '' ) {
        errors.push({'proof_index': i, 'object': obj});
      }
    });
    if(errors.length > 0 ) {
      this.validate = true;
    } 
    else if(this.newarr[0].domain_arr.length ==0 ){
return
    }
    else{
      let formData = new FormData();
      formData.append('form',this.form_id);
      formData.append('early_bird', 'yes');
      if(parseInt(this.MaxDisArr.max_discount_amount) > parseInt(this.discountRuleForm[0].then_apply.value) && this.MaxDisArr.max_discount_amount!=undefined){
           
        
        
        // formData.append('max_discount_amount',this.MaxDisArr.max_discount_amount)
        // alert(this.discountRuleForm[0].then_apply.type)
        if(this.discountRuleForm[0].then_apply.type =='amount'){
          formData.append('max_discount_amount',this.discountRuleForm[0].then_apply.value)
          formData.append('max_discount_cal_type','amount')


        }else{
          let x = parseInt(this.form_price) * (parseInt(this.discountRuleForm[0].then_apply.value)/100)
          formData.append('max_discount_cal_type','percent')

          formData.append('max_discount_amount',x.toString())

        }

          formData.append('max_discount_type',this.MaxDisArr.max_discount_type)
          }
          else{
            formData.append('max_discount_amount',this.discountRuleForm[0].then_apply.value)
            formData.append('max_discount_type','early_bird')
    
          }

      formData.append('discount_early_bird',JSON.stringify(this.discountRuleForm));
   
      

      //static discount max yet to be checked when all are done   
      this.discountGroupService.saveFormDiscountRule(formData).subscribe((result: any) => {
        this.discountGroupService.setMaxDiscount(true)
        result['data']['data']['earlyBirdRules'].forEach((rule,i)=>{
          this.idtopass = rule.id
        })
        console.log(this.idtopass,'idtopass')
        this.discountRuleForm.forEach((val:any,i:any)=>{
val['id'] =this.idtopass
        })
console.log(this.discountRuleForm,'rrrr')
        if(result['success']) {
          Swal.fire({
            position: 'center',
            icon: 'success',
            html: result['message'],
            padding: '2em',
          });
          // this.ngZone.run(() => {
          //   this.router.navigate(['/settings/discount-groups']);
          //   this.discountRuleForm = [];
          //   this.addNewRule();
          // });
        
        } else {
          Swal.fire({
            position: 'center',
            icon: 'error',
            html: result['message'],
            padding: '2em',
          });
        }
      }, error => {
        Swal.fire({
          position: 'center',
          icon: 'error',
          html: error.error['message'],
          padding: '2em',
        });
      });
    }
  }
}
