import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { FormsService } from 'src/app/components/forms/forms.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-on-page-rules',
  templateUrl: './on-page-rules.component.html',
  styleUrls: ['./on-page-rules.component.scss']
})
export class OnPageRulesComponent implements OnInit, OnChanges {

  @Input() formID: any;
  @Input() pageRuleObj: any;
  @Input() pageIndex: any;
  @Input() formBuilder: any;
  @Input() selectedPageName: any;
  @Input() pageRulesData: any;

  validate: boolean = false;
  pageNames: any[] = [];
  conditionsLsit: any[] = [];
  showConditions: boolean = false;
  nextPages: any[] = [];
  newCondition = {
    'field_name': '',
    'comparison_oper': '',
    'value': '',
    'field_type': ''
  }
  allPageRules: any[] = [];

  rulesErrors: boolean = false;
  conditionsErrors: any[] = [];
  deleteRule: any;

  constructor(
    private formsService: FormsService
  ) { }

  ngOnInit(): void { }

  ngOnChanges(changes: SimpleChanges): void {
    // this.allPageRules.concat(this.pageRuleObj)
    if(this.pageRulesData.length > 0) {
      this.allPageRules = this.pageRulesData;
      this.showConditions = true;
    }
    if(this.pageRuleObj.length > 0) {
      this.allPageRules.push(this.pageRuleObj[0])
    }
    this.formsService.getDiscountRuleConditionsList();
    this.formsService.getDiscountRuleConditionsData().subscribe((result: any) => {
      if(result['status']) {
        this.conditionsLsit = result['data'];
      }
    })
    this.setupRules().then((res: any) => {
      if(res.length > 0) {
        res.forEach((o:any) => {
          if(!this.pageNames.includes(o.toString())) {
            this.pageNames.push(o.toString())
          }
        })
      }
    });

    this.allPageRules.map(obj => {
      let pname = Object.keys(obj)
      obj[pname[0]]['conditions'].map(o => {
        if(o.field_type == 'group') {
          // Group Fields operands
          this.updateOperandsToRules(o, o.group_field_type, obj[pname[0]]['on_page_index'], o.field_name);
        } else {
          this.updateOperandsToRules(o, o.field_type, obj[pname[0]]['on_page_index']);
        }
      })
    })
    // this.nextPages = this.formBuilder.slice((this.pageIndex + 1));
    if(this.pageRuleObj.length > 0) {
      this.nextPages = this.formBuilder.attributes.slice((this.pageRuleObj[0][this.selectedPageName]['on_page_index'] + 1));

      this.nextPages.map((o, i) => {
        o[0].pageName = (o[0].pageTitle == '' || o[0].pageTitle == null) ? 'Page' + ((this.pageIndex + 1) + (i + 1)) : o[0].pageTitle;
        o[0].pageNumber = ((this.pageIndex + 1) + (i + 1));
      })
    }

    if(this.pageRulesData.length > 0) {
      this.existingRuleNextPageSetup()
    }
  }

  updateOperandsToRules(obj: any, fieldType: string, pageIndex: number, groupName: string = '') {
    if(fieldType == 'date' || fieldType == 'datetime-local') {
      obj.operands = this.conditionsLsit.filter(el => el.id == 1 || el.id == 2 || el.id == 3 || el.id == 4 || el.id == 9 || el.id == 10);
    } else if(fieldType == 'file') {
      obj.operands = this.conditionsLsit.filter(el => el.id == 3 || el.id == 4);
    } else if(fieldType == 'rating') {
      obj.operands = this.conditionsLsit.filter(el => el.id == 1 || el.id == 2 || el.id == 3 || el.id == 4);
    } else {
      obj.operands = this.conditionsLsit.filter(el => el.id != 9 && el.id != 10);
      if(fieldType == 'checkbox' || fieldType == 'autocomplete' || fieldType == 'radio') {
        let frmField = this.formBuilder.attributes[pageIndex].filter(el => el.type == fieldType && obj.field_name == el.name)
        if(frmField.length > 0) {
          obj.options = frmField[0].values;
        }
      }

      if(groupName != '') {
        let groupFormField = this.formBuilder.attributes[pageIndex].filter(el => el.name == groupName)
        obj.groupFields = groupFormField[0].groupFields;
        groupFormField[0].groupFields.forEach((f: any) => {
          if(f.name === obj.group_field_name && (fieldType == 'checkbox' || fieldType == 'autocomplete' || fieldType == 'radio'))
            obj.options = f.values;
        });
      }
    }
  }

  setupRules() {
    return new Promise((resolve, reject) => {
      if(this.pageRulesData.length > 0)
        resolve(this.pickPageNames(this.pageRulesData))
      if(this.pageRuleObj.length > 0)
        resolve(this.pickPageNames(this.pageRuleObj))
    })
  }

  pickPageNames(array: any) {
    return array.map((a: any) => { return Object.keys(a) })
  }

  setUpOperandsList(fields, pageIndex, conditionIndex) {
    if(fields.type == 'date' || fields.type == 'datetime-local') {
      this.allPageRules[pageIndex][this.pageNames[pageIndex]].conditions[conditionIndex].operands = this.conditionsLsit.filter(el => el.id == 1 || el.id == 2 || el.id == 3 || el.id == 4 || el.id == 9 || el.id == 10);
    } else if(fields.type == 'file') {
      this.allPageRules[pageIndex][this.pageNames[pageIndex]].conditions[conditionIndex].operands = this.conditionsLsit.filter(el => el.id == 3 || el.id == 4);
    } else if(fields.type == 'rating') {
      this.allPageRules[pageIndex][this.pageNames[pageIndex]].conditions[conditionIndex].operands = this.conditionsLsit.filter(el => el.id == 1 || el.id == 2 || el.id == 3 || el.id == 4);
    } else {
      this.allPageRules[pageIndex][this.pageNames[pageIndex]].conditions[conditionIndex].operands = this.conditionsLsit.filter(el => el.id != 9 && el.id != 10);
      if(fields.type == 'checkbox' || fields.type == 'radio' || fields.type == 'autocomplete') {
        this.allPageRules[pageIndex][this.pageNames[pageIndex]].conditions[conditionIndex].options = fields.values
      }
    }
    
  }

  onGroupFieldSelect(selectedFieldName: string, conditionItem: any, pageIndex: number, conditionIndex: number) {
    let groups = this.formBuilder.attributes[pageIndex].filter(el => el.type == 'group')

    if(groups.length > 0) {
      groups.forEach(el => {
        el.groupFields.forEach(f => {
          if(f.name === selectedFieldName) {
            conditionItem.group_field_type = f.type;
            this.setUpOperandsList(f, pageIndex, conditionIndex)
          }
        });
      });
      conditionItem.field_type = 'group';
      conditionItem.group_field_name = selectedFieldName;
    }
  }

  onFormFieldSelect(selectedFieldName: string, conditionItem: any, pageIndex: number, conditionIndex: number) {
    let field = this.formBuilder.attributes[pageIndex].filter(el => el.name === selectedFieldName)
    if(field != undefined && field.length > 0 && field[0].type == 'group') {
      this.allPageRules[pageIndex][this.pageNames[pageIndex]].conditions[conditionIndex].groupFields = field[0].groupFields;
    } else {
      conditionItem.field_type = field[0]?.type
      this.setUpOperandsList(field[0], pageIndex, conditionIndex)
      
      if(selectedFieldName != '') {
        this.showConditions = true;
        if(conditionItem.condition_error != undefined && conditionItem.condition_error) {
          delete conditionItem.condition_error
        }
      } else {
        this.showConditions = false;
        this.rulesErrors = true;
        conditionItem.condition_error = true;
      }
    }
  }

  onConditionSelect(event: any, conditionsArray, conditionIndex, conditionItem) {
    if(event.target.value != '') {
      conditionsArray[conditionIndex]['comparison_oper'] = event.target.value;
      if(conditionItem.condition_error != undefined && conditionItem.condition_error && (event.target.value == 3 || event.target.value == 4)) {
        delete conditionItem.condition_error;
      }
    } else {
      this.rulesErrors = true;
      conditionItem.condition_error = true;
    }
  }

  addNewRow(pageIndex) {
    this.allPageRules[pageIndex][this.pageNames[pageIndex]]?.conditions.push({
      'field_name': '',
      'comparison_oper': '',
      'value': '',
      'field_type': ''
    })
    console.log('THE NEW CON OBJ', this.newCondition, this.allPageRules[pageIndex][this.pageNames[pageIndex]]?.conditions)
  }

  removeRow(ifConditions, conditionIndex) {
    ifConditions.conditions.splice(conditionIndex, 1); 
  }

  skipPageSelect(event, pageRuleIndex, pageName) {
    if(event.target.value != '') {
      this.allPageRules[pageRuleIndex][pageName]['to_page_link'] = parseInt(event.target.value);
      this.allPageRules[pageRuleIndex][pageName]['to_page_link_name'] = this.formBuilder.attributes[(parseInt(event.target.value) - 1)][0]['name'];
      if(this.allPageRules[pageRuleIndex][pageName].to_page_link_error != undefined && this.allPageRules[pageRuleIndex][pageName].to_page_link_error) {
        delete this.allPageRules[pageRuleIndex][pageName].to_page_link_error
      }
    } else {
      this.allPageRules[pageRuleIndex][pageName]['to_page_link'] = null;
      this.allPageRules[pageRuleIndex][pageName]['to_page_link_name'] = null;
      this.rulesErrors = true;
      this.allPageRules[pageRuleIndex][pageName].to_page_link_error
    }
  }

  existingRuleNextPageSetup() {
    this.pageRulesData.forEach((page: any) => {
      this.setPageNameAndNumber(page);
    })
  }

  setPageNameAndNumber(page: any) {
    let nexpages = []
    if(this.pageRulesData.length > 0) {
      this.pageRulesData.forEach((ruleData: any) => {
        let k = Object.keys(page);
        nexpages = this.formBuilder.attributes.slice((page[k[0]]['on_page_index'] + 1))

        let combine = this.nextPages.concat(nexpages)
      })
    }
  }

  conditionCheck(event: any, condition: any, conditionItem: any) {
    if(event.target.value === '') {
      conditionItem.condition_error = true;
      this.rulesErrors = true;
    } else {
      if(conditionItem.condition_error != undefined && conditionItem.condition_error)
        delete conditionItem.condition_error;
    }
  }

  async updateAllowDeletePage(rulePageIndex: number, ruleLinkPageIndex: number) {
    for(let i = rulePageIndex; i < ruleLinkPageIndex; i++) {
      if(this.formBuilder.attributes[i][0]['page_rule'] || this.formBuilder.attributes[i][0]['page_rule_link']) {
        this.formBuilder.attributes[i][0]['allowPageDelete'] = false;
      }
      this.formBuilder.attributes[i][0]['allowPageDelete'] = false;
    }
  }

  updateFormBuilderPages() {
    let saveBuilder = new Observable(observer => {
      if(this.allPageRules.length > 0) {
        this.allPageRules.forEach(async (page: any, pIndex: number) => {
          let pageRuleIndex = page[this.pageNames[pIndex]]['on_page_index'];
          let pageRuleLinkIndex = (page[this.pageNames[pIndex]]['to_page_link'] - 1);
          this.formBuilder.attributes[pageRuleIndex][0]['page_rule'] = true;
          this.formBuilder.attributes[pageRuleLinkIndex][0]['page_rule_link'] = true;
          this.formBuilder.attributes[pageRuleLinkIndex][0]['allowPageDelete'] = false;
          await this.updateAllowDeletePage(pageRuleIndex, pageRuleLinkIndex)
        });

        if(this.deleteRule != undefined && Object.keys(this.deleteRule).length > 0) {
          let delKey = Object.keys(this.deleteRule);
          this.formBuilder.attributes.forEach((el: any) => {
            if(el[0].name === delKey[0]) {
              this.formBuilder.attributes[(this.deleteRule[delKey[0]]['to_page_link'] - 1)][0]['allowPageDelete'] = true;
            }
          })
          
        }
      } else {
        this.formBuilder.attributes.forEach((formField: any) => {
          formField[0]['allowPageDelete'] = true;
        });
      }

      this.onlyFormBuilderUpdate(this.formBuilder).subscribe(result => {
        if(result['success']) {
          this.formsService.updateCurrentForm(this.formBuilder);
        }
        observer.next(result);
        observer.complete();
      }, error => {
        observer.next(error);
        observer.complete();
      })
    });

    return saveBuilder;

  }

  saveRule() {
    console.log(this.formBuilder, 'SAVING THE RULE', this.allPageRules)

    this.validateRules()
    console.log('LOOK FOR ERRORS', this.rulesErrors)

    setTimeout(() => {
      if(!this.rulesErrors) {
        this.updateFormBuilderPages().subscribe((saveFormResult: any) => {
          console.log('FORM BUILDER UPDATE', saveFormResult, this.formBuilder)
          if(saveFormResult['success']) {
            this.savePageRule();
          } else {
            Swal.fire({
              position: 'center',
              icon: 'error',
              text: `${saveFormResult['message']}`,
              showConfirmButton: true,
              padding: '2em',
            });
          }
        }, error => {
          Swal.fire({
            position: 'center',
            icon: 'error',
            html: '<h3>Form builder not updated!</h3>',
            showConfirmButton: true,
            padding: '2em',
          });
          console.error('Form Builder Update Error: ', error)
        })
      }
    }, 100);
  }

  validateRules() {
    this.pageNames.forEach((pageName, index) => {

      this.checkRuleNames(pageName, index).then(() => {
        this.checkPageConditions(pageName, index).then(() => {
          this.checkToPageLinks(pageName, index);
        })
      });
    })
  }

  checkToPageLinks(pageName: any, index: number) {
    let pageLinkErrors = [];
    return new Promise((resolve, reject) => {
      if(this.allPageRules[index][pageName].to_page_link == null) {
        this.allPageRules[index][pageName].to_page_link_error = true;
        this.rulesErrors = true;
        pageLinkErrors.push(pageName)
      } else {
        delete this.allPageRules[index][pageName].to_page_link_error;
        this.rulesErrors = false;
      }

      resolve(pageLinkErrors)
    })
  }

  checkRuleNames(pageName: any, index: number) {
    let pageNameErrors = [];
    return new Promise((resolve, reject) => {
      if(this.allPageRules[index] != undefined && this.allPageRules[index][pageName] != undefined && this.allPageRules[index][pageName].rule_name == '') {
        this.allPageRules[index][pageName].rule_name_error = true;
        this.rulesErrors = true;
        pageNameErrors.push([pageName])
      } else {
        delete this.allPageRules[index][pageName].rule_name_error;
        this.rulesErrors = false;
      }

      resolve(pageNameErrors)
    })
  }

  checkPageConditions(pageName: any, index: number) {
    let conditionErrors = [];
    return new Promise(async (resolve, reject) => {
      await this.allPageRules[index][pageName].conditions.forEach((c, ci) => {
        if(c.operands != undefined) {
          delete c.operands;
        }
        if(c.options != undefined) {
          delete c.options;
        }
        if(c.groupFields != undefined) {
          delete c.groupFields;
        }

        if(c.field_name == '') {
          this.rulesErrors = true;
          c.condition_error = true;
          conditionErrors.push(pageName)
        } else if(c.field_name != '' && (c.comparison_oper == '' || (c.comparison_oper != 3 && c.comparison_oper != 4)) && c.value == '') {
          conditionErrors.push(pageName)
          this.rulesErrors = true;
          c.condition_error = true;
        } else {
          this.rulesErrors = false;
          delete c.condition_error;
        }
      });
      resolve(conditionErrors);
    })
  }

  checkError(event: any, ruleObj: any) {
    if(event.target.value === '') {
      ruleObj.rule_name_error = true;
      this.rulesErrors = true;
    } else {
      if(ruleObj.rule_name_error != undefined && ruleObj.rule_name_error)
        delete ruleObj.rule_name_error;
    }
  }

  ruleDelete(pageRuleIndex: any) {
    Swal.fire({
      title: "Are you sure you want to delete this rule?",
      text: 'This action is not reversable',
      icon: "info",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText:'Yes' ,
      cancelButtonText:'No'
    }).then(response => {
      if(response.value){
        this.deleteRule = this.allPageRules[pageRuleIndex];
        this.allPageRules.splice(pageRuleIndex, 1);
        setTimeout(() => {
          this.saveRule();
        }, 100);
      }
    })
  }

  onlyFormBuilderUpdate(formBuilder: any) {
    return this.formsService.saveFormBuilderData(this.formID, { 'form_body': formBuilder });
  }

  savePageRule() {
    let formData: any = new FormData;
    
    formData.append('form', this.formID);
    formData.append('page_rules', JSON.stringify(this.allPageRules));

    this.formsService.CreatePageRules(formData).subscribe(
      (result: any) => {
        console.log('PAGE RULE SAVED', result)
        if(result['success']) {
          if(this.allPageRules.length === 0) {
            this.formBuilder.attributes.forEach((page: any) => {
              page[0]['allowPageDelete'] = true;
            });
            this.onlyFormBuilderUpdate(this.formBuilder).subscribe(result => {
              if(result['success']) {
                this.formsService.updateCurrentForm(this.formBuilder);
              }
            })
          }
          Swal.fire({
            position: 'center',
            icon: 'success',
            html: result['message'],
            showConfirmButton: true,
            padding: '2em',
          });
        } else {
          Swal.fire({
            position: 'center',
            icon: 'error',
            html: result['message'],
            showConfirmButton: true,
            padding: '2em',
          });
        }
      },
      (error: any) => {
        console.error('Page rule save error: ', error);
        Swal.fire({
          position: 'center',
          icon: 'error',
          html: '<span>Something went wrong</span>',
          showConfirmButton: true,
          padding: '2em',
        });
      }
    )
  }
}
