import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import * as moment from 'moment';
import { DiscountGroupService } from 'src/app/components/discount/discount-group.service';
import { FormsService } from 'src/app/components/forms/forms.service';
import Swal from 'sweetalert2';
import { CommonService } from '../../services/common.service';
import { takeUntil } from "rxjs/operators";
import { Subject } from 'rxjs';
@Component({
  selector: 'app-seat-capacity',
  templateUrl: './seat-capacity.component.html',
  styleUrls: ['./seat-capacity.component.scss']
})
export class SeatCapacityComponent implements OnInit {
  @Input() form_id:any
  @Input() seatCapacityRules:any
  @Input() MaxDisArr: any; // decorate the property with @Input(
@Input() form_price: string = ''; // decorate the property with @Input()

    @Output() SeatRuleShow = new EventEmitter();

  constructor(
    private discountGroupService: DiscountGroupService,
    private common:CommonService,
    private formService: FormsService


  ) { }
  result:any
destroy$: Subject<boolean> = new Subject<boolean>();

  hidesave:boolean = false
idtopass:any=''

  discountRuleForm = [
    {
      "rule_name": "",
      "if_condition": {
        "allow": "any",
        "conditions": [{
          startDate:'',
          endDate:'',
          num_of_seats:''
          
         
        }]
      },
      "then_apply": {
        "type": "percent",
        "value": ""
      },
      "discount_proof_title": "",
      "discount_proof_description": "",
   
    }
  ]
  
  validate = false;
  thenApply = ['percent', 'amount'];
  minDob: any;
  maxDob: any;
  error_start:any
  error_end:any
  minDobstart: any;
  getprice:any

  ngOnInit(): void {
    this.common.getprice().subscribe(res => {
      this.getprice=res
      })
    this.minDobstart = moment(new Date()).format("YYYY-MM-DD");
    this.minDob = moment(new Date()).format("YYYY-MM-DD");

    if(this.seatCapacityRules != undefined && this.seatCapacityRules.length > 0) {
this.discountRuleForm=this.seatCapacityRules
        }    }
  removeRow(discountRuleFormIndex: number, index: number) { 
    this.discountRuleForm[discountRuleFormIndex].if_condition.conditions.splice(index, 1);
  }
  addNewRow(ruleindex) {
    // if(this.discountFields !== undefined && this.discountFields.length > 0) {
      this.discountRuleForm[ruleindex].if_condition.conditions.push({
        startDate:'',
        endDate:'',
        num_of_seats:''
      });
    // // } else {
    //   this.discountRuleForm[ruleindex].if_condition.conditions.push({
    //     "field_type": this.basicInfoFields[0].slug,
    //     // "constraint": this.ruleConditionList[0].name,
    //     // "constraint_id": this.ruleConditionList[0].id,
    //     "value": "",
    //     "showValueBox": true
    //   // })
    // // }
  }
  startChange(e: any) {
    
    this.error_start = "";
    this.minDob = e.target.value;
  }
  EndChange(e: any) {
    
    this.error_end = "";
    this.maxDob = e.target.value;
  }
  MaxDiscountLimit(event: any,index) {

       
  // console.log( parseInt(event.target.value),'gggg', parseInt(this.getprice));
    if(this.discountRuleForm[index].then_apply.type=='amount'){
      if (parseInt( event.target.value)>parseInt(this.getprice)) {
         Swal.fire({
          position: 'center',
          icon: 'error',
         title:'Discount Price should not be greater than form price',
          padding: '2em',
        });
        this.discountRuleForm[index].then_apply.value=''
        // this.discountRuleForm[index]
      }

    }else{
      if (parseInt( event.target.value)>100) {
        // return false;
        Swal.fire({
          position: 'center',
          icon: 'error',
         title:'Discount Percentage should not be greater than 100',
          padding: '2em',
        });
        this.discountRuleForm[index].then_apply.value=''
        // this.discountRuleForm[index]
      }
    }
    
        // return true;
   
 // console.log(this.discountRuleForm[index].then_apply.value,"ddd");
   
   
  }
  removeRule(index,id) {
    // console.log(index,'piddd');
    Swal.fire({
      title: "Are you sure you want to delete this rule ?",
      // text:"sas",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText:"Yes" ,
    }).then((result)=>{
      if(result.value){
            if(index ==0){
              // alert('0')
            this.SeatRuleShow.emit(true)
            this.hidesave=true
              
            }
          // console.log(this.discountRuleForm,'aaaaaaaaaaaaaaaaaaaaa');
            
            this.discountRuleForm.splice(index, 1);
        console.log(this.discountRuleForm,'aaaaaaaaaaaaaaaaaaaaa');
            // alert(id)
            // let id={}
            id=id+',seat_capacity'
            if(id !=undefined) {
              this.formService
              .DeleteDiscountRules(id)
              .pipe(takeUntil(this.destroy$))
              .subscribe((result) => {
                this.result = result;
                // this.formService.getDiscountRuleConditionsList();
                Swal.fire({
                  title: "",
                  text: result['message'],
                  icon: "success",
                  timer: 2000,
                  showConfirmButton: false,
                  showClass: {
                    popup: "animate__animated animate__fadeInDown",
                  },
                  hideClass: {
                    popup: "animate__animated animate__fadeOutUp",
                  },
                });
                // this.groupRuleEditData();
          
                // this.handleDiscountRuleConditions();
          
                // this.FinArr = this.result.data;
              });
        
            }
  
      }
    })
    }
  saveRule(){
    let errors = [];

    this.discountRuleForm.map((obj: any, i: number) => {
      if(obj.rule_name === '' || obj.then_apply.value === '') {
        // alert('yes')
        errors.push({'index': i, 'object': obj});
      }

      // obj.if_condition.conditions.map((consObj: any, ci: number) => {
      //   if(consObj.showValueBox && consObj.value === '') {
      //     errors.push({'condition_index': ci, 'object': consObj});
      //   }
      // });

      // if(obj.proof === 'yes' && (obj.discount_proof_title === '' || obj.discount_proof_description === '' || obj.discount_proof_files_allowed.length === 0 || obj.discount_proof_number_of_files.min === null || obj.discount_proof_number_of_files.max === null || obj.discount_proof_file_size.min === null || obj.discount_proof_file_size.max === null)) {
      //   errors.push({'proof_index': i, 'object': obj});
      // }
      if(obj.discount_proof_title === '' || obj.discount_proof_description === '' ) {
        errors.push({'proof_index': i, 'object': obj});
      }
    });
    if(errors.length > 0 ) {
      this.validate = true;
    } 
    else{
      let formData = new FormData();
      formData.append('form',this.form_id);
      // formData.append('is_preconfigured',this.isPreconfigCheck ? 'yes' : 'no');
      if(parseInt(this.MaxDisArr.max_discount_amount) > parseInt(this.discountRuleForm[0].then_apply.value) && this.MaxDisArr.max_discount_amount!=undefined){
         
        // formData.append('max_discount_amount',this.MaxDisArr.max_discount_amount)
        if(this.discountRuleForm[0].then_apply.type =='amount'){
          formData.append('max_discount_amount',this.discountRuleForm[0].then_apply.value)
        formData.append('max_discount_cal_type','amount')


        }else{
          let x = parseInt(this.form_price) * (parseInt(this.discountRuleForm[0].then_apply.value)/100)
          formData.append('max_discount_amount',x.toString())
        formData.append('max_discount_cal_type','percent')

        }
            formData.append('max_discount_type',this.MaxDisArr.max_discount_type)
          }
          else{
            formData.append('max_discount_amount',this.discountRuleForm[0].then_apply.value)
            formData.append('max_discount_type','seat_capacity')
            formData.append('max_discount_cal_type',this.discountRuleForm[0].then_apply.type)
    
          }
      formData.append('seat_capacity', 'yes');
      // formData.append('max_discount_type', 'seat_capacity');
      // formData.append('max_discount_amount', this.discountRuleForm[0].then_apply.value);
      formData.append('discount_seat_capacity',JSON.stringify(this.discountRuleForm));
      // formData.append('num_of_seats', this.numberofseats);
      // formData.append('rule_name', this.numberofseats);
      //static discount max yet to be checked when all are done   
      this.discountGroupService.saveFormDiscountRule(formData).subscribe((result: any) => {
        this.discountGroupService.setMaxDiscount(true)
        result['data']['data']['seatCapacityRules'].forEach((rule,i)=>{
          this.idtopass = rule.id
        })
        console.log(this.idtopass,'idtopass')
        this.discountRuleForm.forEach((val:any,i:any)=>{
val['id'] =this.idtopass
        })
console.log(this.discountRuleForm,'rrrr')
        if(result['success']) {
          Swal.fire({
            position: 'center',
            icon: 'success',
            html: result['message'],
            padding: '2em',
          });
          // this.ngZone.run(() => {
          //   this.router.navigate(['/settings/discount-groups']);
          //   this.discountRuleForm = [];
          //   this.addNewRule();
          // });
        
        } else {
          Swal.fire({
            position: 'center',
            icon: 'error',
            html: result['message'],
            padding: '2em',
          });
        }
      }, error => {
        Swal.fire({
          position: 'center',
          icon: 'error',
          html: error.error['message'],
          padding: '2em',
        });
      });
    }
  }
}
