<div *ngIf="type == 'HomepageSlider'">
  <app-breadcrumb [title]="'Homepage Slider'" [items]="['System Settings']" [active_item]="'HomePage Slider'"></app-breadcrumb>
</div>
<div *ngIf="type == 'Category'">
  <app-breadcrumb [title]="'Form Categories'" [items]="['System Settings']" [active_item]="'Form Categories'"></app-breadcrumb>
</div>
<div *ngIf=" type == 'SubCategory'">
  <!-- <a class="btn btn-outline-primary btn-air-primary mt-4" type="button" placement="top" role="button" [routerLink]="['/categories/category-list']">Back</a> -->
  <app-breadcrumb [title]="'Form Sub Categories'" [items]="['Form Category']" [itemsLink]="'/categories/category-list'" [active_item]="'Form Sub Categories'"></app-breadcrumb>
</div>
<div *ngIf=" type == 'SubSubCategory'">
  <!-- <a class="btn btn-outline-primary btn-air-primary mt-4" type="button" placement="top" role="button" [routerLink]="['/categories/category-list']">Back</a> -->
  <app-breadcrumb [title]="'Form Sub Sub Categories'" [items]="['Form Sub Category']" [itemsLink]="backtoPageURL" [active_item]="'Form Sub Sub Categories'"></app-breadcrumb>
</div>
<div *ngIf="type == 'Email'">
  <app-breadcrumb [title]="'Auto Generated Emails'" [items]="['System Settings']" [active_item]="'Auto Generated Emails'"></app-breadcrumb>
</div>
<div *ngIf="type == 'Pages'">
  <app-breadcrumb [title]="'Static Pages'" [items]="['System Settings']" [active_item]="'Static Pages'"></app-breadcrumb>
</div>
<div *ngIf="type == 'Admin'">
  <app-breadcrumb [title]="'Admins List'" [items]="['Manage Admins']" [active_item]="'Admins List'"></app-breadcrumb>
  <!-- <p>ADMINNNN {{list |json}}</p> -->
</div>
<div *ngIf="type == 'Roles'">
  <app-breadcrumb [title]="'Roles List'" [items]="['Roles and Permissions']" [active_item]="'Roles List'"></app-breadcrumb>
</div>
<div *ngIf="type == 'User'">
  <app-breadcrumb [title]="'Users List'" [items]="['Users']" [active_item]="'Users List'"></app-breadcrumb>
</div>
<div *ngIf="type == 'Form'">
  <app-breadcrumb [title]="'Forms'" [items]="['Manage Forms']" [active_item]="'Forms'"></app-breadcrumb>
</div>

<div *ngIf="type == 'FinanceRefund'">
  <app-breadcrumb [title]="'Finance Refund'" [active_item]="'Finance Refund'"></app-breadcrumb>
</div>

<div *ngIf="type == 'finance'">
  <app-breadcrumb [title]="'Finance List'" [items]="['Finance']" [active_item]="'Finance List'"></app-breadcrumb>
</div>

<div *ngIf="type == 'AllBook' && book_id !='votes-list' "> 
  <app-breadcrumb [title]="'All Bookings'" [items]="['Bookings']" [active_item]="'All Bookings'"></app-breadcrumb>
</div>
<div *ngIf="type == 'AllBook' && book_id =='votes-list' "> 
  <app-breadcrumb [title]="'Votes'" [items]="['Bookings']" [active_item]="'Votes'"></app-breadcrumb>
</div>

<div class="container-fluid">
  <div class="row">
    <div class="col-sm-12">
      <div class="card">
        <!-- <div *ngIf="catval!=null && type == 'SubCategory'">{{catval}}</div> -->
        <div class="card-header d-flex align-items-center justify-content-between pt-4 pb-0 px-4">
          <div *ngIf="type == 'LastBookings'">
            <h5 class="mb-4">Latest Bookings</h5>
          </div>
          <div class="d-flex align-items-center justify-content-between w-100 flex-wrap" *ngIf="type != 'LastBookings'">
            <div class="searchWrap mb-4" [ngClass]="type == 'AllBook' ? 'mr-auto' : ''">
              <!-- <input type="text" class="form-control search" placeholder="Search" (input)="searchval($event)"/> -->
              <input type="text" class="form-control search" placeholder="Search" [(ngModel)]="MainSearchVar" [ngModelOptions]="{standalone: true}" (ngModelChange)="this.AllBookSearchUpdate.next($event)"/>
              <!-- [(ngModel)]="searchVal" (ngModelChange)="this.FormSearchUpdate.next($event)"  [ngModelOptions]="{standalone: true}"/ -->
              <ng-container *ngIf="type =='SubCategory'">
                <h5 class="mt-3 d-block">{{parent_cat}}</h5> 
              </ng-container>
            </div>
            <!-- <div class="formFilters d-flex align-items-center justify-content-end w-100" *ngIf="type == 'User' && showcreate == 'true'"> -->
              <div class="formFilters d-flex align-items-center justify-content-end mr-3" *ngIf="type == 'AllBook' && book_id !='votes-list'">
                <div class="form-group mr-2 mr-md-0 mr-sm-0 w-100">
                  <!-- <h1>pending from backend</h1> -->
                  <!-- {{status}} -->
                  <ng-select
                  [items]="FilterData"
                  bindLabel="form_business_name" 
                  bindValue="item"
                  class="js-example-basic-multiple"
                  [ngClass]="{ 'was-validated': validateDR }"
                  placeholder="Status type"
                  (change)="BookFilter($event)"
                  [(ngModel)]="status"          
                  [ngModelOptions]="{ standalone: true }"  
                  multiple="true">
                </ng-select>
                  <!-- <select (change)="BookFilter($event)" [ngModelOptions]="{ standalone: true }" [(ngModel)]="status" class="mt-0 mr-sm-0 custom-select form-control" required="">
                    <option [ngValue]="0" [value]="0"  >Status Type</option>
                    <option  *ngFor="let item of FilterData" [value]="item" >
                      {{item.charAt(0).toUpperCase() + item.slice(1)}}
                    </option>
                  </select> -->
                </div>
              </div>
              <div class="formFilters d-flex align-items-center justify-content-end mr-3" *ngIf="type == 'AllBook' && book_id !='votes-list'">
                <div class="form-group mr-2 mr-md-0 mr-sm-0 w-100">
                  <!-- <h1>pending from backend</h1> -->
                  <ng-select
                  [items]="DiscountData"
                  bindLabel="discountval" 
                  bindValue="discountval"
                  class="js-example-basic-multiple"
                  [ngClass]="{ 'was-validated': validateDR }"
                  placeholder="Discount Type"
                  (change)="DiscountFilter($event)"
                  [(ngModel)]="discounttype_val"          
                  [ngModelOptions]="{ standalone: true }"  
                                >
                </ng-select>
                  <!-- <select (change)="BookFilter($event)" [ngModelOptions]="{ standalone: true }" [(ngModel)]="status" class="mt-0 mr-sm-0 custom-select form-control" required="">
                    <option [ngValue]="0" [value]="0"  >Status Type</option>
                    <option  *ngFor="let item of FilterData" [value]="item" >
                      {{item.charAt(0).toUpperCase() + item.slice(1)}}
                    </option>
                  </select> -->
                </div>
              </div>
              <div class="formFilters d-flex align-items-center justify-content-end mr-3" *ngIf="type == 'AllBook' && book_id !='votes-list'">
                <div class="form-group mr-2 mr-md-0 mr-sm-0 w-100">
                  <!-- <h1>pending from backend</h1> -->
                  <input
                  class="form-control digits"
                  [min]="minDobstart"
                  [max]="maxDob"
                  ngbTooltip="Start Date"
                  placement="top"
                  [(ngModel)]="startDate"
                  (change)="startChange($event)"
                  [ngModelOptions]="{
                    standalone: true
                  }"
                  ngDefaultControl
                  id="validationCustom003"
                  type="date"
                />             
                </div>
              </div>
         
            
             
              <div class="formFilters d-flex align-items-center justify-content-end mr-3" *ngIf="type == 'AllBook' && book_id !='votes-list'">
                <div class="form-group mr-2 mr-md-0 mr-sm-0 w-100">
                  <!-- <h1>pending from backend</h1> -->

                  <input
                  class="form-control digits"
                  [min]="minDob"
                  ngbTooltip="End Date"
                  placement="top"
                  (change)="EndChange($event)"formFilters d-flex align-items-center justify-content-end ng-star-inserted
                  [(ngModel)]="EndDate"
                  [ngModelOptions]="{
                    standalone: true
                  }"
                  ngDefaultControl
                  id="validationCustom004"
                  type="date"
                />             
                </div>
              </div>
              <div class="formFilters d-flex align-items-center justify-content-end mr-3" *ngIf="type == 'AllBook' && book_id !='votes-list'">
                <div class="form-group ml-2" >
                  <select [ngModelOptions]="{ standalone: true }" class="form-control" (change)="CategoryChange($event,'yes')" required=""  [(ngModel)]="category_name"
                  [ngModelOptions]="{
                    standalone: true
                  }">
                    <option value="">Category</option>
                    <option *ngFor="let item of allCategories" [value]="item.id"> 
                      <!-- [(ngModel)]="status" -->
                      <!-- (change)="statusValidate($event)" -->
                      {{ item.category_name }}
    
                    </option> 
                  </select>
                </div>
                <div class="form-group ml-2" *ngIf="showSubCategoryList?.length > 0">
                  <!-- <label
                    for="exampleFormControlSelect9"
                    class="mt-3"
                    >Choose Sub Category</label
                  > -->
                  <select
                    class="form-control digits"
                    id="exampleFormControlSelect9"
                    (change)="
                      subCategoryChange(
                        $event.target.value,'yes'
                      )
                    " [(ngModel)]="subcategory_name"
                    [ngModelOptions]="{
                      standalone: true
                    }"
                    required="form_type !='voting_form'"
                  >
                    <option value="">
                      Sub Categories
                    </option>
                    <ng-container
                      *ngFor="
                        let subCategory of showSubCategoryList
                      "
                    >
                      <ng-container *ngIf="subCategory.status == 1">
                        <option
                          [value]="subCategory.id"
                        
                        >
                          {{ subCategory.category_name }}
                        </option>
                      </ng-container>
                    </ng-container>
                  </select>
                </div>
                <div class="form-group ml-2" *ngIf="showSubSubCategoryList.length > 0">
                  <!-- <label
                    for="exampleFormControlSelect10"
                    class="mt-3"
                    >Choose Sub Sub Category</label
                  > -->
                    <select
                      class="form-control digits"
                      id="exampleFormControlSelect10"
                      (change)="
                        subSubCategoryChange(
                          $event.target.value
                        )
                      " [(ngModel)]="sub_sub_category_name"
                      [ngModelOptions]="{
                        standalone: true
                      }"
                    >
                    <!-- required="" -->
    
                      <option value="">
                        Sub Sub Category
                      </option>
                      <ng-container
                        *ngFor="
                          let subSubCategory of showSubSubCategoryList
                        "
                      >
                        <ng-container *ngIf="subSubCategory.status == 1">
                          <option
                            [value]="subSubCategory.id"
                            [selected]="
                              subSubCategory.id ===
                              selectedSubSubCategoryOption
                            "
                          >
                            {{ subSubCategory.category_name }}
                          </option>
                        </ng-container>
                      </ng-container>
                    </select>
                </div>
               
              </div>
              <div class="d-flex align-items-center flex-wrap">
                <div class="d-flex flex-wrap selector" *ngIf="type == 'Admin' ">
             
                  <div *ngIf="showstatus ==true">
                    <div ngbDropdown class="d-inline-block mb-4" placement="bottom">
                      <button  class="btn btn-outline-primary mr-2" id="dropdownBasic1" ngbDropdownToggle >Change Block/Unblock</button>
                      <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
                        <button class="dropdown-item" (click)="changeblock(0,'block')">Unblock</button>
                        <button class="dropdown-item" (click)="changeblock(1,'block')">Block</button>
                      </div>
                    </div>
                    <div ngbDropdown class="d-inline-block mb-4" placement="bottom">
                      <button  class="btn btn-outline-primary mr-2" id="dropdownBasic1" ngbDropdownToggle >Change Status</button>
                      <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
                        <button class="dropdown-item" (click)="changeblock(1,'active')">Active</button>
                        <button class="dropdown-item" (click)="changeblock(0,'active')">Inactive</button>
                      </div>
                    </div>
                  </div>
                  <ng-select
                  [items]="AdminRoleFilterArr"
                  bindLabel="name" 
                  bindValue="name"
                  class="js-example-basic-multiple"
                  [ngClass]="{ 'was-validated': validateDR }"
                  placeholder="Select Role"
                  (change)="AdminroleFilter($event)"
                  [(ngModel)]="Adminroletype"          
                  [ngModelOptions]="{ standalone: true }"  
                                >
                  <!-- multiple="true" -->

                </ng-select>
                  <div class="mb-4">
                    <!-- <h1>asdsa</h1> -->
                    <button class="btn btn-outline-primary btn-air-primary mr-2" *ngIf="showexport =='true'" type="button" placement="top" role="button" (click)="getAdminexceldow()">Export Admins</button>
                    <button class="btn btn-outline-primary btn-air-primary mr-2" *ngIf="showcreate =='true'" type="button" placement="top" role="button" [routerLink]="['/admin/create-admin']">Create Admin</button>
                  </div>
                </div>
               
                <!-- <div class="mb-4" *ngIf="type == 'AllBook' && book_id =='votes-list' ">
                  <a class="btn btn-outline-primary btn-air-primary mr-2" type="button" placement="top" ngbTooltip="Start creating voting forms" role="button" (click)="showCreateForm('voting')">Create Voting Form</a>
                </div> -->
                <!-- <div class ="d-flex align-items-center flex-wrap" *ngIf="type == 'User' && showcreate =='true'"> -->
                  <div class ="d-flex align-items-center flex-wrap" *ngIf="type == 'User'">
                  <div class="mb-4">
                    <button class="btn btn-outline-primary btn-air-primary mr-2" type="button" placement="top" ngbTooltip="Export Users" role="button" (click)="getUserexceldow()">Export Users</button>
                  </div>
                  <div ngbDropdown class="d-inline-block mb-4" placement="bottom" *ngIf="showstatus ==true">
                    <button class="btn btn-outline-primary mr-2" id="dropdownBasic1" ngbDropdownToggle >Change Status</button>
                    <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
                      <button class="dropdown-item" (click)="changeblock(0,'block')">Unblock</button>
                      <button class="dropdown-item" (click)="changeblock(1,'block')">Block</button>
                    </div>
                    <div ngbDropdown class="d-inline-block mb-4" placement="bottom">
                      <button  class="btn btn-outline-primary mr-2" id="dropdownBasic1" ngbDropdownToggle >Change Status</button>
                      <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
                        <button class="dropdown-item" (click)="changeblock(0,'active')">Active</button>
                        <button class="dropdown-item" (click)="changeblock(1,'active')">Inactive</button>
                      </div>
                    </div>
                  </div>
                  <div class="mb-4">
                    <button class="btn btn-outline-primary btn-air-primary mr-2" *ngIf="showcreate =='true'" type="button" placement="top" role="button" [routerLink]="['/user/create-user']">Create User</button>
                  </div>
                </div>
                <!-- <div class ="d-flex align-items-center flex-wrap" *ngIf="type == 'BookUser' && showcreate =='true'"> -->
                  <div class ="d-flex align-items-center flex-wrap" *ngIf="type == 'BookUser' ">
                  <div class="mb-4">
                    <button class="btn btn-outline-primary btn-air-primary mr-2" type="button" placement="top" ngbTooltip="Export Bookings" role="button" (click)="getUserBookings()">Export Bookings</button>
                  </div>
              
                  
                </div>
                <div class ="d-flex align-items-center flex-wrap" *ngIf="type == 'AllBook' && book_id !='votes-list'">
                  <div class="mb-4">
                    <button class="btn btn-outline-primary btn-air-primary mr-2" type="button" placement="top" ngbTooltip="Export Bookings" role="button" (click)="getAllUserBookings()">Export Bookings</button>
                  </div>
                </div>

                <div class ="d-flex align-items-center flex-wrap" *ngIf="type == 'AllBook' && book_id =='votes-list'">
                  <div class="mb-4">
                    <button class="btn btn-outline-primary btn-air-primary mr-2" type="button" placement="top" ngbTooltip="Export Votings" role="button" (click)="getvotingexport()">Export Votings</button>
                  </div>
                </div>
                <div class="mb-4" *ngIf="type =='HomepageSlider' && showcreate =='true'">
                  <button class="btn btn-outline-primary btn-air-primary mr-2" type="button" placement="top" role="button" [routerLink]="['/homecontent/create-homepage-slider']">Create Slider</button>
                </div>
                <div class="mb-4" *ngIf="type == 'dependent'">
                  <button type="button" *ngIf="showcreate =='true'" class="btn btn-outline-primary btn-air-primary mr-2" placement="top" (click)="createDependent()">Create Dependent</button>
                </div>
                <div class="mb-4" *ngIf="type =='Roles' && showcreate =='true'">
                  <button class="btn btn-outline-primary btn-air-primary mr-2" type="button" placement="top" role="button" [routerLink]="['/role/add-roles']">Create New Role</button>
                </div>
    
                <div class="mb-4" *ngIf="type == 'Category' && showcreate =='true'">
                  <button class="btn btn-outline-primary btn-air-primary mr-2" type="button" placement="top" role="button" [routerLink]="['/categories/create-category']">Create Category</button>
                </div>
                
                <div class="mb-4" *ngIf="type == 'finance' && showcreate == 'true'">
                  <button class="btn btn-outline-primary btn-air-primary mr-2" type="button" placement="top" role="button" [routerLink]="['/finance/create-finance']">Create Finance</button>
                </div>
  
                <div class="mb-4" *ngIf="type == 'SubCategory'&& showcreate =='true'">
                  <button class="btn btn-outline-primary btn-air-primary mr-2" type="button" placement="top" role="button" (click)="createsubcat(subid)">Add Sub Category</button>
                </div>
    
                <div class="mb-4" *ngIf="type == 'SubSubCategory'&& showcreate =='true'">
                  <button class="btn btn-outline-primary btn-air-primary mr-2" type="button" placement="top" role="button" (click)="createsubsubcat(subsubid)">Add Sub Sub Category</button>
                </div>
    
                <div class="mb-4" *ngIf="type == 'Email' && showcreate =='true'">
                  <button class="btn btn-outline-primary btn-air-primary mr-2" type="button" placement="top" role="button" [routerLink]="['/email-templates/create-template']">Create Template</button>
                </div>
    
                <div class="mb-4" *ngIf="type == 'Pages' && showcreate =='true'">
                  <button class="btn btn-outline-primary btn-air-primary mr-2" type="button" placement="top" role="button" [routerLink]="['/static-pages/add-pages']">Create Page</button>
                </div>
    
                <!-- <div class="mb-4" *ngIf="type == 'Form' && showcreate =='true'"> -->
           
                <div class="mb-4" *ngIf="type == 'Form' && (showcreate =='true' || showvotecreate =='true' ) ">
                  <button class="btn btn-outline-primary btn-air-primary mr-2" type="button" placement="top" ngbTooltip="Start creating dynamic forms" role="button" (click)="showCreateForm('form')">Create Form</button>
                </div>
                <div class="mb-4" *ngIf="type == 'BulkEmail'">
                  <button class="btn btn-outline-primary btn-air-primary mr-2" type="button" role="button" (click)="openCreateEmailForm(content1)">Create</button>
                </div>
                <div class="perPageDrop d-flex align-items-center mb-4">
                  <div class="" *ngIf="type =='FormSubmission'">
                    <button class="btn btn-outline-primary btn-air-primary mr-2" type="button" placement="top" ngbTooltip="Export List" role="button" *ngIf="editcheck ==01" (click)="getList()">Export List</button>
                  </div>
                  <div class="" *ngIf="type =='VotingForm'">
                    <button class="btn btn-outline-primary btn-air-primary mr-2" type="button" placement="top" ngbTooltip="+Invite" role="button" *ngIf="editcheck ==01" (click)="InviteUsers(content3)">+Invite</button>
                  </div>
                  <!-- <label for="perpage" class="mb-0 mr-2">Per Page:</label> -->
  
                 
                  <select id="perpage" #select (change)="rowsValidate($event)" [ngModelOptions]="{ standalone: true }" [(ngModel)]="pageSize2" class="custom-select btn-pill btn-air-primary m-0">
                    <option *ngFor="let item of RowsArr" [value]="item.value">
                      {{item.value}}
                    </option>
                  </select>
                </div>
              </div>
              <div class="ml-2 mb-4" *ngIf="type=='AutoGenerated' && edit_show">
                <button class="btn btn-outline-primary btn-air-primary mr-2"  type="button" placement="top" ngbTooltip="Start creating dynamic forms" role="button" (click)="SendEmail()">Send Booking Confirmation Email</button>
              
              </div>
             
              <div class="formFilters d-flex align-items-center justify-content-end w-100" *ngIf="type == 'User'">
              <div class="form-group mr-2 mr-md-0 mr-sm-0 w-50">
                <!-- <select id="nationality" (change)="natValidate($event)" [ngModelOptions]="{ standalone: true }" [(ngModel)]="nationalselect" class="w-50 d-block ml-auto mt-0 mr-md-0 mr-sm-0 custom-select form-control" required="">
                  <option [ngValue]="0" [value]="0" >Nationality</option>

                  <option  *ngFor="let item of countryarr" [value]="item.id">
                    {{item.flag}} {{ item.country_name }}
                  </option>
                </select> -->
                <ng-select [items]="countryarr" bindLabel="country_name" bindValue="id"
                [clearable]="false"
                (click)="natValidate($event)"
                class="font-weight-light p-0 w-50 d-block ml-auto mt-0 mr-md-0 mr-sm-0" [(ngModel)]="nationalselect">
                          <ng-template ng-label-tmp let-item="item">
                            <ng-container *ngIf="item.id!='0'"> <img [src]="'/assets/images/country_flags/'+item.country_code+'.svg'" height="20" width="25"></ng-container>
                            <b> {{item.country_name}}</b>
                          </ng-template>
                          <ng-template ng-option-tmp let-item="item" let-index="index">
                            <div>
                              <ng-container *ngIf="item.id!='0'"> <img [src]="'/assets/images/country_flags/'+item.country_code+'.svg'" height="20" width="25"></ng-container>
                              <b> {{item.country_name}}</b>
                            </div>
                        </ng-template>
                          </ng-select>
              </div>
              <div class="form-group ml-2">
                <!-- <label for="dob">Date of Birth:</label> -->
                <input class="form-control text-uppercase" id="dob" (change)="datevalidate($event)" [ngModelOptions]="{ standalone: true }" [(ngModel)]="dob" type="date" />
              </div>
              <div class="form-group ml-2">
                <input class="form-control" id="dob" [ngModelOptions]="{ standalone: true }" [(ngModel)]="dob" placeholder="Total Amount Paid" type="number" (input)="amountin($event)" required=""/>
              </div>
              <div class="form-group ml-2">
                <select (change)="statusValidate($event)" [ngModelOptions]="{ standalone: true }" [(ngModel)]="status" class="mt-0 mr-sm-0 custom-select form-control" required="">
                  <option [ngValue]="" [value]=""  >Status</option>
                  <option  *ngFor="let item of statusarr" [value]="item.name">
                    {{item.name.charAt(0).toUpperCase() + item.name.slice(1)}}
                  </option>
                </select>
              </div>
              <div class="form-group ml-2">
                <select (change)="typeValidate($event)" [ngModelOptions]="{ standalone: true }" [(ngModel)]="typeofuser" class="mt-0 mr-md-0 mr-sm-0 custom-select form-control" required="">
                  <option [ngValue]="0" [value]="0" >User-Type</option>
                  <option *ngFor="let item of typecusarr" [value]="item.value">
                    {{item.value.charAt(0).toUpperCase() + item.value.slice(1)}}
                  </option>
                </select>
              </div>
              <div class="form-group ml-2">
                <button class="btn btn-outline-primary btn-air-primary" type="button" placement="top" ngbTooltip="Reset Filter" role="button" (click)="ResetFilters()">Reset</button>
              </div>
            </div>
          </div>
        </div>


      
        <div class="card-body custom-datatable p-4">

        

        <div class="table-responsive" *ngIf="type == 'Category'">
          <table class="table table-striped table-bordered table-hover dragdropTable">
            <thead>
              <tr>
                <th class="d-none">ID</th>
                <th width="80px">Drag</th>
                <th width="30%">Category Name</th>
                <th width="30%">Banner Image</th>
                <th>Status</th>
                <th *ngIf="showactive =='true' && showedit =='true'">Action </th>
              </tr>
            </thead>
            <tbody [dragula]="'cats'" [(dragulaModel)]="list">
              <tr *ngFor="let catarr of list | paginate: { itemsPerPage: pageSize, currentPage: currentPage, totalItems: totalcount }">
                <td class="d-none">{{catarr.id}}</td>
                <td class="handle">
                  <i class="icofont icofont-drag2 f-24"></i>
                </td>
                <td>
                  <p class="mouse-hover" (click)="subcategory(catarr.id,catarr.category_name)">{{catarr.category_name}}</p>
                </td>
                <td><img class="banner_img" [src]="catarr.banner_image"/></td>
                <td>
                  <div class="d-flex align-items-center" >
                    <span class="act_btn" [ngClass]="{'bg-success':catarr.status == 1, 'bg-danger':catarr.status == 0}" container="body" placement="top"
                        [ngbTooltip]="catarr.status == 0 ? 'Inactive' : 'Active'">{{catarr.status == 0? 'Inactive':'Active' }}</span>
                  </div>
                </td>
                <td >
                  <button container="body" *ngIf="showactive =='true'" class="action_btn" [ngClass]="{'btn-danger':catarr.status == 1, 'btn-success':catarr.status == 0}" type="button" placement="top" [ngbTooltip]="catarr.status == 0? 'Make Active' : 'Make Inactive'">
                    <i class="icon-close" (click)="changeStatus('0',catarr.id)" *ngIf="catarr.status == 1"></i>
                    <i class="icon-check" (click)="changeStatus('1',catarr.id)" *ngIf="catarr.status == 0"></i>
                  </button>
                  <button container="body" *ngIf="showedit =='true'" class="action_btn btn-primary" (click)="redirect(catarr.id,'edit')" type="button" placement="top" ngbTooltip="Edit">
                    <i class="icon-pencil"></i>
                  </button>
                  <button container="body" class="action_btn btn-danger" *ngIf="showdelete =='true'" (click)="deleteCategory(catarr.id,'delete')" type="button" placement="top" ngbTooltip="Delete">
                    <i class="icon-trash"></i>
                  </button>
                  <button container="body" class="action_btn btn-primary" *ngIf="showview =='true'" (click)="redirect(catarr.id,'edit')" type="button" placement="top" ngbTooltip="View">
                    <i class="icon-eye"></i>
                  </button>
                </td>
              </tr>
              <tr *ngIf="list?.length ==0">
                <td colspan="5">No data to display</td>
              </tr>
            </tbody>
          </table>
          <div class="table_pagination">
              <div class="d-flex" *ngIf="totalcount !=0 ">
                <pagination-controls 
                    [directionLinks]="true" 
                    previousLabel="" 
                    nextLabel="" 
                    (pageChange)="pageshift($event)">
                </pagination-controls>
              </div>
          </div>
        </div>

        <div class="table-responsive" *ngIf="type == 'SubCategory'">
          <table class="table table-striped table-bordered table-hover dragdropTable">
            <thead>
              <tr>
                <th class="d-none">ID</th>
                <th width="80px">Drag</th>
                <th width="30%">Category Name</th>
                <th>Status</th>
                <th *ngIf="showactive =='true' && showedit =='true'">Action </th>
              </tr>
            </thead>
            <tbody [dragula]="'subcats'" [(dragulaModel)]="list">
              <tr *ngFor="let subcatarr of list| paginate: { itemsPerPage: pageSize, currentPage: currentPage, totalItems: totalcount }">
                <td class="d-none">{{subcatarr.id}}</td>
                <td class="handle">
                  <i class="icofont icofont-drag2 f-24"></i>
                </td>
                <td>
                  <p class="mouse-hover" (click)="subsubcategory(subcatarr.id,subcatarr.category_name)">{{subcatarr.category_name}}</p>
                </td>
                <td>
                  <div class="d-flex align-items-center">
                    <span class="act_btn" [ngClass]="{'bg-success':subcatarr.status == 1, 'bg-danger':subcatarr.status == 0}" container="body" placement="top"
                        [ngbTooltip]="subcatarr.status == 0? 'Inactive' : 'Active'">{{subcatarr.status == 0? 'Inactive':'Active' }}</span>
                  </div>
                </td>
                <td>
                  <button container="body" class="action_btn" *ngIf="showactive =='true'" [ngClass]="{'btn-danger':subcatarr.status == 1, 'btn-success':subcatarr.status == 0}" type="button" placement="top" [ngbTooltip]="subcatarr.status == 0? 'Make Active' : 'Make Inactive'">
                    <i class="icon-close" (click)="changeStatus('0',subcatarr.id)" *ngIf="subcatarr.status == 1"></i>
                    <i class="icon-check" (click)="changeStatus('1',subcatarr.id)" *ngIf="subcatarr.status == 0"></i>
                  </button>
                  <button container="body" class="action_btn btn-primary" *ngIf="showedit =='true'" (click)="redirect(subcatarr.id,'edit')" type="button" placement="top" ngbTooltip="Edit">
                    <i class="icon-pencil"></i>
                  </button>
                  <button container="body" class="action_btn btn-danger" *ngIf="showdelete =='true'"  (click)="deleteCategory(subcatarr.id,'delete')" type="button" placement="top" ngbTooltip="Delete">
                    <i class="icon-trash"></i>
                  </button>
                  <button container="body" class="action_btn btn-primary" *ngIf="showview =='true'" (click)="redirect(subcatarr.id,'edit')" type="button" placement="top" ngbTooltip="Delete">
                    <i class="icon-eye"></i>
                  </button>
                </td>
              </tr>
              <tr *ngIf="list.length ==0">
                <td colspan="5">No data to display</td>
              </tr>
            </tbody>
          </table>
        </div>

        <div class="table-responsive" *ngIf="type == 'SubSubCategory'">
          <table class="table table-striped table-bordered table-hover dragdropTable">
            <thead>
              <tr>
                <th class="d-none">ID</th>
                <th width="80px">Drag</th>
                <th width="30%">Category Name</th>
                <th>Status</th>
                <th *ngIf="showactive =='true' && showedit =='true'">Action </th>
              </tr>
            </thead>
            <tbody [dragula]="'subsubcats'" [(dragulaModel)]="list">
              <tr *ngFor="let subsubcatarr of list| paginate: { itemsPerPage: pageSize, currentPage: currentPage, totalItems: totalcount }">
                <td class="d-none">{{subsubcatarr.id}}</td>
                <td class="handle">
                  <i class="icofont icofont-drag2 f-24"></i>
                </td>
                <td>
                  <p>{{subsubcatarr.category_name}}</p>
                </td>
                <td>
                  <div class="d-flex align-items-center">
                    <span class="act_btn" [ngClass]="{'bg-success':subsubcatarr.status == 1, 'bg-danger':subsubcatarr.status == 0}" container="body" placement="top"
                        [ngbTooltip]="subsubcatarr.status == 0? 'Inactive' : 'Active'">{{subsubcatarr.status == 0 ? 'Inactive' : 'Active' }}</span>
                  </div>
                </td>
                <td>
                  <button container="body" class="action_btn" *ngIf="showactive =='true'" [ngClass]="{'btn-danger':subsubcatarr.status == 1, 'btn-success':subsubcatarr.status == 0}" type="button" placement="top" [ngbTooltip]="subsubcatarr.status == 0? 'Make Active' : 'Make Inactive'">
                    <i class="icon-close" (click)="changeStatus('0',subsubcatarr.id)" *ngIf="subsubcatarr.status == 1"></i>
                    <i class="icon-check" (click)="changeStatus('1',subsubcatarr.id)" *ngIf="subsubcatarr.status == 0"></i>
                  </button>
                  <button container="body" class="action_btn btn-primary" *ngIf="showedit =='true'" (click)="redirect(subsubcatarr.id,'edit')" type="button" placement="top" ngbTooltip="Edit">
                    <i class="icon-pencil"></i>
                  </button>
                  <button container="body" class="action_btn btn-danger" *ngIf="showdelete =='true'" (click)="deleteCategory(subsubcatarr.id,'delete')" type="button" placement="top" ngbTooltip="Delete">
                    <i class="icon-trash"></i>
                  </button>
                <button container="body" class="action_btn btn-primary" *ngIf="showview =='true'" (click)="redirect(subsubcatarr.id,'edit')" type="button" placement="top" ngbTooltip="Delete">
                    <i class="icon-eye"></i>
                  </button>
                </td>
              </tr>
              <tr *ngIf="list.length ==0">
                <td colspan="5">No data to display</td>
              </tr>
            </tbody>
          </table>
        </div>


<!-- {{pageSize}}size>>>{{currentPage}}>>>>>toal{{totalcount}} -->
          <ngx-datatable
          *ngIf="type != 'Category' && type != 'SubCategory' && type != 'SubSubCategory'"
            class="bootstrap"
            [rows]="list| paginate: { itemsPerPage: pageSize, currentPage: currentPage, totalItems: totalcount }" 
            [headerHeight]="50"
            [footerHeight]="50"
            [columnMode]="'force'"
            [rowHeight]="'auto'"
            [selected]="selected"
            [externalSorting] = "true"
            [selectionType]="'checkbox'"
            [selectAllRowsOnPage]="false"
            (sort)="onSort($event)"
            (select)="onSelect($event)"
            [scrollbarH]="true"
            [dragulaName]="'bag'"
            [trackByProp]="id"
            [dragulaModel]="data"
            [classSelector]=""
            [messages]="{emptyMessage: 'No Records found'}"
          
          >
        
            <ngx-datatable-column
            *ngIf=" type == 'User' || type == 'Admin' "
              [width]="30"
              [sortable]="false"
              [canAutoResize]="false"
              [draggable]="false"
              [resizeable]="false"
              [headerCheckboxable]="true"
              [checkboxable]="true"
            >
            </ngx-datatable-column>


            <!-- Bulk Email Logs -->
            
            <ngx-datatable-column *ngIf="type == 'BulkEmail'" prop="ID" [width]="50">
              <ng-template ngx-datatable-header-template let-column="column" let-sort="sortFn">
                <div class="d-inline-block datatable-header-cell-wrapper" ngbTooltip="ID" container="body" [adaptivePosition]="false" placement="top">
                    <span class="datatable-header-cell-label draggable" (click)="sort()">ID</span>
                </div>
              </ng-template>
                <ng-template let-row="row" ngx-datatable-cell-template>
                   <a class="mouse-hover" href="javascript:void(0)" (click)="showEmailDetails(row,content2)">#{{row.id}}</a> 
                </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-column *ngIf="type == 'BulkEmail'" prop="to" [width]="150">
              <ng-template ngx-datatable-header-template let-column="column" let-sort="sortFn">
                <div class="d-inline-block datatable-header-cell-wrapper" ngbTooltip="To" container="body" [adaptivePosition]="false" placement="top">
                    <span class="datatable-header-cell-label draggable" (click)="sort()">To</span>
                </div>
              </ng-template>
                <ng-template let-row="row" ngx-datatable-cell-template>
                    {{row.to}}
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column *ngIf="type == 'BulkEmail'" prop="cc" [width]="150">
              <ng-template ngx-datatable-header-template let-column="column" let-sort="sortFn">
                <div class="d-inline-block datatable-header-cell-wrapper" ngbTooltip="cc" container="body" [adaptivePosition]="false" placement="top">
                    <span class="datatable-header-cell-label draggable" (click)="sort()">cc</span>
                </div>
              </ng-template>
                <ng-template let-row="row" ngx-datatable-cell-template>
                   <ng-container *ngFor="let item of row.cc_emails">{{item}}</ng-container>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column *ngIf="type == 'BulkEmail'" prop="bcc" [width]="150">
              <ng-template ngx-datatable-header-template let-column="column" let-sort="sortFn">
                <div class="d-inline-block datatable-header-cell-wrapper" ngbTooltip="bcc" container="body" [adaptivePosition]="false" placement="top">
                    <span class="datatable-header-cell-label draggable" (click)="sort()">bcc</span>
                </div>
              </ng-template>
                <ng-template let-row="row" ngx-datatable-cell-template>
                  <ng-container *ngFor="let item of row.bcc_emails">{{item}}</ng-container>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column *ngIf="type == 'BulkEmail'" prop="subject" [width]="200">
              <ng-template ngx-datatable-header-template let-column="column" let-sort="sortFn">
                <div class="d-inline-block datatable-header-cell-wrapper" ngbTooltip="Subject" container="body" [adaptivePosition]="false" placement="top">
                    <span class="datatable-header-cell-label draggable" (click)="sort()">Subject</span>
                </div>
              </ng-template>
                <ng-template let-row="row" ngx-datatable-cell-template>
                    {{row.subject}}
                </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-column *ngIf="type == 'BulkEmail'" prop="content" [width]="200">
              <ng-template ngx-datatable-header-template let-column="column" let-sort="sortFn">
                <div class="d-inline-block datatable-header-cell-wrapper" ngbTooltip="Content" container="body" [adaptivePosition]="false" placement="top">
                    <span class="datatable-header-cell-label draggable" (click)="sort()">Content</span>
                </div>
              </ng-template>
                <ng-template let-row="row" ngx-datatable-cell-template>
                    <p [innerHTML]="row.content"></p>
                </ng-template>
            </ngx-datatable-column>
            
            
            <!-- Bulk Email Logs -->



<!-- //FINANCE_REFUNDS -->
<ngx-datatable-column *ngIf="type == 'FinanceRefund'" prop="id" [width]="100">
  <ng-template ngx-datatable-header-template let-column="column" let-sort="sortFn" >
    <div class="d-inline-block datatable-header-cell-wrapper" ngbTooltip="Refund ID" container="body" [adaptivePosition]="false" placement="top">
        <span class="datatable-header-cell-label draggable" (click)="sort()">Refund ID</span>
    </div>
  </ng-template>
    <ng-template let-row="row" let-rowIndex="rowIndex" ngx-datatable-cell-template>
      <a class="subid mouse-over" (click)="redirect(row.id,row.form_id,row.user_id,row.submission_id)"> {{row.payment_refund_id}} </a>
       
    </ng-template>
</ngx-datatable-column>

<ngx-datatable-column *ngIf="type == 'FinanceRefund'" prop="booking_id" [width]="100">
  <ng-template ngx-datatable-header-template let-column="column" let-sort="sortFn">
    <div class="d-inline-block datatable-header-cell-wrapper" ngbTooltip="Booking ID" container="body" [adaptivePosition]="false" placement="top">
        <span class="datatable-header-cell-label draggable" (click)="sort()">Booking ID</span>
    </div>
  </ng-template>
    <ng-template let-row="row" ngx-datatable-cell-template>
      {{row.booking_id}}     
    </ng-template>
</ngx-datatable-column>

<ngx-datatable-column *ngIf="type == 'FinanceRefund'" [sortable]="false" prop="final_status" [width]="250">
  <ng-template ngx-datatable-header-template let-column="column" let-sort="sortFn">
    <div class="d-inline-block datatable-header-cell-wrapper" ngbTooltip="Booking ID" container="body" [adaptivePosition]="false" placement="top">
        <span class="datatable-header-cell-label draggable" (click)="sort()">Status</span>
    </div>
  </ng-template>
    <ng-template let-row="row" ngx-datatable-cell-template>
      {{row.final_status}}       
    </ng-template>
</ngx-datatable-column>

<ngx-datatable-column *ngIf="type == 'FinanceRefund'" prop="name" [width]="150">
  <ng-template ngx-datatable-header-template let-column="column" let-sort="sortFn">
    <div class="d-inline-block datatable-header-cell-wrapper" ngbTooltip="Customer Name" container="body" [adaptivePosition]="false" placement="top">
        <span class="datatable-header-cell-label draggable" (click)="sort()">Customer Name</span>
    </div>
  </ng-template>
    <ng-template let-row="row" ngx-datatable-cell-template>
        {{row.customer_name}}
    </ng-template>
</ngx-datatable-column>

<ngx-datatable-column *ngIf="type == 'FinanceRefund'" prop="email" [width]="200">
  <ng-template ngx-datatable-header-template let-column="column">
    <div class="d-inline-block datatable-header-cell-wrapper" ngbTooltip="Customer Email" container="body" [adaptivePosition]="false" placement="top">
        <span class="datatable-header-cell-label draggable">Customer Email</span>
    </div>
  </ng-template>
    <ng-template let-row="row" ngx-datatable-cell-template>
        {{row.customer_email}}
    </ng-template>
</ngx-datatable-column>

<ngx-datatable-column *ngIf="type == 'FinanceRefund'" [sortable]="false" prop="mobile" [width]="150">
  <ng-template ngx-datatable-header-template let-column="column" let-sort="sortFn">
    <div class="d-inline-block datatable-header-cell-wrapper" ngbTooltip="Customer Mobile" container="body" [adaptivePosition]="false" placement="top">
        <span class="datatable-header-cell-label draggable" (click)="sort()">Customer mobile</span>
    </div>
  </ng-template>
    <ng-template let-row="row" ngx-datatable-cell-template>
        {{row.customer_mobile_code+'-'+row.customer_mobile}}
    </ng-template>
</ngx-datatable-column>

<ngx-datatable-column *ngIf="type == 'FinanceRefund'" [sortable]="false" prop="booking_date" [width]="200">
  <ng-template ngx-datatable-header-template let-column="column" let-sort="sortFn">
    <div class="d-inline-block datatable-header-cell-wrapper" ngbTooltip="Booking Date" container="body" [adaptivePosition]="false" placement="top">
        <span class="datatable-header-cell-label draggable" (click)="sort()">Booking Date</span>
    </div>
  </ng-template>
    <ng-template let-row="row" ngx-datatable-cell-template>
        {{row.booking_date}}
    </ng-template>
</ngx-datatable-column>

<ngx-datatable-column *ngIf="type == 'FinanceRefund'" [sortable]="false" prop="assigned_to" [width]="200">
  <ng-template ngx-datatable-header-template let-column="column" let-sort="sortFn">
    <div class="d-inline-block datatable-header-cell-wrapper" ngbTooltip="Assign To" container="body" [adaptivePosition]="false" placement="top">
        <span class="datatable-header-cell-label draggable" (click)="sort()">Assign To</span>
    </div>
  </ng-template>
    <ng-template let-row="row" ngx-datatable-cell-template>
      <!-- {{}} -->
        {{row.assigned_to}}
    </ng-template>
</ngx-datatable-column>

<ngx-datatable-column *ngIf="type == 'FinanceRefund'" [sortable]="false" prop="approval_l1_name" [width]="200">
  <ng-template ngx-datatable-header-template let-column="column" let-sort="sortFn">
    <div class="d-inline-block datatable-header-cell-wrapper" ngbTooltip="Manager Name" container="body" [adaptivePosition]="false" placement="top">
        <span class="datatable-header-cell-label draggable" (click)="sort()">Manager Name</span>
    </div>
  </ng-template>
    <ng-template let-row="row" ngx-datatable-cell-template>
      <!-- {{}} -->
        {{row.approval_l1}}
    </ng-template>
</ngx-datatable-column>

<ngx-datatable-column *ngIf="type == 'FinanceRefund'" [sortable]="false" prop="approval_l1_date" [width]="200">
  <ng-template ngx-datatable-header-template let-column="column" let-sort="sortFn">
    <div class="d-inline-block datatable-header-cell-wrapper" ngbTooltip="Manager Approval Date" container="body" [adaptivePosition]="false" placement="top">
        <span class="datatable-header-cell-label draggable" (click)="sort()">Manager Approval Date</span>
    </div>
  </ng-template>
    <ng-template let-row="row" ngx-datatable-cell-template>
        {{row.approval_l1_date}}
    </ng-template>
</ngx-datatable-column>

<ngx-datatable-column *ngIf="type == 'FinanceRefund'" [sortable]="false" prop="approval_l2_name" [width]="200">
  <ng-template ngx-datatable-header-template let-column="column" let-sort="sortFn">
    <div class="d-inline-block datatable-header-cell-wrapper" ngbTooltip="Officer-1 Name" container="body" [adaptivePosition]="false" placement="top">
        <span class="datatable-header-cell-label draggable" (click)="sort()">Officer-1 Name</span>
    </div>
  </ng-template>
    <ng-template let-row="row" ngx-datatable-cell-template>
      <!-- {{}} -->
        {{row.approval_l2}}
    </ng-template>
</ngx-datatable-column>

<ngx-datatable-column *ngIf="type == 'FinanceRefund'" [sortable]="false" prop="approval_l2_date" [width]="200">
  <ng-template ngx-datatable-header-template let-column="column" let-sort="sortFn">
    <div class="d-inline-block datatable-header-cell-wrapper" ngbTooltip="Officer-1 Approval Date" container="body" [adaptivePosition]="false" placement="top">
        <span class="datatable-header-cell-label draggable" (click)="sort()">Officer-1 Approval Date</span>
    </div>
  </ng-template>
    <ng-template let-row="row" ngx-datatable-cell-template>
        {{row.approval_l2_date}}
    </ng-template>
</ngx-datatable-column>

<ngx-datatable-column *ngIf="type == 'FinanceRefund'" [sortable]="false" prop="approval_l3_name" [width]="200">
  <ng-template ngx-datatable-header-template let-column="column" let-sort="sortFn">
    <div class="d-inline-block datatable-header-cell-wrapper" ngbTooltip="Officer-2 Name" container="body" [adaptivePosition]="false" placement="top">
        <span class="datatable-header-cell-label draggable" (click)="sort()">Officer-2 Name</span>
    </div>
  </ng-template>
    <ng-template let-row="row" ngx-datatable-cell-template>
      <!-- {{}} -->
        {{row.approval_l3}}
    </ng-template>
</ngx-datatable-column>

<ngx-datatable-column *ngIf="type == 'FinanceRefund'" [sortable]="false" prop="approval_l3_date" [width]="200">
  <ng-template ngx-datatable-header-template let-column="column" let-sort="sortFn">
    <div class="d-inline-block datatable-header-cell-wrapper" ngbTooltip="Officer-2 Approval Date" container="body" [adaptivePosition]="false" placement="top">
        <span class="datatable-header-cell-label draggable" (click)="sort()">Officer-2 Approval Date</span>
    </div>
  </ng-template>
    <ng-template let-row="row" ngx-datatable-cell-template>
        {{row.approval_l3_date}}
    </ng-template>
</ngx-datatable-column>


<ngx-datatable-column *ngIf="type == 'FinanceRefund'" [sortable]="false" prop="total_amount_paid" [width]="100">
  <ng-template ngx-datatable-header-template let-column="column" let-sort="sortFn">
    <div class="d-inline-block datatable-header-cell-wrapper" ngbTooltip="Total Amount Paid" container="body" [adaptivePosition]="false" placement="top">
        <span class="datatable-header-cell-label draggable" (click)="sort()">Total Amount Paid</span>
    </div>
  </ng-template>
    <ng-template let-row="row" ngx-datatable-cell-template>
        {{row.total_amount_paid}}
    </ng-template>
</ngx-datatable-column>

<ngx-datatable-column *ngIf="type == 'FinanceRefund'" [sortable]="false" prop="course_name" [width]="200">
  <ng-template ngx-datatable-header-template let-column="column" let-sort="sortFn">
    <div class="d-inline-block datatable-header-cell-wrapper" ngbTooltip="Course-name" container="body" [adaptivePosition]="false" placement="top">
        <span class="datatable-header-cell-label draggable" (click)="sort()">Course Name</span>
    </div>
  </ng-template>
    <ng-template let-row="row" ngx-datatable-cell-template>
        {{row.course_name}}
    </ng-template>
</ngx-datatable-column>

<ngx-datatable-column *ngIf="type == 'FinanceRefund'" prop="refund_amount" [width]="150">
  <ng-template ngx-datatable-header-template let-column="column" let-sort="sortFn">
    <div class="d-inline-block datatable-header-cell-wrapper" ngbTooltip="Refund-Amount" container="body" [adaptivePosition]="false" placement="top">
        <span class="datatable-header-cell-label draggable" (click)="sort()">Refund Amount</span>
    </div>
  </ng-template>
    <ng-template let-row="row" ngx-datatable-cell-template>
        {{row.refund_amount_requested}}
    </ng-template>
</ngx-datatable-column>

<ngx-datatable-column *ngIf="type == 'FinanceRefund'" prop="requested_by" [width]="200">
  <ng-template ngx-datatable-header-template let-column="column" let-sort="sortFn">
    <div class="d-inline-block datatable-header-cell-wrapper" ngbTooltip="Requested By" container="body" [adaptivePosition]="false" placement="top">
        <span class="datatable-header-cell-label draggable" (click)="sort()">Requested By</span>
    </div>
  </ng-template>
    <ng-template let-row="row" ngx-datatable-cell-template>
        {{row.requested_by}}
    </ng-template>
</ngx-datatable-column>

<ngx-datatable-column *ngIf="type == 'FinanceRefund'" prop="created_at" [width]="200">
  <ng-template ngx-datatable-header-template let-column="column">
    <div class="d-inline-block datatable-header-cell-wrapper" ngbTooltip="Requested Date" container="body" [adaptivePosition]="false" placement="top">
        <span class="datatable-header-cell-label draggable" (click)="sort()">Requested Date</span>
    </div>
  </ng-template>
    <ng-template let-row="row" ngx-datatable-cell-template>
        {{row.refund_requested_date}}
    </ng-template>
</ngx-datatable-column>

<ngx-datatable-column *ngIf="type == 'FinanceRefund'" [sortable]="false" prop="reason" [width]="200">
  <ng-template ngx-datatable-header-template let-column="column" let-sort="sortFn">
    <div class="d-inline-block datatable-header-cell-wrapper" ngbTooltip="Description" container="body" [adaptivePosition]="false" placement="top">
        <span class="datatable-header-cell-label draggable" (click)="sort()">Description</span>
    </div>
  </ng-template>
    <ng-template let-row="row" ngx-datatable-cell-template>
        {{row.reason}}
    </ng-template>
</ngx-datatable-column>

<ngx-datatable-column *ngIf="type == 'FinanceRefund'" [sortable]="false" prop="attachement" [width]="100">
  <ng-template ngx-datatable-header-template let-column="column" let-sort="sortFn">
    <div class="d-inline-block datatable-header-cell-wrapper" ngbTooltip="Refund Attachment" container="body" [adaptivePosition]="false" placement="top">
        <span class="datatable-header-cell-label draggable" (click)="sort()">Refund Attachment</span>
    </div>
  </ng-template>
    <ng-template let-row="row" ngx-datatable-cell-template>
      <p class="font-roboto" *ngIf="row.refund_file !='' " ><a target="_blank" href="{{row.refund_file}}"><i class="fa fa-file-pdf-o fa-4x"  aria-hidden="true"></i></a>
      </p>
    </ng-template>
</ngx-datatable-column>

            <!-- Slider List  -->


            <ngx-datatable-column *ngIf="type == 'LastBookings'" [width]="40">
              <ng-template ngx-datatable-header-template let-column="column" let-sort="sortFn">
                <div class="d-inline-block datatable-header-cell-wrapper" ngbTooltip="Submission ID" container="body" [adaptivePosition]="false" placement="top">
                    <span class="datatable-header-cell-label draggable" (click)="sort()">ID</span>
                </div>
              </ng-template>
              <ng-template let-row="row" ngx-datatable-cell-template>
                <a class="subid mouse-over" (click)="redirect(row.id,row.form_id,row.user_id,row.submission_id)">#{{row.submission_id ==null? "0":row.submission_id}} </a>
              </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-column *ngIf="type == 'LastBookings'" [width]="200">
              <ng-template ngx-datatable-header-template let-column="column" let-sort="sortFn">
                <div class="d-inline-block datatable-header-cell-wrapper" ngbTooltip="User Name" container="body" [adaptivePosition]="false" placement="top">
                    <span class="datatable-header-cell-label draggable" (click)="sort()">Name</span>
                </div>
              </ng-template>
              <ng-template let-row="row" ngx-datatable-cell-template>
                {{row.user_name}}
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column *ngIf="type == 'LastBookings'" [width]="120">
              <ng-template ngx-datatable-header-template let-column="column" let-sort="sortFn">
                <div class="d-inline-block datatable-header-cell-wrapper" ngbTooltip="User Email" container="body" [adaptivePosition]="false" placement="top">
                    <span class="datatable-header-cell-label draggable" (click)="sort()">Email</span>
                </div>
              </ng-template>
              <ng-template let-row="row" ngx-datatable-cell-template>
                {{row.user_email}}
              </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-column *ngIf="type == 'LastBookings'" [width]="120">
              <ng-template ngx-datatable-header-template let-column="column" let-sort="sortFn">
                <div class="d-inline-block datatable-header-cell-wrapper" ngbTooltip="User Type" container="body" [adaptivePosition]="false" placement="top">
                    <span class="datatable-header-cell-label draggable" (click)="sort()">Type</span>
                </div>
              </ng-template>
              <ng-template let-row="row" ngx-datatable-cell-template>
                {{row.user_type}}
              </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-column *ngIf="type == 'LastBookings'" [width]="100">
              <ng-template ngx-datatable-header-template let-column="column" let-sort="sortFn">
                <div class="d-inline-block datatable-header-cell-wrapper" ngbTooltip="Form Name" container="body" [adaptivePosition]="false" placement="top">
                    <span class="datatable-header-cell-label draggable" (click)="sort()">Form Name</span>
                </div>
              </ng-template>
              <ng-template let-row="row" ngx-datatable-cell-template>
                {{row.form_name}}
              </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-column *ngIf="type == 'LastBookings'" [width]="100">
              <ng-template ngx-datatable-header-template let-column="column" let-sort="sortFn">
                <div class="d-inline-block datatable-header-cell-wrapper" ngbTooltip="Form Type" container="body" [adaptivePosition]="false" placement="top">
                    <span class="datatable-header-cell-label draggable" (click)="sort()">Form Type</span>
                </div>
              </ng-template>
              <ng-template let-row="row" ngx-datatable-cell-template>
                {{row.form_type}}
              </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-column *ngIf="type == 'LastBookings'" [width]="100">
              <ng-template ngx-datatable-header-template let-column="column" let-sort="sortFn">
                <div class="d-inline-block datatable-header-cell-wrapper" ngbTooltip="Total Amount Paid" container="body" [adaptivePosition]="false" placement="top">
                    <span class="datatable-header-cell-label draggable" (click)="sort()">Amount</span>
                </div>
              </ng-template>
              <ng-template let-row="row" ngx-datatable-cell-template>
                {{row.total_amount_paid}}
              </ng-template>
            </ngx-datatable-column>


            <ngx-datatable-column *ngIf="type == 'HomepageSlider'" name="Title" [width]="200">
              <ng-template let-row="row" ngx-datatable-cell-template>
                  {{row.title}}
              </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-column *ngIf="type == 'HomepageSlider'" name="Description" [width]="200">
              <ng-template let-row="row" ngx-datatable-cell-template>
                  <span [innerHTML]="row.description"></span>
              </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-column *ngIf="type == 'HomepageSlider'" prop='button_text' name="Button Text" [width]="100">
              <ng-template let-row="row" ngx-datatable-cell-template>
                  {{row.button_text}}
              </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-column *ngIf="type == 'HomepageSlider'" prop='button_url' name="Button URL" [width]="150">
              <ng-template let-row="row" ngx-datatable-cell-template>
                  {{row.button_url}}
              </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-column *ngIf="type == 'HomepageSlider'"  name="Slider Image" [width]="200" [sortable]="false">
              <ng-template let-row="row" ngx-datatable-cell-template>
                <img class="banner_img" [src]="row.image"/>
              </ng-template>
            </ngx-datatable-column>
            

            <!-- Pages List  -->
            <ngx-datatable-column *ngIf="type == 'Pages'" prop="title"  name="Title" [width]="200">
              <ng-template let-row="row" ngx-datatable-cell-template>
                  {{row.title}}
              </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-column *ngIf="type == 'Pages'" prop="allies_name" name="Allies Name" [width]="200">
              <ng-template let-row="row" ngx-datatable-cell-template>
                  {{row.allies_name}}
              </ng-template>
            </ngx-datatable-column>
          
            <ngx-datatable-column *ngIf="type == 'FormSubmission'|| type == 'BookUser' || type =='AllBook'" prop='id' [width]="80">
              <ng-template ngx-datatable-header-template let-column="column" let-sort="sortFn">
                <div class="d-inline-block datatable-header-cell-wrapper" ngbTooltip="Booking/Submission ID" container="body" [adaptivePosition]="false" placement="top" >
                    <span class="datatable-header-cell-label draggable" (click)="sort()">ID</span>
                </div>
              </ng-template>
              <ng-template let-row="row" ngx-datatable-cell-template>
                <a class="subid mouse-over" (click)="redirect(row.id,row.form_id,row.user_id,row.submission_id)">#{{row.submission_id ==null? "0":row.submission_id}} </a> 
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column *ngIf="type == 'BookUser' || type =='AllBook'" prop='first_name' [width]="80">
              <ng-template ngx-datatable-header-template let-column="column" let-sort="sortFn">
                <div class="d-inline-block datatable-header-cell-wrapper" ngbTooltip="Full Name" container="body" [adaptivePosition]="false" placement="top">
                    <span class="datatable-header-cell-label draggable" (click)="sort()">Name</span>
                </div>
              </ng-template>
              <ng-template let-row="row" ngx-datatable-cell-template>
                  <!-- {{row.user_name}}  -->
                  {{row.user_first_name}} {{row.user_last_name}}
                <!-- <div class='subid' (click)="redirect(row.id,row.form_id)">{{row.submission_id ==null? "0":row.submission_id}} </div>  -->
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column *ngIf="type == 'FormSubmission'" prop='first_name' [width]="130">
              <ng-template ngx-datatable-header-template let-column="column" let-sort="sortFn">
                <div class="d-inline-block datatable-header-cell-wrapper" ngbTooltip="Customer/Dependent First Name" container="body" [adaptivePosition]="false" placement="top">
                    <span class="datatable-header-cell-label draggable" (click)="sort()">First Name</span>
                </div>
              </ng-template>
              <ng-template let-row="row" ngx-datatable-cell-template>
                {{row.user_first_name}}
              </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-column *ngIf="type == 'FormSubmission'" prop='last_name' [width]="130">
              <ng-template ngx-datatable-header-template let-column="column" let-sort="sortFn">
                <div class="d-inline-block datatable-header-cell-wrapper" ngbTooltip="Customer/Dependent Last Name" container="body" [adaptivePosition]="false" placement="top">
                    <span class="datatable-header-cell-label draggable" (click)="sort()">Last Name</span>
                </div>
              </ng-template>
              <ng-template let-row="row" ngx-datatable-cell-template>
                {{row.user_last_name}}
              </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-column *ngIf="type == 'FormSubmission' || type == 'BookUser' || type =='AllBook'" prop='email' [width]="200">
              <ng-template ngx-datatable-header-template let-column="column" let-sort="sortFn">
                <div class="d-inline-block datatable-header-cell-wrapper" ngbTooltip="Customer Email" container="body" [adaptivePosition]="false" placement="top">
                    <span class="datatable-header-cell-label draggable" (click)="sort()">Email</span>
                </div>
              </ng-template>
              <ng-template let-row="row" ngx-datatable-cell-template>
                {{row.user_email}}
              </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-column *ngIf="type == 'FormSubmission' || type == 'BookUser' || type =='AllBook'" prop='user_type' [width]="120">
              <ng-template ngx-datatable-header-template let-column="column" let-sort="sortFn">
                <div class="d-inline-block datatable-header-cell-wrapper" ngbTooltip="Customer Type" container="body" [adaptivePosition]="false" placement="top">
                    <span class="datatable-header-cell-label draggable" (click)="sort()">Type</span>
                </div>
              </ng-template>
              <ng-template let-row="row" ngx-datatable-cell-template>
                {{row.user_type}}
              </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-column *ngIf="type == 'FormSubmission' || type == 'BookUser' || type =='AllBook'" [sortable]="false" [width]="100">
              <ng-template ngx-datatable-header-template let-column="column" let-sort="sortFn">
                <div class="d-inline-block datatable-header-cell-wrapper" ngbTooltip="Main User/Dependent" container="body" [adaptivePosition]="false" placement="top">
                    <span class="datatable-header-cell-label draggable" (click)="sort()">Main User</span>
                </div>
              </ng-template>
              <ng-template let-row="row" ngx-datatable-cell-template>
                {{row.main}}
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column *ngIf="type =='AllBook' || type == 'BookUser'" [sortable]="false" [width]="200">
              <ng-template ngx-datatable-header-template let-column="column" let-sort="sortFn">
                <div class="d-inline-block datatable-header-cell-wrapper" ngbTooltip="Form Internal Name" container="body" [adaptivePosition]="false" placement="top">
                    <span class="datatable-header-cell-label draggable" (click)="sort()">Form Internal Name</span>
                </div>
              </ng-template>
              <ng-template let-row="row" ngx-datatable-cell-template>
                {{row?.form_info?.form_internal_name}}
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column *ngIf="type =='AllBook' || type == 'BookUser'" [sortable]="false" [width]="200">
              <ng-template ngx-datatable-header-template let-column="column" let-sort="sortFn">
                <div class="d-inline-block datatable-header-cell-wrapper" ngbTooltip="Form Business Name" container="body" [adaptivePosition]="false" placement="top">
                    <span class="datatable-header-cell-label draggable" (click)="sort()">Form Business Name</span>
                </div>
              </ng-template>
              <ng-template let-row="row" ngx-datatable-cell-template>
                {{row?.form_info?.form_business_name}}
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column *ngIf="type == 'FormSubmission' || type == 'BookUser' || type =='AllBook'" prop="phone_number" [width]="140">
              <ng-template ngx-datatable-header-template let-column="column" let-sort="sortFn">
                <div class="d-inline-block datatable-header-cell-wrapper" ngbTooltip="Customer Mobile No." container="body" [adaptivePosition]="false" placement="top">
                    <span class="datatable-header-cell-label draggable" (click)="sort()">Mobile No.</span>
                </div>
              </ng-template>
              <ng-template let-row="row" ngx-datatable-cell-template>
                {{row.phone_number}}
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column *ngIf="(type == 'FormSubmission' || type == 'BookUser' || type =='AllBook') && book_id !='votes-list' " prop="created_at" [width]="180">
              <ng-template ngx-datatable-header-template let-column="column" let-sort="sortFn">
                <div class="d-inline-block datatable-header-cell-wrapper" ngbTooltip="Category" container="body" [adaptivePosition]="false" placement="top">
                    <span class="datatable-header-cell-label draggable" (click)="sort()">Category</span>
                </div>
              </ng-template>
              <ng-template let-row="row" ngx-datatable-cell-template>
                <span *ngIf="row?.form_info?.category_name!=''">
                  {{row?.form_info?.category_name}}/{{row?.form_info?.sub_category_name
                  }}<span *ngIf="row?.form_info?.sub_sub_category_name!=''">/</span>{{row?.form_info?.sub_sub_category_name}}

                </span>
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column *ngIf="type == 'FormSubmission' || type == 'BookUser' || type =='AllBook'" prop="created_at" [width]="180">
              <ng-template ngx-datatable-header-template let-column="column" let-sort="sortFn">
                <div class="d-inline-block datatable-header-cell-wrapper" ngbTooltip="Booking Date" container="body" [adaptivePosition]="false" placement="top">
                    <span class="datatable-header-cell-label draggable" (click)="sort()">Booking Date</span>
                </div>
              </ng-template>
              <ng-template let-row="row" ngx-datatable-cell-template>
                {{row.booking_date}}
              </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-column *ngIf="type == 'BookUser' || type =='AllBook' && book_id !='votes-list'" [width]="100">
              <ng-template ngx-datatable-header-template let-column="column" let-sort="sortFn">
                <div class="d-inline-block datatable-header-cell-wrapper" ngbTooltip="Payment Status" container="body" [adaptivePosition]="false" placement="top">
                    <span class="datatable-header-cell-label draggable" (click)="sort()">Payment</span>
                </div>
              </ng-template>
              <ng-template let-row="row" ngx-datatable-cell-template>
                {{row.payment_status}}
              </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-column *ngIf="type == 'FormSubmission'" [width]="100">
              <ng-template ngx-datatable-header-template let-column="column" let-sort="sortFn">
                <div class="d-inline-block datatable-header-cell-wrapper" ngbTooltip="Paid/Unpaid Form" container="body" [adaptivePosition]="false" placement="top">
                    <span class="datatable-header-cell-label draggable" (click)="sort()">Paid/Unpaid</span>
                </div>
              </ng-template>
              <ng-template let-row="row" ngx-datatable-cell-template>
                {{row.payment_status}}
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column *ngIf="type == 'FormSubmission' || type == 'BookUser' || type =='AllBook'&& book_id !='votes-list'" [sortable]="false"[width]="125">
              <ng-template ngx-datatable-header-template let-column="column" let-sort="sortFn">
                <div class="d-inline-block datatable-header-cell-wrapper" ngbTooltip="Total Amount" container="body" [adaptivePosition]="false" placement="top">
                    <span class="datatable-header-cell-label draggable" (click)="sort()">Total Amount</span>
                </div>
              </ng-template>
              <ng-template let-row="row" ngx-datatable-cell-template>
                <!-- {{row.booking_form_price}} -->
                {{ row?.additional_payments?.length > 0 ? (row?.additional_payments[0]?.additional_amount | currency:'QAR ') : row.total_amount }}
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column *ngIf="type == 'FormSubmission' || type == 'BookUser' || type =='AllBook'&& book_id !='votes-list'" [sortable]="false"[width]="125">
              <ng-template ngx-datatable-header-template let-column="column" let-sort="sortFn">
                <div class="d-inline-block datatable-header-cell-wrapper" ngbTooltip="Discount Fee" container="body" [adaptivePosition]="false" placement="top">
                    <span class="datatable-header-cell-label draggable" (click)="sort()">Discount Fee</span>
                </div>
              </ng-template>
              <ng-template let-row="row" ngx-datatable-cell-template>
                {{row.discount_fee}}
              </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-column *ngIf="type == 'FormSubmission' || type == 'BookUser' || type =='AllBook'&& book_id !='votes-list'" [width]="130">
              <ng-template ngx-datatable-header-template let-column="column" let-sort="sortFn">
                <div class="d-inline-block datatable-header-cell-wrapper" ngbTooltip="Total Amount Paid" container="body" [adaptivePosition]="false" placement="top">
                    <span class="datatable-header-cell-label draggable" (click)="sort()">Amount Paid</span>
                </div>
              </ng-template>
              <ng-template let-row="row" ngx-datatable-cell-template>
                {{row.total_amount_paid}}
                <!-- {{row.total_amount}} -->
              </ng-template>
            </ngx-datatable-column>


            <ngx-datatable-column *ngIf="type == 'FormSubmission' || type == 'BookUser' || type =='AllBook'&& book_id !='votes-list'"[sortable]="false" [width]="125">
              <ng-template ngx-datatable-header-template let-column="column" let-sort="sortFn">
                <div class="d-inline-block datatable-header-cell-wrapper" ngbTooltip="Total Transactions" container="body" [adaptivePosition]="false" placement="top">
                    <span class="datatable-header-cell-label draggable" (click)="sort()">Transactions</span>
                </div>
              </ng-template>
              <ng-template let-row="row" ngx-datatable-cell-template>
                {{row.total_transactions}}
              </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-column *ngIf="type == 'FormSubmission' || type == 'BookUser' || type =='AllBook'&& book_id !='votes-list'" [sortable]="false" [width]="100">
              <ng-template ngx-datatable-header-template let-column="column" let-sort="sortFn">
                <div class="d-inline-block datatable-header-cell-wrapper" ngbTooltip="Discount" container="body" [adaptivePosition]="false" placement="top">
                    <span class="datatable-header-cell-label draggable" (click)="sort()">Discount</span>
                </div>
              </ng-template>
              <ng-template let-row="row" ngx-datatable-cell-template>
                {{row.discount_fee=="QAR 0.00"? 'NO' :'YES'}}
              </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-column *ngIf="type == 'FormSubmission'" [sortable]="false" [width]="120">
              <ng-template ngx-datatable-header-template let-column="column" let-sort="sortFn">
                <div class="d-inline-block datatable-header-cell-wrapper" ngbTooltip="Form Category" container="body" [adaptivePosition]="false" placement="top">
                    <span class="datatable-header-cell-label draggable" (click)="sort()">Category</span>
                </div>
              </ng-template>
              <ng-template let-row="row" ngx-datatable-cell-template>
                <!-- {{row.form_category}} -->
                <span *ngIf="row.parent_category != ''">{{row.parent_category}}</span><span *ngIf="row.sub_category != ''">/{{row.sub_category}}</span><span *ngIf="row.sub_sub_category != ''">/{{row.sub_sub_category}}</span>

              </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-column *ngIf="type == 'FormSubmission'" prop="is_public" [width]="100">
              <ng-template ngx-datatable-header-template let-column="column" let-sort="sortFn">
                <div class="d-inline-block datatable-header-cell-wrapper" ngbTooltip="Form Privacy" container="body" [adaptivePosition]="false" placement="top">
                    <span class="datatable-header-cell-label draggable" (click)="sort()">Privacy</span>
                </div>
              </ng-template>
              <ng-template let-row="row" ngx-datatable-cell-template>
                {{row.is_public == '0' ? 'Private' : 'Public' }}
              </ng-template>
            </ngx-datatable-column>

            <!-- <ngx-datatable-column *ngIf="type == 'FormSubmission'" name="Form Type" [width]="200">
              <ng-template let-row="row" ngx-datatable-cell-template>
                  {{row.form_type}}
              </ng-template>
            </ngx-datatable-column> -->

            <ngx-datatable-column *ngIf="type == 'FormSubmission'" prop="age" [width]="80">
              <ng-template ngx-datatable-header-template let-column="column" let-sort="sortFn">
                <div class="d-inline-block datatable-header-cell-wrapper" ngbTooltip="Age" container="body" [adaptivePosition]="false" placement="top">
                    <span class="datatable-header-cell-label draggable" (click)="sort()">Age</span>
                </div>
              </ng-template>
              <ng-template let-row="row" ngx-datatable-cell-template>
                {{row.age}}
              </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-column *ngIf="type == 'FormSubmission'" prop="gender" [width]="100">
              <ng-template ngx-datatable-header-template let-column="column" let-sort="sortFn">
                <div class="d-inline-block datatable-header-cell-wrapper" ngbTooltip="Gender" container="body" [adaptivePosition]="false" placement="top">
                    <span class="datatable-header-cell-label draggable" (click)="sort()">Gender</span>
                </div>
              </ng-template>
              <ng-template let-row="row" ngx-datatable-cell-template>
                {{row.gender.charAt(0).toUpperCase() + row.gender.slice(1)}}
              </ng-template>
            </ngx-datatable-column>


            <ngx-datatable-column *ngIf="type == 'FormSubmission'" prop="nationality" [width]="120">
              <ng-template ngx-datatable-header-template let-column="column" let-sort="sortFn">
                <div class="d-inline-block datatable-header-cell-wrapper" ngbTooltip="Nationality" container="body" [adaptivePosition]="false" placement="top">
                    <span class="datatable-header-cell-label draggable" (click)="sort()">Nationality</span>
                </div>
              </ng-template>
              <ng-template let-row="row" ngx-datatable-cell-template>
                {{row.nationality_country}}
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column *ngIf="type == 'FormSubmission'" prop="form_data" [width]="120">
              <ng-template ngx-datatable-header-template let-column="column" let-sort="sortFn">
                <div class="d-inline-block datatable-header-cell-wrapper" ngbTooltip="Form Data" container="body" [adaptivePosition]="false" placement="top">
                    <span class="datatable-header-cell-label draggable" (click)="sort()">Form Data</span>
                </div>
              </ng-template>
              <ng-template let-row="row" ngx-datatable-cell-template>
                <!-- {{row.form_data.form_break}} -->
                <ng-container *ngIf="row.form_data.form_break">
                <ng-container *ngFor="let item of row.form_data.attributes">
    
                  <ng-container *ngFor="let ite of item; index as j">
                    <ng-container *ngIf="j==0">
                      <span class="d-block mb-3"><b>{{ite.pageTitle == '' ? 'Page'+ ite.index:ite.pageTitle}}</b></span>
                    </ng-container>
                  </ng-container>
                    <ng-container class="d-flex flex-column ml-3" *ngFor="let ite of item; index as j">
                      <ng-container *ngIf="j>=1">
                        <li class="mb-3">
                          <span *ngIf="ite.type!='group' && ite.type !='checkbox' && ite.type !='file' && ite.type !='radio'&&ite.type != 'address-group'&& ite.type !='likert' && ite.type !='autocomplete'"><b>{{ite.label}} :</b> {{ite.value == '' ? 'N/A' : ite.value}}</span>
                          <span *ngIf=" ite.type =='file' ">
                            <b>{{ite.label}} :</b> 
                            <ng-container *ngFor="let it of ite.values">
                              <ng-container *ngIf="ite.values.length>0">
                                <div class="reviewFile">
                                  <img alt="" [src]="it" />
                                </div>
                            </ng-container>
                              <ng-container *ngIf="ite.values.length==0">
                                <h4>{{ ite.label }}</h4>
                                <p>NA</p>
                              </ng-container>
                            </ng-container>
                          </span>
                          <span *ngIf="ite.type=='checkbox' || ite.type=='radio' || ite.type=='autocomplete' ">
                            <span *ngFor="let it of ite.values">
                              <ng-container *ngIf="it.value == ite.value"><b>{{ite.label}} :</b> {{it.label}}</ng-container>
                            </span>
                          </span>
                          <ng-container *ngIf="ite.type=='group'">
                            <li>
                              <span><b>{{ite.label}}</b></span>
                            </li>
                            <ul class="pageBreakData" *ngFor="let it of ite.groupFields">
                              <li class="mb-3"  *ngIf="it.type!='checkbox' && it.type!='radio' && it.type!='autocomplete'&& it.type!='file' &&it.type != 'address-group'&& it.type !='likert' "><b>{{it.label}} :</b> {{it.value == '' ? 'N/A' : it.value}}</li>
                              <ng-container *ngIf="it.type=='checkbox' || it.type=='radio' || it.type=='autocomplete' " >
                                <span *ngFor="let itee of it.values;let k =index">
                                  <li class="mb-3" *ngIf="it.value == itee.value"><b>{{it.label}} :</b> {{ itee.value == it.value? itee.label: 'N/A'}}</li>
                                  <li class="mb-3" *ngIf="it.value =='' && k ==0 "><b>{{it.label}} :</b> N/A</li>
                                </span>
                              </ng-container>
                              <span *ngIf=" it.type =='file' ">
                                <b>{{it.label}} :</b> 
                                <ng-container *ngFor="let it2 of it.values">
                                  <ng-container *ngIf="it.values.length>0">
                                    <div class="reviewFile">
                                      <img alt="" [src]="it2" />
                                    </div>
                                </ng-container>
                                  <ng-container *ngIf="it.values.length==0">
                                    <h4>{{ it.label }}</h4>
                                    <p>NA</p>
                                  </ng-container>
                                </ng-container>
                              </span>
                              <ng-container *ngIf="it.type == 'likert'">
                                <b>{{ it.label }}</b>
                                <ng-container *ngFor="let likertitem of it.QuestionArr">
                                  <ng-container *ngIf="likertitem.value != ''">
                                    <spam>{{ likertitem.Question }}</spam>
                                    <ng-container *ngFor="let ans of likertitem.values">
                                      <p *ngIf="ans.value === likertitem.value">
                                        {{ ans.label }}
                                      </p>
                                    </ng-container>
                                  </ng-container>
                                </ng-container>
                              </ng-container>
                              <ng-container *ngIf="it.type == 'address-group'">
                                <b>{{it.label}}</b>
                                <ng-container *ngFor="let addr of it.addressGroup">
                                  <ng-container *ngIf="addr.value != ''">
                                      <spam>{{ addr.label }}</spam>
                                      <p>{{ addr.value }}</p>
                                  </ng-container>
                                </ng-container>
                              </ng-container>
                            </ul>
                          </ng-container>
                          <ng-container *ngIf="ite.type == 'likert'">
                            <b>{{ ite.label }}</b>
                            <ng-container *ngFor="let likertitem of ite.QuestionArr">
                              <ng-container *ngIf="likertitem.value != ''">
                                <spam>{{ likertitem.Question }}</spam>
                                <ng-container *ngFor="let ans of likertitem.values">
                                  <p *ngIf="ans.value === likertitem.value">
                                    {{ ans.label }}
                                  </p>
                                </ng-container>
                              </ng-container>
                            </ng-container>
                          </ng-container>
                          <ng-container *ngIf="ite.type == 'address-group'">
                            <b>{{ite.label}}</b>
                            <ng-container *ngFor="let addr of ite.addressGroup">
                              <ng-container *ngIf="addr.value != ''">
                                  <spam>{{ addr.label }}</spam>
                                  <p>{{ addr.value }}</p>
                              </ng-container>
                            </ng-container>
                          </ng-container>
                        </li>
                      </ng-container>
                    </ng-container>
              </ng-container>
              </ng-container>
              <ng-container *ngIf="!row.form_data.form_break">
                <ng-container *ngFor="let item of row.form_data.attributes">
                <li class="mb-3">
                  <span *ngIf="item.type!='group'&& item.type !='checkbox' && item.type !='file' && item.type !='radio'&& item.type !='address-group' && item.type !='autocomplete'&& item.type!='likert'"><b>{{item.label}} :</b> {{item.value == '' ? 'N/A' : item.value}}</span>
                  <span *ngIf="item.type=='checkbox' || item.type=='radio' || item.type=='autocomplete' ">
                    <span *ngFor="let ite of item.values">
                      <ng-container *ngIf="ite.value == item.value"><b>{{item.label}} :</b> {{ite.label}}</ng-container>
                    </span>
                  </span>
                  <span *ngIf=" item.type =='file' ">
                    <b>{{item.label}} :</b> 
                    <!-- {{ite.value == '' ? 'N/A' : ite.value}} -->
                    <ng-container *ngFor="let it of item.values">
                      <ng-container *ngIf="item.values.length>0">
                        <div class="reviewFile">
                          <img alt="" [src]="it" />
                        </div>
                    </ng-container>
                    <ng-container *ngIf="item.values.length==0">
                      <h4>{{ item.label }}</h4>
                        <!-- <img alt=""  [src]="it" /> -->
                        <p>NA</p>
                      </ng-container>
                    </ng-container>
                  </span>
                  <ng-container *ngIf="item.type=='group'">
                    <li>
                      <span><b>{{item.label}}</b></span>
                    </li>
                    <ul class="pageBreakData" *ngFor="let ite of item.groupFields">
                      <li class="mb-3" *ngIf=" ite.type !='checkbox' && ite.type !='radio' && ite.type !='autocomplete'&&ite.type != 'address-group'&& ite.type !='file' && ite.type!='likert'"><b>{{ite.label}} :</b> {{ite.value == '' ? 'N/A' : ite.value}}</li>
                    <ng-container *ngIf="ite.type=='checkbox' || ite.type=='radio' || ite.type=='autocomplete' " >
                      <span *ngFor="let it of ite.values;let k =index">
                        <li class="mb-3" *ngIf="ite.value == it.value"><b>{{ite.label}} :</b> {{ ite.value == it.value? it.label: 'N/A'}}</li>
                        <li class="mb-3" *ngIf="ite.value =='' && k ==0 "><b>{{ite.label}} :</b> N/A</li>
                      </span>
                    </ng-container>
                    <span *ngIf=" ite.type =='file' ">
                      <b>{{ite.label}} :</b> 
                      <!-- {{ite.value == '' ? 'N/A' : ite.value}} -->
                      <ng-container *ngFor="let it2 of ite.values">
                        <ng-container *ngIf="ite.values.length>0">
                          <div class="reviewFile">
                            <img alt="" [src]="it2" />
                          </div>
                        </ng-container>
                        <ng-container *ngIf="ite.values.length==0">
                          <h4>{{ ite.label }}</h4>
                          <!-- <img alt=""  [src]="it" /> -->
                          <p>NA</p>
                        </ng-container>
                      </ng-container>
                    </span>
                    <ng-container *ngIf="ite.type == 'likert'">
                      <b>{{ ite.label }}</b>
                      <ng-container *ngFor="let likertitem of ite.QuestionArr">
                        <ng-container *ngIf="likertitem.value != ''">
                          <b>{{ likertitem.Question }}</b>
                          <ng-container *ngFor="let ans of likertitem.values">
                            <p *ngIf="ans.value === likertitem.value">
                              {{ ans.label }}
                            </p>
                          </ng-container>
                        </ng-container>
                      </ng-container>
                    </ng-container>
                    <ng-container *ngIf="ite.type == 'address-group'">
                      <b>{{ite.label}}</b>
                      <ng-container *ngFor="let addr of ite.addressGroup">
                        <ng-container *ngIf="addr.value != ''">
                            <spam>{{ addr.label }}</spam>
                            <p>{{ addr.value }}</p>
                        </ng-container>
                      </ng-container>
                    </ng-container>
                  </ul>
                  </ng-container>
                  <ng-container *ngIf="item.type == 'address-group'">
                    <b>{{item.label}}</b>
                    <ng-container *ngFor="let addr of item.addressGroup">
                      <ng-container *ngIf="addr.value != ''">
                          <spam>{{ addr.label }}</spam>
                          <p>{{ addr.value }}</p>
                      </ng-container>
                    </ng-container>
                  </ng-container>
                  <ng-container *ngIf="item.type == 'likert'">
                    <b>{{ item.label }}</b>
                    <ng-container *ngFor="let likertitem of item.QuestionArr">
                      <ng-container *ngIf="likertitem.value != ''">
                        <b>{{ likertitem.Question }}</b>
                        <ng-container *ngFor="let ans of likertitem.values">
                          <p *ngIf="ans.value === likertitem.value">
                            {{ ans.label }}
                          </p>
                        </ng-container>
                      </ng-container>
                    </ng-container>
                  </ng-container>
                </li>
              </ng-container>
              </ng-container>
              </ng-template>
            </ngx-datatable-column>
            <!-- <ng-container *ngFor="let item of list; index as j">
              <ng-container *ngFor="let ite of item.form_data.attributes">
                <ng-container *ngFor="let it of ite">
            <ngx-datatable-column *ngIf=" it.type !='break'"  [width]="120">
              <ng-template ngx-datatable-header-template let-column="column" *ngIf=" j ==" let-sort="sortFn" >
                <div class="d-inline-block datatable-header-cell-wrapper" ngbTooltip="Nationality" container="body" [adaptivePosition]="false" placement="top">
                    <span class="datatable-header-cell-label draggable" (click)="sort()">{{it.label}}{{j}}
                    </span>
                </div>
              </ng-template>
              <ng-template let-row="row" let-rowIndex="rowIndex"ngx-datatable-cell-template>
                  {{it.value}}{{rowIndex}} 


              </ng-template>
            </ngx-datatable-column>
          </ng-container>
        </ng-container>
        </ng-container> -->
        <!-- ======================================== -->
             <!-- <ng-container *ngFor="let item of list; index as j"> -->
              <!-- <ng-container *ngFor="let ite of item.form_data.attributes"> -->
                <ng-container *ngFor="let it of DynamicColumms;index as j ">
                  <!-- <ng-template  let-row="row" let-rowIndex="rowIndex"> -->
            <ngx-datatable-column *ngIf=" it.type !='break'"  [width]="120">
              <!-- <ng-template ngx-datatable-header-template let-column="column"  let-sort="sortFn" > -->
                <ng-template ngx-datatable-header-template let-column="column"  let-sort="sortFn" >
                <div class="d-inline-block datatable-header-cell-wrapper" ngbTooltip="Nationality" container="body" [adaptivePosition]="false" placement="top">
                    <span class="datatable-header-cell-label draggable" (click)="sort()">{{it.label}}{{it.indexing}}
                    </span>
                </div>
              </ng-template>
              <ng-template let-row="row" let-rowIndex="rowIndex"ngx-datatable-cell-template>
                <ng-container *ngIf="rowIndex == it.indexing" >
                  {{it.value}}{{rowIndex}} 

                </ng-container>


              </ng-template>
            </ngx-datatable-column>
          <!-- </ng-container> -->
        <!-- </ng-container> -->
      <!-- </ng-template> -->
        </ng-container>
<!-- //voting form -->
<ngx-datatable-column *ngIf="type == 'VotingForm'" prop="first_name" [width]="80">
  <ng-template ngx-datatable-header-template let-column="column" let-sort="sortFn">
    <div class="d-inline-block datatable-header-cell-wrapper" ngbTooltip="first_name" container="body" [adaptivePosition]="false" placement="top">
        <span class="datatable-header-cell-label draggable" (click)="sort()">First Name</span>
    </div>
  </ng-template>
  <ng-template let-row="row" ngx-datatable-cell-template>
    {{row.first_name}}
  </ng-template>
</ngx-datatable-column>
<ngx-datatable-column *ngIf="type == 'VotingForm'" prop="last_name" [width]="80">
  <ng-template ngx-datatable-header-template let-column="column" let-sort="sortFn">
    <div class="d-inline-block datatable-header-cell-wrapper" ngbTooltip="last_name" container="body" [adaptivePosition]="false" placement="top">
        <span class="datatable-header-cell-label draggable" (click)="sort()">Last Name</span>
    </div>
  </ng-template>
  <ng-template let-row="row" ngx-datatable-cell-template>
    {{row.last_name}}
  </ng-template>
</ngx-datatable-column>
<ngx-datatable-column *ngIf="type == 'VotingForm'" prop="to" [width]="80">
  <ng-template ngx-datatable-header-template let-column="column" let-sort="sortFn">
    <div class="d-inline-block datatable-header-cell-wrapper" ngbTooltip="to" container="body" [adaptivePosition]="false" placement="top">
        <span class="datatable-header-cell-label draggable" (click)="sort()">To</span>
    </div>
  </ng-template>
  <ng-template let-row="row" ngx-datatable-cell-template>
    {{row.to}}
  </ng-template>
</ngx-datatable-column>

<ngx-datatable-column *ngIf="type == 'VotingForm'" prop="subject" [width]="80">
  <ng-template ngx-datatable-header-template let-column="column" let-sort="sortFn">
    <div class="d-inline-block datatable-header-cell-wrapper" ngbTooltip="subject" container="body" [adaptivePosition]="false" placement="top">
        <span class="datatable-header-cell-label draggable" (click)="sort()">subject</span>
    </div>
  </ng-template>
  <ng-template let-row="row" ngx-datatable-cell-template>
    {{row.subject}}
  </ng-template>
</ngx-datatable-column>

<!-- <ngx-datatable-column *ngIf="type == 'VotingForm'" prop="content" [width]="80">
  <ng-template ngx-datatable-header-template let-column="column" let-sort="sortFn">
    <div class="d-inline-block datatable-header-cell-wrapper" ngbTooltip="content" container="body" [adaptivePosition]="false" placement="top">
        <span class="datatable-header-cell-label draggable" (click)="sort()">content</span>
    </div>
  </ng-template>
  <ng-template let-row="row" ngx-datatable-cell-template>
    <p [innerHTML] ="row.content"></p>
  </ng-template>
</ngx-datatable-column> -->
            <!-- Email List  -->
            <ngx-datatable-column *ngIf=" type =='AutoGenerated'" prop="id" name="ID" [width]="200">
              <ng-template let-row="row" ngx-datatable-cell-template>
              <a class="mouse-hover" mat-flat-button (click)="openpop(row,content)" [queryParams]="row" fragment="userdetail">#{{row.id}}</a>

              </ng-template>
            </ngx-datatable-column> 
            <ngx-datatable-column *ngIf=" type =='AutoGenerated'" prop="to" name="To" [width]="200">
              <ng-template let-row="row" ngx-datatable-cell-template>
                  {{row.to}}
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column *ngIf=" type =='AutoGenerated'" prop="cc" name="CC" [width]="200">
              <ng-template let-row="row" ngx-datatable-cell-template>
                  {{row.cc  ==null ? 'N/A' :row.cc}}
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column *ngIf=" type =='AutoGenerated'" prop="bcc" name="BCC" [width]="200">
              <ng-template let-row="row" ngx-datatable-cell-template>
                  {{row.bcc  ==null ? 'N/A' :row.bcc}}
              </ng-template>
            </ngx-datatable-column>
            <!-- <ngx-datatable-column *ngIf=" type =='AutoGenerated'" prop="content" name="content" [width]="200">
              <ng-template let-row="row" ngx-datatable-cell-template>
                  <div [innerHTML]="row.content  ==null ? 'N/A' :row.content"></div>
              </ng-template>
            </ngx-datatable-column> -->
            <ngx-datatable-column *ngIf="type == 'Email' || type =='AutoGenerated'" prop="subject" name="Subject" [width]="200">
              <ng-template let-row="row" ngx-datatable-cell-template>
                  {{row.subject}}
              </ng-template>
            </ngx-datatable-column>
          
            <ngx-datatable-column *ngIf="type == 'Email'" prop="description" name="Description" [width]="200">
              <ng-template let-row="row" ngx-datatable-cell-template>
                  {{row.template_type.description}}
              </ng-template>
            </ngx-datatable-column>
            
            <!-- Sub Category List  -->
            <ngx-datatable-column *ngIf="type == 'SubCategory'" [sortable]="false" name="Category Name" prop="'" [width]="200">
              <ng-template let-row="row" ngx-datatable-cell-template>
                <p style="cursor:pointer;" (click)="subsubcategory(row.id,row.category_name)">{{row.category_name}}</p>
              </ng-template>
            </ngx-datatable-column>

            <!-- Sub Sub Category List  -->
            <ngx-datatable-column *ngIf="type == 'SubSubCategory'" name="Category Name" [width]="200">
              <ng-template let-row="row" ngx-datatable-cell-template>
                  {{row.category_name}}
              </ng-template>
            </ngx-datatable-column>

            <!-- Category List -->
            
            

            <ngx-datatable-column *ngIf="type == 'Category'"  name="Category Name" [width]="200">
              <ng-template let-row="row"ngx-datatable-cell-template>
                  <p style="cursor:pointer;" (click)="subcategory(row.id,row.category_name)">{{row.category_name}}</p>
              </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-column *ngIf="type == 'Category'" [sortable]="false" name="Banner Image" [width]="200">
              <ng-template let-row="row" ngx-datatable-cell-template>
                    <img class="banner_img" [src]="row.banner_image"/>
              </ng-template>
            </ngx-datatable-column>
             
            <ngx-datatable-column *ngIf="(type == 'Category' || type == 'SubCategory')" name="Status" [sortable]="false" [width]="100">
              <ng-template let-row="row" ngx-datatable-cell-template >
                <div class="d-flex align-items-center">
                  <span class="act_btn" [ngClass]="{'bg-success':row.status == 1, 'bg-danger':row.status == 0}" container="body" placement="top"
                      [ngbTooltip]="row.status == 0? 'Inactive':'Active'">{{row.status == 0? 'Inactive':'Active' }}</span>
                </div>
              </ng-template>
            </ngx-datatable-column>

            <!-- finance --list -->
            <ngx-datatable-column *ngIf="type == 'finance'" name="Merchant Id" [width]="100">
              <ng-template let-row="row" ngx-datatable-cell-template>
                <p style="cursor:pointer;">{{row.merchant_id}}</p>
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column *ngIf="type == 'finance'" name="Finance Group Name" [width]="100">
              <ng-template let-row="row" ngx-datatable-cell-template>
                <p style="cursor:pointer;">{{row.finance_group_name}}</p>
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column *ngIf="type == 'finance'" name="Activity Name" [width]="100">
              <ng-template let-row="row" ngx-datatable-cell-template>
                <p style="cursor:pointer;">{{row.receivable_activity_name}}</p>
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column *ngIf="type == 'finance'" name="Activity Account Code" [width]="100">
              <ng-template let-row="row" ngx-datatable-cell-template>
                <p style="cursor:pointer;">{{row.receivable_activity_account_code}}</p>
              </ng-template>
            </ngx-datatable-column>


            <ngx-datatable-column *ngIf="type == 'finance'" name="Cost Center Name" [width]="100">
              <ng-template let-row="row" ngx-datatable-cell-template>
                <p style="cursor:pointer;">{{row.cost_center_name}}</p>
              </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-column *ngIf="type == 'finance'" name="Status" [sortable]="false" [width]="100">
              <ng-template let-row="row" ngx-datatable-cell-template >
                <div class="d-flex align-items-center">
                  <span class="act_btn" [ngClass]="{'bg-success':row.status == 1, 'bg-danger':row.status == 0}" container="body" placement="top"
                      [ngbTooltip]="row.status == 0? 'Inactive':'Active'">{{row.status == 0? 'Inactive':'Active' }}</span>
                </div>
              </ng-template>
            </ngx-datatable-column>
            
            <!-- Form -List -->
            <ngx-datatable-column *ngIf="type == 'Form'" prop='id' [width]="80">
              <ng-template ngx-datatable-header-template let-column="column" let-sort="sortFn">
                <div class="d-inline-block datatable-header-cell-wrapper" ngbTooltip="Form ID" container="body" [adaptivePosition]="false" placement="top" >
                  <!-- (drop)="drop(row)"  (dragover)="allowDrop($event)" (dragstart)="drag(row)" draggable="true" -->
                    <span class="datatable-header-cell-label draggable" (click)="sort()">ID</span>
                </div>
              </ng-template>
              <ng-template let-row="row" ngx-datatable-cell-template>
                  #{{row.form_id}}
              </ng-template>
          </ngx-datatable-column>

          <ngx-datatable-column *ngIf=" type=='FormCatArr'" prop="form_name" [width]="150">
            <ng-template ngx-datatable-header-template let-column="column" let-sort="sortFn">
              <div class="d-inline-block datatable-header-cell-wrapper" ngbTooltip="Form Internal Title" container="body" [adaptivePosition]="false" placement="top">
                  <span class="datatable-header-cell-label draggable" (click)="sort()">Internal Title</span>
              </div>
            </ng-template>
            <ng-template let-row="row" ngx-datatable-cell-template>
              <a class="mouse-hover" mat-flat-button (click)="redirect(row.id,'edit')" [queryParams]="row" fragment="userdetail">{{row.form_internal_name}}</a>
            </ng-template>
          </ngx-datatable-column>
          <ngx-datatable-column *ngIf="type == 'Form' " prop="form_name" [width]="150">
            <ng-template ngx-datatable-header-template let-column="column" let-sort="sortFn">
              <div class="d-inline-block datatable-header-cell-wrapper" ngbTooltip="Form Internal Title" container="body" [adaptivePosition]="false" placement="top">
                  <span class="datatable-header-cell-label draggable" (click)="sort()">Internal Title</span>
              </div>
            </ng-template>
            <ng-template let-row="row" ngx-datatable-cell-template>
              <ng-container *ngIf="showedit =='true'" >
                <a class="mouse-hover" mat-flat-button (click)="redirectform(row,'edit')" href="/form/manage-forms/form-group/{{row.id}}" fragment="userdetail">{{row.form_internal_name}}</a>

              </ng-container>
              <ng-container *ngIf="showedit !='true' && showdetail!='true'" >
                <span mat-flat-button  fragment="userdetail">{{row.form_internal_name}}</span>

              </ng-container>
              <ng-container *ngIf="showdetail=='true' && showedit!='true'">
                <a class="mouse-hover" mat-flat-button (click)="redirecttoDetail(row.id);"target="_blank">{{row.form_internal_name}}</a>


              </ng-container>
              <!-- (click)="redirectform(row,'edit')" -->
            </ng-template>
          </ngx-datatable-column>

          <ngx-datatable-column *ngIf="type == 'Form'|| type=='FormCatArr'" prop="form_type" [width]="120">
            <ng-template ngx-datatable-header-template let-column="column" let-sort="sortFn">
              <div class="d-inline-block datatable-header-cell-wrapper" ngbTooltip="Form Type" container="body" [adaptivePosition]="false" placement="top">
                  <span class="datatable-header-cell-label draggable" (click)="sort()">Type</span>
              </div>
            </ng-template>
            <ng-template let-row="row" ngx-datatable-cell-template>
                {{row.form_type.charAt(0).toUpperCase() + row.form_type.slice(1).replaceAll('_', ' ') }}
            </ng-template>
          </ngx-datatable-column>


          <ngx-datatable-column *ngIf="type == 'Form'|| type=='FormCatArr'" prop="created_at" [width]="170">
            <ng-template ngx-datatable-header-template let-column="column" let-sort="sortFn">
              <div class="d-inline-block datatable-header-cell-wrapper" ngbTooltip="Form Creation Date" container="body" [adaptivePosition]="false" placement="top">
                  <span class="datatable-header-cell-label draggable" (click)="sort()">Created Date</span>
              </div>
            </ng-template>
            <ng-template let-row="row" ngx-datatable-cell-template>
                {{row.creation_date}}
            </ng-template>
          </ngx-datatable-column>

          <!-- <ngx-datatable-column *ngIf="type == 'Form'" prop="form_business_name" name=" Form Business Name" [width]="200">
            <ng-template let-row="row" ngx-datatable-cell-template>
                {{row.form_business_name}}
            </ng-template>
          </ngx-datatable-column> -->


          <ngx-datatable-column *ngIf="type == 'Form'|| type=='FormCatArr'" prop="form_created_by" [width]="120">
            <ng-template ngx-datatable-header-template let-column="column" let-sort="sortFn">
              <div class="d-inline-block datatable-header-cell-wrapper" ngbTooltip="Form Created By" container="body" [adaptivePosition]="false" placement="top">
                  <span class="datatable-header-cell-label draggable" (click)="sort()">Created By</span>
              </div>
            </ng-template>
            <ng-template let-row="row" ngx-datatable-cell-template>
                {{row.created_by}}
            </ng-template>
          </ngx-datatable-column>

          <ngx-datatable-column *ngIf="type == 'Form'|| type=='FormCatArr'" prop="paid" [width]="100">
            <ng-template ngx-datatable-header-template let-column="column" let-sort="sortFn">
              <div class="d-inline-block datatable-header-cell-wrapper" ngbTooltip="Paid/Unpaid" container="body" [adaptivePosition]="false" placement="top">
                  <span class="datatable-header-cell-label draggable" (click)="sort()">Payment</span>
              </div>
            </ng-template>
            <ng-template let-row="row" ngx-datatable-cell-template>
                {{row.paid=="0"? "Unpaid":"Paid"}}
            </ng-template>
          </ngx-datatable-column>


          <ngx-datatable-column *ngIf="type == 'Form'|| type=='FormCatArr'" prop="total_submissions" [width]="120">
            <ng-template ngx-datatable-header-template let-column="column" let-sort="sortFn">
              <div class="d-inline-block datatable-header-cell-wrapper" ngbTooltip="Number Of Submissions" container="body" [adaptivePosition]="false" placement="top">
                  <span class="datatable-header-cell-label draggable" (click)="sort()">Submissions</span>
              </div>
            </ng-template>
            <ng-template let-row="row" ngx-datatable-cell-template>
                {{row.total_submissions}}
            </ng-template>
          </ngx-datatable-column>


          <ngx-datatable-column *ngIf="type == 'Form'|| type=='FormCatArr'" prop="total_money_generated" [width]="100">
            <ng-template ngx-datatable-header-template let-column="column" let-sort="sortFn">
              <div class="d-inline-block datatable-header-cell-wrapper" ngbTooltip="Money Generated" container="body" [adaptivePosition]="false" placement="top">
                  <span class="datatable-header-cell-label draggable" (click)="sort()">Revenue</span>
              </div>
            </ng-template>
            <ng-template let-row="row" ngx-datatable-cell-template>
                {{row.total_money_generated}}
            </ng-template>
          </ngx-datatable-column>


          <ngx-datatable-column *ngIf="type == 'Form'" [sortable]="false" [width]="80">
            <ng-template ngx-datatable-header-template let-column="column" let-sort="sortFn">
              <div class="d-inline-block datatable-header-cell-wrapper" ngbTooltip="Copy Link" container="body" [adaptivePosition]="false" placement="top">
                  <span class="datatable-header-cell-label draggable">Form link</span>
              </div>
            </ng-template>
            <ng-template let-row="row" ngx-datatable-cell-template>
              <button class="action_btn btn-primary" (click)="copytext(row.id)"><i class="fa fa-copy" aria-hidden="true"></i></button>
          <!-- <span class="action_btn btn-primary" id={{row.id}} *ngIf="showCopytext== 'true'"> Link copied to clipboard</span> -->
            </ng-template>
          </ngx-datatable-column>

          
          <ngx-datatable-column *ngIf="type == 'Form'" [sortable]="false" [width]="100">
            <ng-template ngx-datatable-header-template let-column="column" let-sort="sortFn">
              <div class="d-inline-block datatable-header-cell-wrapper" ngbTooltip="Duplicate Form" container="body" [adaptivePosition]="false" placement="top">
                  <span class="datatable-header-cell-label draggable">Duplicate</span>
              </div>
            </ng-template>
            <ng-template let-row="row" ngx-datatable-cell-template>
              <button class="action_btn btn-primary" (click)="duplicate(row.id,row.form_id)"><i class="fa fa-clone" aria-hidden="true"></i></button>
          <!-- <span class="action_btn btn-primary" id={{row.id}} *ngIf="showCopytext== 'true'"> Link copied to clipboard</span> -->
            </ng-template>
          </ngx-datatable-column>


          <ngx-datatable-column *ngIf="type == 'Form' " prop="form_category" [width]="200">
            <ng-template ngx-datatable-header-template let-column="column" let-sort="sortFn">
              <div class="d-inline-block datatable-header-cell-wrapper" ngbTooltip="Category" container="body" [adaptivePosition]="false" placement="top">
                  <span class="datatable-header-cell-label draggable" (click)="sort()">Category</span>
              </div>
            </ng-template>
            <ng-template let-row="row" ngx-datatable-cell-template>
              <span *ngIf="row.parent_category != ''">{{row.parent_category}}</span><span *ngIf="row.sub_category != ''">/{{row.sub_category}}</span><span *ngIf="row.sub_sub_category != ''">/{{row.sub_sub_category}}</span>
            </ng-template>
          </ngx-datatable-column>

            
          <!-- <ngx-datatable-column *ngIf=" urlval == '/user/user-listing' ||urlval == '/admin/admin-list'"name="Name" [width]="200"> -->
          <ngx-datatable-column *ngIf="type == 'Admin'" prop="name" [width]="150">
            <ng-template ngx-datatable-header-template let-column="column" let-sort="sortFn">
              <div class="d-inline-block datatable-header-cell-wrapper" ngbTooltip="First Name" container="body" [adaptivePosition]="false" placement="top">
                  <span class="datatable-header-cell-label draggable" (click)="sort()">First Name</span>
              </div>
            </ng-template>
            <ng-template let-row="row" ngx-datatable-cell-template>
              <ng-container *ngIf="showedit=='true' || showview=='true'">
                <a class="mouse-hover" mat-flat-button (click)="redirect(row.id,'edit');" target="_blank">{{row.first_name}}</a>

              </ng-container>
              <ng-container *ngIf="showedit!='true'&& showview!='true'">
                <span>{{row.first_name}}</span>

              </ng-container>
           
            </ng-template>
          </ngx-datatable-column>

          <ngx-datatable-column *ngIf="type == 'Admin' " prop="name" [width]="150">
            <ng-template ngx-datatable-header-template let-column="column" let-sort="sortFn">
              <div class="d-inline-block datatable-header-cell-wrapper" ngbTooltip="Last Name" container="body" [adaptivePosition]="false" placement="top">
                  <span class="datatable-header-cell-label draggable" (click)="sort()">Last Name</span>
              </div>
            </ng-template>
            <ng-template let-row="row" ngx-datatable-cell-template>
              {{row.last_name}}
            </ng-template>
          </ngx-datatable-column>

          <ngx-datatable-column *ngIf="type =='Roles'" prop="name" [width]="200">
            <ng-template ngx-datatable-header-template let-column="column" let-sort="sortFn">
              <div class="d-inline-block datatable-header-cell-wrapper" ngbTooltip="Name" container="body" [adaptivePosition]="false" placement="top">
                  <span class="datatable-header-cell-label draggable" (click)="sort()">Name</span>
              </div>
            </ng-template>
            <ng-template let-row="row" ngx-datatable-cell-template>
              {{row.name}}
            </ng-template>
          </ngx-datatable-column>

          <ngx-datatable-column *ngIf="type == 'dependent'" prop="id" [width]="60">
            <ng-template ngx-datatable-header-template let-column="column" let-sort="sortFn">
              <div class="d-inline-block datatable-header-cell-wrapper" ngbTooltip="User ID" container="body" [adaptivePosition]="false" placement="top">
                  <span class="datatable-header-cell-label draggable" (click)="sort()">ID</span>
              </div>
            </ng-template>
            <ng-template let-row="row" ngx-datatable-cell-template>
              <a class="mouse-hover" mat-flat-button (click)="redirect(row.id,'edit')" [queryParams]="row" fragment="userdetail">{{row.de_id}}</a>
            </ng-template>
          </ngx-datatable-column>
          <ngx-datatable-column *ngIf="type == 'User'" prop="id" [width]="60">
            <ng-template ngx-datatable-header-template let-column="column" let-sort="sortFn">
              <div class="d-inline-block datatable-header-cell-wrapper" ngbTooltip="User ID" container="body" [adaptivePosition]="false" placement="top">
                  <span class="datatable-header-cell-label draggable" (click)="sort()">ID</span>
              </div>
            </ng-template>
            <ng-template let-row="row" ngx-datatable-cell-template>
              <ng-container *ngIf="showedit =='true' || showview =='true'">
                <a class="mouse-hover" mat-flat-button (click)="redirect(row.id,'edit')" [queryParams]="row" fragment="userdetail">#{{row.de_id}}</a>

              </ng-container>
              <ng-container *ngIf="showedit !='true' && showview!='true'">
                <span  mat-flat-button  [queryParams]="row" fragment="userdetail">#{{row.de_id}}</span>

              </ng-container>
            </ng-template>
          </ngx-datatable-column>
          <ngx-datatable-column *ngIf="type == 'User' || type =='dependent'" prop="first_name" [width]="150">
            <ng-template ngx-datatable-header-template let-column="column" let-sort="sortFn">
              <div class="d-inline-block datatable-header-cell-wrapper" ngbTooltip="Name" container="body" [adaptivePosition]="false" placement="top">
                  <span class="datatable-header-cell-label draggable" (click)="sort()"> Name</span>
              </div>
            </ng-template>
            <ng-template let-row="row" ngx-datatable-cell-template class="mouse-hover">
              
              <a class="mouse-hover" mat-flat-button (click)="redirect(row.id,'edit')" [queryParams]="row" fragment="userdetail">   {{row.first_name}} {{row.last_name}}</a>

           
            </ng-template>
          </ngx-datatable-column>

          <!-- <ngx-datatable-column *ngIf="type == 'User' || type == 'dependent'" prop="last_name" [width]="150">
            <ng-template ngx-datatable-header-template let-column="column" let-sort="sortFn">
              <div class="d-inline-block datatable-header-cell-wrapper" ngbTooltip="Last Name" container="body" [adaptivePosition]="false" placement="top">
                  <span class="datatable-header-cell-label draggable" (click)="sort()">Last Name</span>
              </div>
            </ng-template>
            <ng-template let-row="row" ngx-datatable-cell-template>
              {{row.last_name}}
            </ng-template>
          </ngx-datatable-column> -->

          <ngx-datatable-column *ngIf="type == 'User' || type == 'dependent'" prop="user_type" [width]="100">
            <ng-template ngx-datatable-header-template let-column="column" let-sort="sortFn">
              <div class="d-inline-block datatable-header-cell-wrapper" ngbTooltip="Type of User" container="body" [adaptivePosition]="false" placement="top">
                  <span class="datatable-header-cell-label draggable" (click)="sort()">Type</span>
              </div>
            </ng-template>
            <ng-template let-row="row" ngx-datatable-cell-template>
                {{row.user_type}}
            </ng-template>
          </ngx-datatable-column>


          <ngx-datatable-column *ngIf="type == 'User' || type == 'Admin'" prop="email" [width]="200">
            <ng-template ngx-datatable-header-template let-column="column" let-sort="sortFn">
              <div class="d-inline-block datatable-header-cell-wrapper" ngbTooltip="Email Address" container="body" [adaptivePosition]="false" placement="top">
                  <span class="datatable-header-cell-label draggable" (click)="sort()">Email Address</span>
              </div>
            </ng-template>
            <ng-template let-row="row" ngx-datatable-cell-template>
                {{row.email}}
            </ng-template>
          </ngx-datatable-column>

          <ngx-datatable-column  *ngIf="type == 'User' || type == 'dependent'" prop="phone_number" [width]="130">
            <ng-template ngx-datatable-header-template let-column="column" let-sort="sortFn">
              <div class="d-inline-block datatable-header-cell-wrapper" ngbTooltip="Phone Number" container="body" [adaptivePosition]="false" placement="top">
                  <span class="datatable-header-cell-label draggable" (click)="sort()">Phone</span>
              </div>
            </ng-template>
        </ngx-datatable-column>
        
      


          <ngx-datatable-column *ngIf="type == 'User'" prop="created_at" [width]="180">
            <ng-template ngx-datatable-header-template let-column="column" let-sort="sortFn">
              <div class="d-inline-block datatable-header-cell-wrapper" ngbTooltip="Customer Since" container="body" [adaptivePosition]="false" placement="top">
                  <span class="datatable-header-cell-label draggable" (click)="sort()">Customer Since</span>
              </div>
            </ng-template>
            <ng-template let-row="row" ngx-datatable-cell-template>
                {{row.customer_since}}
            </ng-template>
          </ngx-datatable-column>


          <ngx-datatable-column *ngIf="type == 'User' || type == 'dependent'" [sortable]="false" [width]="100">
            <ng-template ngx-datatable-header-template let-column="column" let-sort="sortFn">
              <div class="d-inline-block datatable-header-cell-wrapper" ngbTooltip="Total Amount Paid" container="body" [adaptivePosition]="false" placement="top">
                  <span class="datatable-header-cell-label draggable" (click)="sort()">Total</span>
              </div>
            </ng-template>
            <ng-template let-row="row" ngx-datatable-cell-template>
                {{row.total_amount_paid}}
            </ng-template>
          </ngx-datatable-column>


          <ngx-datatable-column *ngIf="type == 'User' || type == 'dependent'" [sortable]="false" [width]="120">
            <ng-template ngx-datatable-header-template let-column="column" let-sort="sortFn">
              <div class="d-inline-block datatable-header-cell-wrapper" ngbTooltip="Total Forms Submitted" container="body" [adaptivePosition]="false" placement="top">
                  <span class="datatable-header-cell-label draggable" (click)="sort()">Total Forms</span>
              </div>
            </ng-template>
            <ng-template let-row="row" ngx-datatable-cell-template>
                {{row.total_form_submitted}}
            </ng-template>
          </ngx-datatable-column>

          <ngx-datatable-column *ngIf="type == 'User' || type == 'dependent'" prop="dob" [width]="125">
            <ng-template ngx-datatable-header-template let-column="column" let-sort="sortFn">
              <div class="d-inline-block datatable-header-cell-wrapper" ngbTooltip="Date of Birth" container="body" [adaptivePosition]="false" placement="top">
                  <span class="datatable-header-cell-label draggable" (click)="sort()">Date of Birth</span>
              </div>
            </ng-template>
            <ng-template let-row="row" ngx-datatable-cell-template>
                {{row.dob}}
            </ng-template>
          </ngx-datatable-column>

          <ngx-datatable-column *ngIf="type == 'User'" [sortable]="false" [width]="120">
            <ng-template ngx-datatable-header-template let-column="column" let-sort="sortFn">
              <div class="d-inline-block datatable-header-cell-wrapper" ngbTooltip="No. of Dependents" container="body" [adaptivePosition]="false" placement="top">
                  <span class="datatable-header-cell-label draggable" (click)="sort()">Dependents</span>
              </div>
            </ng-template>
            <ng-template let-row="row" ngx-datatable-cell-template>
                {{row.num_of_dependants}}
            </ng-template>
          </ngx-datatable-column>

          <ngx-datatable-column *ngIf="type == 'Admin'" prop="address" [width]="200">
            <ng-template ngx-datatable-header-template let-column="column" let-sort="sortFn">
              <div class="d-inline-block datatable-header-cell-wrapper" ngbTooltip="Address" container="body" [adaptivePosition]="false" placement="top">
                  <span class="datatable-header-cell-label draggable" (click)="sort()">Address</span>
              </div>
            </ng-template>
              <ng-template let-row="row" ngx-datatable-cell-template>
                  {{row.address}}
              </ng-template>
          </ngx-datatable-column>


          <ngx-datatable-column *ngIf="type == 'Admin'" prop="last_login" [width]="200">
            <ng-template ngx-datatable-header-template let-column="column" let-sort="sortFn">
              <div class="d-inline-block datatable-header-cell-wrapper" ngbTooltip="Last Login" container="body" [adaptivePosition]="false" placement="top">
                  <span class="datatable-header-cell-label draggable" (click)="sort()">Last Login</span>
              </div>
            </ng-template>
              <ng-template let-row="row" ngx-datatable-cell-template>
                  {{row.last_login == 'Invalid date' ? 'N/A' :row.last_login}}
              </ng-template>
          </ngx-datatable-column>


          <ngx-datatable-column prop="roles" *ngIf="type == 'Admin'" [width]="200">
            <ng-template ngx-datatable-header-template let-column="column" let-sort="sortFn">
              <div class="d-inline-block datatable-header-cell-wrapper" ngbTooltip="User Role" container="body" [adaptivePosition]="false" placement="top">
                  <span class="datatable-header-cell-label draggable" (click)="sort()">User Role</span>
              </div>
            </ng-template>
            <ng-template let-row="row" ngx-datatable-cell-template>
                {{row.roles}}
            </ng-template>
          </ngx-datatable-column>


          <ngx-datatable-column *ngIf="type == 'Admin' || type == 'User'|| type == 'Form' || type == 'Email' || type == 'Pages' || type == 'dependent'" [sortable]="false" name="Status" [width]="200">
              <ng-template let-row="row" ngx-datatable-cell-template >
                <div class="d-flex align-items-center">
                  <!-- <h1></h1> -->
                  <span class="act_btn" [ngClass]="{'bg-success':row.status == 1, 'bg-danger':row.status == 0}" container="body" placement="top"
                      [ngbTooltip]="row.status == 0? 'Inactive':'Active'">{{row.status == 0? 'Inactive':'Active' }}</span>
                  <span *ngIf="type != 'Form'" class="act_btn" [ngClass]="{'bg-success':row.login_attempts < 5, 'bg-danger':row.login_attempts >= 5}" container="body" placement="top"
                  [ngbTooltip]="row.login_attempts && row.login_attempts >= 5 ? 'Blocked' : 'Unblocked'"> {{row.login_attempts && row.login_attempts >= 5 ? "Blocked" : "Unblocked"}}</span>
                </div>
              </ng-template>
          </ngx-datatable-column>

          <ngx-datatable-column name="Action" *ngIf="(showactive=='true' || showedit =='true' || showblock=='true' || showdelete =='true' || showview =='true')&& type!='FormSubmission' && type != 'LastBookings'"[sortable]="false" prop="action" [width]="125">
            <ng-template let-row="row" ngx-datatable-cell-template>
              <div class="d-flex align-items-center">
                <div *ngIf="type != 'Form'">
                <div *ngIf="(type == 'User' || type == 'Admin'  || type == 'Email' || type == 'Pages' || type == 'Category' || type != 'FormSubmission' || type == 'SubCategory' || type != 'HomepageSlider' || type ==' dependent' || type != 'LastBookings' || type == 'finance') && showactive=='true'">
               <!-- <p>ff</p> -->
                  <button container="body" class="action_btn" [ngClass]="{'btn-danger':row.status == 1, 'btn-success':row.status == 0}" type="button" placement="top" [ngbTooltip]="row.status == 0? 'Make Active':'Make Inactive'">
                    <i class="icon-close" (click)="changeStatus('0',row.id)" *ngIf="row.status == 1"></i>
                    <i class="icon-check" (click)="changeStatus('1',row.id)" *ngIf="row.status == 0"></i>
                  </button>
                </div>
              </div>
                <div *ngIf=" type =='Form' && showactive=='true' && row.form_type =='standard_form'">
               <!-- <p>sss</p> -->
                  <button container="body" class="action_btn" [ngClass]="{'btn-danger':row.status == 1, 'btn-success':row.status == 0}" type="button" placement="top" [ngbTooltip]="row.status == 0? 'Make Active':'Make Inactive'">
                    <i class="icon-close" (click)="changeStatus('0',row.id)" *ngIf="row.status == 1"></i>
                    <i class="icon-check" (click)="changeStatus('1',row.id)" *ngIf="row.status == 0"></i>
                  </button>
                </div>

                <div *ngIf=" type =='Form' && showvoteactive=='true' && row.form_type =='voting_form'">
                  <!-- <p>vvv</p> -->
               
                  <button container="body" class="action_btn" [ngClass]="{'btn-danger':row.status == 1, 'btn-success':row.status == 0}" type="button" placement="top" [ngbTooltip]="row.status == 0? 'Make Active':'Make Inactive'">
                    <i class="icon-close" (click)="changeStatus('0',row.id)" *ngIf="row.status == 1"></i>
                    <i class="icon-check" (click)="changeStatus('1',row.id)" *ngIf="row.status == 0"></i>
                  </button>
                </div>
                <div *ngIf="(type == 'User' || type == 'Admin') && (showblock=='true')">
                  <button container="body" class="action_btn" [ngClass]="{'btn-danger':row.login_attempts < 5, 'btn-success':row.login_attempts >= 5}" type="button" placement="top" [ngbTooltip]="row.login_attempts && row.login_attempts >= 5 ? 'Unblock' : 'Block'">
                    <i class="icon-lock" (click)="blockUnblock('0',row.id)" *ngIf="row.login_attempts >= 5"></i>
                    <i class="icon-unlock" (click)="blockUnblock('1',row.id)" *ngIf="row.login_attempts < 5"></i>
                  </button>
                </div>
      
                <div *ngIf="(type == 'Email' || type == 'Pages' || type == 'Category' || type == 'SubCategory' || type == 'FormSubmission'|| type=='Roles' ||type=='dependent' || type=='SubSubCategory' || type == 'HomepageSlider' || type != 'LastBookings' ) &&(showedit =='true') && type != 'Form' && type != 'Admin' && type != 'User' && type !='AllBook'">
                  <button container="body" class="action_btn btn-primary" (click)="redirect(row.id,'edit','editthisone')" type="button" placement="top" ngbTooltip="Edit">
                    <i class="icon-pencil"></i>
                  </button>
                </div>
                <div *ngIf=" (showedit =='true')  && type =='AllBook' && book_id != 'votes-list'">
                  <button container="body" class="action_btn btn-primary" (click)="redirect(row.id,row.form_id,row.user_id,row.submission_id)" type="button" placement="top" ngbTooltip="Edit">
                    <i class="icon-pencil"></i>
                  </button>
                </div>
                <div  *ngIf="  type =='AllBook'">
                  <button container="body" class="action_btn btn-primary"  (click)="redirect(row.id,row.form_id,row.user_id,row.submission_id)" type="button" placement="top" ngbTooltip="View">
                    <i class="icon-eye"></i>
                  </button>
                </div>
                <!-- {{showvoteedit}} -->
                <div *ngIf="(type == 'Form') &&(showedit =='true') && row.form_type =='standard_form'">
              <a class="mouse-hover action_btn btn-primary" placement="top" ngbTooltip="Edit" container="body" (click)="redirectform(row,'edit')"  href="/form/manage-forms/form-group/{{row.id}}" fragment="userdetail">
                <i class="icon-pencil"></i>
                <!-- (click)="redirectform(row,'edit')" -->

              </a>

                  
                </div>
                <div *ngIf="(type == 'Form') &&(showvoteedit =='true') && row.form_type =='voting_form'">
                  <!-- <p>vvvv</p> -->
                  <a container="body" class="action_btn btn-primary"  href="/form/manage-forms/form-group/{{row.id}}" placement="top" ngbTooltip="Edit">
                    <!-- (click)="redirectform(row)" -->
                    <!-- click)="redirectform(row)"  -->
                    <i class="icon-pencil"></i>
                  </a>
                </div>
                <!-- <div *ngIf="(type == 'User' ||type == 'Admin' || type == 'Roles) &&(showedit =='true')"> -->
                <div *ngIf="(type == 'User' ||type == 'Admin' || type == 'Roles' || type =='Pages') &&(showview =='true')">
                  <button container="body" class="action_btn btn-primary" (click)="redirect(row.id,'edit','view')" type="button" placement="top" ngbTooltip="View">
                    <i class="icon-eye"></i>
                  </button>
                </div>
                 <!-- <div *ngIf=" urlval == '/user/user-listing' || urlval == '/admin/admin-list' || urlval == '/form/manage-forms/list' || urlval == '/email-templates/template-list' || urlval == '/static-pages/pages-list' || urlval == '/categories/category-list' || type == 'SubCategory'">
                  <button class="action_btn" [ngClass]="{'btn-danger':row.status == 1, 'btn-success':row.status == 0}" type="button" placement="top" [ngbTooltip]="row.status == 0? 'Make Active':'Make Inactive'">
                    <i class="icon-close" (click)="changeStatus('0',row.id)" *ngIf="row.status == 1"></i>
                    <i class="icon-check" (click)="changeStatus('1',row.id)" *ngIf="row.status == 0"></i>
                  </button>
                </div> -->
                <div *ngIf="type == 'Roles' && showdelete=='true'">
                  <button class="action_btn btn-danger" (click)="Delete(row.id)"  type="button" container="body" placement="top" ngbTooltip="Delete Role">
                    <i class="icon-close " (click)="changeStatus('0',row.id)" ></i>
  
                  </button> 
                </div>
             
                <div *ngIf="type == 'Pages' && showdelete =='true' ">
                  <button class="action_btn btn-danger" (click)="DeleteForm(row.id)"  type="button" container="body" placement="top" ngbTooltip="Delete Page">
                    <i class="icofont icofont-ui-delete"(click)="changeStatus('2',row.id)" ></i>
                    <!-- <i class="icon-close " (click)="changeStatus('0',row.id)" ></i> -->
  
                  </button> 
                </div>
                <!-- <div *ngIf="(type == 'Roles') && (showdelete=='true')"> -->
                  <div *ngIf="type == 'finance'">
                    <!-- <p>d</p>as -->
                    <button class="action_btn btn-danger" (click)="DeleteFinance(row.id)"  type="button" container="body" placement="top" ngbTooltip="Delete Role">
                      <i class="icofont icofont-ui-delete"></i>

  
                  </button> 
                </div>
                <!-- <div *ngIf="(type == 'Form') && (showdelete=='true')"> -->
                  <div *ngIf="type == 'Form' && showdelete =='true' && row.form_type =='standard_form'">
                  <button class="action_btn btn-danger" (click)="DeleteForm(row.id)"  type="button" container="body" placement="top" ngbTooltip="Delete Form">
                    <i class="icofont icofont-ui-delete"></i>
                    <!-- <i class="icon-close " (click)="changeStatus('0',row.id)" ></i> -->
  
                  </button> 
                </div>

                <div *ngIf="type == 'Form' && showvotedelete =='true' && row.form_type =='voting_form'">
                  <button class="action_btn btn-danger" (click)="DeleteForm(row.id)"  type="button" container="body" placement="top" ngbTooltip="Delete Voting Form">
                    <i class="icofont icofont-ui-delete"></i>
                    <!-- <i class="icon-close " (click)="changeStatus('0',row.id)" ></i> -->
  
                  </button> 
                </div>

              </div>
              </ng-template>
              <ng-template let-row="row" ngx-datatable-cell-template>
                  {{row.nationality}}
              </ng-template>
            </ngx-datatable-column>  
     


            <ngx-datatable-footer>
              <ng-template ngx-datatable-footer-template>
              <!-- <h1> {{totalcount}} </h1> -->
              <div class="d-flex justify-content-center" *ngIf="totalcount !=0">
                <pagination-controls 
                    [directionLinks]="true" 
                    previousLabel="" 
                    nextLabel="" 
                    (pageChange)="pageshift($event) ">
                </pagination-controls>
                <!-- (pageChange)="currentPage = $event"> -->
              </div>
            </ng-template>
          </ngx-datatable-footer>
        </ngx-datatable>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- <div class="list">
	<ul>
		<li *ngFor="let item of list | paginate: config">{{ item }}</li>
	</ul>
	<pagination-controls (pageChange)="pageChange($event)" class="my-pagination"></pagination-controls>
</div> -->
<!-- Container-fluid Ends -->


<ng-template #content let-modal>
  <div class="modal-header">
    <h5 class="modal-title" id="modal-basic-title">Auto Generated Email Details</h5>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">&times;</button>
  </div>
  <div class="modal-body">
    <div class="mb-3">
       <p>To: {{to}}</p>
       <p *ngIf="cc !=null">CC: {{cc}}</p>
       <p *ngIf="bcc !=null">BCC: {{bcc}}</p>
       <p >Content: <span [innerHTML]="emailcontent"></span></p>
       <p>Subject: {{subject}}</p>
    </div>
  </div>
  </ng-template>



  <ng-template #content1 let-bulkmodal let-d="dismiss">
    <div class="modal-header">
      <h5 class="modal-title" id="modal-basic-title">Bulk Email</h5>
      <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">&times;</button>
    </div>
    <div class="modal-body">
      <form class="theme-form needs-validation" [class.was-validated]="!BulkEmailForm.valid" novalidate="" [formGroup]="BulkEmailForm">
        <div class="form-row">
          <div class="col-sm-6">
            <div class="form-group">
              <label class="col-form-label">To:</label>
              <ng-multiselect-dropdown
                  [placeholder]="'Choose User'" 
                  [settings]="dropdownSettings"
                  [data]="user_details"
                  formControlName="userTo"
                  [disabled]="false" 
                  (onSelect)="onItemSelect($event)"
                  (onDeSelect)="onDeSelect($event)"
                  (onSelectAll)="onSelectAll($event)"
                  (onDeSelectAll)="onDeSelectAll($event)">
              </ng-multiselect-dropdown>
              <div class="required_error" *ngIf="BulkEmailForm.controls.userTo.touched && BulkEmailForm.controls.userTo.errors?.required">User is required.</div>
            </div>
          </div>
          <div class="col-sm-6">
            <div class="form-group">
              <label class="col-form-label">CC:</label>
              <input class="form-control text-dark" type="text" formControlName="cc" name="cc" placeholder="CC">
              <div  class="required_error" *ngIf="BulkEmailForm.controls.cc.touched && BulkEmailForm.controls.cc.errors?.pattern">CC must be an email.</div>
            </div>
          </div>
        </div>
        <div class="form-row">
          <div class="col-sm-6">
            <div class="form-group">
              <label class="col-form-label">BCC:</label>
              <input class="form-control text-dark" type="text" formControlName="bcc" name="bcc" placeholder="BCC">
              <div  class="required_error" *ngIf="BulkEmailForm.controls.bcc.touched && BulkEmailForm.controls.bcc.errors?.pattern">BCC must be an email.</div>
            </div>
          </div>
          <div class="col-sm-6">
            <div class="form-group">
              <label class="col-form-label">Subject:</label>
              <input class="form-control text-dark" type="text" formControlName="subject" name="subject" required="" placeholder="Subject">
              <div  class="required_error" *ngIf="BulkEmailForm.controls.subject.touched && BulkEmailForm.controls.subject.errors?.required">Subject is required.</div>
            </div>
          </div>
        </div>
        <div class="form-group">
          <label class="col-form-label">Content:</label>
          <ckeditor [editor]="ClassicEditor" id="content" formControlName="content" data="" required></ckeditor>
          <div  class="required_error" *ngIf="BulkEmailForm.controls.content.touched && BulkEmailForm.controls.content.errors?.required">Content is required.</div>
        </div>
        <div class="form-group mb-0">
          <button class="btn btn-primary btn-block" (click)="sendBulkEmail()" type="submit">Send Email</button>
        </div>
      </form>
    </div>
    </ng-template>


    <ng-template #content2 let-modal>
      <div class="modal-header">
        <h5 class="modal-title" id="modal-basic-title">Email Details</h5>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">&times;</button>
      </div>
      <div class="modal-body">
        <div class="mb-3">
           <p><b>To: </b> {{to}}</p>
           <p *ngIf="cc !=null"><b>CC: </b> {{cc}}</p>
           <p *ngIf="bcc !=null"><b>BCC: </b> {{bcc}}</p>
           <p><b>Subject: </b> {{subject}}</p>
           <p><b>Content: </b> <span class="d-inline-block" [innerHTML]="emailcontent"></span></p>
        </div>
      </div>
      </ng-template>

      <ng-template #content3 let-bulkmodal let-d="dismiss">
        <div class="modal-header">
          <h5 class="modal-title" id="modal-basic-title">+Invite</h5>
          <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">&times;</button>
        </div>
        <div class="modal-body inviteForm" *ngIf="InviteUserArr!=undeifned">
          <ng-multiselect-dropdown
            [placeholder]="'+Invite'"
            [settings]="dropdownSettingsInvite"
            [data]="InviteUserArr"
            (onSelect)="onItemInviteSelect($event)"
            (onDeSelect)="onDeSelectInvite($event)"
            (onSelectAll)="onSelectInviteAll($event)"
            (onDeSelectAll)="onDeSelectAllInvite($event)"
            [(ngModel)]="InviteUserdata"
            [ngModelOptions]="{ standalone: true }"
          >
          </ng-multiselect-dropdown>
          <div class="required_error" [ngClass]="invite_err ? 'pt-3' : ''">{{invite_err}}</div>
          <button type="button" class="btn btn-primary mt-3" (click)="Inviteapihit()">+Invite</button>
        </div>
      </ng-template>
  <!-- {{list |json}} -->
  <!-- custom-loader -->
  <div class="customLoader" *ngIf="LoaderClass">
    <div class="col-md-3 loaderWrap">
        <div class="loader-box">
        <div class="loader-19"></div>
        </div>
    </div>
</div>

