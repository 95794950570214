import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormsService } from 'src/app/components/forms/forms.service';
import { takeUntil } from "rxjs/operators";
import { Subject } from 'rxjs';
import { AuthService } from '../../services/firebase/auth.service';
import { DiscountGroupService } from 'src/app/components/discount/discount-group.service';
import Swal from 'sweetalert2';
import { CommonService } from '../../services/common.service';

@Component({
  selector: 'app-returning-student',
  templateUrl: './returning-student.component.html',
  styleUrls: ['./returning-student.component.scss']
})
export class ReturningStudentComponent implements OnInit {
destroy$: Subject<boolean> = new Subject<boolean>();
allCategories: any[] = [];
categoryLevel: number = 1;
idtopass:any=''
@Input() form_id:any
@Input() form_price: string = ''; // decorate the property with @Input()

@Input() RuleStudentArr: any[] = [];
@Input() MaxDisArr: any; // decorate the property with @Input(
  @Output() ReturnRuleShow = new EventEmitter();
  result:any
  hidesave:boolean = false
  constructor(
    private formService: FormsService,
    private authService: AuthService,
    private discountGroupService: DiscountGroupService,
    private common:CommonService



  ) { }
  allsubsubcat:any[]=[]
  discountRuleForm=
   [ {
      "rule_name": "",
      "if_condition": {
        "allow": "any",
     
        "conditions": [{
          "category_id": "",
          "subcategory_id":"",
          "sub_sub_category_id":"",
          "sub_cat_list":[],
          "sub__sub_cat_list":[],
          "startDate":"",
          "endDate":"",
          // "constraint": "Is",
          // "constraint_id": 1,
          // "value": "",
          // "showValueBox": true
        }]
      },
      "then_apply": {
        "type": "percent",
        "value": ""
      },
      "discount_proof_title": "",
      "discount_proof_description": "",
   
    }]
  
  validate = false;

  selectedCategory: any;
  showSubCategoryList: any[] = [];
  showSubSubCategoryList: any[] = [];
  allSubCategories: any[] = [];
  thenApply = ['percent', 'amount'];
  catid:any
  getprice:any
  newarr:any[]=[{
    cat_id:'',
    subcat:''
  }]
  error_start:any
  minDob:any
  error_end:any
  maxDob:any
  startChange(e: any) {
    console.log(this.discountRuleForm,"DDD")
    this.error_start = "";
    this.minDob = e.target.value;
  }
  EndChange(e: any) {
    
    this.error_end = "";
    this.maxDob = e.target.value;
  }
  ngOnInit(): void {
    this.common.getprice().subscribe(res => {
      this.getprice=res
      })
  // console.log(this.RuleStudentArr.length,'rrrrrrrrr')
    if(this.RuleStudentArr.length > 0) {
      this.discountRuleForm = this.RuleStudentArr;
    console.log(this.discountRuleForm,"aaaaaaaaaaaaaaaaaaaaaaaaaaas=====aaaaaaaaaaaaaaaaa")
    // Subcat is not coming
    
      // this.catid=parseInt(this.discountRuleForm[0].if_condition.conditions[0].category_id).toString()
      this.discountRuleForm[0].if_condition.conditions.forEach((condition,i) =>{
       
          if(i>=1){
            this.newarr.push(
              {
                cat_id:'',
                subcat:'',
                
              }
            )
            // this.categoryChange(condition.category_id.toString(),i)
            // this.SubcategoryChange(condition.subcategory_id.toString(),i)

          }else{

            // this.categoryChange(condition.category_id.toString(),i)
            // this.SubcategoryChange(condition.subcategory_id.toString(),i)
          }
      })
      // console.log( this.catid,'catidddddddddddd');
      
      // this.categoryChange(this.catid,1)
    }
    this.getCategoriesList()
  }
  MaxDiscountLimit(event: any,index) {

       
  // console.log( parseInt(event.target.value),'gggg', parseInt(this.getprice));
    if(this.discountRuleForm[index].then_apply.type=='amount'){
      if (parseInt( event.target.value)>parseInt(this.getprice)) {
         Swal.fire({
          position: 'center',
          icon: 'error',
         title:'Discount Price should not be greater than form price',
          padding: '2em',
        });
        this.discountRuleForm[index].then_apply.value=''
        // this.discountRuleForm[index]
      }

    }else{
      if (parseInt( event.target.value)>100) {
        // return false;
        Swal.fire({
          position: 'center',
          icon: 'error',
         title:'Discount Percentage should not be greater than 100',
          padding: '2em',
        });
        this.discountRuleForm[index].then_apply.value=''
        // this.discountRuleForm[index]
      }
    }
    
        // return true;
   
 // console.log(this.discountRuleForm[index].then_apply.value,"ddd");
   
   
  }
  categoryChange(categoryId: any,index:number) {
    // alert(1)
  // console.log(typeof index,'carrr',index);
    console.log(this.RuleStudentArr.length,'RULELENGHR')
    this.selectedCategory = categoryId;
    if(this.RuleStudentArr.length > 0) {
    // setTimeout(() => {
  // console.log(this.allCategories,'ccc');
  // this.showSubCategoryList = this.allSubCategories.filter((el) => {
  //   return el.parent_id == categoryId;
  // });
  this.discountRuleForm[0].if_condition.conditions[index].sub_cat_list= this.allSubCategories.filter((el) => {
    return el.parent_id == categoryId;
  });
console.log(this.discountRuleForm[0].if_condition.conditions[index],'catactactact');
// console.log(this.newarr,'999-==',index);
//   if(index>1){
//     this.newarr.push(
//       {
//         cat_id:'',
//         subcat:''
//       })
//     }
//   this.newarr[index].cat_id =categoryId
//   this.newarr[index].subcat=this.discountRuleForm[0].if_condition.conditions[index].sub_cat_list
//  console.log(this.newarr,'showwwwwwwwwwwuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuu');
 
//  this.newarr.forEach((el) => {
// // console.log(el.subcat,'el');
  
//     el.subcat.forEach((e:any)=>{
//       e.parent_id=e.parent_id.toString();
//     }) 
//   })
// console.log(this.newarr,'rrrrrrrr')

// },500);
    }
    else{
      this.discountRuleForm[0].if_condition.conditions[index].sub_cat_list = this.allSubCategories.filter((el) => {
        return el.parent_id == categoryId;
      });
      // this.newarr[index].cat_id = categoryId
      // this.newarr[index].subcat=this.showSubCategoryList
    // console.log(this.newarr,'new');
      
    //   this.newarr.forEach((el) => {
    // // console.log(el.subcat,'el');
      
    //     el.subcat.forEach((e)=>{
    //       e.parent_id=e.parent_id.toString();
    //     }) 
    //   })
    // console.log(this.newarr,'final================');
  
    }
    // console.log(
    //   this.discountRuleForm[0].if_condition.conditions[index]
    // ,"dddddindexxxxxxxxxx"
    //   );
    // console.log(this.showSubCategoryList,'dddddddddddddddddddddddddddd');
    
  }
  SubcategoryChange(subcatid:any,index:number){
    if(this.RuleStudentArr.length > 0) {
      // setTimeout(() => {
    // console.log(this.allCategories,'ccc');
    // this.showSubSubCategoryList = this.allsubsubcat.filter((el) => {
    //   return el.parent_id == subcatid;
    // });
    this.discountRuleForm[0].if_condition.conditions[index].sub__sub_cat_list=this.allsubsubcat.filter((el) => {
      return el.parent_id == subcatid;
    });
  // console.log(this.showSubCategoryList,'showwwwwwwwwwwwww');
  // console.log(this.newarr,'999-==',index);
    // if(index>1){
    //   this.newarr.push(
    //     {
    //       cat_id:'',
    //       subcat:'',
    //       subsubcat:''
    //     })
    //   }
    // this.newarr[index].cat_id =this.discountRuleForm[0].if_condition.conditions[index].category_id
    // this.newarr[index].subcat=this.discountRuleForm[0].if_condition.conditions[index].sub_cat_list
    // this.newarr[index].subsubcat=this.showSubSubCategoryList
  //  console.log(this.newarr,'showwwwwwwwwwwuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuu');
   
  //  this.newarr.forEach((el) => {
  // // console.log(el.subcat,'el');
    
  //     el.subcat.forEach((e:any)=>{
  //       e.parent_id=e.parent_id.toString();
  //     }) 
  //   })
  // console.log(this.newarr,'rrrrrrrr')
  
  // },500);
      }
      else{
        this.discountRuleForm[0].if_condition.conditions[index].sub__sub_cat_list = this.allsubsubcat.filter((el) => {
          return el.parent_id == subcatid;
        });
    //     this.newarr[index].cat_id = subcatid
    //     this.newarr[index].subcat=this.showSubCategoryList
    // this.newarr[index].subsubcat=this.showSubSubCategoryList

      // console.log(this.newarr,'new');
        
      //   this.newarr.forEach((el) => {
      // // console.log(el.subcat,'el');
        
      //     el.subsubcat.forEach((e)=>{
      //       e.parent_id=e.parent_id.toString();
      //     }) 
      //   })
      // console.log(this.newarr,'final================');
    
      }
  }
  getCategoriesList() {
    this.formService
      .fetchCategoryList(this.categoryLevel)
      .pipe(takeUntil(this.destroy$))
      .subscribe((result) => {
        this.allCategories = result["data"];

        this.allCategories.map((category) => {
          if (category.sub_categories.length > 0) {
            category.sub_categories.map((subCategory) => {
              this.allSubCategories.push(subCategory);
              console.log(this.allSubCategories,"ALLLLL")
              subCategory.subsubcategories.map((subsub)=>{
this.allsubsubcat.push(subsub);
              })
              
            });
          }
          console.log(this.allsubsubcat,"ALLLLLsubsubsusbus")
        });
      }
      ,
      error =>{
        if(error.error['message'] =='Unauthenticated.'){
          this.authService.clear();
  
  
        }
      }
      
      )
      // .add(() => {
      //   this.getFormBuilderData();
      // });
  }
  removeRow(discountRuleFormIndex: number, index: number) { 
  // console.log(index,'index');
    
    this.discountRuleForm[discountRuleFormIndex].if_condition.conditions.splice(index, 1);
    this.newarr.splice(index, 1);
  }
  addNewRow(ruleindex) {
    // if(this.discountFields !== undefined && this.discountFields.length > 0) {
      this.discountRuleForm[ruleindex].if_condition.conditions.push({
        "category_id": "",
        "subcategory_id":"",
        "sub_sub_category_id":"",
        "sub_cat_list":[],
        "sub__sub_cat_list":[],
        

        "startDate":"",
        "endDate":"",
      });
      this.newarr.push(
        {
          cat_id:'',
          subcat:'',
          subsubcat:''
        }
      )
      console.log(this.discountRuleForm,'rrrrrrrrrrrrrr');
      
    // // } else {
    //   this.discountRuleForm[ruleindex].if_condition.conditions.push({
    //     "field_type": this.basicInfoFields[0].slug,
    //     // "constraint": this.ruleConditionList[0].name,
    //     // "constraint_id": this.ruleConditionList[0].id,
    //     "value": "",
    //     "showValueBox": true
    //   // })
    // // }
  }
  removeRule(index,id) {
    // console.log(index,'piddd');
    Swal.fire({
      title: "Are you sure you want to delete this rule ?",
      // text:"sas",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText:"Yes" ,
    }).then((result)=>{
      if(result.value){
            if(index ==0){
              // alert('0')
            this.ReturnRuleShow.emit(true)
            this.hidesave=true
              
            }
          // console.log(this.discountRuleForm,'aaaaaaaaaaaaaaaaaaaaa');
            
            this.discountRuleForm.splice(index, 1);
        console.log(this.discountRuleForm,'aaaaaaaaaaaaaaaaaaaaa');
            // alert(id)
            // let id={}
            id = id+',returning_student'
            if(id !=undefined) {
              this.formService
              .DeleteDiscountRules(id)
              .pipe(takeUntil(this.destroy$))
              .subscribe((result) => {
                this.result = result;
                // this.formService.getDiscountRuleConditionsList();
                Swal.fire({
                  title: "",
                  text: result['message'],
                  icon: "success",
                  timer: 2000,
                  showConfirmButton: false,
                  showClass: {
                    popup: "animate__animated animate__fadeInDown",
                  },
                  hideClass: {
                    popup: "animate__animated animate__fadeOutUp",
                  },
                });
                // this.groupRuleEditData();
          
                // this.handleDiscountRuleConditions();
          
                // this.FinArr = this.result.data;
              });
        
            }
  
      }
    })
 
    }
  saveRule(){
    let errors = [];
console.log(this.discountRuleForm,'dddddddddddddddddddddddddddd')
  

this.discountRuleForm.map((obj: any, i: number) => {
      if(obj.rule_name === '' || obj.then_apply.value === '') {
        // alert('yes')
        errors.push({'index': i, 'object': obj});
      }
      obj.if_condition.conditions.map((obj2:any,j:any)=>{
        // if(obj2.category_id === '' || obj2.subcategory_id === '') {
          if(obj2.category_id === '') {
          // alert('yes')
          errors.push({'index': i, 'object': obj2});
        }
      })
      if(obj.discount_proof_title === '' || obj.discount_proof_description === '' ) {
        errors.push({'proof_index': i, 'object': obj});
      }

      // obj.if_condition.conditions.map((consObj: any, ci: number) => {
      //   if(consObj.showValueBox && consObj.value === '') {
      //     errors.push({'condition_index': ci, 'object': consObj});
      //   }
      // });

    });
   
console.log(errors,'wwwwwwww')
    if(errors.length > 0 ) {
      this.validate = true;
    } 
    else{
      // alert('Returning Student Discount Saved Successfully');
      let formData= new FormData();

      Parameter :
      // form == form id
      // returning_student = yes
      // max_discount_type
      // max_discount_amount
      // discount_returning_student = single object of the rule

      formData.append('form',this.form_id);
      // formData.append('is_preconfigured',this.isPreconfigCheck ? 'yes' : 'no');
      if(parseInt(this.MaxDisArr.max_discount_amount) > parseInt(this.discountRuleForm[0].then_apply.value) && this.MaxDisArr.max_discount_amount!=undefined){
        // alert('pppp')
            formData.append('max_discount_amount',this.MaxDisArr.max_discount_amount)
          formData.append('max_discount_type',this.MaxDisArr.max_discount_type)
          }
          else{
            if(this.discountRuleForm[0].then_apply.type =='amount'){
              formData.append('max_discount_amount',this.discountRuleForm[0].then_apply.value)
              formData.append('max_discount_cal_type','amount')

            }else{
              let x = parseInt(this.form_price) * (parseInt(this.discountRuleForm[0].then_apply.value)/100)
              formData.append('max_discount_amount',x.toString())
              formData.append('max_discount_cal_type','percent')


            }
            formData.append('max_discount_type','returning')
    
          }
      formData.append('returning_student', 'yes');
      // formData.append('max_discount_type', 'returning');
      // //static discount max yet to be checked when all are done   
      // formData.append('max_discount_amount', this.discountRuleForm[0].then_apply.value);
      formData.append('discount_returning_student',  JSON.stringify(this.discountRuleForm));


      this.discountGroupService.saveFormDiscountRule(formData).subscribe((result: any) => {
        // api hit to refresh data
      result['data']['data']['returningStudentRules'].forEach((rule,i)=>{
          this.idtopass = rule.id
        })
        console.log(this.idtopass,'idtopass')
        this.discountRuleForm.forEach((val:any,i:any)=>{
val['id'] =this.idtopass
        })
console.log(this.discountRuleForm,'rrrr')
        this.discountGroupService.setMaxDiscount(true)
        if(result['success']) {
          Swal.fire({
            position: 'center',
            icon: 'success',
            html: result['message'],
            padding: '2em',
          });
          // this.ngZone.run(() => {
          //   this.router.navigate(['/settings/discount-groups']);
          //   this.discountRuleForm = [];
          //   this.addNewRule();
          // });
        
        } else {
          Swal.fire({
            position: 'center',
            icon: 'error',
            html: result['message'],
            padding: '2em',
          });
        }
      }, error => {
        Swal.fire({
          position: 'center',
          icon: 'error',
          html: error.error['message'],
          padding: '2em',
        });
      });
    }
  }
}
