import { Component, OnInit, HostListener, ViewChild, ElementRef } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormsService } from 'src/app/components/forms/forms.service';
import { LayoutService } from '../../services/layout.service';
import { value } from '../../../components/forms/manage-forms/formField-global.model';
import { DragulaService } from "ng2-dragula";
import { CommonService } from '../../services/common.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { AuthService } from '../../services/firebase/auth.service';
import Swal from 'sweetalert2';
import { ToastrService } from 'ngx-toastr';

// import * as countries from '../../mock-data-json/countriesList.json';

@Component({
  selector: 'app-customizer',
  templateUrl: './customizer.component.html',
  styleUrls: ['./customizer.component.scss']
})
export class CustomizerComponent implements OnInit {

  @ViewChild("resdominput") resdominput;
  @ViewChild("allowdominput") allowdominput;

  public screenwidth: any = window.innerWidth;
  public customizer: string = '';
  public layoutType: string = 'ltr';
  public sidebarType: string = 'compact-wrapper';
  public sidebarSetting: string = 'default-sidebar';
  public MIXLayout: string = 'default';

  public primary_color: string = '#066CC1';
  public secondary_color: string = '#f73164';

  singleFieldLabel: string = 'Single Line';
  required_label_err:any
  formFieldItem: any = {};
  formFieldItemIndex: any = null;

  pageBreakItem: any = null;

  minMaxError: string = '';

  pageBreakIndex = null;

  pageBreakSettings: any = null;

  formFieldAttributes: any = [];

  countriesList = [];
  linkert_mssg:any
  fieldItemIndex: number = 0;
  donotclose:boolean =true
  fbIndex: any = null;// Customize Primary Color
  groupIndex: any = null;

  isFieldRequired: boolean = false;
  dup_mssg:any
  dup_mssg_radio:any
  allowCountryCode: boolean = false;

  countryOptions: boolean = false;

  availDomainErr: boolean = false;
  dup_mssg_autocomplete:any
  allowNumbersOnlyPhone: boolean = false;
  public ckEditor = "Ck Editor";
  config = {
    uiColor: '#ffffff',
    toolbarGroups: [{ name: '', groups: [] },
    { name: '', groups: [] },
    { name: '' }, { name: '' },
    { name: '', groups: ['mode', '', ''] },
    { name: '', groups: ['basicstyles', ''] },
    { name: '',   groups: [ 'list', 'indent', 'align'] },
    { name: '' },
    { name: '' },
    { name: 'colors' }],
    
    skin: 'kama',
    resize_enabled: true,
    removePlugins: 'elementspath,save,magicline',
    //extraPlugins: 'divarea,smiley,justify,indentblock,colordialog',
    colorButton_foreStyle: {
      element: 'font',
      attributes: { 'color': '#(color)' }
    },
    removeDialogTabs: 'image:advanced;link:advanced',
    removeButtons: 'Subscript,Superscript,Anchor,Source,Table',
    format_tags: 'p;h1;h2;h3;pre;div'
  }
  arr_store:any=[]
  arr_store2:any=[]

  fieldSizeItem = [
    {
      text: 'Small',
      value: 'sm'
    },
    {
      text: 'Medium',
      value: 'md'
    },
    {
      text: 'Large',
      value: 'lg'
    }
  ];

  SortingOptions = [
    {
      text: 'Default',
      value: 'default'
    },
    {
      text: 'Ascending',
      value: 'asc'
    },
    {
      text: 'Descending',
      value: 'desc'
    }
  ];

  ColumnLayouts = [
    {
      text: '1 Column',
      value: 1
    },
    {
      text: '2 Columns',
      value: 2
    },
    {
      text: '3 Columns',
      value: 3
    }
  ]

  RatingType = [
    {
      text: 'Star',
      value: 'star'
    },
    {
      text: 'Heart',
      value: 'heart'
    },
    {
      text: 'Flag',
      value: 'flag'
    },
    {
      text: 'Circle',
      value: 'circle'
    },
    {
      text: 'Emoji faces',
      value: 'emoji'
    }
  ]

  minuteIntervals = [
    {
      value: 1,
      minuteText: 'Minutes'
    },
    {
      value: 5,
      minuteText: 'Minutes'
    },
    {
      value: 10,
      minuteText: 'Minutes'
    },
    {
      value: 15,
      minuteText: 'Minutes'
    },
    {
      value: 30,
      minuteText: 'Minutes'
    }
  ]

  separatorsList = ['.',',', '-', '_', ':', ';', '|', '&nbsp;'];
// RoundeOffList = ['1 Decimals','2 Decimals','3 Decimals','4 Decimals','5 Decimals']
RoundeOffList = [
  {
    roundVal: 1,
    roundLabel: '1 Decimal'
  },{
    roundVal: 2,
    roundLabel: '2 Decimal'
  },{
    roundVal: 3,
    roundLabel: '3 Decimal'
  },{
    roundVal: 4,
    roundLabel: '4 Decimal'
  },{
    roundVal: 5,
    roundLabel: '5 Decimal'
  }
]
  allowedDomainOptions = [
    {id: 0, name: 'Allow all domains'}, 
    {id: 1, name: 'Allow specific domains'}, 
    {id: 2, name: 'Restrict specific domains'}
  ];

  allowedDomainsInputData: any[] = [];
  restrictedDomainsInputData: any[] = [];

  allowedDomainStr: string = '';
  restrictedDomainStr: string = '';

  allowOthers: boolean = false;

  otherFieldType = [
    {
      label: 'Text',
      value: 'text'
    },
    {
      label: 'Checkbox',
      value: 'checkbox'
    },
    {
      label: 'Radio',
      value: 'radio'
    }
  ];
  weekDays = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

  pageGroupIndex = null;
  pageItemIndex = null;

  allowAutofillDate: boolean = true;

  constructor(private modalService: NgbModal,
    private toastr:ToastrService,
    private authService:AuthService, public layout: LayoutService,private common:CommonService, private formService: FormsService, private dragula: DragulaService) {
    this.countriesList = [];
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.screenwidth = event.target.innerWidth;
  }
  destroy$: Subject<boolean> = new Subject<boolean>();
  result:any
  fieldname:any
  showQuestions:boolean = false
  getcountry(){
    this.common.CountryList().pipe(takeUntil(this.destroy$)).subscribe(result => {
      this.result =result;
      this.countriesList  =this.result.data
      this.countriesList.forEach((count,i)=>{
        count.country_code=count.country_code.toLowerCase()
                })
    }, error => {
      // this.error(error);
      
        if(error.error['message'] =='Unauthenticated.'){
          this.authService.clear();
  
        }
        
    });
      }
  ngOnInit() {
this.getcountry()
    this.dragula.dropModel().subscribe(({targetModel}) => {
      console.log(targetModel,"TARGET MODEL")
        this.formFieldItem.values = targetModel;
      // console.log(targetModel,"yyyyyyyyyyyyyyyyyyy")
        
        setTimeout(() => {
          this.formService.updateFormFieldsValidationRules(this.formFieldItem);
        }, 100);
    });

    /** Service subscriber to get the selected field after clicking on settings gear icon --- - starts - */
    this.formService.getFormFieldsValidationRules().subscribe(result => {

      this.formFieldItem = result['fieldItem'];

      if (result['pageGroups'] && result['groupFields']) {
        // When the page break is there and setting up the fields inside the group
        this.pageGroupIndex = result['pagegroupindex'];
        this.pageItemIndex = result['pageitemindex'];
        this.pageBreakIndex = result['breakIndex'];
        this.fbIndex = null;
        this.groupIndex = null;
        this.formFieldItemIndex = null;
      } else if (result['pageGroups'] && !result['groupFields']) {
        // When the page break is there and setting up the fields and there is no group
        this.pageBreakSettings = result['fieldItem'];
        this.pageBreakItem = result['pageBreakData'];
        this.pageBreakIndex = result['breakIndex'];
        this.formFieldItemIndex = result['index'];
        this.pageItemIndex = null;
        this.pageGroupIndex = null;
        this.fbIndex = null;
        this.groupIndex = null;
      } else if (!result['pageGroups'] && result['groupFields']) {
        // When the page break is not there and setting up the fields inside the group
        this.fbIndex = result['fbindex'];
        this.groupIndex = result['groupindex'];
        this.pageItemIndex = null;
        this.pageGroupIndex = null;
        this.pageBreakIndex = null;
      } else {
        // When the page break is not there and setting up the fields and there is no group
        this.pageItemIndex = null;
        this.pageGroupIndex = null;
        this.pageBreakIndex = null;
        this.fbIndex = null;
        this.groupIndex = null;
        this.formFieldItemIndex = result['index'];
      }

      if(result && Object.keys(result['fieldItem']).length > 0) {
        this.customizer = 'option';

        if(this.formFieldItem.type == 'email') {
          if(this.formFieldItem.allowedDomainsList) {
            this.allowedDomainsInputData = this.formFieldItem.allowedDomainsList.split(', ');
          }
          if(this.formFieldItem.restrictedDomainsList) {
            this.restrictedDomainsInputData = this.formFieldItem.restrictedDomainsList.split(', ');
          }
        }

        let cls = document.getElementsByClassName('fieldSize');

        for (let index = 0; index < cls.length; index++) {
          cls[index].classList.remove('active');
        }

        if(this.formFieldItem.fieldSize == 'sm') {
          cls[0].classList.add('active');
          cls[1].classList.remove('active');
          cls[2].classList.remove('active');
        }if(this.formFieldItem.fieldSize == 'md') {
          cls[1].classList.add('active');
          cls[0].classList.remove('active');
          cls[2].classList.remove('active');
        } if(this.formFieldItem.fieldSize == 'lg') {
          cls[2].classList.add('active');
          cls[0].classList.remove('active');
          cls[1].classList.remove('active');
        }
      }
    });
    /** Service subscriber to get the selected field after clicking on settings gear icon --- - ends - */
    
    // 
    this.formService.getFormBuilderUpdate().subscribe(result => {
      if(result) {
        this.customizer = '';
        this.formService.setFormBuilderUpdate(false);
      }
    })
  }


  value={
    label:"",
    value:"",
    checked:false
  };
  valwithTimeStamp(val){
    val.at(-1).value= val.at(-1).value + new Date().getTime();
  }
  valwithTimeStampLikert(val){
    val.at(-1).value= val.at(-1).label + new Date().getTime();

  }
  // UNiqueIdforquesrioname(val:any){
  // // console.log(val,'vvvv')
  // }
  
  
  dupcheckbox(val,e,index,type:string){

  // console.log(type,'llll');
    
    let arr=[]
    val.forEach((x,i)=>{
      if(i == index){
            x.value=e.target.value
          }
    // console.log(x.label,'lll')
        if(x.label != '') {
          arr.push(x.label)
        }
    })
  // console.log(arr)
    
    // let strArray = [ "q", "w", "w", "w", "e", "i", "u", "r"];
    let findDuplicates = arr => arr.filter((item, index) => arr.indexOf(item) != index)
    if(findDuplicates(arr).length > 0){
      if(type =='checkbox'){
      // this.dup_mssg="Adding duplicate might conflict"
      Swal.fire({
        position: 'center',
        icon: 'error',
       title:'Adding duplicate might conflict',
        padding: '2em',
      });
      }
      if(type =='radio'){
      // this.dup_mssg_radio="Adding duplicate might conflict"
      Swal.fire({
        position: 'center',
        icon: 'error',
       title:'Adding duplicate might conflict',
        padding: '2em',
      });

      }
      if(type =='autocomplete'){
        // this.dup_mssg_autocomplete="Adding duplicate might conflict"
        Swal.fire({
          position: 'center',
          icon: 'error',
         title:'Adding duplicate might conflict',
          padding: '2em',
        });
  
        }
    }else{
      if(type =='checkbox'){
        this.dup_mssg=''

      }
      if(type =='radio'){
        this.dup_mssg_radio=""

      }
      if(type =='autocomplete'){
        this.dup_mssg_autocomplete=""
  
        }
    }


  }
  // likertInput(e,val,index,pid,pval){
    likertInput(e,val){
    
console.log(e.target.value,'eee',val)
let arr=[]
val.forEach((x,i)=>{
  // if(i == index){
  //       x.value=e.target.value
  //     }

  arr.push(x.label)
  
})
console.log(arr,'arr')

// let strArray = [ "q", "w", "w", "w", "e", "i", "u", "r"];
let findDuplicates = arr => arr.filter((item, index) => item!=''?arr.indexOf(item) != index:'')
// pval.at(pid)['key']='01'
// this.arr_store.push({arr:findDuplicates(arr),ind:pid})
this.arr_store.push({arr:findDuplicates(arr)})
console.log(this.arr_store,'arrstoreeeeeee');
this.arr_store2=this.arr_store.filter((item, index) => item.arr.length >0)
console.log(this.arr_store2,'afterrrrr');

console.log(findDuplicates(arr),'rr');
if(findDuplicates(arr).length >0){
  // pval.at(pid)['key']=true
  // this.linkert_mssg='Adding duplicate might conflict'
  Swal.fire({
    position: 'center',
    icon: 'error',
   title:'Adding duplicate might conflict',
    padding: '2em',
  });
}else{
  // this.linkert_mssg=''
}
  }
  showQuestion(){
    this.showQuestions=true
  }
  RemoveQuestion(index){
    // item.values.splice(valueIndex, 1)
    // alert(index)
    if(index==0){
this.showQuestions=false
    }
    this.formFieldItem.QuestionArr.splice(index, 1)
  }
  addValue(values: value[]){
    
    
    values.push(this.value);
    this.value={
      label:"",
      value:"",
      checked:false
    }
  }
  addQuestion(){
    this.formFieldItem.QuestionArr.push({
      "QuestionName":'Unique' + new Date().getTime(),
      "values": [
        {
         
          "value": "option-1"
        },
      
      ],
     })
  // console.log(this.formFieldItem.QuestionArr,'yyyyyyy')
  
  }
  addValueLikert(valuess,index){
    



    this.formFieldItem.QuestionArr[index].values.push({
      label:"",
      value:Date.now()
    })
  
  }
  // Open Modal
  openModal(popup) {
    this.modalService.open(popup, { backdropClass: 'dark-modal', centered: true });
  }

  // Open customizer
  Customizer(val) {

    if(this.donotclose){
      this.customizer = val;
  
      if(!this.customizer) {
        let aBoxEle = document.querySelector('.dndDropArea .field') as HTMLElement;
        let aBoxGroupEle = document.querySelector('.dndDropAreaGrouping .field') as HTMLElement;
        if(aBoxEle.className != "field"){
          if(aBoxEle.classList.contains("active")){
            aBoxEle.classList.remove("active");
          }
        }
        if(aBoxGroupEle && aBoxGroupEle !== null && aBoxGroupEle.className != 'field'){
          if(aBoxGroupEle.classList.contains("active")){
            aBoxGroupEle.classList.remove("active");
          }
        }
      }

    }
// else{
//   this.required_label_err="Label Field is required";
// }
  }

  // Customize Layout Type
  customizeLayoutType(val) {
    this.layoutType = val;
    this.layout.config.settings.layout_type = val;
    if(val == 'rtl') {
      document.getElementsByTagName('html')[0].setAttribute('dir', val);
    } else {
      document.getElementsByTagName('html')[0].removeAttribute('dir');
    }
  }

  // Customize Sidebar Type
  customizeSidebarType(val) {
    this.sidebarType = val;
  }

  // Customize Sidebar Setting
  customizeSidebarSetting(val) {
    this.sidebarSetting = val;
    this.layout.config.settings.sidebar_type = val;
  }

  // Customize Mix Layout
  customizeMixLayout(val) {
    this.MIXLayout = val;
    this.layout.config.settings.layout_version = val;
  }

  applyColor() {
    document.documentElement.style.setProperty('--theme-deafult', this.primary_color);
    document.documentElement.style.setProperty('--theme-secondary', this.secondary_color);
    this.layout.config.color.primary_color = this.primary_color;
    this.layout.config.color.secondary_color = this.secondary_color;
  }

  resetColor() {
    document.documentElement.style.setProperty('--theme-deafult', '#066CC1');
    document.documentElement.style.setProperty('--theme-secondary', '#f73164');
    (<HTMLInputElement>document.getElementById('ColorPicker1')).value = '#066CC1';
    (<HTMLInputElement>document.getElementById('ColorPicker2')).value = '#f73164';
    this.layout.config.color.primary_color = '#066CC1';
    this.layout.config.color.secondary_color = '#f73164';
  }

  updateField(event, field) {
    let type = event.target.name;
    let formField: any;
    if(field.label ===''){
      this.donotclose=false
  this.required_label_err="Label Field is required";

    }else if (field.label.length >99){
      this.donotclose=false

      this.required_label_err="label should not be greater than 99 words";


    }
    else{
      this.donotclose=true
      this.required_label_err="";
    }
    field[type] = (type === 'min' || type === 'max') ? parseInt(event.target.value) : event.target.value;
    if(this.fbIndex !== null && this.groupIndex !== null && this.pageGroupIndex === null && this.pageItemIndex === null && this.pageBreakIndex === null) {
      // Normal grouping without page break

      formField = {
        'field': field,
        'fbindex': this.fbIndex,
        'groupindex': this.groupIndex
      }
      console.log(field.label,"qwwqww")
      
      setTimeout(() => {
        console.log(formField,"11FFFFF")

        this.formService.updateFormFieldsValidationRules(formField);
      }, 100);
    }

    if(this.pageGroupIndex !== null && this.pageItemIndex !== null && this.pageBreakIndex !== null) {
      // When the page break is there and setting up the fields inside the group
      formField = {
        'field': field,
        'breakIndex': this.pageBreakIndex,
        'pagegroupindex': this.pageGroupIndex,
        'pageitemindex': this.pageItemIndex,
        'fieldIndex': this.formFieldItemIndex
      }
      // alert('12')

      setTimeout(() => {
        console.log(formField,"11112221FFFFF")

        this.formService.updateFormFieldsValidationRules(formField);
      }, 100);
    }

    if(this.pageGroupIndex === null && this.pageItemIndex === null && this.fbIndex === null && this.groupIndex === null && this.pageBreakIndex !== null) {
      // When the page break is there and setting up the fields and there is no group
      formField = {
        'field': field,
        'breakIndex': this.pageBreakIndex,
        'pageBreakItem': this.pageBreakItem,
        'fieldIndex': this.formFieldItemIndex
      }

      setTimeout(() => {
        console.log(formField,"3333FFFFF")
        this.formService.updateFormFieldsValidationRules(formField);
      }, 100);
    }

    if(this.pageGroupIndex === null && this.pageItemIndex === null && this.fbIndex === null && this.groupIndex === null && this.pageBreakIndex === null) {
      // When the page break is not there and setting up the fields and there is no group
      // let type = event.target.name;
      field[type] = (type === 'min' || type === 'max') ? parseInt(event.target.value) : event.target.value;

      formField = {
        'field': field
      }

      setTimeout(() => {
        console.log(field,"11112244441FFFFF")

        this.formService.updateFormFieldsValidationRules(formField);
      }, 100);
    }
  }

  updateFormFieldItem(event, field, other: any = null) {
    console.log(event.target.value,"EEEEEEEE","FIELD++++",field,other)
    
    if(event.target.value<0){
      field.negativeValuesAllowed=true
    }
    // console.log(field.label.length,"lengthh")
    // if(field.label.length >99){
    //   // alert('MAxx')
    //   return
    // }
    // console.log(field.min,"min",field.max)
    console.log(typeof field.max ,typeof event.target.value,"++++++++++++++++++++++++++++++++",field.min >= parseInt(event.target.value),field.max )
    
    if(other != null) {
      switch (other) {
        case 'min':
          if(field.max < parseInt(event.target.value)) {
            this.minMaxError = 'Min value should be less than max value';
          } else {
            this.minMaxError = ''
            this.updateField(event, field);
          }
          break;
        case 'max':
          if( field.min > parseInt(event.target.value)) {
            this.minMaxError = 'Min value should be less than max value';
          } else {
            this.minMaxError = '';
            this.updateField(event, field);
          }
          break;
        case 'fieldSize':
          let cls = document.getElementsByClassName('fieldSize');

          for (let index = 0; index < cls.length; index++) {
            cls[index].classList.remove('active');
          }
          field.fieldSize = event.target.value;
          this.updateField(event, field);
          break;
        case 'decimalRoundOff':
          field.decimalLength = parseInt(event.target.value);
          this.updateField(event, field);
          break;
      
        default:
          this.updateField(event, field);
          this.minMaxError = '';
          break;
      }
      // if(other === 'min' && field.max <= event.target.value) {
      //   this.minMaxError = 'Min value should be less than max value';
      // } else if(other === 'max' && field.min >= event.target.value) {
      //   this.minMaxError = 'Min value should be less than max value';
      // } else {
      //   this.updateField(event, field);
      //   this.minMaxError = '';
      // }
    } else {
      console.log('CHECKING TYPE', event.target.value, field.fieldSize)
      this.updateField(event, field);
    }
 
  }

  checkboxChange(event, field, optionType: string) {
    if(event.target.type =='radio'){
      let type = event.target.id;

      field[type] = event.target.checked;
      if(type == 'isRandom'){
        field.isRandomChoices =false
      }else{
        field.isRandom =false

      }

    }else{
      switch (optionType) {
        case 'isRequired':
          if(event.target.checked && (field.isHidden || field.isDisabled)) {
            this.toastr.error("This field can not be set mandatory because it's either set to hidden or disabled!");
          }
          field.isDisabled = false;
          field.isHidden = false;

          break;
        case 'isDisabled':
          if(event.target.checked) {
            if(field.required) {
              this.toastr.info('Disabled field can not be mandatory.');
              field.required = false;
            }
            if(field.type =='email'){
              if(field.allowedDomainArr.length > 0 || field.RestrictedDomainArr.length > 0) {
                this.toastr.info('Domain checks can not be added while the field is disabled.');
                field.allowedDomainArr = [];
                field.RestrictedDomainArr = [];
                field.allowedDomainsList = '';
                field.allowedDomainsList = '';
                this.allowedDomainStr = ' ';
                this.restrictedDomainStr = ' ';
              }

            }
          }
          break;
        case 'isHidden':
          if(event.target.checked) {
            if(field.required) {
              this.toastr.info('Hidden field can not be mandatory.');
              field.required = false;
            }
            if(field.type =='email'){
              if(field.allowedDomainArr.length > 0 || field.RestrictedDomainArr.length > 0) {
                this.toastr.info('Domain checks can not be added while the field is hidden.');
                field.allowedDomainArr = [];
                field.RestrictedDomainArr = [];
                field.allowedDomainsList = '';
                field.allowedDomainsList = '';
                this.allowedDomainStr = ' ';
                this.restrictedDomainStr = ' ';
              }

            }
          }
          break;
        case 'noDuplicates':
          if(event.target.checked && field.value != null && field.value != undefined && field.value.length > 0) {
            this.toastr.info('Can not set initial value with this check.');
            field.value = '';
          }
          break;
        case 'digitsLimit':
          if(event.target.checked && field.min && field.max) {
            this.toastr.info('Min & Max limit will check for digits length now.');
          }
          break;
        case 'allowNegative':
          if(document.getElementById('minBox') != null) {
            if(event.target.checked) {
              // document.getElementById('minBox').setAttribute('MIN', '-' + field.min.toString());
      
              field.min = -field.min;
              if(field.value != null && field.value != undefined && field.value.length > 0) {
                field.value = '';
                this.toastr.info('Initial value not required if negative values allowed.');
              }
            } else {
              field.min = field.min * -1;
              // document.getElementById('minBox').setAttribute('MIN', field.min.toString());
            }
          }
          break;
        case 'autofillDate':
          if(event.target.checked) {
            this.allowAutofillDate = false;
            if(field.type == 'date') {
              field.allowAutofillDate = false;
            }
          } else {
            this.allowAutofillDate = true;
          }
          break;
        case 'rangeDatetime':
            if(event.target.checked && field.type == 'datetime-local') {
              field.allowAutofillDateTime = false;
            }
          break;
        default:
          break;
      }
      //  else {
      //   document.getElementById('minBox').setAttribute('MIN', '1')
      // }
      let type = event.target.name;
      field[type] = event.target.checked;
    }
console.log(event,'e')
console.log(event.target.checked,'cc',event.target.type)
    let formField: any;
   

    if((this.pageBreakIndex !== null || this.pageBreakIndex !== undefined) && (this.pageBreakIndex == 0 || this.pageBreakIndex > 0) && (this.pageGroupIndex === null || this.pageGroupIndex === undefined) && (this.pageItemIndex === null || this.pageItemIndex === undefined)) {
   
      console.log('if')
      formField = {
        'field': field,
        'breakIndex': this.pageBreakIndex,
        'pageBreakItem': this.pageBreakItem,
        'fieldIndex': this.formFieldItemIndex
      }
      setTimeout(() => {
        console.log('1232132132132',formField)
        this.formService.updateFormFieldsValidationRules(formField);
      }, 100);
    } else if((this.pageBreakIndex !== null || this.pageBreakIndex !== undefined) && (this.pageGroupIndex !== null || this.pageGroupIndex !== undefined) && (this.pageItemIndex !== null || this.pageItemIndex !== undefined)) {
      console.log('else')
      formField = {
        'field': field,
        'breakIndex': this.pageBreakIndex,
        'pagegroupindex': this.pageGroupIndex,
        'pageitemindex': this.pageItemIndex,
        'fieldIndex': this.formFieldItemIndex
      }

      setTimeout(() => {
        console.log('1232132132132jjj',formField)

        this.formService.updateFormFieldsValidationRules(formField);
      }, 100);
    } else {
      setTimeout(() => {
        console.log('1232132132132kk',formField)

        this.formService.updateFormFieldsValidationRules(field);
      }, 100);
    }
  }

  frontEndDisplayPriorities(event, field, type) {
    let formField: any;
    switch (type) {
      case 'countryCode':
        field.countryCode = event.target.checked;
        this.allowCountryCode = event.target.checked;
        field.defaultCountry = event.target.checked;

        if(!event.target.checked){
          field.selectedCountryCode={}
          
        }
        break;
      case 'countryOptions':
        if(event.target.value === 'default_country') {
          field.defaultCountry = event.target.checked;
          // field.defaultCountry = true;
          field.obtainIPCountry = false;
          this.allowCountryCode = event.target.checked;
        }
        // if(event.target.value === 'obtain_ip_country') {
        //   field.defaultCountry = false;
        //   field.obtainIPCountry = true;
        //   this.allowCountryCode = false;
        //   field.selectedCountryCode = {};
        // }
        break;
      case 'allowNumbersOnly':
        field.allowNumbersOnly = event.target.checked;
        this.allowNumbersOnlyPhone = event.target.checked;
        break;
      case 'allowOthers':
        field.allowOthers = event.target.checked;
        this.allowOthers = event.target.checked;
        break;
      default:
        break;
    }
    // this.formFieldAttributes[this.fieldItemIndex] = field;
    if((this.pageBreakIndex !== null || this.pageBreakIndex !== undefined) && (this.pageBreakIndex == 0 || this.pageBreakIndex > 0)) {
      formField = {
        'field': field,
        'breakIndex': this.pageBreakIndex,
        'pageBreakItem': this.pageBreakItem,
        'fieldIndex': this.formFieldItemIndex
      }
      setTimeout(() => {
        console.log('aaa1232132132132',formField)

        this.formService.updateFormFieldsValidationRules(formField);
      }, 100);
    } else {
      setTimeout(() => {
        console.log('vvvvv1232132132132',formField)

        this.formService.updateFormFieldsValidationRules(field);
      }, 100);
    }
    // setTimeout(() => {
    //   this.formService.updateFormFieldsValidationRules(field);
    // }, 100);
  }

  allowLimitOnCharacterWords(event, field, type) {
    let formField: any;
    
    switch (event.target.value) {
      case 'word_limit':
        field.limitWords = true;
        field.limitCharatcers = false;
        break;
      case 'char_limit':
        field.limitCharatcers = true;
        field.limitWords = false;
      default:
        break;
    }

    if((this.pageBreakIndex !== null || this.pageBreakIndex !== undefined) && (this.pageBreakIndex == 0 || this.pageBreakIndex > 0)) {
      formField = {
        'field': field,
        'breakIndex': this.pageBreakIndex,
        'pageBreakItem': this.pageBreakItem,
        'fieldIndex': this.formFieldItemIndex
      }
      setTimeout(() => {
        console.log('1trtrtrrrr232132132132',formField)

        this.formService.updateFormFieldsValidationRules(formField);
      }, 100);
    } else {
      setTimeout(() => {
        console.log('1rrr232132132132',formField)

        this.formService.updateFormFieldsValidationRules(field);
      }, 100);
    }

    // setTimeout(() => {
    //   this.formService.updateFormFieldsValidationRules(field);
    // }, 100);
  }

  onPhoneCountryChange(event, field) {
    
    let formField: any;
console.log(event,"EVENT",this.allowCountryCode)
    if(this.allowCountryCode) {
   
      field.selectedCountryCode = event;
      formField = {
        'field': field,
        'breakIndex': this.pageBreakIndex,
        'pageBreakItem': this.pageBreakItem,
        'fieldIndex': this.formFieldItemIndex
      }
      this.formService.updateFormFieldsValidationRules(formField);
      this.countryOptions = true;
    } else {
      // this.countryOptions = false;
      field.obtainIPCountry = true;
      field.selectedCountryCode = {};
      this.formService.updateFormFieldsValidationRules(field);
      
    }

  }

  
  updateDomainsListing(event, field, domainType) {
    // let regex = /(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z0-9][a-z0-9-]{0,61}[a-z0-9]/g
    // let regex = /^([a-zA-Z0-9][a-zA-Z0-9-_]*\.)*[a-zA-Z0-9]*[a-zA-Z0-9-_]*[[a-zA-Z0-9]+$/
    let regex = /(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z0-9][a-z0-9-]{0,61}[a-z0-9]/;
    let formField: any;
    if(event.keyCode === 13 && event.key === 'Enter') {
      if(!regex.test(event.target.value)) {
        this.availDomainErr = true;
      } else {
        this.availDomainErr = false;
        switch (domainType) {
          case 'allowed':
            // this.allowedDomainsInputData.push(event.target.value);
          // console.log(   field.allowedDomainArr,"rrrrrrr");
            
            field.allowedDomainArr.push(event.target.value);
            this.allowedDomainStr = ' ';
            // field.allowedDomainsList = this.allowedDomainsInputData.join(',');
            field.allowedDomainsList =  field.allowedDomainArr.join(',');
            break;
          case 'restricted':
            // this.restrictedDomainsInputData.push(event.target.value);
            field.RestrictedDomainArr.push(event.target.value);

            this.restrictedDomainStr = ' ';
            // field.restrictedDomainsList = this.restrictedDomainsInputData.join(',');
            field.restrictedDomainsList = field.RestrictedDomainArr.join(',');
            break;
          default:
            break;
        }
      }
      
    } else if(event.keyCode === 8 && event.key === 'Backspace') {
      if(domainType == 'allowed' && field.allowedDomainArr.length > 0) {
        field.allowedDomainArr.pop();
        this.allowedDomainStr = ' ';
        field.allowedDomainsList =  field.allowedDomainArr.join(',');
      }
      if(domainType == 'restricted' && field.RestrictedDomainArr.length > 0) {
        field.RestrictedDomainArr.pop();
        this.restrictedDomainStr = ' ';
        field.restrictedDomainsList = field.RestrictedDomainArr.join(',');
      }
    }

    if((this.pageBreakIndex !== null || this.pageBreakIndex !== undefined) && (this.pageBreakIndex == 0 || this.pageBreakIndex > 0)) {
      formField = {
        'field': field,
        'breakIndex': this.pageBreakIndex,
        'pageBreakItem': this.pageBreakItem,
        'fieldIndex': this.formFieldItemIndex
      }
      setTimeout(() => {
        this.formService.updateFormFieldsValidationRules(formField);
      }, 100);
    } else {
      this.formService.updateFormFieldsValidationRules(field);
    }

  }

  updateAllowedDomains(index, field) {
    console.log('hitttt')
    field.allowedDomainArr.splice(index, 1);
    field.allowedDomainsList = this.allowedDomainsInputData.join(',');

    if((this.pageBreakIndex !== null || this.pageBreakIndex !== undefined) && (this.pageBreakIndex == 0 || this.pageBreakIndex > 0)) {
      this.formService.updateFormFieldsValidationRules({
        'field': field,
        'breakIndex': this.pageBreakIndex,
        'pageBreakItem': this.pageBreakItem,
        'fieldIndex': this.formFieldItemIndex
      });
    } else {
      this.formService.updateFormFieldsValidationRules(field);
    }
  }

  updateRestrictedDomains(index, field) {
    // this.restrictedDomainsInputData.splice(index, 1);
    field.RestrictedDomainArr.splice(index, 1);
    field.restrictedDomainsList = this.restrictedDomainsInputData.join(',');

    if((this.pageBreakIndex !== null || this.pageBreakIndex !== undefined) && (this.pageBreakIndex == 0 || this.pageBreakIndex > 0)) {
      this.formService.updateFormFieldsValidationRules({
        'field': field,
        'breakIndex': this.pageBreakIndex,
        'pageBreakItem': this.pageBreakItem,
        'fieldIndex': this.formFieldItemIndex
      });
    } else {
      this.formService.updateFormFieldsValidationRules(field);
    }
  }

  focusOnResDom() {
    this.resdominput.nativeElement.focus();
  }

  focusOnAllDom() {
    this.allowdominput.nativeElement.focus();
  }

  changeAllowedDays(event, field) {
    field.allowedDays = event;
    this.formService.updateFormFieldsValidationRules(field);
  }

  saveSettings(field) {
    console.log(field,"FIELDIDLEDIDLs")
    if(field.type =='range'){
      field.value = Number(field.value)
    }
    console.log(typeof field.value,"VVV",typeof field.min,field.value<field.min)
    if(field.min>0 && field.negativeValuesAllowed){
      // Swal.fire({
      //   position: 'center',
      //   icon: 'error',
      //  title:'Min Value must be negative for allowing negative number checkbox condition',
      //   padding: '2em',
      // });
      this.toastr.error('Min Value must be negative for allowing negative number checkbox condition');

      return
    }
    if(!field.isUnique && !field.digitsLimit && field.value && field.value>field.max){
      this.toastr.error('Initial Value must be less than max value');

      return
    } else if(!field.isUnique && field.digitsLimit && field.value && field.value.length > field.max) {
      this.toastr.error('Initial Value digits must be less than max value');
      return
    } else if(field.required && (field.isHidden || field.isDisabled)) {
      this.toastr.error("This field can not be set mandatory because it's either set to hidden or disabled!");
      return
    } else if(field.type =='email') {
     if((field.isHidden || field.isDisabled) && (field.allowedDomainArr.length > 0 || field.RestrictedDomainArr.length > 0) ) {
      this.toastr.error("This field can not have domains check because it's either set to hidden or disabled!");
      return
    }
    // "allowedDomainArr": [],
    // "RestrictedDomainArr": [],
       const duplicates = [];
   field.allowedDomainArr.forEach((item)=>{
    console.log(item,"item");
    
    field.RestrictedDomainArr.forEach((ite)=>{
      console.log(ite,"ite");
      if(ite == item){
        duplicates.push(item);
      }

    })
   })
   if(duplicates.length>0){
    this.toastr.error("No Duplicate Domains allow in allowed domain and restricted domain");
    return
   }

  }
  if(field.type =='range' && field.value<field.min){
    this.toastr.error("Intial Value cannot be less than min value!");
    return
    
  }else if(field.type =='range' && field.value>field.max){
    this.toastr.error("Intial Value cannot bre greater than max value!");
    return
  }
  if(field.isRandomChoices && field.choices==''){
    this.toastr.error("Choices cannot be empty if random subset is selected");
    return
  }else if(field.isRandomChoices && field.choices=='0'){
    this.toastr.error("Choices cannot be 0 if random subset is selected");
    return
  } else if(field?.values != undefined && field?.values.length > 0) {
    let unit = field.values.filter(el => { return el.label == '' || el.label == null });
    if(unit.length > 0) {
      this.toastr.error("Unit of count cannot be empty");
      return;
    }
  }
  if(field.min>field.max){
    this.minMaxError="Min value should be less than max value"
    return
  }
  console.log(field.min,field.max,"))))")
  this.formService.saveCurrentForm(true);
  this.toastr.success('Settings Saved Successfully');
}

unitChange(event: any, item: any, valueIndex: number) {
  console.log(event.target.value, item)
  if(event.target.value != '') {
    item.values[valueIndex].value = `${event.target.value}-${Date.now()}`;
  }
}

}
