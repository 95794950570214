import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { takeUntil } from 'rxjs/operators';
import { ApiClientService } from 'src/app/shared/services/api-client.service';
import Swal from 'sweetalert2';

@Injectable({
  providedIn: 'root'
})
export class CategoryService {

  constructor(
    public toster: ToastrService,
    private apiClientService: ApiClientService,
    private router: Router
  ) { }

  getCategoryList(rows, page, col, ord) {
    return this.apiClientService.get('category_list?level=1&rows=' + rows + '&page=' + page + '&col=' + col + '&order=' + ord);
  }

  getfinanceList(rows, page, col, ord) {
    return this.apiClientService.get('finance_category?level=1&rows=' + rows + '&page=' + page + '&col=' + col + '&order=' + ord)
  }
  getSubCategoryList(rows, page, id) {
    return this.apiClientService.get('category_list?level=2&category_id=' + id + '&rows=' + rows);
  }

  getSubSubCategoryList(rows, page, col, ord, id) {
    return this.apiClientService.get('category_list?level=3&category_id=' + id + '&rows=' + rows + '&page=' + page + '&col=' + col + '&order=' + ord);
  }

  getsubcatforms(data, pno, rows, col, ord, search) {
    return this.apiClientService.post('category_forms?rows=' + rows + '&page=' + pno + '&col=' + col + '&order=' + ord + '&search=' + search, data);
  }

  removeAdd(data) {
    return this.apiClientService.post('form_add_remove', data);
  }

  categorySort(data) {
    return this.apiClientService.post('category_sorting', data);
  }

  catChangeStatus(data) {
    return this.apiClientService.post('manage_category_status', data);
  }
  ChangeStatus(data) {
    this.apiClientService.post('manage_category_status', data).subscribe(result => {
      if (result && result['success']) {

      } else {

      }
    })
  }

  createCategory(data) {
    this.apiClientService.post('add_category', data).subscribe(result => {
      // if(result && result['success']) {
      this.toster.success(result['message']);
      // this.router.navigate(['categories/category-list'])
      // } else {
      // this.toster.error(result['message']);
      // }
    }, error => {
      this.toster.error(error.error.message);

    })
  }


  createfinance(data) {
    const form = {

      "finance_group_name": data.value.FormGroupNameControl,
      "receivable_activity_name": data.value.activityname,
      "receivable_activity_account_code": data.value.accountcode,
      "cost_center_name": data.value.costcentername,
      "status": '1'
    }

    this.apiClientService.post('finance_category', form).subscribe(result => {
      if (result && result['success']) {
        this.toster.success(result['message']);
        this.router.navigate(['finance/finance-categories'])
      } else {
        this.toster.error(result['message']);
      }
    })
  }



  editCategory(id) {
    return this.apiClientService.get('category_detail?category_id=' + id);
  }

  deleteCategory(id) {
    return this.apiClientService.get('category/' + id);
  }
  // Swal.fire({
  //   title: 'Are you sure?',
  //   text: 'You want to delete it',
  //   icon: 'warning',
  //   showCancelButton: true,
  //   confirmButtonColor: '#3085d6',
  //   cancelButtonColor: '#d33',
  //   confirmButtonText: 'Yes'
  // }).then((result)=>{
  //   if(result.value) {
  //     return this.apiClientService.get('category/'+id).subscribe(result => {
  //       Swal.fire({
  //         title: '',
  //         html: result['message'],
  //         icon: 'success',
  //         timer: 2000,
  //         showConfirmButton: false,
  //         showClass: {
  //           popup: 'animate__animated animate__fadeInDown'
  //         },
  //         hideClass: {
  //           popup: 'animate__animated animate__fadeOutUp'
  //         }
  //       });
  //     },error=>{
  //       Swal.fire({
  //         title: '',
  //         html: error.error['message'],
  //         icon: 'error',
  //         timer: 2000,
  //         showConfirmButton: false,
  //         showClass: {
  //           popup: 'animate__animated animate__fadeInDown'
  //         },
  //         hideClass: {
  //           popup: 'animate__animated animate__fadeOutUp'
  //         }
  //       });
  //     })
  //   }
  // })


  editfinanceCategory(id) {
    return this.apiClientService.get(`finance_category/${id}/edit`)
  }

  updateCategory(data) {
    this.apiClientService.post('update_category', data).subscribe(result => {
      if (result && result['success'] == true) {
        this.toster.success(result['message']);
        // this.router.navigate(['categories/category-list'])

      }
    }, error => {
      this.toster.error(error.error['message']);
    })
  }

  //   updateFinance(data, id, status) {
  //     const api = {
  //       receivable_activity_name: data.activeName,
  //       receivable_activity_account_code: data.accountCode,
  //       cost_center_name : data.costname,
  //       status: status
  //     }

  //     this.apiClientService.put('finance_category/'+id, api).subscribe(result => {
  //       if(result && result['success']== true) {  
  //               this.router.navigate(['finance/finance-categories'])
  //       }
  //     }, error => {
  //       this.toster.error(error.error['message']);
  //     })
  //   }
  updateFinance(data, id) {
    return this.apiClientService.post('finance_category/' + id, data);
  }
}
