import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { DiscountGroupService } from 'src/app/components/discount/discount-group.service';
import { FormsService } from 'src/app/components/forms/forms.service';
import Swal from 'sweetalert2';
import { CommonService } from '../../services/common.service';
import { takeUntil } from "rxjs/operators";
import { Subject } from 'rxjs';
@Component({
  selector: 'app-spicific-discount',
  templateUrl: './spicific-discount.component.html',
  styleUrls: ['./spicific-discount.component.scss']
})
export class SpicificDiscountComponent implements OnInit {
  files: any[] = [];
  @Input() form_id:any
@Input() SpecificDiscount: any[] = [];
@Input() form_price: string = ''; // decorate the property with @Input()

idtopass:any=''

  constructor(
    private discountGroupService: DiscountGroupService,
    private common:CommonService,
    private formService: FormsService


  ) { }
  result:any
destroy$: Subject<boolean> = new Subject<boolean>();

  discountRuleForm = [
    {
      "rule_name": "",
      "if_condition": {
        "allow": "any",
        "conditions": [{
          "email":[],
          "domain":[]
        }]
      },
      "then_apply": {
        "type": "percent",
        "value": ""
      },
      "discount_proof_title": "",
      "discount_proof_description": "",
     
    }
  ]
  @Output() SpecificRuleShow = new EventEmitter();

  hidesave:boolean = false
  @ViewChild("allowdominput") allowdominput;
  @ViewChild("alloweminput") alloweminput;
  allowedDomainStr: string = "";
  allowedEmailStr: string = "";
  allowedDomainsInputData: any[] = [];
  allowedEmailInputData: any[] = [];
  validate = false;
  email_error:any
  emailarr:any=[]
  domainarr:any=[]
  thenApply = ['percent', 'amount'];
  pdf_error:any
  emailFromCSV:any=[]
  DomainFromCSV:any=[]
  domain_err:any
  csvlink:any
  getprice:any
  email_err:boolean=false
  domains_err:boolean=false
  @Input() MaxDisArr: any; // decorate the property with @Input(


  ngOnInit(): void {
    this.common.getprice().subscribe(res => {
      this.getprice=res
      })
    // console.log(this.SpecificDiscount,'ssss');
      if(this.SpecificDiscount!=undefined){
        if(this.SpecificDiscount.length > 0) {
    this.discountRuleForm = this.SpecificDiscount
      this.allowedEmailInputData=this.discountRuleForm[0].if_condition.conditions[0].email
      this.allowedDomainsInputData=this.discountRuleForm[0].if_condition.conditions[0].domain
   // console.log(this.allowedDomainsInputData,'000',this.allowedEmailInputData)
        }

      }
    this.getSampleCSV()
  }
  getSampleCSV(){
    this.discountGroupService.GetSampleCSV().subscribe((result) =>{
      this.csvlink=result['data']['file_path']
    })
  }
  focusOnAllDom() {
    this.allowdominput.nativeElement.focus();
    this.alloweminput.nativeElement.focus();
  }
    // removeRow(discountRuleFormIndex: number, index: number) { 
    //   this.discountRuleForm[discountRuleFormIndex].if_condition.conditions.splice(index, 1);
    // }
  // addNewRow(ruleindex) {
  //   // if(this.discountFields !== undefined && this.discountFields.length > 0) {
  //     this.discountRuleForm[ruleindex].if_condition.conditions.push({
  //       "field_type": '',
  //       // "constraint": this.ruleConditionList[0].name,
  //       // "constraint_id": this.ruleConditionList[0].id,
  //       // "value": "",
  //       "showValueBox": true
  //     });
  //   // // } else {
  //   //   this.discountRuleForm[ruleindex].if_condition.conditions.push({
  //   //     "field_type": this.basicInfoFields[0].slug,
  //   //     // "constraint": this.ruleConditionList[0].name,
  //   //     // "constraint_id": this.ruleConditionList[0].id,
  //   //     "value": "",
  //   //     "showValueBox": true
  //   //   // })
  //   // // }
  // }
  deleteFile(index: number) {
    this.files.splice(index, 1);
  // console.log(this.files,'ssssssssssss');
    
  } 
  MaxDiscountLimit(event: any,index) {

       
  // console.log( parseInt(event.target.value),'gggg', parseInt(this.getprice));
    if(this.discountRuleForm[index].then_apply.type=='amount'){
      if (parseInt( event.target.value)>parseInt(this.getprice)) {
         Swal.fire({
          position: 'center',
          icon: 'error',
         title:'Discount Price should not be greater than form price',
          padding: '2em',
        });
        this.discountRuleForm[index].then_apply.value=''
        // this.discountRuleForm[index]
      }

    }else{
      if (parseInt( event.target.value)>100) {
        // return false;
        Swal.fire({
          position: 'center',
          icon: 'error',
         title:'Discount Percentage should not be greater than 100',
          padding: '2em',
        });
        this.discountRuleForm[index].then_apply.value=''
        // this.discountRuleForm[index]
      }
    }
    
        // return true;
   
 // console.log(this.discountRuleForm[index].then_apply.value,"ddd");
   
   
  }
  updateDomainsListing(event, field, domainType,index) {
    // const regularExpression = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    let newval =event.target.value
    newval= newval.charAt(0).toLowerCase() +  newval.slice(1);
    console.log(newval,'e+====')
    if (event.keyCode === 13 && event.key === "Enter" && event.target.value!='') {
      console.log(/^([a-zA-Z0-9][a-zA-Z0-9-_]*\.)*[a-zA-Z0-9]*[a-zA-Z0-9-_]*[[a-zA-Z0-9]+$/.test(newval),"+++++++++++++++")
      //  if (/^[a-zA-Z0-9][a-zA-Z0-9-]{1,61}[a-zA-Z0-9]\.[a-zA-Z]{2,}$/.test(newval)) {
        // if (/^([a-zA-Z0-9][a-zA-Z0-9-_]*\.)*[a-zA-Z0-9]*[a-zA-Z0-9-_]*[[a-zA-Z0-9]+$/.test(newval)) {   //last worked
          if (/(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z0-9][a-z0-9-]{0,61}[a-z0-9]/.test(newval)) {
          // if (/^[\w.!#$%&’*+\/=?^`{|}~-]+@[a-z0-9-]+(?:\.[a-z]{2,10}){1,2}$"/.test(newval)) {
          // this.domain_err=''
        switch (domainType) {
          case "allowed":
            this.allowedDomainsInputData.push(event.target.value);
            this.allowedDomainStr = " ";
            this.domain_err=''
this.domains_err=false

            this.domainarr.push(event.target.value);
            this.discountRuleForm[0].if_condition.conditions[index].domain= this.allowedDomainsInputData
      }
    }

else{
  this.domain_err='Domain is InValid'

  }

    }
  }
  updateEmailListing(event, field, domainType,index) {
    const regularExpression = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    

    if (event.keyCode === 13 && event.key === "Enter" && event.target.value!='') {
if(regularExpression.test(String(event.target.value).toLowerCase())==true)
{
  switch (domainType) {
    case "allowed":
      this.allowedEmailInputData.push(event.target.value);
      this.allowedEmailStr = " ";
      this.email_error=''
this.email_err=false

      // let emailarr=[]
    this.emailarr.push(event.target.value);
    // console.log(emailarr,'wwwwwwwwww');
    
      this.discountRuleForm[0].if_condition.conditions[index].email= this.allowedEmailInputData

  }
// console.log(
//   this.discountRuleForm[0].if_condition.conditions[index].email,
//   '{{{{{{{{{{{{{{'
// );

}else{
  this.email_error='Email is Invalid'
}
    }
  }
  updateAllowedDomains(index, field) {
    this.allowedDomainsInputData.splice(index, 1);
    this.domain_err=''
  }
  updateAllowedEmails(index, field) {
    this.allowedEmailInputData.splice(index, 1);
    this.email_error=''
  }
  removeRule(index,id) {
    // console.log(index,'piddd');
    Swal.fire({
      title: "Are you sure you want to delete this rule ?",
      // text:"sas",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText:"Yes" ,
    }).then((result)=>{
      if(result.value){
            if(index ==0){
              // alert('0')
            this.SpecificRuleShow.emit(true)
            this.hidesave=true
              
            }
          // console.log(this.discountRuleForm,'aaaaaaaaaaaaaaaaaaaaa');
            
            this.discountRuleForm.splice(index, 1);
        console.log(this.discountRuleForm,'aaaaaaaaaaaaaaaaaaaaa');
            // alert(id)
            // let id={}
            id = id+',specific'
            
            if(id !=undefined) {
              this.formService
              .DeleteDiscountRules(id)
              .pipe(takeUntil(this.destroy$))
              .subscribe((result) => {
                this.result = result;
                // this.formService.getDiscountRuleConditionsList();
                Swal.fire({
                  title: "",
                  text: result['message'],
                  icon: "success",
                  timer: 2000,
                  showConfirmButton: false,
                  showClass: {
                    popup: "animate__animated animate__fadeInDown",
                  },
                  hideClass: {
                    popup: "animate__animated animate__fadeOutUp",
                  },
                });
                // this.groupRuleEditData();
          
                // this.handleDiscountRuleConditions();
          
                // this.FinArr = this.result.data;
              });
        
            }
  
      }
    })
    }
  
  saveRule(){
    let errors = [];
console.log( this.emailarr,'rrrrrrrrrrrrrrrrrrrrrrrrrr');

    this.discountRuleForm.map((obj: any, i: number) => {
      if(obj.rule_name === '' || obj.then_apply.value === '') {
        // alert('yes')
        errors.push({'index': i, 'object': obj});
      }

      // obj.if_condition.conditions.map((consObj: any, ci: number) => {
      //   if(consObj.showValueBox && consObj.value === '') {
      //     errors.push({'condition_index': ci, 'object': consObj});
      //   }
      // });

      if(obj.discount_proof_title === '' || obj.discount_proof_description === '' ) {
        errors.push({'proof_index': i, 'object': obj});
      }
    });
    if(errors.length > 0 ) {
      this.validate = true;
    } 
    else if(this.allowedEmailInputData.length ==0 || this.allowedDomainsInputData.length ==0){
      if(this.allowedEmailInputData.length ==0){
this.email_err=true
      }
      if(this.allowedDomainsInputData.length ==0){
this.domains_err=true
      
      }
      return
    }
    else{
      let formData = new FormData();
      formData.append('form',this.form_id);
      // formData.append('is_preconfigured',this.isPreconfigCheck ? 'yes' : 'no');
      if(parseInt(this.MaxDisArr.max_discount_amount) > parseInt(this.discountRuleForm[0].then_apply.value) && this.MaxDisArr.max_discount_amount!=undefined){
        // alert('pppp')



            // formData.append('max_discount_amount',this.MaxDisArr.max_discount_amount)
            if(this.discountRuleForm[0].then_apply.type =='amount'){
              formData.append('max_discount_amount',this.discountRuleForm[0].then_apply.value)
        formData.append('max_discount_cal_type','amount')


            }else{
              let x = parseInt(this.form_price) * (parseInt(this.discountRuleForm[0].then_apply.value)/100)
              formData.append('max_discount_amount',x.toString())
        formData.append('max_discount_cal_type','percent')


            }
        
            formData.append('max_discount_type',this.MaxDisArr.max_discount_type)
          }
          else{
            formData.append('max_discount_amount',this.discountRuleForm[0].then_apply.value)
            formData.append('max_discount_type','specific')
    
          }
      formData.append('specific', 'yes');
      // formData.append('max_discount_type', 'specific');
    
      // formData.append('max_discount_amount', this.discountRuleForm[0].then_apply.value);
      formData.append('discount_specific',JSON.stringify(this.discountRuleForm));
      // formData.append('num_of_seats', this.numberofseats);
      // formData.append('rule_name', this.numberofseats);
      

      //static discount max yet to be checked when all are done   
      this.discountGroupService.saveFormDiscountRule(formData).subscribe((result: any) => {
        this.discountGroupService.setMaxDiscount(true)
        result['data']['data']['SpecificRule'].forEach((rule,i)=>{
          this.idtopass = rule.id
        })
        console.log(this.idtopass,'idtopass')
        this.discountRuleForm.forEach((val:any,i:any)=>{
val['id'] =this.idtopass
        })
console.log(this.discountRuleForm,'rrrr')
        if(result['success']) {
          Swal.fire({
            position: 'center',
            icon: 'success',
            html: result['message'],
            padding: '2em',
          });
          // this.ngZone.run(() => {
          //   this.router.navigate(['/settings/discount-groups']);
          //   this.discountRuleForm = [];
          //   this.addNewRule();
          // });
        
        } else {
          Swal.fire({
            position: 'center',
            icon: 'error',
            html: result['message'],
            padding: '2em',
          });
        }
      }, error => {
        Swal.fire({
          position: 'center',
          icon: 'error',
          html: error.error['message'],
          padding: '2em',
        });
      });
    }
  }
  onFileDropped($event: any) {
    this.prepareFilesList($event);
  }
  fileBrowseHandler(files: any) {
  // console.log(files,'ffff');
    
    let file_type = files[0].type;
    // alert(file_type)
    if (
   
      file_type === "text/csv"
    ) {
      // this.pdf_error = "";
      // this.file_size = files[0].size / 1024 / 1024;
      
      // if (parseFloat(this.file_size) > 5) {
      //   files = [];
      //   // this.pdf_error = "File size should be only 5 mb.";
      // }
      //  else {
        this.prepareFilesList(files);
      // }
    } else {
      alert('Only Csv Allowed')
      // this.pdf_error = "Allowed Formats (pdf, jpg, png)";
    }
  }
  prepareFilesList(files: Array<any>) {
 
console.log(this.files.length,'length');

    
    if (this.files.length > 0) {
      this.pdf_error = "Only One files are allowed to upload";
      alert('Only One files are allowed to upload')
    }
  
   else{
   
     for (const item of files) {
       item.progress = 0;
       this.files.push(item);
       
     }
    }
     
  

    this.uploadFilesSimulator(0);
    
  }
  uploadFilesSimulator(index: number) {
    // setTimeout(() => {
      if (index === this.files.length) {
        return;
      } else {
        const progressInterval = setInterval(() => {
          if (this.files[index].progress === 100) {
            clearInterval(progressInterval);
            this.uploadFilesSimulator(index + 1);
          } else {
            this.files[index].progress += 20;
          }
        }, 50);
        let formData = new FormData();
        formData.append('specific_discount',this.files[0])
        this.discountGroupService.UploadCSVFile(formData).subscribe((result)=>{
          result['data']['emails']=result['data']['emails'].filter(x=>!this.allowedEmailInputData.includes(x))
          result['data']['domains']=result['data']['domains'].filter(x=>!this.allowedDomainsInputData.includes(x))
          result['data']['emails'].forEach((x,i)=>{
            this.allowedEmailInputData.push(x)

          })
          result['data']['domains'].forEach((x,i)=>{
            this.allowedDomainsInputData.push(x)

          })
// this.allowedDomainsInputData.push(result['data']['domains'])
//       // console.log(this.allowedEmailInputData,'pppp--====')

          Swal.fire({
            position: 'center',
            icon: 'success',
            html: result['message'],
            padding: '2em',
          });
        })
    // }, 1000);
  }
}
}
