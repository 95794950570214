import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { ApiClientService } from '../../shared/services/api-client.service';
import Swal from 'sweetalert2';

@Injectable({
  providedIn: 'root'
})
export class FormsService {

  updatedFieldRule = new BehaviorSubject<any>([]);
  discountRuleFieldConditions = new BehaviorSubject<any>([]);
  currentForm = new BehaviorSubject<any>({});
  saveCurrentFormWithSettings = new BehaviorSubject<boolean>(false);
  formUpdated = new BehaviorSubject<boolean>(false);

  createDiscountRule = new BehaviorSubject<any>(
    {
      "form_id": '',
      "rule_type_id": null,
      "rule": {
        "rule_name": "",
        "if_condition": {
          "allow": "",
          "conditions": []
        },
        "then_apply": {
          "amount_type": "",
          "value": null,
        },
        "proof": ""
      }
    }
  );

  private userSource$ = new BehaviorSubject<any>('');
  image = this.userSource$.asObservable();

  createFormData$ = new Subject();
  formFieldsValidationRules = new Subject();
  private customFieldTypeData = new Subject();
  private CustomFormObjectData = new Subject();
  private updateDiscountAfterFieldsDelete = new Subject();
  public certificate = new BehaviorSubject<object>([]);
  public static = new BehaviorSubject<any>('');
  form_type = new BehaviorSubject<any>('');
  constructor(
    private apiClientService: ApiClientService
  ) { }

  setUser(image):void {
    this.userSource$.next(image);
  }

  
setFormType(formType) {
this.form_type.next(formType);
}
getFormType(){
  return this.form_type.asObservable();
}
  fetchFormsList(rows,page,col,ord,category_name,subcategory_name,sub_sub_category_name,is_popular) {
    return this.apiClientService.get('forms?rows='+rows +'&page=' +page+'&col='+col +"&order="+ord+'&category_name='+category_name+'&subcategory_name='+subcategory_name+'&sub_sub_category_name='+sub_sub_category_name+'&is_popular='+is_popular);
  }
  fetchVoteList(rows,page,col,ord,category_name,subcategory_name,sub_sub_category_name,is_popular) {
    return this.apiClientService.get('voting_forms?rows='+rows +'&page=' +page+'&col='+col +"&order="+ord+'&category_name='+category_name+'&subcategory_name='+subcategory_name+'&sub_sub_category_name='+sub_sub_category_name+'&is_popular='+is_popular);
  }
  duplicateForm(id){
    return this.apiClientService.get('duplicate_form/'+id);

  }
  fetchCategoryList(level: number) {
    return this.apiClientService.get('category_list?level=' + level);
  }

  fetchRoleCategoriesList() {
    return this.apiClientService.get(`auth_category_list`);
  }

  postCreateForm(data: any) {
    return this.apiClientService.post('forms', data);
  }

  fetchFormBuilderData(formid: string) {
    return this.apiClientService.get('forms/' + formid);
  }
  fetchBookingData(formid,id) {
    return this.apiClientService.post('get_form_submission/' + id,formid);
  }
  repushmiddleware(payment) {
    return this.apiClientService.post('push_trx_to_middleware' ,payment);
  }
  fetchVotingForms() {
    return this.apiClientService.get('voting_forms');
  }
  saveFormBuilderData(formid: string, data: any) {
    return this.apiClientService.put('forms/' + formid, data);
  }
  getFormDetail(data){
      return this.apiClientService.get('forms/' + data+ '/edit');
  }
  setCreateFormData(data) {
    this.createFormData$.next(data);
  }
  deleteForms(data){
    // return this.apiClientService.delete('role/'+ data);
    this.apiClientService.delete('forms/'+ data)
    .subscribe((result: any) => {
      if(result && result['success']==true) {
        // this.router.navigate(['/role/all-roles']);
        Swal.fire({
          position: 'center',
          icon: 'success',
          html: result['message'],
          showConfirmButton: true,
          padding: '2em',
        });

      } else {
        Swal.fire({
          position: 'center',
          icon: 'error',
          html: result['message'],
          padding: '2em',
        });
        // this.toster.error('You have enter Wrong Email or Password.');
      }
    })
  }
  deletFinance(data){
    // return this.apiClientService.delete('role/'+ data);
    this.apiClientService.delete('finance_category/'+ data)
    .subscribe((result: any) => {
      if(result && result['success']==true) {
        // this.router.navigate(['/role/all-roles']);
        Swal.fire({
          position: 'center',
          icon: 'info',
          html: result['message'],
          showConfirmButton: true,
          padding: '2em',
        });

      } else {
        Swal.fire({
          position: 'center',
          icon: 'error',
          html: result['message'],
          padding: '2em',
        });
        // this.toster.error('You have enter Wrong Email or Password.');
      }
    })
  }
  getCreateFormData() {
    return this.createFormData$.asObservable();
  }

  setCustomeFormTypes(data: any) {
    this.customFieldTypeData.next(data);
  }
  getCustomFormTypes() {
    return this.customFieldTypeData.asObservable();
  }
  setCustomFormObject(customFormData) {
    this.CustomFormObjectData.next(customFormData);
  }
  getCustomFormData() {
    return this.CustomFormObjectData.asObservable();
  }

  setFormFieldsValidationRules(data) {
    this.formFieldsValidationRules.next(data);
  }

  getFormFieldsValidationRules() {
    return this.formFieldsValidationRules.asObservable();
  }

  updateFormFieldsValidationRules(fieldData) {
    this.updatedFieldRule.next(fieldData);
  }

  getUpdatedFieldRule() {
    return this.updatedFieldRule.asObservable();
  }

  updateCurrentForm(data) {
    this.currentForm.next(data);
  }

  getCurrentForm() {
    return this.currentForm.asObservable();
  }
  getBookingSettingPageData() {
    return this.apiClientService.get('add_pay_link_exp_time');
  }

  getFormSubmission(data,rows = '',page = '',col = '',ord = '',search = '') {
    if(rows =='All'){
      rows=''
    }
    return this.apiClientService.post('get_form_submission?rows='+rows +'&page=' +page+'&col='+col +"&order="+ord+'&search='+search,data);
  }
  getInviteUser(data){
    return this.apiClientService.post('invite_users_list',data);

  }
  getInviteUserList(data,rows,page,col,ord,search){
    if(rows =='All'){
      rows=''
    }
    return this.apiClientService.post('form_email_logs?rows='+rows +'&page=' +page+'&col='+col +"&order="+ord+"&email_type=invite" +"&search="+search,data)

  }
  submituserformedit(data){
    return this.apiClientService.post('update_form_data',data);
    

  }
  send_booking_email(data){
    return this.apiClientService.post('send_booking_email',data);
    

  }
  getCountriesList() {
    return this.apiClientService.get('countrylist');
  }

  getDiscuntTypesList() {
    return this.apiClientService.get('get_discount_types');
  }

  getDiscountRuleConditionsList() {
    let data = {
      'status': false,
      'data': []
    }
    this.apiClientService.get('get_rules_conditions').subscribe((result: any) => {
      if(result.status) {
        data = {
          'status': result.status,
          'data': result.data
        }
      }
      // alert('data')
      // console.log(data);
      this.discountRuleFieldConditions.next(data);
    }, error => {
      data.data = error;
      this.discountRuleFieldConditions.next(data);
    });
  }

  getDiscountRuleConditionsData() {
    return this.discountRuleFieldConditions.asObservable();
  }

  createDiscountRulePayload() {
    return this.createDiscountRule.asObservable();
  }

  createFormDiscountRule(data) {
    return this.apiClientService.post('discount_rules', data);
  }

  getFormDiscountRules(formId) {
    return this.apiClientService.get('discount_rules?form_id=' + formId);
  }
  DeleteDiscountRules(fielID) {
    return this.apiClientService.delete('discount_rules/' + fielID);
  } 
  DeleteFiedRules(fielID) {
    return this.apiClientService.delete('field_rules/' + fielID);
  } 
  DeletePageRules(fielID) {
    return this.apiClientService.delete('page_rules/' + fielID);
  } 
  CreateFieldRules(data:any) {
    return this.apiClientService.post('field_rules' , data);
  }
  CreateFormRules(data:any) {
    return this.apiClientService.post('form_rules', data);
  }
  CreatePageRules(data:any) {
    return this.apiClientService.post('page_rules' , data);
  }
  GetFieldRules(data:any) {
    return this.apiClientService.get('field_rules?form_id='+ data.form_id);
  }
  GetFormRules(data:any) {
    return this.apiClientService.get('form_rules?form_id='+ data.form_id);
  }
  GetPageRules(data:any) {
    return this.apiClientService.get('page_rules?form_id='+ data.form_id);
  }
  createBulkEmail(data: any) {
    return this.apiClientService.post('send_form_email', data);
  }

  getBookingUsers(data:any) {
    return this.apiClientService.post('booking_users', data);
  }

  getBulkEmail(data:any) {
    return this.apiClientService.post('form_email_logs', data);
  }

  updateDiscountAfterFieldDelete(data: any) {
    this.updateDiscountAfterFieldsDelete.next(data);
  }

  getUpdatedDiscountRules() {
   return this.updateDiscountAfterFieldsDelete.asObservable();
  }

  getFormBody(formID: any) {
    return this.apiClientService.get('get_form_builder/'+ formID);
  }

  saveCurrentForm(value: boolean) {
    this.saveCurrentFormWithSettings.next(value);
  }

  saveCurrentFormGetter() {
    return this.saveCurrentFormWithSettings.asObservable();
  }

  setFormBuilderUpdate(data: boolean) {
    this.formUpdated.next(data);
  }

  getFormBuilderUpdate() {
    return this.formUpdated.asObservable();
  }
  FormSort(data) {
    return this.apiClientService.post('form_sorting',data);
  }
  getUsercerificatedetails() {
    return this.apiClientService.get('user_basic_detail');
  }

  getErpData(bookingID: string) {
    return this.apiClientService.get('get_trx_erp_res/' + bookingID);
  }

  sendFormCertificates(data: any) {
    return this.apiClientService.post('send_form_certificate', data);
  }

  formPageRules(data: any) {
    return this.apiClientService.post('get_form_page_rule', data);
  }
} 
