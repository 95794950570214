import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { CookieService } from 'ngx-cookie-service';
import { AuthService } from '../services/firebase/auth.service';
import { environment } from '../../../environments/environment';
import { CommonService } from './common.service';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
res:any
  constructor(private cookieService: CookieService, private authService: AuthService,private common:CommonService) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const isLoggedIn = this.authService.isLoggedIn;
    const isApiUrl = request.url.startsWith(environment.baseApiUrl);

    if (isLoggedIn && isApiUrl) {
      request = this.setAuthorizationHeader(request);
    }
    return next.handle(request);
  }
 
  private setAuthorizationHeader(req: HttpRequest<any>): HttpRequest<any> {


    
    const token: string = this.cookieService.get('token_type') + ' ' + this.cookieService.get('access_token');
    req = req.clone({ setHeaders: { ['Authorization']: (token || '') } });
    // req = req.clone({ setHeaders: { ['Content-Type']: 'application/json' } });
    req = req.clone({ setHeaders: { ['Accept']: 'application/json' } });
    // req = req.clone({ setHeaders: { ['Access-Control-Allow-Origin']: '*' } });
    return req;
  }
}
